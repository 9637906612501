import axios from 'axios'
import { constants } from '@/common/constants'

axios.defaults.baseURL = `https://api.growsurf.com/v2/campaign/${constants.growSurfCampaignId}`
axios.defaults.headers.common['Authorization'] =
  'Bearer ' + constants.growSurfAPI
axios.defaults.headers.post['Content-Type'] = 'application/json'

const addParticipant = async data => {
  try {
    const response = await axios.post(`/participant`, data)
    return response
  } catch (e) {
    return e
  }
}

const getParticipantByEmail = async email => {
  try {
    const response = await axios.get(`/participant/${email}`)
    return response
  } catch (e) {
    return e.response
  }
}

const triggerReferral = async refId => {
  try {
    const response = await axios.post(`/participant/${refId}/ref`)
    return response
  } catch (error) {
    return error
  }
}

export { addParticipant, getParticipantByEmail, triggerReferral }
