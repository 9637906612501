<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <!-- START WRAPPER -->
    <div class="flex min-h-screen justify-between">
      <!-- START LEFT COLUMN -->
      <OnboardingLeftColumn />
      <!-- END LEFT COLUMN -->

      <!-- START RIGHT COLUMN -->
      <div class="flex-1 bg-white dark:bg-gray-900">
        <div class="grid h-full items-start justify-center">
          <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
            <div class="flex h-full max-w-lg flex-col">
              <a href="#" title="TexAu" class="">
                <img
                  class="mx-auto h-12 w-auto xl:h-14"
                  src="@/assets/images/logo-alt.png"
                  alt="TexAu logo"
                />
              </a>

              <div class="mt-12 grid h-full place-items-center">
                <div>
                  <div class="text-center">
                    <Heading text="Create your first Workspace" />
                    <p class="mt-4 text-base text-gray-600 dark:text-gray-400">
                      Give your workspace the name of your organization, client,
                      or just your name. You will still be able to change it
                      later.
                    </p>
                  </div>

                  <vee-form
                    @submit="submitCreate()"
                    class="mt-10"
                    :validation-schema="schema"
                  >
                    <div class="sm:flex sm:items-end sm:space-x-5">
                      <div class="flex-1">
                        <label
                          for=""
                          class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                        >
                          Workspace Name
                        </label>
                        <div class="mt-1.5">
                          <Input
                            v-model="workspaceName"
                            text="Enter workspace name"
                            name="WorkSpaceName"
                          />
                        </div>
                      </div>

                      <div class="mt-4 shrink-0 sm:mt-0">
                        <Button
                          :showLoader="isLoading"
                          type="submit"
                          text="Next"
                          rightIcon="right-arrow"
                        />
                      </div>
                    </div>
                    <p class="mt-2 text-sm text-red-600">
                      <ErrorMessage name="WorkSpaceName" />
                    </p>
                  </vee-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END RIGHT COLUMN -->
    </div>
    <!-- END WRAPPER -->
  </div>
</template>

<script>
import OnboardingLeftColumn from '@/components/onboarding/OnboardingLeftColumn.vue'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'
import Heading from '@/components/onboarding/Heading.vue'
import arrow from '@/assets/images/onboarding/illustrations/right-arrow-icon.svg'
import { createWorkspace } from '@/apis/workspace'

import { updateUserDetail } from '@/apis/user'
import UserProfile from '@/common/userWrapper'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'
import { EVENTS_TO_BE_TRACKED } from '@/common/constants'

export default {
  // step-1
  name: 'createFirstWorkspace',
  components: {
    OnboardingLeftColumn,
    Input,
    Button,
    Heading
  },
  data() {
    return {
      workspaceName: 'My first workspace',
      arrow,
      schema: {
        WorkSpaceName: 'workspace_required|workspace_max:50'
      },
      isLoading: false
    }
  },
  methods: {
    /**
     * Updates the onboarding step to the next phase ("Add Additional Details").
     *
     * This asynchronous method sends a request to update the user's onboarding step
     * and updates the onboarding state in cookies. If the update is successful,
     * it navigates the user to the "Add Additional Details" page. If the update fails,
     * it emits an error event.
     *
     * The loading state (`this.isLoading`) is updated during the process.
     *
     * @async
     * @returns {Promise<void>} No return value, but updates the user onboarding state
     * and navigates to the next step or emits an error event.
     *
     * @throws {string} Emits an error event if the step update or navigation fails.
     */
    async updateNextStep() {
      const responseUser = await updateUserDetail({
        currentOnboardingStep: 'Add Additional Details'
      })
      UserProfile.updateOnboardingStateInCookies('Add Additional Details')
      this.isLoading = false
      if (responseUser['success']) {
        await this.$router.push('/onboarding/additional-details')
      } else {
        this.$emit('error', 'Error in moving to next step.')
      }
    },

    /**
     * Submits a request to create a new workspace.
     *
     * This method handles the workspace creation process, including:
     * - Tracking the event of creating the first workspace with Amplitude.
     * - Storing workspace and user information in cookies upon success.
     * - Proceeding to the next step in the process if creation is successful.
     *
     * @async
     * @returns {Promise<void>} No return value, but updates component state and emits events.
     *
     * @throws {string} Emits an error event with the response message if the workspace creation fails.
     */
    async submitCreate() {
      try {
        this.isLoading = true
        const organisationId = window.$cookies.get('organisationId')
        const orgUserId = window.$cookies.get('orgUserId')
        const response = await createWorkspace(
          this.workspaceName,
          organisationId,
          orgUserId
        )
        if (response['success']) {
          amplitudeTrackEvent(
            EVENTS_TO_BE_TRACKED.CREATED_FIRST_WORKSPACE,
            localStorage.getItem('email')
          )
          window.$cookies.set('workspaceId', response.data.workspaceId, -1, '/')
          window.$cookies.set('orgUserId', response.data.orgUserId, -1, '/')
          await this.updateNextStep()
        } else {
          throw response.message
        }
      } catch (error) {
        this.isLoading = false
        this.$emit('error', error)
      }
    }
  }
}
</script>

<style></style>
