<template>
  <div class="relative mt-4 h-full flex-1">
    <div
      v-if="userRole === 'owner' || userRole === 'admin'"
      class="flex items-center justify-end"
    >
      <Button
        text="New User"
        leftIcon="plus"
        class="h-[33px]"
        @click="$emit('onNewUserClick')"
      />
    </div>

    <div class="h-full mt-2">
      <div class="flex flex-col">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div class="overflow-hidden">
              <table class="min-w-full">
                <thead class="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th
                      scope="col"
                      class="heading-primary min-w-[12rem] rounded-l-lg"
                    >
                      Name
                    </th>

                    <th scope="col" class="heading-primary">Email</th>

                    <th scope="col" class="heading-primary">Role</th>

                    <th scope="col" class="heading-primary rounded-r-lg">
                      <span class="sr-only"> Actions </span>
                    </th>
                  </tr>
                </thead>
                <!-- Skeleton loader -->
                <tbody
                  v-if="initialLoading"
                  class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
                >
                  <tr v-for="ele in 4" :key="ele">
                    <td v-for="ele in 3" :key="ele" class="data-primary">
                      <Skeleton
                        height="41px"
                        width="313px"
                        borderRadius="8px"
                      ></Skeleton>
                    </td>
                  </tr>
                </tbody>

                <tbody
                  v-else
                  class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
                  :key="componentKey"
                >
                  <tr v-for="(item, index) in userData" :key="index" ref="user">
                    <td class="data-primary">
                      <div>
                        <div>
                          <Input
                            text="Enter name"
                            type="text"
                            :readonly="!item.isAdd"
                            :modelValue="item.name"
                            label="Label"
                            labelClass="sr-only"
                            :key="index"
                            @change="onNameInputChange($event, index)"
                            @keyup="onNameInputChange($event, index)"
                          />
                        </div>
                      </div>
                    </td>

                    <td class="data-primary">
                      <div>
                        <div>
                          <input
                            :readonly="!item.isAdd"
                            type="text"
                            placeholder="Enter email"
                            v-model="item.email"
                            class="block w-full rounded-lg border px-3 py-2.5 placeholder-gray-500 transition-all duration-200 dark:placeholder-gray-400 border-gray-300 caret-blue-600 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                            :class="[
                              {
                                'blur-on-lose-focus': !item.isAdd
                              }
                            ]"
                            @change="onEmailChange($event, index)"
                            @keyup="onEmailChange($event, index)"
                          />
                        </div>
                      </div>
                    </td>

                    <td class="data-primary">
                      <div>
                        <div>
                          <Input
                            v-if="!item.isAdd && !showSaveButton(index)"
                            type="text"
                            :v-show="!showSaveButton(index)"
                            :readonly="!item.isAdd"
                            text="Role"
                            :modelValue="
                              item.role.charAt(0).toUpperCase() +
                              item.role.slice(1)
                            "
                            label="Label"
                            labelClass="sr-only"
                            :key="index"
                          />
                          <Select
                            v-if="item.isAdd || showSaveButton(index)"
                            :v-show="showSaveButton(index)"
                            :default-selected="item.role"
                            :options="this.getRoles"
                            @change="onRoleChange($event, index)"
                          />
                        </div>
                      </div>
                    </td>

                    <td class="data-primary" v-if="showActionButton">
                      <div class="flex items-center space-x-3">
                        <Button
                          v-if="item.isEdit && showSaveButton(index)"
                          :v-show="showSaveButton(index)"
                          text="Save"
                          @click="updateMemberData(index)"
                          :show-loader="showUpdatingLoader(index)"
                        />
                        <Button
                          v-if="item.isEdit && !showSaveButton(index)"
                          :v-show="!showSaveButton(index)"
                          text="Edit"
                          color="tertiary"
                          @click="onEditClick(index)"
                        />
                        <Button
                          v-if="item.isDelete"
                          icon="delete"
                          color="iconOnly"
                          :show-loader="showDeleteLoader(index)"
                          @click="onDeleteClick(index)"
                          class="-m-2 rounded-lg p-2 text-red-600 transition-all duration-200 hover:bg-red-50 focus:outline-none dark:text-red-400 dark:hover:bg-red-500 dark:hover:text-red-50"
                        />
                        <Button
                          v-if="item.isAdd"
                          text="Add"
                          color="tertiary"
                          @click="onAddClick(index)"
                          :show-loader="showAddingLoader(index)"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  deleteMember,
  getWorkspaceMembers,
  inviteUser,
  updateMemberRole
} from '@/apis/workspace'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'userRolesSection',
  components: {
    SvgIcon,
    Input,
    Button,
    Select
  },
  data() {
    return {
      initialLoading: false,
      componentKey: 0,
      isEditing: [],
      dataForSave: [],
      isAdding: [],
      isUpdating: [],
      isDeleting: [],
      showActionButton: false
    }
  },
  props: {
    userData: {
      type: Array,
      default: []
    }
  },
  computed: {
    ...mapGetters('settings', ['getNewUserAddStatus']),
    ...mapGetters('user', ['getUserData']),
    ...mapGetters('settings', ['getMetaData']),
    ...mapState('settings', ['userRole']),

    getRoles() {
      const allRoles = this.getMetaData['roles']
        ? this.getMetaData['roles'].map(function (role) {
            return {
              value: role.name,
              label: role.name.charAt(0).toUpperCase() + role.name.slice(1)
            }
          })
        : []
      return allRoles.filter(role => role.value !== 'owner')
    }
  },
  methods: {
    ...mapActions('settings', ['addNewUserClicked']),

    onEditClick(i) {
      this.isEditing[i] = true
    },

    async onDeleteClick(i) {
      this.isDeleting[i] = true
      const memberId = this.userData[i].memberId
      const workspaceId = window.$cookies.get('workspaceId')
      const response = await deleteMember(workspaceId, memberId)
      this.isDeleting[i] = undefined
      if (response['success']) {
        const memberIndex = this.userData.findIndex(
          p => p.memberId === memberId
        )
        this.userData.splice(memberIndex, 1)
        this.componentKey += 1
      } else {
        this.$emit('error', 'Deleting member failed.')
      }
    },

    async updateMemberData(i) {
      this.isUpdating[i] = true
      const memberId = this.userData[i].memberId
      const workspaceId = window.$cookies.get('workspaceId')
      const roleId = this.userData[i].roleId
      const response = await updateMemberRole(workspaceId, memberId, roleId)
      this.isUpdating[i] = undefined
      this.isEditing[i] = undefined
      if (response['success']) {
        this.$emit('inviteSuccess', 'Member Updated Successfully')
        await this.updateTableData(memberId)
      } else {
        this.$emit('error', 'Updating member role failed.')
      }
    },

    showSaveButton(i) {
      return this.getLoaderFlag(this.isEditing, i)
    },

    showUpdatingLoader(i) {
      return this.getLoaderFlag(this.isUpdating, i)
    },

    showDeleteLoader(i) {
      return this.getLoaderFlag(this.isDeleting, i)
    },

    showAddingLoader(i) {
      return this.getLoaderFlag(this.isAdding, i)
    },

    getLoaderFlag(array, i) {
      return !!array[i]
    },

    onNameInputChange(e, index) {
      this.userData[index]['name'] = e.target.value
    },

    onEmailChange(e, index) {
      this.userData[index]['email'] = e.target.value
    },

    onRoleChange(selectedRole, index) {
      this.userData[index]['roleId'] = this.getMetaData['roles'].find(
        item => item.name === selectedRole
      ).id
    },

    async onAddClick(index) {
      this.isAdding[index] = true
      const workspaceId = window.$cookies.get('workspaceId')
      const inviteeData = this.userData[index]
      const inviteResponse = await inviteUser(
        workspaceId,
        inviteeData.email,
        inviteeData.name,
        inviteeData.roleId
      )
      this.isAdding[index] = undefined
      if (inviteResponse['success']) {
        this.$emit('inviteSuccess', `${inviteeData.name} invited successfully.`)
      } else {
        this.$emit('error', inviteResponse.message)
      }
    },

    async updateTableData(memberId) {
      const workspaceMemberResponse = await this.getAllMembers()
      const memberNewObject = workspaceMemberResponse.find(
        o => o.memberId === memberId
      )
      const memberIndex = this.userData.findIndex(p => p.memberId === memberId)
      this.userData[memberIndex] = {
        memberId: memberNewObject.memberId,
        name:
          memberNewObject.orgUserData.userData.firstname +
          ' ' +
          memberNewObject.orgUserData.userData.lastname,
        email: memberNewObject.orgUserData.userData.email,
        role: memberNewObject.role,
        isAdmin:
          memberNewObject.role === 'admin' || memberNewObject.role === 'owner',
        isEdit:
          memberNewObject.role === 'viewer' ||
          memberNewObject.role === 'editor' ||
          memberNewObject.role === 'admin',
        isDelete:
          memberNewObject.role === 'viewer' ||
          memberNewObject.role === 'editor' ||
          memberNewObject.role === 'admin'
      }
      this.componentKey += 1
    },

    async getAllMembers() {
      const workspaceId = window.$cookies.get('workspaceId')
      const workspaceMemberResponse = await getWorkspaceMembers(workspaceId)
      if (workspaceMemberResponse['success']) {
        return workspaceMemberResponse.data
      } else {
        this.$emit('error', 'Unable to fetch members.')
      }
    }
  },
  watch: {
    getNewUserAddStatus: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.userData.push({
            name: '',
            email: '',
            role: '',
            isAdd: true
          })
          this.componentKey += 1
          this.addNewUserClicked({ payload: false })
        }
      },
      deep: true,
      immediate: true
    }
  },
  async beforeMount() {
    this.initialLoading = true
    this.userData.length = 0
    const workspaceMemberResponse = await this.getAllMembers()
    for (const workspaceMemberResponseElement of workspaceMemberResponse) {
      const firstName = workspaceMemberResponseElement.orgUserData.userData
        .firstname
        ? workspaceMemberResponseElement.orgUserData.userData.firstname
        : ''
      const lastName = workspaceMemberResponseElement.orgUserData.userData
        .lastname
        ? workspaceMemberResponseElement.orgUserData.userData.lastname
        : ''
      this.userData.push({
        memberId: workspaceMemberResponseElement.memberId,
        name: firstName || lastName ? firstName + ' ' + lastName : '',
        email: workspaceMemberResponseElement.orgUserData.userData.email,
        role: workspaceMemberResponseElement.role,
        isAdmin:
          workspaceMemberResponseElement.role === 'admin' ||
          workspaceMemberResponseElement.role === 'owner',
        isEdit:
          workspaceMemberResponseElement.role === 'viewer' ||
          workspaceMemberResponseElement.role === 'editor' ||
          workspaceMemberResponseElement.role === 'admin',
        isDelete:
          workspaceMemberResponseElement.role === 'viewer' ||
          workspaceMemberResponseElement.role === 'editor' ||
          workspaceMemberResponseElement.role === 'admin'
      })
    }
    this.componentKey += 1
    for (const data of this.userData) {
      if (data.role === 'owner' || data.role === 'admin') {
        if (data.email === this.getUserData.email) {
          this.showActionButton = true
          break
        }
      }
    }
    this.initialLoading = false
  }
}
</script>

<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .heading-primary {
    @apply px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50;
  }

  .data-primary {
    @apply whitespace-nowrap px-3 py-4;
  }
}
.blur-on-lose-focus:not(:focus) {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
</style>
