<template>
  <div class="w-full">
    <Banner
      v-if="showBanner"
      class="absolute top-1 z-50 left-1/2"
      text=""
      @close="showBanner = false"
    />
    <slot :error="onError" :success="onSuccess" />
    <ToastContainer class="w-1/5" ref="toast" />
  </div>
</template>

<script>
import ToastContainer from '@/components/ToastContainer.vue'
import Banner from '@/components/Banner.vue'
import { constants } from '@/common/constants'
export default {
  name: 'LayoutGeneric',
  components: {
    ToastContainer,
    Banner
  },
  data() {
    return {
      showBanner: false
    }
  },
  mounted() {
    window.intercomSettings = {
      app_id: constants.intercomAppid,
      vertical_padding: 70
    }

    window.Intercom('boot', {
      app_id: constants.intercomAppid
    })
  },
  methods: {
    onError(data) {
      this.$refs.toast.addToast({
        timeout: 7000,
        text: 'Error Occurred!',
        color: 'error',
        caption: data,
        action: false,
        close: true,
        icon: true
      })
    },

    onSuccess(data) {
      this.$refs.toast.addToast({
        timeout: 7000,
        text: 'Successful!',
        color: 'positive',
        caption: data,
        action: false,
        close: true,
        icon: true
      })
    }
  }
}
</script>

<style></style>
