<template>
  <!-- START WRAPPER -->
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center px-4 py-12 antialiased sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto max-h-full w-full max-w-xl overflow-x-hidden rounded-xl bg-white border-2 dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Delete Integration?
          </p>

          <div class="">
            <Button
              @click="emitCloseWithoutRefetch()"
              icon="close-1"
              class="!p-1.5"
              size="large"
              color="tertiary"
            />
          </div>
        </div>
      </div>

      <div class="px-4 pb-5 sm:px-6 sm:pb-6 flex flex-col gap-y-4">
        <div>
          <p class="text-center">This Action is Irreversible</p>
        </div>
        <div class="bg-red-100 text-red-600 rounded-lg p-2">
          <div class="flex space-x-2 items-center justify-center">
            <SvgIcon class="h-5 w-5" name="warning" />
            <p class="font-bold">Warning</p>
          </div>
          <div>
            <p class="text-sm font-[500] text-center">
              All running automations using this Integration will be
              automatically set to pause.
            </p>
          </div>
        </div>
        <div class="flex items-center justify-center space-x-4">
          <Button
            text="Cancel"
            @click="emitCloseWithoutRefetch()"
            color="tertiary"
          />
          <Button
            text="Delete"
            @click="submitDelete"
            color="dangerFilled"
            :leftIcon="deleteLoader ? null : 'delete'"
            :showLoader="deleteLoader"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Input from '@/components/Input.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import {
  deleteConnectedAccount,
  deleteConnectedAccountForce
} from '@/apis/connectedAccounts'
import { deleteProxyById, forceDeleteProxyById } from '@/apis/proxy'

export default {
  name: 'forceDeleteIntegrationModal',
  components: {
    Input,
    SvgIcon,
    Button
  },
  props: {
    forceDeleteId: {
      type: String,

      required: true
    }
  },
  data() {
    return {
      deleteLoader: false
    }
  },
  methods: {
    emitClose() {
      this.$emit('close')
    },
    emitCloseWithoutRefetch() {
      this.$emit('closeModal')
    },
    async submitDelete() {
      try {
        this.deleteLoader = true
        let firstResponse
        let secondResponse

        firstResponse = await deleteConnectedAccount(this.forceDeleteId)

        if (firstResponse['success']) {
          this.$emit('success')
          this.emitClose()
        } else {
          if (firstResponse?.data?.canForceDelete) {
            secondResponse = await deleteConnectedAccountForce(
              this.forceDeleteId
            )

            if (secondResponse['success']) {
              this.emitClose()
              this.$emit('success')
            } else {
              throw secondResponse.message
            }
          } else {
            throw firstResponse.message
          }
        }
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.deleteLoader = false
      }
    }
  }
}
</script>

<style scoped></style>
