<template>
  <AutomationHeader title="Agency White-Label" />
  <section class="flex flex-1 border-t border-gray-200 dark:border-gray-700">
    <SettingsMenu active-option="Agency Whitelabel" />
    <ClientSection />
  </section>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import ClientSection from '@/components/settings/clientSection.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'

export default {
  name: 'clients',
  components: {
    AutomationHeader,
    SettingsMenu,
    ClientSection
  }
}
</script>

<style scoped></style>
