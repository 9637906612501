<template>
  <tr>
    <td class="relative w-12 px-6 sm:w-16 sm:px-8">
      <input
        type="checkbox"
        :value="executionId"
        v-model="executionIdToDelete"
        class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-800 sm:left-6"
        @change="updateCheckall()"
      />
    </td>

    <td
      class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
    >
      <div class="flex flex-col justify-start">
        <p :class="[{ 'pt-4': status != 'running' }]">{{ workflowName }}</p>
        <p
          v-if="status !== 'running'"
          class="text-xs w-56 text-wrap mt-1 capitalize"
          :class="[
            {
              'text-orange-500': status === 'paused' || status === 'waiting',
              'text-red-600': status === 'stopped',
              'text-red-600': status === 'failed',
              'text-green-600': status === 'completed',
              'text-gray-500': status === 'delayed',
              'text-black': status === 'partially-failed'
            }
          ]"
        >
          {{
            status === 'completed' || status === 'stopped'
              ? status
              : statusReason
              ? `${status}: ${
                  statusReason.length > 115
                    ? statusReason.slice(0, 112) + '...'
                    : statusReason
                }`
              : status
          }}
        </p>
      </div>
    </td>

    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
      <!-- <img class="h-6 w-6" :src="platformImg" alt="" /> -->

      <SvgIcon class="text-gray-900 dark:text-gray-50" :name="platformImg" />
    </td>

    <td
      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
    >
      {{ schedule }}
    </td>

    <td
      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
    >
      {{ date }}
    </td>

    <td
      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
    >
      {{ getFormatExecutionTime(elapsedTime) }}
    </td>

    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
      <div class="flex items-center space-x-5">
        <Popper hover>
          <SvgIcon
            class="h-5 w-6"
            v-if="status === 'running'"
            name="automationLoader"
          />

          <button
            v-else
            type="button"
            class="flex items-center justify-center"
            :class="[
              {
                'text-orange-500': status === 'paused' || status === 'waiting',
                'text-red-600': status === 'stopped',
                'text-red-600': status === 'failed',
                'text-green-600': status === 'completed',
                'text-gray-500': status === 'delayed',
                'text-black': status === 'partially-failed'
              }
            ]"
          >
            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 8 8">
              <circle cx="4" cy="4" r="3" />
            </svg>
          </button>
          <template #content>
            <div class="rounded bg-blue-50 p-2 text-sm capitalize">
              {{
                status === 'completed' ||
                status === 'stopped' ||
                status === 'running'
                  ? status
                  : `${status}: ${statusReason ? statusReason : ''} `
              }}
            </div>
          </template>
        </Popper>

        <div class="flex gap-2">
          <!-- TODO: WILL BE NEEDED LATER -->
          <!-- <Popper v-if="status === 'running' || status === 'stopped'" class="cursor-pointer" hover>
            <SvgIcon
              
              class="text-orange-500"
              name="pause"
            />
            <template #content>
              <div class="rounded bg-blue-50 p-2 text-sm capitalize">Pause</div>
            </template>
          </Popper>

          <Popper  v-if="status === 'paused' || status === 'stopped'" class="cursor-pointer" hover>
            <SvgIcon
             
              class="text-green-500"
              name="right-triangle"
            />
            <template #content>
              <div class="rounded bg-blue-50 p-2 text-sm capitalize">
                Resume
              </div>
            </template>
          </Popper> -->
          <Popper
            v-if="status === 'running' || status === 'paused'"
            class="cursor-pointer"
            hover
          >
            <SvgIcon
              @click="stopExecutionClick()"
              class="h-5 w-5 text-red-600"
              name="cancel"
            />
            <template #content>
              <div class="rounded bg-blue-50 p-2 text-sm capitalize">Stop</div>
            </template>
          </Popper>
        </div>
      </div>
    </td>
    <td
      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
    >
      <div class="flex gap-x-4">
        <!-- <div class="w-[104px]"> -->
        <Button
          v-if="
            status === 'completed' ||
            status === 'partially-failed' ||
            $route.query.source === 'sheet' ||
            $route.query.source === 'csv'
          "
          text="Show Results"
          size="small"
          @click="show()"
        />
        <!-- </div> -->

        <Button
          v-if="
            (status === 'failed' ||
              status === 'completed' ||
              status === 'partially-failed') &&
            userRole !== 'viewer'
          "
          color="danger"
          text="Rerun Automation"
          leftIcon="refresh"
          size="small"
          @click="reRun()"
        />
        <div class="flex justify-end w-full">
          <Button
            v-show="status !== 'running' && userRole !== 'viewer'"
            color="iconOnly"
            class="-m-2 p-2 text-red-600 hover:bg-red-50 dark:text-red-400 dark:hover:bg-red-500 dark:hover:text-red-50"
            icon="delete"
            @click="handleDeleteExecution"
          />
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
//svg
import cloud from '@/assets/images/automationStore/automation-details/cloud.svg'
import continueImg from '@/assets/images/automationStore/automation-details/continue.svg'
import pauseImg from '@/assets/images/automationStore/automation-details/pause.svg'
import restartImg from '@/assets/images/automationStore/automation-details/restart.svg'
import Popper from 'vue3-popper'
import Button from '../Button.vue'
import { formatExecutionTime } from '@/common/functions/formatDateAndTime'

import { mapActions, mapState } from 'vuex'
import SvgIcon from '../SvgIcon.vue'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'
import { deleteExecution, stopExecution } from '@/apis/workflows'

export default {
  name: 'AutomationRunDetails',
  props: {
    workflowName: String,
    desktop: Boolean,
    schedule: String,
    createdAt: String,
    status: {},
    executionId: String,
    workflowId: String,
    statusReason: String,
    humanElapsedTime: {},
    executionIdArr: Array
  },
  data() {
    return {
      cloud,
      continueImg,
      pauseImg,
      restartImg,
      ids: [],
      startTime: null,
      elapsedTime: this.humanElapsedTime ?? 0,
      intervalId: null,
      counterInterval: null,
      executionIdToDelete: this.executionIdArr
    }
  },
  watch: {
    status(newVal, oldVal) {
      if (newVal === 'running') {
        this.startCounter()
      } else {
        this.stopCounter()
      }
    },
    executionIdArr() {
      this.executionIdToDelete = this.executionIdArr
    }
  },
  computed: {
    ...mapState('automationStore', ['secondNodeId']),
    ...mapState('automationStore', ['automationStoreData']),
    ...mapState('settings', ['userRole']),
    platformImg() {
      if (this.desktop === true) {
        return 'desktop'
      } else {
        return 'cloud'
      }
    },
    date() {
      const newDate = new Date(Date.parse(this.createdAt))
      const curr_date = newDate.getDate()

      const curr_month = newDate.toLocaleString('en-US', { month: 'short' })

      const curr_yr = newDate.getFullYear()
      return `${curr_date} ${curr_month} ${curr_yr}`
    }
  },
  beforeUnmount() {
    // Clear the interval to stop the counter when the component is destroyed
    clearInterval(this.counterInterval)
  },
  mounted() {
    if (this.status === 'running') {
      this.startCounter()
    } else {
      this.stopCounter()
    }
  },
  methods: {
    ...mapActions('automationStore', ['addExecutionId']),
    ...mapActions('automationStore', ['addStatus']),
    updateCheckall() {
      this.$emit('updateExecutionArr', this.executionId)
    },
    async show() {
      if (this.$route.query.publicWorkflowId) {
        await this.$router.push(
          `/data-store/results?executionId=${this.executionId}&workflowId=${this.$route.query.publicWorkflowId}`
        )
        return
      }
      const workflowId = this.$route.query.workflowId
      const operationId = this.$route.query.operationId

      if (this.$route.query.secondNodeId || this.secondNodeId) {
        await this.$router.push({
          query: {
            workflowId: workflowId,
            operationId: operationId,
            executionId: this.executionId,
            nodeId: this.$route.query.secondNodeId || this.secondNodeId,
            source: this.$route.query.source,
            platformId: this.automationStoreData.platformId
          }
        })
      } else {
        await this.$router.push({
          query: {
            workflowId: workflowId,
            operationId: operationId,
            platformId:
              this.automationStoreData.platformId ||
              this.$route.query.platformId,
            executionId: this.executionId,
            source: this.$route.query.source,
            nodeId: this.$route.query.nodeId || this.automationStoreData.nodeId
          }
        })
      }

      this.$emit('ButtonClick')
    },
    async handleDeleteExecution() {
      try {
        amplitudeTrackEvent('Execution Delete', localStorage.getItem('email'), {
          executionId: this.executionId
        })
        const data = {
          ids: [
            {
              id: this.executionId
            }
          ]
        }
        const response = await deleteExecution(data)
        if (response['success']) {
          this.$emit('deleteApiResponse', {
            type: 'success',
            value: 'Execution Successfully Deleted'
          })
        } else {
          this.$emit('deleteApiResponse', {
            type: 'fail',
            value: 'Something Went Wrong While Deleting The Execution'
          })
        }
      } catch (error) {
        this.$emit('deleteApiResponse', {
          type: 'fail',
          value: 'Something Went Wrong While Deleting The Automation'
        })
      }
    },

    reRun() {
      this.$emit('rerunAutomation', this.executionId)
    },
    startCounter() {
      // Start the counter by incrementing elapsedTime every second
      this.counterInterval = setInterval(() => {
        this.elapsedTime += 1000 // Increment by 1 second
      }, 1000)
    },
    getFormatExecutionTime(ms) {
      return `${formatExecutionTime(ms)}`
    },

    stopCounter() {
      clearInterval(this.counterInterval)
      this.elapsedTime = this.humanElapsedTime
    },
    async stopExecutionClick() {
      try {
        const response = await stopExecution(this.executionId)
        if (response['success']) {
          this.$emit('deleteApiResponse', {
            type: 'success',
            value: response.message
          })
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('deleteApiResponse', {
          type: 'fail',
          value: error
        })
      }
    }
  },

  components: { SvgIcon, Button, SvgIcon, Popper }
}
</script>

<style></style>
