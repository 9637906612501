<template>
  <div v-if="loading" class="">
    <div class="relative flex-1 overflow-y-auto antialiased">
      <div class="flex items-center px-4 sm:px-5 pt-2 justify-end">
        <Button text="Add Prompt" leftIcon="plus" class="h-[33px]" />
      </div>
      <div class="grid grid-cols-4 gap-6 px-4 py-5 sm:p-6 3xl:grid-cols-5">
        <div
          v-for="index in 14"
          :key="index"
          class="h-[200px] w-full transform cursor-pointer rounded-xl border bg-[#F8F8F8] shadow-md transition-all duration-200 hover:-translate-y-1 hover:shadow-lg"
        >
          <Skeleton height="100%" width="100%" borderRadius="8px"></Skeleton>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="relative flex-1 overflow-y-auto antialiased">
    <div class="flex items-center px-4 sm:px-5 pt-2 justify-end">
      <Button
        v-if="userRole !== 'viewer'"
        text="Add Prompt"
        leftIcon="plus"
        class="h-[33px]"
        @click="$emit('onAddPromptClick')"
      />
    </div>
    <div class="grid grid-cols-4 gap-6 px-4 py-5 sm:p-6 3xl:grid-cols-5">
      <div
        v-for="(data, index) of promptData"
        :key="data._id"
        class="bg h-[200px] w-full transform cursor-pointer rounded-xl border bg-[#F8F8F8] px-5 pb-6 pt-4 shadow-md transition-all duration-200 hover:-translate-y-1 hover:shadow-lg"
      >
        <div class="flex flex-row-reverse w-full cursor-pointer">
          <Popper
            v-if="userRole !== 'viewer'"
            placement="bottom"
            :offset-skid="-20"
            @close:popper="toDeleteIndex = null"
          >
            <div>
              <SvgIcon name="3-dot-menu-horizontal" />
            </div>
            <template #content>
              <div
                class="block w-[150px] divide-y divide-gray-100 rounded-lg border border-gray-100 bg-white shadow-xl"
              >
                <div
                  v-if="index === toDeleteIndex"
                  class="py-1 text-red-600 hover:bg-red-50 hover:text-red-700"
                  role="none"
                >
                  <a
                    href="#"
                    class="group flex items-center px-4 py-2 text-base font-medium transition-all duration-200 sm:text-sm"
                    @click="onClickDelete(data._id)"
                  >
                    <SvgIcon name="delete" class="mr-3 h-5 w-5" />
                    Really Delete
                  </a>
                </div>

                <div
                  v-else-if="data.createdBy"
                  class="py-1 text-red-600 hover:bg-red-50 hover:text-red-700"
                  role="none"
                >
                  <a
                    href="#"
                    class="group flex items-center px-4 py-2 text-base font-medium transition-all duration-200 sm:text-sm"
                    @click="toDeleteIndex = index"
                  >
                    <SvgIcon name="delete" class="mr-3 h-5 w-5" />
                    <p class="ml-3">Delete</p>
                  </a>
                </div>

                <div
                  class="py-1 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="none"
                >
                  <a
                    href="#"
                    class="group flex items-center px-4 py-2 text-base font-medium transition-all duration-200 sm:text-sm"
                    @click="onClickClone(data)"
                  >
                    <SvgIcon name="copy" class="mr-3 h-5 w-5" />
                    Clone
                  </a>
                </div>

                <div
                  v-if="usingDeveloperMode"
                  class="py-1 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="none"
                >
                  <a
                    href="#"
                    class="group flex items-center px-4 py-2 text-base font-medium transition-all duration-200 sm:text-sm"
                    @click="onClickCopy(data)"
                  >
                    <SvgIcon name="copy" class="mr-3 h-5 w-5" />
                    Copy ID
                  </a>
                </div>
              </div>
            </template>
          </Popper>
          <div v-else class="h-6"></div>
        </div>
        <div @click="onCardClick(data)" class="space-y-5">
          <div class="flex">
            <SvgIcon name="OpenAI" />
            <div class="flex items-center justify-center pl-[15px]">
              <p class="font-bold">{{ data.title }}</p>
            </div>
          </div>
          <p class="line-clamp-3 overflow-hidden">
            {{ data.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Input from '@/components/Input.vue'
import SavePromptModal from '@/components/settings/texauAI/SavePromptModal.vue'
import PromptBuilder from '@/components/settings/texauAI/PromptBuilder.vue'
import { getPrompt, deletePrompt } from '@/apis/settings/ai'
import VariableMenu from '@/components/settings/texauAI/VariableMenu.vue'
import Popper from 'vue3-popper'
import { mapState } from 'vuex'
export default {
  name: 'AiTemplates',
  emits: ['openPlayground', 'openClonedPlayground', 'apiFail'],
  components: {
    AutomationHeader,
    SettingsMenu,
    Button,
    SvgIcon,
    Input,
    SavePromptModal,
    PromptBuilder,
    VariableMenu,
    Popper
  },
  data() {
    return {
      isEditing: false,
      editingData: null,
      openModal: false,
      promptData: null,
      loading: true,
      toDeleteIndex: null
    }
  },
  created() {
    this.getPromptData()
  },
  computed: {
    ...mapState('settings', ['userRole']),
    usingDeveloperMode() {
      return JSON.parse(localStorage.getItem('devModeEnable') || false)
    }
  },
  methods: {
    onCardClick(data) {
      this.$emit('openPlayground', data)
    },
    onClickClone(data) {
      this.$emit('openClonedPlayground', data)
    },
    async onClickCopy(data) {
      await navigator.clipboard.writeText(data._id)
      this.$emit('apiSuccess', 'Prompt ID copied!')
    },
    async getPromptData() {
      try {
        this.loading = true
        const response = await getPrompt(0, 50)
        if (response['success']) {
          this.promptData = response.data
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('apiFail', error)
      } finally {
        this.loading = false
      }
    },

    async onClickDelete(id) {
      try {
        const response = await deletePrompt(id)
        if (response['success']) {
          this.getPromptData()
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('apiFail', error)
      }
    },
    modalClose() {
      this.openModal = false
      ;(this.isEditing = false), (this.editingData = null), this.getPromptData()
    }
  }
}
</script>
