import axios from '@/common/axios'

const changePassword = async ({ currentPassword, newPassword }) => {
  try {
    const response = await axios.post('auth/change-password', {
      currentPassword,
      newPassword
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const forceSetPassword = async data => {
  try {
    const response = await axios.post('auth/force-set-password', data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const refreshToken = async data => {
  try {
    const response = await axios.post('auth/refresh-token', data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export { changePassword, refreshToken, forceSetPassword }
