<!-- max-h-[450px] -->
<template>
  <div id="inputForm" class="grid gap-3 overflow-auto">
    <div
      v-for="input of sortedPlatformInputs"
      :key="input._id"
      :class="{ flex: input.isDeletable }"
    >
      <div class="flex-1">
        <InputField
          :input="input"
          :name="input.name"
          :error="errorMessage[input.name]"
          :previousNodes="previousNodes"
          :includeSearch="input.includeSearch"
          :allInputs="values"
          @label-update="e => $emit('label-update', e, input, values)"
          @change="e => $emit('change', e, input, values)"
          @input-blur="onBlur(input)"
          @toggleOpenAiAccounts="e => emit('toggleOpenAiAccounts', e)"
        />
      </div>
      <SvgIcon
        class="mx-2 h-4 w-4 cursor-pointer self-center text-red-500"
        name="delete"
        v-if="input.isDeletable"
        @click="$emit('input-delete', input)"
      />
    </div>
  </div>
</template>

<script setup>
import InputField from '@/components/InputField.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { useForm } from 'vee-validate'
import { computed, watch, onMounted } from 'vue'
const props = defineProps({
  platformInputs: {
    type: Object,
    required: true
  },
  previousNodes: { type: Array },
  inputs: {
    required: true
  },
  triggerValidation: {
    type: Boolean
  },
  triggerFormStatus: {
    type: Boolean
  },
  errorMessage: { default: {} }
})

const emit = defineEmits([
  'validationSuccess',
  'validationFailed',
  'input-update',
  'input-delete',
  'label-update',
  'input-blur',
  'form-status',
  'toggleOpenAiAccounts'
])

const sortedPlatformInputs = computed(() => {
  return (
    props.platformInputs &&
    props.platformInputs.sort((a, b) => Number(a.position) - Number(b.position))
  )
})

const { values, validate, resetForm } = useForm({
  initialValues: props.inputs
})

watch(values, async () => {
  const validationResult = await validate({ mode: 'silent' })
  emit('input-update', values, validationResult)
})

const onBlur = async input => {
  const validationResult = await validate({ mode: 'silent' })
  emit('input-blur', values, validationResult, input)
}

// watch for trigger validation prop and
// run validation based on the toggle
watch(
  () => props.triggerValidation,
  async toggle => {
    if (toggle) {
      const validationResult = await validate()
      if (validationResult.valid) {
        emit('validationSuccess', values)
      } else {
        emit('validationFailed', values)
      }
    }
  }
)

watch(props.triggerFormStatus, async (newVal, oldVal) => {
  if (newVal === true) {
    checkAndReturnValidationStatus()
  }
})

onMounted(() => {
  if (props.triggerFormStatus) {
    checkAndReturnValidationStatus()
  }
})

const checkAndReturnValidationStatus = async () => {
  const validationResult = await validate({ mode: 'silent' })
  emit('form-status', validationResult)
}
</script>

<style></style>
