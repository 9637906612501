<template>
  <div class="grid auto-rows-max gap-8 overflow-scroll px-2">
    <div v-if="filters.length === 0" class="mt-1">
      <ConditionButton label="Add condition" @click="addOrCondition" />
    </div>
    <div
      v-else
      v-for="(filter, filterIdx) in filters"
      class="grid grid-flow-row auto-rows-max gap-6"
      :key="filter.id"
    >
      <Label
        class="text-[16px]"
        :text="`${filterIdx > 0 ? 'Or, continue' : 'Continue'}  if`"
      />
      <div
        v-for="(condition, conditionIdx) in filter.conditions"
        :key="condition.id"
      >
        <Condition
          :previousNodes="previousNodes"
          @delete-condition="deleteCondition(condition.id, filter.id)"
          v-model="filters[filterIdx].conditions[conditionIdx]"
        />
      </div>
      <div class="flex gap-2" ref="buttons">
        <ConditionButton
          label="And condition"
          @click="addAndCondition(filterIdx)"
        />
        <ConditionButton
          label="Or condition"
          @click="addOrCondition"
          v-if="filterIdx === filters.length - 1"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Label from '@/components/Label.vue'
import { nanoid } from 'nanoid'
import { ref, watch } from 'vue'
import Condition from './Condition.vue'
import ConditionButton from './ConditionButton.vue'

const props = defineProps({
  modelValue: {
    type: Array,
    required: true
  },
  previousNodes: {
    type: Array,
    required: true
  }
})
const filters = ref(props.modelValue)
const buttons = ref(null)
const emit = defineEmits(['update:modelValue'])

watch(filters, newFilters => {
  emit('update:modelValue', newFilters)
})

const scrollToButton = buttonIdx => {
  setTimeout(() => {
    buttons.value[buttonIdx].scrollIntoView({
      block: 'end',
      behavior: 'smooth'
    })
  }, 100)
}

const addAndCondition = filterIdx => {
  filters.value[filterIdx].conditions.push({
    id: nanoid(),
    field: '',
    operator: null,
    values: ['']
  })
  scrollToButton(filterIdx)
}

const addOrCondition = () => {
  filters.value.push({
    id: nanoid(),
    conditions: [
      {
        id: nanoid(),
        field: '',
        operator: null,
        values: ['']
      }
    ]
  })
  scrollToButton(filters.value.length - 1)
}

// delete condition or the entire filter if no conditions left in that filter
const deleteCondition = (conditionId, filterId) => {
  filters.value = filters.value
    .map(filter => {
      if (filter.id === filterId) {
        return {
          ...filter,
          conditions: filter.conditions.filter(condition => {
            return condition.id !== conditionId
          })
        }
      }
      return filter
    })
    .filter(filter => filter.conditions.length > 0)
}
</script>

<style></style>
