<template>
  <div class="relative flex flex-1 border-l border-gray-200 dark:border-gray-700 antialiased">
    <div class="relative flex flex-col justify-between flex-1 px-4 py-5 sm:p-6">
      <div class="flex-1">
        <AgencyWhitelabelHeader activeOption="report"/>

        <div class="flex items-center justify-between mt-8">
          <div class="flex items-center">
            <SvgIcon name="calendar" />
            <span class="ml-3 text-sm font-semibold text-gray-900 dark:text-gray-50">
                      Weekly report: 07 Mar 2022 - 14 Mar 2022
                    </span>
          </div>

          <div>
            <div>
              <Select text="Select workspace" options=""/>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-7 gap-6 mt-8">
          <InfoBox v-for="(item, index) in primaryInfo" :key="index" :icon-name="item.iconName" :count="item.count" :operation-name="item.operationName" :svg-class="item.svgClass"/>
        </div>

        <ReportTable/>

        <div class="mt-8">
          <p class="text-sm font-semibold text-gray-900 dark:text-gray-50">
            Send report to these people
          </p>

          <UserListForSendReport/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgencyWhitelabelHeader from "@/components/settings/agencyWhitelabelHeader.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import Select from "@/components/Select.vue";
import InfoBox from "@/components/settings/Reports/infoBox.vue";
import ReportTable from "@/components/settings/Reports/reportTable.vue";
import UserListForSendReport from "@/components/settings/Reports/userListForSendReport.vue";

export default {
  name: "reportsSection",
  components: {
   AgencyWhitelabelHeader,
    SvgIcon,
    Select,
    InfoBox,
    ReportTable,
    UserListForSendReport,
  },
  props: {
    primaryInfo: {type:Array, default: [
        {
          iconName: 'inviteSent',
          count: '96',
          operationName: 'Invites Sent'
        },
        {
          iconName: 'peoples',
          count: '33',
          operationName: 'New Connections'
        },
        {
          iconName: 'mail',
          count: '100',
          operationName: 'Messages Sent',
          svgClass: 'w-6 h-6'
        },
        {
          iconName: 'growth',
          count: '+30%',
          operationName: 'Growth Rate'
        },
        {
          iconName: 'message',
          count: '+19%',
          operationName: 'Reply Rate'
        },
        {
          iconName: 'automation',
          count: '22,567',
          operationName: 'Automated Tasks'
        },
        {
          iconName: 'checkInSquare',
          count: '19h',
          operationName: 'Manual Tasks'
        },
      ]}
  }
}
</script>

<style scoped>

</style>
