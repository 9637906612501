export const convertToProperFormat = (schedulingData, option) => {
  const cronExpression = ['0', `*`, '*', '*', '*', '*']
  return formCronForSchedule(cronExpression)[option](schedulingData)
}

const formCronForSchedule = cronExpression => ({
  0: () => ({}),
  1: ({ minutes }) => ({ minutes }),
  2: ({ date, time }) => {
    const exactDateWithTime = new Date(date)
    exactDateWithTime.setHours(
      time.phase === 'am' ? time.hour : (time.hour + 12) % 24
    )
    exactDateWithTime.setMinutes(time.minute)
    return { date: exactDateWithTime.toISOString() }
  },
  3: ({ time }) => {
    cronExpression[2] = time.phase === 'am' ? time.hour : (time.hour + 12) % 24
    cronExpression[1] = time.minute
    return {
      cron: cronExpression.join(' ')
    }
  },
  4: ({ days, time }) => {
    cronExpression[2] = time.phase === 'am' ? time.hour : (time.hour + 12) % 24
    cronExpression[1] = time.minute
    if (days?.length) cronExpression[5] = days.join(',')
    return {
      cron: cronExpression.join(' ')
    }
  },
  5: ({ dates, time }) => {
    cronExpression[2] = time.phase === 'am' ? time.hour : (time.hour + 12) % 24
    cronExpression[1] = time.minute
    if (dates?.length) cronExpression[3] = dates.join(',')
    return {
      cron: cronExpression.join(' ')
    }
  },
  6: ({ dates, months, time }) => {
    cronExpression[2] = time.phase === 'am' ? time.hour : (time.hour + 12) % 24
    cronExpression[1] = time.minute
    if (dates?.length) {
      cronExpression[3] = dates.join(',')
    }
    if (months?.length) {
      cronExpression[4] = months.join(',')
    }
    return {
      cron: cronExpression.join(' ')
    }
  }
})
