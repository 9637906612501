<template>
  <div
    class="absolute left-[50%] top-2 z-30 hidden translate-x-[-50%] rounded-lg bg-blue-50 dark:bg-blue-900/50"
    id="texau-v2-extension-sync-status"
  >
    <div class="p-2">
      <div class="flex items-start justify-between">
        <SvgIcon class="h-5 w-5 text-blue-600" name="info" />

        <p
          class="ml-3 mr-auto flex-1 text-sm font-normal text-gray-900 dark:text-gray-50"
        >
          Syncing...
        </p>
      </div>
    </div>
  </div>
  <div
    class="absolute left-[50%] top-2 z-30 hidden translate-x-[-50%] rounded-lg bg-green-50 dark:bg-green-900/50"
    id="texau-v2-extension-sync-result-success"
  >
    <div class="p-2">
      <div class="flex items-start justify-between">
        <SvgIcon class="h-5 w-5 text-green-600" name="info" />

        <p
          class="ml-3 mr-1 flex-1 text-sm font-normal text-gray-900 dark:text-gray-50"
        >
          Account synced successfully!
        </p>
      </div>
    </div>
  </div>
  <div
    class="absolute left-[50%] top-2 z-30 hidden translate-x-[-50%] rounded-lg bg-red-50 dark:bg-red-900/50"
    id="texau-v2-extension-sync-result-fail"
  >
    <div class="p-2">
      <div class="flex items-start justify-between">
        <SvgIcon class="h-5 w-5 text-red-600" name="info" />

        <p
          class="ml-3 mr-auto flex-1 text-sm font-normal text-gray-900 dark:text-gray-50"
        >
          Error occured while syncing, please try again after sometime!
        </p>
      </div>
    </div>
  </div>

  <AutomationHeader :title="title" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <ConnectNewAccountModal
      v-if="modalActive"
      @close="toggleModalClose"
      :integrationData="modalIntegrationData"
      @updateNotificationApiResponse="updateNotificationApiResponse"
    />

    <SettingsMenu active-option="integration" @showIntegrations="toggleShow" />

    <div
      v-if="integrationsAccess === false"
      class="flex h-full min-w-0 flex-1 flex-col border-l border-gray-200 dark:border-gray-700"
    >
      <NoAccess />
    </div>

    <div
      v-else
      class="flex h-full min-w-0 flex-1 flex-col border-l border-gray-200 dark:border-gray-700"
    >
      <AllIntegrationsSection
        v-if="show === 'integration'"
        @emitError="updateNotificationApiResponse"
        @modalActivation="toggleModalOpen"
        @showAccounts="toggleShow"
      />
      <IntegrationAccounts
        v-else-if="show === 'accounts'"
        @showIntegration="toggleShow"
        :integrationData="integrationData"
        @modalActivation="toggleModalOpen"
        @apiFail="apiFail"
        @apiSuccess="apiSuccess"
        :fetchNewData="fetchNewData"
        @resetFetch="fetchNewData = false"
        @onAddNewClick="onAddNewClick"
      />
    </div>
  </main>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import AllIntegrationsSection from '@/components/settings/allIntegrationsSection.vue'
import ConnectNewAccountModal from '@/components/settings/integration/connectNewAccountModal.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import IntegrationAccounts from '@/components/settings/integration/IntegrationAccounts.vue'
import { mapState } from 'vuex'
import NoAccess from '@/components/NoAccess.vue'

export default {
  name: 'allIntegrations',
  components: {
    AutomationHeader,
    SettingsMenu,
    AllIntegrationsSection,
    ConnectNewAccountModal,
    IntegrationAccounts,
    NoAccess
  },
  data() {
    return {
      modalActive: false,
      integrationData: null,
      modalIntegrationData: null,
      show: 'integration',
      title: 'Integration',
      fetchNewData: false
    }
  },
  computed: {
    ...mapState('user', ['integrationsAccess'])
  },
  methods: {
    toggleModalOpen(data) {
      this.modalIntegrationData = data.item
      this.modalActive = data.val
    },
    toggleModalClose(data) {
      if (data === 'fetch') {
        this.fetchNewData = true
      }
      this.modalActive = false
    },
    updateNotificationApiResponse(data) {
      if (data.type === 'success') {
        this.$emit('success', data.value)
      } else {
        this.$emit('error', data.value)
      }
    },
    toggleShow(data) {
      if (data.val && data.val === 'accounts') {
        this.show = data.val
        this.integrationData = data.item
        this.title = data.item.label
      } else {
        this.show = data
        this.title = 'Integration'
      }
    },
    onAddNewClick() {
      document.querySelector('#addNewAccount').click()
    },
    apiFail(data) {
      this.$emit('error', data)
    },
    apiSuccess(data) {
      this.$emit('success', data)
    }
  }
}
</script>

<style scoped></style>
