div
<template>
  <!-- START WRAPPER -->
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Profile Variables
          </p>

          <div class="flex items-center justify-end space-x-4">
            <div class="w-[250px]" v-if="platformNames">
              <Select
                :hideClear="true"
                :leftImageUrl="platformIcon"
                text="Select platform"
                :options="platformOptions"
                v-model="selectedPlatformId"
                @change="getAllVariables()"
                :key="platformNames"
              />
            </div>
            <Button
              text="New Variable"
              leftIcon="plus"
              @click="addNewVariable"
            />

            <Button
              @click="emitClose"
              icon="close-1"
              class="!p-1.5"
              size="large"
              color="tertiary"
            />
          </div>
        </div>
      </div>

      <div v-if="loading" class="px-4 pb-5 sm:px-6 sm:pb-6">
        <div class="flex items-center justify-center">
          <Spinner />
        </div>
      </div>

      <div
        v-else
        class="px-4 h-[80vh] overflow-auto pb-5 sm:px-6 sm:pb-6 overflow-y-scroll"
      >
        <div
          v-if="showMessage"
          class="rounded-lg bg-blue-50 dark:bg-blue-900/50"
        >
          <div class="p-4">
            <div class="flex items-start justify-between">
              <SvgIcon name="smiley" />
              <p
                class="ml-3 mr-auto flex-1 text-sm font-normal text-gray-900 dark:text-gray-50"
              >
                Variables specific to your profile. This can be a link, your
                website, your phone, or email.
              </p>
              <div class="ml-4 flex shrink-0">
                <Button
                  icon="closeButton"
                  @click="hideMessage"
                  color="iconOnly"
                  class="-m-1 rounded-md p-1 text-gray-500 transition-all duration-200 hover:bg-blue-100 hover:text-blue-600 focus:outline-none dark:text-gray-400 dark:hover:bg-blue-600 dark:hover:text-blue-50"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="mt-5 grid gap-x-6 gap-y-5"
          style="grid-template-columns: 1fr 1fr 0.2fr"
        >
          <div>
            <p class="text-sm font-semibold text-gray-900 dark:text-gray-50">
              Variable Name
            </p>
          </div>

          <div>
            <p class="text-sm font-semibold text-gray-900 dark:text-gray-50">
              Variable Value
            </p>
          </div>
        </div>

        <div
          v-for="(item, index) in variableDataNew"
          :key="variableData"
          class="grid max-h-96 gap-x-6 gap-y-3 py-2"
          style="grid-template-columns: 1fr 1fr 0.2fr"
        >
          <Input
            type="text"
            v-model="item.placeholder"
            label="Label"
            label-class="sr-only"
            :readonly="!item.isNew"
            class="p-[1px]"
            :text="item?.namePlaceholder"
          />

          <div class="p-[1px]">
            <Input
              v-model="item.value"
              :text="item?.valuePlaceholder"
              type="text"
              :readonly="!item.isNew"
              label="Label"
              label-class="sr-only"
            />
          </div>

          <div class="flex items-center space-x-3">
            <Button
              v-if="!item.isNew && !(isDeleting && index === deleteIndex)"
              icon="delete"
              color="iconOnly"
              @click="deleteVariableCall(item.placeholder, index)"
              class="-m-2 rounded-lg p-2 text-red-600 transition-all duration-200 hover:bg-red-50 focus:outline-none dark:text-red-400 dark:hover:bg-red-500 dark:hover:text-red-50"
            />
            <Button
              v-if="isDeleting && index === deleteIndex"
              :showLoader="true"
              color="iconOnly"
              @click="deleteVariableCall(item.placeholder, index)"
              class="-m-2 rounded-lg p-2 text-red-600 transition-all duration-200 hover:bg-red-50 focus:outline-none dark:text-red-400 dark:hover:bg-red-500 dark:hover:text-red-50"
            />
            <Button
              v-if="item.isNew"
              icon="close-1"
              color="iconOnly"
              @click="
                () => {
                  variableDataNew.splice(index, 1)
                }
              "
              class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
              :class="[{ 'bg-gray-100 text-gray-900': item.isEditing }]"
            />
          </div>
        </div>

        <div class="mt-4 flex items-center justify-end space-x-4">
          <Button
            text="Save"
            @click="updateVariableApiCall"
            :showLoader="isSaving"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Input from '@/components/Input.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import Select from '@/components/Select.vue'
import {
  updateVariable,
  deleteVariable,
  getAccountVariables
} from '@/apis/connectedAccounts'

export default {
  name: 'profileVariables',
  components: {
    Input,
    SvgIcon,
    Button,
    Spinner,
    Select
  },
  props: {
    variableData: {
      type: Array,

      required: true
    },
    platformToIdMap: {
      type: String,
      required: true
    },
    platformIcons: {
      type: Array,
      required: true
    },
    platformId: {
      type: Array,
      required: true
    },
    platformNames: {
      type: Array,
      required: true
    },
    getAllConnectedAccount: { type: Function, default: null }
  },
  data() {
    return {
      showMessage: true,
      isSaving: false,
      isDeleting: false,
      deleteIndex: null,
      variableDataNew: null,
      loading: true,
      selectedPlatformId: null,
      platformOptions: null,
      platformName: null,
      platformIcon: null
    }
  },
  beforeMount() {
    this.platformOptions = this.platformNames.map((val, index) => {
      return {
        label: val,
        value: this.platformId[index],
        img: this.platformIcons[index]
      }
    })
    this.selectedPlatformId = this.platformOptions[0].value
    this.getAllVariables()
  },

  methods: {
    async getAllVariables() {
      this.loading = true
      const id = this.platformOptions.find(
        item => item.value === this.selectedPlatformId
      )
      this.platformIcon = id?.img
      const response = await getAccountVariables(
        this.platformToIdMap[this.selectedPlatformId]
      )
      let arr = []
      for (const key in response.data) {
        if (Object.hasOwnProperty.call(response.data, key)) {
          arr.push({
            placeholder: key,
            value: response.data[key],
            isNew: false,
            isEditing: false
          })
        }
      }
      this.variableDataNew = [...arr]
      this.loading = false
    },
    addNewVariable() {
      this.variableDataNew.push(
        ...[
          {
            placeholder: '',
            value: '',
            isNew: true,
            namePlaceholder: 'Variable Name',
            valuePlaceholder: 'Variable Value'
          }
        ]
      )
    },

    hideMessage() {
      this.showMessage = false
    },

    emitClose() {
      this.$emit('close')
    },

    async updateVariableApiCall(i) {
      try {
        let obj = {}
        for (const data of this.variableDataNew) {
          obj[data.placeholder] = data.value
        }

        this.isSaving = true
        const response = await updateVariable(
          this.platformToIdMap[this.selectedPlatformId],
          { data: obj }
        )
        if (response['success']) {
          this.$emit('success', 'Variables Updated')
          await this.getAllVariables()
          this.isSaving = false
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
        return
      }
    },

    async deleteVariableCall(name, index) {
      this.isDeleting = true
      this.deleteIndex = index
      try {
        let data = {
          variableName: name
        }
        const response = await deleteVariable(
          this.platformToIdMap[this.selectedPlatformId],
          data
        )
        if (response['success']) {
          this.variableDataNew.splice(index, 1)
          this.$emit('success', 'Variable Deleted')
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
      this.isDeleting = false
      this.deleteIndex = null
    }
  }
}
</script>

<style scoped></style>
