// Onboarding Pages
import { getUsers, getBaseUser } from '@/apis/user'
import UserProfile from '@/common/userWrapper'
import { createRouter, createWebHistory } from 'vue-router'
import { AuthRoutes } from './authRoutes'
import { PublicRoutes } from './publicRoutes'
import { getExecutionTimeLeft } from '@/common/functions/executionTimeFetch'
import { store } from '@/store/store'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'

/** @type {import('vue-router').RouterOptions['routes']} */

// render auth or public router dependent on auth status
export const routes = UserProfile.getUser() ? AuthRoutes : PublicRoutes

export const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  try {
    // skip navigation if its from the same path to the same path.
    if (from.fullPath === to.fullPath && from.name === to.name) return false
    amplitudeTrackEvent('Page View', localStorage.getItem('email'), {
      pageTitle: to?.name,
      pageUrl: to.fullPath,
      previousPage: from.fullPath
    })
    // Get the page title from the route's 'name' attribute (if it exists)
    if (typeof to.name === 'string') {
      const title = to.name
      // If the route has a title, set it as the page title of the document/page
      if (title) {
        document.title = title
      }
    }

    const user = UserProfile.getUser()

    // return if no logged in user.
    if (!user) return

    // check for email verification.
    // if (!user.isEmailVerified) {
    //   if (to.name !== 'Confirm Mail') {
    //     return { name: 'Confirm Mail', replace: true }
    //   } else {
    //     return true
    //   }
    // }

    // check if the user is onboarded
    if (!user.isOnboarded) {
      const tempStep = user.currentOnboardingStep
        ? user.currentOnboardingStep
        : 'Create First Workspace'
      if (to.name !== tempStep) {
        return { name: tempStep, replace: true }
      }
    } else {
      if (
        from.path.indexOf('onboarding') > -1 ||
        to.path.indexOf('onboarding') > -1
      ) {
        return {
          name: 'home',
          replace: true
        }
      }
    }
    //update intercom message on router change
    window.Intercom('update')

    //check if route requires dev status
    if (to.meta.requiresDeveloperStatus) {
      try {
        //if base user in vuex is empty fetch base user and check dev status
        if (Object.keys(store.state.user.userData).length === 0) {
          const baseUserResponse = await getBaseUser()
          if (!baseUserResponse.data.developer) {
            return {
              name: 'home',
              replace: true
            }
          }
        } else {
          //check vuex for dev status
          if (
            store.state.user.userData?.developer === undefined ||
            store.state.user.userData?.developer === false
          ) {
            return {
              name: 'home',
              replace: true
            }
          }
        }
      } catch (error) {
        //fallback if anything fails
        return {
          name: 'home',
          replace: true
        }
      }
    }

    // call APIs to get basic details and save it in localstorage
    // only if not already done.
    if (window.$cookies.get('orgUserId')) {
      //only get the execution time when user goes to create automations

      if (
        to.name === 'Workflow Builder' ||
        to.name === 'AutomationStorePage3'
      ) {
        getExecutionTimeLeft()
      }
      return
    }

    const userResponse = await getUsers()
    if (userResponse['success']) {
      const userDefaultData = userResponse.data.find(
        o => o['isDefault'] === true
      )
      window.$cookies.set('orgUserId', userDefaultData.orgUserId, -1, '/')
      window.$cookies.set('workspaceId', userDefaultData.workspaceId, -1, '/')
      window.$cookies.set(
        'organisationId',
        userDefaultData.organisationId,
        -1,
        '/'
      )
      getExecutionTimeLeft()
    }
  } catch (error) {}
})
