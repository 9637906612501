<template>
  <div
    class="fixed left-0 top-0 z-40 flex h-screen w-screen items-center justify-center"
  >
    <div
      class="absolute left-0 top-0 h-screen w-screen bg-gray-400 bg-opacity-40"
      @click="$emit('input-modal-close')"
    />
    <div class="z-10 h-[650px] w-[720px] rounded-[10px] bg-white px-8 py-10">
      <div class="relative mb-9">
        <nav class="flex justify-center space-x-4" aria-label="Tabs">
          <a
            href="#"
            title=""
            class="rounded-lg bg-gray-100 px-3 py-2 text-sm font-medium text-gray-700 transition-all duration-200 dark:bg-gray-700 dark:text-gray-50"
            aria-current="page"
          >
            Incoming Webhook Config
          </a>
        </nav>
        <button
          type="button"
          class="absolute right-0 top-0 h-9 w-9 rounded-lg border border-gray-200 bg-white p-1.5 text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900"
          @click="
            () => {
              $emit('input-modal-close')
              $emit('incomingWebhookModalClose')
            }
          "
        >
          <span class="sr-only"> Close </span>
          <SvgIcon name="close" class="m-auto" />
        </button>
      </div>
      <SvgIcon name="Loader" v-if="loadingInputs" class="m-auto" />
      <div v-else class="flex flex-col gap-4">
        <div class="flex items-center gap-2">
          <label
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
            >Webhook Listening</label
          >
          <Switch v-model="isActive" />
        </div>
        <div>
          <label
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
            >Webhook URL</label
          >
          <div class="mt-1.5 flex flex-col gap-2">
            <input
              v-on:focus="$event.target.select()"
              :value="webhookUrl"
              readonly
              class="flex-1 rounded border border-dashed p-2"
              ref="webhookUrlInput"
            />

            <p class="text-sm text-gray-400" v-show="isDetermining">
              Texau is now listening for the data and will determine the data
              structure from the incoming data automatically. To initiate this,
              please send your data sample to the webhook address displayed
              above.
            </p>
            <div class="flex gap-2 self-end">
              <Button
                v-show="isDetermined"
                text="Determined Successfully!"
                color="successFilled"
              />
              <Button
                v-show="isDetermining"
                text="Stop"
                @click="toggleDataStructureDetermining"
                color="dangerFilled"
              />
              <Button
                v-show="!isDetermining && !isDetermined"
                text="Determine data structure"
                @click="toggleDataStructureDetermining"
              />
              <Button
                text="Copy URL"
                @click="copyToClipboard"
                leftIcon="copy"
                color="secondary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  toggleDetermingInputStructure,
  toggleWebhookActive
} from '@/apis/webhooks'
import { getWorkflow, updateWorkflowNode } from '@/apis/workflows'
import { constants } from '@/common/constants'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Switch from '@/components/Switch.vue'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getAllPreviousNodes } from '../../helper'
const props = defineProps({
  selectedNode: {
    type: Object
  },
  nodes: {
    type: Object
  }
})

const previousNodes = ref([])
const webhookUrlInput = ref()

const loadingInputs = ref(true)
const isDetermining = ref(false)
const isDetermined = ref(false)
const isStopped = ref(false)
const isActive = ref(false)
const store = useStore()

const emit = defineEmits([
  'error',
  'incomingWebhookModalClose',
  'clear-configure-message'
])
const route = useRoute()

const webhookUrl = computed(() => {
  const orgUserId = window.$cookies.get('orgUserId')
  return `${constants.WEBHOOK_API_BASE_URL}/${orgUserId}/${route.params.id}`
})

const copyToClipboard = () => {
  webhookUrlInput.value.focus()
  document.execCommand('copy')
}

const toggleDataStructureDetermining = async () => {
  try {
    isStopped.value = false
    const determining = !isDetermining.value
    isDetermining.value = determining
    await toggleDetermingInputStructure(route.params.id, determining)
    if (determining) {
      await pollDeterminingState()
    } else {
      isStopped.value = true
    }
  } catch (error) {}
}

const pollDeterminingState = async () => {
  try {
    const response = await getWorkflow(route.params.id)
    const {
      webhook: { isDeterminingDataStructure, inputs }
    } = response.data
    isDetermining.value = isDeterminingDataStructure
    if (isDeterminingDataStructure) {
      await new Promise(resolve =>
        setTimeout(() => {
          resolve()
        }, 2000)
      )
      return pollDeterminingState()
    } else {
      if (!isStopped.value) {
        isDetermined.value = !!inputs
        store.dispatch('workflow/setVariables', {
          platformOperationId: 'webhook',
          variables: inputs
        })
      }
      return
    }
  } catch (error) {}
}

watch(isActive, async newVal => {
  await toggleWebhookActive(route.params.id, newVal)
})

onMounted(async () => {
  try {
    const [workflowResponse] = await Promise.all([getWorkflow(route.params.id)])
    previousNodes.value = getAllPreviousNodes(props.selectedNode, props.nodes)
    loadingInputs.value = false
    isActive.value = workflowResponse.data.webhook?.isActive || false
  } catch (e) {
    console.log(e)
  }
})

// save the node details on unmount.
onUnmounted(async () => {
  try {
    emit('clear-configure-message', props.selectedNode.id)
    await updateWorkflowNode(route.params.id, props.selectedNode.id, {
      isConfigured: true
    })
  } catch (error) {
    emit('error', 'Error occurred while saving changes.')
  }
})
</script>
