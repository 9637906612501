<template>
  <div
    class="fixed left-0 top-0 z-40 flex h-screen w-screen items-center justify-center"
  >
    <div
      class="absolute left-0 top-0 h-screen w-screen bg-gray-400 bg-opacity-40"
      @click="$emit('input-modal-close')"
    />
    <div class="z-10 h-[650px] w-[1020px] rounded-[10px] bg-white px-8 py-10">
      <div class="relative mb-9">
        <nav class="flex justify-center space-x-4" aria-label="Tabs">
          <div
            class="select-none rounded-lg bg-gray-100 px-3 py-2 text-sm font-medium text-gray-700 transition-all duration-200 dark:bg-gray-700 dark:text-gray-50"
          >
            Filter Config
          </div>
        </nav>
        <button
          type="button"
          class="absolute right-0 top-0 h-9 w-9 rounded-lg border border-gray-200 bg-white p-1.5 text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900"
          @click="$emit('input-modal-close')"
        >
          <span class="sr-only"> Close </span>
          <SvgIcon name="close" class="m-auto" />
        </button>
        <span
          v-show="loadingInputs"
          class="absolute right-[40px] top-0 flex items-center px-3 py-2 text-gray-300"
        >
          {{ 'Loading' }}
          <Spinner size="small" class="ml-1 text-gray-400" />
        </span>
      </div>
      <Filter
        v-if="!loadingInputs"
        :previousNodes="previousNodes"
        v-model="filters"
        class="h-[480px]"
      />
    </div>
  </div>
</template>

<script setup>
import { getWorkflowNode, updateWorkflowNode } from '@/apis/workflows'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { getAllPreviousNodes } from '../../helper'
import Filter from '../routers/Filter.vue'

const props = defineProps({
  selectedNode: {
    type: Object
  },
  nodes: {
    type: Object
  }
})

const loadingInputs = ref(true)
const route = useRoute()
const previousNodes = ref([])
const filters = ref([])

const emit = defineEmits(['error', 'clear-configure-message'])

// fetch all the inputs and node details on mount
// to display the input fields and their existing value
// saved in node.
onMounted(async () => {
  try {
    const [nodeResponse] = await Promise.all([
      getWorkflowNode(route.params.id, props.selectedNode.id)
    ])
    previousNodes.value = getAllPreviousNodes(props.selectedNode, props.nodes)
    filters.value = nodeResponse.data.inputs?.filters || []
    loadingInputs.value = false
  } catch (e) {}
})

// save the node details on unmount.
onUnmounted(async () => {
  try {
    emit('clear-configure-message', props.selectedNode.id)
    await updateWorkflowNode(route.params.id, props.selectedNode.id, {
      inputs: {
        filters: filters.value
      },
      isConfigured: true
    })
  } catch (error) {
    emit('error', 'Error occurred while saving changes.')
  }
})
</script>
