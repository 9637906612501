<template>
  <div
    class="absolute z-10 mt-1.5 block w-56 divide-y divide-gray-100 rounded-lg border border-gray-100 bg-white shadow-xl"
    v-if="parentEl"
    :style="{
      top: bounding.bottom - (showTop ? 190 : 0) + 'px',
      left: bounding.left - 190 + 'px'
    }"
    ref="target"
  >
    <slot />
  </div>
</template>

<script setup>
import {
  onClickOutside,
  useElementBounding,
  useElementVisibility
} from '@vueuse/core'
import { reactive, ref, watch } from 'vue'

const props = defineProps({
  parentEl: {},
  showTop: {}
})
const emit = defineEmits(['close'])

const target = ref()

let bounding = reactive(useElementBounding(props.parentEl))
const targetIsVisible = reactive(useElementVisibility(props.parentEl))

watch(
  () => props.parentEl,
  el => {
    bounding = reactive(useElementBounding(el))
    targetIsVisible.value = useElementVisibility(el)
  }
)
watch(targetIsVisible, visible => {
  if (!visible) emit('close')
})

onClickOutside(target, () => emit('close'))
</script>
