<template>
  <div class="mt-4">
    <div class="rounded-lg bg-blue-50 dark:bg-blue-900/50">
      <div class="p-4">
        <div class="flex items-start">
          <SvgIcon
            name="important"
            class="h-5 w-5 shrink-0 text-blue-500 dark:text-blue-400"
          />
          <p
            class="ml-3 flex-1 text-sm font-medium text-gray-900 dark:text-gray-50"
          >
            Add your proxies in this format: IP:PORT:LOGIN:PASS or
            DOMAIN:PORT:LOGIN:PASS one proxy per line.
          </p>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <label for="" class="sr-only"> IP or Domain </label>
      <div class="mt-1.5">
        <TextArea
          defaultText="2.86.248.33:31112:mazkla:05lKzgtyhiWvTWEM_country-UnitedStates_session-mFGbuIYH 34.226.21.196:31112:mazkla:05lKzgtyhiWvTWEM_country-Canada_session-zaxPoV6g 54.81.215.168:31112:mazkla:05lKzgtyhiWvTWEM_country-UnitedKingdom_session-mGqS 52.44.131. 09:31112:mazkla:05lKzgtyhiWvTWEM_country-HongKong_session-5H99h1aG 52.20.82.161:31112:mazkla:05lKzgtyhiWvTWEM_country-Ukraine_session-TRBEHXsh 3.89.188.210:31112:mazkla:05lKzgtyhiWvTWEM_country-Germany_session-6mYlsB06 52.5.209.10:31112:mazkla:05lKzgtyhiWvTWEM_country-France_session-PYXgNNyO 52.7.131.196:31112:mazkla:05lKzgtyhiWvTWEM_country-France_session-dOlnK8me 52.20.82.161:31112:mazkla:05lKzgtyhiWvTWEM_country-Brazil_session-K4a0OCQy"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
import TextArea from '@/components/TextArea.vue'
export default {
  name: 'PasteFromList',
  components: { SvgIcon, TextArea }
}
</script>

<style></style>
