export const ADD_NEW_USER_CLICKED = (state, payload) => {
  state.isAddNewUserClicked = payload
}

export const ADD_NEW_TAG_CLICKED = (state, payload) => {
  state.isAddTagClicked = payload
}

export const SAVE_METADATA = (state, payload) => {
  state.metadata = payload
}

export const UPDATE_WORKSPACE_MANAGER_STATUS = (state, payload) => {
  state.isWorkspaceManagerLoading = payload
}

export const UPDATE_MFA_ENABLE_STATUS = (state, payload) => {
  state.mfaEnabledSuccessfully = payload
}

export const UPDATE_NEW_PROXY_ADD_STATUS = (state, payload) => {
  state.newProxyAdded = payload
}

export const SET_USER_ROLE = (state, payload) => {
  state.userRole = payload
}
