<template>
  <CreateTemplateFoldersList/>
  <div class="relative flex-1 border-l border-gray-200 dark:border-gray-700 antialiased">
    <div class="px-4 py-5 sm:p-5">
      <a href="#" title=""
         class="inline-flex items-center justify-center p-2 text-gray-500 transition-all duration-200 bg-gray-100 border border-transparent rounded-lg focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 hover:bg-gray-200 hover:text-gray-700 focus:outline-none dark:bg-gray-700 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900 dark:focus:ring-gray-400"
         role="button">
                <span class="sr-only">
                  Back
                </span>
        <SvgIcon name="left-arrow" />
      </a>

      <div class="mt-8 space-y-5">

      </div>

      <div class="flex items-center justify-end mt-6">
        <Button leftIcon="plus" text="Create" />
      </div>
    </div>
  </div>
</template>

<script>
import CreateTemplateFoldersList from "@/components/settings/createTemplateFoldersList.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import Button from '@/components/Button.vue';

export default {
  name: "createTemplateSection",
  components: {
    CreateTemplateFoldersList,
    SvgIcon,
    Button
  }
}
</script>
