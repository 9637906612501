export const getOutput = function (state) {
  return operationId => {
    return state.variablesHashMap
      .get(operationId)
      ?.filter(variable => !variable.isInput)
  }
}

export const getWebhook = state => {
  return state.webhook
}

export const getDynamicOutputs = function (state) {
  return nodeId => {
    return state.dynamicOutputs.get(nodeId)?.map(output => ({
      name: output,
      label: output
    }))
  }
}

export const getInputs = function (state) {
  return operationId =>
    state.variablesHashMap
      .get(operationId)
      ?.filter(variable => variable.isInput)
}

export const doesOperationExist = state => operationId =>
  state.variablesHashMap.has(operationId)

export const isRouterAdded = state => state.isRouterAdded
