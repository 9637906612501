<template>
  <div v-if="loading" class="flex items-center h-[250px] justify-center">
    <Spinner size="large" />
  </div>
  <div v-else class="mt-2 flex flex-col gap-2" :key="reRender">
    <p class="text-center">
      How much time should be added as a delay between Executions
    </p>
    <div class="flex flex-col w-full gap-3">
      <p class="font-[500] text-center">Minimum Delay</p>

      <div class="flex gap-2">
        <div class="w-full">
          <label
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
          >
            Time Unit:
          </label>
          <Select
            v-model="iterationDelayConfig.minDelay.delayUnit"
            :options="['seconds', 'minutes', 'hours', 'days']"
          />
        </div>
        <div class="w-full">
          <label
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
          >
            Delay Value:
          </label>
          <Input
            v-model.number="iterationDelayConfig.minDelay.delayValue"
            text="Delay Value"
            type="number"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full gap-3">
      <p class="font-[500] text-center">Maximum Delay</p>
      <div class="flex gap-2">
        <div class="w-full">
          <label
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
          >
            Time Unit:
          </label>
          <Select
            v-model="iterationDelayConfig.maxDelay.delayUnit"
            :options="['seconds', 'minutes', 'hours', 'days']"
          />
        </div>
        <div class="w-full">
          <label
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
          >
            Delay Value:
          </label>
          <Input
            v-model.number="iterationDelayConfig.maxDelay.delayValue"
            text="Delay Value"
            type="number"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import { getWorkflowAllNodes, updateWorkflowNode } from '@/apis/workflows'
import { mapState, mapActions } from 'vuex'
import SvgIcon from '../SvgIcon.vue'
import Spinner from '../Spinner.vue'

export default {
  name: 'IterationDelayConfig',
  components: {
    Select,
    Input,
    Button,
    SvgIcon,
    Spinner
  },
  data() {
    return {
      iterationDelayConfig: {
        minDelay: {
          delayValue: 5,
          delayUnit: 'seconds'
        },
        maxDelay: {
          delayValue: 9,
          delayUnit: 'seconds'
        }
      },
      reRender: 0,
      nodeData: null,
      loading: true
    }
  },
  computed: {
    ...mapState('automationStore', ['iterationDelay'])
  },
  watch: {
    iterationDelayConfig: {
      handler(newValue, oldValue) {
        if (
          (newValue.maxDelay.delayValue === 0 &&
            newValue.minDelay.delayValue === 0) ||
          this.nodeData === null
        ) {
          return
        }
        this.updateNode(newValue)
      },
      deep: true
    }
  },
  props: {
    selectedNode: {},
    operation: {}
  },
  async mounted() {
    if (this.$route.query.workflowId || this.$route.params.id) {
      const response = await getWorkflowAllNodes(
        this.$route.query.workflowId || this.$route.params.id
      )
      if (this.selectedNode) {
        let nodeDataArr = response.data.find(
          item => item._id === this.selectedNode.id
        )
        if (nodeDataArr.iterationDelayConfig) {
          this.iterationDelayConfig = nodeDataArr.iterationDelayConfig
        } else if (this.operation.defaultIterationDelay) {
          await this.setDefaultIterationDelayConfig()
        }
        this.nodeData = nodeDataArr
      } else if (this.operation.defaultIterationDelay) {
        this.setDefaultIterationDelayConfig()
      }
    }
    this.loading = false
    this.reRender++
  },
  methods: {
    ...mapActions('automationStore', ['changeIterationDelay']),
    prevButtonClick() {
      this.$emit('nextButtonClick', {
        comp: 'scheduling-comp',
        highlight: 2,
        optionalSidebarType: 'scheduling',
        optionalSidebarItem: 'center',
        optionalSidebarSearch: false
      })
    },
    async updateNode(data) {
      const response = await updateWorkflowNode(
        this.$route.query.workflowId || this.$route.params.id,
        this.nodeData._id,
        { iterationDelayConfig: data }
      )
      if (response['success']) {
        this.$emit('success', 'Output Mode Updated Sucessfully')
      }
    },
    setDefaultIterationDelayConfig() {
      const defaultVariation = 0.2 * this.operation.defaultIterationDelay
      this.iterationDelayConfig.maxDelay.delayValue =
        (this.operation.defaultIterationDelay + defaultVariation) / 1000
      this.iterationDelayConfig.minDelay.delayValue =
        (this.operation.defaultIterationDelay - defaultVariation) / 1000
      this.iterationDelayConfig.maxDelay.delayUnit = 'seconds'
      this.iterationDelayConfig.minDelay.delayUnit = 'seconds'
    }
  }
}
</script>
