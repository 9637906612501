<template>
  <forceDeleteIntegrationModal
    :force-delete-id="forceDeleteId"
    v-if="openModal"
    @close="
      () => {
        openModal = false
        forceDeleteId = null
        this.$emit('refetch')
      }
    "
    @closeModal="
      () => {
        forceDeleteId = null
        openModal = false
      }
    "
    @error="
      e => {
        $emit('apiFail', e)
      }
    "
    @success="$emit('success', 'Integration Deleted Successfully')"
  />
  <div
    class="relative flex-1 border-l border-gray-200 antialiased dark:border-gray-700"
  >
    <div class="px-4 py-2 sm:px-5">
      <div class="flex flex-col">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div class="overflow-hidden">
              <table class="min-w-full">
                <thead class="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th
                      scope="col"
                      class="min-w-[12rem] rounded-l-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                    >
                      Is Expired
                    </th>

                    <th
                      v-if="type === 'cookie'"
                      scope="col"
                      class="rounded-r-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                    >
                      <span class="sr-only"> </span>
                    </th>

                    <th
                      scope="col"
                      class="rounded-r-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                    >
                      Platform
                    </th>

                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                    >
                      Delete
                    </th>
                    <th
                      v-if="type === 'apiKey'"
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                    >
                      Edit
                    </th>
                  </tr>
                </thead>

                <tbody
                  class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
                >
                  <tr v-for="account of accounts" :key="account._id">
                    <td class="whitespace-nowrap px-3 py-4">
                      <Entity
                        removePadding
                        :placeholder="account.name"
                        :imgUrl="
                          account.platform.picture &&
                          account.platform.picture.startsWith('https')
                            ? account.platform.picture
                            : imgUrl
                        "
                      />
                    </td>

                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm capitalize text-gray-900 dark:text-gray-50"
                    >
                      {{ account.credentials.isExpired }}
                    </td>

                    <td
                      v-if="type === 'cookie'"
                      class="whitespace-nowrap px-3 py-4"
                    >
                      <button
                        :data-sync="true"
                        name="texau-extension-v2-sync"
                        :data-platform="account.platform.id"
                        :data-userId="account.platform.userId"
                        :data-accountid="account._id"
                        :data-accountname="account.name"
                        class="flex hidden items-center justify-center rounded-lg border border-blue-600 bg-transparent px-3 py-2 text-xs font-semibold text-blue-600 transition-all duration-200 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:border-blue-400 dark:text-blue-400 dark:hover:bg-blue-400 dark:hover:text-blue-50 dark:focus:ring-blue-400 dark:focus:ring-offset-gray-900"
                      >
                        <SvgIcon
                          class="-ml-1 mr-2 h-[14px] w-[14px]"
                          name="refresh"
                        />
                        Sync
                      </button>
                    </td>

                    <td class="flex gap-x-4 whitespace-nowrap px-3 py-4">
                      <img
                        class="h-8 w-8 shrink-0 rounded-full object-cover"
                        :src="account.platform.logoUrl"
                        alt=""
                      />
                    </td>

                    <td class="whitespace-nowrap px-3 py-4">
                      <div class="flex items-center space-x-4">
                        <!-- TODO: MIGHT NEED LATER -->
                        <!-- <Button
                          type="button"
                          class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
                          color="iconOnly"
                          icon="refresh"
                        /> -->

                        <Button
                          class="-m-2 rounded-lg p-2 text-red-600 transition-all duration-200 hover:bg-red-50 focus:outline-none dark:text-red-400 dark:hover:bg-red-500 dark:hover:text-red-50"
                          color="iconOnly"
                          icon="delete"
                          @click="deleteAccount(account._id)"
                        />
                      </div>
                    </td>
                    <td
                      v-if="type === 'apiKey'"
                      class="whitespace-nowrap px-3 py-4"
                    >
                      <div class="flex items-center space-x-4">
                        <Button
                          class="-m-2 rounded-lg p-2 transition-all duration-200 focus:outline-none"
                          color="iconOnly"
                          icon="edit"
                          @click="updateAccount(account._id, account.name)"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import IntegrationsHeader from '@/components/settings/integrationsHeader.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Entity from '../Entity.vue'
import imgUrl from '/src/assets/images/default-avatar-dark.svg'
import forceDeleteIntegrationModal from '@/components/settings/forceDeleteIntegrationModal.vue'

export default {
  name: 'allIntegrationsSection',
  components: {
    IntegrationsHeader,
    SvgIcon,
    Button,
    Entity,
    forceDeleteIntegrationModal
  },

  data() {
    return {
      accounts: this.accountData,
      forceDeleteId: null,
      openModal: false
    }
  },
  methods: {
    async deleteAccount(id) {
      this.forceDeleteId = id
      this.openModal = true
    },
    modalClosed() {
      this.openModal = false
      this.forceDeleteId = null
      this.$emit('refetch')
    },
    updateAccount(id, name) {
      this.$emit('updateApiAccountIc', { id, name })
      document.querySelector('#addNewAccount').click()
    }
  },
  props: {
    accountData: {
      type: Array,
      required: true
    },
    platform: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>
