<template>
  <!-- START WRAPPER -->
  <div class="grid min-h-screen px-4 py-12 bg-gray-600 sm:px-6 lg:px-8 place-items-center dark:bg-gray-700 antialiased">

    <div class="relative w-full max-w-6xl mx-auto overflow-hidden bg-white dark:bg-gray-900 rounded-xl">
      <div class="absolute top-0 right-0 pt-4 pr-4">
        <Button icon="closeButton"
                class="p-1.5 text-gray-600 transition-all duration-200 bg-white border border-gray-200 rounded-lg dark:text-gray-400 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:bg-gray-900 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900"/>
      </div>

      <div class="px-4 py-5 sm:p-6 lg:p-8 xl:p-12">
        <div class="max-w-3xl mx-auto text-center">
          <p class="text-4xl font-bold text-gray-900 dark:text-gray-50">
            Launch your First Automation
          </p>
          <p class="mt-4 text-base font-normal text-gray-600 dark:text-gray-400">
            With TexAu you can run a simple automation and chain it to another one to build a workflow. This will gain
            you
            hours of
            productivity. Here’s few easy automation you can start with:
          </p>
        </div>

        <div v-if="isLoading" class="mt-12 flex w-full flex-col items-center justify-center space-y-4">
          <Spinner size="large"/>
        </div>

        <div v-if="!isLoading" class="mt-12 space-y-6">
          <SingleRecipeSection v-for="(item, index) in publicRecipesList"
                               v-bind="item"
                               :key="index"
                               @error="onError"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
  <ToastContainer class="w-1/5" ref="toast"/>
</template>

<script>
import Button from '@/components/Button.vue';
import SvgIcon from "@/components/SvgIcon.vue";
import SingleRecipeSection from "@/components/publicRecipes/singleRecipeSection.vue";
import {getWorkflowSuggestion} from "@/apis/publicWorkflow";
import Spinner from "@/components/Spinner.vue";
import ToastContainer from "@/components/ToastContainer.vue";

export default {
  name: "page1",
  components: {
    Button,
    SvgIcon,
    SingleRecipeSection,
    Spinner,
    ToastContainer,
  },

  data() {
    return {
      publicRecipesList: [],
      isLoading: false,
    }
  },
  methods: {

    async getRecipeSuggestion() {
      this.isLoading = true;
      const response = await getWorkflowSuggestion(0, 5);
      this.isLoading = false;
      if (response['success']) {
        for (const responseElement of response.data) {
          this.publicRecipesList.push({
            data: responseElement,
          })
        }
      }
    },

    onError(data) {
      this.$refs.toast.addToast({
        timeout: 7000,
        text: 'Error Occurred!',
        color: 'red',
        caption: data,
        action: false,
        close: true
      })
    }
  },

  async beforeMount() {
    this.publicRecipesList.length = 0;
    await this.getRecipeSuggestion();
  }
}
</script>

<style scoped>

</style>
