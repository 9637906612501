<template>
  <div>
    <div v-if="name" class="flex items-center gap-1">
      <SvgIcon
        v-if="variables"
        name="rightArrowFilled"
        @click="showVariables = !showVariables"
        class="h-3 w-3 cursor-pointer transition-all"
        :class="{ 'rotate-90': showVariables }"
      />
      <Badge
        color="primary"
        class="cursor-pointer"
        :text="name"
        @click="$emit('variable-selected', name)"
      />
    </div>
    <div class="my-2 ml-6 flex flex-col">
      <webhook-variable
        v-if="variables"
        v-for="key in Object.keys(variables)"
        :variables="getInternalVariables(key)"
        :name="getName(key)"
        :order="order + 1"
        v-show="order === 0 || showVariables"
        @variable-selected="
          e => {
            let variableName = e
            if (order > 0) {
              variableName = `${name}.${e}`
            }
            $emit('variable-selected', variableName)
          }
        "
      />
    </div>
  </div>
</template>

<script setup>
import Badge from '@/components/Badge.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { defineComponent, ref } from 'vue'

defineComponent({ name: 'webhook-variable' })

const props = defineProps({
  variables: { required: true },
  name: String,
  order: { type: Number, default: 0 }
})

defineEmits(['variable-selected'])

const showVariables = ref(false)
const getName = name => {
  const internalVariables = props.variables[name]

  return Array.isArray(internalVariables) ? `${name}[]` : name
}
const getInternalVariables = name => {
  const internalVariables = props.variables[name]
  if (typeof internalVariables === 'object')
    return Array.isArray(internalVariables)
      ? internalVariables[0]
      : internalVariables

  return undefined
}
</script>
