<template>
  <div>
    <div class="mt-8 rounded-lg bg-blue-50 dark:bg-blue-900/50">
      <div class="p-4">
        <div class="flex items-start">
          <SvgIcon
            class="h-5 w-5 shrink-0 text-blue-500 dark:text-blue-400"
            name="important"
          />
          <p
            class="ml-3 flex-1 text-sm font-medium text-gray-900 dark:text-gray-50"
          >
            Here you can add proxies for scraping Google search, maps, ecommerce
            websites.
          </p>
        </div>
      </div>
    </div>

    <div class="mt-4 flex flex-col">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden">
            <table class="min-w-full">
              <thead class="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th
                    scope="col"
                    class="min-w-[12rem] rounded-l-lg py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                  >
                    Assigned Workspace
                  </th>

                  <th scope="col" class="rounded-r-lg px-3 py-3.5 text-left">
                    <span class="sr-only"> Actions </span>
                  </th>
                </tr>
              </thead>

              <tbody
                class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
              >
                <tr>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                  >
                    Google Maps Proxy Pool
                  </td>

                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm font-normal text-gray-900 dark:text-gray-50"
                  >
                    TexAu
                  </td>

                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="flex items-center space-x-3">
                      <Button
                        icon="edit"
                        color="iconOnly"
                        size="small"
                        class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
                      />

                      <Button
                        icon="delete"
                        color="iconOnly"
                        size="small"
                        class="-m-2 rounded-lg p-2 text-red-600 transition-all duration-200 hover:bg-red-50 focus:outline-none dark:text-red-400 dark:hover:bg-red-500 dark:hover:text-red-50"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
export default {
  name: 'RotatingProxyMain',
  components: { SvgIcon, Button }
}
</script>

<style></style>
