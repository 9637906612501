<template>
  <div>
    <div
      v-if="outputs.length === 0 && !webhook"
      class="py-8 text-center text-sm font-medium uppercase text-gray-300"
    >
      There are no previous automations with output.
    </div>
    <div v-for="node of outputs" class="my-8">
      <OutputList
        :node="node"
        :outputNameToMatch="name"
        @variable-selected="
          (nodeId, output) => $emit('variable-selected', nodeId, output)
        "
      />
    </div>
    <div v-if="webhook" class="my-8">
      <div class="flex items-center space-x-2">
        <SvgIcon
          name="incoming-webhook"
          class="h-8 w-8 shrink-0 rounded-full object-cover"
        />
        <Label text="Webhook" />
      </div>
      <webhook-variable
        :variables="webhook.inputs"
        @variable-selected="
          name => $emit('variable-selected', webhook.nodeId, { name })
        "
        class="my-4 flex flex-wrap gap-4"
      />
    </div>
  </div>
</template>

<script setup>
import Label from '@/components/Label.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { getNodeOutputs } from '../helper'
import OutputList from './outputList.vue'
import WebhookVariable from './webhook-variable.vue'

const props = defineProps({
  previousNodes: {
    type: Array,
    required: true
  },
  name: String
})
const emits = defineEmits(['variable-selected'])
const store = useStore()
const webhook = ref(store.getters['workflow/getWebhook'])
const outputs = computed(() => {
  const outputs = []
  for (const node of props.previousNodes) {
    const nodeOutput = getNodeOutputs(node)
    if (nodeOutput.length > 0) {
      outputs.push({ ...node, output: nodeOutput })
    }
  }
  return outputs
})
</script>
