<template>
  <div class="relative hidden w-full max-w-sm bg-blue-900 lg:block xl:max-w-md">
    <div class="absolute inset-0">
      <img
        class="h-full w-full object-cover"
        src="@/assets/images/onboarding/background-pattern.png"
        alt=""
      />
    </div>

    <div
      class="absolute inset-0 bg-gradient-to-b from-blue-600 to-blue-900 opacity-95"
    ></div>

    <div class="absolute inset-0 grid place-items-center p-8">
      <div class="w-full">
        <div
          class="overflow-hidden rounded-xl border border-blue-600 bg-blue-700 shadow-lg shadow-blue-800/20"
        >
          <div class="px-4 py-5 sm:p-6 lg:p-8">
            <svg
              class="h-8 w-8 text-blue-300"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9082 12.3714H20.5982C20.5182 17.0414 19.5982 17.8114 16.7282 19.5114C16.3982 19.7114 16.2882 20.1314 16.4882 20.4714C16.6882 20.8014 17.1082 20.9114 17.4482 20.7114C20.8282 18.7114 22.0082 17.4914 22.0082 11.6714V6.28141C22.0082 4.57141 20.6182 3.19141 18.9182 3.19141H15.9182C14.1582 3.19141 12.8282 4.52141 12.8282 6.28141V9.28141C12.8182 11.0414 14.1482 12.3714 15.9082 12.3714Z"
              />
              <path
                d="M5.09 12.3714H9.78C9.7 17.0414 8.78 17.8114 5.91 19.5114C5.58 19.7114 5.47 20.1314 5.67 20.4714C5.87 20.8014 6.29 20.9114 6.63 20.7114C10.01 18.7114 11.19 17.4914 11.19 11.6714V6.28141C11.19 4.57141 9.8 3.19141 8.1 3.19141H5.1C3.33 3.19141 2 4.52141 2 6.28141V9.28141C2 11.0414 3.33 12.3714 5.09 12.3714Z"
              />
            </svg>

            <blockquote class="mt-5 text-xl font-medium text-white">
              <p>
                TexAu is a brilliant product that enables me to automate many of
                the lead generation processes I was doing manually. Now, I focus
                my time on higher-level tasks while TexAu works for me!
              </p>
            </blockquote>

            <div class="mt-10 flex items-center">
              <img
                class="h-9 w-9 shrink-0 rounded-full object-cover"
                src="https://ndrsl-avatars.s3.us-east-2.amazonaws.com/1620661817024-3335.jpg"
                alt=""
              />

              <div class="ml-4">
                <p class="text-base font-semibold text-white">Alex Martenson</p>
                <p class="mt-px text-sm text-white">Founder, Milo</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnboardingLeftColumn'
}
</script>

<style></style>
