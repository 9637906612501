<!--
  Entity
  ------------
  Props:
    - entityType: accepted types are 'thumbnail', 'menu', 'action', 'checkbox', 'footer', 'form'
    - placeholder: type string
    - caption: type string
    - footerData: type Array of objects, each object consist of
     - placeholder: type string and it will be footer placeholder
     - initials: type string and ti will be initials with 2 letters
    - longTextCaption: type string, it is used in thumbnail and menu entity types
    - primaryButtonPlaceholder: type string, it is used in action anf form entity types
    - secondaryButtonPlaceholder: type string, it is used in action anf form entity types
    - primaryButtonFunction: type Function, it is used in action anf form entity types
    - secondaryButtonFunction: type Function, it is used in action anf form entity types
    - menuSettingFunction: type Function, it is used in menu entity type
    - menuLongValueText: type String, it is used in menu entity type
    - checkboxButtonPlaceholder: type String, it is used in checkbox entity type
    - utilityFunction: type Function, it is used in 'footer', 'form' & 'action' entity types
    - formLabel: type String, it is used in form entity type,
    - formInputPlaceholder: type String, it is used in form entity type,
    - longCaptionForForm: type String, it is used in form entity type,
    - initials: type String, it is used in all types of entity except checkbox,
 -->
<template>
  <div
    class="overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    :class="{
      'border dark:border-gray-600': !removePadding
    }"
  >
    <div :class="{ 'px-4 py-5 sm:p-6': !removePadding }">
      <div class="flex items-center justify-between space-x-5">
        <div
          class="flex items-center"
          :class="[
            {
              'min-w-0 flex-1': thumbnail || checkbox || form || footer,
              'space-x-3': checkbox
            }
          ]"
        >
          <input
            v-if="checkbox"
            type="checkbox"
            class="h-5 w-5 cursor-pointer rounded-md border-gray-300 text-blue-600 transition-all duration-200 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
            checked
          />
          <span
            v-if="!checkbox && !imgUrl"
            class="inline-flex h-10 w-10 items-center justify-center rounded-full border border-blue-300 bg-blue-100 dark:border-blue-600 dark:bg-blue-500"
          >
            <span
              class="text-base font-medium uppercase leading-none text-blue-600 dark:text-blue-50"
            >
              {{ initials }}
            </span>
          </span>
          <Avatar v-if="imgUrl" :srcAndStatus="[{ src: imgUrl }]" />

          <div v-if="!checkbox" class="ml-4 min-w-0 flex-1">
            <p
              class="truncate text-sm font-semibold text-gray-900 dark:text-gray-50"
            >
              {{ placeholder }}
            </p>
            <p
              class="mt-0.5 truncate text-xs font-semibold text-gray-600 dark:text-gray-400"
            >
              {{ caption }}
            </p>
          </div>
          <div v-if="checkbox" class="min-w-0 flex-1">
            <label
              for=""
              class="block truncate text-sm font-semibold text-gray-900 dark:text-gray-50"
            >
              Text form option label
            </label>
            <span
              class="mt-0.5 truncate text-xs text-gray-600 dark:text-gray-400"
            >
              {{ caption }}
            </span>
          </div>
        </div>

        <div v-if="menu">
          <p
            class="truncate text-xs font-semibold text-gray-600 dark:text-gray-400"
          >
            Value
          </p>
          <p class="mt-0.5 text-sm text-gray-900 dark:text-gray-50">
            {{ menuLongValueText }}
          </p>
        </div>

        <div
          v-if="thumbnail || menu"
          :class="[
            {
              'flex items-center justify-end space-x-5': menu,
              'shrink-0 text-right': thumbnail
            }
          ]"
        >
          <p class="text-xs font-semibold text-gray-600 dark:text-gray-400">
            {{ longTextCaption }}
          </p>

          <button
            v-if="menu"
            type="button"
            @click="menuSettingFunction"
            class="inline-flex items-center justify-center rounded-lg border border-transparent bg-transparent p-2.5 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-gray-700 dark:focus:ring-offset-gray-900"
          >
            <SvgIcon name="menuSetting" />
          </button>
        </div>
        <div
          v-if="action || checkbox || footer || form"
          :class="[
            {
              'flex items-center justify-end space-x-3':
                action || checkbox || footer,
              'ml-auto flex items-center justify-end space-x-3': form
            }
          ]"
        >
          <Button
            v-if="action"
            :text="secondaryButtonPlaceholder"
            color="tertiary"
            @click="secondaryButtonFunction"
          />

          <Button
            v-if="action"
            :text="primaryButtonPlaceholder"
            color="primary"
            @click="primaryButtonFunction"
          />

          <button
            v-if="action || footer || form"
            type="button"
            @click="utilityFunction"
            class="inline-flex items-center justify-center rounded-lg border border-transparent bg-transparent p-2.5 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-gray-700 dark:focus:ring-offset-gray-900"
          >
            <SvgIcon name="utilityButton" />
          </button>

          <button
            v-if="checkbox"
            type="button"
            class="inline-flex items-center justify-center rounded-lg border border-gray-300 bg-transparent px-4 py-2.5 text-base font-semibold text-gray-600 opacity-50 transition-all duration-200 hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 dark:border-gray-600 dark:text-gray-300 dark:focus:ring-offset-gray-900 sm:text-sm"
            disabled
          >
            {{ checkboxButtonPlaceholder }}
          </button>
        </div>
      </div>
      <hr
        v-if="footer || form"
        class="my-5 border-gray-200 dark:border-gray-700"
      />

      <div v-if="footer" class="flex items-center space-x-5">
        <div v-for="(item, index) in footerData">
          <div class="flex items-center space-x-2.5" :key="index">
            <span
              class="inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-full border border-blue-300 bg-blue-100 dark:border-blue-600 dark:bg-blue-500"
            >
              <span
                class="text-xs font-medium uppercase leading-none text-blue-600 dark:text-blue-50"
              >
                {{ item.initials }}
              </span>
            </span>
            <p class="text-sm font-semibold text-gray-900 dark:text-gray-50">
              {{ item.placeholder }}
            </p>
          </div>
        </div>
      </div>

      <div v-if="form">
        <div>
          <label
            for=""
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
          >
            {{ formLabel }}
          </label>
          <div class="mt-1.5">
            <input
              type="text"
              name=""
              id=""
              class="block w-full rounded-lg border border-gray-300 px-3 py-2.5 placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
              :placeholder="formInputPlaceholder"
            />
          </div>
        </div>

        <div class="mt-4 flex items-center justify-between space-x-5">
          <p
            class="min-w-0 flex-1 truncate text-xs font-semibold text-gray-600 dark:text-gray-400"
          >
            {{ longCaptionForForm }}
          </p>

          <div class="flex items-center justify-end space-x-3">
            <Button
              :text="secondaryButtonPlaceholder"
              color="tertiary"
              @click="secondaryButtonFunction"
            />
            <Button
              :text="primaryButtonPlaceholder"
              color="primary"
              @click="primaryButtonFunction"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import SvgIcon from './SvgIcon.vue'
import Avatar from '@/components/Avatar.vue'
export default {
  name: 'Entity',
  components: {
    SvgIcon,
    Button,
    Avatar
  },
  props: {
    placeholder: { type: String, default: 'Placeholder' },
    caption: { type: String, default: '' },
    footerData: {
      type: Array,
      default: [{ placeholder: 'Footer Placeholder', initials: 'AA' }]
    },
    longTextCaption: { type: String, default: 'Long Text Caption' },
    primaryButtonPlaceholder: { type: String, default: 'Placeholder' },
    secondaryButtonPlaceholder: { type: String, default: 'Placeholder' },
    primaryButtonFunction: { type: Function, default: null },
    secondaryButtonFunction: { type: Function, default: null },
    menuSettingFunction: { type: Function, default: null },
    menuLongValueText: { type: String, default: 'Long Value Text' },
    checkboxButtonPlaceholder: { type: String, default: 'Placeholder' },
    utilityFunction: { type: Function, default: null },
    formLabel: { type: String, default: 'Label' },
    formInputPlaceholder: { type: String, default: 'Placeholder' },
    longCaptionForForm: {
      type: String,
      default: 'Long caption for text input'
    },
    initials: { type: String, default: 'AA' },
    removePadding: { type: Boolean },
    imgUrl: { type: String, default: '' },
    entityType: { type: String }
  },
  computed: {
    thumbnail() {
      return this.entityType === 'thumbnail' || this.entityType === 'Thumbnail'
    },
    menu() {
      return this.entityType === 'menu' || this.entityType === 'Menu'
    },
    action() {
      return this.entityType === 'action' || this.entityType === 'Action'
    },
    checkbox() {
      return this.entityType === 'checkbox' || this.entityType === 'Checkbox'
    },
    footer() {
      return this.entityType === 'footer' || this.entityType === 'Footer'
    },
    form() {
      return this.entityType === 'form' || this.entityType === 'Form'
    }
  }
}
</script>
