<template>
  <AutomationHeader title="Users Organization" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <SettingsMenu active-option="usersOrganization" />
    <div class="relative flex-1 border-l border-gray-200 dark:border-gray-700">
      <div class="h-full px-4 py-3 sm:px-5 sm:py-3">
        <userOrganizationRolesSection
          @error="data => $emit('error', data)"
          @inviteSuccess="data => $emit('success', data)"
          @onNewUserClick="onNewUserClick"
        />
      </div>
    </div>
  </main>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import UserOrganizationRolesSection from '@/components/settings/userRole/userOrganizationRolesSection.vue'
import { mapActions } from 'vuex'

export default {
  name: 'userRolesOrganization',
  components: {
    AutomationHeader,
    SettingsMenu,
    UserOrganizationRolesSection
  },
  data() {
    return {
      tab: 'members'
    }
  },
  methods: {
    ...mapActions('settings', ['addNewUserClicked']),

    onNewUserClick() {
      this.addNewUserClicked({ payload: true })
    }
  }
}
</script>

<style scoped></style>
