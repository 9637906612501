import axios from '@/common/axios'

const getTags = async workspaceId => {
  try {
    const response = await axios.get(`/tags/${workspaceId}/tags`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const addTag = async ({ name, description, workspaceId }) => {
  try {
    const response = await axios.post(`/tags/${workspaceId}/tags`, {
      name,
      description
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const updateTag = async ({ name, description, workspaceId, tagId }) => {
  try {
    const response = await axios.put(`tags/${workspaceId}/${tagId}`, {
      name,
      description
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const deleteTag = async ({ workspaceId, tagId }) => {
  try {
    const response = await axios.delete(`/tags/${workspaceId}/${tagId}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export { getTags, addTag, deleteTag, updateTag }
