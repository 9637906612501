<template>
  <checkOutModal v-if="modalActiveCheckout" @close="close" :url="checkoutUrl" />
  <confirmationModal
    @close="close"
    v-if="modalActive"
    :url="cancelPlanUrl"
    @startCancelPlan="startCancelPlan"
  />
  <purchaseAddonModal
    v-if="showAddonsModal"
    @close="showAddonsModal = false"
    @showAddonCheckoutModal="showAddonCheckoutModal"
  />

  <AutomationHeader title="Manage Subscription" />
  <!-- TODO: Fix the overlapping CSS issue -->
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <SettingsMenu active-option="Subscription and Billing" />

    <div
      class="relative flex-1 overflow-y-auto overflow-x-hidden border-l border-gray-200 antialiased dark:border-gray-700"
    >
      <div
        v-if="checkingPaymentStatus"
        class="m-auto mt-10 flex flex-col items-center justify-center gap-2"
      >
        <Loader />
        <span class="text-sm text-gray-400">Checking payment status...</span>
      </div>
      <div v-else class="px-4 pb-5 sm:p-5">
        <paymentMethodsSection
          :planPaymentId="userData?.planPaymentId"
          @planEmit="planEmit"
          @showAddonsModal="showAddonsModal = true"
        />

        <PlanSection
          v-if="
            userData?.planPaymentId === 'free' ||
            userData?.planPaymentId === 'trial'
          "
          @planEmit="planEmit"
          @showCheckoutModal="showCheckoutModal"
          :isAlreadySubscribed="isAlreadySubscribed"
          :status="status"
        />
        <InvoicesSection v-if="show === 'invoices'" />
      </div>
    </div>
  </main>
</template>

<script>
import { checkOut, checkPaymentStatus } from '@/apis/settings/settings-billing'
import Loader from '@/assets/icons/Loader.vue'
import { constants, EVENTS_TO_BE_TRACKED } from '@/common/constants'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import BillingHeader from '@/components/settings/billingHeader.vue'
import checkOutModal from '@/components/settings/checkOutModal.vue'
import confirmationModal from '@/components/settings/confirmationModal.vue'
import InvoicesSection from '@/components/settings/invoicesSection.vue'
import paymentMethodsSection from '@/components/settings/paymentMethodsSection.vue'
import PlanSection from '@/components/settings/planSection.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import { mapState } from 'vuex'
import purchaseAddonModal from '@/components/settings/billing/purchaseAddonModal.vue'
import Button from '@/components/Button.vue'
import { addonsCheckout } from '@/apis/settings/settings-billing'
import { getParticipantByEmail, addParticipant } from '@/apis/affiliate'
import { getPendingAction } from '@/apis/user'

export default {
  name: 'plan',
  components: {
    BillingHeader,
    AutomationHeader,
    SettingsMenu,
    PlanSection,
    InvoicesSection,
    paymentMethodsSection,
    confirmationModal,
    checkOutModal,
    Loader,
    purchaseAddonModal,
    Button
  },
  data() {
    return {
      show: 'plan',
      modalActive: false,
      isAlreadySubscribed: false,
      modalActiveCheckout: false,
      checkoutUrl: null,
      status: null,
      cancelPlanUrl: null,
      checkingPaymentStatus: false,
      limitsBody: null,
      limitsHeader: null,
      limitstableBodyData: null,
      limitsRemainingData: null,
      showAddonsModal: false,
      interval: null,
      pollCount: 0
    }
  },
  computed: {
    ...mapState('user', ['userData'])
  },
  async created() {
    // get the query params
    const urlParams = new URLSearchParams(window.location.search)
    const status = urlParams.get('state')
    if (status === 'succeeded') {
      this.checkingPaymentStatus = true
      const res = await checkPaymentStatus()
      if (
        res.message === 'Subscription is active' &&
        this.userData?.referredBy
      ) {
        const pRes = await getParticipantByEmail(this.userData.email)
        if (pRes.data.name === 'ParticipantNotFound') {
          let data = {
            email: this.userData.email,
            firstName: this.userData.firstName,
            lastName: this.userData.lastName,
            referredBy: this.userData?.referredBy, // The referrer's unique GrowSurf ID.
            referralStatus: 'CREDIT_AWARDED',
            metadata: {
              phoneNumber: this.userData.phone,
              planId: this.userData.planId,
              planPaymentId: this.userData.planPaymentId
            }
          }

          await addParticipant(data)
        }
      }
      window.location.href = window.location.origin + window.location.pathname
    }

    this.longPollPendingActions()
  },
  unmounted() {
    clearInterval(this.interval)
  },
  methods: {
    changeTab(data) {
      if (data === 'cancelSubscription') {
        this.modalActive = true
        return
      }
      this.show = data
    },
    planEmit(data) {
      if (data.type === 'success') {
        this.$emit('success', data.msg)
      } else if (data.type === 'warning') {
        this.$emit('warning', data.msg)
      } else {
        this.$emit('error', data.msg)
      }
    },
    close() {
      this.modalActive = false
      this.modalActiveCheckout = false
    },
    showCheckoutModal(data) {
      amplitudeTrackEvent(
        EVENTS_TO_BE_TRACKED.CLICKED_UPGRADE_PLAN_BUTTON,
        localStorage.getItem('email')
      )
      Chargebee.init({
        site: constants.chargebeeSite
      })
      const cbInstance = Chargebee.getInstance()
      cbInstance.openCheckout({
        hostedPage: async () => {
          const response = await checkOut(data.planId, data.planPaymentId)
          return response.data.hostedPage
        }
      })
    },
    showAddonCheckoutModal(data) {
      amplitudeTrackEvent(
        EVENTS_TO_BE_TRACKED.CLICKED_UPGRADE_PLAN_BUTTON,
        localStorage.getItem('email')
      )
      Chargebee.init({
        site: constants.chargebeeSite
      })
      const cbInstance = Chargebee.getInstance()
      cbInstance.openCheckout({
        hostedPage: async () => {
          const response = await addonsCheckout(data)
          return response.data.hostedPage
        }
      })
    },
    startCancelPlan(data) {
      this.modalActive = false
      this.checkoutUrl = data
      this.modalActiveCheckout = true
      amplitudeTrackEvent(
        EVENTS_TO_BE_TRACKED.CLICKED_CANCEL_SUBSCRIPTION_BUTTON,
        localStorage.getItem('email')
      )
    },
    async longPollPendingActions() {
      this.fetchPendingActions() //inital poll
      this.interval = setInterval(() => {
        this.pollCount++
        if (this.pollCount >= 10) {
          clearInterval(this.interval) // Stop polling after 30 seconds
        } else {
          this.fetchPendingActions()
        }
      }, 3000) // Poll every 3 seconds
    },
    async fetchPendingActions() {
      try {
        const response = await getPendingAction()
        if (response.data.length > 0) {
          clearInterval(this.interval)
          this.$emit('showAllocationModal')
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
  }
}
</script>

<style scoped></style>
