import axios from '@/common/axios'

const getGoogleSpreadSheets = async connectedAccountId => {
  try {
    const response = await axios.get(`/google-sheet/${connectedAccountId}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getGoogleSheets = async (connectedAccountId, spreadsheetId) => {
  try {
    const response = await axios.get(
      `/google-sheet/sheet/${connectedAccountId}/${spreadsheetId}`
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getGoogleHeaders = async (connectedAccountId, spreadsheetId, sheetId) => {
  try {
    const response = await axios.get(
      `/google-sheet/headers/${connectedAccountId}/${spreadsheetId}/${sheetId}`
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const openResultsInGoogleSheets = async data => {
  try {
    const response = await axios.post(`/executions/result/google-sheet`, data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getGoogleToken = async connectedAccountId => {
  try {
    const response = await axios.get(
      `/google-sheet/${connectedAccountId}/token`
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export {
  getGoogleSpreadSheets,
  getGoogleSheets,
  getGoogleHeaders,
  openResultsInGoogleSheets,
  getGoogleToken
}
