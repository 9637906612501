<template>
  <label
    for=""
    class="block text-sm font-medium text-gray-900 dark:text-gray-50"
  >
    {{ text }}
  </label>
</template>

<script>
export default {
  name: 'Label',
  props: {
    text: { type: String }
  }
}
</script>

<style></style>
