<template>
  <SavePromptModal
    v-if="openModal"
    :isEditing="isEditing"
    :editingData="editingData"
    @close="modalClose"
    @error="
      e => {
        $emit('error', e)
      }
    "
    @success="
      e => {
        $emit('success', e)
      }
    "
  />

  <div
    v-if="promptLoading"
    class="absolute left-[50%] top-2 z-30 translate-x-[-50%] w-[150px] rounded-lg bg-blue-50 dark:bg-blue-900/50"
  >
    <div class="p-2">
      <div class="flex items-start">
        <SvgIcon class="h-5 w-5 text-blue-600" name="info" />

        <p
          class="ml-3 mr-auto flex-1 text-sm font-normal text-gray-900 dark:text-gray-50"
        >
          loading
        </p>
        <div class="dot-flashing top-3 right-7"></div>
      </div>
    </div>
  </div>

  <div
    v-if="saveLoading"
    class="absolute left-[50%] top-2 z-30 translate-x-[-50%] rounded-lg bg-gray-200 dark:bg-blue-900/50"
  >
    <div class="py-2 px-4">
      <div class="flex items-start">
        <SvgIcon class="h-5 w-5 text-gray-600" name="info" />

        <p
          class="ml-3 mr-auto text-sm font-normal text-gray-900 dark:text-gray-50"
        >
          Saving...
        </p>
      </div>
    </div>
  </div>

  <AutomationHeader
    :promptTitle="true"
    :promptTitleData="pageTitleData"
    :editableTitle="editableTitle"
    @newPromptTitleData="newPromptTitleData"
  />

  <main
    v-if="aiAccess === false"
    class="border-t overflow-auto h-full border-gray-200 dark:border-gray-700"
  >
    <NoAccess />
  </main>

  <template v-else>
    <div class="flex items-center justify-center flex-1" v-if="loading">
      <Spinner size="large" />
    </div>
    <main
      v-else
      class="border-t overflow-auto h-full border-gray-200 dark:border-gray-700"
    >
      <component
        :key="componentKey"
        :is="currentComp"
        @showTemplates="showTemplates"
        @openPlayground="openPlayground"
        @openClonedPlayground="openClonedPlayground"
        @changeEditableTitleStatus="changeEditableTitleStatus"
        :promptTitleAndDescription="promptTitleAndDescription"
        @apiSuccess="apiSuccess"
        @apiFail="apiFail"
        @promptloader="promptloader"
        @saveloader="saveloader"
        @onAddPromptClick="onAddPromptClick"
      />
    </main>
  </template>
</template>
<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Input from '@/components/Input.vue'
import Spinner from '@/components/Spinner.vue'
import SavePromptModal from '@/components/settings/texauAI/SavePromptModal.vue'
import PromptComponent from '@/components/settings/texauAI/PromptComponent.vue'
import AiTemplates from '@/components/settings/texauAI/AiTemplates.vue'
import { mapState } from 'vuex'
import NoAccess from '@/components/NoAccess.vue'

//api imports
import { getPrompt } from '@/apis/settings/ai'

export default {
  name: 'TexauAI',
  emits: ['success', 'error'],
  components: {
    AutomationHeader,
    Button,
    SvgIcon,
    Input,
    Spinner,
    SavePromptModal,
    builder: PromptComponent,
    templates: AiTemplates,
    NoAccess
  },
  data() {
    return {
      isEditing: false,
      editingData: null,
      openModal: false,
      promptData: null,
      loading: true,
      pageTitleData: { title: '', description: null },
      editableTitle: false,
      currentComp: null,
      promptTitleAndDescription: null,
      promptLoading: false,
      saveLoading: false,
      componentKey: 1
    }
  },
  computed: {
    ...mapState('user', ['aiAccess'])
  },
  watch: {
    $route() {
      if (Object.keys(this.$route.query).length === 0) {
        this.showTemplates()
      }
    }
  },
  async mounted() {
    if (!this.aiAccess) {
      this.pageTitleData = { title: 'AI Prompt Builder', description: null }
    }
    if (this.$route.query.promptId && this.$route.query.cloned === 'true') {
      this.$router.replace({ query: null })
      this.loading = false
      this.showTemplates()
    } else if (this.$route.query.promptId) {
      const response = await getPrompt()
      const promptData = response.data.find(
        data => data._id === this.$route.query.promptId
      )

      this.pageTitleData = {
        title: promptData.title,
        description: promptData.description
      }
      this.promptTitleAndDescription = {
        title: promptData.title,
        description: promptData.description
      }
      this.loading = false
      this.currentComp = 'builder'
    } else {
      this.loading = false
      this.showTemplates()
    }
  },
  methods: {
    async onAddPromptClick() {
      await this.$router.push({ query: null })
      this.pageTitleData = { title: 'My Custom Template', description: null }
      this.editableTitle = true
      this.currentComp = 'builder'
      this.componentKey += 1
    },
    showTemplates() {
      this.currentComp = 'templates'
      this.pageTitleData = { title: 'AI Prompt Builder', description: null }
      this.editableTitle = false
    },
    async openPlayground(data) {
      await this.$router.push({ query: { promptId: data._id, cloned: false } })
      this.pageTitleData = { title: data.title, description: data.description }
      this.promptTitleAndDescription = {
        title: data.title,
        description: data.description
      }
      this.currentComp = 'builder'
    },
    async openClonedPlayground(data) {
      await this.$router.push({ query: { promptId: data._id, cloned: true } })
      this.pageTitleData = {
        title: `${data.title} Cloned `,
        description: data.description
      }
      this.promptTitleAndDescription = {
        title: `${data.title} Cloned `,
        description: data.description
      }
      this.editableTitle = true
      this.currentComp = 'builder'
    },

    newPromptTitleData(data) {
      this.promptTitleAndDescription = data
      this.pageTitleData = data
    },
    modalClose() {
      this.openModal = false
      ;(this.isEditing = false), (this.editingData = null), this.getPromptData()
    },
    changeEditableTitleStatus(data) {
      this.editableTitle = data
    },
    apiSuccess(data) {
      this.$emit('success', data)
    },
    apiFail(data) {
      this.$emit('error', data)
    },
    promptloader(data) {
      this.promptLoading = data
    },
    saveloader(data) {
      this.saveLoading = data
    }
  }
}
</script>
<style scoped>
.dot-flashing {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #1c80cf;
  color: #1c80cf;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #1c80cf;
  color: #1c80cf;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #1c80cf;
  color: #1c80cf;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #1c80cf;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}
</style>
