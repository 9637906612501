<template>
  <div>
    <div class="flex items-center space-x-2">
      <img
        v-if="node.logoUrl"
        :src="node.logoUrl"
        alt="logo"
        class="h-8 w-8 shrink-0 rounded-full object-cover"
      />
      <Label :text="`${node.order ? `${node.order}. ` : ''}${node.label}`" />
    </div>
    <div
      class="flex flex-col gap-2 mt-2"
      v-if="getMatchingVariables.length > 0"
    >
      <div class="text-sm">Recommended:</div>
      <div class="flex justify-between">
        <div class="flex flex-wrap gap-4" v-for="data of getMatchingVariables">
          <Badge
            :text="data.label"
            color="primary"
            class="cursor-pointer"
            @click="$emit('variable-selected', node.id, data)"
          />
        </div>
        <Badge
          :icon="expandedOutputs ? 'close' : 'plus'"
          size="small"
          class="cursor-pointer"
          text=""
          @click="expandedOutputs = !expandedOutputs"
        />
      </div>
    </div>
    <div class="my-4 flex flex-wrap gap-4">
      <Popper
        v-if="expandedOutputs || getMatchingVariables.length === 0"
        placement="top"
        hover
        :open-delay="200"
        v-for="output of node.output.slice(
          0,
          getMatchingVariables.length === 0 && !expandedOutputs ? 4 : undefined
        )"
      >
        <Badge
          :text="output.label"
          color="primary"
          class="cursor-pointer"
          @click="$emit('variable-selected', node.id, output)"
        />
        <template #content>
          <div
            v-if="output.description"
            class="flex max-w-md items-start gap-1 rounded bg-white p-2 text-xs shadow"
          >
            <svg-icon
              name="tooltip"
              class="h-4 w-4 text-blue-500 dark:text-blue-400"
            />
            <p>{{ output.description }}</p>
          </div>
        </template>
      </Popper>

      <Badge
        v-if="node.output.length > 4 && getMatchingVariables.length === 0"
        :icon="expandedOutputs ? 'close' : 'plus'"
        size="small"
        class="cursor-pointer"
        text=""
        @click="expandedOutputs = !expandedOutputs"
      />
    </div>
  </div>
</template>
<script setup>
import Badge from '@/components/Badge.vue'
import Label from '@/components/Label.vue'
import { ref, computed } from 'vue'
import Popper from 'vue3-popper'

const props = defineProps({
  node: Object,
  outputNameToMatch: String
})
defineEmits(['variable-selected'])
const expandedOutputs = ref(false)
const getMatchingVariables = computed(() => {
  const matching = []
  if (props.outputNameToMatch) {
    for (const data of props.node.output) {
      data.name === props.outputNameToMatch && matching.push(data)
    }
  }

  return matching
})
</script>
