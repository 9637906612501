<template>
  <div
    v-show="columns === null"
    class="flex h-full w-full items-center justify-center"
  >
    <Spinner size="large" />
  </div>

  <div class="flex min-w-0 px-4 pt-2 flex-1 flex-col gap-4 overflow-y-auto">
    <AutomationOutputTable
      v-if="output"
      @goBack="prevButtonClick"
      :showGoBack="true"
      :columnsData="columns"
      :outputData="output"
      :filtersOptions="filtersOptions"
      :nodeId="this.$route.query.nodeId"
      :showLoadingText="showLoadingText"
      @error="error"
      @success="e => $emit('success', e)"
      :total="automationTotal"
      @loadNextBatch="loadNextBatch"
      @refetch="fetchOutput(0, $event)"
    />
  </div>
</template>

<script>
import { fetchOutputForTable } from '@/common/functions/fetchOutputForTable'
import { getWorkflowExecution } from '@/apis/workflows'
import AutomationOutputTable from '../AutomationOutputTable.vue'
import Button from '../Button.vue'
import Spinner from '../Spinner.vue'

export default {
  name: 'AutomationOutput',
  async beforeMount() {
    await this.fetchOutput()
    if (this.$route.query.source !== 'input') {
      this.fetchPollingData()
    }
  },
  data() {
    return {
      columns: null,
      output: null,
      automationTotal: null,
      filtersOptions: null,
      showLoadingText: false
    }
  },

  methods: {
    async fetchOutput(start, manipulationConfig) {
      try {
        const { columns, output, total, filtersOptions } =
          await fetchOutputForTable(start, manipulationConfig)

        this.columns = columns
        this.output = output
        this.automationTotal = total
        this.filtersOptions = filtersOptions
      } catch (error) {
        console.log(error.message)
        if (error.message === 'canceled') return
        this.$emit('error', 'Unable to fetch automation result')
      }
    },
    async fetchPollingData() {
      try {
        let executionObj
        //get the status of selectedExecutionNode execution
        const response = await getWorkflowExecution(
          this.$route.query.workflowId
        )

        executionObj = response.data.find(
          item => item._id === this.$route.query.executionId
        )

        if (executionObj?.status && executionObj.status === 'running') {
          this.showLoadingText = true

          const { output } = await fetchOutputForTable(undefined, undefined, 0)
          this.showLoadingText = false
          //replace the old results
          this.output = output
          //call the function again after 5 sec
          setTimeout(await this.fetchPollingData(), 5000)
        } else if (executionObj?.status === 'failed') {
          emit('error', executionObj.statusReason)
        } else {
          return
        }
      } catch (error) {
        console.log(error)
      }
    },

    async loadNextBatch(data) {
      await this.fetchOutput(data)
      let div = document.getElementsByClassName('p-datatable-wrapper')[0]
      div.scrollTop = 0
    },

    prevButtonClick() {
      this.$emit('nextButtonClick', {
        comp: 'run-comp',
        highlight: 4,
        optionalSidebarType: 'run',
        optionalSidebarItem: 'start',
        optionalSidebarSearch: true
      })
    },
    error(data) {
      this.$emit('error', data)
    }
  },
  components: { Button, Spinner, AutomationOutputTable }
}
</script>

<style></style>
