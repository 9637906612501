<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <div class="flex min-h-screen justify-between">
      <OnboardingLeftColumn />
      <div class="flex-1 bg-white dark:bg-gray-900">
        <div class="grid h-full items-start justify-center">
          <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
            <div class="flex h-full max-w-lg flex-col">
              <a href="#" title="TexAu" class="flex items-end justify-center">
                <img
                  class="h-12 w-auto xl:h-14"
                  src="@/assets/images/logo.png"
                  alt="TexAu logo"
                />
              </a>

              <!-- Welcome Message and Instructions -->
              <div class="mt-12 grid h-full place-items-center">
                <div class="text-center">
                  <Heading text="Welcome to TexAu!" />

                  <!-- Conditional messages based on user registration data -->
                  <div v-if="getRegistrationData.isV1User">
                    <p class="mt-4 text-lg text-gray-600 dark:text-gray-400">
                      Your account has been successfully migrated from V1! We
                      will now proceed with the onboarding process and set up
                      your first automation.
                    </p>
                    <p class="text-lg font-bold text-blue-600">
                      You can check your plan details under “View your Limits”
                      button in the header.
                    </p>
                  </div>
                  <div v-else>
                    <p class="mt-4 text-lg text-gray-600 dark:text-gray-400">
                      Your account has been successfully created! We will now
                      proceed with the onboarding process and set up your first
                      automation.
                    </p>
                    <p class="text-lg mt-2 font-bold text-orange-600">
                      You can verify your email and phone number later, before
                      you start running automations or trial.
                    </p>
                  </div>
                </div>

                <!-- Get Started Button -->
                <Button
                  :showLoader="isLoading"
                  size="large"
                  @click="onButtonClick"
                  type="submit"
                  text="Get Started !"
                  rightIcon="right-arrow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingLeftColumn from '@/components/onboarding/OnboardingLeftColumn.vue'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'
import Heading from '@/components/onboarding/Heading.vue'
import Spinner from '@/components/Spinner.vue'
import ResendOtpCounter from '@/components/onboarding/ResendOtpCounter.vue'
import { login } from '@/apis/onboarding'
import { mapGetters, mapActions } from 'vuex'
import arrow from '@/assets/images/onboarding/illustrations/right-arrow-icon.svg'

export default {
  name: 'confirmPhoneOtp',
  components: {
    OnboardingLeftColumn,
    Input,
    Button,
    Heading,
    Spinner,
    ResendOtpCounter
  },
  data() {
    return {
      code: '',
      email: null,
      isLoading: false,
      arrow,
      schema: {
        Code: 'required|code_min:4'
      }
    }
  },
  computed: {
    // Map Vuex getters for registration data
    ...mapGetters('registration', ['getRegistrationData'])
  },
  methods: {
    // Map Vuex actions
    ...mapActions('registration', [
      'registerFinished',
      'registerStarted',
      'loginSuccess'
    ]),

    /**
     * Handles the "Get Started" button click during the onboarding process.
     * - Completes the registration process and logs the user in if credentials are available.
     * - If login fails, redirects the user to the login page.
     * - If no password is available, informs the user that they can log in later.
     *
     * @async
     * @function onButtonClick
     */
    async onButtonClick() {
      this.isLoading = true
      try {
        const { email, password } = this.getRegistrationData

        // Finish registration process
        this.registerFinished()

        if (password) {
          // Login the user internally
          const loginResponse = await login(email, password)

          if (loginResponse.success) {
            // Successful login
            localStorage.setItem('email', email)
            this.loginSuccess({ payload: loginResponse.data })
            this.$router.go() // Refresh the page to reflect login changes
          } else {
            // Login failed, redirect to login page
            await this.$router.push('/login')
            throw new Error(loginResponse.message)
          }
        } else {
          // No password available, allow user to login later
          this.$emit('success', 'You can now log in with your credentials')
          await this.$router.push('/login')
        }
      } catch (error) {
        // Handle errors
        this.$emit('error', error.message || 'An error occurred')
      } finally {
        this.isLoading = false // Ensure loading state is reset
      }
    }
  }
}
</script>
