<template>
  <AutomationHeader title="Users" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <SettingsMenu active-option="users" />
    <div class="relative flex-1 border-l border-gray-200 dark:border-gray-700">
      <div class="h-full px-4 py-5 sm:p-5">
        <nav class="flex justify-center space-x-4" aria-label="Tabs">
          <a
            href="#"
            title=""
            class="rounded-lg px-3 py-2 text-sm font-medium transition-all duration-200"
            :class="[
              {
                'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-50':
                  tab === 'members'
              },
              {
                'text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
                  tab !== 'members'
              }
            ]"
            aria-current="page"
            @click.prevent="tab = 'members'"
          >
            Members
          </a>

          <a
            href="#"
            title=""
            class="rounded-lg px-3 py-2 text-sm font-medium transition-all duration-200"
            :class="[
              {
                'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-50':
                  tab === 'sentInvites'
              },
              {
                'text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
                  tab !== 'sentInvites'
              }
            ]"
            @click.prevent="tab = 'sentInvites'"
          >
            Sent Invitation
          </a>

          <a
            href="#"
            title=""
            class="rounded-lg px-3 py-2 text-sm font-medium transition-all duration-200"
            :class="[
              {
                'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-50':
                  tab === 'pendingInvites'
              },
              {
                'text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
                  tab !== 'pendingInvites'
              }
            ]"
            @click.prevent="tab = 'pendingInvites'"
          >
            Pending Invitation
          </a>
        </nav>
        <UserRolesSection
          v-if="tab === 'members'"
          @error="data => $emit('error', data)"
          @inviteSuccess="data => $emit('success', data)"
          @onNewUserClick="onNewUserClick"
        />

        <SentInvites
          v-else-if="tab === 'sentInvites'"
          @error="data => $emit('error', data)"
          @success="data => $emit('success', data)"
        />

        <PendingInvites
          v-else-if="tab === 'pendingInvites'"
          @error="data => $emit('error', data)"
        />
      </div>
    </div>
  </main>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import UserRolesSection from '@/components/settings/userRole/userRolesSection.vue'
import ToastContainer from '@/components/ToastContainer.vue'
import { mapActions } from 'vuex'
import PendingInvites from '@/components/settings/userRole/PendingInvites.vue'
import SentInvites from '@/components/settings/userRole/SentInvites.vue'

export default {
  name: 'userRoles',
  components: {
    AutomationHeader,
    SettingsMenu,
    UserRolesSection,
    ToastContainer,
    PendingInvites,
    SentInvites
  },
  data() {
    return {
      tab: 'members'
    }
  },
  methods: {
    ...mapActions('settings', ['addNewUserClicked']),

    onNewUserClick() {
      this.addNewUserClicked({ payload: true })
    }
  }
}
</script>

<style scoped></style>
