<template>
  <section class="flex flex-1 border-t border-gray-200 dark:border-gray-700 antialiased text-gray-900 bg-white dark:bg-gray-900 dark:text-gray-50">
    <div class="flex items-center justify-center flex-1 max-w-2xl px-8 py-6 mx-auto">
      <div class="text-center">
        <img class="w-auto mx-auto h-36" src="@/assets/images/illustrations/head.png" alt="">

        <p class="mt-12 text-2xl font-bold text-gray-900 dark:text-gray-50">
          Ooops...
        </p>
        <p class="mt-6 text-xl font-medium text-gray-600 dark:text-gray-400">
          It looks TexAu encountered an issue, an alert has been sent to our development team. Please allow a
          delay of 24 hours,
          support will come back to you shortly.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "errorSection"
}
</script>

<style scoped>

</style>
