import axios from '@/common/axios'

export const toggleDetermingInputStructure = async (
  workflowId,
  determining
) => {
  try {
    const response = await axios.post(
      `/webhooks/${workflowId}/determine-input-structure`,
      {
        determining
      }
    )
    return response.data
  } catch (error) {
    return error?.response?.data
  }
}

export const toggleWebhookActive = async (workflowId, isActive, auth = {}) => {
  try {
    const response = await axios.post(`/webhooks/${workflowId}/toggle-active`, {
      isActive,
      auth
    })
    return response.data
  } catch (error) {
    return error?.response?.data
  }
}
