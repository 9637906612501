import { UTILITY_TYPES } from '@/common/constants'

const fetchVariablesOnNodeAdditions = async (
  changes,
  fetchVariables,
  setDynamicOutputs,
  setRouterAdded
) => {
  checkIfRouterIsAdded(changes, setRouterAdded)
  const nodes = transformChangesToNodes(changes)
  const uniquePlatformOperationObject = getUniquePlatforms(nodes)
  await Promise.all(
    Object.keys(uniquePlatformOperationObject).map(platformOperationId =>
      fetchVariables({
        platformOperationId,
        platformId: uniquePlatformOperationObject[platformOperationId]
      })
    )
  )
  const addedNodes = {}
  nodes.forEach(node => {
    addedNodes[node.id] = node
    if (node.dynamicOutputs) {
      setDynamicOutputs({
        nodeId: node.id,
        dynamicOutputs: node.dynamicOutputs
      })
    }
  })
}

const transformChangesToNodes = changes => {
  return changes
    .filter(change => change.type === 'add')
    .map(change => ({
      id: change.item.id,
      platformId: change.item.data.automationData.platformId,
      platformOperationId: change.item.data.automationData.platformOperationId,
      dynamicOutputs: change.item.data.dynamicOutputs
    }))
    .filter(change => change.platformId)
}

const getUniquePlatforms = nodes => {
  const platformOperations = {}
  nodes.forEach(
    node => (platformOperations[node.platformOperationId] = node.platformId)
  )
  return platformOperations
}

const checkIfRouterIsAdded = (changes, setRouterAdded) => {
  if (
    changes
      .filter(change => change.type === 'add')
      .findIndex(
        change =>
          change.item.data.automationData.utilityType === UTILITY_TYPES.ROUTER
      ) > -1
  ) {
    setRouterAdded(true)
  }
}

export { fetchVariablesOnNodeAdditions }
