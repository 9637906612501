<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <!-- START WRAPPER -->
    <div class="flex min-h-screen justify-between">
      <!-- START LEFT COLUMN -->
      <OnboardingLeftColumn />
      <!-- END LEFT COLUMN -->

      <!-- START RIGHT COLUMN -->
      <div
        v-if="!showTotp && !showNewPassword"
        class="flex-1 bg-white dark:bg-gray-900"
      >
        <div class="grid h-full w-full items-start justify-center">
          <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
            <div class="flex h-full max-w-lg flex-col">
              <a class="" href="#" title="TexAu">
                <CompanyLogo />
              </a>

              <div class="mt-12 grid h-full place-items-center">
                <div>
                  <div class="text-center">
                    <Heading text="Get Started with TexAu" />
                  </div>

                  <vee-form
                    :validation-schema="schema"
                    class="mt-10"
                    @submit="submitCreate()"
                  >
                    <div class="space-y-5">
                      <div>
                        <label
                          class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                          for=""
                        >
                          Email Address
                        </label>
                        <div class="relative mt-1.5">
                          <Input
                            v-model="login.email"
                            name="Email"
                            text="john.doe@company.com"
                            type="email"
                          />

                          <p class="mt-2 text-sm text-red-600">
                            <ErrorMessage name="Email" />
                          </p>
                        </div>
                      </div>

                      <div>
                        <label
                          class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                          for=""
                        >
                          Password
                        </label>
                        <div class="mt-1.5">
                          <Input
                            v-model="login.password"
                            name="Password"
                            text="Enter Password"
                            :type="passwordFieldType"
                            :rightIcon="
                              passwordFieldType === 'text' ? 'eye-off' : 'eye'
                            "
                            rightIconMouse="cursor-pointer"
                            @onRightIconClick="onRightIconClick"
                          />
                          <p class="mt-2 text-sm text-red-600">
                            <ErrorMessage name="Password" />
                          </p>
                        </div>
                      </div>

                      <div>
                        <Button
                          :showLoader="isLoading"
                          class="w-full"
                          text="Log in"
                          type="submit"
                        />
                      </div>
                    </div>
                  </vee-form>
                  <div class="flex items-center justify-between">
                    <div class="mt-4">
                      <label
                        class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                        for=""
                      >
                        Don't have an account yet ?
                      </label>

                      <label
                        class="block cursor-pointer text-sm font-medium text-blue-900 dark:text-blue-400"
                        for=""
                        @click="this.$router.push('/register')"
                      >
                        Create an account
                      </label>
                    </div>
                    <div class="mt-4">
                      <label
                        class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                        for=""
                      >
                        Having trouble logging in ?
                      </label>
                      <div class="flex items-center justify-between space-x-1">
                        <label
                          class="block cursor-pointer text-sm font-medium text-blue-900 dark:text-blue-400"
                          for=""
                          @click="$router.push('/forgot-password')"
                        >
                          Forgot Password ?
                        </label>
                      </div>
                    </div>
                  </div>

                  <OnboardingStep1Footer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END RIGHT COLUMN -->

      <Totp v-if="showTotp" />
      <forcePasswordReset
        :challengeData="challengeData"
        @backToLogin="showNewPassword = false"
        @error="e => $emit('error', e)"
        @success="e => $emit('success', e)"
        v-if="showNewPassword"
      />
    </div>
    <!-- END WRAPPER -->
  </div>
</template>

<script>
import { login } from '@/apis/onboarding'
import logo from '@/assets/images/onboarding/google-logo.svg'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import OnboardingStep1Footer from '@/components/onboarding/OnboardingStep1Footer.vue'
import { mapActions } from 'vuex'
import Heading from '../../components/onboarding/Heading.vue'
import OnboardingLeftColumn from '../../components/onboarding/OnboardingLeftColumn.vue'
import CompanyLogo from '@/components/onboarding/CompanyLogo.vue'
import Totp from '@/views/onboarding/totp.vue'
import forcePasswordReset from '@/components/settings/forcePasswordReset.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'

export default {
  name: 'login',
  components: {
    Input,
    Button,
    OnboardingStep1Footer,
    OnboardingLeftColumn,
    Heading,
    Totp,
    SvgIcon,
    CompanyLogo,
    forcePasswordReset
  },

  data() {
    return {
      showTotp: false,
      showNewPassword: false,
      login: {
        email: '',
        password: ''
      },

      passwordFieldType: 'password',
      isLoading: false,
      logo,
      schema: {
        Email: 'required|email|min:3|max:100',
        Password: 'required|password_min:8'
      },
      challengeData: null
    }
  },
  methods: {
    ...mapActions('registration', ['loginStart']),
    ...mapActions('registration', ['loginSuccess']),

    onCreateAccount() {
      this.$router.push('/register')
    },

    onForgotPassword() {
      this.$router.push('/forgot-password')
    },

    async submitCreate() {
      this.loginStart()
      this.isLoading = true
      const { email, password } = new Proxy(this.login, {})
      const response = await login(email, password)
      if (response['success']) {
        localStorage.setItem('email', email)
        amplitudeTrackEvent('Login', localStorage.getItem('email'), {
          LoginMethod: 'Email'
        })
        this.loginSuccess({ payload: response.data })
        if (response.data.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.challengeData = { email: this.login.email, ...response.data }
          this.showNewPassword = true
        } else if (response.data.challengeName === 'SOFTWARE_TOKEN_MFA') {
          this.showTotp = true
        } else {
          this.$router.go()
        }
      } else {
        this.$emit('error', response.message)
      }
      this.isLoading = false
    },
    onRightIconClick() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    }
  }
}
</script>

<style scoped></style>
