import { testProxy } from '@/apis/proxy'

export const testProxyProtocol = async data => {
  const protocols = ['http', 'https']
  for (const protocol of protocols) {
    const tempData = {
      ip: `${protocol}://${data.ip}`,
      port: data.port,
      username: data.username,
      password: data.password,
      name: data.name
    }
    const response = await testProxy(tempData)
    if (response['success']) {
      return { check: true, response: response.data, data: tempData }
    } else {
      return { check: false, message: response.message }
    }
  }
  return { check: false }
}
