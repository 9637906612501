<template>
  <div>
    <div class="my-4 flex flex-wrap gap-4">
      <Badge
        v-for="tag of personalisedTags.slice(0, expanded ? undefined : 4)"
        :text="tag.label"
        color="primary"
        class="cursor-pointer rounded bg-indigo-100 px-1 text-sm font-semibold text-indigo-600 dark:bg-indigo-900 dark:text-indigo-50"
        @click="$emit('selected', tag)"
      />
      <Badge
        v-if="personalisedTags.length > 4"
        :icon="expanded ? 'close' : 'plus'"
        size="small"
        class="cursor-pointer"
        text=""
        @click="expanded = !expanded"
      />
    </div>
  </div>
</template>
<script setup>
import Badge from '@/components/Badge.vue'
import { ref } from 'vue'

defineProps({
  personalisedTags: Array
})
defineEmits(['selected'])
const expanded = ref(false)
</script>
