export default {
  platformData: {},
  automationStoreData: {},
  automationInputs: {},
  storeInputData: false,
  workflowIdCreated: false,
  executionId: null,
  AutomationStatus: null,
  connectedAccountId: null,
  connectedAccountIdGoogleSheets: null,
  secondNodeId: null,
  validationStatus: false,
  automationNameText: null,
  delayAutomationNaming: false,
  automationOutputMode: {
    outputMode: 'append',
    isDedupeResult: true
  },
  iterationDelay: null,
  automationScheduleData: null,
  automationDesktopData: null,
  csvHeaders: null
}
