import axios from '@/common/axios'

const getWorkflowSuggestion = async (start=0, limit=50) => {
    try {
        const response = await axios.get(`/public-workflows?start=${start}&limit=${limit}`);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

const createWorkflowFromSuggestion = async (body) => {
    try {
        const response = await axios.post(`/public-workflows/clone`, body);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

export {
    getWorkflowSuggestion,
    createWorkflowFromSuggestion,
}
