import { mergeAttributes, Node } from '@tiptap/core'

export const FunctionStart = Node.create({
  name: 'function-start',
  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ node }) {
        return `${node.attrs.name}(`
      }
    }
  },
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,
  addAttributes() {
    return {
      name: {
        default: null,
        parseHTML: element => element.getAttribute('data-name'),
        renderHTML: attributes => {
          if (!attributes.name) {
            return {}
          }

          return {
            'data-name': attributes.name
          }
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`
      }
    ]
  },
  renderText({ node }) {
    return `%%${node.attrs.name}(`
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-type': this.name,
        class:
          'inline rounded bg-purple-100 px-1 text-sm font-semibold text-purple-600 dark:bg-purple-900 dark:text-purple-50',
        style: 'margin-right: 1px'
      }),
      this.options.renderLabel({
        options: this.options,
        node
      })
    ]
  }
})
export const FunctionParamSeparator = Node.create({
  name: 'function-param-separator',
  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ node }) {
        return `;`
      }
    }
  },
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,
  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`
      }
    ]
  },
  renderText({ node }) {
    return `$;`
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-type': this.name,
        class:
          'inline rounded bg-purple-100 px-1 text-sm font-semibold text-purple-600 dark:bg-purple-900 dark:text-purple-50',
        style: 'margin: 0px 1px'
      }),
      this.options.renderLabel({
        options: this.options,
        node
      })
    ]
  }
})
export const FunctionEnd = Node.create({
  name: 'function-end',
  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ node }) {
        return `)`
      }
    }
  },
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,
  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`
      }
    ]
  },
  renderText() {
    return `)%%`
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-type': this.name,
        class:
          'inline rounded bg-purple-100 px-1 text-sm font-semibold text-purple-600 dark:bg-purple-900 dark:text-purple-50',
        style: 'margin-left: 1px'
      }),
      this.options.renderLabel({
        options: this.options,
        node
      })
    ]
  }
})
