<template>
  <div>
    <div class="flex items-center space-x-2 capitalize">
      <Label :text="category.category" />
    </div>
    <div class="my-4 flex flex-wrap gap-4">
      <Popper
        placement="top"
        hover
        :open-delay="200"
        v-for="rule of category.rules.slice(0, expanded ? undefined : 4)"
      >
        <Badge
          :text="rule.name + '()'"
          color="primary"
          class="cursor-pointer rounded bg-purple-100 px-1 text-sm font-semibold text-purple-600 dark:bg-purple-900 dark:text-purple-50"
          @click="$emit('selected', category.category, rule)"
        />
        <template #content>
          <div
            class="flex max-w-md items-start gap-1 rounded bg-white p-2 text-xs shadow"
          >
            <svg-icon
              name="tooltip"
              class="h-4 w-4 text-purple-500 dark:text-purple-400"
            />
            <p>{{ rule.description }}</p>
          </div>
        </template>
      </Popper>
      <Badge
        v-if="category.rules.length > 4"
        :icon="expanded ? 'close' : 'plus'"
        size="small"
        class="cursor-pointer"
        text=""
        @click="expanded = !expanded"
      />
    </div>
  </div>
</template>
<script setup>
import Badge from '@/components/Badge.vue'
import Label from '@/components/Label.vue'
import { ref } from 'vue'
import Popper from 'vue3-popper'

defineProps({
  category: Object
})
defineEmits(['selected'])
const expanded = ref(false)
</script>
