<template>
  <!-- START WRAPPER -->
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Purchase New Proxy
          </p>

          <div>
            <Button
              @click="close"
              icon="close-1"
              class="!p-1.5"
              size="small"
              color="tertiary"
            />
          </div>
        </div>
      </div>

      <div class="px-4 py-5 sm:px-6 h-[400px]">
        <div v-if="loader" class="flex h-full items-center justify-center">
          <Spinner size="large" />
        </div>
        <div v-else class="flex flex-col gap-5">
          <Select
            v-model="selectedAccount"
            :options="accountsOptions"
            label="Select Account For The Proxy"
            :include-search="true"
            :leftImageUrl="platformIcon"
            @change="setIcon()"
          />
          <div class="flex items-end justify-end">
            <Button
              :show-loader="buttonLoader"
              @click="confirm($event, index)"
              text="Buy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Button from '@/components/Button.vue'
import Select from '@/components/Select.vue'
import Input from '@/components/Input.vue'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { getAllConnectedAccounts } from '@/apis/connectedAccounts'
import { buyProxy } from '@/apis/proxy'

export default {
  name: 'ProxyPurchaseModal',

  components: {
    Button,
    Select,
    Input,
    Spinner,
    SvgIcon
  },

  props: ['modalActive'],

  data() {
    return {
      accountsOptions: null,
      accountsData: null,
      selectedAccount: null,
      buttonLoader: false,
      platformIcon: null,
      loader: true
    }
  },
  mounted() {
    this.getAllAccounts()
  },
  methods: {
    async getAllAccounts() {
      try {
        const response = await getAllConnectedAccounts(0, 50, true)
        if (response['success']) {
          this.accountsOptions = response.data.map(account => ({
            value: account._id,
            label: `${account.name} : ${account.platform.label} `
          }))
          this.accountsData = response.data
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('apiFail', error)
      } finally {
        this.loader = false
      }
    },
    confirm(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: 'Do you want to proceed with this account ?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: '!bg-blue-600 !border-blue-600 !rounded-lg',
        accept: () => {
          this.confirmPurchase()
        }
      })
    },
    async confirmPurchase() {
      try {
        if (!this.selectedAccount) {
          throw 'Please Select an Account First'
        }
        this.buttonLoader = true
        const response = await buyProxy({
          connectedAccountId: this.selectedAccount
        })
        if (response['success']) {
          this.$emit('refresh')
          this.close()
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('apiFail', error)
      } finally {
        this.buttonLoader = false
      }
    },
    close() {
      this.$emit('close')
    },
    setIcon() {
      const id = this.accountsData.find(
        item => item._id === this.selectedAccount
      )
      this.platformIcon = id.platform.logoUrl
    }
  }
}
</script>

<style></style>
