<template>
  <nav class="flex justify-center space-x-4 antialiased" aria-label="Tabs">
    <TabSingleMenuOption optionName="Email and Password" :isActive="isEmailActive"/>
    <TabSingleMenuOption optionName="2 Factor Authentication" :isActive="isTwoFactorActive"/>
  </nav>
</template>

<script>
import TabSingleMenuOption from "@/components/settings/tabSingleMenuOption.vue";

export default {
  name: "securityHeader",
  props: {
    activeOption:{
      type: String, default: 'email and password', required: true
    }
  },
  components:{
    TabSingleMenuOption,
  },
  computed: {
    isEmailActive(){
      return this.activeOption === 'email and password'
    },
    isTwoFactorActive(){
      return this.activeOption === '2 factor authentication'
    }
  }
}
</script>

<style scoped>

</style>
