<template>
  <div
    class="overflow-hidden rounded-lg border border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-900"
  >
    <div class="px-4 py-5">
      <div class="flex items-center space-x-6">
        <div class="flex min-w-0 flex-1 items-center">
          <Avatar
            :srcAndStatus="[
              {
                src:
                  userProfileIconUrl.split(',')[0] ===
                    'data:image/gif;base64' || userProfileIconUrl === ''
                    ? imgUrl
                    : userProfileIconUrl
              }
            ]"
          />
          <div class="ml-4 min-w-0 flex-1">
            <div class="flex gap-1">
              <p
                class="truncate text-base font-bold text-gray-900 dark:text-gray-50"
              >
                {{ name }}
              </p>
              <div
                v-if="showLiPremium()"
                class="flex items-center justify-center"
              >
                <Popper hover>
                  <div
                    class="h-5 w-5 rounded-md relative overflow-hidden border-1 border-white"
                  >
                    <img
                      class="object-cover"
                      src="https://assets-of-v2.s3.amazonaws.com/platforms/logos/li_premium_sq.svg"
                      alt=""
                    />
                  </div>
                  <template #content>
                    <div class="w-max max-w-lg rounded bg-blue-50 p-2 text-sm">
                      LinkedIn Premium
                    </div>
                  </template>
                </Popper>
              </div>
            </div>
            <p
              class="truncate text-sm font-medium text-gray-600 dark:text-gray-400"
            >
              {{ desc }}
            </p>
          </div>
        </div>

        <div class="flex -space-x-2 overflow-hidden">
          <img
            v-for="img in platformIconUrl"
            class="h-8 w-8 shrink-0 ring-2 ring-white rounded-full object-cover"
            :src="img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgUrl from '@/assets/images/default-avatar-dark.svg'
import Avatar from '../Avatar.vue'
import { constants } from '@/common/constants'
import Popper from 'vue3-popper'
export default {
  name: 'OnboardingCards',
  components: {
    Avatar,
    Popper
  },
  props: {
    userProfileIconUrl: { type: String },
    name: { type: String },
    desc: { type: String },
    platformIconUrl: { type: String },
    platformId: {},
    isPremium: {}
  },
  methods: {
    showLiPremium(data) {
      if (
        this.platformId.includes(constants.LINKEDIN_PLATFORM_ID) &&
        this.isPremium === true
      ) {
        return true
      } else return false
    }
  }
}
</script>

<style></style>
