<template>
  <workspaceDeleteModal
    v-if="deleteModal"
    :forceDeleteId="forceDeleteId"
    :currentWorkspace="currWorkspaceId === forceDeleteId.workspaceId"
    @close="modalClose()"
    @deleteSuccess="deleteSuccess"
    @error="e => $emit('error', e)"
  />
  <AutomationHeader title="Workspace" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <SettingsMenu active-option="workspace" />

    <div
      class="relative flex-1 border-l border-gray-200 antialiased dark:border-gray-700"
    >
      <div class="flex items-center px-4 sm:px-5 pt-2 justify-end">
        <Button
          text="Add New"
          leftIcon="plus"
          class="h-[33px]"
          @click="this.$router.push('/settings-workspace/create-new-workspace')"
        />
      </div>
      <div class="px-4 py-5 sm:p-5 h-[90%] overflow-y-scroll" :key="reRender">
        <ul role="list" class="divide-y divide-gray-100">
          <li
            v-for="(data, index) of workspaceData"
            :key="data.workspaceId"
            class="flex h-[72px] justify-between gap-x-6 py-5"
          >
            <div class="flex min-w-0 gap-x-4">
              <div class="min-w-0 flex-auto">
                <p
                  @input="handleInput"
                  :contenteditable="index === editable"
                  :class="[
                    { 'rounded-md  border-2 px-2  ': index === editable }
                  ]"
                  class="text-sm font-semibold leading-6 text-gray-900"
                >
                  {{ data.name }}
                </p>
                <p class="mt-1 truncate text-xs leading-5 text-gray-500">
                  {{
                    data.workspaceId == currWorkspaceId
                      ? 'Current Workspace'
                      : null
                  }}
                </p>
              </div>
            </div>

            <div
              v-if="index === editable"
              class="flex items-center justify-center gap-3 px-2"
            >
              <SvgIcon
                @click="
                  renameWorkspace(data.workspaceId, data.orgUserId, index)
                "
                class="h-4 w-4 cursor-pointer text-blue-600 dark:text-white"
                name="TickIcon"
              />
              <SvgIcon
                @click="
                  e => {
                    e.stopPropagation()
                    editable = null
                    workspaceRenameText = null
                    reRender++
                  }
                "
                class="h-4 w-4 cursor-pointer text-blue-600 dark:text-white"
                name="close"
              />
            </div>
            <div v-else>
              <div
                v-if="data.isSelfWorkspace"
                class="flex items-center justify-center gap-3 px-2"
              >
                <Popper hover>
                  <SvgIcon
                    @click="
                      e => {
                        e.stopPropagation()
                        editable = index
                        workspaceRenameText = data.name
                      }
                    "
                    class="h-5 w-5 cursor-pointer text-blue-600 dark:text-white"
                    name="edit"
                  />
                  <template #content>
                    <div class="w-max max-w-lg rounded bg-blue-50 p-2 text-sm">
                      Edit Workspace Name
                    </div>
                  </template>
                </Popper>
                <Popper hover>
                  <div
                    @click="
                      showDeleteModal(
                        data.workspaceId,
                        data.orgUserId,
                        index,
                        data.isSelfWorkspace
                      )
                    "
                    class="cursor-pointer text-red-600"
                  >
                    <SvgIcon class="h-5 w-5 dark:text-white" name="delete" />
                  </div>
                  <template #content>
                    <div class="w-max max-w-lg rounded bg-blue-50 p-2 text-sm">
                      Delete Workspace
                    </div>
                  </template>
                </Popper>
              </div>
              <div class="h-5 w-5 cursor-pointer text-blue-600" v-else>
                <Popper hover>
                  <svg
                    @click="
                      showDeleteModal(
                        data.workspaceId,
                        data.orgUserId,
                        index,
                        data.isSelfWorkspace
                      )
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-logout"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
                    />
                    <path d="M9 12h12l-3 -3" />
                    <path d="M18 15l3 -3" />
                  </svg>
                  <template #content>
                    <div
                      class="w-max max-w-lg rounded bg-blue-50 p-2 text-black text-sm"
                    >
                      Leave Workspace
                    </div>
                  </template>
                </Popper>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>
<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Spinner from '@/components/Spinner.vue'
import Button from '@/components/Button.vue'
import workspaceDeleteModal from '@/components/settings/workspaceDeleteModal.vue'
import { mapActions, mapState } from 'vuex'
import { updateWorkspaceName } from '@/apis/workspace'
import Popper from 'vue3-popper'
import { getPendingAction } from '@/apis/user'
export default {
  name: 'workspaceSettings',
  components: {
    AutomationHeader,
    SettingsMenu,
    SvgIcon,
    Spinner,
    Button,
    workspaceDeleteModal,
    Popper
  },
  data() {
    return {
      workspaceData: null,
      loading: true,
      noWorkspace: false,
      currWorkspaceId: null,
      loggingOut: false,
      editable: null,
      deleteIndex: null,
      workspaceRenameText: null,
      deleteModal: false,
      forceDeleteId: null,
      reRender: 0
    }
  },
  computed: {
    ...mapState('user', ['userWorkspaceData'])
  },
  async mounted() {
    this.currWorkspaceId = window.$cookies.get('workspaceId')

    this.workspaceData = this.userWorkspaceData

    if (this.workspaceData.length === 0) {
      this.noWorkspace = true
    }
  },
  methods: {
    ...mapActions('user', ['saveWorkspaceData']),
    handleInput(e) {
      this.workspaceRenameText = e.target.innerText
    },

    showDeleteModal(id, orgid, index, isSelfWorkspace) {
      if (this.workspaceData.length === 1) {
        this.$emit(
          'error',
          'Sorry, but you cannot delete/leave the last workspace you are currently on.'
        )
        return
      }

      this.forceDeleteId = {
        workspaceId: id,
        orgUserId: orgid,
        isSelfWorkspace: isSelfWorkspace
      }
      this.deleteIndex = index
      this.deleteModal = true
    },
    modalClose() {
      this.deleteModal = false
      this.forceDeleteId = null
      this.deleteIndex = null
    },
    async renameWorkspace(workspaceId, orgUserId, index) {
      try {
        const response = await updateWorkspaceName(workspaceId, orgUserId, {
          workspaceName: this.workspaceRenameText
        })
        if (response['success']) {
          this.workspaceData[index].name = this.workspaceRenameText
          this.editable = null
          this.workspaceRenameText = null
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },
    deleteSuccess(data) {
      if (this.currWorkspaceId === this.forceDeleteId.workspaceId) {
        if (this.deleteIndex < this.workspaceData.length - 1) {
          window.$cookies.set(
            'workspaceId',
            this.workspaceData[this.deleteIndex + 1].workspaceId,
            -1,
            '/'
          )
          window.$cookies.set(
            'orgUserId',
            this.workspaceData[this.deleteIndex + 1].orgUserId,
            -1,
            '/'
          )
          this.currWorkspaceId = window.$cookies.get('workspaceId')
        } else {
          window.$cookies.set(
            'workspaceId',
            this.workspaceData[0].workspaceId,
            -1,
            '/'
          )
          window.$cookies.set(
            'orgUserId',
            this.workspaceData[0].orgUserId,
            -1,
            '/'
          )
          this.currWorkspaceId = window.$cookies.get('workspaceId')
        }
      }

      this.workspaceData = this.workspaceData.filter(
        item => item.workspaceId !== this.forceDeleteId.workspaceId
      )
      this.saveWorkspaceData({ payload: this.workspaceData })
      this.forceDeleteId = null
      this.deleteModal = false
      this.$emit('success', data)
      this.checkForPendingActions()
    },
    async checkForPendingActions() {
      const response = await getPendingAction()
      if (response.data.length > 0) {
        this.$router.go()
      }
    }
  }
}
</script>
