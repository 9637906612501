<template>
  <div
    v-if="tabs.length > 0"
    class="dropdown-border max-w-[660px] bg-white px-4"
  >
    <Tabs
      :tabs="tabs"
      :selectedTab="selectedTab"
      color="primary"
      @tab-selected="selectedTab = $event"
      class="fixed h-10 overflow-hidden bg-white"
    />
    <div class="mt-10 max-h-64 min-w-[600px] overflow-y-auto overflow-x-hidden">
      <OutputTab
        v-if="selectedTabText === 'Inputs'"
        :previousNodes="previousNodes"
        :name="name"
        @variable-selected="
          (nodeId, output) => $emit('variable-selected', nodeId, output)
        "
      />
      <FunctionTab
        v-if="selectedTabText === 'Functions'"
        @function-selected="
          (category, rule) => $emit('function-selected', category, rule)
        "
      />
      <TagsTab
        v-if="selectedTabText === 'Personalisation Tags'"
        :personalisedTags="personalisedTags"
        @selected="$emit('tag-selected', $event)"
      />
    </div>
  </div>
</template>

<style>
.dropdown-border {
  border: 0.5px solid #bec0c5;
  /* Drop Shadow - 5% */

  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
}
</style>

<script setup>
import Tabs from '@/components/Tabs.vue'
import { computed, ref } from 'vue'
import FunctionTab from './functionTab.vue'
import OutputTab from './outputTab.vue'
import TagsTab from './tagsTab.vue'
import { useStore } from 'vuex'

const store = useStore()
const devStatus = computed(() => store._state.data.user.userData.developer)

const props = defineProps({
  previousNodes: {
    type: Array,
    required: true
  },
  personalisedTags: {
    type: Array,
    required: false
  },
  name: String
})
const emits = defineEmits([
  'variable-selected',
  'function-selected',
  'tag-selected'
])
const tabs = ref([
  {
    text: 'Functions',
    selectedClass: 'border-purple-500 text-purple-500'
  }
])

if (!devStatus.value) {
  tabs.value = tabs.value.filter(val => val.text !== 'Functions')
}

const selectedTab = ref(0)
const selectedTabText = computed(() =>
  tabs.value.length > 0 ? tabs.value[selectedTab.value].text : undefined
)

const webhook = ref(store.getters['workflow/getWebhook'])
if (props.previousNodes?.length > 0 || webhook) {
  tabs.value.unshift({
    text: 'Inputs',
    selectedClass: 'border-blue-500 text-blue-500'
  })
}
// dynamically add tags tab if personalised tags are present
if (props.personalisedTags && props.personalisedTags.length > 0) {
  tabs.value.unshift({
    text: 'Personalisation Tags',
    selectedClass: 'border-indigo-500 text-indigo-500'
  })
}
</script>
