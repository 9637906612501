import { constants } from '@/common/constants'
import { store } from '@/store/store'
import { H } from 'highlight.run'
import Button from 'primevue/button'
import Column from 'primevue/column'
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import ConfirmPopup from 'primevue/confirmpopup'
import DataTable from 'primevue/datatable'
import Skeleton from 'primevue/skeleton'
import InputText from 'primevue/inputtext'
import { createApp } from 'vue'
import VueCookies from 'vue-cookies'
import { vue3Debounce } from 'vue-debounce'
import JsonViewer from 'vue-json-viewer'
import App from './App.vue'
import VeeValidatePlugin from './includes/validation'
import { router } from './router/router'
import * as Sentry from '@sentry/vue'
import { createGtm } from '@gtm-support/vue-gtm'
import './tailwind.css'

import 'primeicons/primeicons.css' //icons
import 'primevue/resources/primevue.min.css' //core css
import 'primevue/resources/themes/saga-blue/theme.css' //theme
import SvgIconVue from './components/SvgIcon.vue'

const app = createApp(App)
H.init(constants.highlight, {
  inlineImages: false
})
Sentry.init({
  app,
  dsn: constants.environment === 'local' ? '' : constants.sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration()
  ],
  environment: constants.environment,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://v2-prod.texau.com/',
    'https://v2-beta.texau.com/'
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  hooks: ['create', 'mount', 'unmount'],
  trackComponents: true
})
app.use(VueCookies)
app.use(router)
app.use(store)
app.use(VeeValidatePlugin)
app.use(PrimeVue)
app.use(ConfirmationService)
app.use(JsonViewer)
app.use(
  createGtm({
    id: constants.gTagId,
    vueRouter: router
  })
)

app.directive('debounce', vue3Debounce({}))

app.component('DataTable', DataTable)
app.component('Skeleton', Skeleton)
app.component('ConfirmPopup', ConfirmPopup)
app.component('Column', Column)
app.component('InputText', InputText)
app.component('PrimeVueButton', Button)
app.component('SvgIcon', SvgIconVue)
app.mount('#app')
