<template>
  <div>
    <div class="shrink-0 mb-2">
      <div
        @dragover.prevent
        @drop="handleDrop"
        class="flex items-center justify-center rounded-lg border-2 border-dashed border-blue-300 bg-blue-50 px-6 py-8 dark:border-blue-700 dark:bg-blue-900/50"
      >
        <div class="space-y-1 text-center">
          <div class="">
            <p class="mt-3 text-base font-semibold text-blue-600 sm:text-sm">
              Drag and drop a CSV file here
            </p>
            <p class="mt-3 text-base font-semibold text-blue-600 sm:text-sm">
              OR
            </p>
            <label
              for="file-upload"
              class="relative mt-1 inline-flex cursor-pointer items-center justify-center rounded-lg border border-transparent bg-blue-600 px-4 py-2.5 text-base font-semibold text-white transition-all duration-200 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900 sm:text-sm"
            >
              <SvgIcon class="h-5 w-5" name="upload" />
              <span> Browse files </span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                class="sr-only"
                accept=".csv"
                v-on:change="handleFileUpload"
              />
            </label>

            <div class="m-4 text-blue-500 flex items-center gap-2">
              <svg
                class="shrink-0 h-4 w-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  opacity="0.12"
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  fill="currentColor"
                />
                <path
                  d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p class="text-base font-semibold text-blue-600 sm:text-sm">
                NOTE: Select CSV with header.
              </p>
            </div>

            <p class="mt-8 text-sm font-medium text-blue-400">
              {{ fileName }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <ValidationForm
      :platformInputs="platformInputs"
      :inputs="inputs"
      :previousNodes="[]"
      class="grid-cols-1"
      @input-blur="onInputBlur"
    />
  </div>
</template>

<script setup>
import { uploadCsv } from '@/apis/automation-store/csv'
import { ref, watch } from 'vue'
import ValidationForm from '@/components/ValidationForm.vue'

const props = defineProps({
  inputs: {},
  platformInputs: {},
  selectedNode: {}
})
const file = ref()
const loading = ref(false)
const csvUrl = ref(null)
const fileName = ref(props.inputs?.fileName)

watch(file, n => {
  fileName.value = n.name
})

const $emit = defineEmits(['error', 'file-uploaded'])

const handleDrop = event => {
  event.preventDefault()
  file.value = event.dataTransfer.files[0]
  uploadCsvFile()
}

const handleFileUpload = async e => {
  file.value = e.target.files[0]
  uploadCsvFile()
}

const getCSVHeaders = async () => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsText(file.value)
    reader.onload = () => {
      const headerRow = reader.result.split(/[\r\n]+/)[0] // contains the file content as a string
      const headers = headerRow.split(',')
      resolve(headers)
    }
    reader.onerror = () => {
      $emit('error', reader.error)
    }
  })
}

const uploadCsvFile = async () => {
  const formData = new FormData()
  formData.append('filename', file.value)
  const headers = await getCSVHeaders()
  try {
    $emit('saving', true)
    const response = await uploadCsv(formData)
    csvUrl.value = response.data.csvUrl
    $emit('file-uploaded', {
      inputs: { csvUrl: response.data.csvUrl, fileName: file.value.name },
      dynamicOutputs: headers
    })
  } catch (error) {
    console.log(error)
  }
  $emit('saving', false)
}

const onInputBlur = async data => {
  let headers
  if (!props.selectedNode?.dynamicOutputs) {
    headers = await getCSVHeaders()
  }

  $emit('file-uploaded', {
    inputs: {
      csvUrl: csvUrl.value ?? props.inputs.csvUrl,
      fileName: file.value?.name ?? props.inputs.fileName,
      noOfRowsToProcess: data?.noOfRowsToProcess,
      noOfRowsToSkip: data?.noOfRowsToSkip
    },
    dynamicOutputs: headers ?? props.selectedNode.dynamicOutputs
  })
}
</script>
