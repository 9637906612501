<template>
  <AutomationHeader title="Message Templates" />
  <section class="flex flex-1 border-t border-gray-200 dark:border-gray-700">
    <SettingsMenu active-option="message templates" />
    <MessageTemplatesSection />
  </section>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import MessageTemplatesSection from '@/components/settings/messageTemplatesSection.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'

export default {
  name: 'messageTemplates',
  components: {
    AutomationHeader,
    SettingsMenu,
    MessageTemplatesSection
  }
}
</script>
