<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <!-- START WRAPPER -->
    <div class="flex min-h-screen justify-between">
      <!-- START LEFT COLUMN -->
      <OnboardingLeftColumn />
      <!-- END LEFT COLUMN -->

      <!-- START RIGHT COLUMN -->
      <div class="flex-1 bg-white dark:bg-gray-900">
        <div class="grid h-full items-start justify-center">
          <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
            <div class="flex h-full max-w-lg flex-col">
              <a href="#" title="TexAu" class="">
                <img
                  class="mx-auto h-12 w-auto xl:h-14"
                  src="@/assets/images/logo-alt.png"
                  alt="TexAu logo"
                />
              </a>

              <div class="mt-12 grid h-full place-items-center">
                <div>
                  <div class="text-center">
                    <Heading text="Welcome to TexAu!" />
                    <p class="mt-4 text-base text-gray-600 dark:text-gray-400">
                      Now, it's getting serious! Please enter the code you
                      received on your
                      <span
                        class="text-base font-bold text-blue-600 dark:text-blue-400"
                        >E-mail</span
                      >.
                    </p>
                  </div>

                  <vee-form
                    @submit="submitCreate()"
                    class="mt-10"
                    :validation-schema="schema"
                  >
                    <div class="sm:flex sm:items-end sm:space-x-5">
                      <div class="flex-1">
                        <label
                          for=""
                          class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                        >
                          Code
                        </label>
                        <div class="mt-1.5">
                          <Input
                            v-model="code"
                            text="Enter code"
                            type="number"
                            name="code"
                          />
                        </div>
                      </div>

                      <div class="mt-4 shrink-0 sm:mt-0">
                        <Button
                          :showLoader="isLoading"
                          type="submit"
                          text="Jump to the other side"
                          rightIcon="right-arrow"
                        />
                      </div>
                    </div>
                    <p class="mt-2 text-sm text-red-600">
                      <ErrorMessage name="code" />
                    </p>
                  </vee-form>

                  <ResendOtpCounter
                    resend-otp-for-mail
                    @success="data => $emit('success', data)"
                    @error="data => $emit('error', data)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END RIGHT COLUMN -->
    </div>
    <!-- END WRAPPER -->
  </div>
</template>

<script>
import OnboardingLeftColumn from '@/components/onboarding/OnboardingLeftColumn.vue'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'
import Heading from '@/components/onboarding/Heading.vue'
import arrow from '@/assets/images/onboarding/illustrations/right-arrow-icon.svg'
import { confirmMail, setupBaseUser } from '@/apis/onboarding'
import UserProfile from '@/common/userWrapper'
import Spinner from '@/components/Spinner.vue'
import ResendOtpCounter from '@/components/onboarding/ResendOtpCounter.vue'

export default {
  name: 'confirmMailOtp',
  components: {
    OnboardingLeftColumn,
    Input,
    Button,
    Heading,
    Spinner,
    ResendOtpCounter
  },
  data() {
    return {
      code: '',
      isLoading: false,
      arrow,
      schema: {
        code: 'required|min:6|max:6'
      }
    }
  },
  computed: {},
  methods: {
    updateMailVerifiedCookies() {
      let user_ = UserProfile.getUser()
      user_['isEmailVerified'] = true
      UserProfile.setUser(user_)
    },

    async submitCreate() {
      this.isLoading = true
      const response = await confirmMail(this.code)
      if (response['success']) {
        this.updateMailVerifiedCookies()
        const response = await setupBaseUser(this.code)
        if (response['success']) {
          window.$cookies.set(
            'organisationId',
            response.data.organisationId,
            -1,
            '/'
          )
          window.$cookies.set('orgUserId', response.data['orgUserId'], -1, '/')
          this.isLoading = false
          await this.$router.push('/onboarding/create-workspace')
        } else {
          this.$emit('error', 'Some Error Occured')
        }
      } else {
        this.$emit('error', response.message)
        this.isLoading = false
      }
    }
  }
}
</script>

<style></style>
