<template>
  <AutomationHeader title="Schedule Templates" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <div
      class="h-full w-[280px] shrink-0 overflow-y-auto 2xl:w-full 2xl:max-w-xs"
    >
      <SettingsMenu active-option="schedule templates" />
    </div>
    <ScheduleTemplateSection />
  </main>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import ScheduleTemplateSection from '@/components/settings/scheduleTemplates/scheduleTemplateSection.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'

export default {
  name: 'scheduleTemplates',
  components: {
    AutomationHeader,
    SettingsMenu,
    ScheduleTemplateSection
  }
}
</script>

<style scoped></style>
