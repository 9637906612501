<!--
  Select template folder component
  ------------
  Props:
    - folderList: Array of objects with the following properties:
      - name: String, name of folder
      - isSelect: Boolean, if it is selected
 -->
<template>
  <!-- START WRAPPER -->
  <div class="grid min-h-screen px-4 py-12 bg-gray-600 sm:px-6 lg:px-8 dark:bg-gray-700 place-items-center">

    <div class="relative w-full max-w-lg mx-auto overflow-hidden bg-white rounded-xl dark:bg-gray-900">
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <div>
            <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
              Select Template Folder
            </p>
            <p class="mt-1 text-base font-medium text-gray-600 dark:text-gray-400">
              6 items
            </p>
          </div>

          <div>
            <Button icon="closeButton" class="p-1.5 text-gray-600 transition-all duration-200 bg-white border border-gray-200 rounded-lg dark:text-gray-400 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:bg-gray-900 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900" />
          </div>
        </div>
      </div>

      <div class="px-4 pb-5 sm:px-6 sm:pb-6">
        <div class="flow-root mt-4">
          <div class="-my-4 divide-y divide-gray-200 dark:divide-gray-700">
            <div v-for="(item, index) in folderList" class="flex items-center justify-between py-4">
              <SvgIcon name="folder"/>
              <p class="ml-6 mr-auto text-sm font-medium text-gray-900 dark:text-gray-50">
                {{ item.name }}
              </p>
              <div>
                <label for="" class="sr-only">
                  Label
                </label>
                <Checkboxes :checked="item.isSelect" :key="index"/>
                </div>
            </div>
          </div>
        </div>

        <div class="mt-10">
          <Button :text="isSelected ? 'Save Template' : 'Create Folder'" :leftIcon="!isSelected ? 'plus' : ''"
                  class="inline-flex items-center justify-center px-4 py-2.5 sm:text-sm text-base font-semibold transition-all duration-200 border rounded-lg"
                  :color="!isSelected ? 'secondary' : 'primary'"
          />
        </div>
      </div>
    </div>

  </div>
  <!-- END WRAPPER -->
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";
import Button from '@/components/Button.vue';
import Checkboxes from "@/components/Checkboxes.vue";

export default {
  name: "selectTemplateFolder",
  props: {
    folderList: {type: Array, default: [
        {
          name: 'LinkedIn Campaign Spring 2022',
          isSelect: true
        },
        {
          name: 'Email Campaign',
        },
        {
          name: 'IG Messages',
        },
        {
          name: 'Twitter Journalists Music',
        },
        {
          name: 'LinkedIn Arman Sales',
        },
        {
          name: 'LinkedIn Arman Sales',
        }
      ]}
  },
  components: {
    SvgIcon,
    Button,
    Checkboxes,
  },
  computed:{
    isSelected(){
      return this.folderList.some(function(el) {
        return el['isSelect'] === true;
      })
    }
  }
}
</script>

<style scoped>

</style>
