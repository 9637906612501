<template>
  <div class="mx-auto flex justify-center px-8 py-6">
    <div class="text-center">
      <p class="text-2xl font-bold text-gray-900 dark:text-gray-50">
        Select Your Input Source
      </p>

      <div class="mt-8 flex items-start gap-[76px]">
        <div class="w-[235px]">
          <div
            class="transform rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:-translate-y-1 hover:border-blue-600 hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:hover:border-blue-500"
          >
            <div class="px-4 py-5 sm:p-6">
              <p
                class="text-base font-semibold text-gray-900 dark:text-gray-50"
              >
                Single Input
              </p>
              <img
                class="mx-auto mt-8 h-16 w-auto"
                src="../../assets/images/url-icon.svg"
                alt=""
              />
              <Button
                class="mt-6"
                text="Use Single Input"
                rightIcon="right-arrow"
                @click="onClick('url')"
                :disabled="loading"
              />
            </div>
          </div>
          <p class="text-center font-[300] text-[16px] mt-[20px]">
            Manually type in the required inputs.
          </p>
        </div>

        <div class="w-[235px]">
          <div
            class="transform rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:-translate-y-1 hover:border-blue-600 hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:hover:border-blue-500"
          >
            <div class="px-4 py-5 sm:p-6">
              <p
                class="text-base font-semibold text-gray-900 dark:text-gray-50"
              >
                Sheet
              </p>
              <img
                class="mx-auto mt-8 h-16 w-auto"
                src="@/assets/images/sheet-file-icon.svg"
                alt=""
              />
              <Button
                class="mt-6"
                rightIcon="right-arrow"
                text="Google Sheets"
                :disabled="loading"
                @click="onClick('sheet')"
              />
            </div>
          </div>
          <p class="text-center font-[300] text-[16px] mt-[20px]">
            Use inputs stored on a Google Sheet.
          </p>
        </div>

        <div class="w-[235px]">
          <div
            class="transform rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:-translate-y-1 hover:border-blue-600 hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:hover:border-blue-500"
          >
            <div class="px-4 py-5 sm:p-6">
              <p
                class="text-base font-semibold text-gray-900 dark:text-gray-50"
              >
                CSV
              </p>
              <img
                class="mx-auto mt-8 h-16 w-auto"
                src="@/assets/images/csv-file-icon.svg"
                alt=""
              />
              <Button
                class="mt-6"
                text="Use CSV File"
                rightIcon="right-arrow"
                @click="onClick('csv')"
                :disabled="loading"
              />
            </div>
          </div>
          <p class="text-center font-[300] text-[16px] mt-[20px]">
            Use inputs stored on a CSV File.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../Button.vue'
import Popper from 'vue3-popper'
import { mapState } from 'vuex'

export default {
  name: 'AutomationInputPicker',

  props: {
    platformOperationId: { type: String },
    loading: {}
  },

  components: { Button, Popper },
  computed: {
    ...mapState('automationStore', ['platformData'])
  },
  methods: {
    // pass the platformOperationId and input source as queries in URL and open the automation execution page
    async onClick(type) {
      if (type === 'url') {
        await this.$router.push({
          path: `/automation-store/workflow`,
          query: {
            operationId: this.platformOperationId,
            source: 'input'
          }
        })
      }
      if (type === 'sheet') {
        await this.$router.push({
          path: `/automation-store/workflow`,
          query: {
            operationId: this.platformOperationId,
            source: 'sheet'
          }
        })
      }
      if (type === 'csv') {
        await this.$router.push({
          path: `/automation-store/workflow`,
          query: {
            operationId: this.platformOperationId,
            source: 'csv'
          }
        })
      }
    }
  }
}
</script>

<style></style>
