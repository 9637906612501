<template>
  <div
    class="h-full overflow-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <!-- START WRAPPER -->
    <div class="flex h-full flex-col">
      <div class="flex min-h-0 flex-1 overflow-hidden">
        <!-- START MAIN CONTENT -->
        <main class="flex min-w-0 flex-1 flex-col overflow-y-auto">
          <AutomationHeader
            title="Yellow Page Leads Collector"
            icon="yellow-pages"
          />

          <section
            class="flex flex-1 border-t border-gray-200 dark:border-gray-700"
          >
            <OptionalSidebar :data="optionalSidebarData" item="center" />
            <div
              class="relative flex flex-1 border-l border-gray-200 dark:border-gray-700"
            >
              <div
                class="relative flex flex-1 flex-col justify-between px-4 py-5 sm:p-6"
              >
                <div class="flex-1">
                  <div class="grid grid-cols-7 items-center">
                    <InputOutputCard
                      type="input"
                      :icon="inputOutputCardIcon"
                      :lists="inputList"
                    />

                    <InputOutputCardConnector />

                    <InputOutputCard
                      type="output"
                      :icon="inputOutputCardIcon"
                      :lists="outputList"
                    />
                  </div>
                </div>

                <div class="mt-auto flex items-center justify-end">
                  <Button text="Run" rightIcon="right-arrow" />
                </div>
              </div>
            </div>
          </section>
        </main>
        <!-- END MAIN CONTENT -->
      </div>
    </div>
    <!-- END WRAPPER -->
  </div>
</template>

<script>
import AutomationHeader from '../../components/automationStore/AutomationHeader.vue'
import OptionalSidebar from '../../components/automationStore/OptionalSidebar.vue'
import Button from '../../components/Button.vue'
import InputOutputCard from '../../components/InputOutputCard.vue'
import InputOutputCardConnector from '../../components/InputOutputCardConnector.vue'
export default {
  name: 'page2',
  components: {
    AutomationHeader,
    OptionalSidebar,
    InputOutputCard,
    InputOutputCardConnector,
    Button
  },
  data() {
    return {
      optionalSidebarData: [
        'Extract Yellow Pages business listings',
        'Collect emails and phone numbers'
      ],
      inputList: ['Profile URL'],
      outputList: ['Company', 'Email', 'Phone', 'Address'],
      inputOutputCardIcon: 'yellow-pages-icon'
    }
  }
}
</script>

<style></style>
