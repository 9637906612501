<template>
  <!-- START WRAPPER -->
  <div
    class="grid min-h-screen place-items-center bg-gray-600 px-4 py-12 dark:bg-gray-700 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto w-full max-w-5xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Company
          </p>

          <div class="flex items-center justify-end space-x-4">
            <Button
              icon="closeButton"
              class="rounded-lg border border-gray-200 bg-white p-1.5 text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900"
            />
          </div>
        </div>
      </div>

      <div class="px-4 pb-5 sm:px-6 sm:pb-6">
        <div class="flex items-start">
          <div class="w-56 shrink-0">
            <div
              class="flex justify-center rounded-lg border-2 border-dashed border-blue-300 bg-blue-50 px-6 py-8 dark:border-blue-700 dark:bg-blue-900/50"
            >
              <div class="space-y-1 text-center">
                <div>
                  <label
                    for="file-upload"
                    class="relative inline-flex cursor-pointer items-center justify-center rounded-lg border border-transparent bg-blue-600 px-4 py-2.5 text-base font-semibold text-white transition-all duration-200 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900 sm:text-sm"
                  >
                    <SvgIcon class="h-5 w-5" name="upload" />
                    <span> Upload a file </span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      class="sr-only"
                    />
                  </label>
                  <p
                    class="mt-3 text-base font-semibold text-blue-600 sm:text-sm"
                  >
                    or drag and drop
                  </p>

                  <p class="mt-8 text-sm font-medium text-blue-400">
                    (Square,max.300 px)
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="ml-16 flex-1">
            <div class="grid grid-cols-3 gap-x-6 gap-y-5">
              <div class="col-span-3">
                <Input
                  type="text"
                  text="Your company legal name"
                  label="Company Organization Name"
                  label-class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                />
              </div>

              <div class="col-span-3">
                <Input
                  type="text"
                  text="Client company address"
                  label="Address"
                  label-class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                />
              </div>

              <div class="col-span-3">
                <Input
                  type="email"
                  text="Client company email"
                  label="Organization Contact Email"
                  label-class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                />
              </div>

              <div>
                <Input
                  type="text"
                  text="Postal code"
                  label="Postal code"
                  label-class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                />
              </div>

              <div>
                <Select text="Select State" options="" label="State/Region" />
              </div>

              <div>
                <Select text="Select Country" options="" label="Country" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="px-4 py-5 sm:px-6">
        <div class="relative flex items-center justify-between">
          <div></div>

          <div>
            <Button text="Save" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import Button from '@/components/Button.vue'

export default {
  name: 'addClient',
  components: {
    SvgIcon,
    Input,
    Select,
    Button
  }
}
</script>

<style scoped></style>
