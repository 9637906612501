<template>
  <div>
    <div class="flex flex-row-reverse">
      <Checkboxes label="Custom Mapping" v-model="customMapping" />
    </div>
    <div v-if="customMapping" class="mt-4">
      <GoogleColumnMapping
        :outputs="outputs"
        :modelValue="modelValue"
        @updateMapping="test"
      />
    </div>

    <div v-else class="flex items-center flex-col gap-2">
      <OutputCheckboxes
        v-for="node of outputs"
        :key="node.id"
        :node="node"
        :modelValue="
          selectedOutputByNode[node.id]?.map(output => output.value) || []
        "
        @update:modelValue="updateValue($event, node.id)"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, unref, ref, onMounted } from 'vue'
import OutputCheckboxes from './OutputCheckboxes.vue'
import { getNodeOutputs } from './workflowBuilder/helper'
import Checkboxes from './Checkboxes.vue'
import GoogleColumnMapping from './GoogleColumnMapping.vue'

const customMapping = ref(false)

const props = defineProps({
  previousNodes: {
    type: Array,
    required: true
  },
  modelValue: {
    type: String,
    required: true,
    default: '[]'
  }
})

onMounted(() => {
  let obj = JSON.parse(props.modelValue)

  customMapping.value = obj?.customMapping || false
})

const emits = defineEmits(['update:modelValue', 'blur'])

const selectedOutputByNode = computed(() => {
  const selectedOutputByNode = {}

  let obj = JSON.parse(props.modelValue)
  let objdata
  if (Array.isArray(obj)) {
    objdata = obj
  } else {
    if (obj?.customMapping) return []
    objdata = obj?.mappingData || []
  }

  for (const output of objdata) {
    const { value } = output
    const [nodeId] = value.replace('{{', '').split('.')
    if (!selectedOutputByNode[nodeId]) {
      selectedOutputByNode[nodeId] = []
    }
    selectedOutputByNode[nodeId].push(output)
  }
  return selectedOutputByNode
})

const outputs = computed(() => {
  const outputs = []
  for (const node of props.previousNodes) {
    const nodeOutput = getNodeOutputs(node)
    if (nodeOutput.length > 0) {
      outputs.push({ ...node, output: nodeOutput })
    }
  }
  return outputs
})

const updateValue = (value, nodeId) => {
  let mapping = []
  for (const node of props.previousNodes) {
    const nodeSelectedOutput =
      nodeId === node.id ? value : unref(selectedOutputByNode.value[node.id])
    if (nodeSelectedOutput) {
      mapping = mapping.concat(nodeSelectedOutput)
    }
  }
  emits(
    'update:modelValue',
    JSON.stringify({ customMapping: false, mappingData: mapping })
  )
  emits('blur', JSON.stringify({ customMapping: false, mappingData: mapping }))
}

const test = data => {
  emits(
    'update:modelValue',
    JSON.stringify({ customMapping: true, mappingData: data })
  )
  emits('blur', JSON.stringify({ customMapping: true, mappingData: data }))
}
</script>
