<template>
  <div class="flex">
    <div class="grid flex-1 grid-cols-3 gap-4">
      <WorkflowInput
        label="Variable"
        name="field"
        type="text"
        :previousNodes="previousNodes"
        :modelValue="modelValue.field"
        @update:modelValue="
          field => $emit('update:modelValue', { ...modelValue, field })
        "
        labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
        modal-class="w-[604px]"
      />
      <Select
        label="Rule"
        :modelValue="modelValue.operator"
        @update:modelValue="
          operator => $emit('update:modelValue', { ...modelValue, operator })
        "
        :options="options"
        labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
      />
      <WorkflowInput
        v-if="variableType !== 'boolean'"
        label="Value"
        type="text"
        name="values"
        :previousNodes="previousNodes"
        :modelValue="modelValue.values[0]"
        @update:modelValue="
          value =>
            $emit('update:modelValue', { ...modelValue, values: [value] })
        "
        labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
        modal-class="w-[604px]"
      />
    </div>
    <div
      @click="$emit('delete-condition')"
      class="mx-2 mt-5 flex cursor-pointer items-center justify-center text-red-600"
    >
      <SvgIcon class="h-5 w-5 shrink-0" name="delete" />
    </div>
  </div>
</template>

<script setup>
import Select from '@/components/Select.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import WorkflowInput from '../../customInput/WorkflowInput.vue'

const store = useStore()
const variableType = ref('string')

// format the filter rule types to be used in select dropdown
const options = computed(() => {
  let rules = []
  const inputString = props.modelValue.field

  // Extract the content between '{{' and '}}'
  const extractedContent = inputString.substring(2, inputString.length - 2)

  // Split the content by '.' and get the last part
  const parts = extractedContent.split('.')

  //name of the variable
  const finalValue = parts[parts.length - 1]

  let variableObj

  try {
    //loop hashmap and get the object of the varible containing its type
    for (const [key, value] of store.state.workflow.variablesHashMap) {
      const matchingObject = value.find(obj => obj.name === finalValue)
      if (matchingObject) {
        variableObj = matchingObject
      }
    }

    variableType.value = variableObj?.type || 'string'

    //loop the filter rules and set rules matching the variable type
    for (
      let index = 0;
      index < store.state.settings.metadata.filterRuleTypes.length;
      index++
    ) {
      if (variableObj?.type) {
        //exception for text since filter category for text and string is same
        //and no filter category for text is provided
        if (variableObj.type === 'text') {
          if (
            store.state.settings.metadata.filterRuleTypes[index].category ===
            'string'
          ) {
            rules = setValueForRules(index)
            break
          }
        }
        // set generic filter rule of type array
        else if (variableObj.type === 'array') {
          if (
            store.state.settings.metadata.filterRuleTypes[index].category ===
            'generic'
          ) {
            rules = setValueForRules(index)
            break
          }
        }
        //check if variable type matches the filter rule
        else if (
          variableObj.type ===
          store.state.settings.metadata.filterRuleTypes[index].category
        ) {
          rules = setValueForRules(index)
          break
        }
      } else {
        //no variable is provided yet set all filter rules
        rules.push(...setValueForRules(index))
      }
    }
  } catch (error) {
    //fallback set default filters
    store.state.settings.metadata.filterRuleTypes.forEach(filter => {
      filter.rules.forEach(rule => {
        rules.push({
          label: `(${filter.category}) ${rule.label}`,
          value: rule.name
        })
      })
    })
  }

  return rules
})

function setValueForRules(index) {
  const rules = []
  store.state.settings.metadata.filterRuleTypes[index].rules.forEach(rule => {
    rules.push({
      label: `(${store.state.settings.metadata.filterRuleTypes[index].category}) ${rule.label}`,
      value: rule.name
    })
  })
  return rules
}

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  previousNodes: {
    type: Array,
    required: true
  }
})

defineEmits(['update:modelValue', 'delete-condition'])
</script>
