<template>
  <div>
    <div class="grid grid-cols-3 gap-8">
      <div>
        <Label text="Between" />
        <div class="mt-1.5 flex space-x-4">
          <Select text="12" />

          <Select text="00" />

          <Select text="AM" />
        </div>
      </div>

      <div>
        <Label text="And" />
        <div class="mt-1.5 flex space-x-4">
          <Select text="12" />

          <Select text="00" />

          <Select text="AM" />
        </div>
      </div>

      <div>
        <Label text="Days" />
        <div class="mt-1.5 flex space-x-3">
          <div
            v-for="day in week"
            :key="day"
            class="inline-flex h-7 w-7 cursor-pointer items-center justify-center rounded-md bg-blue-600 text-sm font-medium text-white transition-all duration-200"
          >
            {{ day }}
          </div>
          <!-- <Button
                v-for="day in week"
                :key="day"
                :text="day"
                size="small"
                class="h-7 w-7"
              /> -->
        </div>
      </div>
    </div>

    <hr class="border-gray-200 dark:border-gray-700" />

    <div class="grid grid-cols-2 gap-6">
      <div>
        <Label text="Starting Date (Optional)" />
        <div class="mt-1.5">
          <!-- <Select text="03/01/2022" /> -->
          <Input type="date" />
        </div>
      </div>

      <div>
        <Label text="Ending Date (Optional)" />
        <div class="mt-1.5">
          <!-- <Select v-model="demo2" :options="demo" text="04/01/2022" /> -->
          <Input type="date" />
        </div>
      </div>
    </div>

    <hr class="border-gray-200 dark:border-gray-700" />

    <div class="grid grid-cols-4 gap-6">
      <div>
        <Label text="Starting Time Offset (Optional)" />
        <div class="mt-1.5 flex items-center space-x-2">
          <Input type="number" text="0" class="w-full" />

          <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
            Minutes
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from '../Select.vue'
import Button from '../Button.vue'
import Input from '../Input.vue'
import Label from '../Label.vue'
export default {
  name: 'Scheduling',
  components: { Select, Button, Input, Label },
  data() {
    return {
      week: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],

      demo: ['CEO', 'CTO'],
      demo2: null
    }
  },
  methods: {
    onSubmit() {
      console.log(this.demo2)
    }
  }
}
</script>

<style></style>
