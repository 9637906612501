<template>
  <div class="mb-4">
    <Banner :text="bannerText" :showClose="false" />
  </div>

  <div class="grid grid-cols-[1fr,1fr,1fr,30px] text-center items-center">
    <p>Column Name</p>
    <p>Column Header</p>
    <p>Value</p>
    <p></p>
  </div>
  <div class="overflow-y-auto pb-2 pl-2" :key="reRender">
    <div v-for="(data, index) of columnMappingData" :key="index">
      <div class="grid mt-2 gap-1 grid-cols-[1fr,1fr,1fr,30px]">
        <input
          :class="[
            {
              'border-red-500 focus:!border-red-600 focus:!ring-red-500':
                errorMessage[index] || dupeErrorMessage[index]
            }
          ]"
          class="max-w-[202px] mt-[0.375rem] border-gray-200 rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
          type="text"
          maxlength="3"
          :value="data.columnName"
          @input="capitalizeInput($event, index)"
        />

        <input
          class="border-gray-200 mt-[0.375rem] max-w-[202px] rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
          type="text"
          @blur="updateValue"
          @keyup.enter="updateValue"
          v-model="data.label"
        />
        <WorkflowInput
          @update:modelValue="addedValue($event, index)"
          placeholder=""
          v-model="data.value"
          :previous-nodes="outputs"
        />
        <div
          @click="removeRow(index)"
          class="text-red-500 flex justify-center items-center cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 16 16 "
            fill="none"
          >
            <g clip-path="url(#clip0_1_658)">
              <path
                opacity="0.3"
                d="M5.33333 6H10.6667V12.6667H5.33333V6Z"
                fill="currentColor"
              />
              <path
                d="M10.3333 2.66667L9.66666 2H6.33333L5.66666 2.66667H3.33333V4H12.6667V2.66667H10.3333ZM3.99999 12.6667C3.99999 13.4 4.59999 14 5.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V4.66667H3.99999V12.6667ZM5.33333 6H10.6667V12.6667H5.33333V6Z"
                fill="currentcolor"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_658">
                <rect class="h-5 w-5" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div
        v-if="errorMessage[index] || dupeErrorMessage[index]"
        class="text-sm text-red-500"
      >
        {{ errorMessage[index] || dupeErrorMessage[index] }}
      </div>
    </div>
    <div class="mt-4 pl-2">
      <p class="text-center text-red-500 text-sm" v-if="mainErrorMessage.show">
        {{ mainErrorMessage.msg }}
      </p>
      <div>
        <Button @click="addRow" text="Add column" leftIcon="plus" />
      </div>
      <div ref="scrollButton" class="h-[33px]"></div>
    </div>
  </div>
</template>
<script setup>
import Input from './Input.vue'
import Button from './Button.vue'
import WorkflowInput from './workflowBuilder/customInput/WorkflowInput.vue'
import { ref, watch, onMounted } from 'vue'
import Banner from './Banner.vue'

const emit = defineEmits(['updateMapping'])

const props = defineProps({
  outputs: {
    type: Array
  },
  modelValue: {}
})

onMounted(() => {
  let obj = JSON.parse(props.modelValue)
  let array = obj?.mappingData || []
  if (array.length > 0 && array[0]?.columnName) {
    columnMappingData.value = array
    reRender.value++
  }
})

const columnMappingData = ref([
  {
    columnName: 'A',
    label: '',
    value: ''
  }
])

const errorMessage = ref({})
const dupeErrorMessage = ref({})
const scrollButton = ref(null)
const reRender = ref(1)
const bannerText = `<p class="flex-1 text-sm font-normal text-gray-900"> By default we will be adding these results as new rows in Spreadsheet</p>`
const mainErrorMessage = ref({
  show: false,
  msg: 'Error: Duplicate column names found.'
})
const addRow = async () => {
  if (columnMappingData.value.length === 0) {
    columnMappingData.value.push({
      columnName: 'A',
      label: '',
      value: ''
    })
  } else {
    const nextAlphabet = toColumnName(
      alphabetIndex(
        columnMappingData.value[columnMappingData.value.length - 1].columnName
      ) + 1
    )
    columnMappingData.value.push({
      columnName: nextAlphabet,
      label: '',
      value: ''
    })
  }
  scrollButton.value.scrollIntoView({
    behavior: 'smooth'
  })
}

const alphabetIndex = alphabet => {
  let index = 0
  for (let i = 0; i < alphabet.length; i++) {
    index = index * 26 + (alphabet.charCodeAt(i) - 64)
  }
  return index
}

const toColumnName = num => {
  let ret = ''
  while (num > 0) {
    num--
    ret = String.fromCharCode((num % 26) + 65) + ret
    num = Math.floor(num / 26)
  }
  return ret
}

const removeRow = index => {
  columnMappingData.value.splice(index, 1)
  reRender.value++
  updateValue()
}

const addedValue = (e, index) => {
  const match = e.match(/\.(.*?)}}/)

  let dataArr = columnMappingData.value
  dataArr[index].value = e
  let labelToDisplay
  if (match && match[1]) {
    for (let i = 0; i < props.outputs.length; i++) {
      for (let j = 0; j < props.outputs[i].output.length; j++) {
        if (props.outputs[i].output[j].name === match[1]) {
          labelToDisplay = props.outputs[i].output[j].label
          break
        }
      }
    }
  }

  if (match && match[1]) {
    dataArr.forEach(item => {
      if (item.value === e) {
        item.label = labelToDisplay
      }
    })
  }
  columnMappingData.value = dataArr
  reRender.value++
  updateValue()
}

const updateValue = () => {
  if (checkUnique()) {
    emit('updateMapping', columnMappingData.value)
  }
}

const checkUnique = () => {
  const columnNameIndexMap = new Map()
  const duplicates = []
  columnMappingData.value.forEach((item, index) => {
    const columnName = item.columnName

    //check if the column name already exist in map
    if (columnNameIndexMap.has(columnName)) {
      //push data with the column name, first index & second index to an array
      duplicates.push({
        name: columnName,
        firstIndex: columnNameIndexMap.get(columnName),
        duplicateIndex: index
      })
    } else {
      //add the column name to map
      columnNameIndexMap.set(columnName, index)
    }
  })

  //check the duplicate array
  if (duplicates.length > 0) {
    //add in the error message obj with key being the duplicate index with err msg
    dupeErrorMessage.value = {}
    duplicates.forEach(duplicate => {
      dupeErrorMessage.value[
        duplicate.duplicateIndex
      ] = `Duplicate column name '${duplicate.name}' found`
    })

    //show main error msg
    mainErrorMessage.value.show = true

    //return false as in validation failed
    return false
  } else {
    //clear duplicate error messages
    dupeErrorMessage.value = {}

    mainErrorMessage.value.show = false
    if (
      Object.keys(errorMessage.value).length > 0 ||
      Object.keys(dupeErrorMessage.value).length > 0
    ) {
      //if error msg are still there return validation false
      return false
    } else {
      return true
    }
  }
}

const capitalizeInput = (e, index) => {
  columnMappingData.value[index].columnName = e.target.value.toUpperCase()
  const columnRegex = /^[A-Z]+$/

  if (!columnRegex.test(columnMappingData.value[index].columnName)) {
    errorMessage.value[index] =
      'Invalid column name. Please enter a valid column name like "A", "AA", "AAA", etc.'
  } else {
    delete errorMessage.value[index]
  }

  if (columnMappingData.value[index].value) {
    updateValue()
  } else {
    checkUnique()
  }
}
</script>
