<template>
  <PreviewPromptModal
    :promptText="promptText"
    :variableData="variableData"
    v-if="showPreviewPromptModal"
    @close="showPreviewPromptModal = false"
    @previewPromptClicked="previewPromptClicked"
  />
  <div v-if="loading" class="flex items-center h-full justify-center w-full">
    <Spinner size="large" />
  </div>
  <div v-else class="grid w-full h-full grid-cols-8 overflow-y-auto">
    <div class="col-span-2 border-r border-gray-200">
      <div class="flex flex-col gap-2 px-[14px] pt-5">
        <div class="flex justify-between">
          <p class="font-bold">System:</p>

          <p class="text-sm">{{ systemTextToken.length ?? 0 }} tokens</p>
        </div>
        <div class="flex flex-col gap-y-4">
          <div
            class="border-gray-300 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:hover:border-gray-500"
          >
            <EditorContent :editor="editorSystem" class="border-inherit" />
          </div>
          <div class="flex flex-row-reverse">
            <Popper
              placement="right"
              :interactive="true"
              :style="{ margin: 0, border: 0 }"
              :show="showTemplate1"
              @close:popper="showTemplate1 = false"
            >
              <button
                v-if="selectedPlatformAccount"
                @click="showTemplate1 = !showTemplate1"
                type="button"
                ref="modalButton"
                class="inline-flex items-center justify-center rounded-lg border border-gray-300 bg-white px-4 py-2.5 text-base font-semibold text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900 sm:text-sm"
              >
                <SvgIcon class="h-6 w-6" name="plus" />
                Insert Variables
              </button>
              <template #content>
                <VariableMenu
                  v-if="showTemplate1"
                  :variableData="variableData"
                  @variableSelected="variableSelectedSystem"
                  @closeTemplate="showTemplate1 = false"
                />
              </template>
            </Popper>
          </div>
          <div class="flex flex-col gap-y-7">
            <div v-if="promptData?.platformId">
              <!-- TODO: ADD PLATFORM ICON -->
              <Select
                label="Platform"
                :options="platformOptions"
                :leftImageUrl="platformIcon"
                v-model="selectedPlatform"
              />
            </div>
            <div v-if="promptData?.platformId">
              <Select
                label="Platform Account"
                v-model="selectedPlatformAccount"
                :options="platformAccountSelectOptions"
              />
            </div>
            <div>
              <Select
                label="OpenAI Account"
                v-model="selectedOpenAiAccount"
                :options="openAiAccount"
              />
            </div>
          </div>
        </div>
        <div class="py-4">
          <Button text="Go Back" leftIcon="left-arrow" @click="onGoBackClick" />
        </div>
      </div>
    </div>
    <div class="col-span-4 border-r border-gray-200">
      <div class="flex flex-col gap-2 px-[14px] pt-5">
        <p class="font-bold">Assistant Answer:</p>

        <div
          class="border-gray-300 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:hover:border-gray-500"
        >
          <EditorContent :editor="editorAssistant" class="border-inherit" />
        </div>

        <div class="flex justify-between">
          <p class="font-bold">User Prompt:</p>

          <p class="text-sm">{{ userPromptToken.length ?? 0 }} tokens</p>
        </div>
        <div class="flex flex-col gap-y-4">
          <Popper
            placement="right"
            :show="showPopper"
            :interactive="true"
            :style="{ margin: 0, border: 0 }"
            @close:popper="showPopper = false"
          >
            <div
              class="border-gray-300 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:hover:border-gray-500"
            >
              <EditorContent :editor="editorPrompt" class="border-inherit" />
            </div>
            <template #content>
              <VariableMenu
                v-if="showPopper"
                :variableData="variableData"
                :promptText="promptText"
                @variableSelected="variableSelectedPrompt"
                @closeTemplate="showPopper = false"
              />
            </template>
          </Popper>
          <div class="flex justify-end gap-x-2">
            <button
              @click="showPopper = !showPopper"
              type="button"
              ref="modalButton"
              class="inline-flex items-center justify-center rounded-lg border border-gray-300 bg-white px-4 px-4 py-2.5 text-base font-semibold text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900 sm:text-sm"
            >
              <SvgIcon class="h-6 w-6" name="plus" />
              Insert Variables
            </button>

            <!-- TODO: WILL NEED LATER -->
            <!-- <Button
              text="Insert Full Profile Data"
              color="tertiary"
              leftIcon="plus"
            /> -->
            <Button
              text="Preview"
              @click="onPreviewButtonClicked"
              :showLoader="previewLoading"
              :isdisabled="!previewLoading"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-2 flex flex-col px-[14px] justify-between">
      <div>
        <div class="flex flex-col gap-2 pt-5">
          <p class="font-bold">Model:</p>
          <div class="h-[100px]">
            <Select
              :options="gptOptions"
              @change="checkAndSavePrompt()"
              v-model="selectedGptModel"
            />
          </div>

          <p class="font-bold">Temperature:</p>

          <div class="flex flex-col gap-4">
            <p class="text-sm">{{ temp }}</p>
            <Slider
              @slideend="checkAndSavePrompt()"
              v-model="temperatureValue"
              class="w-14rem"
            />
            <div
              v-if="
                $route.query.promptId &&
                promptData.platformId === constants.LINKEDIN_PLATFORM_ID
              "
              class=""
            >
              <Button @click="onTestPromptClick" text="Test Prompt" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="!$route.query.promptId" class="pb-1">
        <Button @click="onSavePromptClick" text="Save Prompt" />
      </div>
    </div>
  </div>
</template>

<script setup>
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import Select from '@/components/Select.vue'
import VariableMenu from './VariableMenu.vue'
import PreviewPromptModal from './PreviewPromptModal.vue'
import Popper from 'vue3-popper'
import Spinner from '@/components/Spinner.vue'
import Slider from 'primevue/slider'

//vuex imports
import { computed, onMounted, ref, onBeforeUnmount, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

//common func imports
import { EditorContent, useEditor } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import { Tag } from '@/components/workflowBuilder/customInput/Tag'
import { Variable } from '@/components/workflowBuilder/customInput/Variable'
import { convertInputStringToHtml } from '@/components/workflowBuilder/helper'
import { encode } from 'gpt-tokenizer'
import { constants } from '@/common/constants'
import Placeholder from '@tiptap/extension-placeholder'

//api imports
import {
  getAllPlatforms,
  getConnectedAccounts
} from '@/apis/automation-store/Page1'
import {
  getPromptById,
  getPromptPreview,
  savePrompt,
  updatePrompt
} from '@/apis/settings/ai'

//props definition
const props = defineProps({
  promptTitleAndDescription: { type: Object }
})
const store = useStore()

//emits definition
const emit = defineEmits([
  'showTemplates',
  'changeEditableTitleStatus',
  'apiSuccess',
  'apiFail',
  'promptloader',
  'saveloader',
  'showTester'
])

const promptData = ref(null)
const systemText = ref(null)
const promptText = ref(null)
const loading = ref(true)
const selectedGptModel = ref(null)
const gptOptions = computed(() => store._state.data.settings.metadata.gptModel)
const platformOptions = ref(null)
const platformIcon = ref(null)
const selectedPlatform = ref(null)
const platformAccount = ref([])
const selectedPlatformAccount = ref(null)
const openAiAccount = ref(null)
const selectedOpenAiAccount = ref(null)
const temperatureValue = ref(null)
const showPreviewPromptModal = ref(null)
const showPopper = ref(false)
const previewLoading = ref(false)
const showTemplate1 = ref(false)
const router = useRouter()
const route = useRoute()

// system editor variable
const editorSystem = useEditor({
  extensions: [
    StarterKit,
    Variable,
    Tag,
    Placeholder.configure({
      placeholder:
        'Instruct the system role and guide the conversation by giving proper context.\n\nEx: You are a personal assistant. Your job is to decline unsolicited emails.'
    })
  ],
  editorProps: {
    attributes: {
      class:
        'block w-full border rounded-lg px-3 py-2.5 pr-8 placeholder-gray-500 duration-200 dark:placeholder-gray-400 sm:text-sm border-gray-300 caret-blue-600  dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 min-h-[150px] outline-none border-inherit '
    }
  },
  onUpdate() {
    systemText.value = editorSystem.value.getText()
  },
  onBlur() {
    checkAndSavePrompt()
  }
})

//user prompt editor variable
const editorPrompt = useEditor({
  extensions: [
    StarterKit,
    Variable,
    Placeholder.configure({
      placeholder:
        'Be specific. Give clear and detailed instructions for best results.'
    })
  ],
  editorProps: {
    attributes: {
      class:
        'block w-full border rounded-lg px-3 py-2.5 pr-8 placeholder-gray-500 duration-200 dark:placeholder-gray-400 sm:text-sm border-gray-300 caret-blue-600  dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 min-h-[100px] outline-none border-inherit '
    }
  },
  onUpdate() {
    promptText.value = editorPrompt.value.getText()
  },
  onBlur() {
    checkAndSavePrompt()
  }
})

const editorAssistant = useEditor({
  extensions: [
    StarterKit,
    Variable,
    Placeholder.configure({
      placeholder:
        'The result of the prompt will only be displayed after adding the system context and the user prompt.'
    })
  ],
  editorProps: {
    attributes: {
      class:
        'block w-full border rounded-lg px-3 py-2.5 pr-8 placeholder-gray-500 duration-200 dark:placeholder-gray-400 sm:text-sm border-gray-300 caret-blue-600  dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 min-h-[100px] outline-none border-inherit '
    }
  }
  // editable: false
})

/* computed props */

const temp = computed(() => temperatureValue.value / 100)

const platformAccountSelectOptions = computed(() =>
  platformAccount.value.map(account => ({
    value: account._id,
    label: account.name
  }))
)

const variableData = computed(() => {
  if (selectedPlatformAccount.value) {
    return platformAccount.value.find(
      val => val._id === selectedPlatformAccount.value
    )
  } else {
    return { variables: {} }
  }
})

const systemTextToken = computed(() => {
  if (systemText.value === null) return 0
  return encode(systemText.value)
})

const userPromptToken = computed(() => {
  if (promptText.value === null) return 0
  return encode(promptText.value)
})

/* watchers */
watch(selectedPlatform, (currentValue, oldValue) => {
  getNewConnectedAccounts(currentValue)
})

const currentPromptTitleAndDescription = computed(
  () => props.promptTitleAndDescription
)
watch(currentPromptTitleAndDescription, (newValue, oldValue) => {
  checkAndSavePrompt()
})

onMounted(async () => {
  await getPromptData()

  if (promptData.value) {
    editorSystem.value.commands.insertContent(
      convertInputStringToHtml(promptData.value.system)
    )

    editorPrompt.value.commands.insertContent(
      convertInputStringToHtml(promptData.value.prompt)
    )

    if (!promptData.value.createdBy && route.query.cloned === 'false') {
      editorSystem.value.setEditable(false)
      editorPrompt.value.setEditable(false)
    }
  }
})

// destroy the editor on unmount
onBeforeUnmount(() => {
  editorSystem.value.destroy()
  editorPrompt.value.destroy()
  editorAssistant.value.destroy()
})

/* methods */
const getNewConnectedAccounts = async id => {
  const response = await getConnectedAccounts(id)
  platformAccount.value = response.data
}

//fetch prompt data and fetch other account data depending upon the prompt
const getPromptData = async () => {
  try {
    let promptResponse, platformResponse

    //if promt id is present in URL then fetch all the data related to prompt as well
    if (route.query.promptId) {
      ;[promptResponse, platformResponse] = await Promise.all([
        getPromptById(route.query.promptId),
        getAllPlatforms()
      ])

      if (promptResponse['success']) {
        promptData.value = promptResponse.data

        //if createdBy is present open the option to edit title and description
        if (!promptResponse.data.createdBy && route.query.cloned === 'false') {
          //factory prompt
          emit('changeEditableTitleStatus', false)
        } else {
          //user prompt
          emit('changeEditableTitleStatus', true)
        }
        selectedGptModel.value = promptResponse.data.model
        temperatureValue.value = promptResponse.data.temperature * 100

        if (route.query.cloned === 'true') {
          router.replace({
            query: {
              cloned: true
            }
          })
        }
      } else {
        throw promptResponse.message
      }
    } else {
      //only fetch platform data
      platformResponse = await getAllPlatforms()
    }

    if (platformResponse['success']) {
      //if there is already a platform id stored in BE prompt data , mostly cases for factory prompts
      //provide only that platform as other platforms are not needed
      if (promptData.value && promptData.value.platformId) {
        platformOptions.value = platformResponse.data
          .filter(item => item._id === promptData.value.platformId)
          .map(item => {
            selectedPlatform.value = item._id
            return { label: item.name, value: item._id }
          })

        //since we have a platform fetch accounts for that platform and openAI accounts
        const [accountResponse, openAIAccountResponse] = await Promise.all([
          getConnectedAccounts(promptData.value.platformId),
          getConnectedAccounts(constants.OPENAI_PLATFORM_ID)
        ])

        platformAccount.value = accountResponse.data

        openAiAccount.value = openAIAccountResponse.data.map(account => ({
          value: account._id,
          label: account.name
        }))
      } else {
        //no platform id provided fetch all the platforms
        platformOptions.value = platformResponse.data.map(item => {
          return { label: item.name, value: item._id }
        })

        const openAIAccountResponse = await getConnectedAccounts(
          constants.OPENAI_PLATFORM_ID
        )
        openAiAccount.value = openAIAccountResponse.data.map(account => ({
          value: account._id,
          label: account.name
        }))
      }
    }
  } catch (error) {
    emit('apiFail', error)
  } finally {
    loading.value = false
  }
}

const previewPromptClicked = async data => {
  try {
    previewLoading.value = true
    //user is previewing without saving prompt
    if (!route.query.promptId) {
      await onSavePromptClick()
    }

    //show loader status at top
    emit('promptloader', true)

    //hide preview modal
    showPreviewPromptModal.value = false
    let previewData = {
      openAiAccountId: selectedOpenAiAccount.value,
      socialAccountId: selectedPlatformAccount.value,
      promptId: route.query.promptId,
      inputs: data
    }

    const previewResponse = await getPromptPreview(previewData)

    if (previewResponse['success']) {
      editorAssistant.value.setEditable(false)
      editorAssistant.value.commands.clearContent()
      let flag = 0
      for (const key in previewResponse.data) {
        if (key.toLowerCase().includes('ai')) {
          flag++
          editorAssistant.value.commands.insertContent(
            `${convertInputStringToHtml(
              previewResponse.data[key].replace(/\\n/g, '\n')
            )}`
          )
        }
      }
      if (flag === 0) {
        let arr = [
          'completionTokens',
          'model',
          'promptTokens',
          'timestamp',
          'totalTokens'
        ]
        let obj = {}
        for (const key in previewResponse.data) {
          if (arr.includes(key)) continue
          obj[key] = previewResponse.data[key]
        }
        editorAssistant.value.commands.insertContent(
          `${convertInputStringToHtml(JSON.stringify(obj))}`
        )
      }
    } else {
      throw previewResponse.message
    }
  } catch (error) {
    emit('apiFail', error)
  } finally {
    previewLoading.value = false
    emit('promptloader', false)
  }
}

const onPreviewButtonClicked = () => {
  const result = accountValidator()
  if (result) showPreviewPromptModal.value = true
}

const onTestPromptClick = () => {
  const result = accountValidator()
  if (result)
    emit('showTester', {
      selectedOpenAiAccount,
      selectedPlatformAccount
    })
}

const accountValidator = () => {
  if (promptData.value && promptData.value?.platformId) {
    if (!selectedPlatformAccount.value) {
      emit(
        'apiFail',
        'Select Both Platform Account And OpenAI Account to Preview'
      )
      return false
    }
  } else if (!selectedOpenAiAccount.value) {
    emit('apiFail', 'Select OpenAI Account to Preview')

    return false
  }
  return true
}

// adds the variable from insert variable modal
const variableSelectedSystem = data => {
  editorSystem.value.commands.insertContent({
    type: 'variable',
    attrs: {
      nodeId: data.nodeId,
      outputName: data.outputName
    }
  })
  editorSystem.value.commands.focus()
}

const variableSelectedPrompt = data => {
  editorPrompt.value.commands.insertContent({
    type: 'variable',
    attrs: {
      nodeId: data.nodeId,
      outputName: data.outputName
    }
  })
  editorPrompt.value.commands.focus()
}

const onGoBackClick = () => {
  router.replace({ query: null })
  emit('saveloader', false)
  emit('promptloader', false)
  emit('showTemplates')
}

const checkAndSavePrompt = () => {
  if (route.query.promptId && promptData.value.createdBy) {
    onSavePromptClick()
  }
}

// function for updating / saving new prompt
const onSavePromptClick = async () => {
  try {
    if (!props.promptTitleAndDescription) {
      throw 'Prompt Title & Description are Required'
    } else if (!systemText.value) {
      throw 'System Prompt is Required'
    } else if (!selectedGptModel.value) {
      throw 'Gpt Model is Required'
    } else if (!temp.value) {
      throw 'Temperature is Required'
    } else if (!promptText.value) {
      throw 'User Prompt is Required'
    }
    let arr = []
    let match
    const regex = /{{(.*?)}}/g
    while ((match = regex.exec(promptText.value)) !== null) {
      const placeholder = match[1].trim()
      arr.push(placeholder)
    }

    let data = {
      ...props.promptTitleAndDescription,
      system: systemText.value,
      platformId: selectedPlatform.value,
      model: selectedGptModel.value,
      temperature: temp.value,
      prompt: promptText.value,
      variables: arr
    }
    emit('saveloader', true)
    let response
    //if prompt id present then update else create new prompt
    if (route.query.promptId && route.query.cloned === 'false') {
      response = await updatePrompt(route.query.promptId, data)
      if (!response['success']) {
        throw response.message
      }
    } else {
      response = await savePrompt(data)
      if (response['success']) {
        emit('apiSuccess', 'Prompt Saved Successfully')
        promptData.value = response.data
        await router.replace({
          query: { promptId: response.data._id, cloned: false }
        })
      } else {
        if (response.errors) {
          throw response.errors
        } else {
          throw response.message
        }
      }
    }
  } catch (error) {
    if (Array.isArray(error)) {
      for (const data of error) {
        emit('apiFail', data.message)
      }
    } else {
      emit('apiFail', error)
    }
  } finally {
    emit('saveloader', false)
  }
}
</script>

<style scoped>
/* Styles for Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

:deep(.p-slider-handle) {
  background: #2196f3;
}

.single-line {
  white-space: nowrap;
  overflow: hidden;
}
</style>
