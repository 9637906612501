<template>
  <div
    class="py-1"
    role="none"
    :class="{
      'text-gray-700 hover:bg-gray-100 hover:text-gray-900': color === 'gray',
      'text-red-600 hover:bg-red-50 hover:text-red-700': color === 'red'
    }"
  >
    <a
      href="#"
      class="group flex items-center px-4 py-2 text-base font-medium transition-all duration-200 sm:text-sm"
      role="menuitem"
      tabindex="-1"
      @click="onClick"
    >
      <div class="mr-3"><SvgIcon :name="icon" class="h-5 w-5" /></div>

      {{ optionName }}
      <Spinner v-if="loading" class="ml-2" size="small" />
    </a>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Spinner from '../Spinner.vue'
import SvgIcon from '../SvgIcon.vue'

const props = defineProps({
  color: String,
  name: String,
  icon: String,
  confirmationText: String,
  loading: Boolean
})
const emit = defineEmits(['option-click'])

const isConfirmed = ref(!props.confirmationText)
const optionName = ref(props.name)

const onClick = () => {
  if (isConfirmed.value) {
    emit('option-click')
  } else {
    optionName.value = props.confirmationText
    isConfirmed.value = true
  }
}
</script>
