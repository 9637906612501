<template>
  <div class="h-full">
    <div class="inline-block h-full min-w-full py-2 align-middle">
      <div class="h-full w-full overflow-auto">
        <table class="min-w-full">
          <thead class="sticky top-0 bg-gray-50 dark:bg-gray-700">
            <tr>
              <th
                scope="col"
                class="group px-3 text-left py-3.5 text-sm font-semibold text-gray-900 dark:text-gray-50 rounded-tl-lg rounded-bl-lg"
              >
                <input
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-800 sm:left-6"
                />
              </th>
              <th
                v-for="(col, index) of columns"
                scope="col"
                class="group px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                :class="{
                  'rounded-r-lg': col.isInvisible,
                  'cursor-pointer': col.sortable,
                  'w-[11rem]': col.buttonWidth
                }"
                @click="onSort(col)"
              >
                <span
                  :class="{ 'sr-only': col.isInvisible }"
                  class="flex gap-1"
                >
                  {{ col.name }}
                  <span v-if="col.sortable">
                    <svg-icon
                      name="arrow-up-full"
                      class="h-4 w-4"
                      :class="{
                        'fill-gray-800': col.key === sortField,
                        'rotate-180': sortOrder === 'desc',
                        'rotate-0': sortOrder === 'asc',
                        'fill-gray-50 group-hover:fill-gray-300':
                          col.key !== sortField
                      }"
                    />
                  </span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody
            class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
          >
            <tr v-for="ele in 5" :key="ele">
              <td
                class="group px-3 py-3.5 text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                <input
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-800 sm:left-6"
                />
              </td>
              <td
                class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
              >
                <Skeleton
                  height="40px"
                  width="175px"
                  borderRadius="8px"
                ></Skeleton>
              </td>
              <td
                class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
              >
                <Skeleton
                  height="40px"
                  width="170px"
                  borderRadius="8px"
                ></Skeleton>
              </td>
              <td
                class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
              >
                <Skeleton
                  height="40px"
                  width="95px"
                  borderRadius="8px"
                ></Skeleton>
              </td>
              <td
                class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
              >
                <Skeleton
                  height="40px"
                  width="175px"
                  borderRadius="8px"
                ></Skeleton>
              </td>
              <td
                class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
              >
                <Skeleton
                  height="40px"
                  width="175px"
                  borderRadius="8px"
                ></Skeleton>
              </td>
              <td
                class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
              >
                <Skeleton shape="circle" size="40px"></Skeleton>
              </td>
              <td
                class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
              >
                <Skeleton
                  height="40px"
                  width="95px"
                  borderRadius="8px"
                ></Skeleton>
              </td>
              <td
                class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
              >
                <Skeleton
                  height="40px"
                  width="95px"
                  borderRadius="8px"
                ></Skeleton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      required: true
    }
  }
}
</script>
