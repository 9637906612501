<template>
  <!-- @TODO: REFACTOR COMPONENT -->
  <aside
    class="fixed bottom-4 right-4 top-4 z-30 flex w-full max-w-sm flex-col rounded-lg border-gray-200 bg-white shadow-lg outline-none transition-all duration-300 ease-in-out dark:border-gray-600 dark:bg-gray-900"
    :class="{
      'right-0': open,
      'right-[-100%]': !open
    }"
  >
    <div v-if="showCloseButton" class="absolute left-0 top-0 -ml-14 mt-4">
      <button
        type="button"
        class="rounded-lg border border-gray-200 bg-white p-1.5 text-gray-600 shadow-lg transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900"
        @click="onPanelClose"
      >
        <span class="sr-only"> Close </span>
        <SvgIcon name="close" />
      </button>
    </div>

    <div class="p-4">
      <nav class="flex justify-center space-x-4" aria-label="Tabs">
        <a
          href="#"
          title=""
          class="rounded-lg px-3 py-2 text-sm font-medium capitalize transition-all duration-200"
          aria-current="page"
          v-for="({ type }, index) of tabs"
          :class="{
            'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-50':
              currentTab === index,
            'text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
              currentTab !== index
          }"
          @click="currentTab = index"
        >
          {{ type }}
        </a>
      </nav>
    </div>
    <component
      :is="tabs[currentTab].component"
      @item-selected="onItemSelected"
      :type="tabs[currentTab].type"
      :options="options"
    />
  </aside>
</template>

<script setup>
import SvgIcon from '@/components/SvgIcon.vue'
import { Position, useVueFlow } from '@vue-flow/core'
import { markRaw, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import Automation from './automation.vue'
import Operation from './operation.vue'

const props = defineProps({
  open: {
    type: Object,
    default: true
  },
  showCloseButton: {
    type: Boolean
  }
})

const emit = defineEmits(['closePanel', 'item-selected'])

const tabs = ref([
  { type: 'automation', component: markRaw(Automation) },
  { type: 'operation', component: markRaw(Operation) },
  { type: 'integration', component: markRaw(Automation) }
])

const store = useStore()
const { nodes } = useVueFlow()
const currentTab = ref(0)
const isSecondaryNode = ref(false)

const options = reactive({
  isRouterAdded: store.getters['workflow/isRouterAdded'],
  isSecondaryNode: isSecondaryNode.value,
  isFirstNode: nodes.value.length === 0,
  isLeftNode: false
})

watch(
  () => store.getters['workflow/isRouterAdded'],
  n => {
    options.isRouterAdded = n
  }
)

watch(
  () => props.open,
  selectedNode => {
    if (!selectedNode) {
      return
    }

    // when no nodes are present in the workflow
    if (!selectedNode.nodeId) {
      isSecondaryNode.value = false
      return
    }

    options.isFirstNode =
      !selectedNode.nodeId ||
      (!selectedNode.node.data.previousNodeId &&
        selectedNode.position === Position.Left)

    options.isLeftNode = selectedNode.position === Position.Left
    options.isRightNode = selectedNode.position === Position.Right
    options.isBetweenNodes =
      (options.isLeftNode && selectedNode.node.data.previousNodeId) ||
      (options.isRightNode && selectedNode.node.data.nextNodeId)
    isSecondaryNode.value = !!(
      selectedNode.node?.data.automationData.platformId &&
      (selectedNode.position === Position.Bottom ||
        selectedNode.position === Position.Top)
    )
  }
)

watch(isSecondaryNode, toggle => {
  currentTab.value = 0
  if (toggle) {
    tabs.value = [
      { type: 'operation', component: markRaw(Operation) },
      { type: 'integration', component: markRaw(Automation) }
    ]
  } else {
    tabs.value = [
      { type: 'automation', component: markRaw(Automation) },
      { type: 'operation', component: markRaw(Operation) },
      { type: 'integration', component: markRaw(Automation) }
    ]
  }
  options.isSecondaryNode = toggle
})

const onItemSelected = automationData => {
  emit('item-selected', automationData)
  emit('closePanel')
}

const onPanelClose = () => {
  emit('closePanel')
}
</script>
