<template>
  <div class="relative flex flex-1 border-l border-gray-200 dark:border-gray-700 antialiased">
    <div class="relative flex flex-col justify-between flex-1 px-4 py-5 sm:p-6">
      <div class="flex-1">
        <AgencyWhitelabelHeader activeOption="client"/>
        <div class="flex flex-col mt-8">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div class="overflow-hidden">
                <table class="min-w-full">
                  <thead class="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th scope="col"
                        class="min-w-[12rem] py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-50 rounded-l-lg">
                      Name
                    </th>

                    <th scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50">
                      Accounts
                    </th>

                    <th scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50">
                      Contact Email
                    </th>

                    <th scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50">
                      Status
                    </th>

                    <th scope="col"
                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50">
                      Created
                    </th>

                    <th scope="col" class="px-3 py-3.5 text-left rounded-r-lg">
                                <span class="sr-only">
                                  Actions
                                </span>
                    </th>
                  </tr>
                  </thead>

                  <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700">
                  <tr v-for="(item, index) in clientData" :key="index">
                    <td class="px-3 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                                  <span
                                      class="inline-flex items-center justify-center w-10 h-10 bg-blue-100 border border-blue-300 rounded-full dark:bg-blue-500 dark:border-blue-600">
                                    <span
                                        class="text-base font-medium leading-none text-blue-600 uppercase dark:text-blue-50">
                                      {{ item.clientInitials }}
                                    </span>
                                  </span>
                        <div class="ml-3">
                          <p class="text-sm font-semibold text-gray-900 dark:text-gray-50">
                            {{ item.clientName }}
                          </p>
                          <p class="mt-0.5 text-xs font-normal text-gray-600 dark:text-gray-400">
                            {{ item.clientLocation }}
                          </p>
                        </div>
                      </div>
                    </td>

                    <td class="px-3 py-4 text-sm text-gray-900 dark:text-gray-50 whitespace-nowrap">
                      {{item.accounts}}
                    </td>

                    <td class="px-3 py-4 text-sm text-gray-900 dark:text-gray-50 whitespace-nowrap">
                      {{ item.email }}
                    </td>

                    <td class="px-3 py-4 whitespace-nowrap">
                      <Badge :text="item.status" color="added"/>
                    </td>

                    <td class="px-3 py-4 text-sm text-gray-900 dark:text-gray-50 whitespace-nowrap">
                      {{ item.createdAt }}
                    </td>

                    <td class="px-3 py-4 whitespace-nowrap">
                      <div class="flex items-center space-x-4">
                        <Button v-if="item.isEdit" icon="edit" color="iconOnly" class="p-2 -m-2 text-gray-600 transition-all duration-200 rounded-lg hover:bg-gray-100 focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700 focus:ring-0 dark:hover:text-gray-50 hover:text-gray-900"/>
                        <Button v-if="item.isDelete" icon="delete" color="iconOnly" class="p-2 -m-2 text-red-600 transition-all duration-200 rounded-lg hover:bg-red-50 focus:outline-none dark:text-red-400 dark:hover:bg-red-500 dark:hover:text-red-50" />
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-end mt-8">
        <Button leftIcon="plus" text="Add Client" />
      </div>
    </div>
  </div>
</template>

<script>
import AgencyWhitelabelHeader from "@/components/settings/agencyWhitelabelHeader.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import Badge from "@/components/Badge.vue";
import Button from "@/components/Button.vue";

export default {
  name: "clientSection",
  components: {AgencyWhitelabelHeader, SvgIcon, Badge, Button},
  props: {
    clientData: {type: Array, default:
          [{
            clientInitials:'WO',
            clientName: 'Workspace #1',
            clientLocation: 'Paris, France',
            accounts: '50',
            email: 'samplemail@gmail.com',
            status: 'Active',
            createdAt: 'Jun 2019',
            isEdit: true,
            isDelete: true
          }]
    }
  }
}
</script>

<style scoped>

</style>
