import {
  deleteWorkflowNodes,
  updateWorkflow,
  updateWorkflowNode
} from '@/apis/workflows'
import {
  EDGE_TYPE_NAMES,
  NEXT_NODE_CONNECTION_TYPE,
  UTILITY_TYPES
} from '@/common/constants'
import { Position } from '@vue-flow/core'
import {
  calculateMoveDistance,
  getAllNodesInTheDirection,
  moveAllNodesAfterCoordsBy
} from './helper'

export const onNodeAdded = async (workflowId, newNode, nodes, edges) => {
  try {
    const { previousNodeId, connectionType } = newNode.data

    // update previous node's next node with this node when
    // this node is the primary node connection
    if (previousNodeId) {
      let updateData = {
        nextNode: newNode.id
      }
      if (connectionType === NEXT_NODE_CONNECTION_TYPE.PRIMARY) {
        // get previous node and check if this is a connection to router utility node.
        const previousNode = nodes.value.find(
          node => node.id === previousNodeId
        )

        // if it is a router node, change the update data structure according to
        // the router node input
        if (
          previousNode.data.automationData.utilityType === UTILITY_TYPES.ROUTER
        ) {
          const nodePosition =
            previousNode?.position.y > newNode?.position.y
              ? Position.Top
              : Position.Bottom

          const existingEdge = edges.value.find(
            edge =>
              edge.sourceNode.id === previousNodeId &&
              edge.targetNode.id !== newNode.id
          )

          let getRouterData = nodes.value.find(
            data => data.id === previousNodeId
          ).data?.savedInputs

          if (getRouterData) {
            updateData = {
              inputs: {
                routers: [
                  {
                    nextNode:
                      nodePosition === Position.Top
                        ? newNode.id
                        : existingEdge?.targetNode.id,
                    filters:
                      nodes.value.find(data => data.id === previousNodeId).data
                        .savedInputs.routers[0]?.filters || null,
                    routeLabel:
                      nodes.value.find(data => data.id === previousNodeId).data
                        .savedInputs.routers[0]?.routeLabel || null
                  },
                  {
                    nextNode:
                      nodePosition === Position.Bottom
                        ? newNode.id
                        : existingEdge?.targetNode.id,
                    filters:
                      nodes.value.find(data => data.id === previousNodeId).data
                        .savedInputs.routers[1]?.filters || null,
                    routeLabel:
                      nodes.value.find(data => data.id === previousNodeId).data
                        .savedInputs.routers[1]?.routeLabel || null
                  }
                ]
              }
            }
          } else {
            updateData = {
              inputs: {
                routers: [
                  {
                    nextNode:
                      nodePosition === Position.Top
                        ? newNode.id
                        : existingEdge?.targetNode.id
                  },
                  {
                    nextNode:
                      nodePosition === Position.Bottom
                        ? newNode.id
                        : existingEdge?.targetNode.id
                  }
                ]
              }
            }
          }
        }
      } else if (connectionType === NEXT_NODE_CONNECTION_TYPE.SECONDARY) {
        // update secondary nodes for the previous node
        // when this is secondary node
        const secondaryNodes = nodes.value.filter(
          node =>
            node.data.previousNodeId === previousNodeId &&
            node.data.connectionType === NEXT_NODE_CONNECTION_TYPE.SECONDARY
        )
        updateData = {
          secondaryNodes: secondaryNodes.map(node => ({
            nodeId: node.id,
            position:
              node.data.targetHandle === Position.Bottom
                ? Position.Top
                : Position.Bottom
          }))
        }
      }
      await updateWorkflowNode(workflowId, previousNodeId, updateData)
    }
  } catch (error) {
    console.log(error)
  }
}

export const onNodeDeleted = async (
  workflowId,
  nodesToDelete,
  nodes,
  edges,
  removeNodes,
  setRouterAdded
) => {
  // call the api to delete the node

  const nodesToProcess = [nodesToDelete],
    finalDeleteList = []
  while (nodesToProcess.length) {
    const deletedNodes = nodesToProcess.pop()
    finalDeleteList.push(...deletedNodes)
    // update previous node's next node
    // when this node was the primary node connection
    const deletedNodesMap = {}
    deletedNodes.forEach(node => (deletedNodesMap[node.id] = node))
    const leftMostNode = getEdgeNodes(deletedNodes, Position.Left)
    const rightMostNode = getEdgeNodes(deletedNodes, Position.Right)
    const previousNodeId = getPreviousNode(leftMostNode, edges)
    const nextNodeId = getNextNode(rightMostNode, edges)
    if (
      rightMostNode?.data.connectionType === NEXT_NODE_CONNECTION_TYPE.PRIMARY
    ) {
      // calculate the distance to move all the next nodes
      const moveDistance = calculateMoveDistance(deletedNodes)
      moveAllNodesAfterCoordsBy(
        { x: -moveDistance, y: 0 },
        rightMostNode,
        nodes,
        edges,
        false,
        deletedNodes.filter(
          node =>
            !node.data.automationData.utilityType &&
            node.data.connectionType === NEXT_NODE_CONNECTION_TYPE.PRIMARY
        ).length
      )

      updateNeighbouringNodes(
        workflowId,
        previousNodeId,
        nextNodeId,
        edges,
        nodes
      )

      // get secondary nodes and delete them.
      const secondaryNodes = getSecondaryNodes(deletedNodes, nodes, edges)
      if (secondaryNodes.length) nodesToProcess.push(secondaryNodes)

      // get router nodes and delete them.
      const routerNodes = getAllNodesAfterRouter(deletedNodes, nodes)
      if (routerNodes) {
        setRouterAdded(false)
        nodesToProcess.push(routerNodes)
      }

      if (!previousNodeId && nextNodeId) {
        updateWorkflow(workflowId, { startNode: nextNodeId })
      }
    }
    removeNodes(deletedNodes)
  }

  // update all the parent nodes in which secondary node was deleted.
  const alreadyUpdatedList = {}
  for (const deletedNode of finalDeleteList) {
    if (
      deletedNode.data.connectionType === NEXT_NODE_CONNECTION_TYPE.SECONDARY
    ) {
      const previousNodeId = deletedNode.data.previousNodeId
      // skip if this is already updated
      if (alreadyUpdatedList[previousNodeId]) continue

      // get all the remaining secondary nodes for the node from which secondary node was deleted.
      const secondaryNodes = nodes.value.filter(
        node =>
          node.data.previousNodeId === previousNodeId &&
          node.data.connectionType === NEXT_NODE_CONNECTION_TYPE.SECONDARY
      )

      // create update data with remaining secondary node
      const updateData = {
        secondaryNodes: secondaryNodes.map(node => ({
          nodeId: node.id,
          position:
            node.data.targetHandle === Position.Bottom
              ? Position.Top
              : Position.Bottom
        }))
      }
      alreadyUpdatedList[previousNodeId] = true
      updateWorkflowNode(workflowId, previousNodeId, updateData)
    }
  }

  const nodeIds = finalDeleteList.map(node => node.id)
  deleteWorkflowNodes(workflowId, nodeIds)
  return nodeIds
}

// update the edges and neighbouring node next and previous nodes.
const updateNeighbouringNodes = (
  workflowId,
  previousNodeId,
  nextNodeId,
  edges,
  nodes
) => {
  if (previousNodeId && nextNodeId) {
    edges.value = edges.value.concat({
      id: `${previousNodeId}${Position.Right}-${nextNodeId}`,
      type: EDGE_TYPE_NAMES.CONNECTION_LINE,
      source: previousNodeId,
      sourceHandle: Position.Right,
      target: nextNodeId,
      targetHandle: Position.Left
    })
    Promise.all([
      updateWorkflowNode(workflowId, previousNodeId, {
        nextNode: nextNodeId
      }),
      updateWorkflowNode(workflowId, nextNodeId, {
        previousNode: previousNodeId
      })
    ])
  } else if (previousNodeId) {
    nodes.value = nodes.value.map(node => {
      if (previousNodeId === node.id) {
        node.data.nextNodeId = null
      }
      return node
    })
    updateWorkflowNode(workflowId, previousNodeId, {
      nextNode: null
    })
  } else if (nextNodeId) {
    nodes.value = nodes.value.map(node => {
      if (nextNodeId === node.id) {
        node.data.previousNodeId = null
      }
      return node
    })
    updateWorkflowNode(workflowId, nextNodeId, {
      previousNode: null
    })
  }
}

const getAllNodesAfterRouter = (deletedNodes, nodes) => {
  const routerNode = deletedNodes.find(
    node => node.data.automationData.utilityType === UTILITY_TYPES.ROUTER
  )

  if (routerNode) {
    return getAllNodesInTheDirection(routerNode.position, nodes, Position.Right)
  }
}

// function to get the edge node in the given direction
const getEdgeNodes = (deletedNodes, direction) => {
  return deletedNodes.reduce((currentEdgeMost, node) => {
    // if current node is to the left of the currentEdgeMost
    const condition =
      direction === Position.Left
        ? currentEdgeMost.position.x >= node.position.x
        : currentEdgeMost.position.x <= node.position.x
    if (
      condition &&
      node.data.connectionType === NEXT_NODE_CONNECTION_TYPE.PRIMARY
    ) {
      return node
    }
    return currentEdgeMost
  }, deletedNodes[0])
}

// delete all secondary nodes of delete nodes
const getSecondaryNodes = (deletedNodes, nodes, edges) => {
  const nodeIdMap = {}
  nodes.value.forEach(node => (nodeIdMap[node.id] = node))
  const secondaryNodesToDelete = []
  deletedNodes.forEach(node => {
    const secondaryNodes = edges.value
      .filter(
        edge =>
          (edge.targetHandle === Position.Top ||
            edge.targetHandle === Position.Bottom) &&
          edge.source === node.id &&
          nodeIdMap[edge.target].data.connectionType ===
            NEXT_NODE_CONNECTION_TYPE.SECONDARY
      )
      .map(edge => nodeIdMap[edge.target])
    secondaryNodesToDelete.push(...secondaryNodes)
  })
  return secondaryNodesToDelete
}

const getPreviousNode = (node, edges) => {
  if (!node) return
  const edge = edges.value.find(
    edge =>
      node.id === edge.target &&
      (edge.targetHandle === Position.Left ||
        edge.targetHandle === Position.Top ||
        edge.targetHandle === Position.Bottom)
  )
  return edge?.source
}
const getNextNode = (node, edges) => {
  if (!node) return
  const edge = edges.value.find(
    edge => node.id === edge.source && edge.sourceHandle === Position.Right
  )
  return edge?.target
}
