<template>
  <AutomationHeader title="Invites" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <div
      class="flex h-full min-w-0 flex-1 flex-col border-l-0 border-gray-200 dark:border-gray-700"
    >
      <div class="flex h-full items-center justify-center">
        <Spinner v-if="loader" size="large" />
        <div class="text-center font-[500]" v-else>
          <h2
            class="text-2xl"
            :class="[
              { 'text-green-600': messageType === 'success' },
              { 'text-red-600': messageType === 'fail' }
            ]"
          >
            {{ messageText }}
          </h2>
          <RouterLink to="/automation-store?platform=LinkedIn">
            Click Here To Go Back To Automation Store</RouterLink
          >
        </div>
      </div>
      <!-- Accept -->
    </div>
  </main>
</template>
<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import Spinner from '@/components/Spinner.vue'
//api
import { acceptInvite } from '@/apis/workspace'
export default {
  components: {
    AutomationHeader,
    Spinner
  },
  created() {
    if (this.$route.query.inviteId && this.$route.query.workspaceId) {
      this.acceptInviteFromUrl()
    } else {
      this.showRedirect = true
      this.loader = false
    }
  },
  data() {
    return {
      loader: true,
      showRedirect: false,
      messageType: null,
      messageText: ''
    }
  },
  methods: {
    async acceptInviteFromUrl() {
      try {
        const response = await acceptInvite(
          this.$route.query.inviteId,
          this.$route.query.workspaceId
        )
        if (response['success']) {
          this.messageType = 'success'
          this.messageText = 'You Have Successfully Accepted the Invite'
          this.$emit('success', 'Invite Accepted Successfully ')
        } else {
          throw response.message
        }
      } catch (error) {
        this.messageType = 'fail'
        this.messageText = error
        this.$emit('error', error)
      }
      this.loader = false
      this.showRedirect = true
    }
  }
}
</script>
