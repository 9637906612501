import axios from '@/common/axios'

const getDesktop = async () => {
  try {
    const response = await axios.get(`desktop`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const inviteDesktop = async name => {
  try {
    const response = await axios.post(`desktop/invite`, { name })
    return response.data
  } catch (error) {
    return error.response.data
  }
}
const deleteDesktop = async id => {
  try {
    const response = await axios.delete(`desktop/${id}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const renameDesktop = async (id, name) => {
  try {
    const response = await axios.put(`desktop/${id}`, { name })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export { getDesktop, inviteDesktop, deleteDesktop, renameDesktop }
