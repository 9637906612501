<template>
  <div class="flex-1 overflow-y-auto overflow-x-hidden">
    <div class="px-4 py-5 sm:p-5">
      <div class="">
        <Input
          class="mx-auto mb-3 max-w-lg"
          leftIcon="search"
          text="Search"
          @input="inputChange"
        />
        <div class="mt-1 grid grid-cols-4 gap-6">
          <div v-for="item in filteredData" :key="item._id">
            <div
              class="transform rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:-translate-y-1 hover:border-blue-600 hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:hover:border-blue-500"
            >
              <div class="p-4">
                <div class="cursor-pointer">
                  <div
                    class="flex items-center justify-between"
                    @click="onClick(item)"
                  >
                    <img
                      class="h-10 w-10 shrink-0 rounded-full object-cover"
                      :src="item.logoUrl"
                      alt=""
                    />
                    <div
                      class="ml-3 flex-1 text-sm font-semibold text-gray-900 dark:text-gray-50"
                    >
                      {{ item.label }}
                    </div>
                    <svg
                      :key="item.connected"
                      v-if="item.connected"
                      class="w-4 h-4 text-green-600 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx="4" cy="4" r="3"></circle>
                    </svg>
                    <!-- TODO: Might need later -->
                    <!-- <div class="ml-auto" v-if="item.auth">
                      <Button
                        :dataAttribute="item._id"
                        class="text-[0px]"
                        :name="
                          item.type === 'automation' ? 'texau-extension-v2' : ''
                        "
                        :icon="
                          item.type === 'automation' ? 'external-link' : 'plus'
                        "
                        :text="
                          item.type === 'automation'
                            ? 'Install Extension'
                            : null
                        "
                        size="small"
                        @click="
                          event => {
                            event.stopPropagation()
                            modalToggle(item, event)
                          }
                        "
                      />
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IntegrationsHeader from '@/components/settings/integrationsHeader.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'

//API call
import { getAllIntegrations } from '@/apis/settings/settings-integration'
import { startOauthConnection } from '@/apis/oauth'
import Input from '../Input.vue'

export default {
  name: 'allIntegrationsSection',
  components: {
    IntegrationsHeader,
    SvgIcon,
    Button,
    Input
  },

  data() {
    return {
      filteredData: null,
      ArrayData: null,

      listData: null
    }
  },

  created() {
    this.getIntegrations()
  },
  methods: {
    async getIntegrations() {
      if (sessionStorage.integrationData) {
        this.listData = JSON.parse(sessionStorage.integrationData)
        this.filteredData = JSON.parse(sessionStorage.integrationData)
        this.filterArray('integration')
        this.silentDataUpdate()
      } else {
        try {
          const response = await getAllIntegrations()
          sessionStorage.setItem(
            'integrationData',
            JSON.stringify(response.data)
          )
          this.listData = response.data
          this.filteredData = response.data
          this.filterArray('integration')
        } catch (error) {
          console.log(error)
          this.$emit('emitError', {
            type: 'fail',
            value: 'Something Went Wrong Please Try Again Later'
          })
        }
      }
    },
    async silentDataUpdate() {
      //silent integration update to keep up with new connected integrations
      const response = await getAllIntegrations()
      this.listData = response.data
      this.filteredData = response.data
      this.filterArray('integration')
    },
    filterArray() {
      this.filteredData = this.listData.filter(
        list =>
          list.auth &&
          (list.auth.type === 'apiKey' || list.auth.type === 'OAuth2') &&
          list.auth.isOptional === false
      )
      this.ArrayData = this.filteredData.slice()
    },

    async modalToggle(item, e) {
      if (e.currentTarget.innerText === 'Connect Account') {
        return
      } else if (item.auth.type === 'OAuth2') {
        this.addOauthAccount(item.name)
      } else {
        let val = true
        item['update'] = false
        this.$emit('modalActivation', { item, val })
      }
    },
    async addOauthAccount(name) {
      const response = await startOauthConnection(name)

      if (response['success']) {
        window.open(response.data.url, '_blank')
      } else {
        this.$emit('error', 'Error in adding new google account')
      }
    },
    inputChange(e) {
      const searchStr = e.target.value.toLowerCase()
      this.filteredData = this.ArrayData.filter(value => {
        return value.label.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1
      })
    },
    onClick(item) {
      const val = 'accounts'
      this.$emit('showAccounts', { val, item })
    }
  }
}
</script>

<style scoped></style>
