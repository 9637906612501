<template>
  <div class="col-span-3 rounded-lg bg-blue-50 dark:bg-blue-900/50">
    <div class="space-y-5 px-4 py-5 sm:p-6">
      <p
        v-if="input"
        class="text-base font-semibold text-gray-900 dark:text-gray-50"
      >
        When You Input This:
      </p>
      <p
        v-if="output"
        class="text-base font-semibold text-gray-900 dark:text-gray-50"
      >
        TexAu Will Output This:
      </p>

      <!-- input List -->
      <div class="flex flex-wrap gap-4 pt-2" v-if="type === 'input'">
        <span
          v-for="list in lists[0]"
          :key="list"
          class="inline-flex items-center rounded-lg bg-blue-100 px-3 py-1.5 text-sm font-semibold text-blue-600 dark:bg-blue-900 dark:text-blue-50"
        >
          {{ list.label }}
          <SvgIcon
            class="mb-3 ml-1 h-2 w-2 text-red-600"
            v-show="list.isRequired"
            name="star"
          />
        </span>
      </div>

      <!-- output List -->
      <div class="flex flex-wrap gap-4 pt-2" v-if="type === 'output'">
        <span
          v-for="list in spanList"
          :key="list"
          class="inline-flex items-center rounded-lg bg-blue-100 px-3 py-1.5 text-sm font-semibold text-blue-600 dark:bg-blue-900 dark:text-blue-50"
        >
          {{ list.label }}
        </span>

        <span
          v-show="isShowMoreVisible"
          @click="showMoreClicked()"
          class="cursor-pointer rounded-lg border-2 bg-slate-200 px-3 py-1.5 text-sm font-semibold text-black"
          >{{
            show ? 'Show less ...' : `See All(+${spanListMoreNumber} more)`
          }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Input from './Input.vue'
import SvgIcon from './SvgIcon.vue'
import Button from './Button.vue'

export default {
  name: 'InputOutputCard',
  data() {
    return {
      show: false
    }
  },
  created() {
    if (this.lists[0].length === 0) {
      this.lists[0].push({
        _id: 1,
        name: 'Account'
      })
    }
  },

  props: {
    type: { type: String },
    lists: { type: Array }
  },
  computed: {
    input() {
      if (this.type === 'input') return true
    },
    output() {
      if (this.type === 'output') return true
    },
    spanList() {
      if (this.show === false) {
        return this.lists[1].slice(0, 2)
      } else if (this.show === true) {
        return this.lists[1]
      }
    },

    spanListMoreNumber() {
      return this.lists[1].length - 2
    },
    isShowMoreVisible() {
      return this.lists[1].length > 2
    }
  },
  methods: {
    showMoreClicked() {
      this.$emit('toggleModalOpen', this.lists[1])
    }
  },
  components: { Input, SvgIcon, Button }
}
</script>

<style></style>
