<template>
  <div @dblclick="openResult">
    <SvgIcon
      :name="data.automationData.logoUrl"
      class="h-[40px] w-[40px] cursor-pointer"
      :class="{
        'rounded-full border-2 border-blue-500': selected
      }"
    />
    <p
      class="absolute -left-[25px] mt-2 w-[90px] overflow-hidden rounded-lg bg-white bg-opacity-40 p-1 text-center text-xs font-semibold text-gray-900 group-hover:text-blue-600 dark:text-gray-50 dark:group-hover:text-gray-50"
      v-if="nodeLabel"
    >
      {{ nodeLabel }}
    </p>

    <Handle
      v-for="{ position, connected, style, isRouter } in handlers"
      :id="position"
      :type="getHandleType(position)"
      :position="position"
      :style="style"
      class="h-[10px] w-[10px]"
      :class="{
        'bg-blue-200':
          connected &&
          (position === 'bottom' || position === 'top') &&
          !isRouter,
        'bg-blue-500':
          connected && !(position === 'bottom' || position === 'top'),
        'bg-green-500': connected && isRouter && position === 'top',
        'bg-red-500': connected && isRouter && position === 'bottom',

        invisible: !connected
      }"
      :connectable="false"
    />
  </div>
</template>

<script setup>
import { UTILITY_TYPES } from '@/common/constants'
import SvgIcon from '@/components/SvgIcon.vue'
import { Handle, Position, useVueFlow } from '@vue-flow/core'
import { onMounted, ref, watch } from 'vue'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  type: {
    type: String
  },
  label: {
    type: String
  },
  data: {
    type: Object
  },
  position: {
    type: Object
  },
  menuOpenId: {
    type: String
  },
  selected: {
    type: Boolean
  },
  rightPanelSelectedNode: {},
  readonly: Boolean
})

const { edges } = useVueFlow()

const emit = defineEmits([
  'updateNodeInternals',
  'error',
  'toggle-menu',
  'open-result'
])

const handlers = ref([])
const nodeLabel = ref()

watch(
  () => edges.value.length,
  () => setHandles()
)

watch(
  () => props.data.savedInputs,
  n => {
    setNodeLabel()
  }
)

onMounted(() => {
  setHandles()
  setNodeLabel()
})

const setNodeLabel = () => {
  if (
    UTILITY_TYPES.DELAY === props.data?.automationData.utilityType &&
    props.data.savedInputs &&
    props.data.savedInputs?.delayValue &&
    props.data.savedInputs?.delayUnit
  ) {
    nodeLabel.value = `Delay for ${props.data.savedInputs?.delayValue} ${props.data.savedInputs?.delayUnit}`
  }
}

const setHandles = () => {
  const allConnectedHandles = edges.value
    .filter(e => e.source === props.id)
    .map(e => e.sourceHandle)
    .concat(
      edges.value.filter(e => e.target === props.id).map(e => e.targetHandle)
    )

  if (props.data.automationData.utilityType === UTILITY_TYPES.ROUTER) {
    // add two handles for routers
    handlers.value = handlers.value.concat(
      {
        position: Position.Top,
        style: {
          right: '-3px',
          left: 'auto',
          top: '0px'
        },
        connected: allConnectedHandles.includes(Position.Top),
        isRouter: true
      },
      {
        position: Position.Bottom,
        style: {
          right: '-3px',
          left: 'auto',
          bottom: '0px'
        },
        connected: allConnectedHandles.includes(Position.Bottom),
        isRouter: true
      },
      {
        position: Position.Left,
        style: {},
        connected: allConnectedHandles.includes(Position.Left)
      }
    )
  } else {
    if (
      allConnectedHandles.includes(Position.Bottom) ||
      allConnectedHandles.includes(Position.Top)
    ) {
      handlers.value = handlers.value.concat({
        position: allConnectedHandles[0],
        style: {},
        connected: true
      })
    } else {
      handlers.value = handlers.value.concat(
        {
          position: Position.Right,
          style: {},
          connected: allConnectedHandles.includes(Position.Right)
        },
        {
          position: Position.Left,
          style: {},
          connected: allConnectedHandles.includes(Position.Left)
        }
      )
    }
  }
}

const openResult = () => {
  if (
    UTILITY_TYPES.INCOMINGWEBHOOK === props.data?.automationData.utilityType
  ) {
    emit('open-result', {
      _id: props.id,
      ...props.data.automationData
    })
  }
}

const getHandleType = pos =>
  props.data.targetHandle === pos ? 'target' : 'source'
</script>
