import axios from '@/common/axios'

const createWorkspace = async (
  workspaceName,
  organisationId,
  orgUserId,
  timezone
) => {
  try {
    const response = await axios.post('/workspaces', {
      workspaceName,
      orgUserId,
      organisationId,
      timezone
    })
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const getWorkspaceById = async workspaceId => {
  try {
    const response = await axios.get(`/workspaces/${workspaceId}`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const updateOrganisationDetails = async (organisationId, data) => {
  try {
    const response = await axios.put(`/organisations/${organisationId}`, data)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const getWorkspaceMembers = async workspaceId => {
  try {
    const response = await axios.get(`/workspaces/${workspaceId}/members`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const inviteUser = async (workspaceId, email, name, roleId) => {
  try {
    const response = await axios.post(`/workspaces/${workspaceId}/invites`, {
      email,
      name,
      roleId
    })
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const getPendingInvites = async ({ start = 0, limit = 20 }) => {
  try {
    const response = await axios.get(
      `/base-users/invites?start=${start}&limit=${limit}`
    )
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const getSentInvites = async ({ start = 0, limit = 20 }) => {
  try {
    const response = await axios.get(
      `/invitations?start=${start}&limit=${limit}`
    )
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const reSendInvite = async inviteId => {
  try {
    const response = await axios.get(`/invitations/${inviteId}/re-send`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const removeInvite = async inviteId => {
  try {
    const response = await axios.delete(`/invitations/${inviteId}`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const updateMemberRole = async (workspaceId, memberId, newRoleId) => {
  try {
    const response = await axios.put(
      `/workspaces/${workspaceId}/members/${memberId}`,
      { newRoleId }
    )
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const deleteMember = async (workspaceId, memberId) => {
  try {
    const response = await axios.delete(
      `/workspaces/${workspaceId}/members/${memberId}`
    )
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const getAllWorkspaces = async (userId, pageNo = 0, pageSize = 50) => {
  try {
    const response = await axios.get(
      `/users/${userId}/workspaces?pageNo=${pageNo}&pageSize=${pageSize}`
    )
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const UpdateLimits = async (accountId, data, platformOperationId) => {
  try {
    const response = await axios.put(`/accounts/${accountId}/limits`, {
      operationId: platformOperationId,
      executionLimit: data
    })
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const acceptInvite = async (inviteId, workspaceId) => {
  try {
    const response = await axios.post(
      `/workspaces/${workspaceId}/invites/${inviteId}`
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const rejectInvite = async (inviteId, workspaceId) => {
  try {
    const response = await axios.put(
      `/workspaces/${workspaceId}/invites/${inviteId}`
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const updateWorkspaceName = async (workspaceId, orgUserId, data) => {
  try {
    const response = await axios.put(`/workspaces`, data, {
      headers: {
        'X-TexAu-Context': JSON.stringify({
          orgUserId: orgUserId,
          workspaceId: workspaceId
        })
      }
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const deleteWorkspace = async (workspaceId, orgUserId) => {
  try {
    const response = await axios.delete(`/workspaces`, {
      headers: {
        'X-TexAu-Context': JSON.stringify({
          orgUserId: orgUserId,
          workspaceId: workspaceId
        })
      }
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getAllOrganisationUsers = async orgId => {
  try {
    const response = await axios.get(`/organisations/${orgId}/users`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const leaveInvitedWorkspace = async data => {
  try {
    const response = await axios.post(`/workspaces/leave`, data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export {
  createWorkspace,
  getWorkspaceById,
  updateOrganisationDetails,
  getWorkspaceMembers,
  inviteUser,
  updateMemberRole,
  deleteMember,
  getAllWorkspaces,
  UpdateLimits,
  acceptInvite,
  getPendingInvites,
  rejectInvite,
  updateWorkspaceName,
  deleteWorkspace,
  getSentInvites,
  reSendInvite,
  removeInvite,
  getAllOrganisationUsers,
  leaveInvitedWorkspace
}
