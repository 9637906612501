import axios from "@/common/axios";

const getMetaDataFromApi = async () => {
    try {
        const response = await axios.get(`/metadata`);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

const getPlanLimit = async () => {
    try {
        const response = await axios.get(`/organisations/limits`);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

export {
    getMetaDataFromApi,
    getPlanLimit,
}

