<template>
  <ForceDeleteModal
    v-if="forceDeleteModal"
    forceDeleteType="proxy"
    :force-delete-id="forceDeleteId"
    @close="forceDeleteModal = false"
    @error="data => $emit('error', data)"
    @success="proxyForceDeleteSuccess"
  />

  <AutomationHeader title="Proxies" />

  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <SettingsMenu activeOption="Proxies" />

    <div class="relative flex-1 border-l border-gray-200 dark:border-gray-700">
      <div
        v-if="userRole && userRole !== 'owner'"
        class="flex items-center h-full justify-center"
      >
        <div class="flex gap-2 items-center text-red-500">
          <SvgIcon class="h-5 w-5" name="important" />
          <p class="font-[500] text-xl">Only Owner Can Access This Page</p>
        </div>
      </div>
      <template v-else>
        <!-- <div
          v-if="proxyAccess === false || userData.isAdmin === true"
          class="flex items-center px-4 sm:px-5 pt-2 justify-end"
        > -->
        <div class="flex items-center px-4 sm:px-5 pt-2 justify-end">
          <Button
            text="New Proxy"
            leftIcon="plus"
            class="h-[33px]"
            @click="toggleModalOpen(true)"
          />
        </div>
        <div class="px-4 h-full flex flex-col flex-1 py-5 sm:p-5">
          <nav aria-label="Tabs" class="flex justify-center space-x-4">
            <a
              :class="[
                {
                  'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-50':
                    tab === 'social'
                },
                {
                  'text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
                    tab === 'rotating'
                }
              ]"
              aria-current="page"
              class="rounded-lg px-3 py-2 text-sm font-medium transition-all duration-200"
              href="#"
              title=""
              @click.prevent="tab = 'social'"
            >
              Social Proxies
            </a>
            <!-- TODO: will be needed for later -->
            <!-- <a
            :class="[
              {
                'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-50':
                  tab === 'rotating'
              },
              {
                'text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
                  tab === 'social'
              }
            ]"
            class="rounded-lg px-3 py-2 text-sm font-medium transition-all duration-200"
            href="#"
            title=""
            @click.prevent="tab = 'rotating'"
          >
            Rotating Proxy
          </a> -->
          </nav>
          <!-- todo: refresh on purchase -->
          <!-- todo:show provided by texau -->
          <SocialProxyMain
            :key="rerender"
            v-if="tab === 'social'"
            @error="data => $emit('error', data)"
            @success="data => $emit('success', data)"
            @showPurchaseModal="modalActivePurchase = true"
            @showForceDeleteModal="showForceDeleteModal"
          />

          <RotatingProxyMain v-if="tab === 'rotating'" />
        </div>
      </template>
    </div>
  </main>

  <AddRotatingProxyModal
    v-show="modalActiveRotating"
    @close="toggleModalClose"
  />
  <AddSocialProxyModal
    v-show="modalActiveSocial"
    :key="modalActiveSocial"
    @close="toggleModalClose"
    @error="data => $emit('error', data)"
    @success="onSuccessSocialProxy()"
  />
  <ProxyPurchaseModal
    @close="modalActivePurchase = false"
    @refresh="successProxyPurchase"
    @apiFail="apiFail"
    v-if="modalActivePurchase"
  />
</template>

<script>
import AddRotatingProxyModal from '@/components/settings/RotatingProxy/AddRotatingProxyModal.vue'
import AddSocialProxyModal from '@/components/settings/RotatingProxy/AddSocialProxyModal.vue'
import ProxyPurchaseModal from '@/components/settings/RotatingProxy/ProxyPurchaseModal.vue'
import SocialProxyMain from '@/components/settings/RotatingProxy/SocialProxyMain.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import ForceDeleteModal from '@/components/settings/WorkspaceManagerCloud/ForceDeleteModal.vue'
import { ref } from 'vue'
import AutomationHeader from '../../../components/automationStore/AutomationHeader.vue'
import RotatingProxyMain from '../../../components/settings/RotatingProxy/RotatingProxyMain.vue'
import { mapState, mapActions } from 'vuex'
import Button from '@/components/Button.vue'

export default {
  components: {
    AutomationHeader,
    SettingsMenu,
    RotatingProxyMain,
    SocialProxyMain,
    AddRotatingProxyModal,
    AddSocialProxyModal,
    ProxyPurchaseModal,
    ForceDeleteModal,
    Button
  },
  name: 'rotatingProxy',
  data() {
    return {
      tab: 'social',
      modalActiveSocial: ref(false),
      modalActiveRotating: ref(false),
      modalActive: ref(false),
      modalActivePurchase: ref(false),
      rerender: 1,
      forceDeleteModal: false,
      forceDeleteId: null
    }
  },
  computed: {
    ...mapState('user', ['proxyAccess']),
    ...mapState('user', ['userData']),
    ...mapState('settings', ['userRole'])
  },
  methods: {
    toggleModalOpen(value) {
      this.modalActive = value
      if (this.tab === 'social') {
        this.modalActiveSocial = value
      } else if (this.tab === 'rotating') {
        this.modalActiveRotating = value
      }
    },
    toggleModalClose() {
      this.modalActive = false
      if (this.tab === 'social') {
        this.modalActiveSocial = false
      } else if (this.tab === 'rotating') {
        this.modalActiveRotating = false
      }
    },
    proxyForceDeleteSuccess() {
      this.$emit('success', 'Proxy Deleted Successfully')
      this.rerender += 1
    },
    showForceDeleteModal(data) {
      this.forceDeleteModal = true
      this.forceDeleteId = data
    },
    successProxyPurchase() {
      this.$emit('success', 'Proxy Bought Successfully')
      this.rerender++
    },
    apiFail(data) {
      this.$emit('error', data)
    },
    onSuccessSocialProxy(data) {
      this.$emit('success', data)
    }
  }
}
</script>

<style></style>
