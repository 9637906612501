<!-- TextArea Component -->
<!-- by default type will be normal, type can be passed as type='normal' or type='error' -->
<!-- disable can be passes as attribute for getting disabled textarea -->

<template>
  <div>
    <label v-if="label" for="" :class="labelClass" class="flex select-none">
      {{ label }}
      <SvgIcon
        class="mb-3 ml-1 h-2 w-2 text-red-600"
        name="star"
        v-if="isRequired"
      />
    </label>
    <textarea
      name=""
      id=""
      rows="4"
      class="block w-full resize-y rounded-lg border px-3 py-2.5 placeholder-gray-500 transition-all duration-200 sm:text-sm"
      :class="[
        {
          'border-gray-300 caret-blue-600 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500':
            normal,
          'border-red-600 text-red-600 caret-red-600 hover:border-red-700 focus:border-red-600 focus:ring-red-600 dark:border-red-500 dark:bg-gray-900 dark:text-red-500 dark:hover:border-red-400 dark:focus:border-red-500 dark:focus:ring-red-500':
            error,
          'pointer-events-none border border-gray-300 placeholder-gray-500 caret-blue-600 caret-blue-600 opacity-50 hover:border-gray-400 focus:border-blue-600 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm':
            disabled
        }
      ]"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="modelValue"
      @input="updateValue"
      @blur="$emit('blur', $event.target.value)"
    />
  </div>
</template>

<script>
import SvgIcon from './SvgIcon.vue'

export default {
  name: 'TextArea',
  emits: ['update:modelValue', 'blur'],
  props: {
    modelValue: {
      type: String
    },
    type: {
      type: String,
      default: 'normal'
    },
    isRequired: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    label: { type: String },
    labelClass: { type: String },
    placeholder: { type: String }
  },
  computed: {
    normal() {
      return this.type === 'normal' || this.type === 'Normal'
    },
    error() {
      return this.type === 'error' || this.type === 'Error'
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  },
  components: { SvgIcon }
}
</script>
