import axios from '@/common/axios'

const getAllPlatforms = async (
  start = 0,
  limit = 50,
  type = 'automation',
  q = ''
) => {
  try {
    const response = await axios.get(
      `/platforms?start=${start}&limit=${limit}&type=${type}&q=${q}`
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getPlatformOperations = async (
  platformId,
  q,
  start = 0,
  limit = 25,
  category
) => {
  try {
    const response = await axios.get(`/platforms/${platformId}/operations`, {
      params: {
        start,
        limit,
        q,
        category
      }
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getOperation = async (platformId, operationId) => {
  try {
    const response = await axios.get(
      `/platforms/${platformId}/operations/${operationId}`
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getOperationVariableServices = async (
  platformId,
  operationId,
  direction,
  workflowNodeId,
  signal
) => {
  try {
    const response = await axios.get(
      `/platforms/${platformId}/operations/${operationId}/variables`,
      {
        params: {
          direction,
          workflowNodeId
        },
        signal
      }
    )
    return response.data
  } catch (error) {
    return error?.response?.data || error.message
  }
}

export {
  getAllPlatforms,
  getOperation,
  getOperationVariableServices,
  getPlatformOperations
}
