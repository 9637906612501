import { getOperationVariableServices } from '@/apis/getPlatformDetails'

export const deleteVariables = ({ commit }, payload) => {
  commit('DELETE_VARIABLES', payload)
}

export const resetVariables = ({ commit }) => {
  commit('RESET_VARIABLES')
}
export const setWebhookInputs = ({ commit }, payload) => {
  commit('SET_WEBHOOK_INPUTS', payload)
}

export const setVariables = async (
  { commit, state },
  { platformOperationId, platformId, variables }
) => {
  if (platformOperationId === 'webhook') {
    return commit('SET_VARIABLES', { platformOperationId, variables })
  }
  if (state.variablesHashMap.has(platformOperationId)) return
  try {
    const response = await getOperationVariableServices(
      platformId,
      platformOperationId
    )
    commit('SET_VARIABLES', { platformOperationId, variables: response.data })
  } catch (error) {}
}

export const setDynamicOutputs = async (
  { commit },
  { nodeId, dynamicOutputs }
) => {
  commit('SET_DYNAMIC_OUTPUTS', { nodeId, dynamicOutputs })
}

export const setRouterAdded = ({ commit }, toggle) => {
  commit('SET_ROUTER_ADDED', toggle)
}
