import { constants } from '../../common/constants.js'

const STAGING = constants.growSurfCampaignId //  staging campaign ID

const PRODUCTION = null // production campaign ID

const CAMPAIGN_ID = STAGING || PRODUCTION

// Call the GrowSurf Universal Code

;(function (g, r, s, f) {
  g.grsfSettings = { campaignId: CAMPAIGN_ID, version: '2.0.0' }
  s = r.getElementsByTagName('head')[0]
  f = r.createElement('script')
  f.async = 1
  f.src =
    'https://app.growsurf.com/growsurf.js' + '?v=' + g.grsfSettings.version
  f.setAttribute('grsf-campaign', g.grsfSettings.campaignId)
  !g.grsfInit ? s.appendChild(f) : ''
})(window, document)
