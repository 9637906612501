<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <div v-if="loading" class="flex h-full items-center justify-center">
      <Spinner size="large" />
    </div>
    <!-- START WRAPPER -->
    <div v-else class="flex min-h-screen items-stretch justify-between">
      <!-- START LEFT COLUMN -->
      <OnboardingLeftColumn />
      <!-- END LEFT COLUMN -->

      <!-- START RIGHT COLUMN -->
      <div class="flex-1 bg-white dark:bg-gray-900">
        <div class="grid h-full items-start justify-center">
          <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
            <div class="flex h-full max-w-md flex-col">
              <a href="#" title="TexAu" class="">
                <img
                  class="mx-auto h-12 w-auto xl:h-14"
                  src="@/assets/images/logo-alt.png"
                  alt="TexAu logo"
                />
              </a>

              <div class="mt-12 grid h-full place-items-center">
                <div>
                  <img
                    class="mx-auto w-full max-w-xs"
                    src="@/assets/images/onboarding/illustrations/importing-accounts.png"
                    alt=""
                  />

                  <div class="mt-12 text-center">
                    <Heading text="Import your accounts now" />
                    <p class="mt-4 text-base text-gray-600 dark:text-gray-400">
                      Login to the social media accounts you want to use in a
                      new tab, TexAu will now import them.
                    </p>

                    <div
                      class="relative mt-8 h-2 w-full rounded-full bg-gray-100 dark:bg-gray-600"
                    >
                      <div
                        id="texau-v2-import-progress-bar"
                        class="absolute inset-y-0 left-0 w-[0%] rounded-full bg-blue-600 dark:bg-blue-500"
                      ></div>
                    </div>

                    <p class="mt-6 text-sm text-gray-600 dark:text-gray-400">
                      Importing your accounts (<span
                        id="texau-v2-import-progress-percentage"
                        >0</span
                      >%)
                    </p>

                    <div
                      @click="onNextClick()"
                      id="texau-v2-on-import-complete"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END RIGHT COLUMN -->
    </div>
    <!-- END WRAPPER -->
    <ToastContainer class="w-1/5" ref="toast" />
  </div>
</template>

<script>
import OnboardingLeftColumn from '../../components/onboarding/OnboardingLeftColumn.vue'
import Heading from '@/components/onboarding/Heading.vue'
import { updateUserDetail } from '@/apis/user'
import UserProfile from '@/common/userWrapper'
import ToastContainer from '@/components/ToastContainer.vue'
import Spinner from '@/components/Spinner.vue'
export default {
  components: { OnboardingLeftColumn, Heading, ToastContainer, Spinner },
  name: 'Importing',
  data() {
    return {
      loading: false
    }
  },
  async mounted() {
    if (
      this.$route.query.newImport === null ||
      this.$route.query.newImport === undefined
    ) {
      this.loading = true
      await updateUserDetail({ currentOnboardingStep: 'Trigger Extension' })
      UserProfile.updateOnboardingStateInCookies('Trigger Extension')
      await this.$router.push('/onboarding/start-import')
    }
  },
  methods: {
    async onNextClick() {
      const responseUser = await updateUserDetail({
        currentOnboardingStep: 'Accounts'
      })
      UserProfile.updateOnboardingStateInCookies('Accounts')
      if (responseUser['success']) {
        await this.$router.push('/onboarding/accounts')
      } else {
        this.$refs.toast.addToast({
          timeout: 7000,
          text: 'Error Occurred!',
          color: 'red',
          caption: 'Error in moving to next step.',
          action: false,
          close: true
        })
      }
    }
  }
}
</script>

<style></style>
