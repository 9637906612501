<template>
  <Spinner v-if="loading" size="large" class="m-auto" />

  <main
    v-if="!loading && automationStoreData.name"
    class="flex min-w-0 flex-1 flex-col overflow-y-auto"
  >
    <AutomationHeader
      :nav="true && currentComp !== 'output-comp'"
      :title="automationStoreData.name"
      :iconUrl="automationStoreData.logoUrl"
      :highlight="highlight"
      @onNavClick="nextButtonClickFromNav"
      :key="highlight"
      :navText="getNavText"
      @openOutputModal="outputModal = true"
      :operation="operation"
      @success="e => $emit('success', e)"
    />

    <section class="flex flex-1 border-t border-gray-200 dark:border-gray-700">
      <OpenResultsInGoogleSheetsModal
        v-if="googleSheetsModal"
        @close="googleSheetsModal = false"
      />
      <OutputTagsModal @close="outputModal = false" v-if="outputModal" />
      <OptionalSidebar
        v-if="currentComp !== 'output-comp'"
        :search="optionalSidebarSearch"
        :type="optionalSidebarType"
        :item="optionalSidebarItem"
        :key="optionalSidebarType"
        @error="onError"
      />

      <component
        ref="inputFormRef"
        :is="currentComp"
        @nextButtonClick="nextButtonClick"
        @error="onError"
        @success="onSuccess"
        :compData="compData"
        @removeCompData="removeCompData"
        @afterFormValidate="afterFormValidate"
        @AfterFormValidateFromNav="AfterFormValidateFromNav"
        @deleteApiResponse="deleteApiResponse"
        :validateForm="validateForm"
        @resetFromValidation="resetFromValidation"
        :startAutomation="startAutomation"
        :isScheduled="isScheduled"
        @openModal="googleSheetsModal = true"
        @setScheduledStatus="setScheduledStatus"
      ></component>
    </section>
  </main>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import OptionalSidebar from '@/components/automationStore/OptionalSidebar.vue'
import OutputTagsModal from '@/components/automationStore/OutputTagsModal.vue'

//Main Part
import AutomationOutput from '@/components/automationStore/AutomationOutput.vue'
import DirectInputMain from '@/components/automationStore/directInput/DirectInputMain.vue'
import DirectInputRun from '@/components/automationStore/directInput/DirectInputRun.vue'
import GoogleSheetsMain from '@/components/automationStore/googleSheets/googleSheetsMain.vue'
import CsvMain from '@/components/automationStore/csv/CsvMain.vue'
import SchedulingMode from '@/components/automationStore/SchedulingMode.vue'
import OutputMode from '@/components/automationStore/OutputMode.vue'
import Spinner from '@/components/Spinner.vue'
import ToastContainer from '@/components/ToastContainer.vue'
import PublicWorkflowMain from '@/components/automationStore/publicWorkflow/PublicWorkflowMain.vue'
import IterationDelay from '@/components/IterationDelay.vue'

import {
  getOperationVariableServices,
  getPlatformInfo,
  getPlatformVariables
} from '@/apis/automation-store/Page1'
import { getOperation } from '@/apis/getPlatformDetails'
import { mapActions, mapState } from 'vuex'
import OpenResultsInGoogleSheetsModal from '@/components/automationStore/OpenResultsInGoogleSheetsModal.vue'
import { constants } from '@/common/constants'
import { getWorkflow } from '@/apis/workflows'
export default {
  components: {
    AutomationHeader,
    OptionalSidebar,
    ToastContainer,
    Spinner,
    'input-comp': DirectInputMain,
    'sheet-comp': GoogleSheetsMain,
    'csv-comp': CsvMain,
    'outputMode-comp': OutputMode,
    'scheduling-comp': SchedulingMode,
    'run-comp': DirectInputRun,
    'output-comp': AutomationOutput,
    'workflowInput-comp': PublicWorkflowMain,
    'delay-comp': IterationDelay,
    OpenResultsInGoogleSheetsModal,
    OutputTagsModal
  },
  name: 'AutomationStorePage3',
  data() {
    return {
      currentComp: null,
      highlight: 1,
      optionalSidebarType: 'default',
      optionalSidebarItem: 'center',
      optionalSidebarSearch: false,
      NavcompData: null,
      loading: true,
      startAutomation: false,
      validateForm: false,
      googleSheetsModal: false,
      outputModal: false,
      isScheduled: false,
      operation: null
    }
  },
  created() {
    if (this.$route.query.workflowId && this.$route.query.executionId) {
      this.showOutput()
    } else if (this.$route.query.workflowId) {
      this.showRun()
    } else {
      // displays the first page depending upon the source in query
      this.showMain(this.$route.query.source)
    }

    // get all the automation data and store it in vuex
    this.getVariables()
  },
  beforeUnmount() {
    this.removeAutomationData()
    this.removeAutomationInputData()
    this.resetVariables()
    this.setAutomationNameText({
      payload: null
    })
  },
  computed: {
    ...mapState('automationStore', ['automationStoreData']),
    ...mapState('automationStore', ['automationInputs']),
    ...mapState('automationStore', ['connectedAccountId']),
    ...mapState('automationStore', ['platformData']),
    getNavText() {
      switch (this.currentComp) {
        case 'input-comp' || 'workflowInput-comp':
          return 'url'
        case 'sheet-comp':
          return 'sheet'
        case 'csv-comp':
          return 'csv'

        default:
          if (
            this.$route.query.source === 'input' ||
            this.$route.query.source === 'workflowInput'
          )
            return 'url'
          else if (this.$route.query.source === 'sheet') return 'sheet'
          else return 'csv'
      }
    }
  },
  methods: {
    ...mapActions('automationStore', [
      'addAutomationData',
      'removeAutomationData',
      'removeAutomationInputData',
      'setAutomationNameText'
    ]),
    ...mapActions('workflow', ['resetVariables']),

    removeCompData() {
      this.compData = null
    },

    resetFromValidation() {
      this.validateForm = false
    },

    async afterFormValidate() {
      if (this.NavcompData) {
        this.nextButtonClick(this.NavcompData)
        this.NavcompData = null
      } else {
        if (this.$route.query.source === 'input') {
          this.nextButtonClick({
            comp: 'run-comp',
            highlight: 5,
            optionalSidebarType: 'run',
            optionalSidebarItem: 'center',
            optionalSidebarSearch: true,
            startAutomation: true,
            runButtonOnHeader: true
          })
        } else {
          this.nextButtonClick({
            comp: 'run-comp',
            highlight: 5,
            optionalSidebarType: 'run',
            optionalSidebarItem: 'center',
            optionalSidebarSearch: true,
            startAutomation: true,
            runButtonOnHeader: true
          })
        }
      }
    },

    onError(data) {
      this.$emit('error', data)
    },
    onSuccess(data) {
      this.$emit('success', data)
    },

    showRun() {
      this.currentComp = 'run-comp'
      this.optionalSidebarSearch = true
      this.highlight = 5
    },

    showOutput() {
      this.currentComp = 'output-comp'
    },

    showMain(data) {
      if (data === 'input') {
        this.currentComp = 'input-comp'
        return
      }
      if (data === 'sheet') {
        this.currentComp = 'sheet-comp'
        return
      }
      if (data === 'csv') {
        this.currentComp = 'csv-comp'
        return
      }
      if (data === 'workflowInput') {
        this.currentComp = 'workflowInput-comp'
      }
    },

    // function emitted by automationHeader to switch the component in view
    onNavClick(value) {
      this.currentComp = value.comp
      this.highlight = value.highlight
    },

    async nextButtonClickFromNav(data) {
      this.nextButtonClick(data)
    },

    AfterFormValidateFromNav() {
      this.afterFormValidate()
    },

    // function emitted by the components to switch the component in the view
    nextButtonClick(value) {
      this.currentComp = value.comp
      this.highlight = value.highlight
      this.optionalSidebarType = value.optionalSidebarType
      this.optionalSidebarSearch = value.optionalSidebarSearch
      this.optionalSidebarItem = value.optionalSidebarItem
      this.startAutomation = value.startAutomation || false
    },
    async getVariables() {
      try {
        if (this.$route.query.source === 'workflowInput') {
          const response = await getWorkflow(this.$route.query.publicWorkflowId)
          await this.addAutomationData({
            payload: {
              ...response.data
            }
          })
          this.loading = false
          return
        }

        let platformVariablesResponse, platformInfoResponse
        if (this.platformData?.platformVariables) {
          platformVariablesResponse = this.platformData.platformVariables
        } else {
          const response = await getPlatformVariables(
            this.$route.query.operationId
          )
          platformVariablesResponse = response.data
        }

        const [operationVariableResponse, operationResponse] =
          await Promise.all([
            getOperationVariableServices(
              platformVariablesResponse.platformId,
              platformVariablesResponse.platformOperationId
            ),
            getOperation(
              platformVariablesResponse.platformId,
              platformVariablesResponse.platformOperationId
            )
          ])

        this.operation = operationResponse.data

        this.inputList = operationVariableResponse.data.filter(
          item => item.isInput === true
        )
        this.outputList = operationVariableResponse.data.filter(
          item => item.isInput === false
        )

        if (this.platformData?.platform) {
          platformInfoResponse = this.platformData.platform
        } else {
          const response = await getPlatformInfo(
            platformVariablesResponse.platformId
          )
          platformInfoResponse = response.data
        }

        if (
          this.$route.query.source === 'sheet' ||
          this.$route.query.source === 'csv'
        ) {
          let source = this.$route.query.source
          const variablesResponse = await getOperationVariableServices(
            source === 'sheet'
              ? constants.googlePlatformId
              : constants.csvPlatformId,
            source === 'sheet'
              ? constants.googlePlatformOperationId
              : constants.csvPlatformOperationId,
            'input'
          )

          this.addAutomationData({
            payload: {
              ...platformVariablesResponse,
              authType: platformInfoResponse.authType,
              isOptional: platformInfoResponse.isOptional,
              platform: platformInfoResponse.name,
              logoUrl: platformInfoResponse.logoUrl,
              isPublic: platformInfoResponse.isPublic,
              inputList: this.inputList,
              outputList: this.outputList,
              variablesData: variablesResponse.data,
              operation: operationResponse.data
            }
          })
        } else {
          this.addAutomationData({
            payload: {
              ...platformVariablesResponse,
              authType: platformInfoResponse.authType,
              isOptional: platformInfoResponse.isOptional,
              platform: platformInfoResponse.name,
              logoUrl: platformInfoResponse.logoUrl,
              isPublic: platformInfoResponse.isPublic,
              inputList: this.inputList,
              outputList: this.outputList
            }
          })
        }

        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    setScheduledStatus(data) {
      this.isScheduled = data
    },
    deleteApiResponse(data) {
      if (data.type === 'success') {
        this.$emit('success', data.value)
      } else {
        this.$emit('error', data.value)
      }
    }
  }
}
</script>

<style></style>
