<template>
  <thead class="sticky inset-x-0 -top-0.5 bg-gray-50 dark:bg-gray-700">
    <tr>
      <th
        v-for="(header, index) of headersByUserRole"
        scope="col"
        :class="{
          'min-w-[12rem] rounded-l-lg': index === 0
        }"
        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
      >
        <span :class="{ 'sr-only': !header.visible }">
          {{ header.label }}
        </span>
      </th>
    </tr>
  </thead>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'TableHeader',
  computed: {
    ...mapState('settings', ['userRole']),
    headersByUserRole() {
      if (this.userRole === 'viewer') {
        return [
          { label: 'Name', visible: true },
          { label: 'Platform', visible: true },
          { label: 'Proxy', visible: true },
          { label: 'Location', visible: true },
          { label: 'Updated At', visible: true },
          { label: 'Account', visible: true },
          { label: 'Cookies', visible: true },
          { label: 'Proxies', visible: true }
        ]
      } else {
        return this.headers
      }
    }
  },
  props: {
    headers: {
      type: Array,
      default: [
        { label: 'Name', visible: true },
        { label: 'Platform', visible: true },
        { label: 'Proxy', visible: true },
        { label: 'Location', visible: true },
        { label: 'Updated At', visible: true },
        { label: 'Account', visible: true },
        { label: 'Cookies', visible: true },
        { label: 'Proxies', visible: true },
        { label: 'Limits', visible: true },
        { label: 'Variables', visible: true },
        { label: 'Actions', visible: false }
      ]
    }
  }
}
</script>
