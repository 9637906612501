<template>
  <nav class="-mb-px flex space-x-8">
    <!-- dark:text-gray-50 -->
    <a
      v-for="(tab, index) in tabs"
      :key="tab.text"
      href="#"
      title=""
      class="group inline-flex items-center whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium transition-all"
      :class="[
        { [colors.selected]: index == selectedTab },
        {
          [colors.unselected]: index !== selectedTab
        },
        {
          [tab.selectedClass]: index == selectedTab
        }
      ]"
      aria-current="page"
      @click="$emit('tab-selected', index)"
    >
      {{ tab.text }}
      <span
        v-show="!!tab.counter"
        class="ml-3 hidden rounded-md bg-blue-100 px-2 py-1 text-xs font-semibold text-blue-600 md:inline-block"
      >
        {{ tab.counter }}
      </span>
    </a>
  </nav>
</template>

<script>
export default {
  name: 'Tabs',
  emits: ['tab-selected'],
  props: {
    tabs: { type: Array, required: true },
    selectedTab: { type: Number, default: 0 },
    color: {
      type: String,
      default: 'default',
      validator(value) {
        // The value must match one of these strings
        return ['default', 'primary', 'important', 'added', 'removed'].includes(
          value
        )
      }
    }
  },
  computed: {
    colors: function () {
      if (this.color === 'default') {
        return {
          selected: 'border-gray-900 text-gray-900',
          unselected:
            'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
        }
      }
      if (this.color === 'primary') {
        return {
          selected: 'border-blue-500 text-blue-500',
          unselected:
            'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
        }
      }
    }
  }
}
</script>

<style></style>
