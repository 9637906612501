<template>
  <div class="flex border-l border-gray-200 w-72 dark:border-gray-700 antialiased">
    <div class="flex flex-col flex-1 px-4 py-5 sm:px-5">
      <p class="text-base font-semibold text-gray-900 dark:text-gray-50">
        Folders
      </p>

      <nav class="flex flex-col flex-1 mt-4 space-y-1.5 -mx-3" aria-label="Sidebar">
        <a v-for="(item, index) in folderList" href="#" title="" :key="index"
           class="flex items-center px-3 py-2 text-sm font-medium text-gray-600 transition-all duration-200 rounded-lg dark:text-gray-400 dark:hover:text-gray-50 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700">
          <SvgIcon name="folder"/>
          <span class="ml-3 truncate">
                    {{ item }}
                  </span>
          <SvgIcon name="downArrow" />
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";

export default {
  name: "createTemplateFoldersList",
  components: {
    SvgIcon,
  },
  props: {
    folderList: {type: Array, default: ['LinkedIn Campaign Sprints','Email Campaign', 'IG Messages', 'Twitter Journalists Musi...', 'LinkedIn Arman Sales', 'LinkedIn Vikesh Founde...']}
  }
}
</script>
