<!-- Radio Component -->
<!-- For checked pass 'checked', for disabled pass 'disabled', for alert pass 'alert', as attribute -->

<template>
  <div>
    <div>
      <div :class="[{
             'flex items-start space-x-3' : isLabelAndCaption,
             'flex items-centre space-x-3': isLabel,
           }]">
        <input type="radio"
               class="w-5 h-5 transition-all duration-200 rounded-full cursor-pointer"
               :class="[{
                 'text-blue-600 border-gray-300 focus:ring-blue-600 dark:focus:ring-offset-gray-900 dark:border-gray-600 dark:bg-gray-900 dark:focus:ring-blue-500 dark:text-blue-500 enabled:hover:border-gray-400 enabled:dark:hover:border-gray-500 disabled:text-gray-400' : standard || grey,
                 'text-red-600 border-red-300 focus:ring-red-600 hover:bg-red-100 hover:border-red-300  dark:text-red-500 dark:focus:ring-red-500 dark:hover:border-gray-500 dark:border-gray-600 dark:bg-gray-900 dark:focus:ring-offset-gray-900': alert,
                 'text-blue-600 border-gray-300 opacity-50 focus:ring-blue-600 dark:bg-gray-900 dark:border-gray-600 dark:hover:border-gray-500 dark:focus:ring-blue-500 dark:text-blue-500 dark:focus:ring-offset-gray-900': grey,
               }]"
               :checked="checked"
               :disabled="disabled"
        />
        <label v-if="isLabel"
               :class="[{
                 'block text-sm font-semibold text-gray-900 dark:text-gray-50': standard,
                 'text-sm font-medium text-gray-600 dark:text-gray-400': disabled,
                  }]">
          {{label}}
        </label>
        <div v-if="isLabelAndCaption">
          <label
              :class="[{
                 'block text-sm font-semibold text-gray-900 dark:text-gray-50': standard,
                 'block text-sm font-medium text-gray-600 dark:text-gray-400': disabled,
                  }]">
            {{label}}
          </label>
          <span
              class="text-xs mt-0.5"
              :class="[{
                'text-gray-600 dark:text-gray-400': !alert,
                'text-red-600 dark:text-red-500': alert,
                'text-gray-600 text-opacity-50 dark:text-gray-400': disabled,
              }]"
          >
          {{captionText}}
        </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Radio",
  props: {
    checked: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    alert: {type: Boolean, default: false},
    label: {type: String, default: ""},
    captionText: {type: String, default: ""},
  },
  computed: {
    standard() {
      return !this.disabled
    },
    grey() {
      return this.disabled
    },
    isLabel(){
      if(this.label !== '' && this.captionText === ''){
        return true
      }
    },
    isLabelAndCaption(){
      if(this.label!=='' && this.captionText!==''){
        return true
      }
    }
  }
}
</script>

<style></style>
