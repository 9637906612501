<template>
  <div
    class="max-h-84 absolute right-0 z-50 mt-1.5 w-80 divide-y divide-gray-100 rounded-lg border border-gray-100 bg-white shadow-xl dark:divide-gray-700 dark:border-gray-700 dark:bg-gray-900"
  >
    <div class="pt-1" role="none">
      <div
        class="max-h-60 overflow-hidden hover:overflow-y-scroll no-scrollbar"
      >
        <p v-if="noWorkspace" class="px-4 py-3 text-center italic">
          No Workspace Created Yet
        </p>
        <div v-for="data of workspaceData" :key="data.workspaceId">
          <a
            href="#"
            class="flex items-center justify-between space-x-8 whitespace-nowrap px-4 py-3 text-base font-medium text-gray-700 transition-all duration-200 hover:bg-gray-100 dark:hover:bg-gray-800 sm:text-sm"
            role="menuitem"
            tabindex="-1"
            @click.prevent="
              changeWorkspace(data.workspaceId, data.name, data.orgUserId)
            "
          >
            <div class="min-w-0 flex-1">
              <div class="flex justify-between">
                <div>
                  <div class="flex gap-2">
                    <svg
                      v-if="data.roleId === '621c55d65e59073ab2aeda85'"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-gray-900 dark:text-gray-50"
                      viewBox="0 -960 960 960"
                      fill="currentcolor"
                    >
                      <path
                        d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 440q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 152-90.5 276.5T480-80Zm0-400Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Z"
                      />
                    </svg>

                    <svg
                      v-else-if="data.roleId === '621c57125e59073ab2aeda86'"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-gray-900 dark:text-gray-50 icon icon-tabler icons-tabler-outline icon-tabler-user-edit"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                      <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5" />
                      <path
                        d="M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z"
                      />
                    </svg>

                    <svg
                      v-else-if="data.roleId === '621c577f5e59073ab2aeda88'"
                      class="mt-1 h-5 w-5 text-gray-900 dark:text-gray-50"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke="currentColor"
                    >
                      <path
                        d="M1.42012 8.71318C1.28394 8.49754 1.21584 8.38972 1.17772 8.22342C1.14909 8.0985 1.14909 7.9015 1.17772 7.77658C1.21584 7.61028 1.28394 7.50246 1.42012 7.28682C2.54553 5.50484 5.8954 1 11.0004 1C16.1054 1 19.4553 5.50484 20.5807 7.28682C20.7169 7.50246 20.785 7.61028 20.8231 7.77658C20.8517 7.9015 20.8517 8.0985 20.8231 8.22342C20.785 8.38972 20.7169 8.49754 20.5807 8.71318C19.4553 10.4952 16.1054 15 11.0004 15C5.8954 15 2.54553 10.4952 1.42012 8.71318Z"
                      />
                      <path
                        d="M11.0004 11C12.6573 11 14.0004 9.65685 14.0004 8C14.0004 6.34315 12.6573 5 11.0004 5C9.34355 5 8.0004 6.34315 8.0004 8C8.0004 9.65685 9.34355 11 11.0004 11Z"
                      />
                    </svg>
                    <p
                      class="truncate text-sm font-semibold text-gray-900 dark:text-gray-50"
                    >
                      {{ data.name }}
                    </p>
                  </div>

                  <p
                    class="mt-0.5 text-xs font-normal text-gray-600 dark:text-gray-400"
                  >
                    {{
                      data.workspaceId == currWorkspaceId
                        ? 'Current Workspace'
                        : null
                    }}
                  </p>
                </div>
              </div>
            </div>
          </a>
          <hr class="dark:border-gray-700" />
        </div>
      </div>
      <div>
        <hr class="dark:border-gray-700" />
        <a
          @click.prevent="
            $router.push('/settings-workspace/create-new-workspace')
          "
          class="flex cursor-pointer items-center justify-between space-x-8 whitespace-nowrap px-4 py-3 text-base font-medium text-gray-700 transition-all duration-200 hover:bg-gray-100 dark:hover:bg-gray-800 sm:text-sm"
        >
          <div class="min-w-0 flex-1">
            <p
              class="truncate text-sm font-semibold text-gray-900 dark:text-gray-50"
            >
              Add New Workspace
            </p>
          </div>

          <SvgIcon class="h-6 w-6 text-blue-600 dark:text-white" name="plus" />
        </a>
        <hr class="dark:border-gray-700" />
        <a
          @click="onLogoutClick"
          class="flex cursor-pointer items-center justify-between space-x-8 whitespace-nowrap px-4 py-3 text-base font-medium text-gray-700 transition-all duration-200 hover:rounded-b-lg hover:bg-gray-100 dark:hover:bg-gray-800 sm:text-sm"
        >
          <div class="min-w-0 flex-1">
            <p
              class="inline-flex truncate text-sm font-semibold text-gray-900 dark:text-gray-50"
            >
              Log Out
            </p>
            <Spinner
              v-if="isLoggingOut && loggingOut"
              class="ml-3 inline-flex"
            />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from './SvgIcon.vue'
import Spinner from './Spinner.vue'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SwitchWorkspaceDropdown',
  props: {
    isLoggingOut: { type: Boolean, default: false }
  },

  computed: {
    ...mapState('user', ['userWorkspaceData'])
  },
  methods: {
    ...mapActions('user', ['saveWorkspaceData']),

    onLogoutClick() {
      this.loggingOut = true
      this.$emit('onLogout', true)
    },

    changeWorkspace(id, name, orgUserId) {
      sessionStorage.clear()
      window.$cookies.set('workspaceId', id, -1, '/')
      window.$cookies.set('orgUserId', orgUserId, -1, '/')
      this.currWorkspaceId = window.$cookies.get('workspaceId')
      this.$emit('changeWorkspaceEmit', name)
      this.$router.go()
    }
  },
  data() {
    return {
      workspaceData: null,
      loading: true,
      noWorkspace: false,
      currWorkspaceId: null,
      loggingOut: false
    }
  },
  async mounted() {
    this.currWorkspaceId = window.$cookies.get('workspaceId')

    this.workspaceData = this.userWorkspaceData

    if (this.workspaceData.length === 0) {
      this.noWorkspace = true
    }
  },
  components: { SvgIcon, Spinner }
}
</script>

<style></style>
