<template>
  <div class="flex h-full flex-col justify-between px-4 py-4 overflow-x-hidden">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div class="overflow-hidden">
          <table class="min-w-full">
            <thead
              class="sticky inset-x-0 -top-0.5 bg-gray-50 dark:bg-gray-700"
            >
              <tr>
                <th
                  v-for="(header, index) of headers"
                  scope="col"
                  :class="{
                    'min-w-[20rem] rounded-l-lg': index === 2
                  }"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  <span :class="{ 'sr-only': !header.visible }">
                    {{ header.label }}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody
              class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
            >
              <tr v-for="data of resultData" :key="data">
                <td class="whitespace-nowrap px-3 py-4">
                  <img
                    class="h-10 w-10 shrink-0 rounded-full object-cover"
                    :src="data.liProfileImageUrl"
                  />
                </td>
                <td class="whitespace-nowrap px-3 py-4">{{ data.fullName }}</td>
                <td class="whitespace-nowrap px-3 py-4 max-w-[20rem]">
                  <div class="">
                    <p class="whitespace-pre-wrap">
                      {{ openAiText(data) }}
                    </p>
                  </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4">{{ data.jobTitle }}</td>
                <td class="whitespace-nowrap px-3 py-4">
                  {{ data.companyName }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="flex mt-4 justify-between">
      <Button
        leftIcon="left-arrow"
        @click="$emit('showTester')"
        text="Go Back"
      />
      <p class="flex items-center justify-center">
        If you are satisfied with the results, you can now close this window and
        use this template in in your automation!
      </p>
      <Button leftIcon="close" @click="$emit('showBuilder')" text="Close" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
export default {
  name: 'PromptResult',
  components: {
    Button
  },
  props: {
    headers: {
      type: Array,
      default: [
        { label: 'Photo', visible: true },
        { label: 'Name', visible: true },
        { label: 'AI Message', visible: true },
        { label: 'Job Title', visible: true },
        { label: 'Company', visible: true }
      ]
    },
    resultData: { type: Object }
  },
  methods: {
    openAiText(data) {
      for (const key in data) {
        if (key.toLowerCase().includes('ai')) {
          return data[key].replace(/\\n/g, '\n')
        }
      }
    }
  }
}
</script>
