<template>
  <div class="rounded-lg border border-gray-200 dark:border-gray-700">
    <div class="px-4 py-5 sm:p-6">
      <div class="grid grid-cols-7 items-end">
        <div class="col-span-3">
          <Select
            label="Connect this platform"
            text="Search platform"
            left-icon="search"
            :options="platformsNameList"
            @change="v => onPlatformSelectChange(v, 1)"
          />
        </div>
        <ConnectionPath icon="plus-icon" />
        <div class="col-span-3">
          <Select
            label="with this one:"
            text="Search platform"
            left-icon="search"
            :options="platformsNameList"
            @change="v => onPlatformSelectChange(v, 2)"
          />
        </div>
      </div>

      <div v-show="showSecondSection()" class="mt-6 grid grid-cols-7 items-end">
        <div class="col-span-3">
          <Select
            label="First, do this..."
            text="Search Automation"
            left-icon="search"
            :left-image-url="firstPlatformOperationData.logoUrl"
            :options="firstPlatformOperationData.platformOperationList"
            @change="v => onPlatformOperationChange(v, 1)"
          />
        </div>
        <ConnectionPath icon="right-arrow" />
        <div class="col-span-3">
          <Select
            label="then do this:"
            text="Search Automation"
            left-icon="search"
            :left-image-url="secondPlatformOperationData.logoUrl"
            :options="secondPlatformOperationData.platformOperationList"
            @change="v => onPlatformOperationChange(v, 2)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAllPlatforms,
  getPlatformOperations
} from '@/apis/getPlatformDetails'
import { mapActions } from 'vuex'
import Select from '../Select.vue'
import ConnectionPath from './ConnectionPath.vue'

export default {
  name: 'PlatformSelection',
  components: {
    Select,
    ConnectionPath
  },
  data() {
    return {
      platformsNameList: null,
      platformsList: null,
      firstSelectedPlatform: null,
      firstSelectedPlatformData: null,
      secondSelectedPlatformData: null,
      secondSelectedPlatform: null,
      firstPlatformOperationData: {},
      firstPlatformOperationList: null,
      secondPlatformOperationList: null,
      secondPlatformOperationData: {}
    }
  },
  methods: {
    ...mapActions('automationMatrix', [
      'firstPlatformOperationChanged',
      'secondPlatformOperationChanged'
    ]),

    async getAllPlatforms() {
      const platformResponse = await getAllPlatforms(0, 50, 'automation')
      if (platformResponse['success']) {
        this.platformsList = platformResponse.data
        const list = []
        for (const listElement of platformResponse.data) {
          list.push(listElement.name)
        }
        this.platformsNameList = list
      }
    },

    async onPlatformSelectChange(value, type) {
      const s = type === 1 ? 'first' : 'second'
      if (value) {
        const {
          platformOperationList,
          platformOperationData,
          platformName,
          platformId
        } = await this.onPlatformChange(value)
        this[`${s}SelectedPlatform`] = value
        this[`${s}PlatformOperationList`] = platformOperationList
        this[`${s}PlatformOperationData`] = platformOperationData
        this[`${s}SelectedPlatformData`] = { platformName, platformId }
      } else {
        this[`${s}SelectedPlatform`] = null
        this[`${s}PlatformOperationList`] = null
        this[`${s}PlatformOperationData`] = {}
        this[`${s}SelectedPlatformData`] = null
      }
    },

    async onPlatformChange(platformName) {
      const platformSelectedData = this.platformsList.find(
        o => o.name === platformName
      )
      const { logoUrl, name, _id } = new Proxy(platformSelectedData, {})
      const platformOperation = await getPlatformOperations(_id)
      if (platformOperation['success']) {
        const platformOperationList = platformOperation.data
        const temp = []
        for (const platformOperationElement of platformOperationList) {
          temp.push(platformOperationElement.label)
        }
        const platformOperationData = {
          logoUrl,
          platformOperationList: temp
        }
        return {
          platformOperationList,
          platformOperationData,
          platformName: name,
          platformId: _id
        }
      } else {
        console.log(platformOperation)
      }
    },

    showSecondSection() {
      return !!(this.firstSelectedPlatform && this.secondSelectedPlatform)
    },

    async onPlatformOperationChange(value, type) {
      const s = type === 1 ? 'first' : 'second'
      if (value) {
        const platformOperationSelectedData =
          this.firstPlatformOperationList.find(o => o.label === value)
        this[`${s}PlatformOperationChanged`]({
          payload: {
            ...platformOperationSelectedData,
            platformName: this[`${s}SelectedPlatformData`].platformName,
            logoUrl: this[`${s}PlatformOperationData`].logoUrl,
            platformId: this[`${s}SelectedPlatformData`].platformId
          }
        })
      } else {
        this[`${s}PlatformOperationChanged`]({ payload: null })
      }
    }
  },
  async beforeMount() {
    await this.getAllPlatforms()
  }
}
</script>
