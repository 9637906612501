<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-8">
      <div>
        <p class="font-semibold">Output Mode</p>
      </div>
      <div class="">
        <Switch
          @change="appendClick()"
          v-model="append"
          label="Append (Default)"
          caption="New results are appended at the end of the previous and merged to a single CSV file."
        />
      </div>
      <div class="">
        <Switch
          @change="splitClick()"
          v-model="split"
          label="Split"
          caption="New results are outputted as separate CSV file after each extraction."
        />
      </div>
      <div class="">
        <Switch
          @change="overwriteClick()"
          v-model="overwrite"
          label="Overwrite"
          caption="New results will overwrite data from previous extractions. This mode is useful for monitoring change on a page."
        />
      </div>
    </div>
    <hr />
    <div class="flex flex-col gap-8">
      <div>
        <p class="font-semibold">Duplicate management</p>
      </div>
      <div class="">
        <Switch
          @click="setOutputMode()"
          v-model="deduplicate"
          label="Deduplicate (Default)"
          caption="Delete duplicate rows between each extraction."
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../Button.vue'
import Select from '../Select.vue'
import Switch from '../Switch.vue'
import { mapState, mapActions } from 'vuex'
import { getWorkflowAllNodes, updateWorkflowNode } from '@/apis/workflows'

export default {
  name: 'OutputModeConfig',
  components: { Select, Button, Switch },
  data() {
    return {
      append: true,
      split: false,
      overwrite: false,
      deduplicate: true,
      nodeData: null
    }
  },
  props: {
    selectedNode: {}
  },
  async mounted() {
    if (this.$route.query.workflowId || this.$route.params.id) {
      const response = await getWorkflowAllNodes(
        this.$route.query.workflowId || this.$route.params.id
      )
      if (this.selectedNode) {
        this.nodeData = response.data.find(
          item => item._id === this.selectedNode.id
        )
      } else if (
        this.$route.query.source === 'sheet' ||
        this.$route.query.source === 'csv'
      ) {
        this.nodeData = response.data[1]
      } else {
        this.nodeData = response.data[0]
      }

      this.deduplicate = this.nodeData.isDedupeResult
      if (this.nodeData.outputMode === 'append' || !this.nodeData.outputMode) {
        this.append = true
        this.split = false
        this.overwrite = false
      } else if (this.nodeData.outputMode === 'split') {
        this.split = true
        this.append = false
        this.overwrite = false
      } else {
        this.overwrite = true
        this.append = false
        this.split = false
      }
    }
  },
  methods: {
    ...mapActions('automationStore', ['changeAutomationOutputMode']),

    appendClick() {
      this.split = false
      this.overwrite = false
      this.defaultAppendTrue()
    },
    splitClick() {
      this.append = false
      this.overwrite = false
      this.defaultAppendTrue()
    },
    overwriteClick() {
      this.append = false
      this.split = false
      this.defaultAppendTrue()
    },
    defaultAppendTrue() {
      if (((this.split === this.append) === this.overwrite) === false) {
        this.append = true
      }
      this.setOutputMode()
    },
    async setOutputMode() {
      let data = {
        outputMode: this.append ? 'append' : this.split ? 'split' : 'overwrite',
        isDedupeResult: this.deduplicate
      }
      this.changeAutomationOutputMode({
        payload: data
      })
      if (this.$route.query.workflowId || this.$route.params.id) {
        this.updateNode(data)
      }
    },
    async updateNode(data) {
      const response = await updateWorkflowNode(
        this.$route.query.workflowId || this.$route.params.id,
        this.nodeData._id,
        data
      )
      if (response['success']) {
        this.$emit('success', 'Output Mode Updated Sucessfully')
      }
    }
  }
}
</script>
