<!-- Group Select Component -->
<!-- To include a search bar under options pass includeSearch="true" in props -->
<!-- To show images or icons in dropdown pass the key value in the options props -->

<template>
  <div
    class="m-auto w-[calc(100%_-_2px)]"
    :class="[{ 'pointer-events-none': disabled }]"
  >
    <!-- Label for the select component -->
    <label
      v-if="isLabel"
      for=""
      class="flex text-sm font-medium text-gray-900 dark:text-gray-50"
      :class="labelClass"
    >
      {{ label }}
      {{ isRequired === false ? '(Optional)' : null }}
      <SvgIcon
        class="mb-3 ml-1 h-2 w-2 text-red-600"
        name="star"
        v-if="isRequired"
      />
    </label>
    <Popper
      placement="bottom-start"
      :interactive="true"
      @close:popper="searchText = ''"
      @open:popper="onOpen()"
      class="w-full"
      :class="[
        {
          'mt-1.5': isLabel && labelClass !== 'sr-only'
        }
      ]"
      :style="{ margin: 0, border: 0, marginTop: isLabel ? '0.375rem' : 0 }"
    >
      <div
        tabindex="0"
        class="flex w-full cursor-pointer select-none items-center rounded-lg border px-3 py-2.5 text-gray-900 placeholder-gray-500 transition-all duration-200 dark:placeholder-gray-400 sm:text-sm"
        :class="[
          {
            'border-red-600  text-red-600 caret-red-600 hover:border-red-700 focus:border-red-600 focus:ring-red-600 dark:border-red-500 dark:bg-gray-900 dark:placeholder-gray-400 dark:focus:border-red-500 dark:focus:ring-red-500':
              displayError,
            'border-gray-300 caret-blue-600 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500':
              primary
          }
        ]"
        ref="selectInputContainer"
      >
        <div v-if="leftIcon || leftImageUrl || flagUrl" class="mr-2 flex gap-2">
          <SvgIcon
            v-if="leftIcon"
            class="h-5 w-5 text-gray-400"
            :name="leftIcon"
          />

          <Avatar
            v-if="leftImageUrl"
            :srcAndStatus="[{ src: leftImageUrl }]"
            size="xs"
          />
          <img
            v-if="flagUrl"
            class="h-5 w-6 shrink-0 object-cover"
            :src="flagUrl"
            alt=""
          />
        </div>

        <p
          :class="{
            'text-gray-400': !value
          }"
          class="text-sm truncate"
        >
          {{ value || text }}
        </p>
        <div class="ml-auto flex items-center gap-1 pl-2">
          <SvgIcon
            v-if="disabled"
            class="h-4 w-4 text-gray-300 hover:text-gray-400"
            name="lock"
          />

          <SvgIcon
            v-if="!disabled"
            class="h-5 w-5 text-gray-400"
            name="down-arrow"
          />
        </div>
      </div>
      <template #content="{ close }">
        <div
          class="dropdown-border block max-h-64 min-w-max overflow-auto bg-white dark:bg-gray-900"
          :style="{ width: `${dropdownWidth}px` }"
        >
          <div @click="close">
            <div
              v-if="options && options.length > 0"
              v-for="option of options"
              :key="option"
              class=""
            >
              <div class="flex gap-2 px-4 py-3 font-bold text-gray-400">
                <SvgIcon
                  v-if="Object.keys(option)[1]"
                  class="h-5 w-5 text-gray-400"
                  :name="option.HeaderIcon"
                />
                <h3 class="">
                  {{ Object.keys(option)[0] }}
                </h3>
              </div>

              <div
                v-if="
                  Object.entries(option)[0][1] &&
                  Object.entries(option)[0][1].length > 0
                "
                :class="option.lock ? 'pointer-events-none' : 'cursor-pointer'"
                class="items-center px-4 py-3 text-sm hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-50 dark:hover:bg-gray-700"
                v-for="(value, key) in Object.entries(option)[0][1]"
                :key="key"
                @click="updateValue(value)"
              >
                <div class="flex gap-2">
                  <Avatar
                    v-if="option.img && !Array.isArray(option.img)"
                    :srcAndStatus="[{ src: option.img }]"
                    size="xs"
                  />

                  <Avatar
                    v-if="option.img && Array.isArray(option.img)"
                    :srcAndStatus="option.img"
                    size="xs"
                  />

                  <SvgIcon
                    v-if="option.icon"
                    class="h-5 w-5 text-gray-400"
                    :name="option.icon"
                  />

                  <p>
                    {{ option.label }}
                  </p>
                </div>
                <div class="flex gap-2">
                  <p>{{ value }}</p>
                  <SvgIcon
                    v-if="option.lock"
                    class="h-5 w-5 text-gray-400"
                    name="lock"
                  />
                </div>
              </div>
              <div
                v-else
                class="flex items-center px-4 py-3 text-sm text-gray-400"
              >
                <p>No Options</p>
              </div>
            </div>

            <div
              v-else
              class="flex items-center px-4 py-3 text-sm text-gray-400"
            >
              <p>No Options</p>
            </div>
          </div>
          <Button
            v-if="infiniteScrollConfig"
            v-show="!infiniteScrollConfig?.done"
            text="Load More"
            @click="onLoadClick(searchText)"
            :showLoader="loading"
            size="small"
            class="mx-4 my-3 mb-2 px-4"
          />
        </div>
      </template>
    </Popper>
    <!-- CAPTION TEXT -->
    <p
      v-if="captionText || description"
      class="mt-2 text-sm"
      :class="[
        { 'text-red-600': displayError },
        { 'text-gray-500': primary },
        { 'text-gray-500 opacity-50': disabled }
      ]"
    >
      {{ captionText || description }}
    </p>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import Popper from 'vue3-popper'
import Avatar from './Avatar.vue'
import Button from './Button.vue'
import Input from './Input.vue'
import SvgIcon from './SvgIcon.vue'

const props = defineProps({
  options: {
    type: Array,
    default: [
      {
        label: 'placeholder',
        value: null
      }
    ]
  },
  captionText: String,
  error: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  leftIcon: { type: String, default: '' },
  text: { type: String, default: 'Select' },
  label: { type: String, default: '' },
  name: { type: String },
  leftImageUrl: { type: String, default: null },
  flagUrl: { type: String, default: null },
  modelValue: {},
  isRequired: { type: Boolean, default: undefined },
  labelClass: String,
  includeSearch: { type: Boolean, default: false },
  infiniteScrollConfig: {},
  hideClear: Boolean,
  description: String
})

const $emit = defineEmits(['update:modelValue', 'change', 'blur'])

window.addEventListener('resize', setDropdownWidth)

const selectInputContainer = ref()
const searchInput = ref()
const primary = ref(true)
const value = ref(props.modelValue)
const selectOptions = ref(props.options)
const dropdownWidth = ref(selectInputContainer.value?.clientWidth)
const searchText = ref('')
const loading = ref(false)

const displayError = computed(() => {
  if (props.error === true) {
    primary.value = false
    return true
  }
})
const isLabel = computed(() => {
  return props.label !== ''
})
const parsedOptions = computed(() => {
  return !selectOptions.value || typeof selectOptions.value[0] === 'object'
    ? selectOptions.value
    : selectOptions.value.map(option => ({ label: option, value: option }))
})
const displayLabel = computed(() => {
  return (
    parsedOptions.value &&
    parsedOptions.value.find(option => option.value === value.value)?.label
  )
})

const onLoadClick = async (searchText, reset) => {
  loading.value = true
  try {
    await props.infiniteScrollConfig?.onLoadMore(searchText, reset)
  } catch (error) {}
  loading.value = false
}

onMounted(() => {
  setDropdownWidth()
})

onUnmounted(() => {
  window.removeEventListener('resize', setDropdownWidth)
})

watch(
  () => props.modelValue,
  n => {
    value.value = n
  }
)

watch(
  () => props.options,
  n => {
    selectOptions.value = n
  }
)

watch(searchText, n => {
  onLoadClick(n, true)
})

watch(searchText, searchStr => {
  if (props.infiniteScrollConfig) {
    onLoadClick(searchStr, true)
  } else {
    if (typeof props.options[0] === 'object') {
      selectOptions.value = props.options.filter(value => {
        return value.label.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1
      })
    } else {
      selectOptions.value = props.options.filter(value => {
        return value.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1
      })
    }
  }
})

function updateValue(newValue) {
  value.value = newValue
  $emit('update:modelValue', newValue)
  $emit('change', newValue)
  $emit('blur', newValue)
}

function setDropdownWidth() {
  dropdownWidth.value = selectInputContainer.value?.clientWidth
}

function onOpen() {
  // autofocus on the input when the dropdown is opened
  setTimeout(() => {
    searchInput.value?.focus()
  }, 100)
}
</script>

<style scoped>
.dropdown-border {
  border: 0.5px solid #bec0c5;
  /* Drop Shadow - 5% */

  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
}
</style>
