<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <!-- START WRAPPER -->
    <div class="flex min-h-screen justify-between">
      <!-- START LEFT COLUMN -->
      <OnboardingLeftColumn />
      <!-- END LEFT COLUMN -->

      <!-- START RIGHT COLUMN -->
      <div class="flex-1 bg-white dark:bg-gray-900">
        <div class="grid h-full items-start justify-center">
          <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
            <div class="flex h-full max-w-lg flex-col">
              <a href="#" title="TexAu" class="flex items-end justify-center">
                <img
                  class="h-12 w-auto xl:h-14"
                  src="@/assets/images/logo.png"
                  alt="TexAu logo"
                />
              </a>

              <div class="mt-12 grid h-full place-items-center">
                <div>
                  <div class="text-center">
                    <Heading text="Welcome to TexAu!" />
                    <p class="mt-4 text-base text-gray-600 dark:text-gray-400">
                      Now, it's getting serious! Please enter the code you
                      received on your
                      <span
                        class="text-base font-bold text-blue-600 dark:text-blue-400"
                        >Phone</span
                      >.
                    </p>
                  </div>

                  <div v-if="!getRegistrationData.email">
                    <div class="sm:flex sm:items-end sm:space-x-5">
                      <div class="flex-1">
                        <label
                          for=""
                          class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                        >
                          Email
                        </label>
                        <div class="mt-1.5">
                          <Input
                            v-model="email"
                            text="Enter email"
                            type="email"
                            name="Code"
                          />
                        </div>
                      </div>

                      <div class="mt-4 shrink-0 sm:mt-0">
                        <Button
                          :showLoader="isLoading"
                          type="submit"
                          text="Get OTP"
                          rightIcon="right-arrow"
                          @click="getNewOtp"
                        />
                      </div>
                    </div>
                  </div>

                  <vee-form
                    @submit="submitCreate()"
                    class="mt-10"
                    :validation-schema="schema"
                  >
                    <div>
                      <div class="sm:flex sm:items-end sm:space-x-5">
                        <div class="flex-1">
                          <label
                            for=""
                            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                          >
                            Code
                          </label>
                          <div class="mt-1.5">
                            <Input
                              v-model="code"
                              text="Enter code"
                              type="tel"
                              name="Code"
                            />
                          </div>
                        </div>

                        <div class="mt-4 shrink-0 sm:mt-0">
                          <Button
                            :showLoader="isLoading"
                            type="submit"
                            text="Jump to the other side"
                            rightIcon="right-arrow"
                          />
                        </div>
                      </div>
                      <p class="mt-2 text-sm text-red-600">
                        <ErrorMessage name="Code" />
                      </p>
                    </div>
                  </vee-form>

                  <ResendOtpCounter
                    ref="resend"
                    resend-otp-for-phone
                    @success="data => $emit('success', data)"
                    @error="data => $emit('error', data)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END RIGHT COLUMN -->
    </div>
    <!-- END WRAPPER -->
  </div>
</template>

<script>
import OnboardingLeftColumn from '@/components/onboarding/OnboardingLeftColumn.vue'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'
import Heading from '@/components/onboarding/Heading.vue'
import arrow from '@/assets/images/onboarding/illustrations/right-arrow-icon.svg'
import { confirmPhone } from '@/apis/onboarding'
import { mapGetters, mapActions } from 'vuex'
import Spinner from '@/components/Spinner.vue'
import ResendOtpCounter from '@/components/onboarding/ResendOtpCounter.vue'
import { ref } from 'vue'
import { login } from '@/apis/onboarding'

export default {
  name: 'confirmPhoneOtp',
  components: {
    OnboardingLeftColumn,
    Input,
    Button,
    Heading,
    Spinner,
    ResendOtpCounter
  },
  data() {
    return {
      code: '',
      email: null,
      isLoading: false,
      arrow,
      schema: {
        Code: 'required|code_min:4'
      }
    }
  },
  computed: {
    ...mapGetters('registration', ['getRegistrationData'])
  },
  methods: {
    ...mapActions('registration', ['registerFinished']),
    ...mapActions('registration', ['registerStarted']),
    ...mapActions('registration', ['loginSuccess']),

    async submitCreate() {
      this.isLoading = true
      try {
        const { email, password } = new Proxy(this.getRegistrationData, {})
        const response = await confirmPhone(email, this.code)
        // this.isLoading = false
        if (response['success']) {
          this.registerFinished()

          //check if  password exist in vuex , if not send user to login page
          if (this.getRegistrationData.password) {
            //login the user internally
            const loginResponse = await login(email, password)
            if (loginResponse['success']) {
              //normal login flow
              localStorage.setItem('email', email)
              this.loginSuccess({ payload: loginResponse.data })
              this.$router.go()
            } else {
              //if error occurs send user to login page as fallback
              await this.$router.push('/login')
              throw loginResponse.message
            }
          } else {
            this.$emit('success', 'you can now login with your credentials')
            await this.$router.push('/login')
          }
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }

      this.isLoading = false
    },

    async getNewOtp() {
      let email = this.email
      await this.registerStarted({ payload: { email } })
      this.$refs.resend.onResendClick('from-outside')
    }
  }
}
</script>

<style></style>
