<template>
  <nav class="flex justify-center space-x-4 antialiased" aria-label="Tabs">
    <TabSingleMenuOption optionName="Agency" :isActive="isAgencyActive"/>
    <TabSingleMenuOption optionName="Clients" :isActive="isClientActive"/>
    <TabSingleMenuOption optionName="Reports" :isActive="isReportActive"/>
    <TabSingleMenuOption optionName="Custom Domain" :isActive="isCustomDomainActive"/>
  </nav>
</template>

<script>
import TabSingleMenuOption from "@/components/settings/tabSingleMenuOption.vue";

export default {
  name: "agencyWhitelabelHeader",
  props: {
    activeOption:{
      type: String, default: 'agency', required: true
    }
  },
  components: {
    TabSingleMenuOption,
  },
  computed: {
    isAgencyActive(){
      return this.activeOption === 'agency' || this.activeOption === 'Agency'
    },
    isClientActive(){
      return this.activeOption === 'client' || this.activeOption === 'Client'
    },
    isReportActive(){
      return this.activeOption === 'report' || this.activeOption === 'Report'
    },
    isCustomDomainActive(){
      return this.activeOption === 'custom domain' || this.activeOption === 'Custom Domain'
    }
  }
}
</script>

<style scoped>

</style>
