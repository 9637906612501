<template>
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Download Desktop App
          </p>
          <div>
            <Button
              @click="close"
              icon="close-1"
              class="!p-1.5"
              size="small"
              color="tertiary"
            />
          </div>
        </div>
      </div>
      <div class="px-4 py-3 sm:px-6 flex flex-col gap-2">
        <div class="flex items-center justify-between">
          <a
            href="https://v2-desktop-public.s3.us-west-1.amazonaws.com/TexAu+V2+Setup+2.2.0.exe"
          >
            <Button text="Windows" color="tertiary" rightIcon="right-arrow" />
          </a>
          <a
            href="https://v2-desktop-public.s3.us-west-1.amazonaws.com/TexAu%2BV2-2.1.4-arm64.dmg"
          >
            <Button
              text="Mac Os(ARM)"
              color="tertiary"
              rightIcon="right-arrow"
            />
          </a>
          <a
            href="https://v2-desktop-public.s3.us-west-1.amazonaws.com/TexAu%2BV2-2.1.4.dmg"
          >
            <Button
              text="Mac Os(Intel)"
              color="tertiary"
              rightIcon="right-arrow"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import { Field } from 'vee-validate'
import { inviteDesktop } from '@/apis/settings/desktop'
export default {
  name: 'DesktopAddModal',
  components: {
    Button,
    Input
  },
  data() {
    return {
      loader: false,
      gotKey: false,
      key: null,
      name: null,
      showSave: true,
      schema: {
        'Desktop Name': 'required'
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async submitCreate() {
      try {
        this.loader = true
        const response = await inviteDesktop(this.name)
        if (response['success']) {
          this.gotKey = true
          this.key = response.data.desktopLicenseKey
          this.showSave = false
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.loader = false
      }
    },
    async copy() {
      await navigator.clipboard.writeText(this.key)
      this.$emit('success', 'Desktop Registration Key Copied')
    }
  }
}
</script>
