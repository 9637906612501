import automationStore from '@/store/module/automation-store'
import automationMatrix from '@/store/module/automationMatrix'
import registration from '@/store/module/registration'
import settings from '@/store/module/settings'
import user from '@/store/module/user'
import workflow from '@/store/module/workflow'
import { createStore } from 'vuex'
// Create a new store instance.

export const store = createStore({
  modules: {
    registration,
    settings,
    automationMatrix,
    automationStore,
    user,
    workflow
  }
})
