<template>
  <div
    class="relative flex flex-1 border-l border-gray-200 antialiased dark:border-gray-700"
  >
    <div class="relative flex flex-1 flex-col justify-between px-4 py-5 sm:p-6">
      <div class="flex-1">
        <AgencyWhitelabelHeader activeOption="agency" />
        <div class="mt-8 flex items-start">
          <div class="w-56 shrink-0">
            <div
              class="flex justify-center rounded-lg border-2 border-dashed border-blue-300 bg-blue-50 px-6 py-8 dark:border-blue-700 dark:bg-blue-900/50"
            >
              <div class="space-y-1 text-center">
                <div>
                  <label
                    for="file-upload"
                    class="relative inline-flex cursor-pointer items-center justify-center rounded-lg border border-transparent bg-blue-600 px-4 py-2.5 text-base font-semibold text-white transition-all duration-200 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900 sm:text-sm"
                  >
                    <SvgIcon class="h-5 w-5" name="upload" />
                    <span> Upload a file </span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      class="sr-only"
                    />
                  </label>
                  <p
                    class="mt-3 text-base font-semibold text-blue-600 sm:text-sm"
                  >
                    or drag and drop
                  </p>

                  <p class="mt-8 text-sm font-medium text-blue-400">
                    (Square,max.300 px)
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="ml-16 flex-1">
            <div class="grid grid-cols-3 gap-x-6 gap-y-5">
              <div class="col-span-3">
                <Input
                  type="text"
                  text="Your company legal name"
                  label="Company Name"
                  label-class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                />
              </div>

              <div class="col-span-3">
                <Input
                  type="text"
                  text="Your company address"
                  label="Address"
                  label-class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                />
              </div>

              <div>
                <Input
                  type="text"
                  text="Postal code"
                  label="Postal code"
                  label-class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                />
              </div>

              <div>
                <Select text="Select State" options="" label="State/Region" />
              </div>

              <div>
                <Select text="Select Country" options="" label="Country" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 flex items-center justify-end">
        <Button text="Save" />
      </div>
    </div>
  </div>
</template>

<script>
import AgencyWhitelabelHeader from '@/components/settings/agencyWhitelabelHeader.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import Button from '@/components/Button.vue'

export default {
  name: 'agencySection',
  components: {
    AgencyWhitelabelHeader,
    SvgIcon,
    Input,
    Select,
    Button
  }
}
</script>

<style scoped></style>
