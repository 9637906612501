<template>
  <!-- START RIGHT COLUMN -->
  <div class="flex-1 bg-white dark:bg-gray-900">
    <div class="grid h-full items-start justify-center">
      <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
        <div class="flex h-full max-w-lg flex-col">
          <a href="#" title="TexAu" class="">
            <img
              class="mx-auto h-12 w-auto xl:h-14"
              src="@/assets/images/logo-alt.png"
              alt="TexAu logo"
            />
          </a>

          <div class="mt-12 grid h-full place-items-center">
            <div>
              <div class="text-center">
                <Heading text="Welcome to TexAu!" />
                <p class="mt-4 text-base text-gray-600 dark:text-gray-400">
                  Please enter the code from your authenticator app.
                </p>
              </div>

              <vee-form
                @submit="submitCreate()"
                class="mt-10"
                :validation-schema="schema"
              >
                <div class="sm:flex sm:items-end sm:space-x-5">
                  <div class="flex-1">
                    <label
                      for=""
                      class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                    >
                      Code
                    </label>
                    <div class="mt-1.5">
                      <Input
                        v-model="code"
                        text="Enter code"
                        type="tel"
                        name="code"
                      />
                    </div>
                  </div>

                  <div class="mt-4 shrink-0 sm:mt-0">
                    <Button
                      :showLoader="isLoading"
                      type="submit"
                      text="Log In"
                      rightIcon="right-arrow"
                    />
                  </div>
                </div>
                <p class="mt-2 text-sm text-red-600">
                  <ErrorMessage name="code" />
                </p>
              </vee-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END RIGHT COLUMN -->
  <ToastContainer class="w-1/5" ref="toast" />
</template>

<script>
import OnboardingLeftColumn from '@/components/onboarding/OnboardingLeftColumn.vue'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'
import Heading from '@/components/onboarding/Heading.vue'
import arrow from '@/assets/images/onboarding/illustrations/right-arrow-icon.svg'
import { mapActions, mapGetters } from 'vuex'
import { confirmLogin } from '@/apis/onboarding'
import ToastContainer from '@/components/ToastContainer.vue'

export default {
  name: 'totp',
  components: {
    OnboardingLeftColumn,
    Input,
    Button,
    Heading,
    ToastContainer
  },
  data() {
    return {
      code: '',
      isLoading: false,
      arrow,
      schema: {
        code: 'required|min:6|max:6'
      }
    }
  },
  computed: {
    ...mapGetters('registration', ['getLoginData', 'getRegistrationData'])
  },
  methods: {
    ...mapActions('registration', ['loginSuccess']),

    async submitCreate() {
      this.isLoading = true
      const { session } = new Proxy(this.getLoginData, {})
      const email = localStorage.getItem('email')
      const response = await confirmLogin(email, session, this.code)
      this.isLoading = false
      if (response['success']) {
        this.loginSuccess({ payload: response.data })
        this.$router.go()
      } else {
        this.$refs.toast.addToast({
          timeout: 7000,
          text: 'Error Occurred!',
          color: 'red',
          caption: response.message,
          action: false,
          close: true
        })
      }
    }
  }
}
</script>

<style></style>
