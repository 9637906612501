<template>
  <div>
    <div v-if="label">
      <label for="" :class="labelClass" class="flex">
        {{ label }}
        <SvgIcon
          class="mb-3 ml-1 h-2 w-2 text-red-600"
          name="star"
          v-if="isRequired"
        />
      </label>
    </div>

    <div>
      <div
        class="border-gray-300 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:hover:border-gray-500"
      >
        <div
          v-if="editor"
          @click.stop
          class="flex gap-2 rounded-t-lg border border-b-0 border-inherit p-2"
        >
          <div
            v-for="toggle of [
              {
                name: 'bold',
                icon: 'bold',
                function: 'toggleBold'
              },
              {
                name: 'italic',
                icon: 'italic',
                function: 'toggleItalic'
              },
              {
                name: 'underline',
                icon: 'underlined',
                function: 'toggleUnderline'
              },
              {
                name: 'strike',
                icon: 'strikethrough',
                function: 'toggleStrike'
              }
            ]"
            @click="editor.chain().focus()[toggle.function]().run()"
            :disabled="!editor.can().chain().focus()[toggle.function]().run()"
            class="cursor-pointer rounded p-1 transition-colors"
            :class="{
              'bg-blue-100 fill-blue-600 dark:bg-blue-900 dark:text-blue-50':
                editor.isActive(toggle.name),
              'hover:bg-gray-200': !editor.isActive(toggle.name)
            }"
          >
            <svg-icon :name="toggle.icon" class="h-5 w-5 fill-inherit" />
          </div>
          <slot name="toolbar"></slot>

          <!-- TODO: Add Sheet Condition -->
          <Popper
            v-if="props.previousNodes?.length > 0"
            placement="bottom-start"
            locked
            :interactive="true"
            :style="{ margin: 0, border: 0 }"
            :show="showSheetsInput"
            @close:popper="showSheetsInput = false"
            class=""
          >
            <div
              class="w-fit cursor-pointer rounded p-1"
              :class="{
                'bg-blue-100 stroke-blue-600 dark:bg-blue-900 dark:text-blue-50 ':
                  showSheetsInput,
                'stroke-black hover:bg-gray-200': !showSheetsInput
              }"
              @click="showSheetsInput = !showSheetsInput"
            >
              <svg-icon name="sheet" class="h-5 w-5 stroke-inherit" />
            </div>
            <template #content v-if="showDropIcon">
              <sheetInputModal
                :previousNodes="previousNodes"
                @variable-selected="onVariableSelection"
                @function-selected="onFunctionSelection"
                @tag-selected="onTagSelection"
                :class="
                  modalClass ? modalClass + 'max-w-[660px]' : 'max-w-[660px]'
                "
              />
            </template>
          </Popper>

          <Popper
            placement="bottom-start"
            locked
            :interactive="true"
            :style="{ margin: 0, border: 0 }"
            :show="showVariables"
            @close:popper="showVariables = false"
            class="w-[100px]"
          >
            <div
              v-if="includeVariables"
              class="w-fit cursor-pointer rounded p-1"
              :class="{
                'bg-blue-100 stroke-blue-600 dark:bg-blue-900 dark:text-blue-50 ':
                  showVariables,
                'stroke-black hover:bg-gray-200': !showVariables
              }"
              @click="showVariables = !showVariables"
            >
              <svg-icon name="tag" class="h-5 w-5 stroke-inherit" />
            </div>
            <template #content v-if="showDropIcon">
              <VariableModal
                :previousNodes="previousNodes"
                :personalisedTags="personalisedTags"
                @variable-selected="onVariableSelection"
                @function-selected="onFunctionSelection"
                @tag-selected="onTagSelection"
                :class="
                  modalClass ? modalClass + 'max-w-[660px]' : 'max-w-[660px]'
                "
              />
            </template>
          </Popper>

          <div
            v-if="showRemoveTemplatesButton || isPromptIdProvided"
            class="flex items-end justify-end w-full"
          >
            <Popper placement="top" hover :style="{ margin: 0, border: 0 }">
              <div
                class="cursor-pointer w-fit rounded p-1 stroke-black hover:bg-gray-200"
                @click="removeTemplate"
              >
                <svg-icon name="close" class="h-5 w-5 stroke-inherit" />
              </div>
              <template #content>
                <div class="w-max max-w-lg rounded bg-blue-50 p-2 text-sm">
                  Remove Template
                </div>
              </template>
            </Popper>
          </div>
        </div>

        <editor-content
          :editor="editor"
          @click="handleEditorClick"
          @dblclick="handleEditorClick"
          class="border-inherit"
        />
      </div>
      <div class="flex justify-between mt-2">
        <p v-if="description" class="text-sm text-gray-500">
          {{ description }}
          <span v-if="showMaxCharCount">
            Max {{ isPremium === true ? '300' : '200' }} Characters</span
          >
        </p>
        <div v-if="includeAiTemplate">
          <Popper
            placement="right"
            locked
            :interactive="true"
            :style="{ margin: 0, border: 0 }"
            :show="showTemplates"
            @close:popper="showTemplates = false"
          >
            <button
              @click="showTemplates = !showTemplates"
              class="ai-button px-[10px] py-[6px]"
            >
              <div class="items-center justify-center flex gap-[7px]">
                <div class="">
                  <SvgIcon class="h-4 w-4" name="magic-wand-color" />
                </div>

                <p class="ai-text">Insert AI Template</p>
              </div>
            </button>
            <template #content>
              <VariableMenu
                :text="editor.getText({ blockSeparator: '\n' })"
                v-if="showTemplates"
                @selectedTemplate="onTemplateSelection"
                @closeTemplate="showTemplates = false"
                :playground="false"
              />
            </template>
          </Popper>
        </div>
      </div>
      <div v-if="showMaxCharCount">
        <p v-html="getCharCount"></p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { AUTOMATION_VARIABLE_TYPES } from '@/common/constants'
import SvgIcon from '@/components/SvgIcon.vue'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import { EditorContent, useEditor } from '@tiptap/vue-3'
import { computed, onBeforeUnmount, ref, watch, onMounted } from 'vue'
import Popper from 'vue3-popper'
import { convertInputStringToHtml } from '../helper'
import { FunctionEnd, FunctionParamSeparator, FunctionStart } from './Function'
import { Tag } from './Tag'
import { Variable } from './Variable'
import VariableModal from './variableModal.vue'
import sheetInputModal from './sheetInputModal.vue'
import { useRoute } from 'vue-router'
import VariableMenu from '@/components/settings/texauAI/VariableMenu.vue'
import { charCount } from '@/common/functions/linkedinCharCounter'

const props = defineProps({
  modelValue: {},
  label: String,
  labelClass: String,
  type: String,
  placeholder: { type: String, default: 'write something...' },
  readonly: Boolean,
  disabled: Boolean,
  previousNodes: Array,
  isRequired: Boolean,
  modalClass: String,
  personalisedTags: Array,
  hasFileAttachment: Boolean,
  includeAiTemplate: { type: Boolean, default: true },
  includeVariables: { type: Boolean, default: true },
  description: String,
  isPromptIdProvided: { type: Boolean, default: false },
  isPremium: {},
  platformOperationId: {}
})

const emit = defineEmits([
  'update:modelValue',
  'label-update',
  'blur',
  'templateInserted'
])

const route = useRoute()
const showVariables = ref(false)
const showSheetsInput = ref(false)
const showTemplates = ref(false)
const showRemoveTemplatesButton = ref(false)
const showMaxCharCount = ref(false)

const autoConnectOperationId = '640f16290936e46db5716df5'

const editor = useEditor({
  content: convertInputStringToHtml(props.modelValue).replace(/\n/g, '<br>'),
  extensions: [
    StarterKit,
    FunctionStart,
    FunctionParamSeparator,
    FunctionEnd,
    Variable,
    Tag,
    Placeholder.configure({ placeholder: props.placeholder }),
    Underline
  ],
  editorProps: {
    attributes: {
      class:
        'block w-full border px-3 py-2.5 pr-8 placeholder-gray-500 duration-200 dark:placeholder-gray-400 sm:text-sm border-gray-300 caret-blue-600  dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 min-h-[100px] outline-none border-inherit ' +
        (props.hasFileAttachment ? '' : 'rounded-b-lg')
    }
  },
  onUpdate() {
    const text = editor.value.getText({ blockSeparator: '\n' })
    // update vmodel whenever content is updated.
    emit('update:modelValue', convertToProperType(text))
  },
  onBlur() {
    const text = editor.value.getText({ blockSeparator: '\n' })
    emit('blur', convertToProperType(text))
  },
  editable: !props.disabled && !props.readonly
})

onMounted(() => {
  if (
    route.query.operationId === autoConnectOperationId ||
    props.platformOperationId === autoConnectOperationId
  ) {
    showMaxCharCount.value = true
  }

  if (props.isPromptIdProvided === true) {
    editor.value.commands.focus()
    editor.value.setOptions({ editable: false })
  }
})

// destroy the editor on unmount
onBeforeUnmount(() => {
  editor.value.destroy()
})

const handleEditorClick = e => showVariables.value && e.stopPropagation()

const onVariableSelection = (nodeId, output) => {
  showVariables.value = false
  editor.value.commands.insertContent({
    type: 'variable',
    attrs: {
      nodeId,
      outputName: output.name
    }
  })
  editor.value.commands.focus()
}

const onTagSelection = tag => {
  showVariables.value = false
  editor.value.commands.insertContent({
    type: 'tag',
    attrs: {
      name: tag.value
    }
  })
  editor.value.commands.focus()
}

watch(props.isPromptIdProvided, newX => {
  if (newX === true) {
    editor.value.commands.focus()
    editor.value.setOptions({ editable: false })
  }
})

const onTemplateSelection = template => {
  showTemplates.value = false
  showRemoveTemplatesButton.value = true
  editor.value.commands.clearContent()
  editor.value.commands.insertContent({
    type: 'variable',
    attrs: {
      nodeId: template._id,
      outputName: template.title
    }
  })
  editor.value.commands.focus()
  editor.value.setOptions({ editable: false })
  emit('templateInserted')
}

const removeTemplate = () => {
  editor.value.commands.clearContent()
  editor.value.setOptions({ editable: true })
  showRemoveTemplatesButton.value = false
  emit('templateRemoved')
}

const onFunctionSelection = (category, rule) => {
  showVariables.value = false
  editor.value.commands.insertContent({
    type: 'function-start',
    attrs: {
      category,
      ...rule
    }
  })
  const pos = editor.value.state.selection.$anchor.pos
  for (let i = 0; i < rule.numberOfParameters - 1; i++) {
    editor.value.commands.insertContent({
      type: 'function-param-separator',
      attrs: {
        category,
        ...rule
      }
    })
  }
  editor.value.commands.insertContent({
    type: 'function-end',
    attrs: {
      category,
      ...rule
    }
  })
  if (rule.numberOfParameters > 0) {
    editor.value.commands.focus(pos)
  } else {
    editor.value.commands.focus()
  }
}

const showDropIcon = computed(() => {
  let str = location.pathname.split('/')
  return true
})

const getCharCount = computed(() => {
  try {
    let text = props.modelValue
    let { count, characterCount } = charCount(text, props.isPremium)

    if (text.startsWith('{{') && text.endsWith('}}')) {
      return `<span class='text-green-500' >Make Sure Your Template Is Within ${count} Character </span>`
    }

    if (count >= characterCount) {
      return `<span class='text-green-500' >Character Count: ${characterCount}/${count}</span>`
    } else {
      return `<span class='text-red-500' >Character Count: ${characterCount}/${count}</span>`
    }
  } catch (error) {
    console.log(error)
  }
})

const convertToProperType = value => {
  if (props.type === AUTOMATION_VARIABLE_TYPES.NUMBER && /^\d+$/.test(value)) {
    return Number(value)
  }
  return value
}
</script>

<style>
/* Styles for Placeholder (at the top) */

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.ProseMirror p:empty::after {
  content: '\00A0';
}

.single-line {
  overflow: hidden;
}

.ai-button {
  border-radius: 7px;
  border: 1px solid var(--gradients-gradient-style-5, #fc00ff);
  background: var(--secondary-dark-grey-100, #eff4fb);
}

.ai-text {
  background: var(
    --gradients-gradient-style-5,
    linear-gradient(295deg, #fc00ff 0%, #00dbde 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  /* Paragraph Small/Bold */

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 15.6px */
}
</style>
