<template>
  <img
    v-if="mode === 'dark'"
    alt="TexAu logo"
    class="mx-auto h-12 w-auto xl:h-14"
    src="@/assets/images/logo-alt.png"
  />
  <img
    v-else
    alt="TexAu logo"
    class="mx-auto h-12 w-auto xl:h-14"
    src="@/assets/images/logo.svg"
  />
</template>
<script>
export default {
  mounted() {
    this.mode = localStorage.getItem('theme')
  },
  data() {
    return {
      mode: 'light'
    }
  }
}
</script>
