<template>
  <AutomationHeader
    :highlight="highlight"
    :nav="true"
    icon="instagram"
    navText="sheets"
    title="Collect Instagram Followers"
  />

  <section class="flex flex-1 border-t border-gray-200 dark:border-gray-700">
    <OptionalSidebar :data="optionalSidebarData" :search="true" item="start" />
    <GoogleSheetsMain />
  </section>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'

import OptionalSidebar from '@/components/automationStore/OptionalSidebar.vue'

import GoogleSheetsMain from '@/components/automationStore/googleSheets/googleSheetsMain.vue'

export default {
  components: {
    AutomationHeader,

    OptionalSidebar,

    GoogleSheetsMain
  },
  name: 'AutomationStorePage10',
  data() {
    return {
      highlight: 1,
      optionalSidebarData: [
        'Select the sheet with your data input',
        'Select the sheet with your data input',
        'Select the colum header to process',
        'Run now or schedule for later!'
      ]
    }
  }
}
</script>

<style></style>
