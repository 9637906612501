import axios from '@/common/axios'

const getUserWorkspace = async email => {
  try {
    const response = await axios.get(`/admin/workspaces?userEmail=${email}`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

export { getUserWorkspace }
