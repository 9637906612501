export const charCount = (text, isPremium) => {
  let tagPattern = /\[\[[^\]]*\]\]/g
  let tagPattern1 = /\{\{.*?\}\}/g
  let tags = text.match(tagPattern) || []
  let tags1 = text.match(tagPattern1) || []
  let characterCount = text.length
  tags.forEach(tag => {
    // Subtract the length of the tag and add 15
    characterCount -= tag.length
    characterCount += 15
  })

  tags1.forEach(tag => {
    // Subtract the length of the tag and add 15
    characterCount -= tag.length
    characterCount += 15
  })

  let count
  if (isPremium) {
    count = 300
  } else {
    count = 200
  }

  return {
    count,
    characterCount
  }
}
