<template>
  <button
    class="inline-flex items-center justify-center rounded-lg border font-semibold transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 border-gray-300 bg-white px-4 text-gray-600 hover:bg-gray-50 hover:text-gray-900 focus:ring-gray-400 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900 p-3 !p-1.5"
  >
    <svg
      class="h-5 w-5"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M17 7L7 17M7 7L17 17"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'ModalCloseButton'
}
</script>
