<template>
  <div class="relative flex-1 antialiased dark:border-gray-700">
    <div class="flex h-full items-center justify-center" v-if="isLoading">
      <Spinner size="large" />
    </div>

    <div v-else>
      <div
        v-if="userRole !== 'viewer'"
        class="flex items-center pt-2 px-4 justify-end"
      >
        <img
          v-if="integrationData && integrationData.name === 'google-sheets'"
          @click="onGoogleClick()"
          class="h-[46px] cursor-pointer"
          src="@/assets/images/logos/btn_google_signin_dark_normal_web@2x.png"
          alt="signin-with-google"
        />

        <Button
          v-else
          text="New Account"
          leftIcon="plus"
          class="h-[33px]"
          @click="$emit('onAddNewClick')"
        />
      </div>
      <div class="flex flex-col">
        <IntegrationConnectedAccount
          v-if="accountData && accountData.length > 0"
          :accountData="accountData"
          :platform="platform"
          :type="integrationData.auth.type"
          @apiFail="apiFail"
          @apiSuccess="apiSuccess"
          @updateApiAccountIc="updateApiAccountIc"
          @refetch="getAccounts()"
        />
        <div
          v-else
          class="flex h-[60vh] w-full flex-col items-center justify-center space-y-4 dark:border-gray-700"
        >
          <SvgIcon :name="emptyData" />
          <p class="text-3xl font-bold text-gray-900 dark:text-gray-50">
            No Data Available
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-auto flex px-4 py-5">
    <Button text="Go Back" color="secondary" @click="onClick" />
    <button
      @click.prevent="modalToggle($event)"
      class="hidden"
      id="addNewAccount"
    >
      open modal
    </button>
    <!-- TODO: WIll be needed later -->
    <!-- <Button
      v-if="integrationData"
      class="hidden"
      :dataAttribute="integrationData._id"
      :name="integrationData.type === 'automation' ? 'texau-extension-v2' : ''"
      :icon="integrationData.type === 'automation' ? 'external-link' : 'plus'"
      :text="integrationData.type === 'automation' ? 'Install Extension' : null"
      size="small"
      id="addNewAccount"
      @click.prevent="modalToggle($event)"
    /> -->
  </div>
</template>

<script>
import IntegrationConnectedAccount from '@/components/settings/integrationConnectedAccount.vue'
import { getConnectedAccounts } from '@/apis/automation-store/Page1'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import { startOauthConnection } from '@/apis/oauth'
import { mapState } from 'vuex'
export default {
  name: 'IntegrationAccounts',
  data() {
    return {
      accountData: null,
      platform: null,
      emptyData: null,
      isLoading: true,
      accountIdForUpdateApi: null,
      accountNameForUpdateApi: null,
      channel: null
    }
  },
  mounted() {
    this.getAccounts()
    this.getMode()
    this.channel = new BroadcastChannel('app-data')
    this.channel.onmessage = event => {
      if (event.data === 'accountSuccess') {
        this.getAccounts()
      }
    }
  },
  props: {
    integrationData: { type: String, required: true },
    fetchNewData: { type: Boolean }
  },
  computed: {
    ...mapState('settings', ['userRole'])
  },
  watch: {
    fetchNewData(newVal, oldVal) {
      if (newVal) {
        this.getAccounts()
      }
    }
  },
  methods: {
    async onGoogleClick() {
      this.$emit('onAddNewClick')
    },
    async getAccounts() {
      this.isLoading = true
      const response = await getConnectedAccounts(this.integrationData._id)
      this.accountData = response.data
      var regexp =
        /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
      if (response.data.length > 0) {
        if (regexp.test(response.data[0].platform.userId)) {
          let url = response.data[0].platform.userId
          let domain = new URL(url)
          domain = domain.hostname.replace('www.', '').replace('.com', '')
          this.platform = domain[0].toUpperCase() + domain.slice(1)
        } else {
          this.platform = 'Your'
        }
      }
      this.isLoading = false
      this.$emit('resetFetch')
    },
    getMode() {
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? (this.emptyData = 'empty-data-dark')
        : (this.emptyData = 'empty-data-light')
    },
    onClick() {
      this.$emit('showIntegration', 'integration')
    },
    async modalToggle(e) {
      let item
      if (this.integrationData.auth.type === 'OAuth2') {
        this.addOauthAccount(this.integrationData.name)
      } else if (this.integrationData.auth.type === 'apiKey') {
        if (this.accountIdForUpdateApi) {
          item = {
            _id: this.integrationData._id,
            name: this.integrationData.name,
            type: this.integrationData.type,
            label: this.integrationData.label,
            logoUrl: this.integrationData.logoUrl,
            update: true,
            accountId: this.accountIdForUpdateApi,
            accountName: this.accountNameForUpdateApi
          }
        } else {
          item = {
            _id: this.integrationData._id,
            name: this.integrationData.name,
            type: this.integrationData.type,
            logoUrl: this.integrationData.logoUrl,
            label: this.integrationData.label,
            update: false
          }
        }

        let val = true
        this.$emit('modalActivation', { item, val })
        this.accountIdForUpdateApi = null
        this.accountNameForUpdateApi = null
      } else {
        item = {
          _id: this.integrationData._id,
          name: this.integrationData.name,
          type: this.integrationData.type,
          logoUrl: this.integrationData.logoUrl,
          label: this.integrationData.label,
          auth: this.integrationData.auth
        }
        let val = true
        this.$emit('modalActivation', { item, val })
      }
    },
    async addOauthAccount(name) {
      const response = await startOauthConnection(name)

      if (response['success']) {
        window.open(response.data.url, '_blank')
      } else {
        this.$emit('error', 'Error in adding new google account')
      }
    },
    apiFail(data) {
      this.$emit('apiFail', data)
    },
    apiSuccess(data) {
      this.$emit('apiSuccess', data)
    },
    updateApiAccountIc(data) {
      this.accountIdForUpdateApi = data.id
      this.accountNameForUpdateApi = data.name
    }
  },
  components: {
    IntegrationConnectedAccount,
    SvgIcon,
    Button,
    Spinner
  }
}
</script>

<style></style>
