<template>
  <AutomationHeader
      :highlight="highlight"
      :nav="true"
      icon="instagram"
      title="Collect Instagram Followers"
  />

  <section
      class="flex flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <OptionalSidebar :data="optionalSidebarData" item="center"/>

    <SchedulingMode2/>
  </section>
</template>

<script>
import AutomationCards from '../../components/automationStore/AutomationCards.vue'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import AutomationSelector from '@/components/automationStore/AutomationSelector.vue'
import AutomationInputPicker from '@/components/automationStore/AutomationInputPicker.vue'
import OptionalSidebar from '@/components/automationStore/OptionalSidebar.vue'
import SchedulingMode2 from '@/components/automationStore/SchedulingMode2.vue'

export default {
  components: {
    AutomationCards,
    AutomationHeader,
    AutomationSelector,
    AutomationInputPicker,
    OptionalSidebar,
    SchedulingMode2
  },
  name: 'AutomationStorePage5',
  data() {
    return {
      highlight: 2,
      optionalSidebarData: [
        '**Optional Step, only required to plan in advance',
        'Select the schedule recurrence (frequency)',
        'Set start time offset (Optional)',
        'Set start time offset (Optional)'
      ]
    }
  }
}
</script>

<style></style>
