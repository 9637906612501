<template>
  <AutomationHeader
    title="Create New Workspace"
    @emitSuccess="data => $emit('success', data)"
  />
  <section class="flex flex-1 border-t border-gray-200 dark:border-gray-700">
    <SettingsMenu active-option="workspace" />
    <CreateNewWorkspaceSection @error="data => $emit('error', data)" />
  </section>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import CreateNewWorkspaceSection from '@/components/settings/createNewWorkspaceSection.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import ToastContainer from '@/components/ToastContainer.vue'

export default {
  name: 'createNewWorkspace',
  components: {
    AutomationHeader,
    SettingsMenu,
    CreateNewWorkspaceSection,
    ToastContainer
  }
}
</script>

<style scoped></style>
