<template>
  <div
    class="flex flex-1 overflow-scroll flex-col bg-white px-4 py-[18px] text-gray-900 dark:bg-gray-900 dark:text-gray-50"
  >
    <p class="text-base font-semibold text-gray-900 dark:text-gray-50">
      Categories
    </p>

    <nav
      class="-mx-3 mt-4 flex flex-1 flex-col px-2 space-y-1.5"
      aria-label="Sidebar"
    >
      <ul>
        <li
          v-for="(option, index) in categoryOptions"
          :key="option"
          @click="onCategoryClick(index, option.name)"
          class="align-start my-1 flex cursor-pointer items-center rounded-lg px-3 py-[7px] text-center text-sm font-medium text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
          :class="{
            'bg-blue-50 text-blue-600 dark:bg-blue-500 dark:!text-blue-50':
              selected === option.name
          }"
          aria-current="page"
        >
          <div
            class="mr-2 h-7 w-7 flex items-center justify-center rounded-md p-1"
            :class="
              ({ 'bg-blue-200 text-blue-600': selected === option.name },
              { 'bg-slate-100': selected !== option.name })
            "
            v-if="option.icon"
          >
            <div v-html="option.svg"></div>
          </div>
          <p class="truncate">{{ option.name }}</p>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'AutomationStoreCategories',
  data() {
    return {
      categoryOptions: this.options
    }
  },
  props: {
    options: { type: Array, required: true },
    selected: String
  },

  methods: {
    onCategoryClick(index, name) {
      this.$emit('categoryUpdate', name)
    }
  }
}
</script>

<style></style>
