<!-- Card Component -->
<!-- This component has 2 types of card normal and feature -->
<!-- To access feature card pass v-bind:feature="true" as prop -->
<!-- Feature card props: v-bind:feature="true", heading, text, buttonText -->
<!-- Normal card props: heading, buttonText, color:white(default), black, primary(blue), success(green), error(red) -->
<!-- A badge can be added on normal cards by passing v-bind:badge='true', badgeText as props -->
<!-- badge color will depend on the color passed for the card -->

<template>
  <div>
    <div
      v-if="!feature"
      class="overflow-hidden rounded-xl border border-gray-100 transition-all duration-200 hover:shadow-lg hover:shadow-gray-300/50"
    >
      <div
        :class="[
          {
            'bg-white': white,
            'bg-gray-900': black,
            'bg-blue-800': primary,
            'bg-green-800': success,
            'bg-red-800': error
          }
        ]"
      >
        <div class="px-4 py-5 sm:p-6">
          <div class="flex items-center justify-between">
            <div class="flex -space-x-2 overflow-hidden">
              <span
                class="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100 ring-2 ring-white"
              >
                <svg
                  class="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              </span>
              <span
                class="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100 ring-2 ring-white"
              >
                <svg
                  class="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              </span>
              <span
                class="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100 ring-2 ring-white"
              >
                <svg
                  class="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
              </span>
            </div>
            <span
              v-if="badge"
              class="inline-flex items-center rounded-full border px-3 py-1.5 text-sm font-semibold"
              :class="[
                {
                  'border-gray-500 bg-white text-gray-500': white,
                  'border border-gray-400 bg-gray-900 text-gray-400': black,
                  'border border-blue-300 bg-blue-800 text-blue-200': primary,
                  'border border-green-300 bg-green-800 text-green-200':
                    success,
                  'border border-red-300 bg-red-800 text-red-200': error
                }
              ]"
            >
              {{ badgeText }}
            </span>
          </div>
          <p
            class="mt-8 text-2xl font-bold tracking-tight"
            :class="[
              {
                'text-gray-900': white,
                'text-white': black || primary || success || error
              }
            ]"
          >
            {{ heading }}
          </p>
        </div>
      </div>

      <button
        type="button"
        class="flex w-full items-center justify-between px-4 py-5 text-base font-semibold transition-all duration-200 sm:px-6"
        :class="[
          {
            'bg-gray-100 text-gray-900 hover:bg-gray-200': white,
            'bg-gray-700 text-white hover:bg-gray-600': black,
            'bg-blue-600  text-white hover:bg-blue-700': primary,
            'bg-green-600  text-white hover:bg-green-700': success,
            'bg-red-600  text-white hover:bg-red-700': error
          }
        ]"
      >
        {{ buttonText }}
        <svg
          class="h-6 w-6"
          viewBox="0 0 26 26"
          fill="none"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.375 20.8047L17.125 13.0547L9.375 5.30469"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <div v-if="feature" class="mt-8 grid grid-cols-3 gap-8">
      <div
        class="relative overflow-hidden rounded-xl border border-gray-100 bg-white transition-all duration-200 hover:shadow-lg hover:shadow-gray-300/50"
      >
        <div class="aspect-w-16 aspect-h-9 bg-blue-200"></div>

        <div class="px-4 py-5 sm:p-6">
          <p
            class="text-sm font-semibold uppercase tracking-wider text-gray-500"
          >
            {{ heading }}
          </p>
          <p
            class="mt-6 text-2xl font-bold tracking-tight text-gray-900 line-clamp-3"
          >
            {{ text }}
          </p>
          <div class="mt-6">
            <a
              href="#"
              title=""
              class="flex w-full items-center justify-between text-base font-semibold text-blue-600 transition-all duration-200 hover:text-blue-700"
            >
              {{ buttonText }}
              <svg
                class="h-6 w-6"
                viewBox="0 0 26 26"
                fill="none"
                stroke="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.375 20.8047L17.125 13.0547L9.375 5.30469"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span class="absolute inset-0" aria-hidden="true"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    color: { type: String, default: 'white' },
    badge: { type: Boolean, default: false },
    feature: { type: Boolean, default: false },
    badgeText: { type: String, default: 'Placeholder' },
    heading: { type: String, default: 'Add heading text' },
    buttonText: { type: String, default: 'Add button text' },
    text: { type: String, default: 'Add text' }
  },
  computed: {
    black() {
      return this.color === 'black'
    },
    primary() {
      return this.color === 'primary'
    },
    success() {
      return this.color === 'success'
    },
    error() {
      return this.color === 'error'
    },
    white() {
      return this.color === 'white'
    }
  }
}
</script>

<style></style>
