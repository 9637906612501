<template>
  <!-- START WRAPPER -->
  <div
    v-show="modalActive"
    class="absolute top-0 left-0 z-10 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Add New Rotating Proxies
          </p>

          <div>
            <Button
              @click="close"
              icon="close-1"
              class="!p-1.5"
              size="small"
              color="tertiary"
            />
          </div>
        </div>
      </div>

      <div class="px-4 pb-5 sm:px-6 sm:pb-6">
        <!-- <div>
          <label
            for=""
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
          >
            Select Workspace
          </label>
          <div class="mt-1.5">
            <Select text="Workspace: Default" />
          </div>
        </div> -->

        <div class="mt-4">
          <div class="border-b border-gray-200 dark:border-gray-700">
            <nav class="-mb-px flex space-x-8">
              <a
                href="#"
                title=""
                class="group inline-flex w-full items-center justify-center whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium transition-all duration-200"
                :class="[
                  {
                    'border-b-2 border-gray-900 text-gray-900 dark:border-gray-50 dark:text-gray-50':
                      tab === 'list'
                  },
                  {
                    'text-gray-600 hover:text-gray-700 dark:text-gray-400 dark:hover:border-gray-400 dark:hover:text-gray-50':
                      tab === 'file'
                  }
                ]"
                aria-current="page"
                @click.prevent="tab = 'list'"
              >
                Paste From List
              </a>

              <!-- <a
                href="#"
                title=""
                class="group inline-flex w-full items-center justify-center whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium transition-all duration-200"
                :class="[
                  {
                    'border-b-2 border-gray-900 text-gray-900 dark:border-gray-50 dark:text-gray-50':
                      tab === 'file'
                  },
                  {
                    'text-gray-600 hover:text-gray-700 dark:text-gray-400 dark:hover:border-gray-400 dark:hover:text-gray-50':
                      tab === 'list'
                  }
                ]"
                @click.prevent="tab = 'file'"
              >
                Upload From File
              </a> -->
            </nav>
          </div>
        </div>

        <PasteFromList v-show="tab === 'list'" />
        <UploadFromFile v-show="tab === 'file'" />
      </div>

      <div class="px-4 py-5 sm:px-6">
        <div class="relative flex items-center justify-between">
          <div></div>

          <div>
            <Button text="Add Proxies" leftIcon="plus" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import PasteFromList from '@/components/settings/RotatingProxy/PasteFromList.vue'
import UploadFromFile from '@/components/settings/RotatingProxy/UploadFromFile.vue'
import Button from '@/components/Button.vue'
import Select from '@/components/Select.vue'

export default {
  name: 'addRotatingProxy',
  data() {
    return {
      tab: 'list'
    }
  },
  props: ['modalActive'],
  methods: {
    close() {
      this.$emit('close')
    }
  },
  components: { PasteFromList, UploadFromFile, Button, Select }
}
</script>

<style></style>
