<!-- StatusDot Component -->
<!-- for color pass color='neutral',color='positive', color='warning', color='error'
-->
<!-- for size pass size='small', 'medium'
  default: 'medium'
 -->

<template>
  <div :class="[{ 'flex items-center space-x-4': label }]">
    <div
      class="rounded-full"
      :class="[
        {
          'bg-gray-300 dark:bg-gray-400': neutral,
          'bg-blue-600 dark:bg-blue-500': defaultValue,
          'bg-green-600 dark:bg-green-500': positive,
          'bg-yellow-500 dark:bg-yellow-400': warning,
          'bg-red-600 dark:bg-red-500': error,
          'h-3 w-3': size === 'small',
          'h-6 w-6': size === 'medium'
        }
      ]"
    ></div>
    <span
      v-if="label"
      class="text-base font-normal text-gray-900 dark:text-gray-50"
    >
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'StatusDot',
  props: {
    color: { type: String, default: 'default' },
    size: { type: String, default: 'medium' },
    label: { type: String, default: '' }
  },
  computed: {
    neutral() {
      return this.color === 'neutral' || this.color === 'Neutral'
    },
    defaultValue() {
      return this.color === 'default' || this.color === 'Default'
    },
    positive() {
      return this.color === 'Positive' || this.color === 'positive'
    },
    warning() {
      return this.color === 'Warning' || this.color === 'warning'
    },
    error() {
      return this.color === 'Error' || this.color === 'error'
    }
  }
}
</script>
