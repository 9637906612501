<template>
  <div
    class="fixed left-0 top-0 z-40 flex h-screen w-screen items-center justify-center"
  >
    <div
      class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
    >
      <div
        class="relative mx-auto w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
      >
        <div class="px-4 py-5 sm:px-6">
          <div class="">
            <div class="flex items-center justify-between">
              <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
                Choose a Desktop to Run this Workflow
              </p>

              <div class="flex items-center justify-end space-x-4">
                <Button
                  @click="close"
                  icon="close-1"
                  class="!p-1.5"
                  size="small"
                  color="tertiary"
                />
              </div>
            </div>
          </div>

          <div class="my-3 max-h-[60vh] overflow-y-scroll">
            <div
              v-if="noData"
              class="mt-10 flex w-full flex-col items-center justify-center space-y-4"
            >
              <SvgIcon name="empty-data-light" />
              <p class="text-3xl font-bold text-gray-900 dark:text-gray-50">
                No Desktop Available
              </p>
            </div>
            <div v-else>
              <div class="overflow-hidden">
                <table class="min-w-full">
                  <thead class="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th
                        scope="col"
                        class="rounded-l-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        class="rounded-l-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                      >
                        Connected
                      </th>
                      <th
                        scope="col"
                        class="rounded-l-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                      >
                        Registered
                      </th>
                      <th
                        scope="col"
                        class="rounded-r-lg px-3 py-3.5 text-left"
                      >
                        <span class="sr-only"> Actions </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="loading"
                    class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
                  >
                    <tr v-for="ele in 4" :key="ele">
                      <td
                        v-for="ele in 3"
                        :key="ele"
                        class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                      >
                        <Skeleton
                          height="40px"
                          width="140px"
                          borderRadius="8px"
                        ></Skeleton>
                      </td>
                    </tr>
                  </tbody>
                  <tbody
                    v-else
                    class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
                  >
                    <tr v-for="(data, index) in tableData" :key="data._id">
                      <td
                        class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                      >
                        {{ data.name }}
                      </td>

                      <td class="whitespace-nowrap px-3 py-4">
                        <div class="flex items-center">
                          <Badge
                            v-if="data.isConnected"
                            text="Connected"
                            color="added"
                          />
                          <Badge v-else text="Not Connected" color="removed" />
                        </div>
                      </td>

                      <td class="whitespace-nowrap px-3 py-4">
                        <div class="flex items-center">
                          <Badge
                            v-if="data.isRegistered"
                            text="Registered"
                            color="added"
                          />
                          <Badge v-else text="Not Registered" color="removed" />
                        </div>
                      </td>
                      <td
                        v-if="userRole !== 'viewer'"
                        class="whitespace-nowrap w-[300px] px-3 py-4"
                      >
                        <div
                          class="flex items-center justify-end space-x-3 px-10"
                        >
                          <Button
                            v-if="
                              currentSelected?._id &&
                              currentSelected._id === data._id
                            "
                            text="Cancel"
                            rightIcon="right-arrow"
                            color="danger"
                            @click="desktopRemove()"
                          />
                          <Button
                            v-else
                            text="Use this"
                            rightIcon="right-arrow"
                            color="
                           successFilled
                          "
                            @click="desktopClick(data)"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getDesktop } from '@/apis/settings/desktop'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Badge from '@/components/Badge.vue'
import NoAccess from '@/components/NoAccess.vue'
export default {
  name: 'desktopSelectModal',
  components: {
    SvgIcon,
    Button,
    NoAccess,
    Badge
  },
  props: {
    currentSelected: {},
    workflow: {}
  },
  computed: {},
  data() {
    return {
      loading: false,
      noData: false,
      modalOpen: false,
      tableData: null,
      reallyDeleteIndex: null,
      poll: true
    }
  },
  async mounted() {
    await this.getAllDesktop()
  },
  beforeUnmount() {
    this.poll = false
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getAllDesktop() {
      try {
        this.loading = true
        const response = await getDesktop()
        if (response.data.length > 0) {
          this.noData = false
          this.tableData = response.data
        } else {
          this.noData = true
        }
      } catch (error) {
        this.emitError(error)
      } finally {
        this.loading = false
      }
    },

    desktopClick(data) {
      this.$emit('desktopSelected', data)
      this.close()
    },
    desktopRemove() {
      this.$emit('desktopRemove')
      this.close()
    }
  }
}
</script>

<style></style>
