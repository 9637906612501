<template>
  <div class="relative flex-1 border-l border-gray-200 dark:border-gray-700 antialiased">
    <div class="px-4 py-5 sm:p-5">
      <div class="max-w-2xl mx-auto space-y-12">
        <LunchTimePause />

        <WorkingHours />

        <VacationTime />
      </div>
    </div>
  </div>

</template>

<script>
import LunchTimePause from '@/components/settings/scheduleTemplates/lunchTimePause.vue';
import WorkingHours from '@/components/settings/scheduleTemplates/workingHours.vue';
import VacationTime from "@/components/settings/scheduleTemplates/vacationTime.vue";

export default {
  name: "scheduleTemplateSection",
  components: {
    LunchTimePause,
    WorkingHours,
    VacationTime,
  }
}
</script>

<style scoped>

</style>
