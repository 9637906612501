import { useScroll, watchThrottled } from '@vueuse/core'
import { toRef } from 'vue'

const noop = async () => {}

export const useInfiniteScroll = (
  el,
  { onLoadMore = noop, throttle = 500 }
) => {
  const { arrivedState } = useScroll(el)
  const hasReachedBottom = toRef(arrivedState, 'bottom')

  watchThrottled(
    hasReachedBottom,
    async reached => {
      if (reached) {
        await onLoadMore()
      }
    },
    { throttle }
  )
}
