<template>
  <!-- START WRAPPER -->
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Add New Social Proxy
          </p>

          <div>
            <Button
              @click="close"
              icon="close-1"
              class="!p-1.5"
              size="small"
              color="tertiary"
            />
          </div>
        </div>
      </div>

      <vee-form
        @submit="submitCreate()"
        :validation-schema="schema"
        id="inputForm"
      >
        <div class="px-4 pb-5 sm:px-6 sm:pb-6">
          <div class="grid grid-cols-2 gap-x-6 gap-y-5">
            <!-- <div class="col-span-2">
              <label
                for=""
                class="block text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                Select Workspace
              </label>
              <div class="mt-1.5">
                <Select name="Workspace Name" />
              </div>
            </div> -->
            <div class="col-span-2">
              <label
                for=""
                class="block text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                Add proxy credentials
              </label>
              <div class="mt-1.5">
                <Input
                  v-model="data.name"
                  text="Proxy Name"
                  name="Proxy Name"
                  @change="onInputChange"
                  @keyup="onInputChange"
                />
              </div>
              <p class="mt-2 text-sm text-red-600">
                <ErrorMessage name="Proxy Name" />
              </p>
            </div>

            <div>
              <label for="" class="sr-only"> IP or Domain </label>
              <div class="mt-1.5">
                <Input
                  v-model="data.ip"
                  text="IP or Domain"
                  name="Ip"
                  @change="onInputChange"
                  @keyup="onInputChange"
                />
              </div>
              <p class="mt-2 text-sm text-red-600">
                <ErrorMessage name="Ip" />
              </p>
            </div>

            <div>
              <label for="" class="sr-only"> Port </label>
              <div class="mt-1.5">
                <Input
                  v-model="data.port"
                  text="Port"
                  name="Port"
                  @change="onInputChange"
                  @keyup="onInputChange"
                />
              </div>
              <p class="mt-2 text-sm text-red-600">
                <ErrorMessage name="Port" />
              </p>
            </div>

            <div>
              <label for="" class="sr-only"> Login </label>
              <div class="mt-1.5">
                <Input
                  v-model="data.username"
                  text="Username"
                  name="Username"
                  @change="onInputChange"
                  @keyup="onInputChange"
                />
              </div>
              <p class="mt-2 text-sm text-red-600">
                <ErrorMessage name="Username" />
              </p>
            </div>

            <div>
              <label for="" class="sr-only"> Password </label>
              <div class="mt-1.5">
                <Input
                  v-model="data.password"
                  text="Password"
                  type="password"
                  name="Password"
                  @change="onInputChange"
                  @keyup="onInputChange"
                />
              </div>
              <p class="mt-2 text-sm text-red-600">
                <ErrorMessage name="Password" />
              </p>
            </div>
          </div>
        </div>
      </vee-form>

      <div class="px-4 py-5 sm:px-6">
        <div class="relative flex items-center justify-between">
          <div v-show="tested" class="flex items-center">
            <SvgIcon name="location-ping" />
            <img
              class="ml-3 h-8 w-8 shrink-0 rounded-full object-cover"
              :src="locationForShow.url"
              alt=""
            />
            <span
              class="ml-4 text-base font-medium text-gray-600 dark:text-gray-400"
            >
              {{ locationForShow.address }}
            </span>
          </div>
          <p
            v-show="isError"
            class="mt-2 flex items-center text-sm text-red-600"
          >
            Proxy does not supported by TexAu
          </p>
          <div></div>

          <!-- LOADING SPINNER -->
          <div
            v-show="isTesting"
            class="absolute inset-y-0 left-1/2 -translate-x-1/2 transform py-2.5"
          >
            <Spinner />
          </div>

          <div>
            <Button
              v-show="!tested"
              class="dark:!hover:bg-blue-400 !border-blue-600 !text-blue-600 dark:!text-blue-400"
              color="tertiary"
              text="Test Proxy"
              type="submit"
              form="inputForm"
            />
            <Button
              v-show="tested"
              :show-loader="isSaving"
              text="Save Proxy"
              @click="saveProxy"
              leftIcon="plus"
              form="inputForm"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Button from '@/components/Button.vue'
import Select from '@/components/Select.vue'
import Input from '@/components/Input.vue'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { addNewProxy } from '@/apis/proxy'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { mapActions } from 'vuex'
import { testProxyProtocol } from '@/common/functions/proxy'

export default {
  name: 'AddSocialProxyModal',

  components: {
    Button,
    Select,
    Input,
    Spinner,
    SvgIcon
  },

  props: ['modalActive'],

  data() {
    return {
      tested: false,
      isTesting: false,
      locationForShow: {},
      isError: false,
      dataForSave: {},
      isSaving: false,
      schema: {
        Ip: 'required',
        Port: 'required',
        Username: 'required',
        Password: 'required',
        'Proxy Name': 'required',
        'Workspace Name': ''
      },
      data: {
        name: '',
        ip: '',
        port: '',
        username: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions('settings', ['updateNewProxyAddStatus']),

    close() {
      this.$emit('close')
    },

    async submitCreate() {
      this.isTesting = true
      const check = await testProxyProtocol(this.data)
      this.isTesting = false
      if (check.check) {
        this.tested = true
        this.locationForShow.url = findFlagUrlByIso2Code(
          check.response.countryCode
        )
        this.locationForShow.address = `${check.response.city}, ${check.response.regionName}, ${check.response.country}`
        // update data for save
        const credential = {
          ip: check.data.ip,
          port: check.data.port,
          username: check.data.username,
          password: check.data.password
        }
        const location = {
          city: check.response.city,
          region: check.response.regionName,
          country: check.response.country,
          countryCode: check.response.countryCode
        }
        this.dataForSave.name = check.data.name
        this.dataForSave.location = location
        this.dataForSave.type = 'static'
        this.dataForSave.credentials = credential
      } else {
        this.isError = true
      }
    },

    async saveProxy() {
      this.isSaving = true
      const response = await addNewProxy(this.dataForSave)
      this.isSaving = false
      if (response['success']) {
        await this.updateNewProxyAddStatus({ payload: true })
        this.$emit('success', 'Proxy added successfully.')
        this.$emit('close')
      } else {
        this.$emit('error', 'Error in saving proxy.')
        this.$emit('close')
      }
    },

    onInputChange() {
      this.isError = false
      this.tested = false
      this.isTesting = false
    }
  }
}
</script>

<style></style>
