<template>
  <nav class="flex justify-center space-x-4 antialiased" aria-label="Tabs">
    <TabSingleMenuOption
      optionName="Plan"
      @click="changeTab('plan')"
      :isActive="isPlanActive"
    />
    <TabSingleMenuOption
      optionName="Payment Methods"
      @click="changeTab('payments')"
      :isActive="isPaymentsActive"
    />
    <TabSingleMenuOption
      optionName="Invoices"
      @click="changeTab('invoices')"
      :isActive="isInvoicesActive"
    />
    <TabSingleMenuOption
      v-if="status === 'active'"
      optionName="Cancel Subscription"
      @click="changeTab('cancelSubscription')"
      :isActive="isCancelSubscriptionActive"
    />
  </nav>
</template>

<script>
import TabSingleMenuOption from '@/components/settings/tabSingleMenuOption.vue'

export default {
  name: 'billingHeader',
  props: {
    activeOption: { type: String, required: true, default: 'plan' },
    status: { type: String, required: true }
  },
  components: {
    TabSingleMenuOption
  },

  computed: {
    isPlanActive() {
      return this.activeOption === 'plan' || this.activeOption === 'Plan'
    },
    isPaymentsActive() {
      return (
        this.activeOption === 'payments' || this.activeOption === 'Payments'
      )
    },
    isInvoicesActive() {
      return (
        this.activeOption === 'invoices' || this.activeOption === 'Invoices'
      )
    },
    isCancelSubscriptionActive() {
      return (
        this.activeOption === 'cancelSubscription' ||
        this.activeOption === 'CancelSubscription'
      )
    }
  },
  methods: {
    changeTab(tab) {
      this.$emit('changeTab', tab)
    }
  }
}
</script>

<style scoped></style>
