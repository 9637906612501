<template>
  <button
    type="button"
    class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
  >
    <SvgIcon name="edit" class="h-5 w-5" />
  </button>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'

export default {
  name: 'EditButton',
  components: {
    SvgIcon
  }
}
</script>
