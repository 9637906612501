<template>
  <div :class="[{'flex items-center mt-2': tooltip}]">
    <p class="text-sm font-semibold text-gray-900 dark:text-gray-50">
      {{ text }}
    </p>

    <tooltip v-if="tooltip"/>
  </div>
</template>

<script>
import tooltip from "@/assets/icons/tooltip.vue";

export default {
  name: "Description",
  components: {tooltip},
  props: {
    text: {type: String, default: ""},
    tooltip: {type: Boolean, default: false},
  }
}
</script>

<style scoped>

</style>
