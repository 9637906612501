<template>
  <section class="flex flex-1 border-t border-gray-200 antialiased dark:border-gray-700 text-gray-900 bg-white dark:bg-gray-900 dark:text-gray-50">
    <div class="flex items-center justify-center flex-1 max-w-2xl px-8 py-6 mx-auto">
      <Collect />
    </div>
  </section>
</template>

<script>
import Button from "@/components/Button.vue";
import Collect from "@/components/automationStore/collect.vue";

export default {
  name: "automationProcessed",
  components: {
    Button,
    Collect
  }
}
</script>

<style scoped>

</style>
