<template>
  <ul>
    <li
      v-for="option in options"
      :key="option.name"
      class="flex h-9 w-[98px] cursor-pointer items-center border-r border-l border-gray-200 bg-white first:rounded-t-[5px] first:border-t last:rounded-b-[5px] last:border-b"
      :class="{
        'bg-red-50 text-red-500': option.name === 'Delete'
      }"
      @click.stop="$emit('option-clicked', option)"
    >
      <SvgIcon :name="option.icon" class="ml-3 mr-3 h-4 w-4 cursor-pointer" />
      <span class="text-xs font-semibold">
        {{ option.name }}
      </span>
    </li>
  </ul>
</template>

<script setup>
import { ref } from 'vue'
import SvgIcon from '../SvgIcon.vue'

defineEmits(['option-clicked'])

const options = ref([
  {
    name: 'Delete',
    icon: 'delete'
  }
])
</script>
