<template>
  <!-- START WRAPPER -->
  <div class="grid min-h-screen px-4 py-12 bg-gray-600 sm:px-6 lg:px-8 place-items-center antialiased dark:bg-gray-700">

    <div class="relative w-full max-w-xl mx-auto overflow-hidden bg-white dark:bg-gray-900 rounded-xl">
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Connect New Account
          </p>

          <div class="flex items-center justify-end space-x-4">
            <button type="button"
                    class="p-1.5 text-gray-600 transition-all duration-200 bg-white border border-gray-200 rounded-lg dark:text-gray-400 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:bg-gray-900 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900">
              <span class="sr-only">
                Close
              </span>
              <SvgIcon name="closeButton" />
            </button>
          </div>
        </div>
      </div>

      <div class="px-4 pb-5 sm:px-6 sm:pb-6">
        <div class="flex items-center justify-center max-w-sm mx-auto">
          <img
              class="object-cover w-16 h-16 mx-auto rounded-full shrink-0 ring-blue-600 ring-1 ring-offset-2 dark:ring-blue-500 dark:ring-offset-gray-900"
              src="@/assets/images/symbols/texau.svg" alt="">

          <div class="flex items-center justify-center">
            <SvgIcon name="rightArrowFilled" class="w-5 h-5 text-blue-600 opacity-20 dark:text-blue-500" />
            <SvgIcon name="rightArrowFilled" class="w-5 h-5 text-blue-600 opacity-40 dark:text-blue-500" />
            <SvgIcon name="rightArrowFilled" class="w-5 h-5 text-blue-600 opacity-60 dark:text-blue-500" />
            <SvgIcon name="rightArrowFilled" class="w-5 h-5 text-blue-600 opacity-80 dark:text-blue-500" />
            <SvgIcon name="rightArrowFilled" class="w-5 h-5 text-blue-600 dark:text-blue-500" />
          </div>

          <img
              class="object-cover w-16 h-16 mx-auto rounded-full shrink-0 ring-google-green dark:ring-offset-gray-900 ring-1 ring-offset-2"
              src="@/assets/images/symbols/google-sheet.svg" alt="">
        </div>

        <div class="mt-8 rounded-lg bg-blue-50 dark:bg-blue-900/50">
          <div class="p-4">
            <div class="flex items-start">
              <SvgIcon name="info" class="w-5 h-5 text-blue-500 shrink-0 dark:text-blue-300"/>
              <p class="flex-1 ml-3 text-sm font-medium text-gray-900 dark:text-gray-50">
                Allow on-behalf client account authorization by sharing the link below:
              </p>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <label for="" class="sr-only">
            Copy link
          </label>
          <div class="relative">
            <input type="text" name="" id=""
                   class="block w-full pl-3 pr-12 py-2.5 transition-all duration-200 hover:border-gray-400 border-gray-300 font-medium rounded-lg sm:text-sm focus:ring-blue-600  dark:hover:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400 focus:border-blue-600 text-blue-600 dark:text-gray-50 caret-blue-600 placeholder-gray-500 border"
                   placeholder="" value="https://app.texau.com/integrations/sheets/accounts/187866-386896-a78986693g"
                   readonly>

            <div class="absolute inset-y-0 right-0 flex items-center pr-3">
              <button type="button" class="text-blue-600 dark:text-blue-500">
                <SvgIcon name="copy" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- END WRAPPER -->
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";

export default {
  name: "delegationLink",
  components: {
    SvgIcon,
  }
}
</script>
