<template>
  <main class="flex min-w-0 flex-1 flex-col overflow-y-auto">
    <AutomationHeader :nav="false" :title="title" :iconUrl="logo" />
    <div v-if="output === null" class="flex h-full items-center justify-center">
      <Spinner size="large" />
    </div>
    <section class="border-t border-gray-200 dark:border-gray-700">
      <div class="px-4 pb-5 pt-7">
        <AutomationOutputTable
          v-if="output"
          test="test"
          :columnsData="columns"
          :outputData="output"
          :nodeId="this.$route.query.nodeId"
          :title="this.title"
          :total="automationTotal"
          :filtersOptions="filtersOptions"
          :executionToggleOptions="getOptions()"
          :executionDropdownDisable="getExecutionDropdownDisable()"
          :selectedExecutionNode="selectedExecutionNode"
          :showLoadingText="showLoadingText"
          @nodeExecutionChange="nodeExecutionChange"
          @loadNextBatch="loadNextBatch"
          @refetch="fetchOutput(0, $event)"
          @error="emitError"
          @success="emitSuccess"
        />
      </div>
    </section>
  </main>
</template>

<script>
import AutomationOutputTable from '@/components/AutomationOutputTable.vue'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import { formatDate, formatTime } from '@/common/functions/formatDateAndTime'
import { getWorkflowExecution } from '@/apis/workflows'
import { fetchOutputForTable } from '@/common/functions/fetchOutputForTable'
import Spinner from '@/components/Spinner.vue'
export default {
  name: 'Results',
  components: {
    AutomationHeader,
    AutomationOutputTable,
    Spinner
  },
  async mounted() {
    const response = await getWorkflowExecution(this.$route.query.workflowId)
    this.executionidArr = response.data
    if (this.$route.query.executionId) {
      if (this.$route.query.outputMode === 'append') {
        this.selectedExecutionNode = 'all'
      } else {
        this.selectedExecutionNode = this.executionidArr.filter(
          node => node._id === this.$route.query.executionId
        )[0]
      }
    } else {
      this.selectedExecutionNode = this.executionidArr[0]
    }
    await this.fetchOutput()
    if (this.$route.query.source !== 'input') {
      this.fetchPollingData()
    }
  },
  data() {
    return {
      logo: null,
      title: null,
      columns: null,
      output: null,
      filtersOptions: null,
      automationTotal: null,
      executionidArr: null,
      selectedExecutionNode: null,
      showLoadingText: false
    }
  },
  methods: {
    async fetchPollingData() {
      try {
        let executionObj
        //get the status of selectedExecutionNode execution
        const response = await getWorkflowExecution(
          this.$route.query.workflowId
        )

        if (this.selectedExecutionNode === 'all') {
          executionObj = response.data.find(item => item.status === 'running')
        } else {
          executionObj = response.data.find(
            item => item._id === this.selectedExecutionNode._id
          )
        }
        if (executionObj?.status && executionObj.status === 'running') {
          this.showLoadingText = true
          let all = this.selectedExecutionNode === 'all' ? 1 : 0
          const { output } = await fetchOutputForTable(
            undefined,
            undefined,
            all
          )
          this.showLoadingText = false

          //replace the old results
          this.output = output

          //call the function again after 5 sec
          setTimeout(await this.fetchPollingData(), 5000)
        } else if (executionObj?.status === 'failed') {
          emit('error', executionObj.statusReason)
        } else {
          return
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchOutput(start, manipulationConfig) {
      try {
        let all = this.selectedExecutionNode === 'all' ? 1 : 0

        const { logo, title, columns, output, total, filtersOptions } =
          await fetchOutputForTable(start, manipulationConfig, all)
        this.logo = logo
        this.title = title
        this.columns = columns
        this.automationTotal = total
        this.filtersOptions = filtersOptions
        output.length === 0 && this.$emit('error', 'No Result Found')
        this.output = output
      } catch (error) {
        console.log(error)
        if (error.message === 'canceled') return
        this.emitError(error)
      }
    },
    async fetchOutputForNode(selectedExecutionNode, start) {
      //replace query with new data
      this.showLoadingText = false
      this.output = null
      let data = {
        nodeId: this.$route.query.nodeId,
        executionId:
          this.selectedExecutionNode === 'all'
            ? this.$route.query.executionId
            : selectedExecutionNode._id,
        workflowId: this.$route.query.workflowId,
        operationId: this.$route.query.operationId,
        platformId: this.$route.query.platformId,
        source: this.$route.query.source,
        outputMode: this.$route.query.outputMode
      }
      await this.$router.push({
        query: data,
        replace: true
      })
      if (this.$route.query.source !== 'input') {
        await this.fetchOutput(start)
        this.fetchPollingData()
      } else {
        await this.fetchOutput(start)
      }
    },
    nodeExecutionChange(data) {
      this.selectedExecutionNode = data
      this.fetchOutputForNode(this.selectedExecutionNode)
    },
    emitError(data) {
      this.$emit('error', data)
    },
    emitSuccess(data) {
      this.$emit('success', data)
    },
    async loadNextBatch(data) {
      this.fetchOutput(data)
      let div = document.getElementsByClassName('p-datatable-wrapper')[0]
      div.scrollTop = 0
    },
    getOptions() {
      let arr
      arr = this.executionidArr.map((node, index) => ({
        label: ` Result ${this.executionidArr.length - index}: ${formatDate(
          node.createdAt
        )} ${formatTime(node.createdAt)}`,
        value: node
      }))
      if (this.$route.query.outputMode === 'append') {
        arr.unshift({
          label: 'All',
          value: 'all'
        })
      }

      return arr
    },
    getExecutionDropdownDisable() {
      if (this.$route.query.outputMode === 'overwrite') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style></style>
