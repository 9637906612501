<template>
  <div class="relative">
    <div class="absolute inset-0 flex items-center pt-14" aria-hidden="true">
      <div class="w-full border-t-[3px] border-dashed border-blue-600"></div>
    </div>
    <div class="relative flex justify-center pt-14">
      <span class="rounded-full bg-blue-600 p-2 text-white">
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <polyline points="9 6 15 12 9 18"></polyline>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputOutputCardConnector'
}
</script>

<style></style>
