export const SET_VARIABLES = (state, payload) => {
  state.variablesHashMap.set(payload.platformOperationId, payload.variables)
}

export const SET_DYNAMIC_OUTPUTS = (state, payload) => {
  state.dynamicOutputs.set(payload.nodeId, payload.dynamicOutputs)
}

export const DELETE_VARIABLES = (state, payload) => {
  state.variablesHashMap.delete(payload.platformOperationId)
}

export const RESET_VARIABLES = state => {
  state.variablesHashMap = new Map()
  state.dynamicOutputs = new Map()
  state.isRouterAdded = false
  state.webhook = null
}

export const SET_ROUTER_ADDED = (state, toggle) => {
  state.isRouterAdded = toggle
}
export const SET_WEBHOOK_INPUTS = (state, payload) => {
  state.webhook = payload
}
