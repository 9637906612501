<!-- 
  Collapse
  ========
  A collapsible component for Vue.js.
  ========
  Props:
  - defaultExpanded: Boolean, whether the panel is active or not.
  - heading: String, the heading of the panel.
  - subheading: String, the subheading of the panel. Optional.
  - body: String, the body of the panel.
 -->

<template>
  <div
    role="region"
    class="relative overflow-hidden border-b border-gray-200 dark:border-gray-700"
  >
    <h3>
      <button
        @click="onClick"
        :aria-expanded="expanded"
        class="flex w-full items-center justify-between py-5 text-left"
      >
        <div>
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            {{ heading }}
          </p>
          <p class="mt-1 text-base font-semibold text-gray-500">
            {{ subheading }}
          </p>
        </div>

        <span aria-hidden="true" class="ml-4">
          <SvgIcon
            :name="expanded ? 'up-arrow' : 'down-arrow'"
            class="h-6 w-6 text-gray-600 dark:text-gray-400"
          />
        </span>
      </button>
    </h3>

    <div
      :style="{
        transitionProperty: 'height',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        transitionDuration: '0.25s'
      }"
      ref="expandableBody"
    >
      <div class="pb-5">
        <p class="text-base leading-7 text-gray-900 dark:text-gray-50">
          {{ body }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from './SvgIcon.vue'
export default {
  name: 'Collapse',
  props: {
    defaultExpanded: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      required: true
    },
    subheading: {
      type: String,
      default: null
    },
    body: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      expanded: this.defaultExpanded
    }
  },
  mounted() {
    this.setBodyHeight()
  },
  methods: {
    onClick() {
      this.expanded = !this.expanded
      this.setBodyHeight()
    },
    setBodyHeight() {
      this.$refs.expandableBody.style.height = this.expanded
        ? `${this.$refs.expandableBody.scrollHeight}px`
        : '0px'
    }
  },
  components: { SvgIcon }
}
</script>
