<template>
  <div class="m-auto w-[calc(100%_-_2px)]">
    <!-- Label for the select component -->
    <label
      v-if="isLabel"
      for=""
      class="flex text-sm font-medium text-gray-900 dark:text-gray-50"
      :class="labelClass"
    >
      {{ label }} {{ isRequired === false ? '(Optional)' : null }}
      <SvgIcon
        class="mb-3 ml-1 h-2 w-2 text-red-600"
        name="star"
        v-if="isRequired"
      />
    </label>
    <Popper
      :show="showPopper"
      placement="bottom-start"
      :interactive="true"
      @close:popper="
        () => {
          ;(searchText = ''), (focusedIndex = null), (showPopper = null)
        }
      "
      class="w-full"
      :class="[
        {
          'mt-1.5': isLabel && labelClass !== 'sr-only'
        }
      ]"
      @keyup.down="focusedNext"
      @keyup.up="focusedPrevious"
      @keyup.enter="
        () => {
          updateValue(options[focusedIndex].value)
          showPopper = false
        }
      "
      :style="{ margin: 0, border: 0, marginTop: '0.375rem' }"
      @open:popper="fetchOptions"
    >
      <div
        tabindex="0"
        class="flex w-full cursor-pointer select-none items-center rounded-lg border px-3 py-2.5 text-gray-900 placeholder-gray-500 transition-all duration-200 dark:placeholder-gray-400 sm:text-sm"
        :class="[
          {
            'border-red-600  text-red-600 caret-red-600 hover:border-red-700 focus:border-red-600 focus:ring-red-600 dark:border-red-500 dark:bg-gray-900 dark:placeholder-gray-400 dark:focus:border-red-500 dark:focus:ring-red-500':
              displayError,
            'border-gray-300 caret-blue-600 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500':
              primary
          }
        ]"
        ref="selectInputContainer"
      >
        <div v-if="leftIcon" class="mr-2 flex gap-2">
          <SvgIcon
            v-if="leftIcon"
            class="h-5 w-5 text-gray-400"
            :name="leftIcon"
          />
        </div>

        <p
          :class="{
            'text-gray-400 ': !displayLabel
          }"
          class="text-sm"
        >
          {{ displayLabel || text }}
        </p>
        <SvgIcon class="ml-auto h-5 w-5 text-gray-400" name="down-arrow" />
      </div>
      <template #content="{ close }">
        <div
          class="dropdown-border block max-h-64 min-w-max overflow-auto bg-white"
          :style="{ width: `${dropdownWidth}px` }"
        >
          <div
            v-if="dropdownConfig.searchInput"
            class="sticky top-0 bg-white p-1 dark:bg-gray-900"
          >
            <input
              :placeholder="`Search${isLabel ? ' ' + label : ''}`"
              v-model="searchText"
              class="block w-full rounded-lg border border-gray-300 px-3 py-2.5 pl-8 pr-3 text-sm placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              v-debounce:300ms="keyValidate"
              :debounce-events="'keydown'"
            />
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400"
            >
              <SvgIcon name="search" />
            </div>
          </div>
          <div
            v-if="loading"
            class="flex items-center px-4 py-3 text-sm text-gray-400"
          >
            <div class="flex items-center">
              <Spinner class="mr-2" />
              Loading options...
            </div>
          </div>
          <div
            v-else-if="options && options.length > 0"
            v-for="(option, index) of options"
            :key="option.value"
            :id="'dop' + index"
            :class="[{ 'bg-gray-100': index === focusedIndex }]"
            class="flex cursor-pointer items-center px-4 py-3 text-sm hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-50 dark:hover:bg-gray-700"
            @click="
              () => {
                close()
                updateValue(option.value)
              }
            "
          >
            <p>
              {{ option.label }}
            </p>
          </div>
          <div v-else class="flex items-center px-4 py-3 text-sm text-gray-400">
            <p>No Options</p>
          </div>
        </div>
      </template>
    </Popper>
    <p v-if="description" class="mt-2 text-sm text-gray-500">
      {{ description }}<slot></slot>
    </p>
    <!-- CAPTION TEXT -->
    <p
      v-if="captionText"
      class="mt-2 text-sm"
      :class="[
        { 'text-red-600': displayError },
        { 'text-gray-500': primary },
        { 'text-gray-500 opacity-50': disabled }
      ]"
    >
      {{ captionText }}
    </p>
  </div>
</template>

<script>
import Popper from 'vue3-popper'
import Input from './Input.vue'
import Spinner from './Spinner.vue'
import SvgIcon from './SvgIcon.vue'
import { runIntegration } from '@/apis/integrations'
import { getValueFromPath } from '@/common/functions/objectHelper'
import { mapActions } from 'vuex'
export default {
  name: 'DynamicSelect',
  data() {
    return {
      primary: true,
      showDropdown: false,
      value: this.modelValue,
      dropdownWidth: this.$refs.selectInputContainer?.clientWidth,
      options: [],
      loading: false,
      searchText: '',
      controller: new AbortController(),
      focusedIndex: null,
      showPopper: null
    }
  },
  emits: ['update:modelValue', 'change', 'error', 'blur'],
  props: {
    captionText: String,
    error: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    leftIcon: { type: String, default: '' },
    text: { type: String, default: 'Select' },
    label: { type: String, default: '' },
    name: { type: String },
    leftImageUrl: { type: String, default: null },
    modelValue: {},
    isRequired: { type: Boolean, default: undefined },
    labelClass: String,
    allInputs: { type: Object, required: true },
    choices: { type: Array, default: [] },
    dropdownConfig: { type: Object },
    description: String
  },
  mounted() {
    this.setDropdownWidth()
    if (this.choices && this.choices.length > 0) {
      this.options = this.options.concat(this.choices)
    } else if (this.modelValue) {
      this.fetchOptions()
    }
  },
  created() {
    window.addEventListener('resize', this.setDropdownWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.setDropdownWidth)
  },
  computed: {
    displayError() {
      if (this.error === true) {
        this.primary = false
        return true
      }
    },
    isLabel() {
      return this.label !== ''
    },
    displayLabel() {
      return (
        this.options &&
        this.options.find(option => option.value === this.value)?.label
      )
    }
  },
  methods: {
    ...mapActions('automationStore', ['addAutomationData']),
    updateValue(value) {
      this.value = value
      this.$emit('update:modelValue', value)
      this.$emit('change', value)
      this.$emit('blur', value)
    },
    setDropdownWidth() {
      this.dropdownWidth = this.$refs.selectInputContainer?.clientWidth
    },
    abortOldRequest() {
      this.controller.abort('request cancelled')
      this.controller = new AbortController()
    },
    async fetchOptions(searchText = '') {
      try {
        this.abortOldRequest()
        this.focusedIndex = 0
        this.loading = true
        // fetch and save the options
        const response = await runIntegration(
          {
            ...this.allInputs,
            [this.dropdownConfig.searchInput]: searchText.trim()
          },
          this.dropdownConfig.platformId,
          this.dropdownConfig.operationId,
          this.controller.signal
        )
        if (response['success']) {
          const rawOptions = [].concat(response.data)
          const options = rawOptions.map(row => ({
            label: getValueFromPath(row, this.dropdownConfig.labelPath),
            value: getValueFromPath(row, this.dropdownConfig.valuePath)
          }))
          this.options = options
        } else {
          throw response
        }
      } catch (error) {
        this.$emit('error', error.message)
      }
      this.loading = false
    },
    //validate keys so that search function does not run on shift or ctrl press
    keyValidate(q, e) {
      if (
        e.key === 'ArrowUp' ||
        e.key === 'ArrowDown' ||
        e.key === 'Meta' ||
        e.key === 'Enter' ||
        e.key === 'Shift' ||
        e.key === 'Control'
      ) {
        return
      } else {
        this.fetchOptions(q)
      }
    },
    focusedNext() {
      if (this.focusedIndex === null) {
        this.focusedIndex = 0
        let id = 'dop' + this.focusedIndex
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })
        return
      } else if (this.focusedIndex === this.options.length - 1) {
        return
      } else {
        this.focusedIndex += 1
        let id = 'dop' + this.focusedIndex
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })
      }
    },
    focusedPrevious() {
      if (this.focusedIndex === null || this.focusedIndex === 0) return
      this.focusedIndex -= 1
      let id = 'dop' + this.focusedIndex
      document
        .getElementById(id)
        .scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
    }
  },
  components: { SvgIcon, Popper, Input, Spinner },
  watch: {
    modelValue() {
      this.value = this.modelValue
    },
    choices(n) {
      if (Array.isArray(n)) this.options = this.options.concat(n)
    },
    options(n) {
      this.addAutomationData({
        payload: {
          [this.name]: n
        }
      })
    }
  }
}
</script>

<style scoped>
.dropdown-border {
  border: 0.5px solid #bec0c5;
  /* Drop Shadow - 5% */

  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
}
</style>
