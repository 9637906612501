<template>
  <div>
    <div v-if="label" class="flex justify-between">
      <label v-show="!isEditingLabel" for="" :class="labelClass" class="flex">
        {{ label }} {{ isRequired === false ? '(Optional)' : null }}

        <SvgIcon
          class="mb-3 ml-1 h-2 w-2 text-red-600"
          name="star"
          v-if="isRequired"
        />
        <SvgIcon
          name="edit"
          class="ml-1 h-3 w-3 cursor-pointer text-gray-400"
          @click="onEditLabel"
          v-if="isLabelEditable"
        />
      </label>
      <input
        :value="label"
        v-if="isEditingLabel"
        :class="labelClass"
        class="rounded border border-dashed border-gray-300 pl-1 outline-none"
        @blur="onLabelBlur"
        ref="labelInput"
      />
      <Checkboxes
        v-if="showDropIcon"
        label="Allow Multiple Inputs"
        v-model="multipleHeaderCheck"
      />
    </div>

    <Popper
      placement="bottom-start"
      :interactive="true"
      class="w-full"
      :style="{ margin: 0, border: 0, marginTop: '0.375rem' }"
      @open:popper="showVariables = true"
      @close:popper="showVariables = false"
    >
      <div>
        <div class="relative">
          <editor-content
            :editor="editor"
            @click="handleEditorClick"
            @dblclick="handleEditorClick"
          />
          <div
            v-if="showDropIcon"
            class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 dark:text-gray-50"
          >
            <SvgIcon
              class="h-5 w-5 cursor-pointer transition-transform"
              :class="{
                'rotate-180': showVariables
              }"
              name="down-arrow"
            />
          </div>
        </div>
      </div>
      <template #content v-if="showDropIcon" class="mx-1">
        <VariableModal
          :previousNodes="previousNodes"
          @variable-selected="onVariableSelection"
          @function-selected="onFunctionSelection"
          :class="modalClass"
          :name="name"
        />
      </template>
    </Popper>
    <p v-if="description" class="mt-2 text-sm text-gray-500">
      {{ description }}<slot></slot>
    </p>
  </div>
</template>

<script setup>
import { AUTOMATION_VARIABLE_TYPES } from '@/common/constants'
import Input from '@/components/Input.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Placeholder from '@tiptap/extension-placeholder'
import StarterKit from '@tiptap/starter-kit'
import { EditorContent, useEditor } from '@tiptap/vue-3'
import { computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue'
import Popper from 'vue3-popper'
import { convertInputStringToHtml } from '../helper'
import { FunctionEnd, FunctionParamSeparator, FunctionStart } from './Function'
import OneLiner from './Oneliner'
import { Variable } from './Variable'
import VariableModal from './variableModal.vue'
import { useRoute } from 'vue-router'
import Checkboxes from '@/components/Checkboxes.vue'
const props = defineProps({
  modelValue: {},
  label: String,
  labelClass: String,
  type: String,
  placeholder: { type: String, default: 'write something...' },
  readonly: Boolean,
  disabled: Boolean,
  previousNodes: Array,
  isRequired: { type: Boolean, default: undefined },
  modalClass: String,
  isLabelEditable: Boolean,
  description: String,
  name: String,
  allowMapping: { type: Boolean, default: undefined },
  defaultMultipleInputs: { type: Boolean, default: false }
})

const emit = defineEmits(['update:modelValue', 'label-update', 'blur'])
const route = useRoute()
const showVariables = ref(false)
const isEditingLabel = ref(false)
const labelInput = ref(null)
const isSingleLine = computed(() => {
  return props.type !== AUTOMATION_VARIABLE_TYPES.MESSAGE
})
const multipleHeaderCheck = ref(false)

const editor = useEditor({
  content: convertInputStringToHtml(props.modelValue),
  extensions: [
    ...(isSingleLine.value ? [OneLiner] : []),
    StarterKit.configure({
      bold: false,
      italic: false,
      paragraph: {
        HTMLAttributes: {
          class: isSingleLine.value ? 'single-line' : 'multi-line'
        }
      }
    }),
    FunctionStart,
    FunctionParamSeparator,
    FunctionEnd,
    Variable,
    Placeholder.configure({ placeholder: props.placeholder })
  ],
  editorProps: {
    attributes: {
      class:
        'block w-full rounded-lg border px-3 py-2.5 pr-8 placeholder-gray-500 transition-all duration-200 dark:placeholder-gray-400 sm:text-sm border-gray-300 caret-blue-600 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500' +
        (!isSingleLine.value ? ' min-h-[100px]' : '')
    }
  },
  onUpdate() {
    const text = editor.value.getText()
    // update vmodel whenever content is updated.
    emit('update:modelValue', convertToProperType(text))
  },
  onBlur() {
    const text = editor.value.getText()
    emit('blur', convertToProperType(text))
  },
  editable: !props.disabled && !props.readonly
})

onMounted(() => {
  if (props.isLabelEditable && props.label === 'New Field') {
    onEditLabel()
  }
  multipleHeaderCheck.value = props.defaultMultipleInputs
})

// destroy the editor on unmount
onBeforeUnmount(() => {
  editor.value.destroy()
})

const handleEditorClick = e => showVariables.value && e.stopPropagation()

const onVariableSelection = (nodeId, output) => {
  if (multipleHeaderCheck.value === false) {
    editor.value.commands.clearContent()
  }

  editor.value.commands.insertContent({
    type: 'variable',
    attrs: {
      nodeId,
      outputName: output.name,
      outputLabel: output.label
    }
  })
  editor.value.commands.focus()
}

const onFunctionSelection = (category, rule) => {
  editor.value.commands.insertContent({
    type: 'function-start',
    attrs: {
      category,
      ...rule
    }
  })
  const pos = editor.value.state.selection.$anchor.pos
  for (let i = 0; i < rule.numberOfParameters - 1; i++) {
    editor.value.commands.insertContent({
      type: 'function-param-separator',
      attrs: {
        category,
        ...rule
      }
    })
  }
  editor.value.commands.insertContent({
    type: 'function-end',
    attrs: {
      category,
      ...rule
    }
  })
  if (rule.numberOfParameters > 0) {
    editor.value.commands.focus(pos)
  } else {
    editor.value.commands.focus()
  }
}

const showDropIcon = computed(() => {
  if (props.allowMapping === false) {
    return false
  }
  let str = location.pathname.split('/')
  if (
    str[1] === 'workflow-builder' ||
    route.query.source === 'sheet' ||
    route.query.source === 'csv' ||
    str[1] === 'ai-prompt-builder'
  ) {
    return true
  } else {
    return false
  }
})

const onEditLabel = () => {
  isEditingLabel.value = true
  nextTick(() => {
    labelInput.value.focus()
    labelInput.value.select()
  })
}

const onLabelBlur = e => {
  isEditingLabel.value = false
  emit('label-update', e.target.value)
}
const convertToProperType = value => {
  if (props.type === AUTOMATION_VARIABLE_TYPES.NUMBER && /^\d+$/.test(value)) {
    return Number(value)
  }
  return value
}
</script>

<style>
/* Styles for Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.single-line {
  white-space: nowrap;
  overflow: hidden;
}
</style>
