<template>
  <div>
    <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
      Vacation Time (Exclude Dates)
    </p>

    <div class="grid items-end grid-cols-5 mt-6 gap-x-6">
      <div class="col-span-2">
        <label for="" class="block text-sm font-medium text-gray-900 dark:text-gray-50">
          From
        </label>
        <div class="mt-1.5 relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3">
            <SvgIcon name="calendarSmall"/>
          </div>

          <select name="" id=""
                  class="block w-full pr-10 pl-10 py-2.5 transition-all duration-200 hover:border-gray-400 text-gray-900 dark:text-gray-50 border-gray-300 rounded-lg sm:text-sm dark:hover:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400 focus:ring-blue-600 focus:border-blue-600  placeholder-gray-500 border">
            <option value="">
              DD / MM / YY
            </option>
          </select>
        </div>
      </div>

      <div class="col-span-2">
        <label for="" class="block text-sm font-medium text-gray-900 dark:text-gray-50">
          to
        </label>
        <div class="mt-1.5 relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3">
            <SvgIcon name="calendarSmall"/>
          </div>

          <select name="" id=""
                  class="block w-full pr-10 pl-10 py-2.5 transition-all duration-200 hover:border-gray-400 text-gray-900 dark:text-gray-50 border-gray-300 rounded-lg sm:text-sm dark:hover:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400 focus:ring-blue-600 focus:border-blue-600  placeholder-gray-500 border">
            <option value="">
              DD / MM / YY
            </option>
          </select>
        </div>
      </div>

      <div>
        <Button text="Add" color="success" class="inline-flex items-center justify-center px-4 py-2.5 sm:text-sm text-base font-semibold text-green-500 transition-all duration-200 bg-transparent border border-green-500 rounded-lg hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-gray-900 focus:ring-green-500 dark:border-green-400 dark:text-green-400 dark:focus:ring-green-400 dark:hover:bg-green-400 dark:hover:text-green-50 w-full"/>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import SvgIcon from "@/components/SvgIcon.vue";

export default {
  name: "vacationTime",
  components:{
    Button,
    SvgIcon,
  }
}
</script>

<style scoped>

</style>
