import axios from '@/common/axios'

const getPlatformDetails = async platformId => {
  try {
    const response = await axios.get(`/platforms/${platformId}`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const getAllConnectedAccounts = async (start = 0, limit = 20, onlySocial) => {
  try {
    const response = await axios.get(`/accounts`, {
      params: { start, limit, onlySocial }
    })
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const getConnectedAccountsUi = async (start = 0, limit = 20) => {
  try {
    const response = await axios.get(`/accounts/ui`, {
      params: { start, limit }
    })
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const deleteConnectedAccount = async accountId => {
  try {
    const response = await axios.delete(`/accounts/${accountId}`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const deleteConnectedAccountForce = async accountId => {
  try {
    const response = await axios.delete(`/accounts/${accountId}/force`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const updateVariable = async (accountId, data) => {
  try {
    const response = await axios.put(`/accounts/${accountId}/variables`, data)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const deleteVariable = async (accountId, data) => {
  try {
    const response = await axios.delete(`/accounts/${accountId}/variables`, {
      data: data
    })

    return response.data
  } catch (e) {
    return e.response.data
  }
}

const validateCredentials = async (credentials, platformId) => {
  try {
    const response = await axios.post(`/accounts/validate`, {
      credentials,
      platformId
    })
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const getAccountVariables = async accountId => {
  try {
    const response = await axios.get(`/accounts/${accountId}/variables`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

export {
  getAllConnectedAccounts,
  getConnectedAccountsUi,
  getPlatformDetails,
  deleteConnectedAccount,
  deleteConnectedAccountForce,
  updateVariable,
  deleteVariable,
  validateCredentials,
  getAccountVariables
}
