<template>
  <div :class="mainClass">
    <img
      class="mx-auto h-32 w-auto"
      src="@/assets/images/onboarding/illustrations/waiter-dish.png"
      alt=""
    />

    <p class="mt-8 text-2xl font-bold text-gray-900 dark:text-gray-50">
      Your Data is Served!
    </p>

    <div class="mt-8 grid grid-cols-3 gap-6">
      <div
        class="transform rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:-translate-y-1 hover:border-blue-600 hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:hover:border-blue-500"
      >
        <div class="px-4 py-5 sm:p-6">
          <img
            class="mx-auto h-16 w-auto"
            src="@/assets/images/icons/csv-file.svg"
            alt=""
          />
          <div class="mt-6">
            <Button
              @click="downloadCsv()"
              text="Download CSV"
              :leftIcon="csvLoader ? null : 'download'"
              :show-loader="csvLoader"
            />
          </div>
        </div>
      </div>

      <div
        class="transform rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:-translate-y-1 hover:border-blue-600 hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:hover:border-blue-500"
      >
        <div class="px-4 py-5 sm:p-6">
          <img
            class="mx-auto h-16 w-auto"
            src="@/assets/images/icons/sheet-file.svg"
            alt=""
          />
          <div class="mt-6">
            <Button
              text="Export To Google Sheets"
              @click="$emit('openModal')"
              leftIcon="upper-right-arrow"
            />
          </div>
        </div>
      </div>

      <div
        class="transform rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:-translate-y-1 hover:border-blue-600 hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:hover:border-blue-500"
      >
        <div class="px-4 py-5 sm:p-6">
          <img
            class="mx-auto h-16 w-auto"
            src="@/assets/images/icons/sheet-file.svg"
            alt=""
          />
          <div class="mt-6">
            <Button
              @click="previewButtonClick()"
              text="Preview Data"
              color="successFilled"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import { fetchOutputForTable } from '@/common/functions/fetchOutputForTable'
import { mapState } from 'vuex'
import { exportName } from '@/common/functions/csvFileNameGeneration'
import { downloadCsv } from '@/apis/automation-store/csv'

export default {
  data() {
    return {
      csvLoader: false
    }
  },
  computed: {
    ...mapState('automationStore', ['automationStoreData'])
  },
  methods: {
    previewButtonClick() {
      this.$emit('previewButtonClick')
    },
    async downloadCsv() {
      this.csvLoader = true
      try {
        const response = await downloadCsv(
          this.$route.query.executionId,
          this.$route.query.nodeId
        )
        if (response['success']) {
          let downloadLink = response.data.csvUrl

          const filename = 'my-file.csv'

          const anchor = document.createElement('a')
          anchor.href = downloadLink
          anchor.download = filename
          document.body.appendChild(anchor)
          anchor.click()
          document.body.removeChild(anchor)
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }

      this.csvLoader = false
    }
  },
  name: 'collect',
  components: {
    Button
  },
  props: {
    mainClass: { type: String, default: 'text-center' }
  }
}
</script>

<style scoped></style>
