<!-- 
  Breadcrumbs
  ------------
  A breadcrumb is a navigation element that displays the user's path to a page.
  It is usually used as a subnavigation of a page.
  ------------
  Props:
    - items: Array of objects with the following properties:
      - text: String 
      - href: String
      - active: Boolean
 -->

<template>
  <nav class="flex" aria-label="Breadcrumb" v-if="items.length > 0">
    <ol role="list" class="flex items-center space-x-4">
      <li v-for="(item, index) in items" :key="item.text">
        <div class="flex items-center">
          <SvgIcon
            v-if="index !== 0"
            name="right-arrow"
            class="h-5 w-5 shrink-0 text-gray-400 dark:text-gray-500"
          />
          <router-link
            :to="item.href"
            :class="{
              'ml-4 text-sm font-medium text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50':
                item.active,
              'ml-4 text-sm font-medium text-gray-900 dark:text-gray-50':
                !item.active
            }"
            :aria-current="!item.active ? 'page' : false"
            >{{ item.text }}
          </router-link>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
import SvgIcon from './SvgIcon.vue'
export default {
  name: 'Breadcrumbs',
  props: {
    items: {
      type: Array,
      default: []
    }
  },
  components: { SvgIcon }
}
</script>
