<template>
  <div class="overflow-y-auto py-5 pr-2" ref="gridEl">
    <div class="grid grid-cols-3 justify-items-center gap-5">
      <div
        class="group cursor-pointer text-center"
        v-for="item of list.filter(
          item => !item.hide && !item?.hideFromFrontEnd
        )"
      >
        <div
          class="transition-all duration-200"
          @click="$emit('item-selected', item)"
        >
          <div
            class="flex items-center justify-center rounded-xl border border-gray-200 bg-white group-hover:border-blue-600 group-hover:bg-blue-50 dark:border-gray-600 dark:bg-gray-900 dark:group-hover:border-blue-500 dark:group-hover:bg-blue-900"
            :style="{
              height: NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] + 2 + 'px',
              width: NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] + 2 + 'px'
            }"
          >
            <SvgIcon v-if="item.isIcon" :name="item.logoUrl" />
            <img
              v-else
              class="mx-auto h-10 w-10 rounded-full object-cover"
              :src="item.logoUrl"
              alt=""
            />
          </div>
          <p
            class="mt-3 overflow-hidden break-words text-xs font-semibold text-gray-900 group-hover:text-blue-600 dark:text-gray-50 dark:group-hover:text-gray-50"
            :style="{
              width: NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] + 2 + 'px'
            }"
          >
            {{ item.shortName || item.label || item.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { NODE_SIZES, NODE_TYPE_NAMES } from '@/common/constants'
import { useInfiniteScroll } from '@/common/useInfiniteScroll'
import SvgIcon from '@/components/SvgIcon.vue'
import { ref } from 'vue'

const props = defineProps({
  list: {
    type: Array,
    required: true
  },
  onLoadMore: Function
})

const emit = defineEmits(['dragstart', 'item-selected', 'load-more'])

const gridEl = ref(null)

useInfiniteScroll(gridEl, {
  onLoadMore: () => emit('load-more')
})
</script>
