<template>
  <!-- START WRAPPER -->
  <div class="grid min-h-screen px-4 py-12 bg-gray-600 sm:px-6 lg:px-8 dark:bg-gray-700 place-items-center">

    <div class="relative w-full max-w-lg mx-auto overflow-hidden bg-white rounded-xl dark:bg-gray-900">
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Change Payment Method
          </p>

          <div>
            <Button icon="closeButton" class="p-1.5 text-gray-600 transition-all duration-200 bg-white border border-gray-200 rounded-lg dark:text-gray-400 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:bg-gray-900 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900"/>
          </div>
        </div>
      </div>

      <div class="px-4 pb-5 sm:px-6 sm:pb-6">
        <div class="flow-root mt-4">
          <div class="-my-6 divide-y divide-gray-200 dark:divide-gray-700">
            <div v-for="(item, index) in paymentMethods" class="flex items-center justify-between py-6" :key="index">
              <div class="w-10 shrink-0">
                <img v-if="item.data.isVisa" class="w-auto h-6" src="@/assets/images/logos/visa.svg" alt="">
                <img v-if="item.data.isMasterCard" class="w-auto h-6" src="@/assets/images/logos/mastercard.svg" alt="">
              </div>
              <p class="ml-6 mr-auto text-sm font-medium text-gray-900 dark:text-gray-50">
                {{ item.data.name }}
              </p>
              <div>
                <label for="" class="sr-only">
                  {{item.isVisa? 'Visa' : item.isMasterCard ? 'Mastercard' : ''}}
                </label>
                <Checkboxes :checked="item.currentlyInUse" />
              </div>
            </div>
          </div>
        </div>

        <div class="mt-10">
          <Button text="Save" />
        </div>
      </div>
    </div>

  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Button from '@/components/Button.vue';
import SvgIcon from "@/components/SvgIcon.vue";
import Checkboxes from "@/components/Checkboxes.vue";

export default {
  name: "changePaymentMethod",
  props: {
    paymentMethods: {
      type: Array, default: [
        {
          currentlyInUse: true,
          data: {
            isVisa: true,
            name: 'Visa ending ****1234'
          }
        },
        {
          data: {
            isMasterCard: true,
            name: 'Mastercard ending ****1234'
          }
        },
        {
          data: {
            isVisa: true,
            name: 'Visa ending ****1234'
          }
        },
        {
          data: {
            isMasterCard: true,
            name: 'Mastercard ending ****1234'
          }
        }
      ]

    }
  },
  components: {
    Button,
    SvgIcon,
    Checkboxes,
  }
}
</script>

<style scoped>

</style>
