<template>
  <div
    class="fixed left-0 top-0 z-40 flex h-screen w-screen items-center justify-center"
  >
    <div
      class="absolute left-0 top-0 h-screen w-screen bg-gray-400 bg-opacity-40"
      @click="$emit('modal-close')"
    />
    <div
      class="z-10 flex h-[760px] w-[720px] flex-col rounded-[10px] bg-white px-8 py-10"
    >
      <div class="relative mb-9">
        <nav class="flex justify-center space-x-4" aria-label="Tabs">
          <a
            href="#"
            title=""
            class="rounded-lg bg-gray-100 px-3 py-2 text-sm font-medium text-gray-700 transition-all duration-200 dark:bg-gray-700 dark:text-gray-50"
            aria-current="page"
          >
            Scheduling
          </a>
        </nav>
        <button
          type="button"
          class="absolute right-0 top-0 h-9 w-9 rounded-lg border border-gray-200 bg-white p-1.5 text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900"
          @click="$emit('modal-close')"
        >
          <span class="sr-only"> Close </span>
          <SvgIcon name="close" class="m-auto" />
        </button>
      </div>
      <Spinner v-if="loadingInputs" class="mx-auto" />

      <div
        v-if="
          props.firstNode?.label === 'Sheet Input' ||
          props.firstNode?.label === 'Get data from csv'
        "
        class="mb-2"
      >
        <p class="mb-2 font-[400]">
          You've used
          {{
            props.firstNode?.label === 'Sheet Input' ? 'google sheet' : 'CSV'
          }}
          as input for workflow so we'll be automatically detecting the no. of
          rows to be processed everyday depending on daily limits and then
          <span class="font-semibold">we'll schedule it</span> from our side to
          run on each day until it process all the rows of sheet/CSV.
        </p>
        <Button
          text="Advanced Settings"
          :color="advancedButtonClicked ? 'primary' : 'tertiary'"
          size="small"
          leftIcon="plus"
          @click="advancedButtonClicked = true"
        />
      </div>
      <div v-if="showModal">
        <Scheduling
          v-if="!loadingInputs"
          :inputs="inputs"
          :option="option"
          :trigger-validation="triggerValidation"
          @input-update="updateInputs"
          @option-update="updateOption"
          @validationSuccess="saveInputs"
          @validationFailed="triggerValidation = false"
        />

        <Button
          v-if="!loadingInputs"
          @click="triggerValidation = true"
          class="mt-9 self-start"
        >
          Save
          <Spinner v-show="saving" size="small" class="ml-1 text-white" />
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { updateWorkflow } from '@/apis/workflows'
import Button from '@/components/Button.vue'
import Scheduling from '@/components/scheduling/config.vue'
import { convertToProperFormat } from '@/components/scheduling/utility'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'

const props = defineProps({ workflow: {}, firstNode: {} })
const inputs = ref(props.workflow?.schedule?.rawData || {})
const option = ref(props.workflow?.schedule?.option || 0)
const saving = ref(false)
const triggerValidation = ref(false)
const advancedButtonClicked = ref(false)

const showModal = computed(() => {
  if (
    props.firstNode?.label === 'Sheet Input' ||
    props.firstNode?.label === 'Get data from csv'
  ) {
    if (advancedButtonClicked.value === true) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
})

const route = useRoute()

const emit = defineEmits(['success', 'error'])

const updateInputs = updatedInputs => {
  inputs.value = updatedInputs
  if (inputs.value?.endDate) {
    const date = new Date(inputs.value?.endDate)
    const today = new Date()
    let obj = { ...updatedInputs }
    if (
      date.getUTCFullYear() === today.getUTCFullYear() &&
      date.getUTCMonth() === today.getUTCMonth() &&
      date.getUTCDate() === today.getUTCDate()
    ) {
      // Set the time to 23:59:59
      const newEndDate = new Date()
      newEndDate.setHours(23, 59, 59, 0)
      obj.endDate = newEndDate.toISOString()
      inputs.value = obj
    }
  }
}
const updateOption = newOption => {
  option.value = newOption
}

const saveInputs = async () => {
  saving.value = true
  try {
    let optionVal = option.value === '' ? 0 : option.value
    triggerValidation.value = false
    const structuredInput = convertToProperFormat(inputs.value, optionVal)
    const optionSchedulingData = {
      startDate: inputs.value.startDate,
      endDate: inputs.value.endDate,
      startTime: inputs.value.startTime,
      endTime: inputs.value.endTime,
      option: optionVal,
      timezone: Intl?.DateTimeFormat().resolvedOptions().timeZone
    }
    amplitudeTrackEvent('Workflow Schedule', localStorage.getItem('email'), {
      schedule: {
        ...structuredInput,
        ...optionSchedulingData,
        rawData: inputs.value
      },
      workflowId: route.params.id
    })
    await updateWorkflow(route.params.id, {
      schedule: {
        ...structuredInput,
        ...optionSchedulingData,
        rawData: inputs.value
      },
      isScheduled: optionSchedulingData.option === 0 ? false : true,
      isActive: false
    })
    emit('success', 'Schedule updated successfully')
  } catch (error) {
    emit(
      'error',
      'Failed to add or update the schedule. Please try again in some time.'
    )
  }
  saving.value = false
}
</script>
