<template>
  <AutomationHeader title="Notifications" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <connectSlackAccountModal
      v-if="modalActive"
      :configData="configData"
      @close="toggleModalClose"
      @success="$router.go()"
      @updateNotificationApiResponse="notificationEmit"
      @error="e => $emit('error', e)"
    />

    <SettingsMenu active-option="notifications" />

    <NotificationSection
      @notificationEmit="notificationEmit"
      @modalActivation="toggleModalOpen"
    />
  </main>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import connectSlackAccountModal from '@/components/settings/notification/connectSlackAccountModal.vue'
import NotificationSection from '@/components/settings/notificationSection.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'

export default {
  name: 'notifications',
  components: {
    AutomationHeader,
    SettingsMenu,
    NotificationSection,
    connectSlackAccountModal
  },
  data() {
    return {
      modalActive: false,
      configData: null
    }
  },
  methods: {
    notificationEmit(data) {
      if (data.type === 'success') {
        this.$emit('success', data.msg)
      } else {
        this.$emit('error', data.msg)
      }
    },
    toggleModalOpen(data) {
      this.configData = data
      this.modalActive = true
    },
    toggleModalClose() {
      this.modalActive = false
    }
  }
}
</script>

<style scoped></style>
