<template>
  <nav class="flex justify-center space-x-4 antialiased" aria-label="Tabs">
    <TabSingleMenuOption v-for="(item, index) in menuOptions" :optionName="item.name" :isActive="item.active" :key="index"/>
  </nav>
</template>

<script>
import TabSingleMenuOption from "@/components/settings/tabSingleMenuOption.vue";

export default {
  name: "integrationsHeader",
  props: {
    activeOption: {type: String, default: 'all integrations', required: true}
  },
  components: {
    TabSingleMenuOption,
  },
  data() {
    return {
      menuOptions: [
        {
          name: 'All Integrations',
          active: this.activeOption === 'all integrations'
        },
        {
          name: 'Data',
          active: this.activeOption === 'data'
        },
        {
          name: 'Email',
          active: this.activeOption === 'email'
        },
        {
          name: 'Enrichment',
          active: this.activeOption === 'enrichment'
        },
        {
          name: 'CRM',
          active: this.activeOption === 'crm'
        },
        {
          name: 'Communication',
          active: this.activeOption === 'communication'
        },
        {
          name: 'Personalization',
          active: this.activeOption === 'personalization'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
