<template>
  <div
    v-for="category of store.getters[
      'settings/getMetaData'
    ].functionRuleTypes.filter(cat => cat.rules.length)"
    class="my-8"
  >
    <FunctionList
      :category="category"
      @selected="(category, rule) => $emit('function-selected', category, rule)"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import FunctionList from './functionList.vue'

const store = useStore()
defineEmits(['function-selected'])
</script>
