<template>
  <div
    class="fixed flex h-fit w-full flex-col items-center border-l border-gray-200 outline-none transition-all duration-300 ease-in-out"
    :class="{
      'bottom-[64px]': open,
      'bottom-[-1000px]': !open
    }"
    ref="modalEl"
  >
    <h3>History Timeline</h3>
    <div class="h-full w-fit max-w-2xl overflow-x-auto bg-white p-2 shadow">
      <Loader v-if="loading" class="m-auto w-[60vw] max-w-2xl" />
      <div v-else>
        <div
          v-if="executions.length === 0"
          class="w-[60vw] max-w-2xl p-2 text-center text-sm text-gray-500"
        >
          This workflow has no history.
        </div>
        <div class="grid grid-flow-col gap-2" v-else>
          <div
            v-for="execution of executions"
            class="border-grey-500 group flex w-max cursor-pointer gap-2 rounded border-2 p-2 hover:border-blue-500 hover:bg-blue-50"
            @click="$emit('select', execution._id)"
            :class="{
              'border-blue-500 bg-blue-50':
                $route.query.executionId === execution._id
            }"
          >
            <div>
              <svg-icon
                :name="`status-${execution.status}`"
                :class="{
                  'fill-yellow-400':
                    execution.status === 'running' ||
                    execution.status === 'paused' ||
                    execution.status === 'waiting' ||
                    execution.status === 'delayed',
                  'fill-green-500': execution.status === 'completed',
                  'fill-red-500':
                    execution.status === 'failed' ||
                    execution.status === 'partially-failed'
                }"
                class="h-6 w-6"
              />
            </div>

            <div class="text-sm">
              <p>
                {{ new Date(execution.createdAt).toDateString() }}
              </p>
              <p>
                {{ new Date(execution.createdAt).toLocaleTimeString() }}
              </p>
            </div>
            <span
              class="invisible absolute z-50 top-0 ml-[41px] rounded bg-white border-[1px] p-1 text-sm capitalize group-hover:visible"
            >
              {{ execution.status }}&nbsp;{{
                execution?.statusReason ? `: ${execution?.statusReason}` : ''
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getWorkflowExecution } from '@/apis/workflows'
import Loader from '@/assets/icons/Loader.vue'
import { onClickOutside } from '@vueuse/core'
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  open: Boolean
})

const emit = defineEmits(['modal-close', 'select'])

const route = useRoute()

const executions = ref([])
const modalEl = ref()
const loading = ref(true)

onClickOutside(modalEl, clicked => {
  if (clicked) {
    emit('modal-close')
  }
})

const getExecutions = async () => {
  try {
    loading.value = true
    const workflowId = route.params.id
    const response = await getWorkflowExecution(workflowId)
    executions.value = response.data
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

watch(
  () => props.open,
  open => {
    if (open) getExecutions()
  }
)

onMounted(() => {
  if (route.query.executionId) getExecutions()
})
</script>
