<!-- Size of the toast can be adjusted on the component call  -->
<!-- Props: icon(t/f), action(t/f), close(t/f), caption(t/f), text(text),  error(t/f) -->
<!-- events: toast-close, toast-action -->

<template>
  <div
    class="pointer-events-auto rounded-lg border"
    :class="[
      { ' !border-red-600 bg-red-600': red },
      { 'border-gray-200 bg-white': plain },
      { 'border border-blue-600 bg-blue-600': blue },
      { 'border border-gray-900 bg-gray-900': dark },
      { 'border border-green-600 bg-green-600': green },
      { 'border border-yellow-600 bg-yellow-600': yellow },
      { 'bg-gray-100': neutral },
      { 'bg-blue-50': info || infoPlain },
      { 'bg-green-50': positive || success },
      { 'bg-red-50': error },
      { 'bg-yellow-50': warning || warningPlain }
    ]"
  >
    <div class="p-4">
      <div v-show="!caption" class="flex items-center justify-between">
        <SvgIcon
          v-show="
            icon &&
            !(
              red ||
              blue ||
              yellow ||
              green ||
              dark ||
              neutral ||
              info ||
              positive ||
              error ||
              warning ||
              infoPlain ||
              warningPlain ||
              errorPlain
            )
          "
          name="green-check"
        />
        <SvgIcon
          class="mr-2.5 h-5 w-5 text-white"
          :class="[
            { '!text-blue-600 ': info },
            { '!text-green-600': positive },
            { '!text-red-600': error },
            { '!text-yellow-600': warning }
          ]"
          v-show="
            (red ||
              blue ||
              yellow ||
              green ||
              dark ||
              info ||
              positive ||
              error ||
              warning) &&
            icon
          "
          name="important"
        />
        <SvgIcon
          class="mr-2.5 h-5 w-5 shrink-0"
          :class="[
            { '!text-blue-600': infoPlain },
            { '!text-gray-600': !infoPlain }
          ]"
          v-show="(neutral || infoPlain) && icon"
          name="smiley"
        />

        <SvgIcon
          class="mr-2.5 h-5 w-5 shrink-0 text-yellow-500"
          v-show="warningPlain && icon"
          name="important-triangle"
        />

        <p
          class="flex-1 text-sm font-normal text-gray-900"
          :class="[{ 'text-white': red || blue || yellow || green || dark }]"
        >
          {{ text }}
        </p>
        <div v-show="action" class="ml-4">
          <a
            href="#"
            title=""
            class="text-sm font-bold text-blue-600 transition-all duration-200 hover:text-blue-800 focus:outline-none focus:ring-0"
            :class="[
              {
                'text-white hover:text-red-200':
                  red || blue || yellow || green || dark
              },
              { 'text-gray-600 hover:!text-gray-900': neutral },
              { '!text-blue-600 hover:!text-blue-800': info },
              { '!text-green-600 hover:!text-green-800 ': positive || success },
              { '!text-red-600 hover:!text-red-800': error },
              {
                '!text-yellow-600 hover:!text-yellow-800':
                  warning || warningPlain
              }
            ]"
            role="button"
          >
            Action
          </a>
        </div>
        <div v-show="close" class="ml-4 flex items-center">
          <Button
            class="-m-1 rounded-md p-1"
            :class="[
              {
                'text-red-200 hover:bg-red-700 hover:text-white focus:ring-red-700 focus:ring-offset-red-600':
                  red
              },
              {
                'text-blue-200 hover:bg-blue-700 hover:text-white focus:ring-blue-700 focus:ring-offset-blue-600':
                  blue
              },
              {
                'text-gray-200 hover:bg-gray-700 hover:text-white focus:ring-gray-700 focus:ring-offset-gray-600':
                  dark
              },
              {
                'text-green-200 hover:bg-green-700 hover:text-white focus:ring-green-700 focus:ring-offset-green-600':
                  green
              },
              {
                'text-yellow-100 hover:bg-yellow-700 hover:text-white focus:ring-yellow-700 focus:ring-offset-yellow-600 ':
                  yellow
              },
              {
                'bg-white  text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:ring-gray-400 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
                  plain
              },
              {
                'text-gray-600 hover:bg-gray-200 hover:text-gray-700 focus:ring-gray-400 focus:ring-offset-gray-100':
                  neutral
              },
              {
                'text-gray-600 hover:bg-blue-100 hover:text-gray-700 focus:ring-blue-200 focus:ring-offset-blue-50 ':
                  info || infoPlain
              },
              {
                'text-gray-600 hover:bg-green-100 hover:text-gray-700 focus:ring-green-200 focus:ring-offset-green-50 ':
                  positive || success
              },
              {
                'text-gray-600 hover:bg-red-100 hover:text-gray-700 focus:ring-red-200 focus:ring-offset-red-50':
                  error
              },
              {
                'text-gray-600 hover:bg-yellow-100 hover:text-gray-700 focus:ring-yellow-200 focus:ring-offset-yellow-50':
                  warning || warningPlain
              }
            ]"
            color="iconOnly"
            icon="close"
            @click="$emit('toast-close')"
          />
        </div>
      </div>

      <div v-show="caption" class="flex items-start justify-between">
        <SvgIcon
          v-show="
            icon &&
            !(
              red ||
              blue ||
              yellow ||
              green ||
              dark ||
              neutral ||
              info ||
              positive ||
              error ||
              warning
            )
          "
          name="green-check"
        />
        <SvgIcon
          class="mr-2.5 h-5 w-5 text-white"
          :class="[
            { '!text-blue-600 ': info },
            { '!text-green-600': positive },
            { '!text-red-600': error },
            { '!text-yellow-600': warning }
          ]"
          v-show="
            (red ||
              blue ||
              yellow ||
              green ||
              dark ||
              info ||
              positive ||
              error ||
              warning) &&
            icon
          "
          name="important"
        />
        <SvgIcon
          class="mr-2.5 h-5 w-5 !text-gray-600"
          v-show="neutral && icon"
          name="smiley"
        />
        <div class="flex-1">
          <p
            class="text-sm font-semibold text-gray-900"
            :class="[{ 'text-white': red || blue || yellow || green || dark }]"
          >
            {{ text }}
          </p>
          <p
            class="mt-1 text-sm font-normal text-gray-600"
            :class="[
              { 'text-blue-200': blue },
              { 'text-gray-200': dark },
              { 'text-green-200': green },
              { 'text-red-200': red },
              { 'text-yellow-100': yellow }
            ]"
          >
            {{ caption }}
          </p>
          <div v-show="action" class="mt-2.5">
            <router-link
              :to="actionHref"
              class="text-sm font-bold text-blue-600 transition-all duration-200 hover:text-blue-800 focus:outline-none focus:ring-0"
              :class="[
                {
                  'text-white hover:text-red-200':
                    red || blue || yellow || green || dark
                },
                { 'text-gray-600 hover:!text-gray-900': neutral },
                { '!text-blue-600 hover:!text-blue-800': info },
                { '!text-green-600 hover:!text-green-800 ': positive },
                { '!text-red-600 hover:!text-red-800': error },
                { '!text-yellow-600 hover:!text-yellow-800': warning }
              ]"
              role="button"
            >
              {{ actionText }}
            </router-link>
          </div>
        </div>
        <div v-show="close" class="ml-4 flex items-center">
          <Button
            class="-m-1 rounded-md p-1"
            :class="[
              {
                'text-red-200 hover:bg-red-700 hover:text-white focus:ring-red-700 focus:ring-offset-red-600':
                  red
              },
              {
                'text-blue-200 hover:bg-blue-700 hover:text-white focus:ring-blue-700 focus:ring-offset-blue-600':
                  blue
              },
              {
                'text-gray-200 hover:bg-gray-700 hover:text-white focus:ring-gray-700 focus:ring-offset-gray-600':
                  dark
              },
              {
                'text-green-200 hover:bg-green-700 hover:text-white focus:ring-green-700 focus:ring-offset-green-600':
                  green
              },
              {
                'text-yellow-100 hover:bg-yellow-700 hover:text-white focus:ring-yellow-700 focus:ring-offset-yellow-600 ':
                  yellow
              },
              {
                'bg-white  text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:ring-gray-400 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
                  plain
              },
              {
                'text-gray-600 hover:bg-gray-200 hover:text-gray-700 focus:ring-gray-400 focus:ring-offset-gray-100':
                  neutral
              },
              {
                'text-gray-600 hover:bg-blue-100 hover:text-gray-700 focus:ring-blue-200 focus:ring-offset-blue-50 ':
                  info
              },
              {
                'text-gray-600 hover:bg-green-100 hover:text-gray-700 focus:ring-green-200 focus:ring-offset-green-50 ':
                  positive
              },
              {
                'text-gray-600 hover:bg-red-100 hover:text-gray-700 focus:ring-red-200 focus:ring-offset-red-50':
                  error
              },
              {
                'text-gray-600 hover:bg-yellow-100 hover:text-gray-700 focus:ring-yellow-200 focus:ring-offset-yellow-50':
                  warning
              }
            ]"
            color="iconOnly"
            icon="close"
            @click="$emit('toast-close')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router'
import Button from './Button.vue'
import SvgIcon from './SvgIcon.vue'

export default {
  name: 'Toast',
  props: {
    text: { type: String, default: 'placeholder' },
    icon: { type: Boolean, default: false },
    caption: { type: String, default: false },
    action: { type: Boolean, default: false },
    actionText: { type: String, default: 'action' },
    actionHref: { type: String, default: '#' },
    close: { type: Boolean, default: false },
    color: { type: String, default: 'plain' }
  },
  components: { SvgIcon, Button, RouterLink },
  // red || blue || yellow || green|| dark
  computed: {
    plain() {
      if (this.color === 'plain') return true
    },
    red() {
      if (this.color === 'red') return true
    },
    blue() {
      if (this.color === 'blue') return true
    },
    yellow() {
      if (this.color === 'yellow') return true
    },
    green() {
      if (this.color === 'green') return true
    },
    dark() {
      if (this.color === 'dark') return true
    },
    neutral() {
      if (this.color === 'neutral') return true
    },
    info() {
      if (this.color === 'info') return true
    },
    positive() {
      if (this.color === 'positive') return true
    },
    error() {
      if (this.color === 'error') return true
    },
    warning() {
      if (this.color === 'warning') return true
    },
    success() {
      if (this.color === 'success') return true
    },
    warningPlain() {
      if (this.color === 'warningPlain') return true
    },
    infoPlain() {
      if (this.color === 'infoPlain') return true
    }
  }
}
</script>

<style></style>
