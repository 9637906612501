<template>
  <section class="flex flex-1 border-t antialiased border-gray-200 dark:border-gray-700 text-gray-900 bg-white dark:bg-gray-900 dark:text-gray-50">
    <div class="flex items-center justify-center flex-1 max-w-lg px-8 py-6 mx-auto">
      <div>
        <div class="rounded-lg bg-blue-50 dark:bg-blue-900/50">
          <div class="px-4 py-5 sm:p-6">
            <div class="flex items-start">
              <SvgIcon name="smiley" />
              <p class="ml-3 text-sm font-semibold text-gray-900 dark:text-gray-50">
                Did you know?
              </p>
            </div>
            <p class="mt-3 text-sm font-normal text-gray-900 dark:text-gray-50">
              {{didYouKnowText}}
            </p>
          </div>
        </div>

        <div class="mt-8 text-center">
          <img class="w-full max-w-xs mx-auto" src="@/assets/images/illustrations/importing-accounts.png" alt="">

          <div class="mt-8 text-center">
            <p class="text-base font-semibold text-gray-900 dark:text-gray-50">
              Processing, please wait: {{ recordCollectedData }}
            </p>

            <div class="relative w-full h-2 mt-5 bg-gray-100 rounded-full dark:bg-gray-600">
              <div class="absolute inset-y-0 left-0 w-[25%] bg-blue-600 dark:bg-blue-500 rounded-full"></div>
            </div>

            <p class="mt-6 text-sm text-gray-600 dark:text-gray-400">
              Elapsed Time: {{ elapsedTime }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";

export default {
  name: "automationProcessing",
  components: {
    SvgIcon,
  },
  props: {
    didYouKnowText: {
      type: String,
      required: true
    },
    recordCollectedData: {
      type: String,
      required: true,
    },
    elapsedTime: {
      type: String,
      required: true,
    }

  }
}
</script>

<style scoped>

</style>
