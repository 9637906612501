<template>
  <AutomationHeader
    :highlight="highlight"
    :nav="true"
    icon="instagram"
    title="Collect Instagram Followers"
  />

  <section class="flex flex-1 border-t border-gray-200 dark:border-gray-700">
    <OptionalSidebar :data="optionalSidebarData" item="center" />

    <SchedulingMode />
  </section>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import OptionalSidebar from '@/components/automationStore/OptionalSidebar.vue'
import SchedulingMode from '@/components/automationStore/SchedulingMode.vue'

export default {
  components: {
    AutomationHeader,
    OptionalSidebar,
    SchedulingMode
  },
  name: 'AutomationStorePage4',
  data() {
    return {
      highlight: 1,
      optionalSidebarData: [
        '**Optional Step, only required to plan in advance',
        'Select the schedule recurrence (frequency)',
        'Set start time offset (Optional)',
        'Set start time offset (Optional)'
      ]
    }
  }
}
</script>

<style></style>
