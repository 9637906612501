<template>
  <div
    class="absolute top-0 left-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto h-fit w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Watch this video tutorial
          </p>

          <div class="flex items-center justify-end space-x-4">
            <Button
              @click="close"
              icon="close-1"
              class="!p-1.5"
              size="small"
              color="tertiary"
            />
          </div>
        </div>
      </div>

      <div class="p-4">
        <div
          class="aspect-w-16 aspect-h-9 mt-4 overflow-hidden rounded-lg bg-gray-300"
        >
          <iframe
            :src="src"
            title="Texau V2 Complete Walkthrough"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
export default {
  name: 'WatchYoutubeVideoModal',
  components: {
    SvgIcon,
    Button
  },
  props: {
    src: { type: String }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style></style>
