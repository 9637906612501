<template>
  <div
    class="relative h-[200px] bg-linkedin-100 hover:border-linkedin-600 dark:bg-linkedin-900 transform cursor-pointer overflow-hidden rounded-lg border border-transparent transition-all duration-200 hover:-translate-y-1 hover:shadow-lg"
  >
    <div class="h-full">
      <div class="flex flex-row-reverse w-full cursor-pointer pr-1 pt-2">
        <Popper hover>
          <div @click="onConfigureClick()" class="text-blue-400">
            <SvgIcon name="preferences" />
          </div>
          <template #content>
            <div class="w-max max-w-lg rounded px-2 text-sm">Configure</div>
          </template>
        </Popper>
      </div>
      <div @click="onTryClick" class="px-[18px] h-full">
        <div class="flex flex-col space-y-1 2xl:space-y-2">
          <div class="flex h-[32px] -space-x-2 overflow-hidden">
            <img
              class="inline-block h-8 w-8 rounded-full ring-2 ring-white"
              alt=""
              v-for="logo in data.uniquePlatformIconUrl"
              :key="logo"
              :src="logo"
            />
          </div>
          <div class="flex flex-col gap-[1px]">
            <p
              class="overflow-hidden text-base font-semibold text-gray-900 dark:text-gray-50 2xl:mt-2"
            >
              {{ data.name }}
            </p>
            <p
              class="overflow-hidden text-sm font-normal text-gray-500 line-clamp-3 dark:text-gray-400"
            >
              {{ `${getFormatDateAndTime(data.createdAt)}` }}
            </p>
            <p
              class="overflow-hidden text-sm font-normal text-gray-500 line-clamp-1 dark:text-gray-400"
            >
              {{ data.notes }}
            </p>
          </div>
        </div>
        <div class="absolute bottom-3 right-5">
          <Button size="small" color="secondary" text="Try it!" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../Button.vue'
import SvgIcon from '../SvgIcon.vue'
import Popper from 'vue3-popper'
import { formatDate, formatTime } from '@/common/functions/formatDateAndTime'
import { mapActions } from 'vuex'
import { getPublicWorkflowInputs } from '@/apis/workflows'

export default {
  name: 'WorkflowCards',
  emits: ['error'],
  props: {
    data: {}
  },

  methods: {
    ...mapActions('automationStore', ['addAutomationData']),

    async onTryClick() {
      try {
        this.loader = true

        let coneWorkflowId = this.data._id

        const inputResponse = await getPublicWorkflowInputs(coneWorkflowId)
        if (inputResponse['success']) {
          await this.addAutomationData({
            payload: {
              ...this.data,
              logoUrl: this.data.uniquePlatformIconUrl[0],
              inputList: {
                ...inputResponse.data
              }
            }
          })
          await this.$router.push({
            path: `/automation-store/workflow`,
            query: {
              publicWorkflowId: coneWorkflowId,
              source: 'workflowInput'
            }
          })
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },
    async onConfigureClick() {
      try {
        await this.$router.push(`/workflow-builder/${this.data._id}`)
      } catch (error) {
        this.$emit('error', error)
      }
    },

    getFormatDateAndTime(d) {
      return `${formatDate(d)} ${formatTime(d)}`
    }
  },
  components: { Button, SvgIcon, Popper }
}
</script>

<style></style>
