<template>
  <!-- START WRAPPER -->
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto max-h-full w-full max-w-xl overflow-x-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Remaining Limits
          </p>

          <div class="">
            <Button
              @click="emitClose"
              icon="close-1"
              class="!p-1.5"
              size="large"
              color="tertiary"
            />
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center h-[200px]" v-if="loading">
        <Spinner size="large" />
      </div>

      <div v-else class="px-4 pb-5 sm:px-6 sm:pb-6 flex flex-col gap-y-4">
        <remainingLimitsTable
          :limitstableBodyData="limitstableBodyData"
          :limitsRemainingData="limitsRemainingData"
          :orgProxyData="orgProxyData"
        />
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Input from '@/components/Input.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import remainingLimitsTable from './remainingLimitsTable.vue'
import Spinner from '../Spinner.vue'
import { getOrganisationLimits } from '@/apis/user'
export default {
  name: 'remainingLimitsModal',
  components: {
    Input,
    SvgIcon,
    Button,
    remainingLimitsTable,
    Spinner
  },

  data() {
    return {
      loading: true,
      limitsRemainingData: null,
      limitstableBodyData: null,
      orgProxyData: null
    }
  },
  mounted() {
    this.getLimitsData()
  },

  methods: {
    emitClose() {
      this.$emit('close')
    },
    async getLimitsData() {
      const response = await getOrganisationLimits()
      const {
        apiAccess,
        isPayAsYouGo,
        aiAccess,
        desktopUnlimited,
        integrationsAccess,
        desktopUnlimitedExecutionTime,
        proxyAccess,
        proxyBandwidth,
        ...tableObj
      } = response.data
      this.limitsRemainingData = {
        apiAccess,
        isPayAsYouGo,
        aiAccess,
        desktopUnlimited,
        integrationsAccess,
        desktopUnlimitedExecutionTime,
        proxyAccess
      }
      this.orgProxyData = proxyBandwidth
      this.limitstableBodyData = tableObj
      this.loading = false
    }
  }
}
</script>

<style scoped></style>
