<template>
  <!-- START WRAPPER -->
  <div
    class="absolute top-0 left-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Add Webhook Link
          </p>

          <div class="flex items-center justify-end space-x-4">
            <Button
              @click="close"
              icon="close-1"
              class="!p-1.5"
              size="small"
              color="tertiary"
            />
          </div>
        </div>
      </div>

      <div class="px-4 pb-5 sm:px-6 sm:pb-6">
        <div class="mt-8 grid grid-cols-2 gap-y-5 gap-x-6">
          <ValidationForm
            class="col-span-2"
            :platformInputs="platformInputs"
            :previousNodes="[]"
            :inputs="inputData"
            :triggerValidation="triggerValidation"
            @validationSuccess="submitCreate"
            @validationFailed="triggerValidation = false"
          />

          <div class="col-span-2 flex flex-row-reverse">
            <Button
              class=""
              leftIcon="plus"
              text="Add New"
              @click="triggerValidation = true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Button from '@/components/Button.vue'
import { mapState } from 'vuex'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import { updateNotificationPreferences } from '@/apis/settings/settings-integration'
import SvgIcon from '@/components/SvgIcon.vue'
import ValidationForm from '@/components/ValidationForm.vue'

export default {
  name: 'connectSlackAccountModal',
  components: {
    SvgIcon,
    Button,
    Select,
    Input,
    ValidationForm
  },
  computed: {
    ...mapState('user', ['userWorkspaceData'])
  },
  props: {
    configData: {}
  },
  data() {
    return {
      triggerValidation: false,
      selectedWorkspace: null,
      accountName: null,
      webhookLink: null,
      platformInputs: null,
      validationSuccess: false,
      inputData: {},
      currWorkspaceId: window.$cookies.get('workspaceId')
    }
  },
  created() {
    this.getWorkspace()
  },
  methods: {
    close() {
      this.$emit('close')
      this.magicLink = false
    },
    async getWorkspace() {
      try {
        this.platformInputs = [
          {
            isRequired: true,

            type: 'text',

            label: `Add Slack Account Name`,
            name: 'accountName'
          },
          {
            isRequired: true,

            type: 'text',

            label: `Add Webhook Link`,
            name: 'webhookLink'
          }
        ]

        if (this.configData) {
          this.inputData['accountName'] = this.configData.accountName
          this.inputData['webhookLink'] = this.configData.url
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },
    async submitCreate(inputs) {
      this.triggerValidation = false
      try {
        let data = {
          slack: {
            config: {
              url: inputs.webhookLink,
              method: 'POST',
              accountName: inputs.accountName
            }
          }
        }

        const response = await updateNotificationPreferences(
          this.currWorkspaceId,
          data
        )
        if (response['success']) {
          this.$emit('updateNotificationApiResponse', {
            type: 'success',
            msg: 'Preferences Updated Successfully'
          })
          this.$emit('success')
        } else {
          this.$emit('updateNotificationApiResponse', {
            type: 'fail',
            msg: 'Some Error Occured While Updating Preferences'
          })
        }
      } catch (error) {
        this.$emit('updateNotificationApiResponse', {
          type: 'fail',
          msg: 'Some Error Occured While Updating Preferences'
        })
      }
    }
  }
}
</script>

<style></style>
