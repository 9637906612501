<template>
  <!-- START WRAPPER -->
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto w-full max-w-3xl rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6 flex flex-col justify-between">
        <div>
          <div class="flex items-center justify-between">
            <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
              Add Profile URL
            </p>

            <div>
              <Button
                @click="close"
                icon="close-1"
                class="!p-1.5"
                size="small"
                color="tertiary"
              />
            </div>
          </div>
          <hr class="my-2" />
          <div
            v-if="type === 'url'"
            class="mt-3 flex flex-col gap-5 overflow-y-scroll"
          >
            <Input
              class="p-1"
              text="E.g. https://www.linkedin.com/search/results/people/?keywords=texau&origin=GLOBAL_SEARCH_HEADER&sid=vPn"
              label="Enter The URL To Test the Prompt on"
              v-model="url"
            />
          </div>

          <div v-else-if="type === 'sheet'" class="">
            <div
              v-if="loading"
              class="flex items-center justify-center h-[200px]"
            >
              <Spinner size="large" />
            </div>
            <div v-else>
              <ValidationForm
                class=""
                :platformInputs="platformInputs"
                :previousNodes="previousNodes"
                :inputs="inputData"
                :triggerValidation="triggerValidation"
                @validationSuccess="validationSuccess"
                @validationFailed="validationFailed"
                @change="selectUpdate"
                @input-update="onInputUpdate"
              />
            </div>
          </div>

          <div v-else-if="type === 'csv'" class="">
            <div class="flex flex-col gap-4">
              <csvInput
                :inputs="nodeInputs"
                @file-uploaded="saveCsvInputs"
                @saving="formloading = $event"
              />
              <ValidationForm
                class=""
                :platformInputs="platformInputs"
                :previousNodes="previousNodes"
                :inputs="inputData"
                :triggerValidation="triggerValidation"
                @validationSuccess="validationSuccess"
                @validationFailed="validationFailed"
                @change="selectUpdate"
                @input-update="onInputUpdate"
              />
            </div>
          </div>
        </div>

        <div>
          <span
            v-show="formloading"
            class="flex items-center justify-center text-gray-300"
          >
            Checking for more inputs
            <Spinner size="small" class="ml-1 text-gray-400" />
          </span>
        </div>

        <div class="flex flex-row-reverse">
          <Button
            v-if="type === 'url'"
            @click="onStartTestClick()"
            text="Start Test"
          />
          <Button v-else @click="triggerValidation = true" text="Start Test" />
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import Input from '@/components/Input.vue'
import ValidationForm from '@/components/ValidationForm.vue'
import { getConnectedAccounts } from '@/apis/automation-store/Page1'
import { constants } from '@/common/constants'
import ObjectID from 'bson-objectid'
import { mapActions, mapState } from 'vuex'
import { getGoogleHeaders } from '@/apis/automation-store/googleSheets'
import { getOperationVariableServices } from '@/apis/automation-store/Page1'
import csvInput from '@/components/workflowBuilder/inputs/csvInput.vue'

export default {
  components: {
    Button,
    Spinner,
    Input,
    ValidationForm,
    csvInput
  },
  props: {
    type: { type: String, required: true }
  },
  data() {
    return {
      url: null,
      loading: true,
      formloading: false,
      platformInputs: [],
      inputData: {},
      previousNodes: [],
      spreadsheetId: null,
      sheetId: null,
      inputList: null,
      triggerValidation: false
    }
  },
  computed: {
    ...mapState('automationStore', ['automationStoreData']),
    ...mapState('automationStore', ['connectedAccountId']),
    ...mapState('automationStore', ['connectedAccountIdGoogleSheets']),
    ...mapState('automationStore', ['automationInputs'])
  },
  async created() {
    const platform = await getOperationVariableServices(
      constants.LINKEDIN_PLATFORM_ID,
      constants.LINKEDIN_PROFILE_SCRAPER_ID
    )
    this.inputList = platform.data.filter(item => item.isInput === true)
    if (this.type === 'sheet') {
      this.getAccount()
    }
  },
  methods: {
    ...mapActions('workflow', ['setDynamicOutputs']),
    ...mapActions('workflow', ['resetVariables']),
    ...mapActions('automationStore', ['addConnectedAccountId']),
    ...mapActions('automationStore', ['addConnectedAccountIdGoogleSheets']),
    async getAccount() {
      const [googleAccountresponse, sheetsResponse] = await Promise.all([
        getConnectedAccounts(constants.googlePlatformId),
        getOperationVariableServices(
          constants.googlePlatformId,
          constants.googlePlatformOperationId,
          'input'
        )
      ])

      this.platformInputs.push({
        name: 'connectedAccountId',
        type: 'select',
        label: 'Select Google Account:',
        isRequired: true,
        choices: googleAccountresponse.data.map(account => ({
          value: account._id,
          label: account.name
        }))
      })
      this.inputData['connectedAccountId'] = googleAccountresponse?.data[0]?._id
      this.addConnectedAccountIdGoogleSheets({
        payload: googleAccountresponse?.data[0]?._id
      })
      this.platformInputs.push(
        ...sheetsResponse.data.filter(item => item.label !== 'Loop')
      )

      this.loading = false
    },
    async selectUpdate(e, input, value) {
      if (input.name === 'connectedAccountId') {
        this.addConnectedAccountIdGoogleSheets({
          payload: e
        })
        return
      }

      if (input.name === 'googleSpreadsheetId') {
        this.spreadsheetId = e
        return
      }

      if (input.name === 'googleSheetId') {
        this.sheetId = e
        this.platformInputs.splice(3)
        await this.getSheetColumnHeader(this.spreadsheetId, this.sheetId)
        return
      }
    },
    async getSheetColumnHeader(spreadsheetId, sheetId) {
      try {
        this.formloading = true
        const response = await getGoogleHeaders(
          this.connectedAccountIdGoogleSheets,
          spreadsheetId,
          sheetId
        )

        if (response['success']) {
          let nodeId = ObjectID().toHexString()
          this.resetVariables()
          this.setDynamicOutputs({
            nodeId,
            dynamicOutputs: response.data
          })

          for (let data of this.inputList) {
            let obj = {
              ...data
            }
            delete obj['position']
            this.platformInputs.push({
              ...obj,
              hasDependencyOnFields: [
                'connectedAccountId',
                'googleSpreadsheetId',
                'googleSheetId'
              ]
            })
          }
          this.previousNodes = [
            {
              id: nodeId,
              label: 'Get data',
              order: 1,
              platformId: '62986487c9e1b344befa8ddc',
              platformOperationId: '628335fad0cc02a4b44b6fb7',
              logoUrl:
                'https://assets-of-v2.s3.amazonaws.com/platforms/logos/google-sheets.svg'
            }
          ]
        } else {
          throw response.message
        }
      } catch (e) {
        this.$emit('error', e)
      } finally {
        this.formloading = false
      }
    },
    async saveCsvInputs({ inputs, dynamicOutputs }) {
      this.url = inputs.csvUrl
      let nodeId = ObjectID().toHexString()
      this.resetVariables()
      this.setDynamicOutputs({
        nodeId,
        dynamicOutputs: dynamicOutputs
      })

      for (let data of this.inputList) {
        let obj = {
          ...data
        }
        delete obj['position']
        this.platformInputs.push({
          ...obj
        })
      }
      this.previousNodes = [
        {
          id: nodeId,
          label: 'Get data from csv',
          order: 1,
          platformId: '6287224920c13f85d82c3342',
          platformOperationId: '628722a920c13f85d82c3345',
          logoUrl:
            'https://assets-of-v2.s3.amazonaws.com/platforms/logos/CSV.svg'
        }
      ]
    },
    validationSuccess(e) {
      this.triggerValidation = false
      if (this.type === 'sheet') {
        this.$emit('startTestSheet', e)
      } else {
        this.$emit('startTestCsv', {
          url: this.url,
          ...e
        })
      }
    },
    close() {
      this.$emit('close')
    },
    onStartTestClick() {
      this.$emit('startTestUrl', this.url)
    }
  }
}
</script>

<style></style>
