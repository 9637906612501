<template>
  <div>
    <label :class="labelClass">{{ label }}</label>
    <div class="mt-1.5 flex flex-wrap gap-2">
      <div
        v-for="option of options"
        class="flex h-8 w-max cursor-pointer items-center justify-center rounded p-2 text-xs"
        :class="{
          'bg-blue-500 text-white': isActive(option.value),
          'bg-gray-100  text-black': !isActive(option.value)
        }"
        :key="option.value"
        @click="toggleActive(option.value)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {},
  options: {
    type: Array,
    required: true
  },
  label: String,
  labelClass: String
})
const emit = defineEmits(['update:modelValue'])

const isActive = option => props.modelValue?.includes(option)

const toggleActive = option => {
  const activeOptions = props.modelValue || []
  let value
  if (isActive(option)) {
    value = activeOptions.filter(active => active !== option)
  } else {
    value = activeOptions.concat(option)
  }

  emit('update:modelValue', value)
  emit('blur', value)
}
</script>
