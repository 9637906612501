<template>
  <div :class="`overflow-hidden rounded-lg bg-${bannerColor}-100`">
    <div class="px-4 py-2.5 min-h-10">
      <div class="flex items-center justify-between">
        <svg
          :class="`w-5 h-5 mr-2.5 text-${bannerColor}-600 shrink-0`"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path
            opacity="0.12"
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            fill="currentColor"
          />
          <path
            d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div v-html="text"></div>
        <div class="flex items-center justify-end ml-4 space-x-4 shrink-0">
          <router-link
            :to="buttonUrl"
            title=""
            class="text-sm font-bold text-blue-600 transition-all duration-200 hover:text-blue-800 focus:outline-none focus:ring-0"
            role="button"
          >
            {{ buttonText }}
          </router-link>

          <button
            v-if="showClose"
            @click="$emit('close')"
            type="button"
            :class="`p-1 -m-1 text-gray-600 transition-all duration-200 bg-transparent rounded-md hover:bg-${bannerColor}-100 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-${bannerColor}-200 focus:ring-offset-2 focus:ring-offset-${bannerColor}-50`"
          >
            <span class="sr-only"> Close </span>
            <svg
              class="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    text: {},
    buttonText: {},
    buttonUrl: {},
    showClose: { type: Boolean, default: true },
    bannerColor: { type: String, default: 'blue' }
  }
}
</script>
