<template>
  <div
    class="relative flex flex-1 border-l border-gray-200 dark:border-gray-700"
  >
    <div class="relative flex flex-1 flex-col justify-between px-4 py-5 sm:p-6">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-8">
          <div>
            <p class="font-semibold">Output Mode</p>
          </div>
          <div class="">
            <Switch
              @change="appendClick()"
              v-model="append"
              label="Append (Default)"
              caption="New results are appended at the end of the previous and merged to a single CSV file."
            />
          </div>
          <div class="">
            <Switch
              @change="splitClick()"
              v-model="split"
              label="Split"
              caption="New results are outputted as separate CSV file after each extraction."
            />
          </div>
          <div class="">
            <Switch
              @change="overwriteClick()"
              v-model="overwrite"
              label="Overwrite"
              caption="New results will overwrite data from previous extractions. This mode is useful for monitoring change on a page."
            />
          </div>
        </div>
        <hr />
        <div class="flex flex-col gap-8">
          <div>
            <p class="font-semibold">Duplicate management</p>
          </div>
          <div class="">
            <Switch
              @click="setOutputMode()"
              v-model="deduplicate"
              label="Deduplicate (Default)"
              caption="Delete duplicate rows between each extraction."
            />
          </div>
        </div>
      </div>

      <div class="mt-auto flex items-center justify-between">
        <Button
          color="tertiary"
          text="Previous"
          leftIcon="left-arrow"
          @click="prevButtonClick()"
        />

        <Button
          v-if="isScheduled"
          :showLoader="buttonLoading"
          @click="stopSchedule()"
          text="Stop Schedule"
          color="danger"
          rightIcon="right-arrow"
        />
        <div v-else>
          <Button
            v-if="$route.query.source === 'input'"
            :showLoader="buttonLoading"
            :color="validationStatus ? 'successFilled' : 'gray'"
            :disabled="validationStatus ? false : true"
            @click="nextButtonClick()"
            :text="automationScheduleData ? 'Schedule' : 'Run'"
            rightIcon="right-arrow"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../Button.vue'
import Select from '../Select.vue'
import Switch from '../Switch.vue'
import { mapState, mapActions } from 'vuex'
import { getWorkflowAllNodes, updateWorkflowNode } from '@/apis/workflows'

export default {
  name: 'OutputMode',
  components: { Select, Button, Switch },
  data() {
    return {
      append: true,
      split: false,
      overwrite: false,
      deduplicate: true,
      nodeData: null,
      buttonLoading: false
    }
  },
  computed: {
    ...mapState('automationStore', ['automationOutputMode']),
    ...mapState('automationStore', ['validationStatus']),
    ...mapState('automationStore', ['automationScheduleData']),
    ...mapState('user', ['desktopUnlimited', 'userData'])
  },
  async mounted() {
    if (this.$route.query.workflowId) {
      const response = await getWorkflowAllNodes(this.$route.query.workflowId)
      if (
        this.$route.query.source === 'sheet' ||
        this.$route.query.source === 'csv'
      ) {
        this.nodeData = response.data[1]
      } else {
        this.nodeData = response.data[0]
      }

      this.deduplicate = this.nodeData.isDedupeResult
      //
      this.setData(this.nodeData.outputMode)
    } else {
      this.deduplicate = this.automationOutputMode.isDedupeResult
      this.setData(this.automationOutputMode.outputMode)
    }
  },
  methods: {
    ...mapActions('automationStore', ['changeAutomationOutputMode']),
    setData(data) {
      if (data === 'append' || !data) {
        this.append = true
        this.split = false
        this.overwrite = false
      } else if (data === 'split') {
        this.split = true
        this.append = false
        this.overwrite = false
      } else {
        this.overwrite = true
        this.append = false
        this.split = false
      }
    },
    nextButtonClick() {
      if (this.validationStatus === true) {
        this.buttonLoading = true
        this.$emit('afterFormValidate')
      } else {
        this.$emit('nextButtonClick', {
          comp: 'run-comp',
          highlight: 4,
          optionalSidebarType: 'run',
          optionalSidebarItem: 'start',
          optionalSidebarSearch: true,
          startAutomation: true,
          runButtonOnHeader: true
        })
      }
    },
    prevButtonClick() {
      if (this.$route.query.source === 'input') {
        this.$emit('nextButtonClick', {
          comp: 'scheduling-comp',
          highlight: 2,
          optionalSidebarType: 'scheduling',
          optionalSidebarItem: 'center',
          optionalSidebarSearch: false
        })
      } else {
        this.$emit('nextButtonClick', {
          comp: 'delay-comp',
          highlight: 3,
          optionalSidebarType: 'scheduling',
          optionalSidebarItem: 'center',
          optionalSidebarSearch: false
        })
      }
    },
    appendClick() {
      this.split = false
      this.overwrite = false
      this.defaultAppendTrue()
    },
    splitClick() {
      this.append = false
      this.overwrite = false
      this.defaultAppendTrue()
    },
    overwriteClick() {
      this.append = false
      this.split = false
      this.defaultAppendTrue()
    },
    defaultAppendTrue() {
      if (((this.split === this.append) === this.overwrite) === false) {
        this.append = true
      }
      this.setOutputMode()
    },
    async setOutputMode() {
      let data = {
        outputMode: this.append ? 'append' : this.split ? 'split' : 'overwrite',
        isDedupeResult: this.deduplicate
      }
      this.changeAutomationOutputMode({
        payload: data
      })
      if (this.$route.query.workflowId) {
        this.updateNode(data)
      }
    },
    async updateNode(data) {
      const response = await updateWorkflowNode(
        this.$route.query.workflowId,
        this.nodeData._id,
        data
      )
      if (response['success']) {
        this.$emit('success', 'Output Mode Updated Sucessfully')
      }
    },
    async stopSchedule() {
      try {
        const response = await pauseSchedule(this.$route.query.workflowId)
        if (response['success']) {
          this.$emit('success', 'Automation Schedule Stopped Successfully')
          this.$emit('setScheduledStatus', false)
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
    }
  }
}
</script>
