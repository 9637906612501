import axios from '@/common/axios'

const generateApiKey = async () => {
  try {
    const response = await axios.post('/users/access-tokens')
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const deleteApiKey = async () => {
  try {
    const response = await axios.delete('/users/access-token')
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export { generateApiKey, deleteApiKey }
