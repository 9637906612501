<template>
  <!-- START WRAPPER -->
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto max-h-full w-full max-w-xl overflow-x-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Remove 2 Factor Authentication?
          </p>

          <div class="">
            <Button
              @click="emitClose"
              icon="close-1"
              class="!p-1.5"
              size="large"
              color="tertiary"
            />
          </div>
        </div>
      </div>

      <div class="px-4 pb-5 sm:px-6 sm:pb-6 flex flex-col gap-y-4">
        <div>
          <!-- <p class="text-center">
              {{
                `${forceDeleteData.length} ${
                  forceDeleteData.length > 1 ? 'Workflows' : 'Workflow'
                } Selected`
              }}
            </p> -->
        </div>
        <div class="bg-red-100 text-red-600 rounded-lg p-2">
          <div class="flex space-x-2 items-center justify-center">
            <SvgIcon class="h-5 w-5" name="warning" />
            <p class="font-bold">Warning</p>
          </div>
          <div>
            <p class="text-sm font-[500] text-center">
              Are You Sure You Want to Remove 2 Factor Authentication, This will
              decrease your account security
            </p>
          </div>
        </div>
        <div class="flex items-center justify-center space-x-4">
          <Button text="Cancel" @click="emitClose()" color="tertiary" />
          <Button text="Remove" @click="submitRemove" color="dangerFilled" />
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Input from '@/components/Input.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'

export default {
  name: 'removeMfaModal',
  components: {
    Input,
    SvgIcon,
    Button
  },
  props: {},
  data() {
    return {
      deleteLoader: false
    }
  },
  methods: {
    emitClose() {
      this.$emit('close')
    },
    submitRemove() {
      this.$emit('confirmRemoveMfa')
    }
  }
}
</script>

<style scoped></style>
