<!--
  Avatar
  ------------
  Props:
    - srcAndStatus: Array of objects with the following properties:
      - src: String
      - status: Boolean
      - initials: String
      - text: String
    - appearance: string can be 'circle' or 'square'
    - size: string can be 'large' or 'medium' or 'small' or 'xs'
 -->
<template>
  <div
    :class="[
      {
        ' flex -space-x-1 overflow-hidden': isMultiple,
        'rounded-full': circle
      }
    ]"
  >
    <div v-for="(item, index) in srcAndStatus" :key="index">
      <span
        class="bg-inherit"
        :class="[
          {
            'relative inline-block': isMultiple
          }
        ]"
      >
        <span
          v-if="!item.initials && !item.src"
          :id="index"
          class="inline-flex overflow-hidden bg-gray-100 dark:bg-gray-700"
          :class="[
            {
              'rounded-lg': square,
              'rounded-full': circle,
              'h-12 w-12': large,
              'h-10 w-10': medium,
              'h-8 w-8': small,
              'h-5 w-5': xs
            }
          ]"
        >
          <SvgIcon name="avatar" />
        </span>
        <span
          v-if="item.initials && !item.src"
          class="inline-flex items-center justify-center border border-blue-300 bg-blue-100 dark:border-blue-600 dark:bg-blue-500"
          :class="[
            {
              'rounded-lg': square,
              'rounded-full': circle,
              'h-12 w-12': large,
              'h-10 w-10': medium,
              'h-8 w-8': small,
              'h-5 w-5': xs
            }
          ]"
          :id="index"
        >
          <span
            class="text-base font-medium uppercase leading-none text-blue-600 dark:text-blue-50"
          >
            {{ item.initials }}
          </span>
        </span>
        <img
          :id="index"
          v-if="(item.src && item.initials) || item.src"
          :class="[
            {
              'inline-block ring-[3px] ring-white dark:ring-gray-900':
                isMultiple,
              'rounded-lg': square,
              'rounded-full': circle,
              'h-12 w-12': large,
              'h-10 w-10': medium,
              'h-8 w-8': small,
              'h-5 w-5': xs
            }
          ]"
          :src="item.src"
          alt=""
          @error="imageUrlAlt"
        />
        <span
          v-if="item.status"
          class="absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full bg-green-400 ring-2 ring-white dark:ring-gray-900"
        >
        </span>
        <span
          v-if="item.text"
          class="ml-3 text-sm text-gray-900 dark:text-gray-50"
        >
          {{ item.text }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import SvgIcon from './SvgIcon.vue'
import imgUrl from '/src/assets/images/default-avatar-dark.svg'

export default {
  name: 'Avatar',
  props: {
    srcAndStatus: {
      type: Array,
      default: [{ src: '', status: false, initials: '', text: '' }]
    },
    appearance: { type: String, default: 'circle' },
    size: { type: String, default: 'medium' }
  },
  computed: {
    isMultiple() {
      if (this.srcAndStatus.length >= 1) {
        return true
      }
    },
    square() {
      return this.appearance === 'square' || this.appearance === 'Square'
    },
    circle() {
      return this.appearance === 'circle' || this.appearance === 'Circle'
    },
    large() {
      return this.size === 'large' || this.size === 'Large'
    },
    medium() {
      return this.size === 'medium' || this.size === 'Medium'
    },
    small() {
      return this.size === 'small' || this.size === 'Small'
    },
    xs() {
      return this.size === 'xs' || this.size === 'xs'
    }
  },
  components: { SvgIcon },
  methods: {
    imageUrlAlt(event) {
      event.target.src = imgUrl
    }
  }
}
</script>
