<template>
  <!-- START WRAPPER -->
  <div class="flex h-full flex-col dark:bg-gray-900">
    <div class="flex min-h-0 flex-1 overflow-hidden">
      <!-- START MAIN CONTENT -->
      <main
        class="flex min-w-0 flex-1 flex-col overflow-y-auto overflow-x-hidden"
      >
        <AutomationHeader title="Agency White-Label" />
        <section
          class="flex flex-1 border-t border-gray-200 dark:border-gray-700"
        >
          <SettingsMenu active-option="Agency Whitelabel" />
          <ReportsSection />
        </section>
      </main>
      <!-- END MAIN CONTENT -->
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import ReportsSection from '@/components/settings/Reports/reportsSection.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'

export default {
  name: 'reports',
  components: {
    AutomationHeader,
    SettingsMenu,
    ReportsSection
  }
}
</script>

<style scoped></style>
