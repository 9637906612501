import axios from './axios'

const UserProfile = (function () {
  const getUser = function () {
    if (!window.$cookies.get('user')) {
      return false
    }
    return window.$cookies.get('user')
  }

  const setUser = function (user) {
    delete user['IdToken']
    delete user['ExpiresIn']
    delete user['TokenType']
    window.$cookies.set('user', JSON.stringify(user), '30d', '/')
  }

  const updateOnboardingStateInCookies = function (step) {
    let user_ = UserProfile.getUser()
    user_['currentOnboardingStep'] = step
    UserProfile.setUser(user_)
  }

  const logout = async function () {
    try {
      const response = await axios.post(`/auth/logout`)
      if (response.data['success']) {
        clearCookie()
        return response.data
      } else {
        return { data: { success: false } }
      }
    } catch (error) {
      clearCookie()
      return error.response.data
    }
  }

  const clearCookie = function () {
    window.$cookies.keys().forEach(cookie => window.$cookies.remove(cookie))
    sessionStorage.clear()
  }

  return {
    getUser: getUser,
    setUser: setUser,
    logout: logout,
    clearCookie: clearCookie,
    updateOnboardingStateInCookies: updateOnboardingStateInCookies
  }
})()

export default UserProfile
