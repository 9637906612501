import { AUTOMATION_VARIABLE_TYPES, VARIABLE_REGEX } from '@/common/constants'
import { charCount } from '@/common/functions/linkedinCharCounter'

export function validateInput(input, value) {
  if (value?.checkForCharCount === true) {
    let { count, characterCount } = charCount(value.text, value.isPremium)

    if (count < characterCount) {
      return 'Character Limit Exceeded'
    }
  }

  if (input.isRequired) {
    if (
      value == undefined ||
      (typeof value === 'string' && value.trim().length === 0)
    )
      return `${input.label} is required.`
  }

  const hasVariables = VARIABLE_REGEX.test(String(value))

  // Reset the regex index to 0
  VARIABLE_REGEX.lastIndex = 0

  if (
    value != undefined &&
    input.type === AUTOMATION_VARIABLE_TYPES.NUMBER &&
    isNaN(Number(value)) &&
    !hasVariables
  ) {
    return `${input.label} should be a valid number.`
  }

  if (input.type === AUTOMATION_VARIABLE_TYPES.TIME && value) {
    if (
      value.hour == undefined ||
      value.minute == undefined ||
      value.phase == undefined
    ) {
      return `${input.label} should be a valid time.`
    }
  }

  if (
    (input.type === AUTOMATION_VARIABLE_TYPES.MESSAGE ||
      input.type === AUTOMATION_VARIABLE_TYPES.FILE) &&
    value
  ) {
    if (value.file && value.file === 'size limit exceed') {
      return `File size should be less than 5 MB`
    }

    if (value.file && value.file === 'api error') {
      return `Error in uploading file please try again later`
    }

    if (value.file && value.file === 'unsupportedFile') {
      return `File type is not supported`
    }

    // TODO: WILL ADD LATER
    // if (input.isRequired) {
    //   if (value.text == undefined || value.text.trim().length === 0) {
    //     return `${input.label} is required.`
    //   }
    // }
  }

  if (input.validation && input.validation.length > 0 && !hasVariables) {
    if (value === undefined || value === null) value = ''

    const errorMessages = []
    for (const validationElement of input.validation) {
      if (!validationElement.regex) continue
      const regex = new RegExp(
        JSON.parse(validationElement.regex).source,
        JSON.parse(validationElement.regex).flags
      )

      if (!regex.test(value)) {
        errorMessages.push(validationElement.errorMessage)
      }
    }
    if (errorMessages.length) return errorMessages.join(', ')
  }

  if (
    input.type === AUTOMATION_VARIABLE_TYPES.ATTACHMENT ||
    input.type === AUTOMATION_VARIABLE_TYPES.MESSAGE
  ) {
    if (value === 'size limit exceed') {
      return `File size should be less than 5 MB`
    }

    if (value === 'api error') {
      return `Error in uploading file please try again later`
    }
  }

  return true
}
