<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <!-- START WRAPPER -->
    <div class="flex min-h-screen items-stretch justify-between">
      <!-- START LEFT COLUMN -->
      <OnboardingLeftColumn />
      <!-- END LEFT COLUMN -->

      <!-- START RIGHT COLUMN -->
      <div class="flex-1 bg-white dark:bg-gray-900">
        <div class="grid h-full items-start justify-center">
          <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
            <div class="flex h-full max-w-lg flex-col">
              <a href="#" title="TexAu" class="">
                <CompanyLogo />
              </a>

              <div class="mt-12 grid h-full place-items-center">
                <div>
                  <div class="text-center">
                    <Heading text="Confirm Your Location" />
                    <p class="mt-4 text-base text-gray-600 dark:text-gray-400">
                      TexAu will now assign a proxy close to your actual
                      location to run safely in the cloud, please confirm your
                      location:
                    </p>
                  </div>

                  <ValidationForm
                    class="mt-4"
                    :platformInputs="platformInputs"
                    :previousNodes="[]"
                    :inputs="inputData"
                    :triggerValidation="triggerValidation"
                    @validationSuccess="submitCreate"
                    @validationFailed="triggerValidation = false"
                  />
                  <Button
                    class="mt-1"
                    :show-loader="isLoading"
                    @click="triggerValidation = true"
                    text="Confirm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END RIGHT COLUMN -->
    </div>
    <!-- END WRAPPER -->
    <ToastContainer class="w-1/5" ref="toast" />
  </div>
</template>

<script>
import { updateUserDetail } from '@/apis/user'
import { updateOrganisationDetails } from '@/apis/workspace'
import UserProfile from '@/common/userWrapper'
import Button from '@/components/Button.vue'
import Heading from '@/components/onboarding/Heading.vue'
import Select from '@/components/Select.vue'
import ToastContainer from '@/components/ToastContainer.vue'
import ValidationForm from '@/components/ValidationForm.vue'
import OnboardingLeftColumn from '../../components/onboarding/OnboardingLeftColumn.vue'
import { locations } from '@/common/constants'
import CompanyLogo from '@/components/onboarding/CompanyLogo.vue'

export default {
  // step-7
  components: {
    OnboardingLeftColumn,
    Button,
    Select,
    Heading,
    ToastContainer,
    ValidationForm,
    CompanyLogo
  },
  name: 'updateLocation',
  data() {
    return {
      isLoading: false,
      platformInputs: [
        {
          name: 'location',
          type: 'select',
          label: `Location`,
          isRequired: true,
          includeSearch: true,
          choices: locations
        }
      ],
      triggerValidation: false,

      colorMode: ''
    }
  },
  created() {},
  methods: {
    updateOnboardingStatusInCookies() {
      let user_ = UserProfile.getUser()
      user_['isOnboarded'] = true
      UserProfile.setUser(user_)
    },

    deleteOnboardingStepInCookies() {
      let user_ = UserProfile.getUser()
      delete user_['currentOnboardingStep']
      UserProfile.setUser(user_)
    },

    async handleNextStep() {
      const response = await updateUserDetail({
        isOnboarded: true,
        currentOnboardingStep: null
      })
      this.updateOnboardingStatusInCookies()
      this.deleteOnboardingStepInCookies()
      this.isLoading = false
      if (response['success']) {
        await this.$router.go()
      } else {
        this.$refs.toast.addToast({
          timeout: 7000,
          text: 'Error Occurred!',
          color: 'red',
          caption: 'Error in moving to next step.',
          action: false,
          close: true
        })
      }
    },

    async submitCreate(input) {
      this.triggerValidation = false
      this.isLoading = true
      const organisationId = window.$cookies.get('organisationId')
      const response = await updateOrganisationDetails(organisationId, {
        place: input.location
      })
      if (response['success']) {
        await this.handleNextStep()
      } else {
        this.$refs.toast.addToast({
          timeout: 7000,
          text: 'Error Occurred!',
          color: 'red',
          caption: response.message,
          action: false,
          close: true
        })
      }
    }
  },
  created() {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      console.log('dark')
      this.colorMode = 'dark'
    } else {
      console.log('light')
      this.colorMode = 'light'
    }
  }
}
</script>

<style></style>
