<template>
  <div class="mt-4 px-6">
    <label
      for=""
      class="block text-sm font-medium text-gray-900 dark:text-gray-50"
    >
      Search Operation
    </label>

    <div class="relative mt-1.5 text-gray-400 focus-within:text-gray-600">
      <div class="absolute inset-y-0 left-0 flex items-center pl-3">
        <SvgIcon name="search" />
      </div>

      <input
        type="text"
        name=""
        id=""
        class="block w-full rounded-lg border border-gray-300 py-2.5 pl-10 pr-3 text-gray-900 placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
        placeholder="Search"
        @input="searchOperations"
        @keyup.stop
      />
    </div>
    <AutomationGrid
      :list="operations"
      @item-selected="a => $emit('item-selected', a)"
    />
  </div>
</template>

<script setup>
import SvgIcon from '@/components/SvgIcon.vue'
import { ref, watch } from 'vue'
import { utilities } from '../utilities'
import AutomationGrid from './automationGrid.vue'

const props = defineProps({
  options: {}
})

defineEmits(['item-selected'])

const operations = ref(Object.values(utilities(props.options)))

const setOperations = () => {
  operations.value = Object.values(utilities(props.options))
}

watch(() => JSON.stringify(props.options), setOperations)

const searchOperations = q => {
  operations.value = Object.values(utilities(props.options)).filter(utility =>
    utility.name.toLowerCase().includes(q.target.value)
  )
}
</script>
