const getValueFromPath = (theObject, key) => {
  if (key && key.includes('.')) {
    return key.split('.').reduce((data, key) => {
      if (data) {
        // check if key has array selector like key[0]
        if (key.includes('[') && key.includes(']')) {
          const regex = /([A-z0-9]*?)\[([0-9])\]/gi
          let result
          // while loop to check multiple indexes in the key
          while ((result = regex.exec(key.trim()))) {
            if (result[1]) {
              if (data[result[1]]) {
                data = data[result[1]][result[2]]
              } else {
                return undefined
              }
            } else {
              data = data[result[2]]
            }
          }
          return data
        }
        return data[key]
      }
    }, theObject)
  }
  return theObject[key]
}

export { getValueFromPath }
