<template>
  <div>
    <label v-if="label" for="" :class="labelClass" class="flex">
      {{ label }} {{ isRequired === false ? '(Optional)' : null }}
      <SvgIcon
        class="ml-1 mb-3 h-2 w-2 text-red-600"
        name="star"
        v-if="isRequired"
      />
    </label>

    <div class="mt-2 ml-2">
      <ValidationForm
        :key="JSON.stringify(inputs)"
        :platformInputs="inputFields"
        :previousNodes="previousNodes"
        :inputs="inputs"
        class="grid-cols-1"
        @input-delete="removeDynamicField"
        @input-update="inputUpdate"
        @label-update="labelUpdate"
      />
      <Button
        v-if="!predefinedFields"
        :text="`Add ${label}`"
        size="small"
        leftIcon="plus"
        color="tertiary"
        @click="addDynamicField"
      />
    </div>
    <p v-if="description" class="mt-2 text-sm text-gray-500">
      {{ description }}<slot></slot>
    </p>
  </div>
</template>

<script setup>
import { AUTOMATION_VARIABLE_TYPES } from '@/common/constants'
import { nanoid } from 'nanoid'
import { computed, nextTick, onMounted, ref } from 'vue'
import Button from './Button.vue'
import SvgIcon from './SvgIcon.vue'
import ValidationForm from './ValidationForm.vue'

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  labelClass: {
    type: String
  },
  name: {
    type: String,
    required: true
  },
  previousNodes: {
    type: Array,
    default: []
  },
  isRequired: {
    type: { type: Boolean, default: undefined }
  },
  modelValue: {
    type: Object,
    required: true
  },
  predefinedFields: {
    type: Array
  },
  description: String
})

const dynamicInputs = ref([])
const inputs = ref(props.modelValue)
const emit = defineEmits(['update:modelValue', 'blur'])

const inputFields = computed(() => {
  if (props.predefinedFields) {
    return props.predefinedFields
  } else {
    return dynamicInputs.value
  }
})

onMounted(() => {
  dynamicInputs.value = Object.keys(props.modelValue).map(key => ({
    _id: nanoid(),
    name: key,
    label: key,
    type: AUTOMATION_VARIABLE_TYPES.TEXT,
    isLabelEditable: true,
    isDeletable: true
  }))
})

const addDynamicField = () => {
  const uniqueId = nanoid()
  dynamicInputs.value.push({
    _id: uniqueId,
    name: uniqueId,
    label: 'New Field',
    type: AUTOMATION_VARIABLE_TYPES.TEXT,
    isLabelEditable: true,
    isDeletable: true
  })
}

const removeDynamicField = removedInput => {
  dynamicInputs.value = dynamicInputs.value.filter(
    input => input._id !== removedInput._id
  )
}

const inputUpdate = values => {
  emit('update:modelValue', values)
  emit('blur', values)
}

const labelUpdate = (newLabel, updatedInput, values) => {
  if (newLabel !== updatedInput.name) {
    dynamicInputs.value = dynamicInputs.value.map(input => {
      if (input._id === updatedInput._id) {
        return {
          ...input,
          label: newLabel,
          name: newLabel
        }
      }
      return input
    })

    nextTick(() => {
      inputs.value = {
        ...values,
        [newLabel]: values[updatedInput.name]
      }
      delete inputs.value[updatedInput.name]
      inputUpdate(inputs.value)
    })
  }
}
</script>
