<template>
  <div class="mt-8">
    <p class="text-xl font-bold text-gray-900 dark:text-gray-50">Recommended workflows for you</p>
    <div class="mt-6 grid grid-cols-3 gap-6">
      <WorkflowCard
          v-for="workflow in workflows"
          :key="workflow.id"
          v-bind="workflow"
      />
    </div>
  </div>
</template>

<script>
import WorkflowCard from '@/components/automationMatrix/WorkflowCard.vue'
import Button from '../Button.vue'

export default {
  name: 'RecommendedWorkflows',
  components: {
    WorkflowCard,
    Button,
  },
  props: {
    workflows: {
      type: Array,
      required: true
    }
  }
}
</script>
