<template>
  <!-- START WRAPPER -->
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto max-h-[90vh] w-full overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="">
          <div class="flex items-center justify-between">
            <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
              Rate Limits
            </p>

            <div class="flex items-center space-x-4">
              <div class="w-[250px]" v-if="!loader">
                <Input
                  leftIcon="search"
                  text="Search Automation"
                  v-model="input"
                />
              </div>
              <div class="w-[250px]" v-if="platformNames">
                <Select
                  :leftImageUrl="platformIcon"
                  text="Select platform"
                  :options="platformOptions"
                  v-model="selectedPlatformId"
                  @change="getAllPlatformOperations()"
                  :key="platformNames"
                  :hideClear="true"
                />
              </div>

              <Button
                @click="emitClose"
                icon="close-1"
                class="!p-1.5"
                size="large"
                color="tertiary"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="max-h-[90vh] min-h-[50vh] overflow-y-scroll pt-4 pb-20">
        <div
          v-if="loader"
          class="flex min-h-[40vh] items-center justify-center"
        >
          <Spinner class="" size="large" />
        </div>
        <div v-if="!loader" class="overflow-hidden">
          <table class="min-w-full">
            <thead class="bg-gray-50 dark:bg-gray-800">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  Platform
                </th>

                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  Name
                </th>

                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  Daily Limit (default)
                </th>

                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  Hourly Limit (default)
                </th>

                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  Daily Limit (user)
                </th>

                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  Hourly Limit (user)
                </th>

                <th
                  scope="col"
                  class="px-6 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  Edit
                </th>
              </tr>
            </thead>

            <tbody
              class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
            >
              <tr v-for="(item, index) in filterRateLimitData()" :key="index">
                <td class="whitespace-nowrap px-6 py-4">
                  <img
                    class="h-8 w-8 shrink-0 rounded-full object-cover"
                    :src="platformIcon"
                    alt=""
                  />
                </td>

                <td
                  class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  {{ item.name }}
                </td>

                <td
                  class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  <div class="ml-3">
                    {{
                      item.executionLimit[0].interval === 'daily'
                        ? item.executionLimit[0].limit === 0
                          ? 'N/A'
                          : item.executionLimit[0].limit
                        : item.executionLimit[1].limit === 0
                        ? 'N/A'
                        : item.executionLimit[1].limit
                    }}
                  </div>
                </td>

                <td
                  class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  <div class="ml-3">
                    {{
                      item.executionLimit[0].interval === 'hourly'
                        ? item.executionLimit[0].limit === 0
                          ? 'N/A'
                          : item.executionLimit[0].limit
                        : item.executionLimit[1].limit === 0
                        ? 'N/A'
                        : item.executionLimit[1].limit
                    }}
                  </div>
                </td>

                <td
                  class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  <div v-if="!item.isEditing" class="ml-3">
                    {{
                      item.limitsUser[0].interval === 'daily'
                        ? item.limitsUser[0].limit === 0 ||
                          item.limitsUser[0].limit === '0'
                          ? 'N/A'
                          : item.limitsUser[0].limit
                        : item.limitsUser[1].limit === 0 ||
                          item.limitsUser[1].limit === '0'
                        ? 'N/A'
                        : item.limitsUser[1].limit
                    }}
                  </div>
                  <Input
                    :min="0"
                    v-if="item.isEditing"
                    class="w-fit [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    type="number"
                    :text="
                      item.limitsUser[0].interval === 'daily'
                        ? item.limitsUser[0].limit
                        : item.limitsUser[1].limit
                    "
                    v-model="item.newDailyLimit"
                  />
                </td>

                <td
                  class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  <div v-if="!item.isEditing" class="ml-3">
                    {{
                      item.limitsUser[1].interval === 'hourly'
                        ? item.limitsUser[1].limit === 0 ||
                          item.limitsUser[1].limit === '0'
                          ? 'N/A'
                          : item.limitsUser[1].limit
                        : item.limitsUser[0].limit === 0 ||
                          item.limitsUser[0].limit === '0'
                        ? 'N/A'
                        : item.limitsUser[0].limit
                    }}
                  </div>
                  <Input
                    v-if="item.isEditing"
                    class="w-fit"
                    type="number"
                    min="0"
                    :text="
                      item.limitsUser[1].interval === 'hourly'
                        ? item.limitsUser[1].limit
                        : item.limitsUser[0].limit
                    "
                    v-model="item.newHourlyLimit"
                  />
                </td>

                <td class="whitespace-nowrap px-6 py-4">
                  <div class="flex space-x-3">
                    <Button
                      v-if="!item.isEditing"
                      text="Edit"
                      color="secondary"
                      @click="item.isEditing = true"
                    />

                    <Button
                      v-if="item.isEditing"
                      icon="checkInSquare"
                      color="iconOnly"
                      @click="
                        ;(item.isEditing = false),
                          onSaveClick(
                            item.platformOperationId,
                            item.limitsUser,
                            item.newHourlyLimit,
                            item.newDailyLimit,
                            index
                          )
                      "
                      class="-m-2 rounded-lg p-2 text-green-600 transition-all duration-200 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:text-gray-50"
                      :class="[
                        { 'bg-green-100 text-gray-900': item.isEditing }
                      ]"
                    />
                    <Button
                      v-if="item.isEditing"
                      icon="close-1"
                      color="iconOnly"
                      @click="
                        () => {
                          item.isEditing = false
                        }
                      "
                      class="-m-2 rounded-lg p-2 text-red-600 transition-all duration-200 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:text-gray-50"
                      :class="[{ 'bg-red-100 text-gray-900': item.isEditing }]"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Input from '@/components/Input.vue'
import {
  getPlatformInfo,
  getPlatformOperations
} from '@/apis/automation-store/Page1'
import Select from '@/components/Select.vue'
import { UpdateLimits } from '@/apis/workspace'
import Spinner from '@/components/Spinner.vue'
import {
  getConnectedAccountsUi,
  getAllConnectedAccounts
} from '@/apis/connectedAccounts'

export default {
  name: 'rateLimits',
  data() {
    return {
      selectedPlatformId: null,
      platformOptions: null,
      platformName: null,
      platformIcon: null,
      rateLimitData: null,
      savedLimitsData: this.limitsData,
      loader: true,
      input: ''
    }
  },
  props: {
    platformId: {
      type: Array,
      required: true
    },
    platformNames: {
      type: Array,
      required: true
    },
    platformIcons: {
      type: Array,
      required: true
    },
    accountId: {
      type: String,
      required: true
    },
    platformToIdMap: {
      type: String,
      required: true
    },
    limitsData: {
      type: Array
    }
  },
  components: {
    Button,
    SvgIcon,
    Input,
    Spinner,
    Select
  },
  async beforeMount() {
    this.platformOptions = this.platformNames.map((val, index) => {
      return {
        label: val,
        value: this.platformId[index],
        img: this.platformIcons[index]
      }
    })
    this.selectedPlatformId = this.platformOptions[0].value

    await this.getAllPlatformOperations()
  },
  methods: {
    async getAllPlatformOperations() {
      this.loader = true

      const id = this.platformOptions.find(
        item => item.value === this.selectedPlatformId
      )

      this.platformIcon = id?.img

      const response = await getPlatformOperations(
        this.selectedPlatformId,
        0,
        50
      )
      let total = response.total

      if (response['success']) {
        this.rateLimitData = response.data.map(v => ({
          ...v,
          isEditing: false,
          limitsUser: [],
          newDailyLimit: null,
          newhourlyLimit: null
        }))
        let start = 50
        while (total != this.rateLimitData.length) {
          const response = await getPlatformOperations(
            this.selectedPlatformId,
            start,
            50
          )
          let arr = response.data.map(v => ({
            ...v,
            isEditing: false,
            limitsUser: [],
            newDailyLimit: null,
            newhourlyLimit: null
          }))
          this.rateLimitData.push(...arr)
          start += 50
        }

        let limitDataSelected = this.savedLimitsData.limitsData.find(
          item => item.platform === this.selectedPlatformId
        )

        for (const i of this.rateLimitData) {
          let newArr = this.limitsFiller(i.executionLimit)
          i.executionLimit = newArr

          if (limitDataSelected?.limits.hasOwnProperty(i.platformOperationId)) {
            i.limitsUser = limitDataSelected.limits[i.platformOperationId]
          }

          let newLimitsArr = this.limitsFiller(i.limitsUser)
          i.limitsUser = newLimitsArr
        }
        this.loader = false
      }
    },
    filterRateLimitData() {
      return this.rateLimitData.filter(item =>
        item.name.toLowerCase().includes(this.input.toLowerCase())
      )
    },
    limitsFiller(arr) {
      if (arr.length === 0) {
        arr.push(
          {
            interval: 'hourly',
            limit: 0
          },
          {
            interval: 'daily',
            limit: 0
          }
        )
      } else if (arr.length === 1) {
        if (arr[0].interval === 'daily') {
          arr.push({
            interval: 'hourly',
            limit: 0
          })
        } else {
          arr.push({
            interval: 'daily',
            limit: 0
          })
        }
      } else {
        return arr
      }
      return arr
    },

    async onSaveClick(
      platformOperationId,
      limits,
      newHourlyLimit,
      newDailyLimit,
      index
    ) {
      let prevDailyLimit =
        limits[0].interval === 'daily' ? limits[0].limit : limits[1].limit
      let prevHourlyLimit =
        limits[1].interval === 'hourly' ? limits[1].limit : limits[0].limit

      let data = null

      if (
        newDailyLimit &&
        newDailyLimit != prevDailyLimit &&
        newHourlyLimit &&
        newHourlyLimit != prevHourlyLimit
      ) {
        data = [
          {
            limit: newDailyLimit,
            interval: 'daily'
          },
          {
            limit: newHourlyLimit,
            interval: 'hourly'
          }
        ]
      } else if (newDailyLimit && newDailyLimit != prevDailyLimit) {
        data = [
          {
            limit: newDailyLimit,
            interval: 'daily'
          }
        ]
        if (prevHourlyLimit != 0) {
          data.push({
            limit: prevHourlyLimit,
            interval: 'hourly'
          })
        }
      } else if (newHourlyLimit && newHourlyLimit != prevHourlyLimit) {
        data = [
          {
            limit: newHourlyLimit,
            interval: 'hourly'
          }
        ]
        if (prevDailyLimit != 0) {
          data.push({
            limit: prevDailyLimit,
            interval: 'daily'
          })
        }
      }

      try {
        const response = await UpdateLimits(
          this.platformToIdMap[this.selectedPlatformId],
          data,
          platformOperationId
        )
        if (response['success']) {
          for (const data of this.savedLimitsData.limitsData) {
            if (data.platform === this.selectedPlatformId) {
              data.limits[platformOperationId] =
                response.data.limits[platformOperationId]
            }
          }
          //for first time only since there are no limits data present in accounts
          if (this.limitsData.limitsData.length === 0) {
            const accountResponse = await getConnectedAccountsUi(0, 20)
            const Limitaccount = accountResponse.data.find(
              item => item.id === this.limitsData.data.accountId
            )
            const objectList = Object.entries(Limitaccount.limits)
            const temp = []
            for (const objectListElement of objectList) {
              temp.push({
                platform: objectListElement[0],
                limits: objectListElement[1]
              })
            }
            this.savedLimitsData.limitsData = temp
          }

          this.getAllPlatformOperations()
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }

      this.$emit('callGetAllConnectAccount')
    },

    emitClose() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped></style>
