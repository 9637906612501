<template>
  <div>
    <div
      v-if="automationOutput === null"
      class="flex h-full items-center justify-center"
    >
      <Spinner size="large" />
    </div>

    <AutomationOutputTable
      v-else-if="automationOutput && selectedNode"
      :columnsData="automationColumns"
      :outputData="automationOutput"
      :nodeId="selectedNode._id"
      :title="automationTitle"
      :total="automationTotal"
      :filtersOptions="filterOptions"
      :isWorkflow="isWorkflow"
      :workflowToggleOptions="workflowToggleOptions"
      :executionToggleOptions="executionToggleOptions"
      :selectedNode="selectedNode"
      :selectedExecutionNode="selectedExecutionNode"
      :showLoadingText="showLoadingText"
      :executionDropdownDisable="getExecutionDropdownDisable()"
      :utilityType="selectedNode.utilityType"
      @nodeChange="e => emit('nodeChange', e)"
      @nodeExecutionChange="e => emit('nodeExecutionChange', e)"
      @loadNextBatch="loadNextBatch"
      @refetch="fetchData(0, $event)"
      @success="e => emit('success', e)"
    />
  </div>
</template>
<script setup>
import { fetchOutputForTable } from '@/common/functions/fetchOutputForTable'
import { getWorkflowExecution } from '@/apis/workflows'
import AutomationOutputTable from '@/components/AutomationOutputTable.vue'
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Spinner from '../Spinner.vue'

const props = defineProps({
  selectedNode: {},
  isWorkflow: {
    type: Boolean,
    default: false
  },
  workflowToggleOptions: {
    type: Array
  },
  executionToggleOptions: {
    type: Array
  },
  selectedExecutionNode: {},
  fetchNewExecutionData: {}
})

const router = useRouter()
const route = useRoute()

const automationOutput = ref(null)
const automationColumns = ref(null)
const filterOptions = ref(null)
const automationTitle = ref(null)
const automationLogo = ref(null)
const automationTotal = ref(null)
const showLoadingText = ref(false)

const emit = defineEmits([
  'error',
  'success',
  'warning',
  'title',
  'nodeChange',
  'nodeExecutionChange'
])

onMounted(async () => {
  automationOutput.value = null
  await fetchOutputForNode(props.selectedNode)
  //start polling
  fetchPollingData()
})

watch(
  () => props.selectedNode,
  n => {
    onSelectedNodeChange(n)
  }
)

watch(
  () => props.fetchNewExecutionData,
  n => {
    fetchOutputForExecutionNode(props.selectedExecutionNode)
  }
)

const onSelectedNodeChange = async n => {
  await fetchOutputForNode(n)
  //start polling again when nodes are changed
  fetchPollingData()
}

const fetchPollingData = async () => {
  try {
    let executionObj
    //get the status of selectedExecutionNode execution
    const response = await getWorkflowExecution(route.query.workflowId)

    if (props.selectedExecutionNode === 'all') {
      executionObj = response.data.find(item => item.status === 'running')
    } else {
      executionObj = response.data.find(
        item => item._id === props.selectedExecutionNode._id
      )
    }
    if (executionObj?.status && executionObj.status === 'running') {
      showLoadingText.value = true
      let all = props.selectedExecutionNode === 'all' ? 1 : 0
      const { output } = await fetchOutputForTable(undefined, undefined, all)
      showLoadingText.value = false

      //replace the old results
      automationOutput.value = output

      //call the function again after 5 sec
      setTimeout(fetchPollingData, 5000)
    } else if (executionObj?.status === 'failed') {
      emit('error', executionObj.statusReason)
    } else {
      return
    }
  } catch (error) {
    console.log(error)
  }
}

const fetchOutputForNode = async (selectedNode, start) => {
  try {
    //replace query with new data
    automationOutput.value = null
    await router.push({
      query: {
        nodeId: selectedNode._id,
        executionId: route.query.executionId,
        workflowId: route.query.workflowId,
        platformId: selectedNode.platformId,
        operationId: selectedNode.platformOperationId,
        outputMode: route.query.outputMode
      },
      replace: true
    })

    await fetchData(start)
  } catch (error) {
    console.log(error)
  }
}

const fetchOutputForExecutionNode = async (selectedNode, start) => {
  //replace query with new data
  automationOutput.value = null
  await router.push({
    query: {
      nodeId: route.query.nodeId,
      executionId:
        selectedNode === 'all' ? route.query.executionId : selectedNode._id,
      workflowId: route.query.workflowId,
      platformId: route.query.platformId,
      operationId: route.query.operationId,
      outputMode: route.query.outputMode
    },
    replace: true
  })

  await fetchData(start)
}

const fetchData = async (start, manipulationConfig) => {
  try {
    let all = props.selectedExecutionNode === 'all' ? 1 : 0

    const { logo, title, columns, output, total, filtersOptions } =
      await fetchOutputForTable(
        start,
        manipulationConfig,
        all,
        props.selectedNode?.utilityType
      )
    automationTitle.value = title
    automationLogo.value = logo
    automationColumns.value = columns
    automationTotal.value = total
    filterOptions.value = filtersOptions
    output.length === 0 && emit('error', 'No Result Found')
    automationOutput.value = output
    emit('title', title)
    emit('logo', logo)
  } catch (error) {
    console.log(error)
    if (error.message === 'canceled') return
    emit('error', 'Unable to fetch automation result')
  }
}

const loadNextBatch = async data => {
  await fetchData(data)
  let div = document.getElementsByClassName('p-datatable-wrapper')[0]
  div.scrollTop = 0
}

const getExecutionDropdownDisable = () => {
  if (route.query.outputMode === 'overwrite') {
    return true
  } else {
    return false
  }
}
</script>
