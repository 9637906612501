import axios from '@/common/axios'

const getAllProxy = async (start = 0, limit = 50) => {
  try {
    const response = await axios.get(`/proxies?start=${start}&limit=${limit}`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const testProxy = async body => {
  try {
    const response = await axios.post(`/proxies/test`, body)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const buyProxy = async () => {
  try {
    const response = await axios.post(`/proxies/buy`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const addNewProxy = async body => {
  try {
    const response = await axios.post(`/proxies`, body)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const deleteProxyById = async proxyId => {
  try {
    const response = await axios.delete(`/proxies/${proxyId}`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const forceDeleteProxyById = async proxyId => {
  try {
    const response = await axios.delete(`/proxies/${proxyId}/force`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const deleteProxyByAccountId = async (accountId, isDesktop = '') => {
  try {
    const response = await axios.delete(
      `/accounts/${accountId}/proxies?removeFor=${isDesktop}`
    )
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const updateProxy = async (proxyId, data) => {
  try {
    const response = await axios.put(`/proxies/${proxyId}`, data)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const getProxyById = async proxyId => {
  try {
    const response = await axios.get(`/proxies/${proxyId}`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const setAccountProxy = async (accountId, proxyId, type) => {
  try {
    const response = await axios.post(`/accounts/${accountId}/proxies`, {
      [type]: proxyId
    })
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const cancelProxy = async () => {
  try {
    const response = await axios.post(`/proxies/cancel`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const refreshProxy = async proxyId => {
  try {
    const response = await axios.get(`/proxies/${proxyId}/reset-location`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

export {
  getAllProxy,
  testProxy,
  addNewProxy,
  deleteProxyById,
  forceDeleteProxyById,
  deleteProxyByAccountId,
  updateProxy,
  getProxyById,
  setAccountProxy,
  buyProxy,
  cancelProxy,
  refreshProxy
}
