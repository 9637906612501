<template>
  <div
    class="absolute top-0 left-0 z-10 h-screen w-screen"
    @click="$emit('backdrop-click')"
  ></div>
</template>

<script>
export default {
  name: 'Backdrop'
}
</script>
