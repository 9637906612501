import axios from "@/common/axios";

const startOauthConnection = async (appName, code = null, state = null) => {
    try {
        const url = code && state ? `/accounts/${appName}/oauth?state=${state}&code=${code}` : `/accounts/${appName}/oauth`
        const response = await axios.get(url);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

export {
    startOauthConnection,
}

