<template>
  <div v-if="tabs.length > 0" class="dropdown-border bg-white px-4">
    <Tabs
      :tabs="tabs"
      :selectedTab="selectedTab"
      color="primary"
      @tab-selected="selectedTab = $event"
      class="fixed h-10 overflow-hidden bg-white"
    />
    <div class="mt-10 max-h-64 min-w-[600px] overflow-auto">
      <OutputTab
        :previousNodes="previousNodes"
        :name="name"
        @variable-selected="
          (nodeId, output) => $emit('variable-selected', nodeId, output)
        "
      />
    </div>
  </div>
</template>

<style>
.dropdown-border {
  border: 0.5px solid #bec0c5;
  /* Drop Shadow - 5% */

  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
}
</style>

<script setup>
import Tabs from '@/components/Tabs.vue'
import { computed, ref } from 'vue'
import FunctionTab from './functionTab.vue'
import OutputTab from './outputTab.vue'
import TagsTab from './tagsTab.vue'
import { useStore } from 'vuex'

const store = useStore()
const devStatus = computed(() => store._state.data.user.userData.developer)

const props = defineProps({
  previousNodes: {
    type: Array,
    required: true
  },
  personalisedTags: {
    type: Array,
    required: false
  },
  name: String
})
const emits = defineEmits([
  'variable-selected',
  'function-selected',
  'tag-selected'
])
const tabs = ref([
  {
    text: 'Inputs',
    selectedClass: 'border-blue-500 text-blue-500'
  }
])

if (!devStatus.value) {
  tabs.value = tabs.value.filter(val => val.text !== 'Functions')
}

const selectedTab = ref(0)
</script>
