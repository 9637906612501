<template>
  <AutomationHeader
    :title="
      platformData?.metaData?.name ||
      platformData?.platformVariables?.name ||
      null
    "
    :iconUrl="platformData?.metaData?.logo || platformData?.platform?.logoUrl"
  />
  <section
    class="flex h-full flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <div
      v-if="pageLoading"
      class="flex items-center justify-center h-full w-full"
    >
      <Spinner size="large" />
    </div>
    <AutomationInputPicker
      v-else
      :loading="loading"
      :platformOperationId="platformOperationId"
    />
  </section>
</template>

<script>
import AutomationCards from '../../components/automationStore/AutomationCards.vue'
import Categories from '@/components/automationStore/Categories.vue'
import Spinner from '@/components/Spinner.vue'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import AutomationSelector from '@/components/automationStore/AutomationSelector.vue'
import AutomationInputPicker from '@/components/automationStore/AutomationInputPicker.vue'
import { mapActions, mapState } from 'vuex'
import {
  getPlatformVariables,
  getPlatformInfo
} from '@/apis/automation-store/Page1'

export default {
  components: {
    AutomationCards,
    Categories,
    AutomationHeader,
    AutomationSelector,
    AutomationInputPicker,
    Spinner
  },
  name: 'AutomationStorePage2',
  data() {
    return {
      platformOperationId: null,
      loading: true,
      pageLoading: true
    }
  },

  created() {
    // get the name and logo url to display on header
    this.getVariables()
  },
  computed: {
    ...mapState('automationStore', ['platformData'])
  },

  methods: {
    ...mapActions('automationStore', ['addPlatformData']),
    async getVariables() {
      try {
        const [response, platformInfoResponse] = await Promise.all([
          getPlatformVariables(this.$route.query.operationId),
          getPlatformInfo(this.$route.query.platformId)
        ])
        if (response.data.numberOfInputs === 0) {
          await this.addPlatformData({
            payload: {
              platformVariables: response.data,
              platform: platformInfoResponse.data
            }
          })
          await this.$router.replace({
            path: `/automation-store/workflow`,
            query: {
              operationId: response.data.platformOperationId,
              source: 'input'
            }
          })
        }
        this.pageLoading = false
        if (response['success']) {
          this.platformOperationId = response.data.platformOperationId

          await this.addPlatformData({
            payload: {
              platformVariables: response.data,
              platform: platformInfoResponse.data
            }
          })
        }
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style></style>
