<template>
  <!-- render component based on the input type -->
  <section v-if="canRender">
    <div class="flex items-center justify-center gap-x-2">
      <component
        :is="inputComponent"
        v-bind="input"
        :previousNodes="previousNodes"
        :platformOperationId="input.platformOperationId || null"
        :options="choices || input.choices"
        :choices="choices || input.choices"
        :isPremium="input.isPremium || false"
        :includeSearch="input.includeSearch || null"
        :allInputs="{ ...allInputs, ...extraInputs }"
        :description="input?.description"
        :attachmentSpecifications="input.attachmentSpecifications || null"
        :personalisedTags="input.personalisedTags || null"
        :validation="input.validation || null"
        :allowMapping="input.allowMapping"
        :placeholder="
          input.sample && input.sample.length > 0
            ? `e.g.: ${input.sample[0].value}`
            : undefined
        "
        v-model="value"
        :name="input?.name"
        labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
        modalClass="w-[656px]"
        @label-update="e => $emit('label-update', e)"
        @change="e => $emit('change', e)"
        @error="
          error => {
            setErrors(error)
          }
        "
        @blur="emit('input-blur')"
        @toggleOpenAiAccounts="e => emit('toggleOpenAiAccounts', e)"
        class="w-full"
      />
      <!-- Show action buttons -->

      <Button
        v-if="input.actionButtons"
        v-for="button of input.actionButtons"
        size="small"
        class="h-[42px]"
        @click="onActionButtonClick(button)"
      >
        <div class="flex items-center justify-center gap-1 text-xs">
          <svg-icon :name="button.button.icon" class="h-4 w-4" />
          {{ button.button.text }}
        </div>
      </Button>
    </div>

    <p
      class="mt-1 text-xs text-red-600"
      :class="{
        invisible: !errorMessage && !error
      }"
    >
      {{ errorMessage || error || 'errorMessage' }}
    </p>
  </section>
</template>

<script setup>
import { getOperationVariableServices } from '@/apis/getPlatformDetails'
import { runIntegration } from '@/apis/integrations'
import { AUTOMATION_VARIABLE_TYPES } from '@/common/constants'
import { getValueFromPath } from '@/common/functions/objectHelper'
import Checkboxes from '@/components/Checkboxes.vue'
import Date from '@/components/Date.vue'
import DynamicObjectInput from '@/components/DynamicObjectInput.vue'
import MultiSelect from '@/components/MultiSelect.vue'
import Select from '@/components/Select.vue'
import Time from '@/components/Time.vue'
import { validateInput } from '@/helpers/validateInputs'
import Swal from 'sweetalert2'
import { useField } from 'vee-validate'
import { computed, markRaw, ref, toRef } from 'vue'
import Button from './Button.vue'
import DynamicSelect from './DynamicSelect.vue'
import OutputCheckboxList from './OutputCheckboxList.vue'
import RichTextEditor from './RichTextEditor.vue'
import WorkflowInput from './workflowBuilder/customInput/WorkflowInput.vue'
import UploadInput from './UploadInput.vue'
import Email from './Email.vue'
import googlePicker from '@/components/googlePicker.vue'
import { useStore } from 'vuex'

const props = defineProps({
  input: {
    type: Object,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  allInputs: { type: Object, required: true },
  previousNodes: { type: Array },
  error: { type: String }
})
const store = useStore()
const choices = ref(null)
const extraInputs = ref({})

const canRender = computed(() => {
  let render = true
  props.input.hasDependencyOnFields?.forEach(dependencyInput => {
    if (props.allInputs[dependencyInput] == undefined) {
      render = false
    }
  })
  return render
})

const emit = defineEmits(['label-update', 'input-blur', 'toggleOpenAiAccounts'])

const name = toRef(props, 'name')
const { value, errorMessage, setErrors, setValue } = useField(
  name,
  value => validateInput(props.input, value),
  {}
)

// map of input type to component
const typeToComponentMap = {
  [AUTOMATION_VARIABLE_TYPES.TEXT]: markRaw(WorkflowInput),
  [AUTOMATION_VARIABLE_TYPES.MESSAGE]: markRaw(RichTextEditor),
  [AUTOMATION_VARIABLE_TYPES.BOOLEAN]: markRaw(Checkboxes),
  [AUTOMATION_VARIABLE_TYPES.SELECT]: markRaw(Select),
  [AUTOMATION_VARIABLE_TYPES.OBJECT]: markRaw(DynamicObjectInput),
  [AUTOMATION_VARIABLE_TYPES.NUMBER]: markRaw(WorkflowInput),
  [AUTOMATION_VARIABLE_TYPES.TIME]: markRaw(Time),
  [AUTOMATION_VARIABLE_TYPES.MULTI_SELECT]: markRaw(MultiSelect),
  [AUTOMATION_VARIABLE_TYPES.DYNAMIC_SELECT]: markRaw(DynamicSelect),
  [AUTOMATION_VARIABLE_TYPES.DATE]: markRaw(Date),
  [AUTOMATION_VARIABLE_TYPES.OUTPUT_CHECKBOX]: markRaw(OutputCheckboxList),
  [AUTOMATION_VARIABLE_TYPES.FILE]: markRaw(UploadInput),
  [AUTOMATION_VARIABLE_TYPES.SN_MESSAGE]: markRaw(Email),
  [AUTOMATION_VARIABLE_TYPES.GOOGLE_PICKER]: markRaw(googlePicker)
}
const inputComponent = ref(typeToComponentMap[props.input.type])

const onActionButtonClick = async actionButton => {
  if (actionButton.action === 'operation') {
    await handleOperationAction(actionButton)
  }
}

const handleOperationAction = async actionButton => {
  const abort = new AbortController()
  let inputs = {}

  // get inputs from the user
  try {
    const actionResponse = await Swal.fire({
      didOpen: async () => {
        Swal.showLoading()
        const response = await getOperationVariableServices(
          actionButton.data.platformId,
          actionButton.data.operationId,
          'input',
          null,
          abort.signal
        )
        Swal.hideLoading()
        Swal.update({
          html: `
          <form id="inputs" class="flex p-1 flex-col gap-2">
          ${response.data
            .filter(
              input =>
                (input.type === AUTOMATION_VARIABLE_TYPES.TEXT ||
                  input.type === AUTOMATION_VARIABLE_TYPES.NUMBER) &&
                props.allInputs[input.name] == undefined
            )
            .map(
              input => `
                <label
                  for=${input.name}
                  class="block text-sm  font-medium text-gray-900 dark:text-gray-50 text-start"
                >
                  ${input.label}
                </label>
                
                <input
                  id=${input.name}
                  class="input-field mt-1.5 block w-full rounded-lg border px-3 py-2.5 placeholder-gray-500 transition-all duration-200 dark:placeholder-gray-400 sm:text-sm"
                />
            `
            )
            .join('')}

          </form>
          `
        })
      },

      willClose: () => {
        abort.abort()
        inputs = Object.fromEntries(
          Array.from(
            Swal.getHtmlContainer().querySelectorAll('input.input-field')
          ).map(s => {
            return [s.id, s.value]
          })
        )
      },
      customClass: {
        confirmButton:
          'inline-flex items-center justify-center rounded-lg border font-semibold transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 border-transparent bg-blue-600  text-white  hover:bg-blue-800  focus:ring-blue-600 dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900 w-fit px-4 py-2.5 text-base sm:text-sm m-1'
      },
      buttonsStyling: false,
      confirmButtonText: 'Submit'
    })

    if (!actionResponse.isConfirmed) return

    // show loading and run integration
    await Swal.fire({
      didOpen: async () => {
        Swal.showLoading()
        try {
          const response = await runIntegration(
            {
              ...props.allInputs,
              ...inputs
            },
            actionButton.data.platformId,
            actionButton.data.operationId
          )
          const label = getValueFromPath(
            response.data,
            actionButton.data.labelPath
          )
          const value = getValueFromPath(
            response.data,
            actionButton.data.valuePath
          )
          setValue(value)
          choices.value = [
            {
              label,
              value
            }
          ]
          if (actionButton?.forType === 'googleSheet') {
            extraInputs.value.spreadSheetName = label
            store.dispatch('automationStore/addAutomationInputData', {
              payload: {
                spreadSheetName: label
              }
            })
          }

          emit('input-blur')
        } catch (error) {
          console.log(error)
        }
        Swal.hideLoading()
        Swal.close()
      },
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    })
  } catch (error) {
    console.log({ error })
  }
}
</script>
