<template>
  <div
    class="fixed left-0 top-0 z-40 flex h-screen w-screen items-center justify-center"
  >
    <div
      class="absolute left-0 top-0 h-screen w-screen bg-gray-400 bg-opacity-40"
      @click="$emit('input-modal-close')"
    />
    <div
      class="z-10 w-[720px] overflow-auto rounded-[10px] bg-white px-8 py-10"
    >
      <div class="relative mb-9">
        <nav class="flex justify-center space-x-4" aria-label="Tabs">
          <a
            href="#"
            title=""
            class="rounded-lg bg-gray-100 px-3 py-2 text-sm font-medium text-gray-700 transition-all duration-200 dark:bg-gray-700 dark:text-gray-50"
            aria-current="page"
          >
            Webhook Config
          </a>
        </nav>
        <button
          type="button"
          class="absolute right-0 top-0 h-9 w-9 rounded-lg border border-gray-200 bg-white p-1.5 text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900"
          @click="$emit('input-modal-close')"
        >
          <span class="sr-only"> Close </span>
          <SvgIcon name="close" class="m-auto" />
        </button>
      </div>
      <SvgIcon name="Loader" v-if="loadingInputs" class="m-auto" />

      <div v-else class="flex h-[500px] 3xl:h-[720px] flex-col gap-2">
        <nav class="flex justify-center space-x-4" aria-label="Tabs">
          <div
            href="#"
            title=""
            :class="getTabClass('manual')"
            aria-current="page"
            @click="tab = 'manual'"
          >
            Manual Headers
          </div>
          <div
            href="#"
            title=""
            :class="getTabClass('paste')"
            aria-current="page"
            @click="tab = 'paste'"
          >
            Paste Curl
          </div>
        </nav>
        <ValidationForm
          v-show="tab === 'manual'"
          :platformInputs="inputFields"
          :previousNodes="previousNodes"
          :inputs="webhook"
          :triggerValidation="triggerValidation"
          @validationSuccess="saveNodeInputs"
          @validationFailed="triggerValidation = false"
          class="grid-cols-1 px-4"
          @input-update="inputUpdate"
        />
        <div v-show="tab === 'paste'" class="flex flex-col gap-4 pb-4">
          <h2>Paste your curl or axios request below:</h2>
          <TextArea
            @input="parseCommand"
            v-model="inputCommand"
            placeholder="Paste your curl or axios request here"
            rows="6"
          ></TextArea>

          <Button
            class="w-[100px]"
            color="secondary"
            text="Reset"
            left-icon="reset"
            size="small"
            @click="resetModal"
          />

          <div v-if="method">
            <h3>Request Method</h3>
            <div class="flex flex-col gap-2">
              <Select
                v-model="method"
                :options="[
                  {
                    value: 'get',
                    label: 'Get'
                  },
                  { value: 'delete', label: 'Delete' },
                  { value: 'head', label: 'Head' },
                  { value: 'options', label: 'Options' },
                  { value: 'post', label: 'Post' },
                  { value: 'put', label: 'Put' },
                  { value: 'patch', label: 'Patch' },
                  { value: 'purge', label: 'Purge' },
                  { value: 'link', label: 'Link' },
                  { value: 'unlink', label: 'Unlink' }
                ]"
              />
              <div>
                <h3>URL</h3>
                <Input v-model="url" text="Request URL" readonly />
              </div>
            </div>
          </div>

          <div v-if="Object.keys(headers).length || showHeaderBox">
            <label>Headers</label>
            <p
              v-if="headerJsonError"
              class="text-sm text-red-500 font-semibold"
            >
              Invalid JSON format. Please ensure all keys and values are
              properly quoted, and commas and braces are correctly placed.
            </p>
            <WorkflowInput
              class="border-red-500"
              description="Please ensure all keys and values are enclosed in double quotes to maintain valid JSON format."
              v-model="formattedHeaders"
              :previousNodes="previousNodes"
              type="message"
              :defaultMultipleInputs="true"
            />
          </div>

          <div v-if="body">
            <label>Body</label>
            <p v-if="bodyJsonError" class="text-sm text-red-500 font-semibold">
              Invalid JSON format. Please ensure all keys and values are
              properly quoted, and commas and braces are correctly placed.
            </p>
            <WorkflowInput
              description="Please ensure all keys and values are enclosed in double quotes to maintain valid JSON format."
              v-model="formattedBody"
              :previousNodes="previousNodes"
              type="message"
              :defaultMultipleInputs="true"
            />
          </div>

          <div class="flex flex-col gap-2">
            <h3>Rate Limit (optional)</h3>
            <Input
              label-class="flex text-sm font-medium text-gray-900 dark:text-gray-50"
              label="Limit"
              v-model="pasteLimits.limit"
            />
            <Select
              label="Interval"
              v-model="pasteLimits.interval"
              :options="[
                {
                  label: 'Daily',
                  value: 'daily'
                },
                {
                  label: 'Hourly',
                  value: 'hourly'
                }
              ]"
            />
          </div>

          <div class="mt-2">
            <Button @click="submit" :show-loader="isSubmitLoading"
              >Submit</Button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getWorkflowNode, updateWorkflowNode } from '@/apis/workflows'
import SvgIcon from '@/components/SvgIcon.vue'
import ValidationForm from '@/components/ValidationForm.vue'
import { onMounted, onUnmounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { getAllPreviousNodes } from '../../helper'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import TextArea from '@/components/TextArea.vue'
import Switch from '@/components/Switch.vue'
import Select from '@/components/Select.vue'
import WorkflowInput from '@/components/workflowBuilder/customInput/WorkflowInput.vue'
const props = defineProps({
  selectedNode: {
    type: Object
  },
  nodes: {
    type: Object
  }
})

const previousNodes = ref([])
const emptyWebhook = {
  url: '',
  data: {},
  method: '',
  headers: {},
  rateLimits: {}
}
const webhook = ref(emptyWebhook)
const loadingInputs = ref(true)
const triggerValidation = ref(false)
let updatedInputs = webhook
const tab = ref('manual')
const showHeaderBox = ref(false)
const inputFields = ref([
  {
    _id: 1,
    name: 'url',
    label: 'Webhook URL',
    isRequired: true,
    type: 'text'
  },
  {
    _id: 2,
    name: 'method',
    label: 'Request Method',
    isRequired: true,
    type: 'select',
    choices: [
      {
        value: 'get',
        label: 'Get'
      },
      { value: 'delete', label: 'Delete' },
      { value: 'head', label: 'Head' },
      { value: 'options', label: 'Options' },
      { value: 'post', label: 'Post' },
      { value: 'put', label: 'Put' },
      { value: 'patch', label: 'Patch' },
      { value: 'purge', label: 'Purge' },
      { value: 'link', label: 'Link' },
      { value: 'unlink', label: 'Unlink' }
    ]
  },
  {
    _id: 3,
    name: 'headers',
    label: 'Headers',
    isRequired: false,
    type: 'object'
  },
  {
    _id: 4,
    name: 'data',
    label: 'Data',
    isRequired: true,
    type: 'object'
  },
  {
    _id: 5,
    name: 'rateLimits',
    label: 'Rate limits',
    isRequired: false,
    type: 'object',
    predefinedFields: [
      {
        name: 'limit',
        label: 'Limit',
        type: 'text'
      },
      {
        name: 'interval',
        label: 'Interval',
        type: 'select',
        choices: [
          {
            label: 'Daily',
            value: 'daily'
          },
          {
            label: 'Hourly',
            value: 'hourly'
          }
        ]
      }
    ]
  }
])
const inputCommand = ref('')
const bodyKeys = ref([])
const url = ref('')
const emit = defineEmits(['error', 'clear-configure-message'])
const route = useRoute()
const method = ref('') // Stores the request method
const headers = ref({}) // Stores headers as an object
const body = ref('') // Stores the request body
const bodyJsonError = ref(false)
const headerJsonError = ref(false)
const pasteLimits = ref({
  limit: null,
  interval: null
})
const isSubmitLoading = ref(false)

const resetModal = () => {
  showHeaderBox.value = false
  body.value = ''
  headers.value = {}
  url.value = ''
  pasteLimits.value = {
    limit: null,
    interval: null
  }
  method.value = ''
  inputCommand.value = ''
}

/**
 * A computed property that formats the headers data as a JSON string for display
 * and allows updating the headers from a JSON string input.
 * @type {import('vue').ComputedRef<object>}
 * @returns {object} - The headers data as a JavaScript object.
 */
const formattedHeaders = computed({
  get() {
    return JSON.stringify(headers.value, null, 2)
  },
  set(value) {
    try {
      const parsed = JSON.parse(value)
      Object.keys(headers.value).forEach(key => delete headers.value[key]) // Clear existing headers
      Object.assign(headers.value, parsed) // Update headers
      headerJsonError.value = false
    } catch (e) {
      headerJsonError.value = true
      console.error('Invalid JSON format for headers')
    }
  }
})

/**
 * A computed property that formats the body data as a JSON string for display
 * and allows updating the body data from a JSON string input.
 * @type {import('vue').ComputedRef<object>}
 * @returns {object} - The body data as a JavaScript object.
 */
const formattedBody = computed({
  get() {
    return JSON.stringify(body.value, null, 2)
  },
  set(val) {
    try {
      body.value = JSON.parse(val)
      bodyJsonError.value = false
    } catch (e) {
      bodyJsonError.value = true
      console.error(e)
    }
  }
})

/**
 * It fetches workflow node data based on the route parameter and updates component state accordingly.
 *
 * - If the fetched node's inputs indicate a 'manual' tab, it sets the tab to 'manual'
 *   and initializes the webhook object with the corresponding inputs.
 * - If the fetched node's inputs indicate a 'paste' tab, it updates the relevant state
 *   properties such as body, headers, URL, rate limits, HTTP method, and curl command,
 *   and sets the tab to 'paste'.
 *
 * Additionally, it retrieves all previous nodes related to the selected node.
 * In case of an error during the data fetching, it logs the error to the console.
 *
 * @async
 * @returns {Promise<void>} A promise that resolves when the data fetching is complete.
 */
onMounted(async () => {
  try {
    const [nodeResponse] = await Promise.all([
      getWorkflowNode(route.params.id, props.selectedNode.id)
    ])
    if (nodeResponse?.data?.inputs?.tab === 'manual') {
      tab.value = 'manual'
      webhook.value = {
        ...emptyWebhook,
        ...nodeResponse.data.inputs
      }
    } else if (nodeResponse?.data?.inputs?.tab === 'paste') {
      body.value = nodeResponse?.data?.inputs?.data
      headers.value = nodeResponse?.data?.inputs?.headers
      url.value = nodeResponse?.data?.inputs?.url
      pasteLimits.value = nodeResponse?.data?.inputs?.rateLimits
      method.value = nodeResponse?.data?.inputs?.method
      inputCommand.value = nodeResponse?.data?.inputs?.curl
      tab.value = 'paste'
    }
    previousNodes.value = getAllPreviousNodes(props.selectedNode, props.nodes)

    loadingInputs.value = false
  } catch (e) {
    console.log(e)
  }
})

const inputUpdate = values => {
  updatedInputs = values
}

/**
 * It handles saving the current state of the component based on the selected tab
 * and emits appropriate events.
 *
 * - If the current tab is 'manual', it updates the workflow node with the current
 *   inputs and marks it as configured.
 * - If the current tab is not 'manual', it invokes the `submit` function to
 *   handle the submission process.
 *
 * Upon successful completion, it emits a 'clear-configure-message' event with the
 * selected node's ID. If an error occurs during the process, it emits an 'error'
 * event with a relevant error message.
 *
 * @async
 * @returns {Promise<void>} A promise that resolves when the update or submit operation is complete.
 */
onUnmounted(async () => {
  try {
    emit('clear-configure-message', props.selectedNode.id)
    if (tab.value === 'manual') {
      await updateWorkflowNode(route.params.id, props.selectedNode.id, {
        inputs: { ...updatedInputs, tab: tab.value },
        isConfigured: true
      })
    } else {
      submit()
    }
  } catch (error) {
    emit('error', 'Error occurred while saving changes.')
  }
})

/**
 * Submits the form data, including method, URL, headers, body, and rate limits,
 * and updates the workflow node with the provided information.
 * @returns {Promise<void>} - A promise that resolves when the submission is complete.
 */
const submit = async () => {
  const result = {
    method: method.value,
    url: url.value,
    headers: headers.value,
    data: body.value,
    rateLimits: {
      limit: pasteLimits.value.limit,
      interval: pasteLimits.value.interval
    },
    curl: inputCommand.value,
    tab: tab.value
  }

  try {
    isSubmitLoading.value = true
    emit('clear-configure-message', props.selectedNode.id)
    await updateWorkflowNode(route.params.id, props.selectedNode.id, {
      inputs: result,
      isConfigured: true
    })
    emit('input-modal-close')
  } catch (error) {
    emit('error', 'Error occurred while saving changes.')
  } finally {
    isSubmitLoading.value = false
  }
}

/**
 * Parses the curl command from the input field and extracts relevant information,
 * including the request method, URL, headers, and body.
 * @returns {void}
 */
const parseCommand = () => {
  const command = inputCommand.value.trim()
  if (!command) return
  // Reset previous values
  headers.value = []
  bodyKeys.value = []
  url.value = ''
  method.value = 'post'

  method.value = getHttpRequest(command)

  // Extract URL from curl or axios command
  const urlMatch = command.match(/(https?:\/\/[^\s']+)/)
  if (urlMatch) {
    url.value = urlMatch[1]
  }

  let headerMatch = command.match(
    /(?:-H|--header)\s+['"]?([^:]+):\s*(['"][^'"]*['"]|\{.*\}|[^'"\s]+)/gi
  )
  let obj = {}
  if (headerMatch) {
    showHeaderBox.value = true
    headerMatch.forEach(header => {
      const [key, value] = header
        .replace(/(-H|--header)\s+/, '')
        .replace(/'/g, '')
        .split(': ')
        .map(s => s.trim())
      obj[key] = value
    })
  }
  headers.value = obj
  const bodyMatch = inputCommand.value.match(
    /(?:-d|--data|--data-raw)\s+(?:\$|--raw\s*)?(['"]?)([\s\S]*?)\1/
  )
  body.value = bodyMatch ? JSON.parse(bodyMatch[2]) : {}
}

/**
 * Extracts the HTTP request method from a given input string.
 *
 * This function searches for the HTTP method specified with either the `-X`
 * or `--request` flag in the input string. It returns the method in lowercase
 * if found; otherwise, it returns null.
 *
 * @param {string} input - The input string containing the HTTP method.
 * @returns {string|null} The extracted HTTP method in lowercase or null if not found.
 */
const getHttpRequest = input => {
  const regexArray = [
    /-X\s+['"]?(\w+)['"]?/,
    /--request\s+['"]?(\w+)['"]?/ // For `--request` flag
  ]

  for (const regex of regexArray) {
    const match = input.match(regex)
    if (match) {
      return match[1].toLowerCase() // Return the first matched HTTP method
    }
  }
  return 'post'
}

/**
 * Gets the CSS class for the tab based on its active state.
 * @param {string} val - The value of the tab to check.
 * @returns {string} - The CSS class for the tab.
 */
const getTabClass = val => {
  return tab.value === val
    ? 'rounded-lg bg-gray-100 cursor-pointer px-3 py-2 text-sm font-medium text-gray-700 transition-all duration-200 dark:bg-gray-700 dark:text-gray-50' // Active tab
    : 'rounded-lg hover:bg-gray-100 cursor-pointer px-3 py-2 text-sm font-medium text-gray-700 transition-all duration-200 ' // Inactive tab
}
</script>

<style scoped>
textarea {
  width: 100%;
  margin-bottom: 20px;
}
</style>
