export const addAutomationData = ({ commit, dispatch }, { payload }) => {
  commit('ADD_DATA', payload)
}

export const removeAutomationData = ({ commit, dispatch }) => {
  commit('REMOVE_DATA')
}

export const addAutomationInputData = ({ commit, dispatch }, { payload }) => {
  commit('ADD_AUTOMATION_INPUT', payload)
}

export const removeAutomationInputData = ({ commit, dispatch }) => {
  commit('REMOVE_AUTOMATION_INPUT')
}

export const toggleStoreInputData = ({ commit, dispatch }, { payload }) => {
  commit('TOGGLE_STORE_INPUT_DATA', payload)
}

export const toggleWorkflowIdCreated = ({ commit, dispatch }, { payload }) => {
  commit('TOGGLE_WORKFLOW_ID_CREATED', payload)
}

export const addExecutionId = ({ commit, dispatch }, { payload }) => {
  commit('ADD_EXECUTION_ID', payload)
}

export const addStatus = ({ commit, dispatch }, { payload }) => {
  commit('ADD_STATUS', payload)
}

export const addConnectedAccountId = ({ commit, dispatch }, { payload }) => {
  commit('ADD_CONNECTED_ACCOUNT_ID', payload)
}

export const addConnectedAccountIdGoogleSheets = (
  { commit, dispatch },
  { payload }
) => {
  commit('ADD_CONNECTED_ACCOUNT_ID_GOOGLE_SHEETS', payload)
}

export const addSecondNodeId = ({ commit, dispatch }, { payload }) => {
  commit('ADD_SECOND_NODE_ID', payload)
}

export const setValidationStatus = ({ commit, dispatch }, { payload }) => {
  commit('SET_VALIDATION_STATUS', payload)
}

export const setAutomationNameText = ({ commit, dispatch }, { payload }) => {
  commit('SET_AUTOMATION_NAME_TEXT', payload)
}

export const setDelayAutomationNaming = ({ commit, dispatch }, { payload }) => {
  commit('SET_DELAY_AUTOMATION_NAMING', payload)
}

export const changeAutomationOutputMode = (
  { commit, dispatch },
  { payload }
) => {
  commit('CHANGE_AUTOMATION_OUTPUT_MODE', payload)
}

export const changeIterationDelay = ({ commit, dispatch }, { payload }) => {
  commit('CHANGE_ITERATION_DELAY', payload)
}

// export const addAutomationScheduleData = (
//   { commit },
//   { schedule, isScheduled, isActive }
// ) => {
//   commit('ADD_AUTOMATION_SCHEDULE_DATA', { schedule, isScheduled, isActive })
// }
export const addAutomationScheduleData = ({ commit }, payload) => {
  commit('ADD_AUTOMATION_SCHEDULE_DATA', payload)
}

export const addAutomationDesktopData = ({ commit }, payload) => {
  commit('ADD_AUTOMATION_DESKTOP_DATA', payload)
}

export const addPlatformData = ({ commit, dispatch }, payload) => {
  commit('ADD_PLATFORM_DATA', payload)
}

export const addCsvHeaders = ({ commit, dispatch }, { payload }) => {
  commit('ADD_CSV_HEADERS', payload)
}
