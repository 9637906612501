<template>
  <div class="border-0 p-0 text-gray-900 dark:bg-gray-900 dark:text-gray-50">
    <label :class="labelClass">{{ label }}</label>
    <div class="mt-1.5 flex w-max gap-3">
      <Select
        name="hour"
        :model-value="modelValue?.hour"
        :options="
          Array.from(new Array(12)).map((v, i) => ({
            value: i + 1,
            label: `${String(i + 1).length === 1 ? '0' : ''}${i + 1}`
          }))
        "
        @update:model-value="hour => update({ ...modelValue, hour })"
        class="w-20"
        text="12"
      />
      <Select
        name="minute"
        :model-value="modelValue?.minute"
        :options="
          Array.from(new Array(60)).map((v, i) => ({
            value: i,
            label: `${String(i).length === 1 ? '0' : ''}${i}`
          }))
        "
        @update:model-value="minute => update({ ...modelValue, minute })"
        class="w-20"
        text="00"
      />
      <Select
        name="phase"
        :model-value="modelValue?.phase"
        :options="[
          { label: 'AM', value: 'am' },
          { label: 'PM', value: 'pm' }
        ]"
        @update:model-value="phase => update({ ...modelValue, phase })"
        class="w-20"
        text="am"
      />
    </div>
  </div>
</template>

<script setup>
import Select from './Select.vue'

const props = defineProps({
  modelValue: {},
  label: String,
  labelClass: String
})

const $emit = defineEmits(['update:model-value', 'blur'])

const update = value => {
  $emit('update:model-value', value)
  $emit('blur', value)
}
</script>
