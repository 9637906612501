<template>
  <div class="relative -top-20 outline-none" tabindex="0">
    <VueFlow class="h-screen" @click="closeAllModals()">
      <Background class="pattern z-0" gap="18" />
      <Controls :show-interactive="false" />
      <template #connection-line="props">
        <CustomConnectionLine v-bind="props" />
      </template>
      <template #node-automationNode="props">
        <AutomationNodeStatic
          v-bind="props"
          :menuOpenId="menuOpenId"
          :selectable="true"
          :result="result[props.id]"
          @toggle-menu="toggleMenu"
          @open-result="openResult"
        />
      </template>
      <template #node-utilityNode="props">
        <UtilityNodeStatic
          v-bind="props"
          :menuOpenId="menuOpenId"
          :selectable="true"
          @toggle-menu="toggleMenu"
          @open-result="openResult"
        />
      </template>
      <Metadata
        v-model="metadata"
        class="relative left-4 top-20 z-10 w-[340px]"
        readonly
      />
      <Button
        text="Edit Workflow"
        class="absolute left-4 top-36 z-10 w-[340px] fill-blue-500"
        color="secondary"
        leftIcon="wrench"
        @click="$router.push(`/workflow-builder/${route.params.id}`)"
      />
    </VueFlow>
    <HistoryModal open @select="onSelectHistory" />
    <!-- <ControlBar
      class="fixed bottom-0 z-10 w-full"
      :workflowId="route.params.id"
      :is-active="workflow?.isActive"
      :is-scheduled="workflow?.isScheduled"
      @show-history="showHistory = !showHistory"
      @response="msg => $emit('success', msg)"
      resultsMode
    /> -->
    <ResultTableModal
      v-if="selectedNode"
      :node="selectedNode"
      @modal-close="closeResult"
    />
    <NodeLoader v-show="nodeLoader" />
  </div>
</template>

<script setup>
import {
  getWorkflow,
  getWorkflowAllNodes,
  getWorkflowExecutionAllNodesById
} from '@/apis/workflows'
import { EDGE_TYPE_NAMES, GRID_SIZE } from '@/common/constants'
import Button from '@/components/Button.vue'
import Metadata from '@/components/workflowBuilder/Metadata.vue'
import CustomConnectionLine from '@/components/workflowBuilder/customEdges/connectionLine.vue'
import AutomationNodeStatic from '@/components/workflowBuilder/customNodes/automationNodeStatic.vue'
import UtilityNodeStatic from '@/components/workflowBuilder/customNodes/utilityNodeStatic.vue'
import { getNodeAndEdges } from '@/components/workflowBuilder/helper'
import NodeLoader from '@/components/workflowBuilder/modals/NodeLoader.vue'
import HistoryModal from '@/components/workflowBuilder/modals/history/modal.vue'
import ResultTableModal from '@/components/workflowBuilder/modals/result/modal.vue'
import { Background } from '@vue-flow/background'
import { Controls } from '@vue-flow/controls'
import '@vue-flow/controls/dist/style.css'
import { VueFlow, useVueFlow } from '@vue-flow/core'
import { markRaw, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const menuOpenId = ref(null)
const hydratingWorkflow = ref(true)
const nodeLoader = ref(false)
const metadata = ref({})
const workflow = ref()
const showHistory = ref(false)
const result = ref({})
const selectedNode = ref(null)

const router = useRouter()
const route = useRoute()

const store = useStore()

const emit = defineEmits(['updateNodeInternals', 'warning', 'error'])

const { nodes, addNodes, addEdges, setNodes, setEdges } = useVueFlow({
  edgeTypes: {
    [EDGE_TYPE_NAMES.CONNECTION_LINE]: markRaw(CustomConnectionLine)
  },
  defaultEdgeOptions: {
    type: EDGE_TYPE_NAMES.CONNECTION_LINE
  },
  autoConnect: true,
  snapToGrid: false,
  snapGrid: [GRID_SIZE, GRID_SIZE],
  nodesDraggable: false,
  deleteKeyCode: null,
  zoomOnDoubleClick: false
})

// unset all the nodes and edges
const resetWorkflow = async () => {
  closeAllModals()
  setNodes([])
  setEdges([])
  await getWorkflowToAddNodeAndEdges()
}

// fetch the workflow and add node and edges
const getWorkflowToAddNodeAndEdges = async () => {
  const workflowId = route.params.id
  if (workflowId) {
    toggleNodeLoader(true)
    const [{ data: workflowData }, nodesResponse, nodesExecutionResponse] =
      await Promise.all([
        getWorkflow(workflowId),
        getWorkflowAllNodes(workflowId),
        getWorkflowExecutionAllNodesById(route.query.executionId)
      ])
    const map = {}
    nodesExecutionResponse.data.forEach(node => {
      map[node.nodeId] = node
    })
    result.value = map
    const { nodes, edges } = await getNodeAndEdges(
      workflowData,
      nodesResponse,
      map
    )
    addNodes(nodes)
    addEdges(edges)
    // if node is present in the query, directly open the modal
    if (route.query.nodeId) {
      const selectedNode = nodes
        .map(node => ({ _id: node.id, ...node.data.automationData }))
        .find(node => node._id === route.query.nodeId)
      if (selectedNode) openResult(selectedNode)
    }
    workflow.value = workflowData
    metadata.value = {
      name: workflowData.name,
      notes: workflowData.notes
    }
    toggleNodeLoader(false)
  } else if (!nodes.value.length) {
    setRightPanelNode({})
    store.dispatch('workflow/resetVariables', {})
    metadata.value = {}
  }
  hydratingWorkflow.value = false
}

onMounted(resetWorkflow)

const toggleMenu = nodeId => {
  menuOpenId.value = nodeId
}

const toggleNodeLoader = value => {
  nodeLoader.value = value
}

const openResult = async node => {
  selectedNode.value = node
  await router.push({
    query: {
      nodeId: node._id,
      executionId: route.query.executionId,
      workflowId: route.params.id,
      platformId: node.platformId,
      operationId: node.platformOperationId
    }
  })
}

const closeResult = async () => {
  selectedNode.value = null
  await router.push({
    query: {
      executionId: route.query.executionId
    }
  })
}

const closeAllModals = async () => {
  if (!nodes.value.length) return
  selectedNode.value = null
  await router.push({
    query: {
      executionId: route.query.executionId
    }
  })
}

const onSelectHistory = async executionId => {
  try {
    nodeLoader.value = true
    await router.push({
      query: {
        executionId
      }
    })
    await resetWorkflow()
  } catch (error) {
    console.log(error)
  }
  nodeLoader.value = false
}
</script>

<style>
@import '@vue-flow/core/dist/style.css';
@import '@vue-flow/core/dist/theme-default.css';

.pattern {
  background-color: #f7f7f7;
}

.pattern circle {
  fill: #aeaeae;
}

.vue-flow__node {
  transition: transform 0.1s ease-out;
}
</style>
