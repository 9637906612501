import axios from '@/common/axios'

const getPlans = async () => {
  try {
    const response = await axios.get(`plans`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const checkOut = async (planId, paymentPlanId) => {
  try {
    const response = await axios.post(`billing/check-out`, {
      planId,
      paymentPlanId
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}
const createSelfServePortal = async () => {
  try {
    const response = await axios.post(`billing/manage`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}
const checkPaymentStatus = async () => {
  try {
    const response = await axios.post(`billing/verify`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const updatePlan = async (newPlanId, newPaymentPlanId) => {
  try {
    const response = await axios.post(`billing/update-plan`, {
      newPlanId,
      newPaymentPlanId
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const pausePlan = async (newPlanId, newPaymentPlanId) => {
  try {
    const response = await axios.post(`billing/pause-plan`, {
      newPlanId,
      newPaymentPlanId
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const resumePlan = async (newPlanId, newPaymentPlanId) => {
  try {
    const response = await axios.post(`billing/resume-plan`, {
      newPlanId,
      newPaymentPlanId
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const invoices = async (start = 0, limit = 20) => {
  try {
    const response = await axios.get(`/invoices`, {
      params: {
        start,
        limit
      }
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const cardDetails = async () => {
  try {
    const response = await axios.get(`/invoices/card-details`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getAddons = async () => {
  try {
    const response = await axios.get(`/plans/addons`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const addonsCheckout = async data => {
  try {
    const response = await axios.post(`billing/addon-check-out`, data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export {
  cardDetails,
  checkOut,
  createSelfServePortal,
  checkPaymentStatus,
  getPlans,
  invoices,
  pausePlan,
  resumePlan,
  updatePlan,
  getAddons,
  addonsCheckout
}
