<!-- Size of the toast can be adjusted on the component call  -->
<!-- use refs to use toast. use the add toast method to add toasts -->
<!--
  each toast object requires props required for toast component
-->

<template>
  <div
    class="absolute top-0 right-5 z-50 flex max-h-screen flex-col items-end gap-3 overflow-auto py-3"
  >
    <Toast
      class="w-full"
      v-for="toast of toasts"
      :key="toast.id"
      v-bind="toast"
      @toast-close="removeToast(toast.id)"
    />
  </div>
</template>

<script>
import generateUID from '@/helpers/generateUID'
import Button from './Button.vue'
import SvgIcon from './SvgIcon.vue'
import Toast from './Toast.vue'

export default {
  name: 'ToastContainer',
  components: { SvgIcon, Button, Toast },
  data() {
    return {
      toasts: []
    }
  },
  methods: {
    addToast(newToast) {
      const id = generateUID()
      this.toasts = this.toasts.concat({ id, ...newToast })
      setTimeout(() => {
        this.removeToast(id)
      }, newToast.timeout)
    },
    removeToast(toastId) {
      this.toasts = this.toasts.filter(t => t.id !== toastId)
    }
  }
}
</script>

<style></style>
