<template>
  <div
    v-if="playground"
    class="h-[555px] w-[350px] overflow-y-auto rounded-lg border-2 bg-white shadow-lg"
  >
    <div class="border-b flex justify-between border-gray-200 px-6 py-3">
      <p class="font-bold">Select Variable</p>
      <Button
        @click="$emit('closeTemplate')"
        icon="close-1"
        class="!p-1.5"
        size="small"
        color="tertiary"
      />
    </div>
    <div class="px-10 py-5">
      <div class="">
        <div class="flex flex-col gap-7">
          <div
            v-if="
              variableData.variables &&
              Object.keys(variableData.variables).length > 0
            "
            class="gap-5"
          >
            <div>
              <p class="font-bold">You:</p>
            </div>
            <div class="flex flex-col items-start gap-[15px]">
              <div
                v-for="(value, key) in variableData.variables"
                @click="
                  $emit('variableSelected', {
                    nodeId: 'texAuVariables',
                    outputName: key
                  })
                "
                class="flex w-full cursor-pointer items-center justify-center rounded-xl bg-green-200 p-[10px]"
              >
                <p class="font-[600] text-[14px] text-green-600">{{ key }}</p>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-5">
            <div>
              <p class="font-bold">Your Prospect:</p>
            </div>

            <div class="flex flex-col items-start gap-[15px]">
              <div
                v-for="data of var2"
                :key="data"
                @click="
                  $emit('variableSelected', {
                    nodeId: null,
                    outputName: data
                  })
                "
                class="flex w-full cursor-pointer items-center justify-center rounded-xl bg-blue-200 p-[10px]"
              >
                <p class="font-[600] text-[14px] text-blue-600">{{ data }}</p>
              </div>

              <div
                v-for="data of userVar"
                :key="data"
                @click="
                  $emit('variableSelected', {
                    nodeId: null,
                    outputName: data
                  })
                "
                class="flex w-full cursor-pointer items-center justify-center rounded-xl bg-blue-200 p-[10px]"
              >
                <p class="font-[600] text-[14px] text-blue-600">{{ data }}</p>
              </div>

              <div class="flex gap-2">
                <Input v-model="customVariable" text="Custom Variable" />
                <Button
                  @click="addCustomVariable()"
                  text="Add"
                  size="small"
                  leftIcon="plus"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-else
    class="h-[444px] w-[300px] overflow-y-auto rounded-lg border-2 bg-white shadow-lg"
  >
    <div class="border-b flex justify-between border-gray-200 px-6 py-3">
      <p class="font-bold">Select Template</p>
      <Button
        @click="$emit('closeTemplate')"
        icon="close-1"
        class="!p-1.5"
        size="small"
        color="tertiary"
      />
    </div>
    <div class="text-center pt-2" v-if="text">
      <p class="text-[11px] text-red-600">
        Selecting Template Will Overwrite Message Content
      </p>
    </div>
    <div class="px-8 py-5">
      <div class="flex flex-col gap-5">
        <div class="flex flex-col items-start gap-[15px]">
          <div
            v-for="data of templateData"
            :key="data._id"
            @click="$emit('selectedTemplate', data)"
            class="flex w-full cursor-pointer items-center justify-center rounded-xl bg-blue-200 p-[5px]"
          >
            <p class="font-[600] text-[14px] text-blue-600">
              {{ data.title }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { getPrompt } from '@/apis/settings/ai'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'

const props = defineProps({
  parentEl: {},
  variableData: { type: Object },
  playground: { type: Boolean, default: true },
  text: { type: String },
  promptText: { type: String, default: null }
})

defineEmits(['selectedTemplate', 'variableSelected'])

onMounted(async () => {
  if (props.playground === false) {
    const response = await getPrompt(0, 50, true)
    templateData.value = response.data
  }

  if (props.promptText) {
    extractPlaceholders()
  }
})

const templateData = ref(null)

const var2 = ref([
  'First Name',
  'Company Name',
  'Job Title',
  'Headline',
  'Industry'
])
const userVar = ref([])
const customVariable = ref(null)

const addCustomVariable = () => {
  userVar.value.push(customVariable.value)
}

const extractPlaceholders = () => {
  const regex = /{{(.*?)}}/g
  let match
  while ((match = regex.exec(props.promptText)) !== null) {
    const placeholder = match[1].trim()
    if (var2.value.includes(placeholder)) continue
    userVar.value.push(placeholder)
  }
}
</script>
