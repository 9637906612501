<template>
  <!-- START WRAPPER -->
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto h-full w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="h-full px-4 py-5 sm:px-6">
        <div class="flex items-center justify-end">
          <div>
            <Button
              @click="close"
              icon="close-1"
              class="!p-1.5"
              size="small"
              color="tertiary"
            />
          </div>
        </div>
        <div class="flex h-full items-center justify-center" v-show="loading">
          <Spinner size="large" />
        </div>
        <iframe
          v-show="!loading"
          @load="load"
          ref="frame"
          :src="url"
          class="mt-1 h-full w-full"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
export default {
  components: {
    Button,
    Spinner
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true
    }
  },
  methods: {
    close() {
      this.$emit('close')
      window.open(`${window.location}`, '_self')
    },
    load() {
      this.loading = false
    }
  }
}
</script>

<style></style>
