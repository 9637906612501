<template>
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="">
          <div class="flex items-center justify-between">
            <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
              TexAu returns following details:
            </p>

            <div class="flex items-center justify-end space-x-4">
              <Button
                @click="close"
                icon="close-1"
                class="!p-1.5"
                size="small"
                color="tertiary"
              />
            </div>
          </div>
        </div>

        <div class="my-3 max-h-[60vh] overflow-y-scroll">
          <table class="min-w-full rounded-lg">
            <thead class="sticky top-0 bg-blue-50 dark:bg-gray-800">
              <tr>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  Label
                </th>

                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  Description
                </th>
              </tr>
            </thead>

            <tbody
              class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
            >
              <tr v-for="list in automationStoreData.outputList" :key="list">
                <td
                  class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  {{ list.label }}
                </td>

                <td
                  class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
                >
                  {{ list.description }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'OutputTagsModal',
  components: {
    SvgIcon,
    Button
  },
  computed: {
    ...mapState('automationStore', ['automationStoreData'])
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style></style>
