<template>
  <div
    class="rounded-xl border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-900"
  >
    <div class="p-4">
      <div class="grid grid-cols-6 gap-4">
        <div class="col-span-2 flex items-center space-x-4">
          <img
            class="h-10 w-10 shrink-0 rounded-full object-cover"
            :src="data.firstNode.platformIconUrl"
            alt=""
          />
          <p class="text-sm font-semibold text-gray-900 dark:text-gray-50">
            {{ data.firstNode.platformOperationName }}
          </p>
        </div>

        <div class="flex items-center justify-center">
          <SvgIcon
            name="rightArrowFilled"
            class="h-5 w-5 text-blue-600 opacity-20 dark:text-blue-500"
          />
          <SvgIcon
            name="rightArrowFilled"
            class="h-5 w-5 text-blue-600 opacity-40 dark:text-blue-500"
          />
          <SvgIcon
            name="rightArrowFilled"
            class="h-5 w-5 text-blue-600 opacity-60 dark:text-blue-500"
          />
          <SvgIcon
            name="rightArrowFilled"
            class="h-5 w-5 text-blue-600 opacity-80 dark:text-blue-500"
          />
          <SvgIcon name="rightArrowFilled" />
        </div>

        <div class="col-span-2 flex items-center space-x-4">
          <img
            class="h-10 w-10 shrink-0 rounded-full object-cover"
            :src="data.secondNode.platformIconUrl"
            alt=""
          />
          <p class="text-sm font-semibold text-gray-900 dark:text-gray-50">
            {{ data.secondNode.platformOperationName }}
          </p>
        </div>

        <div class="text-right">
          <Button
            text="Try it"
            @click="onCreateClick"
            :show-loader="isCreating"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import { createWorkflowFromSuggestion } from '@/apis/publicWorkflow'
import { WORKFLOW_CREATED_FROM } from '@/common/constants'

export default {
  name: 'singleRecipeSection',
  components: {
    SvgIcon,
    Button
  },
  props: {
    data: { type: Object, required: true }
  },
  data() {
    return {
      isCreating: false
    }
  },
  methods: {
    async onCreateClick() {
      this.isCreating = true
      const response = await createWorkflowFromSuggestion({
        firstNodeData: this.data.firstNode,
        secondNodeData: this.data.secondNode,
        name:
          this.data.firstNode.platformOperationName +
          this.data.secondNode.platformOperationName,
        description:
          this.data.firstNode.platformOperationName +
          this.data.secondNode.platformOperationName,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        createdFrom: WORKFLOW_CREATED_FROM.PUBLIC_WORKFLOW,
        desktop: false
      })
      this.isCreating = false
      if (response['success']) {
        console.log('Workflow Created Successfully: ', response.data)
        // TODO: redirect to builder for editing input
      } else {
        this.$emit('error', 'Error while creating workflow.')
      }
    }
  }
}
</script>

<style scoped></style>
