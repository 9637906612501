import { getOperationVariableServices } from '@/apis/getPlatformDetails'
import { previewData } from '@/apis/workflows'
import { AUTOMATION_VARIABLE_DIRECTION } from '@/common/constants'

let abortController = new AbortController()

const fetchOutputForTable = async (
  startVal,
  manipulationConfig,
  all,
  utilityNode = false
) => {
  let start = startVal ?? 0
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  })

  if (abortController) {
    abortController.abort()
    abortController = new AbortController()
  }

  let columns, previewDataResponse, outputVariables

  if (utilityNode) {
    previewDataResponse = await previewData(
      params.executionId,
      params.nodeId,
      start,
      100,
      manipulationConfig,
      abortController.signal,
      all
    )
    columns = []
  } else {
    ;[previewDataResponse, outputVariables] = await Promise.all([
      previewData(
        params.executionId,
        params.nodeId,
        start,
        100,
        manipulationConfig,
        abortController.signal,
        all
      ),
      getOperationVariableServices(
        params.platformId,
        params.operationId,
        AUTOMATION_VARIABLE_DIRECTION.OUTPUT,
        null,
        abortController.signal
      )
    ])
    columns = outputVariables.data
  }

  if (previewDataResponse === 'canceled') {
    throw new Error('canceled')
  }
  let logo = previewDataResponse.data.platformIconUrl
  let title = previewDataResponse.data.platformOperationName

  let filtersOptions = columns.slice()
  filtersOptions.sort((a, b) => {
    const labelA = a.label ? a.label.toUpperCase() : ''
    const labelB = b.label ? b.label.toUpperCase() : ''

    if (labelA < labelB) {
      return -1
    }
    if (labelA > labelB) {
      return 1
    }
    return 0
  })

  let outputValue = null
  let total = null
  if (previewDataResponse['success']) {
    if (
      Array.isArray(previewDataResponse.data.result) &&
      previewDataResponse.data.result.length === 0
    ) {
      return { output: [], logo, title, columns, total: 0 }
    }

    outputValue = [].concat(previewDataResponse.data.result.response)
    total = previewDataResponse.data.total
    if (columns.length <= 1 && outputValue[0]) {
      columns = []
        .concat(
          Object.keys(outputValue[0]).map(key => ({ name: key, label: key }))
        )
        .concat(columns)
    }
    columns.push(
      {
        _id: '456',
        label: 'Timestamp',
        name: 'timestamp'
      },
      {
        _id: '123',
        label: 'Error Message',
        name: 'message'
      }
    )
    return { output: outputValue, logo, title, columns, total, filtersOptions }
  } else {
    throw error
  }
}

export { fetchOutputForTable }
