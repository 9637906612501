<template>
  <!-- TOP HEADER OF EVERY AUTOMATION PAGE -->
  <AutomationHeader title="Connecting Account" />
  <div
    class="flex flex-1 border-t border-gray-200 bg-white text-gray-900 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-50"
  >
    <div
      v-if="isLoading"
      class="mt-12 flex w-full flex-col items-center justify-center space-y-4"
    >
      <Spinner size="large" />
      <p class="text-3xl font-bold text-gray-900 dark:text-gray-50">
        Connecting Account...
      </p>
    </div>
  </div>
</template>

<script>
import { startOauthConnection } from '@/apis/oauth'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import Spinner from '@/components/Spinner.vue'
import ToastContainer from '@/components/ToastContainer.vue'

export default {
  name: 'redirect',
  components: {
    Spinner,
    AutomationHeader,
    ToastContainer
  },
  data() {
    return {
      isLoading: false
    }
  },

  async mounted() {
    await this.confirmConnect()
  },

  methods: {
    async confirmConnect() {
      const channel = new BroadcastChannel('app-data') // Create a BroadcastChannel

      this.isLoading = true
      const code = this.$route.query.code
      const state = this.$route.query.state
      const appName = this.$route.params.appName
      if (code && state) {
        const response = await startOauthConnection(appName, code, state)
        this.isLoading = false
        if (response['success']) {
          channel.postMessage('accountSuccess') // Post a message to the BroadcastChannel
          this.$emit('success', 'Account connected successfully.')
          setTimeout(function () {
            window.close()
          }, 5000)
        } else {
          this.$emit('error', 'Failed connecting account.')
          setTimeout(function () {
            window.close()
          }, 5000)
        }
      } else {
        this.isLoading = false
        this.$emit('error', 'Failed connecting account.')
        setTimeout(function () {
          window.close()
        }, 5000)
      }
    }
  }
}
</script>

<style scoped></style>
