<template>
  <div
    class="relative flex-1 border-l border-gray-200 antialiased dark:border-gray-700"
  >
    <div class="px-4 py-5 sm:p-6">
      <div class="mx-auto max-w-3xl space-y-8">
        <div class="grid grid-cols-2 gap-x-6 gap-y-4" :key="componentKey">
          <div>
            <Input
              type="text"
              @change="onInputChange($event)"
              @keyup="onInputChange($event)"
              text="Enter workspace name"
              maxlength="20"
              label="Workspace Name"
              label-class="block text-sm font-medium text-gray-900 dark:text-gray-50"
            />
          </div>

          <div>
            <Select
              @change="onSelectChange($event)"
              text="Select Timezone"
              :includeSearch="true"
              :options="timeZoneList"
              label="Timezone"
            />
          </div>
        </div>

        <div v-if="showPopup" class="rounded-lg bg-blue-50 dark:bg-blue-900/50">
          <div class="p-4">
            <div class="flex items-start justify-between">
              <SvgIcon name="smiley" />

              <p
                class="ml-3 mr-auto flex-1 text-sm font-normal text-gray-900 dark:text-gray-50"
              >
                You can invite up to 3 external members per workspace. This can
                be your end client, or an automation freelancer that work on
                your behalf or for your client. You can also assign a permission
                for each: editor or viewer only.
              </p>
              <div class="ml-4 flex shrink-0">
                <Button
                  icon="close"
                  @click="closePopup"
                  color="iconOnly"
                  class="-m-1 rounded p-1 text-gray-500 transition-all duration-200 hover:bg-blue-100 hover:text-blue-600 focus:outline-none dark:text-gray-400 dark:hover:bg-blue-600 dark:hover:text-blue-50"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button text="Save" :show-loader="isSaving" @click="saveData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from '@/components/Select.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import { createWorkspace } from '@/apis/workspace'
import { timeZones } from '@/common/constants'

export default {
  name: 'createNewWorkspaceSection',
  components: {
    Select,
    SvgIcon,
    Button,
    Input
  },
  data() {
    return {
      showPopup: true,
      dataForSave: {},
      isSaving: false,
      componentKey: 0
    }
  },
  computed: {
    timeZoneList: function () {
      return timeZones
    }
  },
  methods: {
    closePopup() {
      this.showPopup = false
    },

    onInputChange(e) {
      this.dataForSave['workspaceName'] = e.target.value
    },

    onSelectChange(e) {
      this.dataForSave['timezone'] = e
    },

    async saveData() {
      this.isSaving = true
      const organisationId = window.$cookies.get('organisationId')
      const orgUserId = window.$cookies.get('orgUserId')
      const createWorkspaceResponse = await createWorkspace(
        this.dataForSave.workspaceName,
        organisationId,
        orgUserId,
        this.dataForSave.timezone
      )
      this.isSaving = false
      if (createWorkspaceResponse['success']) {
        this.dataForSave = {}
        this.componentKey += 1
        this.$router.go()
        // TODO: fetch all workspaces again to show in list
      } else {
        this.$emit('error', createWorkspaceResponse.message)
      }
    }
  }
}
</script>

<style scoped></style>
