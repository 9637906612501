<!-- Source: https://stackoverflow.com/questions/71176408/dynamic-inline-importing-raw-svg-in-vue-js-vite -->

<script>
import { defineAsyncComponent } from 'vue'

export default {
  props: {
    name: {
      type: String,
      required: true
    }
  },

  computed: {
    dynamicComponent() {
      const name = this.name

      return defineAsyncComponent(() => import(`../assets/icons/${name}.vue`))
    }
  }
}
</script>

<template>
  <component :is="dynamicComponent" />
</template>
