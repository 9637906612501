<template>
  <h1
    class="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-50 sm:text-4xl"
  >
    {{ text }}
  </h1>
</template>

<script>
export default {
  name: 'Heading',
  props: {
    text: { type: String, default: 'Pass Some Text' }
  }
}
</script>

<style></style>
