<template>
  <div class="flex-1 overflow-y-auto overflow-x-hidden">
    <div class="px-4 h-full pb-5 pt-2 mt-[41px] sm:px-5">
      <Table class="relative h-full min-w-full">
        <TableHeader />
        <tbody
          class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
        >
          <tr v-for="ele in 4" :key="ele">
            <td class="whitespace-nowrap px-3 py-4">
              <div class="flex gap-1">
                <Skeleton shape="circle" size="40px"></Skeleton>
                <Skeleton
                  height="40px"
                  width="140px"
                  borderRadius="8px"
                ></Skeleton>
              </div>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <div class="flex -space-x-2 overflow-hidden">
                <Skeleton shape="circle" size="40px"></Skeleton>
              </div>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <div class="min-w-[205px] h-[41.6px]">
                <Skeleton
                  height="100%"
                  width="100%"
                  borderRadius="8px"
                ></Skeleton>
              </div>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <Skeleton
                height="40px"
                width="55px"
                borderRadius="8px"
              ></Skeleton>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <Skeleton
                height="40px"
                width="80px"
                borderRadius="8px"
              ></Skeleton>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <Skeleton
                height="40px"
                width="40px"
                borderRadius="8px"
              ></Skeleton>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <Skeleton
                height="12px"
                width="12px"
                borderRadius="8px"
              ></Skeleton>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <Skeleton
                height="12px"
                width="12px"
                borderRadius="8px"
              ></Skeleton>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <Skeleton
                height="40px"
                width="40px"
                borderRadius="8px"
              ></Skeleton>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <Skeleton
                height="40px"
                width="40px"
                borderRadius="8px"
              ></Skeleton>
            </td>
            <td class="whitespace-nowrap px-3 py-4">
              <div class="flex items-center justify-center space-x-1">
                <Skeleton
                  height="40px"
                  width="68px"
                  borderRadius="8px"
                ></Skeleton>
                <Skeleton
                  height="40px"
                  width="40px"
                  borderRadius="8px"
                ></Skeleton>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  </div>
</template>

<script>
import Table from './Table.vue'
import TableHeader from './TableHeader.vue'
export default {
  components: {
    Table,
    TableHeader
  }
}
</script>
