<template>
  <div
    class="relative h-[200px] bg-linkedin-100 hover:border-linkedin-600 dark:bg-linkedin-900 transform cursor-pointer overflow-hidden rounded-lg border border-transparent transition-all duration-200 hover:-translate-y-1 hover:shadow-lg"
  >
    <div class="h-full">
      <div @click="onTryClick" class="px-[18px] pt-[32px] h-full">
        <div class="flex flex-col space-y-1 2xl:space-y-2">
          <div class="flex h-[32px] -space-x-2 overflow-hidden">
            <img
              class="inline-block h-8 w-8 rounded-full ring-white"
              alt=""
              v-for="logo in workflowData.uniquePlatformIconUrl"
              :key="logo"
              :src="logo"
            />
          </div>
          <div class="flex flex-col gap-[1px]">
            <p
              class="overflow-hidden text-base font-semibold text-gray-900 dark:text-gray-50 2xl:mt-2"
            >
              {{ workflowData.name }}
            </p>
            <!-- <p
              class="overflow-hidden text-sm font-normal text-gray-500 line-clamp-3 dark:text-gray-400"
            >
              {{ `${getFormatDateAndTime(data.createdAt)}` }}
            </p> -->
            <p
              class="overflow-hidden text-sm font-normal text-gray-500 line-clamp-1 dark:text-gray-400"
            >
              {{ workflowData.notes }}
            </p>
          </div>
        </div>
        <div class="absolute bottom-3 right-5">
          <Button
            size="small"
            color="secondary"
            text="Try it!"
            :showLoader="loader"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../Button.vue'
import SvgIcon from '../SvgIcon.vue'
import Popper from 'vue3-popper'
import { formatDate, formatTime } from '@/common/functions/formatDateAndTime'
import { clonePublicWorkflow, getPublicWorkflowInputs } from '@/apis/workflows'
import { mapActions } from 'vuex'

export default {
  name: 'PublicWorkflowCards',
  emits: ['error'],
  props: {
    workflowData: {},
    platformIcon: { type: String }
  },
  data() {
    return {
      loader: false
    }
  },
  methods: {
    ...mapActions('automationStore', ['addAutomationData']),
    async onTryClick() {
      try {
        this.loader = true
        const response = await clonePublicWorkflow(this.workflowData._id, {
          timezone: this.workflowData.timezone
        })
        let coneWorkflowId = response.data._id
        if (response['success']) {
          const inputResponse = await getPublicWorkflowInputs(coneWorkflowId)
          if (inputResponse['success']) {
            await this.addAutomationData({
              payload: {
                ...response.data,
                logoUrl: response.data.uniquePlatformIconUrl[0],
                inputList: {
                  ...inputResponse.data
                }
              }
            })
            await this.$router.push({
              path: `/automation-store/workflow`,
              query: {
                publicWorkflowId: coneWorkflowId,
                source: 'workflowInput'
              }
            })
          }
        } else {
          throw response.message
        }
        // await this.$router.push(`/workflow-builder/${this.data._id}`)
      } catch (error) {
        this.$emit('error', error)
      }
    },
    getFormatDateAndTime(d) {
      return `${formatDate(d)} ${formatTime(d)}`
    }
  },
  components: { Button, SvgIcon, Popper }
}
</script>

<style></style>
