<template>
  <div>
    <vee-form
      :validation-schema="schema"
      class="mt-10"
      @submit="submitPassword()"
    >
      <div class="space-y-5">
        <div>
          <label
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
            for=""
          >
            OTP
          </label>
          <div class="mt-1.5">
            <Input v-model="code" text="Enter code" type="tel" name="code" />
            <p class="mt-2 text-sm text-red-600">
              <ErrorMessage name="code" />
            </p>
          </div>
        </div>
        <div>
          <label
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
            for=""
          >
            New Password
          </label>
          <div class="mt-1.5">
            <Input
              v-model="password"
              name="Password"
              text="Enter New Password"
              :type="passwordFieldType"
              :rightIcon="passwordFieldType === 'text' ? 'eye-off' : 'eye'"
              rightIconMouse="cursor-pointer"
              @onRightIconClick="onRightIconClick"
            />
            <p class="mt-2 text-sm text-red-600">
              <ErrorMessage name="Password" />
            </p>
          </div>
        </div>

        <div>
          <Button
            :showLoader="isLoading"
            class="w-full"
            text="Enter the new Password"
            type="submit"
          />
        </div>
      </div>
    </vee-form>
  </div>
</template>

<script>
import { resetPassword } from '@/apis/onboarding'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
export default {
  name: 'ResetPassword',
  components: {
    Input,
    Button
  },
  data() {
    return {
      code: null,
      password: null,
      isLoading: false,
      passwordFieldType: 'password',
      schema: {
        Password: 'required|password_min:12',
        code: 'required|min:6|max:6'
      }
    }
  },
  props: {
    email: { type: String, required: true }
  },
  methods: {
    async submitPassword() {
      this.isLoading = true
      let response
      try {
        let data = {
          email: this.email,
          confirmationCode: this.code,
          newPassword: this.password
        }
        response = await resetPassword(data)
        if (response['success']) {
          this.$emit('passwordReset', {
            type: 'success',
            message: 'Password Reset Successfully !'
          })
        } else {
          throw 'responseError'
        }
      } catch (error) {
        if (error === 'responseError') {
          this.$emit('passwordReset', {
            message: response.message,
            type: 'fail'
          })
        } else {
          console.log(error)
        }
        this.isLoading = false
      }
    },
    onRightIconClick() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    }
  }
}
</script>

<style></style>
