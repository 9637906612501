<template>
  <!-- START WRAPPER -->
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto w-full max-w-3xl rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6 flex flex-col gap-5">
        <div>
          <div class="flex items-center justify-between">
            <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
              Add Prompt Title
            </p>

            <div>
              <Button
                @click="close"
                icon="close-1"
                class="!p-1.5"
                size="small"
                color="tertiary"
              />
            </div>
          </div>
          <hr class="my-2" />
          <div class="mt-3 flex flex-col gap-5 overflow-y-scroll">
            <div class="flex flex-col gap-4">
              <p class="font-bold text-gray-500 capitalize">Prompt Name</p>
              <Input
                v-model="promptDesc.title"
                text="Write a Nice Title For your Prompt ..."
              />
            </div>
            <div class="flex flex-col gap-4">
              <p class="font-bold text-gray-500 capitalize">
                Prompt Description
              </p>
              <textarea
                class="w-full text-sm rounded-lg border-gray-300"
                name=""
                id=""
                rows="10"
                placeholder="A Brief Description of what The Prompt Does ..."
                v-model="promptDesc.description"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="flex flex-row-reverse">
          <Button @click="close()" text="Save" />
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import Input from '@/components/Input.vue'
export default {
  components: {
    Button,
    Spinner,
    Input
  },
  props: {
    promptTitleData: { type: Object, required: true }
  },
  data() {
    return {
      promptDesc: this.promptTitleData
    }
  },
  methods: {
    close() {
      this.$emit('close', this.promptDesc)
    }
  }
}
</script>

<style></style>
