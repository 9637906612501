<template>
  <CreateTemplateFoldersList/>
  <div class="relative flex-1 border-l border-gray-200 dark:border-gray-700 antialiased">
    <div class="px-4 py-5 sm:p-5">
      <p class="text-base font-semibold text-gray-900 dark:text-gray-50">
        Select platform
      </p>

      <div class="grid grid-cols-7 gap-6 mt-5">
        <div v-for="(item, index) in platformList" :key="index"
             class="inline-flex items-center justify-center overflow-hidden transition-all duration-200 border rounded-lg cursor-pointer"
             :class="[{
          'border-blue-600 bg-blue-50 dark:bg-blue-900/50 dark:border-blue-500':item.isActive,
          'bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-blue-600 dark:hover:border-blue-500':!item.isActive,
        }]">
          <div class="px-4 py-5">
            <img v-if="item.name === 'linkedin'" class="object-cover w-10 h-10 rounded-full" src="@/assets/images/symbols/linkedin.svg" alt="">
            <img v-if="item.name === 'twitter'" class="object-cover w-10 h-10 rounded-full" src="@/assets/images/symbols/twitter.svg" alt="">
            <img v-if="item.name === 'facebook'" class="object-cover w-10 h-10 rounded-full" src="@/assets/images/symbols/facebook.svg" alt="">
            <img v-if="item.name === 'reddit'" class="object-cover w-10 h-10 rounded-full" src="@/assets/images/symbols/reddit.svg" alt="">
            <img v-if="item.name === 'pinterest'" class="object-cover w-10 h-10 rounded-full" src="@/assets/images/symbols/pinterest.svg" alt="">
            <img v-if="item.name === 'youtube'" class="object-cover w-10 h-10 rounded-full" src="@/assets/images/symbols/youtube.svg" alt="">
            <img v-if="item.name === 'instagram'" class="object-cover w-10 h-10 rounded-full" src="@/assets/images/symbols/instagram.svg" alt="">
            <img v-if="item.name === 'quora'" class="object-cover w-10 h-10 rounded-full" src="@/assets/images/symbols/quora.svg" alt="">
            <img v-if="item.name === 'medium'" class="object-cover w-10 h-10 rounded-full" src="@/assets/images/symbols/medium.svg" alt="">
            <img v-if="item.name === 'craigslist'" class="object-cover w-10 h-10 rounded-full" src="@/assets/images/symbols/craigslist.svg" alt="">
            <img v-if="item.name === 'producthunt'" class="object-cover w-10 h-10 rounded-full" src="@/assets/images/symbols/producthunt.svg" alt="">
            <img v-if="item.name === 'tiktok'" class="object-cover w-10 h-10 rounded-full" src="@/assets/images/symbols/tiktok.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateTemplateFoldersList from "@/components/settings/createTemplateFoldersList.vue";

export default {
  name: "messageTemplatesSection",
  components: {
    CreateTemplateFoldersList,
  },
  props: {
    platformList: {type:Array, default: [
        {
          name: 'linkedin',
          isActive: true,
        },
        {
          name: 'twitter',
        },
        {
          name: 'facebook',
        },
        {
          name: 'reddit',
        },
        {
          name: 'pinterest',
        },
        {
          name: 'youtube',
        },
        {
          name: 'instagram',
        },
        {
          name: 'quora',
        },
        {
          name: 'medium',
        },
        {
          name: 'craigslist',
        },
        {
          name: 'producthunt',
          isProducthunt: true,
        },
        {
          name:'tiktok',
          isTiktok: true,
        }
      ]}
  },
}
</script>
