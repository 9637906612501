export const registerStarted = ({ commit, dispatch }, { payload }) => {
  commit('REGISTER_STARTED', payload)
}

export const registerFinished = ({ commit, dispatch }) => {
  commit('REGISTER_FINISHED')
}

export const loginStart = ({ commit, dispatch }) => {
  commit('LOGIN_STARTED')
}

export const loginSuccess = ({ commit, dispatch }, { payload }) => {
  commit('LOGIN_SUCCESS', payload)
}

export const changeNewImportPage = ({ commit, dispatch }, { payload }) => {
  commit('CHANGE_NEW_IMPORT_PAGE', payload)
}
