<template>
  <div class="mt-8 h-full">
    <table v-if="loading" class="min-w-full">
      <thead class="bg-gray-50 dark:bg-gray-700">
        <tr>
          <th
            class="w-[25vw] px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
          >
            Invited By
          </th>
          <th
            class="w-[25vw] px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
          >
            Workspace Name
          </th>
          <th
            class="w-[25vw] px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
          >
            Role
          </th>
          <th
            class="w-[25vw] px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
          >
            Action
          </th>
        </tr>
      </thead>

      <tbody
        class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
      >
        <tr v-for="ele in 4" :key="ele">
          <td
            v-for="ele in 5"
            :key="ele"
            class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
          >
            <Skeleton height="40px" width="100%" borderRadius="8px"></Skeleton>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-else-if="inviteData.length === 0 && !loading"
      class="mt-10 flex h-full w-full flex-col items-center justify-center space-y-4"
    >
      <SvgIcon name="empty-data-light" />
      <p class="text-3xl font-bold text-gray-900 dark:text-gray-50">
        No Invites Available
      </p>
    </div>
    <table v-else class="min-w-full">
      <thead class="bg-gray-50 dark:bg-gray-700">
        <tr>
          <th
            class="w-[25vw] px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
          >
            Invited By
          </th>
          <th
            class="w-[25vw] px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
          >
            Workspace Name
          </th>
          <th
            class="w-[25vw] px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
          >
            Role
          </th>
          <th
            class="w-[25vw] px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
          >
            Action
          </th>
        </tr>
      </thead>

      <tbody
        class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
      >
        <tr v-for="(data, index) of inviteData" :key="data._id">
          <td
            class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
          >
            {{ data.invitedBy }}
          </td>

          <td
            class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
          >
            {{ data.invitee.workspaceName }}
          </td>

          <td
            class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
          >
            {{ getRoles(data.invitee.roleId) }}
          </td>

          <td class="whitespace-nowrap px-3 py-4">
            <div class="flex items-center space-x-3">
              <Button
                text="Accept"
                :leftIcon="data.isAcceptloading ? '' : 'TickIcon'"
                :showLoader="data.isAcceptloading"
                @click="acceptInvite(data._id, data.invitee.workspaceId, index)"
              />
              <Button
                text="Reject"
                color="dangerFilled"
                :leftIcon="data.isRejectLoading ? '' : 'close'"
                :showLoader="data.isRejectLoading"
                @click="rejectInvite(data._id, data.invitee.workspaceId, index)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { acceptInvite, getPendingInvites, rejectInvite } from '@/apis/workspace'
import { mapGetters } from 'vuex'
export default {
  components: { Button, SvgIcon },
  data() {
    return {
      inviteData: null,
      loading: true
    }
  },
  computed: {
    ...mapGetters('settings', ['getMetaData'])
  },
  async mounted() {
    await this.getAllInvites()
    this.loading = false
  },
  methods: {
    async getAllInvites() {
      try {
        const response = await getPendingInvites({})
        if (response['success']) {
          this.inviteData = response.data.map(data => ({
            ...data,
            isAcceptloading: false,
            isRejectLoading: false
          }))
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },
    async acceptInvite(inviteId, workspaceId, index) {
      this.inviteData[index].isAcceptloading = true
      try {
        const response = await acceptInvite(inviteId, workspaceId)
        if (response['success']) {
          this.$router.go()
        } else {
          throw response.message
        }
      } catch (error) {
        this.inviteData[index].isAcceptloading = false
        this.$emit('error', error)
      }
    },
    async rejectInvite(inviteId, workspaceId, index) {
      this.inviteData[index].isRejectLoading = true
      try {
        const response = await rejectInvite(inviteId, workspaceId)
        if (response['success']) {
          this.inviteData.splice(index, 1)
        } else {
          throw response.message
        }
      } catch (error) {
        this.inviteData[index].isRejectLoading = true
        this.$emit('error', error)
      }
    },
    getRoles(id) {
      let roleName
      for (const role of this.getMetaData['roles']) {
        if (role.id === id) {
          roleName = role.name
          break
        }
      }

      return roleName
    }
  }
}
</script>
