export const getNewUserAddStatus = function (state) {
  return state.isAddNewUserClicked
}

export const getNewTagAddStatus = function (state) {
  return state.isAddTagClicked
}

export const getMetaData = function (state) {
  return state.metadata
}

export const getWorkspaceManagerLoadingStatus = function (state) {
    return state.isWorkspaceManagerLoading
}

export const getMfaStatus = function (state) {
    return state.mfaEnabledSuccessfully;
}

export const getNewProxyAddStatus = function (state) {
    return state.newProxyAdded
}
