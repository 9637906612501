<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <!-- START WRAPPER -->
    <div class="flex h-full flex-col">
      <div class="flex min-h-0 flex-1 overflow-hidden">
        <!-- START MAIN CONTENT -->
        <main class="flex min-w-0 flex-1 flex-col overflow-y-auto">
          <AutomationHeader
            title="Yellow Page Leads Collector"
            icon="yellow-pages"
          />

          <section
            class="flex flex-1 border-t border-gray-200 dark:border-gray-700"
          >
            <OptionalSidebar :data="optionalSidebarData" item="center" />

            <div
              class="relative flex flex-1 border-l border-gray-200 dark:border-gray-700"
            >
              <div
                class="relative flex flex-1 flex-col justify-between px-4 py-5 sm:p-6"
              >
                <div class="flex-1">
                  <div class="grid grid-cols-2 gap-6">
                    <Input
                      label="Search URL"
                      labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
                      type="url"
                      text="Ex: https://www.yellowpages.com/search?search_terms=Sel..."
                      rightIcon="search-url"
                    />

                    <Input
                      label="Maximum Results"
                      labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
                      text="Maximum is 5000 results per run"
                    />
                  </div>
                </div>

                <div class="mt-auto flex items-center justify-end">
                  <Button text="Run" rightIcon="right-arrow" />
                </div>
              </div>
            </div>
          </section>
        </main>
        <!-- END MAIN CONTENT -->
      </div>
    </div>
    <!-- END WRAPPER -->
  </div>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import OptionalSidebar from '@/components/automationStore/OptionalSidebar.vue'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
export default {
  name: 'page3',
  components: { AutomationHeader, OptionalSidebar, Input, Button },
  data() {
    return {
      optionalSidebarData: [
        'Enter a Yellow Page Search URL',
        'Add the number of businesses to collect',
        'Run!'
      ]
    }
  }
}
</script>

<style></style>
