<template>
  <div class="relative flex flex-1 border-l border-gray-200 dark:border-gray-700 antialiased">
    <div class="relative flex flex-col justify-between flex-1 px-4 py-5 sm:p-6">
      <div class="flex-1">
        <AgencyWhitelabelHeader activeOption="custom domain"/>

        <div class="mt-8 rounded-lg bg-blue-50 dark:dark:bg-blue-900/50">
          <div class="p-4">
            <div class="flex items-start">
              <SvgIcon name="info" class="w-5 h-5 text-blue-500 dark:text-blue-400" />
              <p class="flex-1 ml-3 text-sm font-medium text-gray-900 dark:text-gray-50">
                You need to create an A record pointing to 33.99.45.142 from your host.domain, see how to <a
                  href="#" title="" class="font-bold text-blue-600 dark:text-blue-200 hover:underline">here</a>.
              </p>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 mt-6 gap-x-6 gap-y-5">
          <div class="col-span-2">
              <Input text="app.agency.com" type="url" labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50" label="Domain"/>
          </div>

          <div class="col-span-2">
            <label for="" class="block text-sm font-medium text-gray-900 dark:text-gray-50">
              Send email from address
            </label>
            <div class="grid grid-cols-4 gap-6 mt-2">
              <div
                  class="overflow-hidden transition-all duration-200 bg-white border border-gray-200 rounded-lg dark:bg-gray-900 dark:border-gray-600 hover:border-gray-300 dark:hover:border-gray-500">
                <div class="p-4">
                  <div class="flex items-center justify-between space-x-5">
                    <label for="aws" class="flex items-center">
                      <img class="object-cover w-10 h-10 rounded-full shrink-0" src="@/assets/images/symbols/aws.svg"
                           alt="">
                      <span
                          class="flex-1 ml-4 mr-auto text-sm font-semibold text-gray-900 cursor-pointer dark:text-gray-50">
                                Amazon Web Services
                              </span>
                    </label>
                    <Checkboxes id="aws" name="aws"/>
                  </div>
                </div>
              </div>

              <div
                  class="overflow-hidden transition-all duration-200 bg-white border border-gray-200 rounded-lg dark:bg-gray-900 dark:border-gray-600 hover:border-gray-300 dark:hover:border-gray-500">
                <div class="p-4">
                  <div class="flex items-center justify-between">
                    <label for="mailgun" class="flex items-center">
                      <img class="object-cover w-10 h-10 rounded-full shrink-0"
                           src="@/assets/images/symbols/mailgun.svg" alt="">
                      <span
                          class="flex-1 ml-4 mr-auto text-sm font-semibold text-gray-900 cursor-pointer dark:text-gray-50">
                                Mailgun
                              </span>
                    </label>
                    <Checkboxes checked id="mailgun" name="mailgun"/>
                  </div>
                </div>
              </div>

              <div
                  class="overflow-hidden transition-all duration-200 bg-white border border-gray-200 rounded-lg dark:bg-gray-900 dark:border-gray-600 hover:border-gray-300 dark:hover:border-gray-500">
                <div class="p-4">
                  <div class="flex items-center justify-between">
                    <label for="sendgrid" class="flex items-center">
                      <img class="object-cover w-10 h-10 rounded-full shrink-0"
                           src="@/assets/images/symbols/sendgrid.svg" alt="">
                      <span
                          class="flex-1 ml-4 mr-auto text-sm font-semibold text-gray-900 cursor-pointer dark:text-gray-50">
                                SendGrid
                              </span>
                    </label>
                    <Checkboxes id="sendgrid" name="sendgrid"/>
                    </div>
                </div>
              </div>

              <div
                  class="overflow-hidden transition-all duration-200 bg-white border border-gray-200 rounded-lg dark:bg-gray-900 dark:border-gray-600 hover:border-gray-300 dark:hover:border-gray-500">
                <div class="p-4">
                  <div class="flex items-center justify-between">
                    <label for="smtp" class="flex items-center">
                      <img class="object-cover w-10 h-10 rounded-full shrink-0" src="@/assets/images/symbols/smtp.svg"
                           alt="">
                      <span
                          class="flex-1 ml-4 mr-auto text-sm font-semibold text-gray-900 cursor-pointer dark:text-gray-50">
                                SMTP
                              </span>
                    </label>
                    <Checkboxes id="smtp" name="smtp"/>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <div>
              <Input type="email" modelValue="arman@texau.com" label="Send email from address" label-class="block text-sm font-medium text-gray-900 dark:text-gray-50"/>
          </div>

          <div>
              <Input type="text" model-value="Arman Mazka" label="Send email from name" labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"/>
          </div>

          <div>
              <Input type="text" modelValue="VWspP6nikyNKEyNtUn4nW8JAML3rRP!L" label="Access API key ID" labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"/>
          </div>

          <div>
              <Input type="text" modelValue="n44erH9AV99kyK3^9N6hgZUPW3@td8H4" labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50" label="Secret access API key ID"/>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-end mt-8">
        <Button text="Add" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import Checkboxes from "@/components/Checkboxes.vue";
import Input from '@/components/Input.vue';
import AgencyWhitelabelHeader from "@/components/settings/agencyWhitelabelHeader.vue";
import SvgIcon from "@/components/SvgIcon.vue";

export default {
  name: "customDomainSection",
  components: {
    AgencyWhitelabelHeader,
    Input,
    Button,
    SvgIcon,
    Checkboxes,
  }
}
</script>

<style scoped>

</style>
