<template>
  <div class="mt-4 text-center">
    <label
      v-if="this.timeLeftForResend >= 0 && showTimer"
      class="block text-sm font-medium text-gray-900 dark:text-gray-50"
      for=""
    >
      Resend code in <span id="timer">{{ timeLeftForResend }}</span> second
    </label>

    <label
      v-if="this.timeLeftForResend < 0"
      class="block cursor-pointer text-sm font-medium text-blue-900 dark:text-blue-400"
      for=""
      @click="onResendClick($event)"
    >
      Resend Now
    </label>
  </div>
  <div
    v-if="isResending"
    class="mt-1 flex w-full flex-col items-center justify-center space-y-4"
  >
    <Spinner size="small" />
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue'
import { resendMailOtp, resendPhoneOtp } from '@/apis/onboarding'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ResendOtpCounter',

  components: {
    Spinner
  },

  props: {
    resendOtpForMail: { type: Boolean, default: false },
    resendOtpForPhone: { type: Boolean, default: false }
  },

  data() {
    return {
      timeLeftForResend: 60,
      isResending: false,
      showTimer: false
    }
  },

  computed: {
    ...mapGetters('registration', ['getRegistrationData'])
  },

  methods: {
    async onResendClick(e) {
      if (
        (e.target && e.target.innerText === 'Resend Now') ||
        e === 'from-outside'
      ) {
        this.isResending = true
        let response
        if (this.resendOtpForPhone) {
          const { email } = new Proxy(this.getRegistrationData, {})
          response = await resendPhoneOtp({ email })
        }
        if (this.resendOtpForMail) {
          response = await resendMailOtp()
        }
        this.isResending = false
        if (response['success']) {
          this.$emit('success', 'OTP resend successful')
          this.showTimer = true
          this.timeLeftForResend = 60
          this.calculateTimeLeft()
          this.$emit('stopAPI', true)
        } else {
          this.$emit('error', response.message)
        }
      }
    },

    calculateTimeLeft() {
      let downloadTimer = setInterval(() => {
        if (this.timeLeftForResend <= 0) {
          this.$emit('stopAPI', false)
          clearInterval(downloadTimer)
        }
        this.timeLeftForResend -= 1
      }, 1000)
    }
  },

  beforeMount() {
    if (this.$route.name === 'Confirm User') {
      return
    }
    if (this.getRegistrationData.email) {
      this.showTimer = true
      this.calculateTimeLeft()
    }
  }
}
</script>

<style scoped></style>
