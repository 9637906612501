<template>
  <div>
    <div
      class="overflow-hidden rounded-xl border border-gray-300 bg-white"
      :class="[
        { 'border-red-600': error === 'default' },
        { 'opacity-50': disabled === 'default' }
      ]"
    >
      <div class="px-4 py-5 sm:p-6">
        <p
          class="text-xl font-bold text-gray-900 dark:text-gray-50"
          :class="[{ 'opacity-50': disabled === 'content' }]"
        >
          {{ heading }}
        </p>
        <p
          class="mt-2 text-sm text-gray-500"
          :class="[{ 'opacity-50': disabled === 'content' }]"
        >
          {{ content }}
        </p>

        <div
          class="mt-6 overflow-hidden rounded-xl border border-gray-300 bg-white"
          :class="[{ 'border-red-600': error === 'footer' }]"
        >
          <div class="py-2 pl-4 pr-2">
            <div class="flex items-center justify-between space-x-5">
              <p
                class="flex-1 text-base font-normal text-gray-900 dark:text-gray-50"
                :class="[{ 'disabled text-red-600': error === 'footer' }]"
              >
                <span class="font-bold"> Note: </span>
                {{ note }}
              </p>

              <div class="shrink-0">
                <Button
                  :text="buttonText"
                  size="small"
                  :color="color"
                  :disabled="
                    error === 'footer' ||
                    disabled === 'default' ||
                    disabled === 'footer'
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from './Button.vue'
export default {
  name: 'Fieldset',
  components: { Button },
  props: {
    buttonText: { type: String, default: 'Placeholder' },
    error: { type: String },
    disabled: { type: String },
    heading: { type: String },
    content: { type: String },
    note: { type: String }
  },
  computed: {
    color() {
      if (
        this.error === 'footer' ||
        this.disabled === 'default' ||
        this.disabled === 'footer'
      ) {
        return 'disabled'
      } else if (this.error === 'default') {
        return 'dangerFilled'
      } else if (this.disabled === 'content') {
        return 'black'
      }
    }
  }
}
</script>

<style></style>
