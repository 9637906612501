import axios from '@/common/axios'

const createLink = async data => {
  try {
    const response = await axios.post(`/account-import/create-link`, data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getLinkData = async id => {
  try {
    const response = await axios.get(`/account-import/${id}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export { createLink, getLinkData }
