<template>
  <div class="w-full">
    <div class="flex items-center space-x-2">
      <img
        v-if="node.logoUrl"
        :src="node.logoUrl"
        alt="logo"
        class="h-8 w-8 shrink-0 rounded-full object-cover"
      />
      <Label :text="`${node.order ? `${node.order}. ` : ''}${node.label}`" />
    </div>

    <div class="my-6 grid grid-cols-3 gap-3">
      <Checkboxes
        key="all"
        label="Select All"
        v-model="isSelectedAll"
        @update:modelValue="updateSelectedAll($event)"
      />
      <Checkboxes
        v-for="output of node.output"
        :key="output.name"
        :label="output.label"
        :modelValue="modelValue.includes(`{{${node.id}.${output.name}}}`)"
        @update:modelValue="updateValue($event, output.name)"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import Checkboxes from './Checkboxes.vue';
import Label from './Label.vue';

const props = defineProps({
  node: {
    type: Object,
    required: true
  },
  modelValue: {
    type: Array,
    required: true
  }
})

const isSelectedAll = ref(false)
const emits = defineEmits(['update:modelValue'])

watch(
  () => props.modelValue,
  () => {
    isSelectedAll.value = props.modelValue.length === props.node.output.length
  }
)

onMounted(() => {
  isSelectedAll.value = props.modelValue.length === props.node.output.length
})

const updateSelectedAll = () => {
  if (isSelectedAll.value) {
    emits(
      'update:modelValue',
      props.node.output.map(output => ({
        label: output.label,
        value: `{{${props.node.id}.${output.name}}}`
      }))
    )
  } else {
    emits('update:modelValue', [])
  }
}

const updateValue = (isSelected, value) => {
  const checked = props.node.output
    .filter(output => {
      if (output.name === value) {
        return isSelected
      } else {
        return props.modelValue.includes(`{{${props.node.id}.${output.name}}}`)
      }
    })
    .map(output => ({
      label: output.label,
      value: `{{${props.node.id}.${output.name}}}`
    }))
  emits('update:modelValue', checked)
}
</script>
