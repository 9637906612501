<!-- 
  Pagination
  ------------
  A simple pagination component for Vue.
  ------------
  Props:
    - count: number, the total number of pages.
    - page: number, the current page.
    - pagechanged: function, the callback function when the page is changed.
    - onlyIcon: boolean, whether to display the next/previous button text or not.
    - type: list or select. default is list.
 -->

<template>
  <nav
    class="z-0 inline-flex items-center"
    :class="{
      'space-x-2': type === 'list',
      'space-x-4': type === 'select'
    }"
  >
    <a
      href="#"
      title=""
      class="inline-flex items-center rounded-lg border border-transparent px-2 py-2.5 text-sm font-medium transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
      :class="[
        {
          'text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-gray-300 dark:text-gray-50':
            !hasPreviousPage,
          'rounded-lg text-sm font-medium text-blue-600 hover:bg-blue-50 hover:text-blue-700 focus:ring-blue-600':
            hasPreviousPage
        }
      ]"
      @click="onClickFirstPage"
    >
      <SvgIcon name="pagination-left" />
      <span :class="{ 'sr-only': onlyIcon }"> First </span>
    </a>
    <a
      href="#"
      title=""
      class="inline-flex items-center rounded-lg border border-transparent px-2 py-2.5 text-sm font-medium transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
      :class="[
        {
          'text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-gray-300 dark:text-gray-50':
            !hasPreviousPage,
          'rounded-lg text-sm font-medium text-blue-600 hover:bg-blue-50 hover:text-blue-700 focus:ring-blue-600':
            hasPreviousPage
        }
      ]"
      @click="onPreviousPageClicked"
    >
      <SvgIcon name="pagination-left" />
      <span :class="{ 'sr-only': onlyIcon }"> Previous </span>
    </a>

    <template v-if="type === 'select'">
      <!-- TODO: UPDATE THIS TO HANDLE IN A BETTER WAY -->
      <!-- update:modelValue => Event emitted by the Select component -->
      <Select
        :options="new Array(count).fill(0).map((a, i) => i + 1)"
        @update:modelValue="onPageChanged"
        :modelValue="page"
      />
    </template>

    <template v-else>
      <a
        v-for="index in pageNumber"
        :key="index"
        href="#"
        title=""
        class="inline-flex items-center justify-center rounded-lg border border-transparent p-2.5 text-sm font-semibold transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
        :class="[
          {
            'bg-blue-600 text-white hover:bg-blue-800 focus:ring-blue-600':
              index.name === page,
            'bg-transparent text-gray-900 hover:bg-gray-100 focus:ring-gray-300  dark:text-gray-50':
              index.name !== page
          }
        ]"
        @click="onPageChanged(index.name)"
      >
        <span class="inline-flex h-5 w-5 items-center justify-center">
          {{ index.name }}
        </span>
      </a>
    </template>
    <a
      href="#"
      title=""
      class="inline-flex items-center rounded-lg border border-transparent text-sm font-medium transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
      :class="[
        {
          'text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-gray-300 dark:text-gray-50':
            !hasNextPage,
          'rounded-lg text-sm font-medium text-blue-600 hover:bg-blue-50 hover:text-blue-700 focus:ring-blue-600':
            hasNextPage,
          'p-2.5': type === 'select',
          'px-2 py-2.5': type === 'list'
        }
      ]"
      @click="onNextPageClicked"
    >
      <span :class="{ 'sr-only': onlyIcon }"> Next </span>
      <SvgIcon
        name="pagination-right"
        :class="{
          'ml-2 -mr-0.5': !onlyIcon
        }"
      />
    </a>
    <a
      href="#"
      title=""
      class="inline-flex items-center rounded-lg border border-transparent px-2 py-2.5 text-sm font-medium transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
      :class="[
        {
          'text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-gray-300 dark:text-gray-50':
            !hasNextPage,
          'rounded-lg text-sm font-medium text-blue-600 hover:bg-blue-50 hover:text-blue-700 focus:ring-blue-600':
            hasNextPage
        }
      ]"
      @click="onClickLastPage"
    >
      <span :class="{ 'sr-only': onlyIcon }"> Last </span>
      <SvgIcon
        name="pagination-right"
        :class="{
          'ml-2 -mr-0.5': !onlyIcon
        }"
      />
    </a>
  </nav>
</template>

<script>
import Select from './Select.vue'
import SvgIcon from './SvgIcon.vue'
export default {
  name: 'Pagination',
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    count: {
      type: Number,
      required: true
    },
    page: {
      type: Number
    },
    type: {
      type: String,
      default: 'list'
    },
    onlyIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onPageChanged(page) {
      this.$emit('pagechanged', Number(page))
    },
    onNextPageClicked() {
      if (this.hasNextPage) this.onPageChanged(this.page + 1)
    },
    onPreviousPageClicked() {
      if (this.hasPreviousPage) this.onPageChanged(this.page - 1)
    },
    onClickFirstPage() {
      if (this.hasPreviousPage) this.$emit('pagechanged', 1)
    },
    onClickLastPage() {
      if (this.hasNextPage) this.$emit('pagechanged', this.count)
    }
  },
  computed: {
    startPage() {
      if (this.page === 1) {
        return 1
      }

      if (this.page === this.count) {
        return this.count - this.maxVisibleButtons + 1
      }

      return this.page - 1
    },
    hasPreviousPage() {
      return this.page > 1
    },
    hasNextPage() {
      return this.page < this.count
    },
    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.count)
    },
    pageNumber() {
      const range = []

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i
        })
      }

      return range
    }
  },
  components: { SvgIcon, Select }
}
</script>
