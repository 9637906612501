import axios from '@/common/axios'

const uploadCsv = async formData => {
  try {
    const response = await axios.post(`/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const downloadCsv = async (executionId, nodeId, all) => {
  try {
    const response = await axios.get(
      `/executions/${executionId}/nodes/${nodeId}/csv-result`,
      {
        params: {
          all
        }
      }
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const downloadWorkflowCsv = async executionId => {
  try {
    const response = await axios.get(`/executions/result/${executionId}/csv`)
    return response.data
  } catch (error) {
    return error?.response?.data || error
  }
}

export { uploadCsv, downloadCsv, downloadWorkflowCsv }
