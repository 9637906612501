<template>
  <div class="border-0 p-0 text-gray-900 dark:bg-gray-900 dark:text-gray-50">
    <label
      v-if="isLabel"
      for=""
      class="flex text-sm font-medium text-gray-900 dark:text-gray-50"
      :class="labelClass"
    >
      {{ label }}
      {{ isRequired === false ? '(Optional)' : null }}
      <SvgIcon
        class="mb-3 ml-1 h-2 w-2 text-red-600"
        name="star"
        v-if="isRequired"
      />
    </label>
    <Datepicker
      :min-date="minDate"
      :max-date="maxDate"
      :model-value="modelValue"
      @update:model-value="
        e => {
          if (e === null) {
            $emit('update:model-value', null)
            $emit('blur', null)
          } else {
            $emit(
              'update:model-value',
              new Date(e).toISOString().replace('.000', '')
            )
            $emit('blur', new Date(e).toISOString().replace('.000', ''))
          }
        }
      "
      :enable-time-picker="false"
      class="mt-1.5"
      :placeholder="placeholder"
      :teleport="true"
    ></Datepicker>
    <p v-if="description" class="mt-2 text-sm text-gray-500">
      {{ description }}<slot></slot>
    </p>
  </div>
</template>

<script setup>
import Datepicker from '@vuepic/vue-datepicker'
import { computed } from 'vue'
import '@vuepic/vue-datepicker/dist/main.css'

const props = defineProps({
  modelValue: {},
  label: String,
  labelClass: String,
  placeholder: String,
  validation: Array,
  description: Array,
  isRequired: { type: Boolean, default: undefined }
})

defineEmits(['update:model-value', 'blur'])

const minDate = computed(() => {
  if (
    props.validation &&
    props.validation.length > 0 &&
    props.validation[0].config?.pastDatesAllowed
  ) {
    return null
  } else {
    return new Date()
  }
})

const maxDate = computed(() => {
  if (props.validation && props.validation.length > 0) {
    // Get the current date and time
    const currentDate = new Date()

    // Calculate the new date by adding milliseconds
    const newDate = new Date(
      currentDate.getTime() + props.validation[0].config.dateUpto
    )
    return newDate
  } else {
    return null
  }
})

const isLabel = computed(() => {
  return props.label !== ''
})
</script>

<style>
.dp__action_select {
  background-color: #1c80cf !important;
}

.dp__action_select:hover {
  background-color: #125386 !important;
}
</style>
