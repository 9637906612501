<template>
  <executionSettings
    :selectedRow="selectedRow"
    v-if="openExecutionSettingsModal"
    @closeModal="openExecutionSettingsModal = false"
    @error="emitError"
    @success="emitSuccess"
  />
  <div class="h-full w-full overflow-auto" ref="tableBody">
    <Menu
      :parentEl="selectedRow?.ref"
      :showTop="selectedRow?.showTop"
      @close="selectedRow = null"
      :options="
        selected === 0
          ? [
              {
                name: 'Clone',
                icon: 'copy',
                optionClick: async () => {
                  cloning = true
                  await cloneWorkflow(selectedRow?.id)
                  $refs.tableBody.scrollTo(0, 0)
                  cloning = false
                  selectedRow = null
                },
                color: 'gray',
                loading: cloning
              },
              {
                name: 'Reset',
                icon: 'reset',
                optionClick: async () => {
                  resetWorkflow(
                    selectedRow?.id,
                    selectedRow?.iconUrl,
                    selectedRow?.name
                  )
                },
                color: 'gray'
              },
              {
                name: 'Manage Executions',
                icon: 'preferences',
                optionClick: async () => {
                  openExecutionSettingsModal = true
                },
                color: 'gray'
              },
              {
                name: 'Delete',
                icon: 'delete',
                optionClick: () => {
                  deleteWorkflows(selectedRow?.id)
                  selectedRow = null
                },
                color: 'red',
                confirmationText: 'Really delete?'
              }
            ]
          : [
              {
                name: 'Export To Google Sheets',
                icon: 'send',
                optionClick: async () => {
                  $refs.tableBody.scrollTo(0, 0)

                  $emit('openGoogleSheetsModal', selectedRow)
                  selectedRow = null
                },
                color: 'gray'
              },
              {
                name: 'Download CSV',
                icon: 'download',
                optionClick: async () => {
                  downloadCSV = true
                  await downloadCsvFile(selectedRow)
                  $refs.tableBody.scrollTo(0, 0)
                  downloadCSV = false
                  selectedRow = null
                },
                color: 'gray',
                loading: downloadCSV
              },
              {
                name: 'Delete',
                icon: 'delete',
                optionClick: () => {
                  deleteResults(selectedRow?.id)
                  selectedRow = null
                },
                color: 'red',
                confirmationText: 'Really delete?'
              }
            ]
      "
    />
    <table class="min-w-full">
      <thead class="sticky top-0 bg-gray-50 dark:bg-gray-700">
        <tr>
          <th
            scope="col"
            class="group px-3 text-left py-3.5 text-sm font-semibold text-gray-900 dark:text-gray-50 rounded-tl-lg rounded-bl-lg"
          >
            <input
              type="checkbox"
              v-model="isCheckAll"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-800 sm:left-6"
              @click="checkAll()"
            />
          </th>
          <th
            v-for="(col, index) of columns"
            scope="col"
            class="group px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
            :class="{
              'rounded-r-lg': col.isInvisible,
              'cursor-pointer': col.sortable,
              'w-[11rem]': col.buttonWidth
            }"
            @click="onSort(col)"
          >
            <span :class="{ 'sr-only': col.isInvisible }" class="flex gap-1">
              {{ col.name }}
              <span v-if="col.sortable">
                <svg-icon
                  name="arrow-up-full"
                  class="h-4 w-4"
                  :class="{
                    'fill-gray-800': col.key === sortField,
                    'rotate-180': sortOrder === 'desc',
                    'rotate-0': sortOrder === 'asc',
                    'fill-gray-50 group-hover:fill-gray-300':
                      col.key !== sortField
                  }"
                />
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody
        class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
      >
        <WorkflowListTableBody
          v-if="selected === 0"
          :deleteWorkflows="deleteWorkflows"
          :propsTableData="data"
          @error="emitError"
          @success="emitSuccess"
          @trigger-menu="selectedRow = $event"
          :workflowIdArr="workflowIdArr"
          @updateWorkflowArr="updateWorkflowArr"
        />
        <WorkflowResultTableBody
          v-else-if="selected === 1"
          :data="dataResults"
          @trigger-menu="selectedRow = $event"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import Checkboxes from '../Checkboxes.vue'
import Menu from './menu.vue'
import WorkflowListTableBody from './workflowListTableBody.vue'
import WorkflowResultTableBody from './workflowResultTableBody.vue'
import executionSettings from '../automationStore/executionSettings.vue'
export default {
  name: 'DataStoreTable',
  props: {
    selected: {
      type: Number,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    dataResults: {
      type: Array,
      required: true
    },
    deleteWorkflows: {
      type: Function,
      required: true
    },
    resetWorkflow: {
      type: Function,
      required: true
    },
    deleteResults: {
      type: Function,
      required: true
    },
    cloneWorkflow: {
      type: Function,
      required: true
    },
    downloadCsvFile: {
      type: Function
    },
    sortField: {
      type: String
    },
    sortOrder: {
      type: String
    }
  },
  data() {
    return {
      selectedRow: null,
      cloning: false,
      downloadCSV: false,
      isCheckAll: false,
      workflowIdArr: [],
      openExecutionSettingsModal: false
    }
  },
  emits: ['error', 'success', 'openGoogleSheetsModal', 'sort'],
  methods: {
    emitError(e) {
      this.$emit('error', e)
    },
    emitSuccess(data) {
      this.$emit('success', data)
    },
    onSort(col) {
      if (!col.sortable) return
      let order = 'asc'
      let field = col.key

      if (field === this.sortField) {
        if (this.sortOrder === 'asc') {
          order = 'desc'
        } else {
          order = undefined
          field = undefined
        }
      }

      this.$emit('sort', { field, order })
    },
    checkAll() {
      this.isCheckAll = !this.isCheckAll
      this.workflowIdArr = []
      if (this.isCheckAll) {
        for (const val of this.data) {
          this.workflowIdArr.push(val._id)
        }
      }
      this.$emit('updateWorkflowIdToDelete', this.workflowIdArr)
    },
    updateWorkflowArr(data) {
      this.workflowIdArr = data
      if (this.data.length === this.workflowIdArr.length) {
        this.isCheckAll = true
      } else {
        this.isCheckAll = false
      }
      this.$emit('updateWorkflowIdToDelete', this.workflowIdArr)
    }
  },
  components: {
    WorkflowListTableBody,
    WorkflowResultTableBody,
    Checkboxes,
    Menu,
    executionSettings
  }
}
</script>
