<template>
  <div class="flex flex-col mt-2">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div class="overflow-hidden">
          <table class="min-w-full">
            <tbody class="bg-white dark:bg-gray-900">
            <tr v-for="(item, index) in userDetails" :key="index">
              <td class="px-0 py-2 whitespace-nowrap">
                <div>
                  <div class="mt-1.5">
                    <Input type="text" :modelValue="item.name" label="Label" label-class="sr-only"/>
                  </div>
                </div>
              </td>

              <td class="px-3 py-2 whitespace-nowrap">
                <div>
                  <div class="mt-1.5">
                    <Input type="text" :modelValue="item.role" label="Label" labelClass="sr-only" />
                  </div>
                </div>
              </td>

              <td class="px-3 py-2 whitespace-nowrap">
                <div>
                  <div class="mt-1.5">
                    <Input type="text" :modelValue="item.workspace" label="Label" labelClass="sr-only" />
                  </div>
                </div>
              </td>

              <td class="px-3 py-2 whitespace-nowrap">
                <div>
                  <div class="mt-1.5">
                    <Input type="text" :modelValue="item.email" label="Label" labelClass="sr-only" />
                  </div>
                </div>
              </td>

              <td class="px-0 py-2 whitespace-nowrap">
                <Button :text="item.isAdd ? 'Add' : item.isDelete ? 'Delete' : ''" :color="item.isAdd ? 'success' : item.isDelete ? 'danger' : ''" class="inline-flex w-full items-center bg-transparent justify-center px-4 py-2.5 sm:text-sm text-base font-semibold"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";

export default {
  name: "userListForSendReport",
  props: {
    userDetails: {type: Array, default:[
        {
          name: 'Arman Mazka',
          role: 'CMO',
          workspace: 'Workplenty',
          email: 'arman@workplenty.com',
          isAdd: true
        },
        {
          name: 'Arman Mazka',
          role: 'CMO',
          workspace: 'Workplenty',
          email: 'arman@workplenty.com',
          isDelete: true
        }
      ]}
  },
  components: {
    Input,
    Button
  }
}
</script>

<style scoped>

</style>
