<template>
  <div ref="container" @dblclick="openInputEditor" class="h-[40px] w-[40px]">
    <Popper
      v-if="errorMessages?.length"
      hover
      @click.stop
      @dblclick.stop
      class="absolute right-[9px] -top-[22px]"
      placement="right"
      locked
      :offset-distance="6"
      @open:popper="container.parentElement.style.zIndex = '1000'"
      @close:popper="container.parentElement.style.zIndex = '0'"
    >
      <SvgIcon name="warning" class="animate-pulse stroke-yellow-500 p-0.5" />
      <template #content>
        <ul class="rounded bg-white p-2 text-xs shadow-md min-w-[190px]">
          <li v-for="message in errorMessages">* {{ message }}</li>
        </ul>
      </template>
    </Popper>
    <SvgIcon
      :name="data.automationData.logoUrl"
      class="h-[40px] w-[40px] cursor-pointer"
      :class="{
        'rounded-full border-2 border-blue-500': selected,
        ' border-yellow-400 rounded-full border-2 ': errorMessages?.length
      }"
    />
    <p
      class="absolute -left-[25px] mt-2 w-[90px] overflow-hidden rounded-lg bg-white bg-opacity-40 p-1 text-center text-xs font-semibold text-gray-900 group-hover:text-blue-600 dark:text-gray-50 dark:group-hover:text-gray-50"
      v-if="nodeLabel"
    >
      {{ nodeLabel }}
    </p>
    <div
      v-for="{ position, style, isRouter } in handlers"
      class="group absolute z-10 flex h-[24px] w-[24px] items-center justify-center"
      @mouseenter="hoverHandle = position"
      @mouseleave="hoverHandle = null"
      :style="getPosForHandle(position, style)"
      @click="
        canAddNode({ isRouter, position }) && openRightPanel($event, position)
      "
      @dblclick="
        canAddNode({ isRouter, position }) && openRightPanel($event, position)
      "
    >
      <SvgIcon
        v-show="canAddNode({ isRouter, position })"
        class="h-[12px] w-[12px] rounded-full bg-blue-500 text-white transition-transform"
        name="plus"
        :class="{
          'scale-[2]':
            hoverHandle === position ||
            (rightPanelSelectedNode?.nodeId === id &&
              rightPanelSelectedNode?.position === position),
          'scale-0':
            hoverHandle !== position &&
            !(
              rightPanelSelectedNode?.nodeId === id &&
              rightPanelSelectedNode?.position === position
            ),
          'bg-green-500': isRouter && position === 'top',
          'bg-red-500': isRouter && position === 'bottom'
        }"
      />
    </div>

    <Handle
      v-for="{ position, connected, style, isRouter } in handlers"
      :id="position"
      :type="getHandleType(position)"
      :position="position"
      :style="style"
      class="h-[10px] w-[10px]"
      :class="{
        'bg-blue-200':
          connected &&
          (position === 'bottom' || position === 'top') &&
          !isRouter,
        'bg-blue-500':
          connected && !(position === 'bottom' || position === 'top'),
        'bg-green-500': connected && isRouter && position === 'top',
        'bg-red-500': connected && isRouter && position === 'bottom',

        'bg-gray-500': !connected
      }"
      :connectable="false"
    />
  </div>
</template>

<script setup>
import { NODE_SIZES, NODE_TYPE_NAMES, UTILITY_TYPES } from '@/common/constants'
import SvgIcon from '@/components/SvgIcon.vue'
import { Handle, Position, useVueFlow } from '@vue-flow/core'
import { onMounted, ref, watch } from 'vue'
import Popper from 'vue3-popper'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  type: {
    type: String
  },
  label: {
    type: String
  },
  data: {
    type: Object
  },
  position: {
    type: Object
  },
  menuOpenId: {
    type: String
  },
  selected: {
    type: Boolean
  },
  rightPanelSelectedNode: {},
  errorMessages: Array
})
const container = ref()
const { edges, removeSelectedNodes, getSelectedNodes } = useVueFlow()

const emit = defineEmits([
  'updateNodeInternals',
  'error',
  'open-panel',
  'toggle-menu',
  'open-input-editor'
])

const handlers = ref([])
const hoverHandle = ref()
const nodeLabel = ref()

watch(
  () => edges.value.length,
  () => setHandles()
)

watch(
  () => props.data.savedInputs,
  n => {
    setNodeLabel()
  }
)

onMounted(() => {
  setHandles()
  setNodeLabel()
})

const setNodeLabel = () => {
  if (
    UTILITY_TYPES.DELAY === props.data?.automationData.utilityType &&
    props.data.savedInputs &&
    props.data.savedInputs?.delayValue &&
    props.data.savedInputs?.delayUnit
  ) {
    nodeLabel.value = `Delay for ${props.data.savedInputs?.delayValue} ${props.data.savedInputs?.delayUnit}`
  }
}

const setHandles = () => {
  const allConnectedHandles = edges.value
    .filter(e => e.source === props.id)
    .map(e => e.sourceHandle)
    .concat(
      edges.value.filter(e => e.target === props.id).map(e => e.targetHandle)
    )

  if (props.data.automationData.utilityType === UTILITY_TYPES.ROUTER) {
    // add two handles for routers
    handlers.value = handlers.value.concat(
      {
        position: Position.Top,
        style: {
          right: '-3px',
          left: 'auto',
          top: '0px'
        },
        connected: allConnectedHandles.includes(Position.Top),
        isRouter: true
      },
      {
        position: Position.Bottom,
        style: {
          right: '-3px',
          left: 'auto',
          bottom: '0px'
        },
        connected: allConnectedHandles.includes(Position.Bottom),
        isRouter: true
      },
      {
        position: Position.Left,
        style: {},
        connected: allConnectedHandles.includes(Position.Left)
      }
    )
  } else {
    if (
      allConnectedHandles.includes(Position.Bottom) ||
      allConnectedHandles.includes(Position.Top)
    ) {
      handlers.value = handlers.value.concat({
        position: allConnectedHandles[0],
        style: {},
        connected: true
      })
    } else {
      handlers.value = handlers.value.concat(
        {
          position: Position.Right,
          style: {},
          connected: allConnectedHandles.includes(Position.Right)
        },
        {
          position: Position.Left,
          style: {},
          connected: allConnectedHandles.includes(Position.Left)
        }
      )
    }
  }
}

const getHandleType = pos =>
  props.data.targetHandle === pos ? 'target' : 'source'

const getPosForHandle = pos => {
  if (pos === Position.Left) {
    return {
      top: NODE_SIZES[NODE_TYPE_NAMES.UTILITY_NODE] / 2 - 12 + 'px',
      left: '-10px'
    }
  }
  if (pos === Position.Right) {
    return {
      top: NODE_SIZES[NODE_TYPE_NAMES.UTILITY_NODE] / 2 - 12 + 'px',
      right: '-12px'
    }
  }
  if (pos === Position.Top) {
    return {
      left: 'auto',
      top: '-7px',
      right: '-5px'
    }
  }
  if (pos === Position.Bottom) {
    return {
      left: 'auto',
      bottom: '-7px',
      right: '-5px'
    }
  }
}

const openInputEditor = () => {
  if (props.data.automationData.utilityType === UTILITY_TYPES.ROUTER) return
  emit('open-input-editor', {
    id: props.id,
    ...props.data
  })
}

const openRightPanel = (e, position) => {
  e.stopPropagation()
  removeSelectedNodes(getSelectedNodes.value)
  emit('open-panel', props, position)
}

const canAddNode = ({ position, isRouter }) => {
  return isRouter || (position !== 'bottom' && position !== 'top')
}
</script>
