<template>
  <div
    class="relative flex-1 border-l border-gray-200 antialiased dark:border-gray-700"
  >
    <div
      v-if="userRole && userRole !== 'owner'"
      class="flex items-center h-full justify-center"
    >
      <div class="flex gap-2 items-center text-red-500">
        <SvgIcon class="h-5 w-5" name="important" />
        <p class="font-[500] text-xl">Only Owner Can Access This Page</p>
      </div>
    </div>
    <template v-else>
      <div class="" v-if="loading">
        <div class="flex flex-col gap-4">
          <div class="px-4 py-5 sm:p-5">
            <div class="mx-auto max-w-2xl">
              <div>
                <label
                  for=""
                  class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  Developer API Key
                </label>
                <div class="relative mt-1.5">
                  <Skeleton
                    height="41px"
                    width="672px"
                    borderRadius="8px"
                  ></Skeleton>
                </div>
              </div>

              <div class="mt-8 flex items-center justify-between">
                <Skeleton
                  height="41px"
                  width="174px"
                  borderRadius="8px"
                ></Skeleton>

                <Skeleton
                  height="41px"
                  width="174px"
                  borderRadius="8px"
                ></Skeleton>
              </div>
            </div>
          </div>
          <div class="px-4 py-5 sm:p-5">
            <div class="mx-auto max-w-2xl">
              <div>
                <label
                  for=""
                  class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  X-Texau-Context
                </label>
                <div class="relative mt-1.5">
                  <Skeleton
                    height="41px"
                    width="672px"
                    borderRadius="8px"
                  ></Skeleton>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 py-5 sm:p-5">
            <div class="mx-auto max-w-2xl">
              <div>
                <label
                  for=""
                  class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  Organisation Id
                </label>
                <div class="relative mt-1.5">
                  <Skeleton
                    height="41px"
                    width="672px"
                    borderRadius="8px"
                  ></Skeleton>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="px-4 py-5 sm:p-5">
        <div class="mx-auto max-w-2xl">
          <Input
            labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
            label="X-Texau-Context"
            v-model="context"
            :readonly="true"
            right-icon="copy"
            right-icon-mouse="cursor-pointer"
            @onRightIconClick="copy('context')"
          />
          <div
            v-show="copyContext"
            class="mt-4 overflow-hidden rounded-lg bg-green-100 dark:bg-green-900/50"
          >
            <div class="px-4 py-3">
              <div class="flex items-center">
                <SvgIcon name="check" />
                <span
                  class="ml-3 text-sm font-medium text-green-700 dark:text-green-300"
                >
                  X-TexAu-Context header has been copied successfully!
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
        </div>
      </div>
      <div v-else class="flex flex-col gap-4">
        <div class="px-4 py-5 sm:p-5">
          <div class="mx-auto max-w-2xl">
            <div>
              <label
                for=""
                class="block text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                Developer API Key
              </label>
              <div class="relative mt-1.5">
                <Input
                  text="•••••••••••••••••••••••••••••••••••••••••••••••••••••••••"
                  v-model="apiKeyToShow"
                  :key="apiKey"
                  :readonly="true"
                  :right-icon="apiKeyToShow === '' ? '' : 'copy'"
                  right-icon-mouse="cursor-pointer"
                  @onRightIconClick="copy('api')"
                />
              </div>
            </div>

            <div
              class="mt-4 overflow-hidden rounded-lg bg-green-100 dark:bg-green-900/50"
            >
              <div class="px-4 py-3" v-show="copied">
                <div class="flex items-center">
                  <SvgIcon name="check" />
                  <span
                    class="ml-3 text-sm font-medium text-green-700 dark:text-green-300"
                  >
                    API key has been copied successfully!
                  </span>
                </div>
              </div>
            </div>

            <div class="mt-4 flex items-center justify-between">
              <div>
                <Button
                  :showLoader="isLoading"
                  :text="
                    isAccessTokenCreated ? 'Regenerate API Key' : 'Add API Key'
                  "
                  :leftIcon="
                    !isLoading && (isAccessTokenCreated ? 'refresh' : 'plus')
                  "
                  @click="generateKey"
                  v-if="!apiKey"
                />
                <Button
                  :showLoader="isLoading"
                  text="Delete"
                  color="danger"
                  @click="deleteKey"
                  v-else
                />
              </div>

              <a
                href="https://texau.gitbook.io/api-docs/quick-start"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button
                  color="secondary"
                  text="Click to learn more"
                  rightIcon="external-link"
                />
              </a>
            </div>
          </div>
        </div>

        <div class="px-4 py-5 sm:p-5">
          <div class="mx-auto max-w-2xl">
            <Input
              labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
              label="X-TexAu-Context"
              v-model="context"
              :readonly="true"
              right-icon="copy"
              right-icon-mouse="cursor-pointer"
              @onRightIconClick="copy('context')"
            />
            <div
              v-show="copyContext"
              class="mt-4 overflow-hidden rounded-lg bg-green-100 dark:bg-green-900/50"
            >
              <div class="px-4 py-3">
                <div class="flex items-center">
                  <SvgIcon name="check" />
                  <span
                    class="ml-3 text-sm font-medium text-green-700 dark:text-green-300"
                  >
                    X-TexAu-Context header has been copied successfully!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="px-4 py-5 sm:p-5">
          <div class="mx-auto max-w-2xl">
            <Input
              labelClass="block text-sm font-medium text-gray-900 dark:text-gray-50"
              label="Organisation Id"
              v-model="organisationId"
              :readonly="true"
              right-icon="copy"
              right-icon-mouse="cursor-pointer"
              @onRightIconClick="copy('organisationId')"
            />
            <div
              v-show="copyOrganisationId"
              class="mt-4 overflow-hidden rounded-lg bg-green-100 dark:bg-green-900/50"
            >
              <div class="px-4 py-3">
                <div class="flex items-center">
                  <SvgIcon name="check" />
                  <span
                    class="ml-3 text-sm font-medium text-green-700 dark:text-green-300"
                  >
                    Organisation Id has been copied successfully!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'
import { getUsers } from '@/apis/user'
import TextArea from '@/components/TextArea.vue'
import { mapState } from 'vuex'

//API call
import { generateApiKey, deleteApiKey } from '@/apis/settings/settings-api-key'

export default {
  name: 'apiKeySection',
  components: {
    SvgIcon,
    Input,
    Button,
    TextArea
  },
  data() {
    return {
      apiKey: '',
      apiKeyToShow: '',
      copied: false,
      copyContext: false,
      copyOrganisationId: false,
      plus: 'plus',
      isLoading: false,
      isAccessTokenCreated: false,
      loading: true
    }
  },
  computed: {
    context() {
      return JSON.stringify({
        orgUserId: window.$cookies.get('orgUserId'),
        workspaceId: window.$cookies.get('workspaceId')
      })
    },
    organisationId() {
      return window.$cookies.get('organisationId')
    },

    ...mapState('settings', ['userRole'])
  },
  async mounted() {
    let currWorkspaceId = window.$cookies.get('workspaceId')
    const response = await getUsers()
    for (const data of response.data) {
      if (data.workspaceId === currWorkspaceId) {
        this.isAccessTokenCreated = data.isAccessTokenCreated
        break
      }
    }
    this.loading = false
  },
  methods: {
    async generateKey() {
      try {
        this.plus = ''
        this.isLoading = true
        const response = await generateApiKey()
        if (response['success']) {
          this.apiKey = response.data.apiAccessToken

          this.plus = 'plus'
          this.apiKeyToShow =
            this.apiKey.slice(0, 5) + '**********' + this.apiKey.slice(-1 - 4)
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('apiResponse', { type: 'error', message: error })
      }
      this.isLoading = false
    },
    async deleteKey() {
      try {
        this.isLoading = true
        const response = await deleteApiKey()
        if (response['success']) {
          this.copied = false
          this.apiKey = ''
          this.apiKeyToShow = ''
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('apiResponse', { type: 'error', message: error })
      }
      this.isLoading = false
    },
    async copy(type) {
      try {
        if (type === 'api') {
          await navigator.clipboard.writeText(this.apiKey)
          this.copied = true
        } else if (type === 'context') {
          await navigator.clipboard.writeText(this.context)
          this.copyContext = true
        } else {
          await navigator.clipboard.writeText(this.organisationId)
          this.copyOrganisationId = true
        }
        setTimeout(this.removeCopiedContainer, 2000)
      } catch (error) {
        console.log(error)
      }
    },
    removeCopiedContainer(type) {
      this.copied = false
      this.copyContext = false
      this.copyOrganisationId = false
    }
  }
}
</script>

<style scoped></style>
