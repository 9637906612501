<template>
  <div
    class="relative h-full flex-1 border-l border-gray-200 antialiased dark:border-gray-700"
  >
    <div
      class="relative flex h-full flex-1 flex-col justify-between px-4 py-5 sm:p-6"
    >
      <div class="flex-1">
        <div
          v-if="userRole !== 'viewer'"
          class="flex items-center px-4 sm:px-5 justify-end"
        >
          <Button
            text="Configure Slack Webhook"
            leftIcon="preferences"
            class="h-[33px]"
            @click="$emit('modalActivation', this.notiData?.slack?.config)"
          />
        </div>
        <div class="px-4 py-5 sm:p-5">
          <div class="flex flex-col">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div
                class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
              >
                <div class="overflow-hidden">
                  <table class="min-w-full">
                    <thead class="bg-gray-50 dark:bg-gray-700">
                      <tr>
                        <th
                          scope="col"
                          class="min-w-[12rem] rounded-l-lg py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                        >
                          Event
                        </th>

                        <th
                          scope="col"
                          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                        >
                          Slack
                        </th>

                        <!-- <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                    >
                      MS Teams
                    </th> -->

                        <th
                          scope="col"
                          class="px-3 rounded-r-lg py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                        >
                          Email
                        </th>

                        <!-- <th
                      scope="col"
                      class="rounded-r-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                    >
                      Push
                    </th> -->
                      </tr>
                    </thead>

                    <tbody
                      class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
                    >
                      <tr>
                        <td
                          class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                        >
                          Execution Success
                        </td>

                        <td class="whitespace-nowrap px-3 py-4">
                          <button
                            type="button"
                            :class="[
                              { 'pointer-events-none': userRole === 'viewer' },
                              {
                                '!bg-blue-600 dark:bg-blue-500': slackES,
                                'bg-gray-400 dark:bg-gray-500': !slackES
                              }
                            ]"
                            class="elative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
                            role="switch"
                            name="slack_executionSuccess"
                            aria-checked="false"
                            :aria-checked="slackES.toString()"
                            @click="
                              ;(slackES = !slackES), change($event, 'slackES')
                            "
                          >
                            <span
                              aria-hidden="true"
                              class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              :class="{
                                'translate-x-5': slackES,
                                'translate-x-0': !slackES
                              }"
                            ></span>
                          </button>
                        </td>

                        <!-- <td class="whitespace-nowrap px-3 py-4">
                      <button
                        type="button"
                        class="elative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
                        role="switch"
                        aria-checked="false"
                        :aria-checked="msTeamsES.toString()"
                        @click="msTeamsES = !msTeamsES"
                        :class="{
                          '!bg-blue-600 dark:bg-blue-500': msTeamsES,
                          'bg-gray-400 dark:bg-gray-500': !msTeamsES
                        }"
                      >
                        <span
                          aria-hidden="true"
                          class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          :class="{
                            'translate-x-5': msTeamsES,
                            'translate-x-0': !msTeamsES
                          }"
                        ></span>
                      </button>
                    </td> -->

                        <td class="whitespace-nowrap px-3 py-4">
                          <button
                            type="button"
                            class="elative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
                            role="switch"
                            aria-checked="false"
                            name="email_executionSuccess"
                            :aria-checked="emailES.toString()"
                            @click="
                              ;(emailES = !emailES), change($event, 'emailES')
                            "
                            :class="[
                              { 'pointer-events-none': userRole === 'viewer' },
                              {
                                '!bg-blue-600 dark:bg-blue-500': emailES,
                                'bg-gray-400 dark:bg-gray-500': !emailES
                              }
                            ]"
                          >
                            <span
                              aria-hidden="true"
                              class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              :class="{
                                'translate-x-5': emailES,
                                'translate-x-0': !emailES
                              }"
                            ></span>
                          </button>
                        </td>

                        <!-- <td class="whitespace-nowrap px-3 py-4">
                      <button
                        type="button"
                        class="elative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
                        role="switch"
                        aria-checked="false"
                        :aria-checked="pushEF.toString()"
                        @click="pushEF = !pushEF"
                        :class="{
                          '!bg-blue-600 dark:bg-blue-500': pushEF,
                          'bg-gray-400 dark:bg-gray-500': !pushEF
                        }"
                      >
                        <span
                          aria-hidden="true"
                          class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          :class="{
                            'translate-x-5': pushEF,
                            'translate-x-0': !pushEF
                          }"
                        ></span>
                      </button>
                    </td> -->
                      </tr>

                      <tr>
                        <td
                          class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                        >
                          Execution Failed
                        </td>

                        <td class="whitespace-nowrap px-3 py-4">
                          <button
                            type="button"
                            class="elative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
                            x-data="{ on: true }"
                            role="switch"
                            aria-checked="false"
                            :aria-checked="slackEF.toString()"
                            name="slack_executionFailed"
                            @click="
                              ;(slackEF = !slackEF), change($event, 'slackEF')
                            "
                            :class="[
                              { 'pointer-events-none': userRole === 'viewer' },
                              {
                                '!bg-blue-600 dark:bg-blue-500': slackEF,
                                'bg-gray-400 dark:bg-gray-500': !slackEF
                              }
                            ]"
                          >
                            <span
                              aria-hidden="true"
                              class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              :class="{
                                'translate-x-5': slackEF,
                                'translate-x-0': !slackEF
                              }"
                            ></span>
                          </button>
                        </td>

                        <!-- <td class="whitespace-nowrap px-3 py-4">
                      <button
                        type="button"
                        class="elative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
                        role="switch"
                        aria-checked="false"
                        :aria-checked="msTeamsEF.toString()"
                        @click="msTeamsEF = !msTeamsEF"
                        :class="{
                          '!bg-blue-600 dark:bg-blue-500': msTeamsEF,
                          'bg-gray-400 dark:bg-gray-500': !msTeamsEF
                        }"
                      >
                        <span
                          aria-hidden="true"
                          class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          :class="{
                            'translate-x-5': msTeamsEF,
                            'translate-x-0': !msTeamsEF
                          }"
                        ></span>
                      </button>
                    </td> -->

                        <td class="whitespace-nowrap px-3 py-4">
                          <button
                            type="button"
                            class="elative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
                            role="switch"
                            aria-checked="false"
                            :aria-checked="emailEF.toString()"
                            name="email_executionFailed"
                            @click="
                              ;(emailEF = !emailEF), change($event, 'emailEF')
                            "
                            :class="[
                              { 'pointer-events-none': userRole === 'viewer' },
                              {
                                '!bg-blue-600 dark:bg-blue-500': emailEF,
                                'bg-gray-400 dark:bg-gray-500': !emailEF
                              }
                            ]"
                          >
                            <span
                              aria-hidden="true"
                              class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              :class="{
                                'translate-x-5': emailEF,
                                'translate-x-0': !emailEF
                              }"
                            ></span>
                          </button>
                        </td>

                        <!-- <td class="whitespace-nowrap px-3 py-4">
                      <button
                        type="button"
                        class="elative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
                        x-data="{ on: true }"
                        role="switch"
                        aria-checked="false"
                        :aria-checked="pushES.toString()"
                        @click="pushES = !pushES"
                        :class="{
                          '!bg-blue-600 dark:bg-blue-500': pushES,
                          'bg-gray-400 dark:bg-gray-500': !pushES
                        }"
                      >
                        <span
                          aria-hidden="true"
                          class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          :class="{
                            'translate-x-5': pushES,
                            'translate-x-0': !pushES
                          }"
                        ></span>
                      </button>
                    </td> -->
                      </tr>

                      <tr>
                        <td
                          class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                        >
                          Cookie Expired
                        </td>

                        <td class="whitespace-nowrap px-3 py-4">
                          <button
                            type="button"
                            class="elative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
                            role="switch"
                            aria-checked="false"
                            :aria-checked="slackCE.toString()"
                            name="slack_cookieExpired"
                            @click="
                              ;(slackCE = !slackCE), change($event, 'slackCE')
                            "
                            :class="[
                              { 'pointer-events-none': userRole === 'viewer' },
                              {
                                '!bg-blue-600 dark:bg-blue-500': slackCE,
                                'bg-gray-400 dark:bg-gray-500': !slackCE
                              }
                            ]"
                          >
                            <span
                              aria-hidden="true"
                              class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              :class="{
                                'translate-x-5': slackCE,
                                'translate-x-0': !slackCE
                              }"
                            ></span>
                          </button>
                        </td>

                        <!-- <td class="whitespace-nowrap px-3 py-4">
                      <button
                        type="button"
                        class="elative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
                        x-data="{ on: true }"
                        role="switch"
                        aria-checked="false"
                        :aria-checked="msTeamsCE.toString()"
                        @click="msTeamsCE = !msTeamsCE"
                        :class="{
                          '!bg-blue-600 dark:bg-blue-500': msTeamsCE,
                          'bg-gray-400 dark:bg-gray-500': !msTeamsCE
                        }"
                      >
                        <span
                          aria-hidden="true"
                          class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          :class="{
                            'translate-x-5': msTeamsCE,
                            'translate-x-0': !msTeamsCE
                          }"
                        ></span>
                      </button>
                    </td> -->

                        <td class="whitespace-nowrap px-3 py-4">
                          <button
                            type="button"
                            class="elative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
                            x-data="{ on: true }"
                            role="switch"
                            aria-checked="false"
                            :aria-checked="emailCE.toString()"
                            name="email_cookieExpired"
                            @click="
                              ;(emailCE = !emailCE), change($event, 'emailCE')
                            "
                            :class="[
                              { 'pointer-events-none': userRole === 'viewer' },
                              {
                                '!bg-blue-600 dark:bg-blue-500': emailCE,
                                'bg-gray-400 dark:bg-gray-500': !emailCE
                              }
                            ]"
                          >
                            <span
                              aria-hidden="true"
                              class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              :class="{
                                'translate-x-5': emailCE,
                                'translate-x-0': !emailCE
                              }"
                            ></span>
                          </button>
                        </td>

                        <!-- <td class="whitespace-nowrap px-3 py-4">
                      <button
                        type="button"
                        class="elative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
                        role="switch"
                        aria-checked="false"
                        :aria-checked="pushCE.toString()"
                        @click="pushCE = !pushCE"
                        :class="{
                          '!bg-blue-600 dark:bg-blue-500': pushCE,
                          'bg-gray-400 dark:bg-gray-500': !pushCE
                        }"
                      >
                        <span
                          aria-hidden="true"
                          class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          :class="{
                            'translate-x-5': pushCE,
                            'translate-x-0': !pushCE
                          }"
                        ></span>
                      </button>
                    </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getNotificationPreferences,
  updateNotificationPreferences
} from '@/apis/settings/settings-integration'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '../Button.vue'
import { mapState } from 'vuex'

export default {
  name: 'notificationSection',
  components: { SvgIcon, Button },
  data() {
    return {
      on: false,
      slackES: false,
      slackEF: false,
      slackCE: false,
      msTeamsES: false,
      msTeamsEF: false,
      msTeamsCE: false,
      emailES: false,
      emailEF: false,
      emailCE: false,
      pushEF: false,
      pushES: false,
      pushCE: false,
      notiData: null
    }
  },
  computed: {
    ...mapState('settings', ['userRole'])
  },
  created() {
    this.getAllNotifications()
  },
  methods: {
    async getAllNotifications() {
      try {
        const workspaceId = window.$cookies.get('workspaceId')
        const response = await getNotificationPreferences(workspaceId)
        // this.notificationData = response.data
        if (response['success']) {
          this.notiData = response.data
          for (let data in this.notiData) {
            if (!!this.notiData[data].events) {
              for (let event in this.notiData[data].events) {
                if (this.notiData[data].events.cookieExpired) {
                  this[`${data}CE`] = true
                }
                if (this.notiData[data].events.executionFailed) {
                  this[`${data}EF`] = true
                }
                if (this.notiData[data].events.executionSuccess) {
                  this[`${data}ES`] = true
                }
              }
            }
          }
        } else {
          this.$emit('notificationEmit', {
            type: 'fail',
            msg: `Please configure your account from integrations to use notifications`
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async change(e, value) {
      try {
        const workspaceId = window.$cookies.get('workspaceId')
        let name = e.srcElement.name.split('_')[0]
        if (this.notiData[name].isConfigured || (name = 'email')) {
          let data = e.srcElement.name.split('_')[1]
          let apiData = {
            [name]: {
              events: {
                [data]: this[`${value}`]
              }
            }
          }

          const response = await updateNotificationPreferences(
            workspaceId,
            apiData
          )
          this.$emit('notificationEmit', {
            type: 'success',
            msg: `Changes Applied Successfully`
          })
        } else {
          this[`${value}`] = !this[`${value}`]
          this.$emit('notificationEmit', {
            type: 'fail',
            msg: `Please configure your ${name} account from integrations and then try again`
          })
        }
      } catch (error) {
        this.$emit('notificationEmit', {
          type: 'fail',
          msg: `Please configure your Slack account and then try again`
        })

        console.log(error)
      }
    }
  }
}
</script>
