<template>
  <!-- START WRAPPER -->
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Add New Prompt
          </p>

          <div>
            <Button
              @click="close"
              icon="close-1"
              class="!p-1.5"
              size="small"
              color="tertiary"
            />
          </div>
        </div>
      </div>

      <vee-form
        @submit="submitCreate()"
        :validation-schema="schema"
        id="inputForm"
      >
        <div class="px-4 sm:px-6">
          <div class="grid grid-cols-2 gap-x-6 gap-y-5">
            <div class="col-span-2">
              <label
                for=""
                class="block text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                Prompt Name
              </label>
              <div class="mt-1.5">
                <vee-field
                  class="block w-full rounded-lg border border-gray-300 px-3 py-2.5 placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                  name="Prompt Name"
                  type="text"
                  v-model="promptFormData.title"
                />
              </div>
              <p class="mt-2 text-sm text-red-600">
                <ErrorMessage name="Prompt Name" />
              </p>
            </div>

            <div class="col-span-2">
              <label
                for=""
                class="block text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                Prompt Description
              </label>
              <div class="mt-1.5">
                <Field
                  v-slot="{ field }"
                  v-model="promptFormData.prompt"
                  name="Prompt Description"
                >
                  <textarea
                    ref="textarea"
                    @input="resize()"
                    class="h-[40px] max-h-[233px] w-full resize-none rounded-lg border-gray-300"
                    v-bind="field"
                    name="Prompt Description"
                  />
                </Field>
              </div>
              <p class="mt-2 text-sm text-red-600">
                <ErrorMessage name="Prompt Description" />
              </p>
            </div>
          </div>
        </div>
      </vee-form>

      <div class="px-4 py-3 sm:px-6">
        <div class="flex items-center justify-between">
          <Button
            :showLoader="loader"
            class="dark:!hover:bg-blue-400 !border-blue-600 !text-blue-600 dark:!text-blue-400"
            color="tertiary"
            :text="isEditing ? 'Update Prompt' : 'Save Prompt'"
            type="submit"
            form="inputForm"
          />
          <div v-if="isEditing">
            <Button
              @click="promptDelete()"
              v-if="reallyDelete"
              :showLoader="deleteLoader"
              color="danger"
              text="Really Delete"
              :leftIcon="deleteLoader ? '' : 'delete'"
            />
            <Button
              v-else
              color="danger"
              icon="delete"
              @click="reallyDelete = true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Button from '@/components/Button.vue'
import Select from '@/components/Select.vue'
import Input from '@/components/Input.vue'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { Field } from 'vee-validate'
import { savePrompt, updatePrompt, deletePrompt } from '@/apis/settings/ai'

export default {
  name: 'SavePromptModal',

  components: {
    Button,
    Select,
    Input,
    Spinner,
    SvgIcon,
    Field
  },

  props: {
    isEditing: { type: Boolean, required: true },
    editingData: { type: Object, required: true }
  },
  mounted() {
    if (this.isEditing) {
      this.promptFormData = this.editingData
    }
  },
  data() {
    return {
      tested: false,
      isTesting: false,
      locationForShow: {},
      isError: false,
      dataForSave: {},
      isSaving: false,
      schema: {
        'Prompt Name': 'required',
        'Prompt Description': 'required'
      },
      promptFormData: {
        title: '',
        prompt: ''
      },
      loader: false,
      deleteLoader: false,
      reallyDelete: false
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    resize() {
      let element = this.$refs['textarea']

      element.style.height = '0px'

      element.style.height = element.scrollHeight + 'px'
    },
    async submitCreate() {
      try {
        this.loader = true
        let response
        if (this.isEditing) {
          response = await updatePrompt(this.promptFormData._id, {
            title: this.promptFormData.title,
            prompt: this.promptFormData.prompt
          })
        } else {
          response = await savePrompt(this.promptFormData)
        }

        if (response['success']) {
          this.$emit('success', 'Prompt Saved Successfully')
          this.$emit('close')
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.loader = false
      }
    },
    async promptDelete() {
      try {
        this.deleteLoader = true
        const response = await deletePrompt(this.promptFormData._id)
        if (response['success']) {
          this.$emit('success', 'Prompt Deleted Successfully')
          this.$emit('close')
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.deleteLoader = false
      }
    }
  }
}
</script>

<style></style>
