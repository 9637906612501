<template>
  <div class="flex flex-col mt-8">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div class="overflow-hidden">
          <table class="min-w-full">
            <thead class="bg-gray-50 dark:bg-gray-700">
            <tr>
              <th scope="col"
                  class="heading-primary min-w-[12rem] rounded-l-lg">
                Name
              </th>

              <th scope="col"
                  class="heading-primary">
                Platform
              </th>

              <th scope="col"
                  class="heading-primary">
                Invites Sent
              </th>


              <th scope="col"
                  class="heading-primary">
                New Connections
              </th>

              <th scope="col"
                  class="heading-primary">
                Message Sent
              </th>

              <th scope="col"
                  class="heading-primary">
                Replies
              </th>

              <th scope="col"
                  class="heading-primary">
                Growth Rate
              </th>

              <th scope="col"
                  class="heading-primary">
                Reply Rate
              </th>
            </tr>
            </thead>

            <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700">
            <tr v-for="(item, index) in reportTableData" :key="index">
              <td class="px-3 py-4 whitespace-nowrap">
                <div class="flex items-center">
                                  <span
                                      class="inline-flex items-center justify-center w-10 h-10 bg-blue-100 border border-blue-300 rounded-full shrink-0">
                                    <span class="text-sm font-medium leading-none text-blue-600 uppercase">
                                      {{ item.initials }}
                                    </span>
                                  </span>
                  <div class="ml-3">
                    <p class="text-sm font-semibold text-gray-900 dark:text-gray-50">
                      {{ item.name }}
                    </p>
                    <p class="mt-0.5 text-xs font-normal text-gray-600 dark:text-gray-400">
                      {{ item.title }}
                    </p>
                  </div>
                </div>
              </td>

              <td class="px-3 py-4 whitespace-nowrap">
                <img class="object-cover w-8 h-8 rounded-full shrink-0"
                     :src="item.platformUrl" alt="">
              </td>

              <td class="data-primary">
                {{ item.invitesSent }}
              </td>

              <td class="data-primary">
                {{ item.newConnections }}
              </td>

              <td class="data-primary">
                {{ item.messageSent }}
              </td>

              <td class="data-primary">
                {{ item.replies }}
              </td>

              <td class="data-primary">
                {{ item.growthRate }}
              </td>

              <td class="data-primary">
                {{ item.replyRate }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reportTable",
  props: {
    reportTableData: {type: Array, default: [
        {
          name: 'Arman Mazka',
          title: 'Head of Product, TexAu',
          initials: 'AM',
          platformUrl: '',
          invitesSent: '50',
          newConnections: '50',
          messageSent: '50',
          replies: '50',
          growthRate: '50%',
          replyRate: '50%'
        }
      ]}
  }
}
</script>

<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .heading-primary {
    @apply px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50;
  }
  .data-primary {
    @apply px-3 py-4 text-sm text-gray-900 dark:text-gray-50 whitespace-nowrap;
  }
}
</style>
