<template>
  <AutomationHeader title="Verify Account" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <SettingsMenu />
    <div
      class="relative flex-1 border-l border-gray-200 antialiased dark:border-gray-700"
    >
      <div class="px-4 py-5 sm:p-5">
        <!-- Tab navigation for selecting between email and phone verification -->
        <nav class="flex justify-center space-x-4" aria-label="Tabs">
          <a
            v-show="!userData.isEmailVerified"
            href="#"
            class="rounded-lg px-3 py-2 text-sm font-medium transition-all duration-200"
            :class="tabClass('email')"
            aria-current="page"
            @click.prevent="tab = 'email'"
          >
            Email Verification
          </a>
          <a
            v-show="!userData.isPhoneVerified"
            href="#"
            class="rounded-lg px-3 py-2 text-sm font-medium transition-all duration-200"
            :class="tabClass('phone')"
            aria-current="page"
            @click.prevent="tab = 'phone'"
          >
            Phone Verification
          </a>
        </nav>

        <!-- Content for email verification tab -->
        <div
          v-if="tab === 'email'"
          class="mx-auto mt-8 grid max-w-xl grid-cols-1 gap-y-5"
        >
          <VerificationSection
            title="Registered Email"
            inputType="email"
            :inputValue="emailValue"
            :buttonText="emailButtonText"
            :isDisabled="isDisabledEmail"
            :isLoading="isLoadingEmail"
            @getOtp="getOtpEmail"
            @submitCode="submitEmailCode"
            :codeValue="codeEmail"
            v-model="codeEmail"
            @openIntercom="openIntercom"
          />
        </div>

        <!-- Content for phone verification tab -->
        <div v-else class="mx-auto mt-8 grid max-w-xl grid-cols-1 gap-y-5">
          <VerificationSection
            title="Registered Phone Number"
            inputType="tel"
            :inputValue="phoneValue"
            :buttonText="phoneButtonText"
            :isDisabled="isDisabledPhone"
            :isLoading="isLoadingPhone"
            @getOtp="getOtpPhone"
            @submitCode="submitPhoneCode"
            :codeValue="codePhone"
            v-model="codePhone"
            @openIntercom="openIntercom"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import VerificationSection from '@/views/settings/settings-verify/VerificationSection.vue'
import {
  verifyEmail,
  verifyPhone,
  confirmEmail,
  confirmPhone
} from '@/apis/user'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'VerifyMain',
  components: {
    SvgIcon,
    Input,
    AutomationHeader,
    SettingsMenu,
    Button,
    VerificationSection
  },
  data() {
    return {
      emailValue: '',
      phoneValue: '',
      isLoadingEmail: false,
      isLoadingPhone: false,
      tab: 'email', // Default tab
      isDisabledEmail: false,
      isDisabledPhone: false,
      emailButtonText: 'Get OTP',
      phoneButtonText: 'Get OTP',
      codeEmail: null,
      codePhone: null
    }
  },
  created() {
    // Initialize email and phone values from local storage and user data
    this.emailValue = localStorage.getItem('email')
    this.phoneValue = this.userData.phone
    this.getVerificationStatus() // Set initial tab based on verification status
  },
  computed: {
    ...mapState('settings', ['userRole']),
    ...mapState('user', ['userData'])
  },
  methods: {
    ...mapActions('user', ['saveUserData']),

    /**
     * Returns the appropriate CSS class based on the active tab.
     *
     * @param {string} tabType - The type of tab to check.
     * @returns {Object} - An object containing CSS classes.
     */
    tabClass(tabType) {
      return {
        'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-50':
          this.tab === tabType,
        'text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
          this.tab !== tabType
      }
    },

    /**
     * Starts a countdown timer for the OTP button (email or phone).
     *
     * @param {string} type - The type of OTP ('email' or 'phone').
     */
    startCountdown(type) {
      const config = {
        email: {
          timerKey: 'secondsRemainingEmail',
          buttonKey: 'emailButtonText',
          disableKey: 'isDisabledEmail'
        },
        phone: {
          timerKey: 'secondsRemainingPhone',
          buttonKey: 'phoneButtonText',
          disableKey: 'isDisabledPhone'
        }
      }

      const { timerKey, buttonKey, disableKey } = config[type]

      this[timerKey] = 30
      this[disableKey] = true
      this[buttonKey] = `Wait... ${this[timerKey]}s`

      const countdownInterval = setInterval(() => {
        this[timerKey]--
        this[buttonKey] = `Wait... ${this[timerKey]}s`

        if (this[timerKey] <= 0) {
          clearInterval(countdownInterval)
          this[disableKey] = false
          this[buttonKey] = 'Get OTP'
        }
      }, 1000)
    },

    /**
     * Requests OTP for email verification.
     * Starts a countdown on success, or emits an error on failure.
     *
     * @async
     * @function getOtpEmail
     */
    async getOtpEmail() {
      try {
        const otpRes = await verifyEmail()
        if (otpRes.success) {
          this.startCountdown('email')
        } else {
          throw otpRes.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },

    /**
     * Requests OTP for phone verification.
     * Starts a countdown on success, or emits an error on failure.
     *
     * @async
     * @function getOtpPhone
     */
    async getOtpPhone() {
      try {
        const otpRes = await verifyPhone()
        if (otpRes.success) {
          this.startCountdown('phone')
        } else {
          throw otpRes.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },

    /**
     * Submits the email verification code for confirmation.
     *
     * @async
     * @param {string} code - The verification code to be submitted.
     */
    async submitEmailCode(code) {
      await this.submitCode('email', confirmEmail, code)
    },

    /**
     * Submits the phone verification code for confirmation.
     *
     * @async
     * @param {string} code - The verification code to be submitted.
     */
    async submitPhoneCode(code) {
      await this.submitCode('phone', confirmPhone, code)
    },

    /**
     * Submits the verification code for email or phone.
     *
     * @async
     * @param {string} type - The type of verification ('email' or 'phone').
     * @param {Function} confirmFn - The function to call for verification.
     * @param {string} code - The verification code to be submitted.
     */
    async submitCode(type, confirmFn, code) {
      const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1)
      const loadingKey = `isLoading${capitalizedType}`
      const verificationKey = `is${capitalizedType}Verified`

      this[loadingKey] = true

      try {
        const response = await confirmFn({ code })

        if (response.success) {
          this.updateUserData(verificationKey)
          this.emitSuccess(capitalizedType)
          this.getVerificationStatus()
        } else {
          throw new Error(response.message || 'Verification failed')
        }
      } catch (error) {
        this.$emit('error', error.message || error)
      } finally {
        this[loadingKey] = false
      }
    },

    updateUserData(verificationKey) {
      const updatedUserData = {
        ...this.userData,
        [verificationKey]: true
      }

      this.saveUserData({ payload: updatedUserData })
    },

    emitSuccess(type) {
      this.$emit('success', `${type} Verified Successfully`)
    },

    /**
     * Sets the initial tab based on the user's email and phone verification status.
     */
    getVerificationStatus() {
      const { isEmailVerified, isPhoneVerified } = this.userData

      if (isEmailVerified && isPhoneVerified) {
        const accountsUrl = new URL(
          '/accounts',
          window.location.origin
        ).toString()
        window.location.href = accountsUrl
      } else {
        this.tab = isEmailVerified ? 'phone' : 'email'
      }
    },

    /**
     * Opens the Intercom chat for support with a predefined message containing OTP issue details.
     *
     * @param {string} data - The type of OTP issue ('email' or 'phone').
     */
    openIntercom(data) {
      window.Intercom(
        'showNewMessage',
        `Hello team, I'm having an issue with ${data} OTP. Can you please help? Here is my email: ${this.emailValue} and phone: ${this.phoneValue}`
      )
    }
  }
}
</script>
