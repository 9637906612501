<template>
  <AutomationHeader
    :right-button-function="onAddTagClick"
    isRightButton
    right-button-class="inline-flex items-center justify-center px-4 py-2.5 sm:text-sm text-base font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-lg hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-offset-gray-900 dark:focus:ring-blue-500"
    right-button-text="Add Tag"
    title="Tags"
  />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <SettingsMenu active-option="tags" />

    <TagsSection @error="data => $emit('error', data)" />
  </main>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import TagsSection from '@/components/settings/tagsSection.vue'
import ToastContainer from '@/components/ToastContainer.vue'
import { mapActions } from 'vuex'

export default {
  name: 'tags',
  components: {
    AutomationHeader,
    SettingsMenu,
    TagsSection,
    ToastContainer
  },
  methods: {
    ...mapActions('settings', ['addNewTagClicked']),

    onAddTagClick() {
      this.addNewTagClicked({ payload: true })
    }
  }
}
</script>

<style scoped></style>
