<!-- Buttons Component -->
<!-- Pass text and color as props to use various classes -->
<!-- color : primary, secondary, tertiary, warning, danger, success, disabled, warningFilled, dangerFilled, successFilled -->
<!-- To add icons pass the position of icon v-bind:leftIcon OR rightIcon OR icon="ICON NAME" -->
<!-- To make the button icon only and to add external classes pass :color = iconOnly -->
<!-- Size can also be adjusted by passing the props size: large, small, block , normal(is default) -->

<template>
  <button
    :data-platform="dataAttribute"
    :name="name"
    :disabled="isdisabled"
    type="button"
    class="inline-flex items-center justify-center rounded-lg border font-semibold transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
    :class="[
      {
        'border-transparent bg-gray-900 text-white hover:bg-gray-800 focus:ring-gray-900':
          black,
        'border-transparent bg-gray-400 text-white hover:bg-gray-600 focus:ring-gray-900':
          gray,
        'cursor-not-allowed  disabled:cursor-not-allowed ': isdisabled,
        'border-transparent bg-blue-600  text-white  hover:bg-blue-800  focus:ring-blue-600 dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900':
          primary,
        'border-blue-600 bg-transparent text-blue-600 hover:bg-blue-100 focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:border-blue-400 dark:text-blue-400 dark:hover:bg-blue-400 dark:hover:text-blue-50 dark:focus:ring-blue-400 dark:focus:ring-offset-gray-900':
          secondary,
        'border-gray-300 bg-white px-4 text-gray-600 hover:bg-gray-50 hover:text-gray-900 focus:ring-gray-400  dark:border-gray-600  dark:bg-gray-900 dark:text-gray-400   dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900':
          tertiary,

        'border-yellow-500 bg-white px-4  text-yellow-600  hover:bg-yellow-50  focus:ring-yellow-500 dark:border-yellow-400 dark:text-yellow-400 dark:hover:bg-yellow-400 dark:hover:text-yellow-50 dark:focus:ring-yellow-400 dark:focus:ring-offset-gray-900':
          warning,
        'border-0': iconOnly,
        'border-red-600 bg-white px-4 text-red-600  hover:bg-red-50  focus:ring-red-600  dark:border-red-400 dark:bg-transparent dark:text-red-400 dark:hover:bg-red-400 dark:hover:text-red-50 dark:focus:ring-red-400 dark:focus:ring-offset-gray-900':
          danger,
        'border-green-500 bg-white px-4  text-green-500  hover:bg-green-50  focus:ring-green-500 dark:border-green-400 dark:text-green-400 dark:hover:bg-green-400 dark:hover:text-green-50 dark:focus:ring-green-400 dark:focus:ring-offset-gray-900':
          success,
        'border-transparent bg-green-500 px-4  text-white  hover:bg-green-600  focus:ring-green-500 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-500 dark:focus:ring-offset-gray-900':
          successFilled,
        'border-transparent bg-yellow-500 px-4  text-white  hover:bg-yellow-600  focus:ring-yellow-500 dark:bg-yellow-400 dark:hover:bg-yellow-500 dark:focus:ring-yellow-400 dark:focus:ring-offset-gray-900':
          warningFilled,
        'border-transparent bg-red-600 px-4  text-white  hover:bg-red-600  focus:ring-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-500 dark:focus:ring-offset-gray-900':
          dangerFilled,
        'px-4 py-2.5 text-base sm:text-sm': (normal || block) && !icon,
        'px-6 py-3 text-base ': large && !icon,
        'px-3 py-2 text-xs': small && !icon,
        'p-3': icon && large,
        'p-2.5': icon && (normal || block),
        'p-2': icon && small
      }
    ]"
  >
    <SvgIcon
      v-if="showLoader"
      name="spinnerIcon"
      :class="{
        'h-[14px] w-[14px]': small
      }"
    />
    <SvgIcon
      v-if="icon"
      :class="[
        {
          '-ml-1 mr-2 h-6 w-6': leftIcon,
          'h-5 w-5': icon,
          'ml-1': showLoader,
          'h-[14px] w-[14px]': small
        }
      ]"
      :name="icon"
    />
    <SvgIcon
      v-if="leftIcon"
      class="mr-2 h-5 w-5 text-xs"
      :class="{
        'h-[14px] w-[14px]': small
      }"
      :name="leftIcon"
    />
    <slot></slot>
    {{ text }}
    <SvgIcon
      v-if="rightIcon"
      class="-mr-1 ml-2 h-5 w-5"
      :class="{
        'h-[14px] w-[14px]': small
      }"
      :name="rightIcon"
    />
  </button>
</template>

<script>
import SvgIcon from './SvgIcon.vue'

export default {
  name: 'Button',
  components: { SvgIcon },
  props: {
    text: { type: String },
    color: { type: String, default: 'primary' },
    size: { type: String, default: 'normal' },
    leftIcon: { type: String, default: '' },
    rightIcon: { type: String, default: '' },
    icon: { type: String, default: '' },
    svgSrc: String,
    disabled: { type: Boolean, default: false },
    loader: { type: Boolean, default: false },
    name: { type: String, default: null },
    showLoader: { type: Boolean, default: false },
    dataAttributeName: { type: String },
    dataAttribute: { type: String, default: null }
  },
  computed: {
    // TODO: BETTER WAY TO HANDLE THIS.
    primary() {
      return this.color === 'primary'
    },
    secondary() {
      return this.color === 'secondary'
    },
    tertiary() {
      return this.color === 'tertiary'
    },
    iconOnly() {
      return this.color === 'iconOnly'
    },
    warning() {
      return this.color === 'warning'
    },
    danger() {
      return this.color === 'danger'
    },
    success() {
      return this.color === 'success'
    },
    warningFilled() {
      return this.color === 'warningFilled'
    },
    dangerFilled() {
      return this.color === 'dangerFilled'
    },
    successFilled() {
      return this.color === 'successFilled'
    },
    isdisabled() {
      return this.disabled === true || this.showLoader
    },
    black() {
      return this.color === 'black'
    },
    gray() {
      return this.color === 'gray'
    },
    normal() {
      return this.size === 'normal'
    },
    large() {
      return this.size === 'large'
    },
    small() {
      return this.size === 'small'
    },
    block() {
      return this.size === 'block'
    }
  }
}
</script>

<style></style>
