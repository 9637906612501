import axios from '@/common/axios'

const runIntegration = async (inputs, platformId, operationId, signal) => {
  try {
    const response = await axios.post(
      `/platforms/${platformId}/operations/${operationId}`,
      {
        inputs
      },
      { signal }
    )
    return response.data
  } catch (error) {
    return error?.response?.data
  }
}

export { runIntegration }
