<template>
  <div class="h-full border-t">
    <div v-if="loader" class="flex h-full items-center justify-center">
      <Spinner size="large" />
    </div>
    <template v-else>
      <div v-if="linkExpired" class="flex mt-4 justify-center">
        <div class="overflow-hidden rounded-lg bg-red-100 dark:bg-red-900/50">
          <div class="px-4 py-3">
            <div class="flex items-center">
              <SvgIcon class="h-5 w-5 text-red-700" name="cancel" />
              <span
                class="ml-3 text-sm font-medium text-red-700 dark:text-red-300"
              >
                This Link is Expired Please Try Again With Another Link
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        :class="[
          { 'mt-20': Object.keys(userData).length > 0 },
          {
            'flex h-full items-center justify-center':
              Object.keys(userData).length === 0
          }
        ]"
      >
        <div
          class="relative mx-auto w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
        >
          <p
            class="text-center text-xl font-bold text-gray-900 dark:text-gray-50"
          >
            Connect New Account
          </p>
          <p class="text-md mt-4 text-center text-gray-700 dark:text-gray-50">
            {{ pageData[0].name }} would like to connect your account
          </p>

          <div class="mt-8 px-4 pb-5 sm:px-6 sm:pb-6">
            <div class="mx-auto flex max-w-sm items-center justify-center">
              <img
                class="mx-auto h-16 w-16 shrink-0 rounded-full object-cover ring-1 ring-blue-600 ring-offset-2 dark:ring-blue-500 dark:ring-offset-gray-900"
                src="@/assets/images/symbols/texau.svg"
                alt=""
              />

              <div class="flex items-center justify-center">
                <SvgIcon
                  name="rightArrowFilled"
                  class="h-5 w-5 text-blue-600 opacity-20 dark:text-blue-500"
                />
                <SvgIcon
                  name="rightArrowFilled"
                  class="h-5 w-5 text-blue-600 opacity-40 dark:text-blue-500"
                />
                <SvgIcon
                  name="rightArrowFilled"
                  class="h-5 w-5 text-blue-600 opacity-60 dark:text-blue-500"
                />
                <SvgIcon
                  name="rightArrowFilled"
                  class="h-5 w-5 text-blue-600 opacity-80 dark:text-blue-500"
                />
                <SvgIcon
                  name="rightArrowFilled"
                  class="h-5 w-5 text-blue-600 dark:text-blue-500"
                />
              </div>

              <div class="flex -space-x-2 overflow-hidden mx-auto">
                <img
                  v-for="img in pageData"
                  class="h-16 w-16 shrink-0 ring-2 ring-white rounded-full object-cover"
                  :src="img.platformIconUrl"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center justify-center">
            <button
              @click="onImportClick($event)"
              id="texau-extension-v2-import"
              :data-linkid="getDataAttribute()"
              class="inline-flex items-center justify-center rounded-lg border border-transparent bg-blue-600 px-4 py-2.5 text-base font-semibold text-white transition-all duration-200 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900 sm:text-sm"
            >
              <SvgIcon
                id="texau-v2-extension-import-loader"
                class="hidden"
                name="spinnerIcon"
              />
              Install Extension
            </button>
          </div>
          <div
            id="texau-v2-extension-import-success"
            class="mt-4 hidden overflow-hidden rounded-lg bg-green-100 dark:bg-green-900/50"
          >
            <div class="px-4 py-3">
              <div class="flex items-center">
                <SvgIcon name="check" />
                <span
                  class="ml-3 text-sm font-medium text-green-700 dark:text-green-300"
                >
                  Account connected successfully!
                </span>
              </div>
            </div>
          </div>
          <div
            id="texau-v2-extension-import-failed"
            class="mt-4 hidden overflow-hidden rounded-lg bg-red-100 dark:bg-red-900/50"
          >
            <div class="px-4 py-3">
              <div class="flex items-center">
                <SvgIcon class="h-5 w-5 text-red-700" name="cancel" />
                <span
                  class="ml-3 text-sm font-medium text-red-700 dark:text-red-300"
                >
                  Error occurred while connecting account, Please try again.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getLinkData } from '@/apis/accountImport'
import Spinner from '@/components/Spinner.vue'
import { mapState } from 'vuex'
export default {
  name: 'magicLinkIntegration',
  components: {
    Spinner
  },
  data() {
    return {
      loader: true,
      pageData: null,
      linkExpired: false
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    ...mapState('user', ['userData'])
  },
  methods: {
    async onImportClick(e) {
      if (e.currentTarget.innerText === 'Install Extension') {
        //TODO: open chrome extension store link in new tab
        var newTab = window.open(
          'https://chromewebstore.google.com/detail/texau-v2/hbhdepogdnphlloancjiifodpchlkeam',
          '_blank'
        )
        newTab.focus()
      } else {
        return
      }
    },
    async getData() {
      try {
        let links = this.$route.query.linkIds.split(',')
        if (links.length === 1) {
          const response = await getLinkData(links[0])
          if (!response['success']) {
            this.linkExpired = true
            throw 'link expired'
          } else {
            this.pageData = [response.data]
          }
        } else {
          const [res1, res2, res3] = await Promise.all([
            getLinkData(links[0]),
            getLinkData(links[1]),
            getLinkData(links[2])
          ])
          if (!res1['success'] || !res2['success'] || !res3['success']) {
            this.linkExpired = true
            throw 'link expired'
          } else {
            this.pageData = [res1.data, res2.data, res3.data]
          }
        }

        this.loader = false
      } catch (e) {
        this.$emit('error', e)
        this.loader = false
      }
    },
    getDataAttribute() {
      let arr = this.$route.query.linkIds.split(',')
      if (arr.length > 1) {
        return encodeURIComponent(
          JSON.stringify({
            li: arr[0],
            sn: arr[1],
            rl: arr[2]
          })
        )
      } else {
        return encodeURIComponent(
          JSON.stringify({
            platform: arr[0]
          })
        )
      }
    }
  }
}
</script>
