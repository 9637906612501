<template>
  <div class="flex h-[calc(100%-4rem)] flex-col justify-between">
    <div class="">
      <div class="flex mt-8 flex-col gap-y-4">
        <div
          v-if="proxyAccess"
          class="rounded-lg bg-green-50 dark:bg-green-900/50"
        >
          <div class="p-4 flex justify-between">
            <div class="flex items-center">
              <SvgIcon
                class="h-5 w-5 shrink-0 text-green-500 dark:text-green-400"
                name="important"
              />
              <p
                class="ml-3 flex-1 text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                You have access to proxies provided by
                <span class="font-bold">TexAu</span>. Proxy will be shown after
                you have added an account.
              </p>
            </div>
            <!-- <div>
              <Button
                size="small"
                :show-loader="cancelProxyLoading"
                @click="onCancelProxyClick"
                color="dangerFilled"
                text="Cancel Access"
              />
            </div> -->
          </div>
        </div>
        <!-- <div
          v-if="proxyAccess === false || userData.isAdmin === true"
          class="rounded-lg bg-blue-50 dark:bg-blue-900/50"
        > -->
        <div
          v-if="proxyAccess === false"
          class="rounded-lg bg-blue-50 dark:bg-blue-900/50"
        >
          <div class="p-4">
            <div class="flex items-start">
              <SvgIcon
                class="h-5 w-5 shrink-0 text-blue-500 dark:text-blue-400"
                name="important"
              />
              <p
                class="ml-3 flex-1 text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                We strongly recommend you to use
                <span class="font-bold">static residential proxies</span>.
                Proxies should be <span class="font-bold">HTTP</span> type not
                HTTPS or SOCKS.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 flex flex-col">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div class="overflow-hidden">
              <div
                v-if="initialLoading"
                class="mt-12 flex w-full flex-col items-center justify-center space-y-4"
              >
                <Spinner size="large" />
              </div>
              <table v-else class="min-w-full">
                <tbody
                  class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
                  :key="componentKey"
                >
                  <tr v-for="(item, index) in proxies" :key="index" ref="proxy">
                    <td class="whitespace-nowrap px-3 py-4">
                      <div>
                        <label for="" class="sr-only"> Proxy Name </label>
                        <div class="mt-1.5">
                          <Input
                            :model-value="item.name"
                            :readonly="!checkEditing(index)"
                            text="Proxy Name"
                            @change="onInputChange($event, 'name', null, index)"
                            @keyup="onInputChange($event, 'name', null, index)"
                          />
                        </div>
                      </div>
                    </td>

                    <td class="whitespace-nowrap px-3 py-4">
                      <div>
                        <label for="" class="sr-only"> IP </label>
                        <div class="mt-1.5">
                          <Input
                            :model-value="item.credentials.ip"
                            :readonly="!checkEditing(index)"
                            text="IP"
                            @change="
                              onInputChange($event, 'credentials', 'ip', index)
                            "
                            @keyup="
                              onInputChange($event, 'credentials', 'ip', index)
                            "
                          />
                        </div>
                      </div>
                    </td>

                    <td class="whitespace-nowrap px-3 py-4">
                      <div>
                        <label for="" class="sr-only"> Port </label>
                        <div class="mt-1.5">
                          <Input
                            :model-value="item.credentials.port"
                            :readonly="!checkEditing(index)"
                            text="Port"
                            @change="
                              onInputChange(
                                $event,
                                'credentials',
                                'port',
                                index
                              )
                            "
                            @keyup="
                              onInputChange(
                                $event,
                                'credentials',
                                'port',
                                index
                              )
                            "
                          />
                        </div>
                      </div>
                    </td>

                    <td class="whitespace-nowrap px-3 py-4">
                      <div>
                        <label for="" class="sr-only"> Login </label>
                        <div class="mt-1.5">
                          <Input
                            :model-value="item.credentials.username"
                            :readonly="!checkEditing(index)"
                            text="Login"
                            @change="
                              onInputChange(
                                $event,
                                'credentials',
                                'username',
                                index
                              )
                            "
                            @keyup="
                              onInputChange(
                                $event,
                                'credentials',
                                'username',
                                index
                              )
                            "
                          />
                        </div>
                      </div>
                    </td>

                    <td class="whitespace-nowrap px-3 py-4">
                      <div>
                        <label for="" class="sr-only"> Password </label>
                        <div class="mt-1.5">
                          <Input
                            :type="isPasswordShow[index] ? 'text' : 'password'"
                            :model-value="item.credentials.password"
                            @onRightIconClick="
                              isPasswordShow[index]
                                ? hidePassword(index)
                                : showPassword(index)
                            "
                            :right-icon="
                              isPasswordShow[index] ? 'eye-off' : 'eye'
                            "
                            :readonly="!checkEditing(index)"
                            text="Password"
                            right-icon-mouse="cursor-pointer"
                            @change="
                              onInputChange(
                                $event,
                                'credentials',
                                'password',
                                index
                              )
                            "
                            @keyup="
                              onInputChange(
                                $event,
                                'credentials',
                                'password',
                                index
                              )
                            "
                          />
                        </div>
                      </div>
                    </td>

                    <td class="whitespace-nowrap px-3 py-4">
                      <img
                        class="h-8 w-8 shrink-0 rounded-full object-cover"
                        :src="item.location"
                        alt=""
                      />
                    </td>

                    <td class="whitespace-nowrap px-3 py-4">
                      <div class="flex items-center space-x-3">
                        <SvgIcon
                          v-if="item.isUsable"
                          class="h-4 w-4 text-green-600 dark:text-green-400"
                          name="indicator"
                        />

                        <SvgIcon
                          v-if="!item.isUsable"
                          class="h-4 w-4 text-red-600 dark:text-red-400"
                          name="indicator"
                        />

                        <Button
                          v-if="!checkEditing(index) && !checkTested(index)"
                          icon="edit"
                          @click="onEditClick(index)"
                          color="iconOnly"
                          class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
                        />

                        <Button
                          v-if="checkEditing(index) && !checkTested(index)"
                          :show-loader="checkTesting(index)"
                          text="Test"
                          @click="onTestClick(index)"
                        />

                        <Button
                          v-if="checkTested(index)"
                          :show-loader="checkSaving(index)"
                          text="Save"
                          @click="onSaveClick(index)"
                        />

                        <Button
                          icon="delete"
                          @click="onDeleteProxyClick(item.proxyId)"
                          color="iconOnly"
                          class="-m-2 rounded-lg p-2 text-red-600 transition-all duration-200 hover:bg-red-50 focus:outline-none dark:text-red-400 dark:hover:bg-red-500 dark:hover:text-red-50"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="">
      <Button
        v-if="proxyAccess === false"
        :show-loader="buyProxyLoading"
        @click="onBuyProxyClick"
        text="Buy Proxy"
      />
    </div> -->
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'
import {
  deleteProxyById,
  getAllProxy,
  getProxyById,
  updateProxy,
  cancelProxy,
  buyProxy
} from '@/apis/proxy'
import Spinner from '@/components/Spinner.vue'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { mapActions, mapGetters, mapState } from 'vuex'
import { testProxyProtocol } from '@/common/functions/proxy'

export default {
  name: 'SocialProxyMain',
  components: { SvgIcon, Input, Button, Spinner },
  data() {
    return {
      initialLoading: false,
      cancelProxyLoading: false,
      buyProxyLoading: false,
      proxyApiResponse: [],
      proxies: [],
      isDeleting: [],
      componentKey: 1,
      isPasswordShow: [],
      isEditing: [],
      isTesting: [],
      originalData: [],
      isTested: [],
      dataForUpdate: [],
      isSaving: [],
      showEditButton: []
    }
  },
  async beforeMount() {
    await this.getAllStaticProxies()
  },
  computed: {
    ...mapGetters('settings', ['getNewProxyAddStatus']),
    ...mapState('user', ['proxyAccess']),
    ...mapState('user', ['userData'])
  },

  watch: {
    getNewProxyAddStatus: {
      async handler(newValue, oldValue) {
        if (newValue) {
          await this.getAllStaticProxies()
          this.componentKey += 1
          this.updateNewProxyAddStatus({ payload: false })
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    ...mapActions('settings', ['updateNewProxyAddStatus']),
    ...mapActions('user', ['saveProxyAccess']),

    checkSaving(i) {
      return !!this.isSaving[i]
    },

    async onSaveClick(i) {
      this.isSaving[i] = true
      const proxyId = this.proxyApiResponse[i].proxyId
      const data = this.dataForUpdate[i]
      const response = await updateProxy(proxyId, data)
      this.isSaving[i] = false
      if (response['success']) {
        this.isTested[i] = false
        this.$emit('success', 'Proxy update successful.')
        await this.updateProxyList(i, proxyId)
      } else {
        this.$emit('error', 'Proxy update failed')
      }
    },

    async updateProxyList(i, proxyId) {
      const response = await getProxyById(proxyId)
      if (response['success']) {
        const data = [response.data].filter(a => {
          if (a.type === 'static') {
            return a
          }
        })
        this.proxyApiResponse[i] = data[0]
        const res = data.map(item => ({
          proxyId: item.proxyId,
          name: item.name,
          location: item.location.countryCode
            ? findFlagUrlByIso2Code(item.location.countryCode)
            : '',
          type: item.type,
          isUsable: item.isUsable,
          credentials: {
            ip: item.credentials.ip.split('/')[2],
            port: item.credentials.port,
            username: item.credentials.username,
            password: item.credentials.password
          }
        }))
        this.proxies[i] = res[0]
      } else {
        this.proxies[i] = this.originalData[i]
      }
    },

    async onCancelProxyClick() {
      try {
        this.cancelProxyLoading = true
        const response = await cancelProxy()
        if (response['success']) {
          this.saveProxyAccess({ payload: response.data.proxyAccess })
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.cancelProxyLoading = false
      }
    },

    async onBuyProxyClick() {
      try {
        this.buyProxyLoading = true
        const response = await buyProxy()
        if (response['success']) {
          this.saveProxyAccess({ payload: response.data.proxyAccess })
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.buyProxyLoading = false
      }
    },

    checkTested(i) {
      return !!this.isTested[i]
    },

    checkTesting(i) {
      return !!this.isTesting[i]
    },

    onInputChange(e, key, childKey, i) {
      // this.isEditing[i] = true;
      this.isTested[i] = false
      childKey
        ? (this.proxyApiResponse[i][key][childKey] = e.target.value)
        : (this.proxyApiResponse[i][key] = e.target.value)
    },

    async onTestClick(i) {
      try {
        this.isTesting[i] = true
        const data = {
          ip: this.proxyApiResponse[i].credentials.ip.split('/')[2]
            ? this.proxyApiResponse[i].credentials.ip.split('/')[2]
            : this.proxyApiResponse[i].credentials.ip,
          port: this.proxyApiResponse[i].credentials.port,
          username: this.proxyApiResponse[i].credentials.username,
          password: this.proxyApiResponse[i].credentials.password,
          name: this.proxyApiResponse[i].name
        }
        const check = await testProxyProtocol(data)
        this.isTesting[i] = false
        if (check.check) {
          this.proxies[i].isUsable = true
          this.isEditing[i] = false
          this.isTested[i] = true
          this.proxies[i].location = findFlagUrlByIso2Code(
            check.response.countryCode
          )
          // update data for save
          const credential = {
            ip: check.data.ip,
            port: check.data.port,
            username: check.data.username,
            password: check.data.password
          }
          const location = {
            city: check.response.city,
            region: check.response.regionName,
            country: check.response.country,
            countryCode: check.response.countryCode
          }
          this.dataForUpdate[i] = {
            name: check.data.name,
            location: location,
            credentials: credential,
            type: 'static'
          }
          this.$emit('success', 'Proxy test successful.')
        } else {
          this.proxies[i].isUsable = false
          throw check.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },

    checkEditing(i) {
      return !!this.isEditing[i]
    },

    onEditClick(i) {
      this.isEditing[i] = true
      this.originalData[i] = this.proxyApiResponse[i]
    },

    showPassword(i) {
      this.isPasswordShow[i] = true
    },

    hidePassword(i) {
      this.isPasswordShow[i] = false
    },
    async getAllStaticProxies() {
      this.initialLoading = true
      this.proxies.length = 0
      this.proxyApiResponse.length = 0
      const response = await getAllProxy(0, 50)
      if (response['success']) {
        const data = response.data.filter(a => {
          if (a.type === 'static' && a.isProvidedByTexAu !== true) {
            return a
          }
        })
        if (data.length === 0 && response.data.length >= 1) {
          this.saveProxyAccess({ payload: true })
        }
        this.proxyApiResponse.push(...data)
        const res = data.map(item => ({
          proxyId: item.proxyId,
          name: item.name,
          location: item.location.countryCode
            ? findFlagUrlByIso2Code(item.location.countryCode)
            : '',
          type: item.type,
          isUsable: item.isUsable,
          credentials: {
            ip: item.credentials.ip.split('/')[2],
            port: item.credentials.port,
            username: item.credentials.username,
            password: item.credentials.password
          }
        }))
        this.proxies.push(...res)
        this.initialLoading = false
      } else {
        this.$emit('error', 'Error in fetching social proxy.')
      }
    },
    onDeleteProxyClick(id) {
      this.$emit('showForceDeleteModal', id)
    }
  }
}
</script>

<style></style>
