<template>
  <!-- START WRAPPER -->
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto max-h-full w-full max-w-xl overflow-x-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            {{ this.forceDeleteId.isSelfWorkspace ? 'Delete' : 'Leave' }}
            Workspace
            <span class="text-blue-600">{{ workspaceDataToDelete?.name }}</span>
            ?
          </p>

          <div class="">
            <Button
              @click="emitClose"
              icon="close-1"
              class="!p-1.5"
              size="large"
              color="tertiary"
            />
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-center px-4 pb-5 sm:px-6 sm:pb-6"
        v-if="loader"
      >
        <Spinner />
      </div>

      <div v-else class="px-4 pb-5 sm:px-6 sm:pb-6 flex flex-col gap-y-4">
        <div>
          <p class="text-center">
            {{
              this.forceDeleteId.isSelfWorkspace
                ? 'This will delete all your workflows.'
                : 'This will remove you from this workspace'
            }}
          </p>
        </div>
        <div class="bg-red-100 text-red-600 rounded-lg p-2">
          <div class="flex space-x-2 items-center justify-center">
            <SvgIcon class="h-5 w-5" name="warning" />
            <p class="font-bold">Warning</p>
          </div>
          <div>
            <p class="text-sm font-[500] text-center">
              {{
                currentWorkspace
                  ? `This is your current workspace and ${
                      this.forceDeleteId.isSelfWorkspace
                        ? 'deleting'
                        : 'leaving'
                    } it is an irreversible action.`
                  : 'This is an irreversible action.'
              }}
            </p>
          </div>
        </div>
        <div class="flex items-center justify-center space-x-4">
          <Button text="Cancel" @click="emitClose()" color="tertiary" />
          <Button
            :text="this.forceDeleteId.isSelfWorkspace ? 'Delete' : 'Leave'"
            @click="submitClick"
            color="dangerFilled"
            :leftIcon="deleteLoader ? null : 'delete'"
            :showLoader="deleteLoader"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Input from '@/components/Input.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import Spinner from '../Spinner.vue'
import {
  deleteWorkspace,
  getWorkspaceById,
  leaveInvitedWorkspace
} from '@/apis/workspace'

export default {
  name: 'workspaceDeleteModal',
  components: {
    Input,
    SvgIcon,
    Button,
    Spinner
  },
  props: {
    forceDeleteId: {
      type: Object,

      required: true
    },
    currentWorkspace: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loader: false,
      deleteLoader: false,
      workspaceDataToDelete: null
    }
  },

  mounted() {
    this.workspaceData()
  },
  methods: {
    emitClose() {
      this.$emit('close')
    },

    async workspaceData() {
      try {
        this.loader = true
        const response = await getWorkspaceById(this.forceDeleteId.workspaceId)
        this.workspaceDataToDelete = response.data
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.loader = false
      }
    },

    submitClick() {
      if (this.forceDeleteId.isSelfWorkspace) {
        this.submitDelete()
      } else {
        this.submitLeave()
      }
    },
    async submitDelete() {
      try {
        this.deleteLoader = true
        const response = await deleteWorkspace(
          this.forceDeleteId.workspaceId,
          this.forceDeleteId.orgUserId
        )
        if (response['success']) {
          this.$emit('deleteSuccess', 'Workspace Deleted Successfully')
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.deleteLoader = false
      }
    },
    async submitLeave() {
      try {
        this.deleteLoader = true
        const response = await leaveInvitedWorkspace({
          workspaceId: this.forceDeleteId.workspaceId,
          orgUserId: this.forceDeleteId.orgUserId
        })
        if (response['success']) {
          this.$emit('deleteSuccess', 'Workspace Left Successfully')
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.deleteLoader = false
      }
    }
  }
}
</script>

<style scoped></style>
