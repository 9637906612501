<template>
  <div
    class="relative flex flex-1 border-l border-gray-200 dark:border-gray-700"
  >
    <div v-if="loading" class="flex h-full w-full items-center justify-center">
      <Spinner size="large" />
    </div>
    <div
      v-else
      class="relative flex flex-1 flex-col justify-between px-4 py-5 sm:p-6"
    >
      <div class="flex-1">
        <ValidationForm
          class="grid grid-cols-2 gap-6"
          :platformInputs="platformInputs"
          :previousNodes="[]"
          :inputs="inputData"
          :triggerValidation="triggerValidation"
          @validationSuccess="submitCreate"
          @validationFailed="validationFailed"
          @input-update="onInputUpdate"
          @input-blur="onInputBlur"
        />
      </div>

      <div class="mt-auto flex items-center justify-end">
        <Button
          v-if="isScheduled"
          :showLoader="buttonLoading"
          @click="stopSchedule()"
          text="Stop Schedule"
          color="danger"
          rightIcon="right-arrow"
        />
        <Button
          v-else
          :showLoader="buttonLoading"
          color="successFilled"
          @click="triggerValidation = true"
          text="Run"
          rightIcon="right-arrow"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/Input.vue'
import OutputTagsModal from '@/components/automationStore/OutputTagsModal.vue'
import Button from '@/components/Button.vue'
import InputOutputCard from '@/components/InputOutputCard.vue'
import InputOutputCardConnector from '@/components/InputOutputCardConnector.vue'
import Select from '@/components/Select.vue'
import ToastContainer from '@/components/ToastContainer.vue'
import ValidationForm from '@/components/ValidationForm.vue'
import { mapActions, mapState } from 'vuex'
import { constants, platformIds } from '@/common/constants'
import TwitterThread from '@/views/thread/TwitterThread.vue'
//API
import { getConnectedAccounts } from '@/apis/automation-store/Page1'
import {
  getPublicWorkflowInputs,
  savePublicWorkflowInputs
} from '@/apis/workflows'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'PublicWorkflowMain',
  components: {
    Input,
    Button,
    InputOutputCard,
    InputOutputCardConnector,
    Select,
    OutputTagsModal,
    ValidationForm,
    Spinner,
    ToastContainer,
    TwitterThread
  },
  emits: [
    'resetFromValidation',
    'AfterFormValidateFromNav',
    'AfterFormValidate',
    'success',
    'error'
  ],
  data() {
    return {
      inputData: {},
      triggerValidation: false,
      platformInputs: null,
      modalActive: false,
      spanList: null,
      loading: true,
      buttonLoading: false,
      connectedAccounts: {},
      showOpenAiAccountsFlag: false,
      twitterProfileData: null,
      threadText: null,
      twitterThreadId: constants['TWITTER_THREAD_ID']
    }
  },
  props: {
    validateForm: {
      required: true
    },
    isScheduled: {
      required: true
    }
  },
  async created() {
    this.platformInputs = null
    if (!this.automationStoreData.inputList) {
      await this.getAndSetWorkflowData()
    }
    // await this.getAndSetWorkflowData()
    await this.createPlatformInputs()
    this.loading = false
  },
  computed: {
    ...mapState('automationStore', ['automationStoreData']),
    ...mapState('automationStore', ['automationInputs']),
    // ...mapState('automationStore', ['isScheduled']),

    value() {
      return name => {
        return (name = this.automationInputs[name]
          ? this.automationInputs[name]
          : '')
      }
    }
  },
  methods: {
    ...mapActions('automationStore', ['addAutomationData']),
    ...mapActions('automationStore', ['setValidationStatus']),
    ...mapActions('automationStore', ['addConnectedAccountId']),
    ...mapActions('automationStore', ['addAutomationInputData']),

    async getAndSetWorkflowData() {
      const inputResponse = await getPublicWorkflowInputs(
        this.$route.query.publicWorkflowId
      )
      if (inputResponse['success']) {
        await this.addAutomationData({
          payload: {
            ...this.automationStoreData,
            logoUrl: this.automationStoreData.uniquePlatformIconUrl[0],
            inputList: {
              ...inputResponse.data
            }
          }
        })
      }
    },
    async createPlatformInputs() {
      const objectValues = Object.values(
        this.automationStoreData.inputList.inputNeeded
      )
      let flattenedArray = [].concat(...objectValues)
      if (
        this.automationStoreData.inputList.socialAccountNeeded &&
        this.automationStoreData.inputList.socialAccountNeeded.length > 0
      ) {
        let connectedAccArr = []

        //loop the socialAccountNeeded call API of the accounts and create platformInputs
        await Promise.all(
          this.automationStoreData.inputList.socialAccountNeeded.map(
            async (element, index) => {
              let response = await getConnectedAccounts(element)

              // set the connectedAccountId the first id from response
              let connectedAccountId = response?.data[0]?._id

              //if connected account found in vuex i.e ID already selected so replace the connectedAccountId
              if (this.automationInputs[element]) {
                connectedAccountId = this.automationInputs[element]
              }

              // store the whole account response in an object, needed later to change profile pic
              this.connectedAccounts[element] = response.data

              //find the exact account using the connectedAccountId
              let account = response?.data.find(
                ({ _id }) => _id === connectedAccountId
              )

              //create platform input and now add the profile id using the account
              this.inputData[element] = response.data[0]._id
              connectedAccArr.push({
                name: element,
                type: 'select',
                label: 'Account',
                isRequired: true,
                choices: response.data.map(account => ({
                  value: account._id,
                  label: account.name
                })),
                leftImageUrl: account?.platform?.picture,
                includeSearch: true,
                description: `Your ${platformIds[element]} Account`
              })
            }
          )
        )

        //create final array of all the inputs and account
        flattenedArray = [...connectedAccArr, ...flattenedArray]
      }
      this.platformInputs = flattenedArray
      if (Object.keys(this.automationInputs).length > 0) {
        this.inputData = this.automationInputs
      }
    },
    async onInputUpdate(inputs, validationResult) {
      //add inputs in vuex
      for (const input in inputs) {
        if (input === 'connectedAccountId') {
          this.addConnectedAccountId({
            payload: inputs[input] === '' ? null : inputs[input]
          })
        }
        if (input === 'threadText') continue
        this.addAutomationInputData({
          payload: { [input]: inputs[input] }
        })
      }
      //update vuex validation status
      if (validationResult.valid) {
        this.setValidationStatus({
          payload: true
        })
      } else {
        this.setValidationStatus({
          payload: false
        })
      }
      const inputObj = { ...inputs }

      this.automationStoreData.inputList.socialAccountNeeded.forEach(
        element => {
          const account = this.connectedAccounts[element]?.find(
            acc => acc._id === inputObj[element]
          )
          this.platformInputs = this.platformInputs.map(input => {
            if (input.name === element) {
              return {
                ...input,
                leftImageUrl: account?.platform?.picture
              }
            }
            return input
          })
        }
      )
    },
    async onInputBlur(values, validationResult, input) {
      if (validationResult.valid) {
        this.setValidationStatus({
          payload: true
        })
      } else {
        this.setValidationStatus({
          payload: false
        })
      }
    },

    validationFailed() {
      this.setValidationStatus({
        payload: false
      })
      this.triggerValidation = false
      this.$emit('resetFromValidation')
      if (this.validateForm !== false) {
        this.$emit('AfterFormValidateFromNav')
      }
    },
    async submitCreate(inputs) {
      try {
        this.setValidationStatus({
          payload: true
        })
        // this.buttonLoading = true

        this.$emit('resetFromValidation')
        let saveInputsData = {
          inputs: {},
          socialAccountIds: {}
        }

        for (const key in this.automationStoreData.inputList.inputNeeded) {
          if (this.automationStoreData.inputList.inputNeeded[key].length > 0) {
            saveInputsData.inputs[key] = {}
            this.automationStoreData.inputList.inputNeeded[key].forEach(
              element => {
                saveInputsData.inputs[key][element.name] = inputs[element.name]
              }
            )
          }
        }

        if (this.automationStoreData.inputList.socialAccountNeeded.length > 0) {
          this.automationStoreData.inputList.socialAccountNeeded.forEach(
            element => {
              saveInputsData.socialAccountIds[element] = inputs[element]
            }
          )
        }
        await savePublicWorkflowInputs(
          this.$route.query.publicWorkflowId,
          saveInputsData
        )

        // As the trigger is from the navBar
        if (this.validateForm !== false) {
          this.$emit('AfterFormValidateFromNav')
        } else {
          this.$emit('AfterFormValidate')
        }
      } catch (error) {
        this.$emit('error', error)
      }
    }
  }
}
</script>
