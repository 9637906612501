<template>
  <div
    class="relative -top-20 outline-none"
    @keyup.delete="onDeleteNodes"
    tabindex="0"
  >
    <VueFlow
      class="h-screen"
      @click="closeAllModals()"
      :class="{
        'z-20': rightPanelSelectedNode
      }"
    >
      <Background class="pattern z-0" gap="18" />
      <Controls :show-interactive="false" />
      <template #connection-line="props">
        <CustomConnectionLine v-bind="props" />
      </template>
      <template #node-automationNode="props">
        <AutomationNodeStatic
          v-if="route.query.executionId"
          v-bind="props"
          :executionData="executionData?.data"
          :menuOpenId="menuOpenId"
          :selectable="true"
          :result="result[props.id]"
          @toggle-menu="toggleMenu"
          @open-result="openResult"
        />
        <AutomationNode
          v-else
          v-bind="props"
          :menuOpenId="menuOpenId"
          :selectable="true"
          :rightPanelSelectedNode="rightPanelSelectedNode"
          :errorMessages="nodeErrors[props.id]"
          @error="error => $emit('error', error)"
          @open-panel="setRightPanelNode"
          @toggle-menu="toggleMenu"
          @open-input-editor="showInputModal"
          v-model:node-loader="nodeLoader"
        />
      </template>
      <template #node-utilityNode="props">
        <UtilityNodeStatic
          v-if="route.query.executionId"
          v-bind="props"
          :menuOpenId="menuOpenId"
          :selectable="true"
          @toggle-menu="toggleMenu"
          @open-result="openResult"
        />
        <UtilityNode
          v-else
          v-bind="props"
          :menuOpenId="menuOpenId"
          :selectable="true"
          :rightPanelSelectedNode="rightPanelSelectedNode"
          :errorMessages="nodeErrors[props.id]"
          @error="error => $emit('error', error)"
          @warning="warning => $emit('warning', warning)"
          @open-panel="setRightPanelNode"
          @toggle-menu="toggleMenu"
          @open-input-editor="showUtilityModal(props)"
          v-model:node-loader="nodeLoader"
        />
      </template>
      <Metadata
        v-model="metadata"
        @textUpdate="updateMetadata"
        @success="msg => $emit('success', msg)"
        class="relative left-4 top-20 z-10 w-[340px]"
      />
    </VueFlow>
    <HistoryModal
      :open="showHistory && !!workflow"
      @select="onSelectHistory"
      @modal-close="showHistory = false"
    />
    <ControlBar
      class="fixed bottom-0 z-20 w-full"
      :workflowId="route.params.id"
      :is-active="workflow?.isActive"
      :is-scheduled="workflow?.isScheduled"
      :validate="validateNodes"
      :desktopDataSelected="desktopDataSelected"
      :nodes="nodes"
      :workflowData="workflow"
      @show-history="showHistory = !showHistory"
      @response="(msg, response) => handleResponse(msg, response)"
      @show-schedule-modal="showScheduleModal"
      @show-outputMode-modal="showOutputModeModal"
      @show-desktop-modal="showDesktopModeModal"
      @error="msg => $emit('error', msg)"
    />
    <NodeLoader v-show="nodeLoader" />
    <RightPanel
      @close-panel="setRightPanelNode"
      :open="rightPanelSelectedNode"
      :showCloseButton="nodes.length > 0"
      @item-selected="onAutomationSelection"
    />
    <InputModal
      v-if="inputModalSelectedNode"
      :cloned="workflow.isCloned"
      :selectedNode="inputModalSelectedNode"
      :nodes="nodes"
      :desktopDataSelected="desktopDataSelected"
      @error-message="(id, message) => (nodeErrors[id] = message)"
      @input-modal-close="inputModalSelectedNode = null"
      @success="msg => $emit('success', msg)"
      @clear-configure-message="clearConfigureMessage"
    />
    <SchedulingModal
      v-if="showSchedule && workflow"
      :workflow="workflow"
      :firstNode="nodes[0]"
      @modal-close="showSchedule = null"
      @success="
        msg => {
          $emit('success', msg)
          refetchWorkflowOnce()
        }
      "
      @error="msg => $emit('error', msg)"
    />

    <DesktopModal
      v-if="showDesktopModal"
      :workflow="workflow"
      :currentSelected="desktopDataSelected"
      @close="showDesktopModal = null"
      @desktopSelected="desktopSelected"
      @desktopRemove="desktopRemove"
    />
    <OutputModeModal
      v-if="showOutputModal"
      :workflow="workflow"
      @modal-close="showOutputModal = null"
      @success="msg => $emit('success', msg)"
    />
    <ResultTableModal
      v-if="selectedNode"
      :node="selectedNode"
      @modal-close="closeResult"
    />
    <component
      v-if="utilityModalSelectedNode"
      :is="utilityModal"
      :selectedNode="utilityModalSelectedNode"
      @input-modal-close="utilityModalSelectedNode = null"
      :nodes="nodes"
      @error="
        e => {
          emit('error', e)
        }
      "
      @clear-configure-message="clearConfigureMessage"
      @incomingWebhookModalClose="resetWorkflow"
    />
  </div>
</template>

<script setup>
import {
  createWorkflow,
  deleteWorkflow,
  getWorkflow,
  getWorkflowAllNodes,
  getWorkflowExecutionAllNodesById,
  getWorkflowExecutionById,
  updateWorkflow,
  updateWorkflowNode
} from '@/apis/workflows'
import {
  EDGE_TYPE_NAMES,
  GRID_SIZE,
  NEXT_NODE_CONNECTION_TYPE,
  UTILITY_TYPES,
  WORKFLOW_CREATED_FROM
} from '@/common/constants'
import ControlBar from '@/components/workflowBuilder/ControlBar.vue'
import Metadata from '@/components/workflowBuilder/Metadata.vue'
import CustomConnectionLine from '@/components/workflowBuilder/customEdges/connectionLine.vue'
import AutomationNode from '@/components/workflowBuilder/customNodes/automationNode.vue'
import AutomationNodeStatic from '@/components/workflowBuilder/customNodes/automationNodeStatic.vue'
import {
  addNewNode,
  calculateNextNodeData,
  getNodeAndEdges,
  roundDownToGridSize
} from '@/components/workflowBuilder/helper'
import InputModal from '@/components/workflowBuilder/inputs/inputModal.vue'
import NodeLoader from '@/components/workflowBuilder/modals/NodeLoader.vue'
import DelayModal from '@/components/workflowBuilder/modals/delay/modal.vue'
import FilterModal from '@/components/workflowBuilder/modals/filters/modal.vue'
import HistoryModal from '@/components/workflowBuilder/modals/history/modal.vue'
import IncomingWebhookModal from '@/components/workflowBuilder/modals/incomingWebhooks/modal.vue'
import OutgoingWebhookModal from '@/components/workflowBuilder/modals/outgoingWebhooks/modal.vue'
import OutputModeModal from '@/components/workflowBuilder/modals/outputMode/modal.vue'
import ResultTableModal from '@/components/workflowBuilder/modals/result/modal.vue'
import RouterModal from '@/components/workflowBuilder/modals/routers/RouterModal.vue'
import SchedulingModal from '@/components/workflowBuilder/modals/schedule/modal.vue'
import DesktopModal from '@/components/workflowBuilder/modals/desktop/modal.vue'
import { fetchVariablesOnNodeAdditions } from '@/components/workflowBuilder/outputHashMap'
import RightPanel from '@/components/workflowBuilder/rightPanel/rightPanel.vue'
import {
  onNodeAdded,
  onNodeDeleted
} from '@/components/workflowBuilder/workflowNodeEventsHandler'
import { validateInput } from '@/helpers/validateInputs'
import { Background } from '@vue-flow/background'
import { Controls } from '@vue-flow/controls'
import '@vue-flow/controls/dist/style.css'
import { Position, VueFlow, useVueFlow } from '@vue-flow/core'
import {
  computed,
  markRaw,
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import UtilityNodeStatic from '@/components/workflowBuilder/customNodes/utilityNodeStatic.vue'
import UtilityNode from '@/components/workflowBuilder/customNodes/utilityNode.vue'

const rightPanelSelectedNode = ref(null)
const menuOpenId = ref(null)
const inputModalSelectedNode = ref(null)
const showSchedule = ref(false)
const showOutputModal = ref(false)
const showDesktopModal = ref(false)
const utilityModalSelectedNode = ref(null)
const utilityModal = ref(null)
const hydratingWorkflow = ref(true)
const nodeLoader = ref(false)
const metadata = ref({})
const workflow = ref()
const showHistory = ref(false)
const nodeErrors = reactive({})
const result = ref({})
const selectedNode = ref(null)
const workflowTimer = ref(null)
const desktopDataSelected = ref(null)
const executionData = ref(null)
const isRunning = ref(false)

const hasErrors = computed(() =>
  Object.values(nodeErrors).some(messages => messages.length > 0)
)

const route = useRoute()
const router = useRouter()

const store = useStore()

const utilityModalMap = {
  [UTILITY_TYPES.ROUTER]: markRaw(RouterModal),
  [UTILITY_TYPES.FILTER]: markRaw(FilterModal),
  [UTILITY_TYPES.DELAY]: markRaw(DelayModal),
  [UTILITY_TYPES.OUTGOINGWEBHOOK]: markRaw(OutgoingWebhookModal),
  [UTILITY_TYPES.INCOMINGWEBHOOK]: markRaw(IncomingWebhookModal)
}

const emit = defineEmits(['updateNodeInternals', 'warning', 'error'])

const {
  nodes,
  edges,
  addNodes,
  project,
  setCenter,
  onNodesChange,
  addEdges,
  removeNodes,
  getSelectedNodes,
  nodesSelectionActive,
  setNodes,
  setEdges,
  findNode
} = useVueFlow({
  edgeTypes: {
    [EDGE_TYPE_NAMES.CONNECTION_LINE]: markRaw(CustomConnectionLine)
  },
  defaultEdgeOptions: {
    type: EDGE_TYPE_NAMES.CONNECTION_LINE
  },
  autoConnect: true,
  snapToGrid: false,
  snapGrid: [GRID_SIZE, GRID_SIZE],
  nodesDraggable: false,
  deleteKeyCode: null,
  zoomOnDoubleClick: false
})

// unset all the nodes and edges
const resetWorkflow = () => {
  closeAllModals()
  setNodes([])
  setEdges([])
  getWorkflowToAddNodeAndEdges()
}

const checkAndDeleteWorkflow = async () => {
  if (
    nodes.value.length === 0 &&
    metadata.value.name === 'New Workflow' &&
    !isRunning.value
  ) {
    deleteWorkflow(workflow.value._id)
  }
  store.dispatch('automationStore/removeAutomationData')
}

// fetch the workflow and add node and edges
const getWorkflowToAddNodeAndEdges = async () => {
  const workflowId = route.params.id
  if (workflowId) {
    toggleNodeLoader(true)
    const [{ data: workflowData }, nodesResponse] = await Promise.all([
      getWorkflow(workflowId),
      getWorkflowAllNodes(workflowId)
    ])

    if (workflowData.isCloned) {
      nodesResponse.data.forEach(element => {
        if (element?.isConfigured === false) {
          nodeErrors[element._id] = [
            'Open the node and cross check inputs and account (wherever required)'
          ]
        }
      })
    }

    const { nodes, edges } = await getNodeAndEdges(workflowData, nodesResponse)

    if (!nodes.length) setRightPanelNode({})
    await store.dispatch('workflow/resetVariables')
    if (workflowData.webhook?.inputs) {
      await store.dispatch('workflow/setWebhookInputs', {
        inputs: workflowData.webhook.inputs,
        nodeId: workflowData.startNode
      })
    }
    addNodes(nodes)
    addEdges(
      edges.map(edge => ({ ...edge, data: { ...edge.data, editFilter } }))
    )
    workflow.value = workflowData
    metadata.value = {
      name: workflowData.name,
      notes: workflowData.notes
    }
    toggleNodeLoader(false)
  } else if (!nodes.value.length) {
    setRightPanelNode({})
    store.dispatch('workflow/resetVariables')
    metadata.value = {}
  }
  hydratingWorkflow.value = false
}

const clearConfigureMessage = id => {
  if (nodeErrors[id]) {
    nodeErrors[id] = nodeErrors[id].filter(
      item =>
        item !==
        'Open the node and cross check inputs and account (wherever required)'
    )
  }
}

onMounted(() => {
  const workflowId = route.params.id
  if (!workflowId) return
  workflowTimer.value = setInterval(async () => {
    const workflowRes = await getWorkflow(workflowId)

    workflow.value = workflowRes.data
    if (workflowRes.data?.desktopId) {
      desktopDataSelected.value = {
        _id: workflowRes.data?.desktopId,
        name: workflowRes.data?.desktopName
      }
    }
  }, 5000)
})

const refetchWorkflowOnce = async () => {
  const workflowId = route.params.id
  if (!workflowId) return
  const workflowRes = await getWorkflow(workflowId)
  workflow.value = workflowRes.data
}

// watch for workflow id change in the route
watch(route, () => {
  if (route.name === 'Workflow Builder') {
    resetWorkflow()
  }
})

onMounted(resetWorkflow)
onBeforeUnmount(checkAndDeleteWorkflow)
onBeforeUnmount(() => {
  clearInterval(workflowTimer.value)
})

const getInputsForNode = platformOperationId =>
  store.getters['workflow/getInputs'](platformOperationId) || []

onNodesChange(async changeData => {
  // fetch the output of any node that is added.
  fetchVariablesOnNodeAdditions(
    changeData,
    payload => store.dispatch('workflow/setVariables', payload),
    payload => store.dispatch('workflow/setDynamicOutputs', payload),
    payload => store.dispatch('workflow/setRouterAdded', payload)
  )

  // only continue if this change was made by the user and not during the
  // initialisation phase on mount
  if (hydratingWorkflow.value) return
  for (const change of changeData) {
    // check the type of the change
    if (change.type === 'add') {
      nextTick(async () => {
        try {
          await onNodeAdded(route.params.id, change.item, nodes, edges)
          toggleNodeLoader(false)
        } catch (error) {}
      })
    }
  }
})

const createOrUpdateWorkflow = async metadata => {
  if (!route.params.id) {
    const response = await createWorkflow(
      'direct-input',
      metadata.name || `New Workflow`,
      'test',
      WORKFLOW_CREATED_FROM.BUILDER
    )
    if (!response.success) {
      emit(
        'error',
        'Unable to create this workflow. Please try again in sometime.'
      )
      return
    }
    return response
  } else {
    return updateWorkflow(route.params.id, metadata)
  }
}

const updateMetadata = async metadata => {
  try {
    if (metadata.name || metadata.notes) {
      const response = await createOrUpdateWorkflow(metadata)
      if (!response.success) {
        throw response.message
      } else if (!route.params.id) {
        await router.push(`/workflow-builder/${response.data?._id}`)
      }
    }
  } catch (error) {
    emit('error', error)
  }
}

// select automation to add
const onAutomationSelection = async automationData => {
  if (nodes.value.length) {
    try {
      const node = findNode(rightPanelSelectedNode.value.nodeId)

      const { newNodes, newEdges } = await calculateNextNodeData(
        node,
        automationData,
        route.params.id,
        rightPanelSelectedNode.value.position,
        nodes,
        edges
      )

      // add the node and edge once the allHandles is updated.
      nextTick(() => {
        addNodes(newNodes)
        addEdges(
          newEdges.map(edge => ({
            ...edge,
            data: { editFilter }
          }))
        )
        emit('updateNodeInternals')
      })
    } catch (error) {
      console.log(error)
      // new node cannot be added error
      emit('warning', error.message)
    }
  } else {
    toggleNodeLoader(true)
    const position = project({
      x: GRID_SIZE,
      y: roundDownToGridSize(window.innerHeight / 2)
    })
    // set the newly added node as the vertical center of the screen
    setCenter(
      position.x - GRID_SIZE + window.innerWidth / 2 - 100,
      position.y + GRID_SIZE,
      {
        zoom: 1
      }
    )
    // if route doesnt have any id, that means workflow is not created yet.
    // create a workflow first and then apply the node changes
    const response = await createOrUpdateWorkflow(metadata.value)
    const workflowId = response.data?._id
    await router.push(`/workflow-builder/${workflowId}`)
    const newNode = await addNewNode(workflowId, {
      type: automationData.nodeType,
      position,
      label: automationData.shortName,
      data: {
        automationData,
        connectionType: NEXT_NODE_CONNECTION_TYPE.PRIMARY,
        targetHandle: Position.Left,
        order: 1
      }
    })
    await updateWorkflow(workflowId, { startNode: newNode.id })
    addNodes([newNode])
  }
}

const toggleMenu = nodeId => {
  menuOpenId.value = nodeId
}

const setRightPanelNode = (node, position) => {
  rightPanelSelectedNode.value = node
    ? { nodeId: node.id, node, position }
    : null
}

const showInputModal = node => {
  inputModalSelectedNode.value = node
}

const showScheduleModal = node => {
  showSchedule.value = true
}

const showOutputModeModal = () => {
  showOutputModal.value = true
}

const showDesktopModeModal = () => {
  showDesktopModal.value = true
}

const showUtilityModal = props => {
  utilityModalSelectedNode.value = {
    id: props.id,
    ...props.data
  }
  utilityModal.value = utilityModalMap[props.data.automationData.utilityType]
}

const editFilter = props => {
  const node = nodes.value.find(node => node.id === props.source)
  node.data = { ...node.data, routeId: props.target }
  showUtilityModal(node)
}

const onDeleteNodes = async e => {
  if (
    inputModalSelectedNode.value ||
    utilityModalSelectedNode.value ||
    route.query.executionId
  )
    return
  const selectedNodes = getSelectedNodes
  const totalNodes = nodes.value.length
  const totalSelectedNodes = selectedNodes.value.length
  nodesSelectionActive.value = false
  if (totalSelectedNodes > 0) {
    const deletedNodeIds = await onNodeDeleted(
      route.params.id,
      selectedNodes.value,
      nodes,
      edges,
      removeNodes,
      payload => store.dispatch('workflow/setRouterAdded', payload)
    )
    deletedNodeIds.forEach(id => delete nodeErrors[id])
    if (totalSelectedNodes === totalNodes) {
      setRightPanelNode({})
    }
  }
}

const toggleNodeLoader = value => {
  nodeLoader.value = value
}

const closeAllModals = () => {
  if (!nodes.value.length) return
  rightPanelSelectedNode.value = null
  inputModalSelectedNode.value = null
  utilityModalSelectedNode.value = null
  showSchedule.value = null
  showOutputModal.value = null
}

const onSelectHistory = async executionId => {
  try {
    await router.push({
      query: {
        executionId
      }
    })
  } catch (error) {
    console.log(error)
  }
}

const validateSavedInputs = (savedInputs = {}, inputFields) => {
  return inputFields
    .map(input => validateInput(input, savedInputs[input.name]))
    .filter(error => typeof error === 'string')
}

const validateNodes = () => {
  if (workflow.value.isCloned) {
    if (
      Object.values(nodeErrors).some(arr =>
        arr.includes(
          'Open the node and cross check inputs and account (wherever required)'
        )
      )
    ) {
      emit(
        'warning',
        'The highlighted automations have not been configured Please open them to check their inputs.'
      )
      return true
    }
  }

  nodes.value = nodes.value.map(node => {
    // only validate input if this node was added
    const {
      data: {
        automationData: { platformOperationId },
        savedInputs
      }
    } = node
    nodeErrors[node.id] = validateSavedInputs(
      savedInputs,
      getInputsForNode(platformOperationId)
    )
    return node
  })

  if (hasErrors.value) {
    emit(
      'warning',
      'The highlighted automations have one or more missing input. Please add them before running.'
    )
  }

  return hasErrors.value
}

const handleResponse = async (msg, response) => {
  //set isRunning flag to true so that workflow cannot be deleted
  isRunning.value = true
  refetchWorkflowOnce()
  if (msg === 'Automation started') {
    await router.push({
      query: {
        executionId: response.workflowExecutionId
      }
    })
  }
  emit('success', msg)
}

const getResult = async () => {
  const executionId = route.query.executionId
  if (!executionId) return
  const nodesExecutionResponse = await getWorkflowExecutionAllNodesById(
    route.query.executionId
  )

  const map = {}
  nodesExecutionResponse.data.forEach(node => {
    map[node.nodeId] = node
  })
  result.value = map

  executionData.value = await getWorkflowExecutionById(executionId)
  if (executionData.value.data.status === 'running') {
    isRunning.value = true
    setTimeout(getResult, 3000)
  }
}

watch(route, getResult)
onMounted(getResult)

const openResult = async node => {
  selectedNode.value = node
  await router.push({
    query: {
      nodeId: node._id,
      executionId: route.query.executionId,
      workflowId: route.params.id,
      platformId: node.platformId,
      operationId: node.platformOperationId
    }
  })
}

const closeResult = async () => {
  selectedNode.value = null
  await router.push({
    query: {
      executionId: route.query.executionId
    }
  })
}

const checkAndUpdateNode = async () => {
  const automationNodes = nodes.value.filter(
    item => item.type === 'automationNode'
  )
  if (automationNodes.length > 0) {
    const promise = automationNodes.map(obj => updateNodeForDesktop(obj.id))
    await Promise.all([
      ...promise,
      updateWorkflow(workflow.value._id, {
        desktopId: desktopDataSelected.value?._id || null,
        desktopName: desktopDataSelected.value?.name || null
      })
    ])
  }
}

const updateNodeForDesktop = async id => {
  await updateWorkflowNode(workflow.value._id, id, {
    desktopId: desktopDataSelected.value?._id || null
  })
}

const desktopSelected = data => {
  desktopDataSelected.value = data
  checkAndUpdateNode()
}
const desktopRemove = async () => {
  desktopDataSelected.value = null
  checkAndUpdateNode()
}
</script>

<style>
@import '@vue-flow/core/dist/style.css';
@import '@vue-flow/core/dist/theme-default.css';

.pattern {
  background-color: #f7f7f7;
}

.pattern circle {
  fill: #aeaeae;
}

.vue-flow__node {
  transition: transform 0.1s ease-out;
}

.swa-btn-confirm {
  @apply bg-red-500 !important;
}
.swa-btn-cancel {
  @apply bg-white text-black !important;
}

.swa-actions {
  width: 100%;
  justify-content: end;
  padding-right: 1.6em;
}
</style>
