<template>
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Add New Desktop
          </p>
          <div>
            <Button
              @click="close"
              icon="close-1"
              class="!p-1.5"
              size="small"
              color="tertiary"
            />
          </div>
        </div>
      </div>
      <div class="px-4 py-3 sm:px-6 flex flex-col gap-2">
        <div>
          <vee-form
            @submit="submitCreate()"
            :validation-schema="schema"
            id="inputForm"
          >
            <div class="grid grid-cols-2 gap-x-6 gap-y-5">
              <div class="col-span-2">
                <label
                  for=""
                  class="block text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  Desktop Name
                </label>
                <div class="mt-1.5">
                  <vee-field
                    class="block w-full rounded-lg border border-gray-300 px-3 py-2.5 placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                    name="Desktop Name"
                    type="text"
                    v-model="name"
                  />
                </div>
                <p class="mt-2 text-sm text-red-600">
                  <ErrorMessage name="Desktop Name" />
                </p>
              </div>
            </div>
          </vee-form>
        </div>
        <div class="flex flex-col gap-2" v-if="gotKey">
          <Input
            type="text"
            :readonly="true"
            :modelValue="key"
            label="Registration Key"
            right-icon="copy"
            right-icon-mouse="cursor-pointer"
            @onRightIconClick="copy()"
          />

          <div class="rounded-lg bg-blue-50 dark:bg-blue-900/50">
            <div class="p-4">
              <p
                class="ml-3 mr-auto flex-1 text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                This registration key is a
                <span class="font-semibold text-blue-600">unique</span>,<span
                  class="font-semibold text-blue-600"
                  >one-time</span
                >
                code designed exclusively for connecting TexAu with the Desktop
                App. Please carefully
                <span class="font-semibold text-blue-600">copy</span> and paste
                this key during the connection process
              </p>
              <p
                class="ml-3 mr-auto flex-1 text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                It's crucial to note that once you
                <span class="font-semibold text-blue-600">close</span> the
                modal, the registration key will be permanently
                <span class="font-semibold text-blue-600">deleted</span>. To
                generate a new registration key, you will need to add another
                desktop.
              </p>
              <p
                class="ml-3 mr-auto flex-1 text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                For any further assistance or inquiries regarding registration
                keys, please contact TexAu's support team directly. Thank you
                for your understanding.
              </p>
            </div>
          </div>
        </div>
        <div v-if="showSave" class="flex items-center justify-between">
          <Button
            :showLoader="loader"
            class="dark:!hover:bg-blue-400 !border-blue-600 !text-blue-600 dark:!text-blue-400"
            color="tertiary"
            text="Save Desktop"
            type="submit"
            form="inputForm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import { Field } from 'vee-validate'
import { inviteDesktop } from '@/apis/settings/desktop'
export default {
  name: 'DesktopAddModal',
  components: {
    Button,
    Input
  },
  data() {
    return {
      loader: false,
      gotKey: false,
      key: null,
      name: null,
      showSave: true,
      schema: {
        'Desktop Name': 'required'
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async submitCreate() {
      try {
        this.loader = true
        const response = await inviteDesktop(this.name)
        if (response['success']) {
          this.gotKey = true
          this.key = response.data.desktopLicenseKey
          this.showSave = false
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.loader = false
      }
    },
    async copy() {
      await navigator.clipboard.writeText(this.key)
      this.$emit('success', 'Desktop Registration Key Copied')
    }
  }
}
</script>
