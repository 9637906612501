<template>
  <AutomationHeader title="Download" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <div
      class="flex h-full min-w-0 flex-1 flex-col border-l-0 border-gray-200 dark:border-gray-700"
    >
      <div class="flex h-full items-center justify-center">
        <div v-if="loader">
          <div class="flex items-center justify-center">
            <Spinner size="large" />
          </div>
          <p class="mt-2 text-lg">{{ currentMessage }}</p>
        </div>

        <div v-else>
          <h2 v-if="errorOccured" class="text-red-500 text-center font-[500]">
            {{ errorMessage }}
          </h2>

          <RouterLink
            to="/automation-store?platform=LinkedIn"
            class="text-blue-600"
          >
            Click Here To Go Back To Automation Store</RouterLink
          >
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import Spinner from '@/components/Spinner.vue'
//api
import { downloadWorkflowCsv } from '@/apis/automation-store/csv'
export default {
  components: {
    AutomationHeader,
    Spinner
  },
  created() {
    if (this.$route.query.executionId) {
      this.startRotation()
      this.downloadCsvFile()
    } else {
      this.showRedirect = true
      this.loader = false
    }
  },
  data() {
    return {
      loader: true,
      showRedirect: false,
      currentMessage: '',
      message: [
        'Grabbing your data...',
        'Bundling it....',
        'Starting download...'
      ],
      errorOccured: false,
      errorMessage: ''
    }
  },
  computed: {
    waitingMessage() {}
  },
  methods: {
    async downloadCsvFile() {
      try {
        let csvResponse
        let downloadLink
        csvResponse = await downloadWorkflowCsv(this.$route.query.executionId)
        if (csvResponse['success']) {
          downloadLink = csvResponse.data.zipUrl
          const filename = 'my-file.csv'
          const anchor = document.createElement('a')
          anchor.href = downloadLink
          anchor.download = filename
          document.body.appendChild(anchor)
          anchor.click()
          document.body.removeChild(anchor)
        } else {
          throw csvResponse.message
        }
      } catch (error) {
        this.errorOccured = true
        this.errorMessage = error
        this.$emit('error', error)
      } finally {
        this.loader = false
      }
    },
    startRotation() {
      let index = 0
      setInterval(() => {
        this.currentMessage = this.message[index]
        index = (index + 1) % this.message.length
      }, 3000)
    }
  }
}
</script>
