<template>
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto max-w-2xl max-h-[90vh] w-full overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          class="rounded-md border border-gray-200 bg-white p-2 text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
          @click="emitClose"
        >
          <span class="sr-only"> Close </span>
          <SvgIcon name="close" />
        </button>
      </div>

      <div v-if="!showStepTwo" class="px-4 py-5 sm:p-6 lg:p-8 xl:p-12">
        <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
          Enter this secret key in any authenticator app:
        </p>
        <div class="text-center">
          <div class="mt-8 flex items-center justify-center space-x-5">
            <qrcode-vue
              :value="'otpauth://totp/TexAu V2?secret=' + secretKey"
              size="200"
              level="H"
              margin="4"
            />
          </div>

          <div class="relative mt-8">
            <label
              for=""
              class="block text-sm font-medium text-gray-900 dark:text-gray-50"
            >
              {{ secretKey }}
            </label>
          </div>

          <div class="mt-8 flex items-center justify-center space-x-5">
            <Button text="Next" @click="onNextClick" rightIcon="right-arrow" />
          </div>
        </div>
      </div>
      <div v-if="showStepTwo" class="px-4 py-5 sm:p-6 lg:p-8 xl:p-12">
        <vee-form
          @submit="submitCreate()"
          class="mt-10"
          :validation-schema="schema"
        >
          <div class="flex-1">
            <label
              for=""
              class="block text-sm font-medium text-gray-900 dark:text-gray-50"
            >
              Enter code from your authenticator app:
            </label>
            <div class="mt-5">
              <Input v-model="code" text="Enter code" type="tel" name="Code" />
            </div>
            <p class="mt-2 text-sm text-red-600">
              <ErrorMessage name="Code" />
            </p>
          </div>

          <div class="mt-8 flex items-center justify-center space-x-5">
            <Button :color="'secondary'" text="Back" @click="onBack()" />
            <Button
              :showLoader="isLoading"
              type="submit"
              text="Confirm"
              @click="onNextClick"
            />
          </div>
          <p class="mt-2 text-sm text-red-600"><ErrorMessage name="code" /></p>
        </vee-form>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import QrcodeVue from 'qrcode.vue'
import { confirmAssociation } from '@/apis/user'
import { mapActions } from 'vuex'
import UserProfile from '@/common/userWrapper'

export default {
  name: 'MfaConfirmSection',
  components: { SvgIcon, Button, Input, QrcodeVue },
  props: {
    secretKey: { type: String, required: true },
    show: { type: Boolean, required: true }
    // onClose: { type: Function, required: true }
  },
  data() {
    return {
      code: '',
      isLoading: false,
      showStepTwo: false,
      schema: {
        Code: 'required|min:6|max:6'
      }
    }
  },
  methods: {
    ...mapActions('settings', ['mfaEnableSuccessful']),

    onNextClick() {
      this.showStepTwo = true
    },

    async submitCreate() {
      this.isLoading = true
      const response = await confirmAssociation({ code: this.code })
      this.isLoading = false
      if (response['success']) {
        this.mfaEnableSuccessful({ payload: true })
        this.updateMfaStatusInCookies()
        this.emitClose()
        this.$emit('mfaSuccess')
      } else {
        console.log(response)
      }
    },

    updateMfaStatusInCookies() {
      let user_ = UserProfile.getUser()
      user_['isMfaEnabled'] = true
      UserProfile.setUser(user_)
    },

    onBack() {
      this.showStepTwo = false
    },
    emitClose() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped></style>
