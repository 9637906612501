<template>
  <div>
    <vee-form :validation-schema="schema" class="mt-10" @submit="submitEmail()">
      <div class="space-y-5">
        <div>
          <label
            class="block text-sm font-medium text-gray-900 dark:text-gray-50"
            for=""
          >
            Email Address
          </label>
          <div class="mt-1.5">
            <Input
              v-model="email"
              name="Email"
              text="john.doe@company.com"
              type="email"
            />
            <p class="mt-2 text-sm text-red-600">
              <ErrorMessage name="Email" />
            </p>
          </div>
        </div>

        <div>
          <Button
            :showLoader="isLoading"
            class="w-full"
            text="Send Code to Your Registered Phone"
            type="submit"
          />
        </div>
      </div>
    </vee-form>
  </div>
</template>

<script>
import { forgotPassword, resetPassword } from '@/apis/onboarding'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
export default {
  name: 'GetMobileOtp',
  components: {
    Input,
    Button
  },
  data() {
    return {
      email: null,
      isLoading: false,

      schema: {
        Email: 'required|email|min:3|max:100'
      },
      resetPasswordDiv: false
    }
  },
  methods: {
    async submitEmail() {
      this.isLoading = true
      let response
      try {
        response = await forgotPassword(this.email)
        if (response['success']) {
          this.$emit('codeSend', {
            email: this.email,
            message: 'Code send to your registered phone',
            type: 'success'
          })
        } else {
          throw 'responseError'
        }
      } catch (error) {
        if (error === 'responseError') {
          this.$emit('codeSend', {
            email: this.email,
            message: response.message,
            type: 'fail'
          })
        } else {
          console.log(error)
        }
      }
      this.isLoading = false
    }
  }
}
</script>

<style></style>
