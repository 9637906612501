<template>
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto max-h-full w-full max-w-2xl overflow-x-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Allocate Proxy Bandwidth
          </p>
        </div>
      </div>

      <div class="p-4 space-y-4">
        <!-- Heading Section -->
        <p
          v-if="pendingActionData.actionType === 'allocate-bandwidth'"
          class="text-center font-bold text-xl text-gray-800 dark:text-gray-200"
        >
          Thanks for your order!
        </p>
        <p
          v-if="pendingActionData.actionType === 'allocate-bandwidth'"
          class="text-center text-gray-600 dark:text-gray-400"
        >
          Now for the next step: allocate your proxy bandwidth to your
          workspaces.
        </p>
        <p v-else class="text-center text-gray-600 dark:text-gray-400">
          Allocate your remaining proxy bandwidth to your workspaces.
        </p>

        <!-- Important Notice Section -->
        <div class="p-4 relative bg-blue-500 rounded-lg text-white">
          <div class="space-y-3">
            <!-- Important Header -->
            <p
              class="flex items-center justify-center text-lg font-semibold gap-2"
            >
              <SvgIcon name="warning" class="w-5 h-5" />
              IMPORTANT
            </p>

            <!-- Instructions List -->
            <ul class="space-y-2 list-none">
              <li class="flex gap-2">
                <span class="font-semibold">•</span>
                <p>
                  Ensure that the proxy allocation does not exceed or fall below
                  the purchased amount.
                </p>
              </li>
              <li class="flex gap-2">
                <span class="font-semibold">•</span>
                <p>
                  For a comprehensive guide, read more
                  <a href="#" class="underline text-white">here</a>.
                </p>
              </li>
              <li class="flex gap-2">
                <span class="font-semibold">•</span>
                <p>
                  Need assistance?
                  <a
                    href="#"
                    @click.prevent="openIntercom"
                    class="underline text-white"
                    >Contact support</a
                  >.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        :key="refresh"
        class="p-6 bg-gray-50 dark:bg-gray-800 rounded-lg space-y-4 shadow-lg"
      >
        <!-- Header: Total Bandwidth Info -->
        <div class="flex justify-between items-center">
          <p class="text-lg font-semibold text-gray-800 dark:text-gray-100">
            Total Bandwidth
            {{
              pendingActionData.actionType === 'allocate-bandwidth'
                ? Purchased
                : ''
            }}: {{ pendingActionData.metadata.totalBandwidth }} GB
          </p>
          <div
            v-if="allocationWorkspaceData"
            class="text-lg text-blue-600 dark:text-blue-400"
            v-html="getBandwidthLeftText(totalBandwidthLeft.toFixed(2))"
          ></div>
        </div>

        <!-- Action Buttons -->
        <div class="flex justify-end gap-3">
          <Button
            text="Reset"
            @click="spreadProxyAllocation"
            size="small"
            left-icon="refresh"
            class=""
          />
          <Button
            text="Save"
            size="small"
            :color="validation ? 'successFilled' : 'gray'"
            :disabled="!validation"
            :left-icon="loading ? '' : 'save'"
            :show-loader="loading"
            @click="onSaveClick()"
          />
        </div>

        <!-- Workspace Allocation List -->
        <div v-if="allocationWorkspaceData" class="space-y-3">
          <div v-for="workspace in allocationWorkspaceData">
            <div
              :key="workspace.workspaceId"
              v-if="workspace.roleId === '621c57495e59073ab2aeda87'"
              class="p-2 bg-gray-200 rounded-lg border border-gray-200 dark:border-gray-700 shadow-sm"
            >
              <div class="flex items-center justify-between">
                <p class="font-semibold text-gray-800 dark:text-gray-100">
                  {{ workspace.name }}
                </p>
                <div class="flex items-center gap-2">
                  <Input
                    text="Add Bandwidth"
                    type="number"
                    :min="0"
                    v-model="workspace.proxyAllocated"
                    @input="handleAllocationChange(workspace)"
                    @keypress="preventNegative($event)"
                    @paste="preventPasteNegative($event)"
                    class="w-24 rounded-md dark:bg-gray-800 dark:text-white px-2 py-1"
                  />
                  <span class="font-semibold text-gray-700 dark:text-gray-400"
                    >GB</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import SvgIcon from '../SvgIcon.vue'
import { mapActions, mapState } from 'vuex'
import { getUsers, resolvePendingAction } from '@/apis/user'

/**
 * AllocateBandwidthModal component handles the allocation of proxy bandwidth to workspaces.
 * It displays a modal with details about the total bandwidth available and allows users to allocate bandwidth to individual workspaces.
 * Includes validation, important instructions, and controls for saving and resetting allocations.
 *
 * @component
 * @example
 * <AllocateBandwidthModal :pendingActionData="pendingActionData" />
 */

export default {
  name: 'AllocateBandwidthModal',
  components: {
    Button,
    Input,
    SvgIcon
  },
  props: {
    pendingActionData: { type: Object }
  },
  computed: {
    ...mapState('user', ['userWorkspaceData']),
    /**
     * Computes the validation state based on the remaining bandwidth.
     * @returns {boolean} True if the total bandwidth left is 0, otherwise false.
     */
    validation() {
      return this.totalBandwidthLeft === 0
    }
  },
  data() {
    return {
      loader: false,
      allocationWorkspaceData: null,
      totalBandwidthLeft: 0,
      refresh: 0,
      loading: false
    }
  },
  mounted() {
    /**
     * Initializes the workspace data and spreads proxy bandwidth allocation when the component is mounted.
     */
    this.allocationWorkspaceData = this.userWorkspaceData.filter(
      workspace => workspace.roleId === '621c57495e59073ab2aeda87'
    )

    this.spreadProxyAllocation()
  },
  methods: {
    ...mapActions('user', ['saveWorkspaceData']),
    /**
     * Closes the modal by emitting the 'close' event.
     * @emits close
     */
    close() {
      this.$emit('close')
    },
    /**
     * Spreads the total bandwidth equally across the eligible workspaces.
     */
    spreadProxyAllocation() {
      const remainingWorkspaces = this.allocationWorkspaceData.length

      this.allocationWorkspaceData = this.allocationWorkspaceData.map(item => ({
        ...item,
        proxyAllocated: Number(
          this.pendingActionData.metadata.totalBandwidth / remainingWorkspaces
        )
      }))
      this.totalBandwidthLeft = 0
      this.refresh++
    },
    /**
     * Handles changes in proxy allocation for a specific workspace and updates the remaining bandwidth.
     * @param {Object} workspace The workspace whose allocation was changed.
     */
    handleAllocationChange(workspace) {
      let allocatedSum = 0

      // Calculate the sum of all proxyAllocated values using a loop
      for (let ws of this.allocationWorkspaceData) {
        allocatedSum += Number(ws.proxyAllocated) || 0
      }

      this.totalBandwidthLeft =
        this.pendingActionData.metadata.totalBandwidth - allocatedSum
    },

    // Prevent negative numbers from being typed
    preventNegative(event) {
      // Allow only numbers and specific keys
      const allowedKeys = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'Tab',
        '.'
      ]
      if (!allowedKeys.includes(event.key)) {
        event.preventDefault()
      }
    },
    // Prevent negative numbers from being pasted
    preventPasteNegative(event) {
      const clipboardData = event.clipboardData || window.clipboardData
      const pastedData = clipboardData.getData('text')

      // Check if pasted content is a positive number
      if (!/^\d*\.?\d*$/.test(pastedData)) {
        event.preventDefault()
      }
    },

    /**
     * Generates a message indicating the remaining or exceeded bandwidth.
     * @param {number} num The remaining or exceeded bandwidth value.
     * @returns {string} The HTML string displaying the remaining or exceeded bandwidth.
     */
    getBandwidthLeftText(num) {
      if (num < 0) {
        return `<p class='text-red-700 font-bold ' >Total Bandwidth Exceeded by: ${Math.abs(
          num
        )} GB</p>`
      } else {
        return `<p class='text-green-500 font-bold ' >Total Bandwidth Left: ${num} GB</p>`
      }
    },
    /**
     * Handles the save action by sending the updated allocation data to the server.
     * @async
     * @returns {Promise<void>} Resolves when the save action is completed or rejected.
     * @throws {Error} Throws an error if the save action fails.
     */
    async onSaveClick() {
      try {
        this.loading = true
        const obj = this.allocationWorkspaceData.reduce((acc, item) => {
          acc[item.workspaceId] = item.proxyAllocated
          return acc
        }, {})

        const convertedObject = Object.fromEntries(
          Object.entries(obj).map(([key, value]) => [key, Number(value)])
        )

        const response = await resolvePendingAction(
          this.pendingActionData._id,
          { metadata: convertedObject }
        )
        if (response['success']) {
          const userResponse = await getUsers()
          this.saveWorkspaceData({ payload: userResponse.data })
          this.$emit('success', 'Proxy Allocated successfully')
          this.close()
        } else {
          throw response.message
        }
      } catch (error) {
        console.log(error)
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
    },
    openIntercom() {
      const email = localStorage.getItem('email')
      window.Intercom(
        'showNewMessage',
        `Hello team, I would like some assistance regarding allocating my proxy bandwidth. Can you please help? Here is my email: ${email}`
      )
    }
  }
}
</script>
