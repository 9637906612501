<template>
  <AutomationHeader title="Developer API" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <SettingsMenu active-option="api" />
    <div
      v-if="apiAccess === false"
      class="relative flex-1 border-l border-gray-200 antialiased dark:border-gray-700"
    >
      <NoAccess />
    </div>
    <ApiKeySection v-else @apiResponse="apiResponse" />
  </main>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import ApiKeySection from '@/components/settings/apiKeySection.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import { mapState } from 'vuex'
import NoAccess from '@/components/NoAccess.vue'

export default {
  name: 'developerApi',
  components: {
    AutomationHeader,
    SettingsMenu,
    ApiKeySection,
    NoAccess
  },
  computed: {
    ...mapState('user', ['apiAccess'])
  },
  methods: {
    apiResponse(data) {
      this.$emit(data.type, data.message)
    }
  }
}
</script>

<style scoped></style>
