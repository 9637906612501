import axios from '@/common/axios'

const savePrompt = async data => {
  try {
    const response = await axios.post('/prompt', data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getPrompt = async (start = 0, limit = 50, forFeature = false) => {
  try {
    const response = await axios.get(`/prompt`, {
      params: { start, limit, forFeature }
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getPromptById = async id => {
  try {
    const response = await axios.get(`/prompt/${id}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const updatePrompt = async (promptId, data) => {
  try {
    const response = await axios.put(`/prompt/${promptId}`, data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const deletePrompt = async promptId => {
  try {
    const response = await axios.delete(`/prompt/${promptId}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getPromptPreview = async data => {
  try {
    const response = await axios.post(`/prompt/playground`, data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export {
  savePrompt,
  getPrompt,
  updatePrompt,
  deletePrompt,
  getPromptById,
  getPromptPreview
}
