<template>
  <section class="flex flex-1 border-t border-gray-200 dark:border-gray-700 antialiased text-gray-900 bg-white dark:bg-gray-900 dark:text-gray-50">
    <div class="flex items-center justify-center flex-1 max-w-xl px-8 py-6 mx-auto">
      <div class="text-center">
        <img class="w-auto h-64 mx-auto" src="@/assets/images/illustrations/empty-list.png" alt="">

        <p class="mt-8 text-2xl font-bold text-gray-900 dark:text-gray-50">
          Well, it looks your search URL gave 0 result. Maybe try another one?
        </p>

        <div class="mt-6">
          <Button text="Retry" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  name: "noResult",
  components: {
    Button
  }
}
</script>

<style scoped>

</style>
