import * as Amplitude from '@amplitude/analytics-browser'
import { constants } from '@/common/constants'

/**
 * Determines the Amplitude key based on the environment.
 * If the environment is 'prod', the Amplitude key is set; otherwise, it is an empty string.
 *
 * @constant {string}
 */
const key =
  constants.environment === 'production' ? constants.AMPLITUDE_KEY : ''

/**
 * Tracks an event using Amplitude.
 *
 * @param {string} eventName - The name of the event to be tracked.
 * @param {string} email - The user's email which will be used as the user ID.
 * @param {Object} [eventProperties={}] - Additional properties related to the event being tracked.
 */
export function amplitudeTrackEvent(eventName, email, eventProperties = {}) {
  Amplitude.init(key)
  Amplitude.track(
    eventName,
    { ...eventProperties, user_id: email },
    { user_id: email }
  )
}
