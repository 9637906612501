<!-- Spinner Component -->
<!-- Default size is medium, for size size='large', size='medium', size='small' can be passed -->

<template>
  <Loader :className="className"
  />
</template>

<script>
import Loader from "@/assets/icons/Loader.vue";

export default {
  name: "Spinner",
  components: {Loader},
  props: {
    size: {type: String, default: 'medium'},
  },
  computed: {
    className() {
      if(this.size === 'medium' || this.size === 'Medium'){
        return 'w-6 h-6'
      } else if(this.size === 'small' || this.size === 'Small'){
        return 'w-4 h-4'
      } else if(this.size === 'large' || this.size === 'Large'){
        return 'w-12 h-12'
      }
    },
  }
}
</script>

<style>

</style>
