<template>
  <a href="#" title=""
     class="px-3 py-2 text-sm font-medium transition-all duration-200 rounded-lg"
     :class="[{
         'text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-50': isActive,
         'text-gray-500 hover:text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-gray-50 dark:hover:bg-gray-700': !isActive,
       }]"
     aria-current="page">
    {{ optionName }}
  </a>
</template>

<script>
export default {
  name: "tabSingleMenuOption",
  props: {
    optionName: {type: String, required: true},
    isActive: {type: Boolean, default: false},
  }
}
</script>

<style scoped>

</style>
