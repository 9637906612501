import { NODE_TYPE_NAMES, UTILITY_TYPES } from '@/common/constants'

export const utilities = (options = {}) => ({
  [UTILITY_TYPES.ROUTER]: {
    name: 'Router',
    shortName: 'Router',
    logoUrl: 'router',
    isIcon: true,
    utilityType: UTILITY_TYPES.ROUTER,
    nodeType: NODE_TYPE_NAMES.UTILITY_NODE,
    numberOfOutputs: 2,
    hide:
      options.isRouterAdded ||
      options.isSecondaryNode ||
      options.isFirstNode ||
      options.isBetweenNodes
  },
  [UTILITY_TYPES.FILTER]: {
    name: 'Filter',
    shortName: 'Filter',
    logoUrl: 'filter',
    isIcon: true,
    utilityType: UTILITY_TYPES.FILTER,
    nodeType: NODE_TYPE_NAMES.UTILITY_NODE,
    numberOfOutputs: 1,
    hide: options.isSecondaryNode || options.isFirstNode
  },
  [UTILITY_TYPES.DELAY]: {
    name: 'Delay',
    shortName: 'Delay',
    logoUrl: 'delay',
    isIcon: true,
    utilityType: UTILITY_TYPES.DELAY,
    nodeType: NODE_TYPE_NAMES.UTILITY_NODE,
    numberOfOutputs: 1,
    hide: options.isSecondaryNode || options.isFirstNode
  },
  // [UTILITY_TYPES.DEDUPE]: {
  //   name: 'De Dupe',
  //   shortName: 'De Dupe',
  //   logoUrl: 'dedupe',
  //   isIcon: true,
  //   utilityType: UTILITY_TYPES.DEDUPE,
  //   nodeType: NODE_TYPE_NAMES.UTILITY_NODE,
  //   numberOfOutputs: 1
  // },
  [UTILITY_TYPES.OUTGOINGWEBHOOK]: {
    name: 'Outgoing Webhook',
    shortName: 'Outgoing Webhook',
    logoUrl: 'webhook',
    isIcon: true,
    utilityType: UTILITY_TYPES.OUTGOINGWEBHOOK,
    nodeType: NODE_TYPE_NAMES.UTILITY_NODE,
    numberOfOutputs: 1,
    hide: options.isFirstNode
  },
   [UTILITY_TYPES.INCOMINGWEBHOOK]: {
     name: 'Incoming Webhook',
     shortName: 'Incoming Webhook',
     logoUrl: 'incoming-webhook',
     isIcon: true,
     utilityType: UTILITY_TYPES.INCOMINGWEBHOOK,
     nodeType: NODE_TYPE_NAMES.UTILITY_NODE,
     numberOfOutputs: 1,
     hide: options.isSecondaryNode || !options.isFirstNode
   }
})
