export const REGISTER_STARTED = (state, payload) => {
  state.registrationData = payload
}

export const REGISTER_FINISHED = state => {}

export const LOGIN_STARTED = state => {}

export const LOGIN_SUCCESS = (state, payload) => {
  state.loginData = payload
}

export const CHANGE_NEW_IMPORT_PAGE = (state, payload) => {
  state.newImportPage = payload
}
