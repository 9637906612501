<template>
  <PromptBuilder @showTester="showTester" v-if="show === 'builder'" />
  <PromptTesterInputSelector
    :accountsData="accountsData"
    @showBuilder="show = 'builder'"
    @showResult="showResult"
    v-else-if="show === 'inputSelector'"
  />
  <PromptTestResult
    :resultData="resultData"
    @showBuilder="show = 'builder'"
    @showTester="show = 'inputSelector'"
    v-else-if="show === 'testResult'"
  />
</template>

<script>
import PromptBuilder from './PromptBuilder.vue'
import PromptTesterInputSelector from './PromptTesterInputSelector.vue'
import PromptTestResult from './PromptTestResult.vue'
export default {
  name: 'PromptComponent',
  components: {
    PromptBuilder,
    PromptTesterInputSelector,
    PromptTestResult
  },
  data() {
    return {
      show: 'builder',
      accountsData: null,
      resultData: null
    }
  },
  methods: {
    showTester(data) {
      this.accountsData = data
      this.show = 'inputSelector'
    },
    showResult(data) {
      this.resultData = [].concat(data)
      this.show = 'testResult'
    }
  }
}
</script>
