export const addNewUserClicked = ({ commit, dispatch }, { payload }) => {
  commit('ADD_NEW_USER_CLICKED', payload)
}

export const addNewTagClicked = ({ commit, dispatch }, { payload }) => {
  commit('ADD_NEW_TAG_CLICKED', payload)
}

export const saveMetadata = ({ commit, dispatch }, { payload }) => {
  commit('SAVE_METADATA', payload)
}

export const updateWorkspaceManagerStatus = (
  { commit, dispatch },
  { payload }
) => {
  commit('UPDATE_WORKSPACE_MANAGER_STATUS', payload)
}

export const mfaEnableSuccessful = ({ commit, dispatch }, { payload }) => {
  commit('UPDATE_MFA_ENABLE_STATUS', payload)
}

export const updateNewProxyAddStatus = ({ commit, dispatch }, { payload }) => {
  commit('UPDATE_NEW_PROXY_ADD_STATUS', payload)
}

export const setUserRole = ({ commit, dispatch }, { payload }) => {
  commit('SET_USER_ROLE', payload)
}
