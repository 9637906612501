<template>
  <!-- START WRAPPER -->
  <div class="flex h-full flex-col antialiased">
    <div class="flex min-h-0 flex-1 overflow-hidden">
      <!-- START MAIN CONTENT -->
      <main class="flex min-w-0 flex-1 flex-col overflow-y-auto">
        <AutomationHeader
          title="Yellow Page Leads Collector"
          iconUrl="yellow-page-leads-icon-url"
        />
        <AutomationProcessed />
      </main>
      <!-- END MAIN CONTENT -->
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import AutomationProcessed from '@/components/publicRecipes/automationProcessed.vue'

export default {
  name: 'page5',
  components: {
    AutomationHeader,
    AutomationProcessed
  }
}
</script>

<style scoped></style>
