<template>
  <div
    class="fixed left-0 top-0 z-40 flex h-screen w-screen items-center justify-center"
  >
    <div
      class="absolute left-0 top-0 h-screen w-screen bg-gray-400 bg-opacity-40"
      @click="$emit('input-modal-close')"
    />
    <div class="z-10 h-[650px] w-[1020px] rounded-[10px] bg-white px-8 py-10">
      <!-- Top tab and close button -->

      <div class="relative mb-9">
        <button
          type="button"
          class="absolute right-0 top-0 h-9 w-9 rounded-lg border border-gray-200 bg-white p-1.5 text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50 dark:focus:ring-offset-gray-900"
          @click="$emit('input-modal-close')"
        >
          <span class="sr-only"> Close </span>
          <SvgIcon name="close" class="m-auto" />
        </button>
        <span
          v-show="loadingInputs"
          class="absolute right-[40px] top-0 flex items-center px-3 py-2 text-gray-300"
        >
          {{ 'Loading' }}
          <Spinner size="small" class="ml-1 text-gray-400" />
        </span>
      </div>
      <div
        v-if="routes.length === 0 && !loadingInputs"
        class="mt-12 text-gray-400"
      >
        No automations are connected to this router. You need to connect
        automations to this router to configure the conditions.
      </div>
      <div
        v-else
        v-for="(route, index) of routes"
        v-show="!loadingInputs && tab === index"
      >
        <div class="flex w-64 px-2 pb-4">
          <Input
            class="w-64"
            label="Route label"
            text="eg: connection successful"
            v-model="routes[index].meta.label"
          />
        </div>
        <Filter
          :previousNodes="previousNodes"
          v-model="routes[index].router.filters"
          class="h-[480px]"
          :class="{
            'h-[412px]': index > 0
          }"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { getWorkflowNode, updateWorkflowNode } from '@/apis/workflows'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { useVueFlow } from '@vue-flow/core'
import { onMounted, onUnmounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { getAllPreviousNodes } from '../../helper'
import Filter from './Filter.vue'

const emit = defineEmits([
  'input-modal-close',
  'error',
  'clear-configure-message'
])

const props = defineProps({
  selectedNode: {
    type: Object
  },
  nodes: {
    type: Object
  }
})

const { edges } = useVueFlow()
const route = useRoute()
const loadingInputs = ref(true)
const tab = ref(0)
const previousNodes = ref([])
// reactive to watch changes in deep nested data
const routes = reactive([])
const callApi = ref(true)
// fetch all the inputs and node details on mount
// to display the input fields and their existing value
// saved in node.
onMounted(async () => {
  try {
    const [nodeResponse] = await Promise.all([
      getWorkflowNode(route.params.id, props.selectedNode.id)
    ])

    // add all the routes to the routes state
    nodeResponse.data.inputs.routers.map((router, index) => {
      const node = props.nodes.find(node => node.id === router.nextNode)
      if (!node) {
        throw 'noSecondNode'
      }
      if (!router.filters) {
        router.filters = []
      }
      if (props.selectedNode.routeId === router.nextNode) {
        tab.value = index
      }
      routes.push({ meta: { label: router.routeLabel, id: node.id }, router })
    })
    loadingInputs.value = false
    previousNodes.value = getAllPreviousNodes(props.selectedNode, props.nodes)
  } catch (error) {
    if (error === 'noSecondNode') {
      emit(
        'error',
        'Please add the Second node to continue adding router conditions'
      )
      callApi.value = false
      emit('input-modal-close')
    } else emit('error', 'Error occurred while fetching inputs.')
  }
})

// save the node details on unmount.
onUnmounted(async () => {
  try {
    if (!callApi.value) {
      return
    }
    // update route labels
    routes.forEach(route => {
      edges.value = edges.value.map(edge => {
        if (edge.target === route.meta.id) {
          return {
            ...edge,
            data: {
              ...edge.data,
              routeLabel: route.meta.label
            }
          }
        }
        return edge
      })
    })
    emit('clear-configure-message', props.selectedNode.id)
    await updateWorkflowNode(route.params.id, props.selectedNode.id, {
      inputs: {
        routers: routes.map(route => ({
          filters: route.router.filters,
          nextNode: route.meta.id,
          routeLabel: route.meta.label
        }))
      },
      isConfigured: true
    })
  } catch (error) {
    emit('error', 'Error occurred while saving changes.')
  }
})
</script>
