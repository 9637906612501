<template>
  <AddProfileInputModal
    :type="modalType"
    v-if="showModal"
    @close="showModal = false"
    @startTestUrl="startTestUrl"
    @startTestSheet="startTestSheet"
    @startTestCsv="startTestCsv"
  />
  <div class="flex h-full flex-col justify-between">
    <div v-if="loading" class="flex h-[80%] items-center justify-center">
      <Spinner size="large" />
    </div>

    <div v-else class="mx-auto flex items-center justify-center px-8 py-6">
      <div class="text-center">
        <div class="flex flex-col gap-y-4">
          <p class="text-2xl font-bold text-gray-900 dark:text-gray-50">
            Select Your Data Sample To Test Your Prompt
          </p>
          <p class="text-sm text-gray-700">
            Your sample must contain at least one LinkedIn profile export to
            test your prompt.
          </p>
        </div>

        <!-- <div class="mt-8 grid grid-cols-4 gap-6"> -->
        <div class="mt-8 flex items-start gap-[76px]">
          <div class="w-[235px]">
            <div
              class="transform rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:-translate-y-1 hover:border-blue-600 hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:hover:border-blue-500"
            >
              <div class="px-4 py-5 sm:p-6">
                <p
                  class="text-base font-semibold text-gray-900 dark:text-gray-50"
                >
                  Dummy Sample
                </p>
                <img
                  class="mx-auto mt-8 h-16 w-auto"
                  src="../../../assets/images/url-icon.svg"
                  alt=""
                />
                <Button
                  class="mt-6"
                  text="Profile Sample"
                  rightIcon="right-arrow"
                  @click="onClick('sample')"
                />
              </div>
            </div>
            <p class="text-center font-[300] text-[16px] mt-[20px]">
              Use our provided sample of LinkedIn profiles.
            </p>
          </div>
          <div class="w-[235px]">
            <div
              class="transform rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:-translate-y-1 hover:border-blue-600 hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:hover:border-blue-500"
            >
              <div class="px-4 py-5 sm:p-6">
                <p
                  class="text-base font-semibold text-gray-900 dark:text-gray-50"
                >
                  URL
                </p>
                <img
                  class="mx-auto mt-8 h-16 w-auto"
                  src="../../../assets/images/url-icon.svg"
                  alt=""
                />
                <Button
                  class="mt-6"
                  text="Search Url"
                  rightIcon="right-arrow"
                  @click="onClick('url')"
                />
              </div>
            </div>
            <p class="text-center font-[300] text-[16px] mt-[20px]">
              Use a LinkedIn profile search URL.
            </p>
          </div>
          <div class="w-[235px]">
            <div
              class="transform rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:-translate-y-1 hover:border-blue-600 hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:hover:border-blue-500"
            >
              <div class="px-4 py-5 sm:p-6">
                <p
                  class="text-base font-semibold text-gray-900 dark:text-gray-50"
                >
                  Sheet
                </p>
                <img
                  class="mx-auto mt-8 h-16 w-auto"
                  src="../../../assets/images/sheet-file-icon.svg"
                  alt=""
                />
                <Button
                  @click="onClick('sheet')"
                  class="mt-6"
                  text="Google Sheets"
                  rightIcon="right-arrow"
                />
              </div>
            </div>
            <p class="text-center font-[300] text-[16px] mt-[20px]">
              Use LinkedIn profile URLs stored on a Google Sheet.
            </p>
          </div>
          <div class="w-[235px]">
            <div
              class="transform rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:-translate-y-1 hover:border-blue-600 hover:shadow-lg dark:border-gray-700 dark:bg-gray-900 dark:hover:border-blue-500"
            >
              <div class="px-4 py-5 sm:p-6">
                <p
                  class="text-base font-semibold text-gray-900 dark:text-gray-50"
                >
                  CSV
                </p>
                <img
                  class="mx-auto mt-8 h-16 w-auto"
                  src="../../../assets/images/csv-file-icon.svg"
                  alt=""
                />
                <Button
                  @click="onClick('csv')"
                  class="mt-6"
                  text="CSV File"
                  rightIcon="right-arrow"
                />
              </div>
            </div>
            <p class="text-center font-[300] text-[16px] mt-[20px]">
              Use LinkedIn profile URLs stored on a CSV File.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="pl-4 pb-4">
      <Button
        @click="$emit('showBuilder')"
        text="Go Back"
        leftIcon="left-arrow"
      />
    </div>
  </div>
</template>
<script>
import { WORKFLOW_CREATED_FROM } from '@/common/constants'
import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import {
  addWorkflowNode,
  createWorkflow,
  postExecution,
  updateWorkflow,
  updateWorkflowNode,
  getWorkflowExecution
} from '@/apis/workflows'
import { previewData } from '@/apis/workflows'
import AddProfileInputModal from './AddProfileInputModal.vue'
import { constants } from '@/common/constants'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PromptTesterInputSelector',

  props: {
    promptTitleAndDescription: { type: Object },
    accountsData: { type: Object }
  },
  data() {
    return {
      loading: false,
      timeoutDuration: 3000,
      showModal: false,
      url: null,
      modalType: 'url'
    }
  },
  computed: {
    ...mapState('workflow', ['dynamicOutputs'])
  },
  components: { Button, Spinner, AddProfileInputModal },
  methods: {
    async onClick(type) {
      switch (type) {
        case 'url':
          this.modalType = 'url'
          this.showModal = true
          break

        case 'sample':
          this.automationDummySample()
          break
        case 'sheet':
          this.modalType = 'sheet'
          this.showModal = true
          break

        case 'csv':
          this.modalType = 'csv'
          this.showModal = true
          break

        default:
          break
      }
    },
    startTestUrl(data) {
      this.showModal = false
      this.url = data
      this.loading = true
      this.automationSingleInputUrl()
    },
    async startTestSheet(data) {
      this.showModal = false
      this.loading = true
      let obj = { ...data }

      try {
        const workflowId = await this.createWorflow()

        const keys = this.dynamicOutputs.keys()
        let firstNodeId = keys.next().value

        let nodeData = {
          id: firstNodeId,
          platformOperationId: constants.googlePlatformOperationId,
          platformId: constants.googlePlatformId,
          label: 'Google Sheet Input',
          nextNode: '',
          previousNode: '',
          connectedAccountId: obj['connectedAccountId'],
          inputs: {
            googleSpreadsheetId: obj['googleSpreadsheetId'],
            googleSheetId: obj['googleSheetId'],
            isLoop: false
          }
        }

        // Create first node
        await addWorkflowNode(workflowId, nodeData)

        await this.secondAndThirdNodeGeneration(firstNodeId, workflowId, obj)
      } catch (error) {
        this.$emit('apiFail', error)
      } finally {
        this.loading = false
      }
    },

    async startTestCsv(data) {
      this.showModal = false
      this.loading = true
      let obj = { ...data }
      try {
        const workflowId = await this.createWorflow()

        const keys = this.dynamicOutputs.keys()
        let firstNodeId = keys.next().value

        let nodeData = {
          id: firstNodeId,
          platformOperationId: constants.csvPlatformOperationId,
          platformId: constants.csvPlatformId,
          label: 'Csv Input',
          nextNode: '',
          previousNode: '',
          inputs: {
            csvUrl: obj['url']
          }
        }

        // Create first node
        await addWorkflowNode(workflowId, nodeData)

        await this.secondAndThirdNodeGeneration(firstNodeId, workflowId, obj)
      } catch (error) {
        this.$emit('apiFail', error)
      } finally {
        this.loading = false
      }
    },

    //Workflow creation logic for single input URL
    async automationSingleInputUrl() {
      try {
        const workflowId = await this.createWorflow()

        let firstNodeData = {
          platformOperationId: constants.LINKEDIN_PEOPLE_SEARCH_ID,
          platformId: constants.LINKEDIN_PLATFORM_ID,
          label: 'LinkedIn Profile Search Extractor',
          nextNode: '',
          previousNode: '',
          connectedAccountId: this.accountsData.selectedPlatformAccount,
          inputs: {
            liPeopleSearchUrl: this.url,
            maxCount: 5
          }
        }
        // console.log(firstNodeData)
        // return

        let firstNodeId = await this.nodeCreation(workflowId, firstNodeData)
        await this.addNodeToWorkflow(workflowId, firstNodeId)

        let secondNodeData = {
          platformOperationId: this.$route.query.promptId, //prompt ID
          platformId: '63e10116079be44dacafe81d', // OpenAI Platform ID
          label: this.promptTitleAndDescription.title, // Prompt name
          nextNode: '',
          previousNode: firstNodeId,
          connectedAccountId: this.accountsData.selectedOpenAiAccount, //Open AI account
          inputs: {
            texAuSocialAccountId: this.accountsData.selectedPlatformAccount,
            firstName: `{{${firstNodeId}.fullName}}`,
            companyName: `{{${firstNodeId}.companyName}}`
          }
        }

        let secondNodeId = await this.nodeCreation(workflowId, secondNodeData)

        await this.updateNode(workflowId, firstNodeId, {
          nextNode: secondNodeId
        })

        await this.startExecution(workflowId, firstNodeId, secondNodeId)
      } catch (error) {
        this.$emit('apiFail', error)
      } finally {
        this.loading = false
      }
    },

    //Workflow creation logic for dummy sample
    async automationDummySample() {
      try {
        this.loading = true
        let csvUrl =
          'https://csv-input-v2.s3.us-west-1.amazonaws.com/6452d0ecd56b2b74e649f27e/39ee972d-89fc-4a82-9ff1-355d99335c5a.749bf53f-6a07-451b-afdf-6592d127f279csv'
        const workflowId = await this.createWorflow()

        let firstNodeData = {
          platformOperationId: constants.csvPlatformOperationId,
          platformId: constants.csvPlatformId,
          label: 'Csv Input',
          nextNode: '',
          previousNode: '',
          inputs: { csvUrl }
        }

        let firstNodeId = await this.nodeCreation(workflowId, firstNodeData)
        let data = {
          liProfileUrl: `{{${firstNodeId}.Profile Public URL}}`
        }
        await this.secondAndThirdNodeGeneration(firstNodeId, workflowId, data)
      } catch (error) {
        this.$emit('apiFail', error)
      } finally {
        this.loading = false
      }
    },

    async createWorflow() {
      try {
        const response = await createWorkflow(
          'input',
          `${this.promptTitleAndDescription.title} - Test`,
          this.promptTitleAndDescription.title,
          WORKFLOW_CREATED_FROM.STORE,
          'OpenAi'
        )

        if (response['success']) {
          return response.data._id
        } else {
          throw response.message
        }
      } catch (error) {
        throw error
      }
    },

    async nodeCreation(workflowId, nodeData) {
      try {
        const node = await addWorkflowNode(workflowId, nodeData)
        if (node['success']) {
          return node.data._id
        } else {
          throw node.message
        }
      } catch (error) {
        throw error
      }
    },

    async addNodeToWorkflow(workflowId, nodeId) {
      try {
        const response = await updateWorkflow(workflowId, {
          startNode: nodeId
        })

        if (response['success']) {
          return
        } else {
          throw response.message
        }
      } catch (error) {
        throw error
      }
    },

    async updateNode(workflowId, nodeId, data) {
      try {
        const response = await updateWorkflowNode(workflowId, nodeId, data)
        if (response['success']) {
          return
        } else {
          throw response.message
        }
      } catch (error) {
        throw error
      }
    },

    async secondAndThirdNodeGeneration(firstNodeId, workflowId, data) {
      try {
        await this.addNodeToWorkflow(workflowId, firstNodeId)

        let secondNodeData = {
          platformOperationId: constants.LINKEDIN_PROFILE_SCRAPER_ID,
          platformId: constants.LINKEDIN_PLATFORM_ID,
          label: 'LinkedIn Profile Scraper',
          nextNode: '',
          previousNode: '',
          connectedAccountId: this.accountsData.selectedPlatformAccount,
          inputs: {
            liProfileUrl: data['liProfileUrl']
          }
        }

        let secondNodeId = await this.nodeCreation(workflowId, secondNodeData)
        await this.updateNode(workflowId, firstNodeId, {
          nextNode: secondNodeId
        })

        let thirdNodeData = {
          platformOperationId: this.$route.query.promptId, //prompt ID
          platformId: '63e10116079be44dacafe81d', // OpenAI Platform ID
          label: this.promptTitleAndDescription.title, // Prompt name
          nextNode: '',
          previousNode: firstNodeId,
          connectedAccountId: this.accountsData.selectedOpenAiAccount, //Open AI account
          inputs: {
            texAuSocialAccountId: this.accountsData.selectedPlatformAccount,
            firstName: `{{${secondNodeId}.firstName}}`,
            companyName: `{{${secondNodeId}.companyName}}`
          }
        }

        let thirdNodeId = await this.nodeCreation(workflowId, thirdNodeData)

        await this.updateNode(workflowId, secondNodeId, {
          nextNode: thirdNodeId
        })

        await this.startExecution(workflowId, secondNodeId, thirdNodeId)
      } catch (error) {
        throw error
      }
    },

    async startExecution(workflowId, firstNodeId, secondNodeId) {
      try {
        const execution = await postExecution(workflowId)

        if (execution['success']) {
          let executionId = execution.data.workflowExecutionId
          await this.loopApiCall(workflowId)

          const resultResponseOpenAI = await previewData(
            executionId,
            secondNodeId,
            0,
            100
          )

          const resultResponseLinkedin = await previewData(
            executionId,
            firstNodeId,
            0,
            100
          )
          let resultData
          if (Array.isArray(resultResponseOpenAI.data.result.response)) {
            resultData = resultResponseOpenAI.data.result.response.map(
              (obj, index) => ({
                ...obj,
                ...resultResponseLinkedin.data.result.response[index]
              })
            )
          } else {
            resultData = {
              ...resultResponseOpenAI.data.result.response,
              ...resultResponseLinkedin.data.result.response
            }
          }

          this.loading = false
          this.$emit('showResult', resultData)
        } else {
          throw execution.message
        }
      } catch (error) {
        throw error
      }
    },

    async loopApiCall(workflowId) {
      let status = 'running'
      while (status === 'running') {
        try {
          const response = await getWorkflowExecution(workflowId)
          status = response.data[0].status
          // Set a timeout to run the loop again
          await new Promise(resolve =>
            setTimeout(resolve, this.timeoutDuration)
          )
        } catch (error) {
          throw error
        }
      }
    }
  }
}
</script>
