<template>
  <AutomationHeader title="Developer Mode" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <SettingsMenu active-option="Developer Mode" />

    <div class="relative flex-1 border-l border-gray-200 dark:border-gray-700">
      <div class="px-4 py-5 sm:p-5">
        <div class="flex flex-col gap-4">
          <div
            class="flex items-center bg-gray-100 p-2 rounded-lg justify-between"
          >
            <div>
              <p>Developer Mode</p>
            </div>
            <div>
              <Switch v-model="devMode" @change="onDevModeToggle" />
            </div>
          </div>
          <div class="bg-gray-100 p-2 rounded-lg">
            <p class="font-[500]">
              Enable Developer Mode to effortlessly copy Automation and Workflow
              IDs, view advanced data options in results, and explore beta
              features.
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import { useStore } from 'vuex'
import Switch from '@/components/Switch.vue'
import { onMounted, onUnmounted, ref, computed } from 'vue'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'

const store = useStore()
const emit = defineEmits(['error', 'success'])
const devMode = ref(false)

onMounted(async () => {
  devMode.value = JSON.parse(localStorage.getItem('devModeEnable')) || false
})

/**
 * Toggles Developer Mode and performs related actions such as updating localStorage, dispatching
 * a Vuex store action, tracking events via Amplitude, and emitting success or error messages.
 *
 * The function saves the current state of `devMode` in localStorage, dispatches a Vuex action
 * to save the dev mode status, tracks the toggle event with Amplitude, and emits success messages
 * depending on whether Developer Mode is enabled or disabled. If an error occurs, it emits an error event.
 *
 * @function onDevModeToggle
 * @throws Will emit an 'error' event if any operation fails.
 */

const onDevModeToggle = () => {
  try {
    localStorage.setItem('devModeEnable', JSON.stringify(devMode.value))
    store.dispatch('user/saveDevModeStatus', { devMode: devMode.value })
    if (devMode.value) {
      amplitudeTrackEvent(
        'Developer Mode Toggled on',
        localStorage.getItem('email')
      )
      emit('success', 'Developer Mode Successfully Enabled')
    } else {
      amplitudeTrackEvent(
        'Developer Mode Toggled off',
        localStorage.getItem('email')
      )
      emit('success', 'Developer Mode Successfully Disabled')
    }
  } catch (error) {
    emit('error', error)
  }
}
</script>

<style scoped></style>
