<template>
  <tr v-for="(row, index) of data">
    <td
      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
    >
      {{ row.name }}
    </td>
    <td
      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
    >
      {{
        `${formatDate(row.executionDetails.createdAt)} ${formatTime(
          row.executionDetails.createdAt
        )}`
      }}
    </td>
    <td
      class="whitespace-nowrap px-3 py-4 text-sm capitalize text-gray-900 dark:text-gray-50"
    >
      {{ row.executionDetails.trigger }}
    </td>
    <td
      class="whitespace-nowrap px-3 py-4 text-sm capitalize text-gray-900 dark:text-gray-50"
    >
      {{ row.executionDetails.status }}
    </td>
    <td
      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
    >
      {{ `${formatExecutionTime(row.executionDetails.executionTimeUsed)}` }}
    </td>

    <td class="whitespace-nowrap px-3 py-4">
      <div class="item-center flex justify-center">
        <img
          class="relative inline-block h-8 w-8 rounded-full ring-2 ring-white dark:ring-gray-900"
          :src="img"
          alt=""
          v-for="img in row.uniquePlatformIconUrl"
        />
      </div>
    </td>

    <td
      class="min-w-[11rem] whitespace-nowrap px-3 py-4"
      v-if="row.executionDetails"
    >
      <div
        class="flex items-center justify-end space-x-6 text-gray-400 dark:text-gray-50"
      >
        <!-- TODO: will be added later -->
        <!-- <SvgIcon :name="row.executionDetails.desktop ? 'desktop' : 'cloud'" /> -->

        <Button
          text="View Results"
          color="success"
          :showLoader="isLoading && index === loaderIndex"
          @click="
            onSeeDataClick(
              row.executionDetails._id,
              row.workflowId,
              row.inputSource,
              index
            )
          "
        />
      </div>
    </td>

    <td class="whitespace-nowrap px-3 py-4">
      <div class="relative">
        <button
          type="button"
          class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
          @click="
            $emit('trigger-menu', {
              ref: $refs.buttons[index],
              id: row.executionDetails._id,
              executionId: row.executionDetails._id,
              workflowId: row.workflowId,
              inputSource: row.inputSource
            })
          "
          ref="buttons"
        >
          <span class="sr-only"> Open dropdown menu </span>
          <SvgIcon name="3-dot-menu" />
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import {
  getWorkflow,
  getWorkflowAllNodes,
  getWorkflowNode
} from '@/apis/workflows'
import { WORKFLOW_CREATED_FROM } from '@/common/constants'
import Button from '@/components/Button.vue'
import Backdrop from '../Backdrop.vue'
import StatusDot from '../StatusDot.vue'
import SvgIcon from '../SvgIcon.vue'
export default {
  name: 'WorkflowTableBody',
  props: {
    data: {
      type: Array,
      required: true,
      default: []
    }
  },
  emits: ['trigger-menu'],
  components: { StatusDot, SvgIcon, Backdrop, Button },
  data() {
    return {
      showMenuFor: null,
      loaderIndex: null,
      isLoading: false
    }
  },
  methods: {
    formatDate(d) {
      const newDate = new Date(Date.parse(d))

      const curr_date = newDate.getDate()

      const curr_month = newDate.toLocaleString('en-US', { month: 'short' })

      const curr_yr = newDate.getFullYear()
      return `${curr_date} ${curr_month} ${curr_yr}`
    },
    formatTime(d) {
      const newDate = new Date(Date.parse(d))
      let startTime = new Date(newDate.getTime())
      return startTime.toLocaleTimeString()
    },
    formatExecutionTime(ms) {
      const seconds = Math.floor(ms / 1000)
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds - hours * 3600) / 60)
      const remainingSeconds = seconds - hours * 3600 - minutes * 60
      return `${hours}hr ${minutes}min ${remainingSeconds}sec`
    },
    async onSeeDataClick(executionId, workflowId, inputSource, index) {
      this.loaderIndex = index
      this.isLoading = true
      const response = await getWorkflow(workflowId)
      if (response.data.createdFrom === WORKFLOW_CREATED_FROM.BUILDER) {
        await this.$router.push(
          `/data-store/results?executionId=${executionId}&workflowId=${workflowId}`
        )
        return
      }
      const startNode = response.data.startNode
      let nodeId, platformId, operationId
      if (inputSource === 'sheet' || inputSource === 'csv') {
        const response = await getWorkflowAllNodes(workflowId)
        ;({ nodeId, operationId, platformId } = this.parseResponse(
          response.data[1]
        ))
      } else {
        const response = await getWorkflowNode(workflowId, startNode)
        ;({ nodeId, operationId, platformId } = this.parseResponse(
          response.data
        ))
      }
      const url = `/automation-store/results?workflowId=${workflowId}&executionId=${executionId}&operationId=${operationId}&platformId=${platformId}&nodeId=${nodeId}&source=${inputSource}`
      await this.$router.push(url)
    },
    parseResponse(data) {
      return {
        nodeId: data._id,
        operationId: data.platformOperationId,
        platformId: data.platformId
      }
    }
  }
}
</script>
