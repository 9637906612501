import { AUTOMATION_VARIABLE_TYPES } from '@/common/constants'
import { nanoid } from 'nanoid'

export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
]

const commonDateInputs = [
  {
    _id: nanoid(),
    name: 'startDate',
    label: 'Start Date (optional)',
    type: AUTOMATION_VARIABLE_TYPES.DATE,
    placeholder: '12/12/2022'
  },
  {
    _id: nanoid(),
    name: 'endDate',
    label: 'End Date (optional)',
    type: AUTOMATION_VARIABLE_TYPES.DATE,
    placeholder: '12/12/2022'
  }
  // {
  //   _id: nanoid(),
  //   name: 'startTimeOffset',
  //   label: 'Start Time Offset (in minutes) (optional)',
  //   type: AUTOMATION_VARIABLE_TYPES.NUMBER
  // }
]
const commonTimeRangeInputs = [
  {
    _id: nanoid(),
    name: 'startTime',
    label: 'Start Time Everyday (optional)',
    type: AUTOMATION_VARIABLE_TYPES.TIME
  },
  {
    _id: nanoid(),
    name: 'endTime',
    label: 'End Time Everday (optional)',
    type: AUTOMATION_VARIABLE_TYPES.TIME
  }
]

export const scheduleInputs = [
  //'None'
  [],
  // 'At Regular Intervals',
  [
    {
      _id: nanoid(),
      name: 'minutes',
      label: 'Interval (in minutes)',
      type: AUTOMATION_VARIABLE_TYPES.NUMBER,
      isRequired: true
    },
    ...commonTimeRangeInputs,
    ...commonDateInputs
  ],
  // 'Once',
  [
    {
      _id: nanoid(),
      name: 'date',
      label: 'Date',
      type: AUTOMATION_VARIABLE_TYPES.DATE,
      isRequired: true,
      placeholder: '12/12/2022'
    },
    {
      _id: nanoid(),
      name: 'time',
      label: 'Time',
      type: AUTOMATION_VARIABLE_TYPES.TIME,
      isRequired: true
    }
  ],
  // 'Every Day',
  [
    {
      _id: nanoid(),
      name: 'time',
      label: 'Run Every day at',
      type: AUTOMATION_VARIABLE_TYPES.TIME,
      isRequired: true
    },
    ...commonDateInputs
  ],
  // 'Days Of The Week',
  [
    {
      _id: nanoid(),
      name: 'days',
      label: 'Days',
      type: AUTOMATION_VARIABLE_TYPES.MULTI_SELECT,
      isRequired: true,
      choices: DAYS.map((v, i) => ({
        value: i,
        label: v
      }))
    },
    {
      _id: nanoid(),
      name: 'time',
      label: 'Run At',
      type: AUTOMATION_VARIABLE_TYPES.TIME,
      isRequired: true
    },
    ...commonDateInputs
  ],
  // 'Days Of The Month',
  [
    {
      _id: nanoid(),
      name: 'dates',
      label: 'Dates',
      type: AUTOMATION_VARIABLE_TYPES.MULTI_SELECT,
      isRequired: true,
      choices: Array.from(new Array(31)).map((v, i) => ({
        value: i + 1,
        label: `${String(i + 1).length === 1 ? '0' : ''}${i + 1}`
      }))
    },
    {
      _id: nanoid(),
      name: 'time',
      label: 'Run At',
      type: AUTOMATION_VARIABLE_TYPES.TIME,
      isRequired: true
    },
    ...commonDateInputs
  ],
  // 'Specific Dates'
  [
    {
      _id: nanoid(),
      name: 'months',
      label: 'Months',
      type: AUTOMATION_VARIABLE_TYPES.MULTI_SELECT,
      isRequired: true,
      choices: MONTHS.map((v, i) => ({
        value: i,
        label: v
      }))
    },
    {
      _id: nanoid(),
      name: 'dates',
      label: 'Dates',
      type: AUTOMATION_VARIABLE_TYPES.MULTI_SELECT,
      isRequired: true,
      choices: Array.from(new Array(31)).map((v, i) => ({
        value: i + 1,
        label: `${String(i + 1).length === 1 ? '0' : ''}${i + 1}`
      }))
    },
    {
      _id: nanoid(),
      name: 'time',
      label: 'Run At',
      type: AUTOMATION_VARIABLE_TYPES.TIME,
      isRequired: true
    },
    ...commonDateInputs
  ]
]
