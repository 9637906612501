<template>
  <div ref="container">
    <p
      v-if="iteration"
      class="absolute bg-gray-700 -left-[40px] text-xs font-semibold -top-10 w-[170px] p-2 after:content-[''] after:absolute after:w-0 after:h-0 after:border-4 after:border-gray-700 after:-bottom-1 after:left-1/2 after:-translate-x-1/2 after:rotate-45 text-gray-200 rounded-lg"
    >
      {{ `${iteration} Row Processing...` }}
    </p>
    <div
      class="box-border flex cursor-pointer flex-col items-center justify-center truncate rounded-lg border-2 border-blue-500"
      @dblclick="openResult"
      :class="[
        {
          'bg-blue-50': selected,
          'bg-white': !selected,
          'border-red-500': failed || partiallyFailed,
          'border-orange-300': running,
          'border-green-500': completed
        }
      ]"
      :style="{
        height: NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] + 'px',
        width: NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] + 'px'
      }"
    >
      <div class="group" v-if="statusIcon">
        <SvgIcon
          :name="statusIcon"
          class="absolute right-0.5 top-0.5 h-6 w-6 cursor-default p-0.5"
          :class="{
            'fill-green-500 ': completed,
            'fill-red-500': failed || partiallyFailed,
            'fill-orange-300': running || paused || waiting || delayed
          }"
        />
        <span
          class="invisible absolute z-50 -top-6 rounded bg-white p-1 text-sm capitalize group-hover:visible"
        >
          {{ props.result?.status }}&nbsp;{{
            props.result?.statusReason ? `: ${props.result?.statusReason}` : ''
          }}
        </span>
      </div>

      <NodeMenu v-if="menuOpenId === id" class="absolute bottom-28" />
      <img
        class="h-10 w-10 rounded-full object-cover"
        :src="data.automationData.logoUrl"
        alt=""
      />

      <!-- <SvgIcon
      name="3-dot-menu-horizontal"
      class="absolute top-2 right-2 h-1 w-5 fill-gray-200 hover:fill-blue-500"
      @click.stop="e => $emit('toggle-menu', id)"
    /> -->

      <Handle
        v-for="{ position, connected } in allHandles"
        :id="position"
        :type="getHandleType(position)"
        :position="position"
        class="h-[10px] w-[10px]"
        :class="{
          invisible: !connected,
          'bg-blue-500':
            connected && (position === 'right' || position === 'left'),
          'bg-blue-200':
            connected && position !== 'right' && position !== 'left'
        }"
        :connectable="false"
      />
    </div>
    <p
      class="absolute -left-[10px] mt-2 w-[100px] overflow-hidden rounded-lg bg-white bg-opacity-40 p-1 text-center text-xs font-semibold text-gray-900 group-hover:text-blue-600 dark:text-gray-50 dark:group-hover:text-gray-50"
    >
      {{ data.order ? `${data.order}.` : '' }} {{ label }}
    </p>
  </div>
</template>

<script setup>
import { NODE_SIZES, NODE_TYPE_NAMES } from '@/common/constants'
import { Handle, Position, useVueFlow } from '@vue-flow/core'
import { computed, onMounted, ref } from 'vue'
import NodeMenu from '../nodeMenu.vue'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  type: {
    type: String
  },
  label: {
    type: String
  },
  data: {
    type: Object
  },
  position: {
    type: Object
  },
  menuOpenId: {
    type: String
  },
  selected: {
    type: Boolean
  },
  errorMessages: Array,
  rightPanelSelectedNode: {},
  readonly: Boolean,
  result: Object,
  executionData: Object
})

const emit = defineEmits([
  'updateNodeInternals',
  'error',
  'toggle-menu',
  'open-result',
  'update:node-loader'
])
const { edges } = useVueFlow()

const allHandles = ref([])
const container = ref()

const completed = computed(() => props.result?.status === 'completed')
const failed = computed(() => props.result?.status === 'failed')
const partiallyFailed = computed(
  () => props.result?.status === 'partially-failed'
)
const running = computed(() => props.result?.status === 'running')
const paused = computed(() => props.result?.status === 'paused')
const delayed = computed(() => props.result?.status === 'delayed')
const waiting = computed(() => props.result?.status === 'waiting')
const iteration = computed(() => {
  if (props.executionData?.state) {
    let isCurrNode =
      props.result?.nodeId === props.executionData?.state?.currentNode
    let isChildCurrNode = undefined

    if (!isCurrNode && props.executionData?.state?.childExecs?.length > 0) {
      isChildCurrNode = props.executionData?.state?.childExecs.find(
        obj => obj.currentNode === props.result?.nodeId
      )
    }
    if (
      (props.result?.status === 'running' ||
        props.result?.status === 'paused') &&
      (isCurrNode || isChildCurrNode)
    ) {
      if (isChildCurrNode) {
        if (!!isChildCurrNode.totalIterationCount) {
          return `${isChildCurrNode.iteration + 1}/${ordinalSuffix(
            isChildCurrNode.totalIterationCount
          )}`
        } else {
          return ordinalSuffix(isChildCurrNode.iteration + 1)
        }
      } else {
        if (!!props.executionData?.state?.totalIterationCount) {
          return `${props.executionData?.state?.iteration + 1}/${ordinalSuffix(
            props.executionData?.state?.totalIterationCount
          )}`
        } else {
          return ordinalSuffix(props.executionData?.state?.iteration + 1)
        }
      }
    }
  }

  function ordinalSuffix(num) {
    const j = num % 10,
      k = num % 100

    if (j == 1 && k != 11) {
      return num + 'st'
    }
    if (j == 2 && k != 12) {
      return num + 'nd'
    }
    if (j == 3 && k != 13) {
      return num + 'rd'
    }
    return num + 'th'
  }
})

const statusIcon = computed(() => {
  return props.result?.status ? `status-${props.result?.status}` : ''
})

// set all the handles of this node
const setHandles = () => {
  // if the previous is connected on the left edge or if this is the first node
  if (props.data.targetHandle === Position.Left || !props.data.targetHandle) {
    const outputHandles = edges.value
      .filter(e => e.source === props.id)
      .map(e => e.sourceHandle)
      .concat(
        edges.value.filter(e => e.target === props.id).map(e => e.targetHandle)
      )
    allHandles.value = Object.values(Position).map(pos => ({
      position: pos,
      isActive:
        pos === Position.Left ||
        pos === Position.Right ||
        !outputHandles.includes(pos), // deactivate already added secondary node dot
      connected: outputHandles.includes(pos)
    }))
  } else {
    // secondary nodes
    allHandles.value = [
      { position: props.data.targetHandle, isActive: false, connected: true }
    ]
  }
}

onMounted(() => {
  setHandles()
})

const getHandleType = pos =>
  props.data.targetHandle === pos ? 'target' : 'source'

const openResult = () => {
  emit('open-result', {
    _id: props.id,
    ...props.data.automationData
  })
}
</script>
