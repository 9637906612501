import { store } from '@/store/store'
const exportName = title => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')
  const hours = currentDate.getHours().toString().padStart(2, '0')
  const minutes = currentDate.getMinutes().toString().padStart(2, '0')
  const seconds = currentDate.getSeconds().toString().padStart(2, '0')

  let name = store._state.data.automationStore.automationStoreData.name
  if (title) {
    name = title
  }

  return `${name}_${day}-${month}-${year}_${hours}-${minutes}-${seconds}`
}

export { exportName }
