<template>
  <div class="grid grid-cols-2">
    <div class="col-span-1">
      <div class="pr-2 divHeight overflow-scroll">
        <h3 class="text-center font-[600]">Text</h3>
        <div class="flex flex-col gap-2 mt-2">
          <div class="" v-for="(row, index) of rows" :key="row.count">
            <div class="flex flex-col gap-2">
              <WorkflowRichTextEditor
                :placeholder="`Tweet ${index + 1}`"
                :include-ai-template="false"
                :include-variables="false"
                v-model="row.text"
                class="hover:border-blue-700 rounded-lg"
              />

              <div class="mt-1 flex justify-end gap-4">
                <Button
                  v-if="index > 0"
                  class="bg-gray-500 hover:bg-gray-600"
                  size="small"
                  text="-"
                  @click="removeRow(index)"
                />
                <Button size="small" text="+" @click="addRow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-1">
      <div class="divHeight overflow-scroll px-2">
        <h3 class="text-center font-[600]">Preview</h3>
        <div v-for="(row, index) of rows" :key="row.count">
          <div class="flex min-h-[100px] min-w-[250px] overflow-auto">
            <div class="mr-2 mt-2 flex min-w-[50px] flex-col items-center">
              <img
                :src="this.twitterProfileData.platform.picture"
                alt=""
                class="relative box-border h-[52] w-[52] rounded-full"
              />
              <div style="height: 5px; flex: 0 0 5px"></div>
              <div
                v-if="currentInputIndex > 1 && index + 1 != currentInputIndex"
                class="tweet-line"
              ></div>
            </div>
            <div class="flex w-full flex-col">
              <div class="flex justify-start">
                <p class="truncate">{{ this.twitterProfileData.name }}</p>
                <p class="truncate pl-2">
                  {{
                    `@${this.twitterProfileData.platform.userId
                      .split('/')
                      .pop()}`
                  }}
                </p>
              </div>
              <!--  whitespace-pre-wrap break-words pb-2 sm:max-w-[200px]  -->
              <div
                class="min-w-[270px] max-w-[325px] whitespace-pre-wrap break-words pb-2 3xl:max-w-[450px]"
              >
                {{ row.text }}
              </div>
              <div class="my-2 flex h-6 w-full items-center justify-end">
                <span
                  class="ml-2 text-right"
                  :class="{ 'text-red-600': row.text.length > 280 }"
                  >{{ 280 - row.text.length }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from '@/components/Spinner.vue'
import Button from '@/components/Button.vue'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'

import WorkflowRichTextEditor from '@/components/workflowBuilder/customInput/WorkflowRichTextEditor.vue'

export default {
  emits: ['threadUpdate'],
  components: {
    Spinner,
    AutomationHeader,
    Button,
    WorkflowRichTextEditor
  },
  data() {
    return {
      currentInputIndex: 1,
      rows: [{ count: 1, text: '' }]
    }
  },
  props: {
    twitterProfileData: { type: Object, required: true },
    inputData: {}
  },
  computed: {},
  async created() {
    if (this.inputData.threadText && this.inputData.threadText.length > 0) {
      this.rows = this.inputData.threadText.slice()
      this.currentInputIndex = this.inputData.threadText.length
    }
  },
  methods: {
    addRow() {
      this.currentInputIndex++
      this.rows.push({ count: this.currentInputIndex, text: '' })
    },
    removeRow(index) {
      this.currentInputIndex--
      this.rows.splice(index, 1)
    }
  },
  watch: {
    rows: {
      handler(newVal, oldVal) {
        this.$emit('threadUpdate', this.rows)
      },
      deep: true
    }
  }
}
</script>
<style scoped>
.tweet-line {
  width: 2px;
  background: #475662;
  height: 100%;
}

:deep(.ql-snow.ql-toolbar) {
  border-width: 0 0 1px 0 !important;
  border-style: solid;
  border-color: #d1d5db;
}

.divHeight {
  height: calc(100vh - 370px);
}
</style>
