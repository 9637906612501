<template>
  <body
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <!-- START WRAPPER -->
    <div class="flex min-h-screen items-stretch justify-between">
      <!-- START LEFT COLUMN -->
      <OnboardingLeftColumn />
      <!-- END LEFT COLUMN -->

      <!-- START RIGHT COLUMN -->
      <div class="flex-1 bg-white dark:bg-gray-900">
        <div class="grid h-full items-start justify-center">
          <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
            <div class="flex h-full max-w-xl flex-col">
              <a href="#" title="TexAu" class="">
                <CompanyLogo />
              </a>

              <div class="mt-12 grid h-full place-items-center">
                <div>
                  <!-- <div class="text-center">
                  <Heading text="One Software,"/>
                  <Heading text="Two Applications"/>
                </div> -->

                  <!-- <div
                    class="mt-12 grid grid-cols-1 gap-5 text-center sm:grid-cols-2"
                  > -->
                  <div class="mt-12 grid place-items-center px-32">
                    <!-- <div
                      class="flex transform flex-col overflow-hidden rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:-translate-y-1 hover:border-blue-600 hover:shadow-lg dark:border-gray-600 dark:bg-gray-900 dark:hover:border-blue-500"
                    >
                      <div class="flex-1 px-4 py-5 sm:p-6">
                        <img
                          class="mx-auto h-20 w-auto"
                          src="@/assets/images/onboarding/illustrations/keyboard-open.svg"
                          alt=""
                        />
                        <p
                          class="mt-6 text-xl font-bold text-gray-900 dark:text-gray-50"
                        >
                          TexAu Desktop
                        </p>
                        <p
                          class="mt-2 text-sm text-gray-600 dark:text-gray-400"
                        >
                          Installed on your device, no proxy needed.
                        </p>
                      </div>

                      <div class="mt-auto px-4 pb-5 sm:px-6 sm:pb-6">
                        <Button text="Download App" rightIcon="download" />
                      </div>
                    </div> -->

                    <div
                      class="flex transform flex-col overflow-hidden rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:-translate-y-1 hover:border-blue-600 hover:shadow-lg dark:border-gray-600 dark:bg-gray-900 dark:hover:border-blue-500"
                    >
                      <div class="flex-1 px-4 py-5 sm:p-6">
                        <img
                          class="mx-auto h-20 w-auto"
                          src="@/assets/images/onboarding/illustrations/cloud.svg"
                          alt=""
                        />
                        <p
                          class="mt-6 text-xl font-bold text-gray-900 dark:text-gray-50"
                        >
                          TexAu Cloud
                        </p>
                        <p
                          class="mt-2 text-sm text-gray-600 dark:text-gray-400"
                        >
                          Requires proxies. If you don't know anything about
                          proxies, we strongly recommend you to use the Desktop
                          App first.
                        </p>
                      </div>

                      <div class="mt-auto px-4 pb-5 sm:px-6 sm:pb-6">
                        <Button
                          :show-loader="isLoading"
                          @click="onCloudClick"
                          text="Yes, I have proxies"
                          rightIcon="right-arrow"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END RIGHT COLUMN -->
    </div>
    <!-- END WRAPPER -->
    <ToastContainer class="w-1/5" ref="toast" />
  </body>
</template>

<script>
import OnboardingLeftColumn from '@/components/onboarding/OnboardingLeftColumn.vue'
import Button from '@/components/Button.vue'
import Heading from '@/components/onboarding/Heading.vue'
import arrow from '@/assets/images/onboarding/illustrations/right-arrow-icon.svg'
import download from '@/assets/images/onboarding/illustrations/download-app-icon.svg'
import { updateUserDetail } from '@/apis/user'
import ToastContainer from '@/components/ToastContainer.vue'
import UserProfile from '@/common/userWrapper'
import CompanyLogo from '@/components/onboarding/CompanyLogo.vue'

export default {
  // step-6
  name: 'showCloudAndDesktop',
  data() {
    return {
      arrow,
      download,
      isLoading: false
    }
  },
  components: {
    OnboardingLeftColumn,
    Button,
    Heading,
    ToastContainer,
    CompanyLogo
  },

  methods: {
    async updateNextStep() {
      const responseUser = await updateUserDetail({
        currentOnboardingStep: 'Update Data'
      })
      UserProfile.updateOnboardingStateInCookies('Update Data')
      this.isLoading = false
      if (responseUser['success']) {
        await this.$router.push('/onboarding/update-data')
      } else {
        this.$refs.toast.addToast({
          timeout: 7000,
          text: 'Error Occurred!',
          color: 'red',
          caption: 'Error in moving to next step.',
          action: false,
          close: true
        })
      }
    },

    async onCloudClick() {
      this.isLoading = true
      await this.updateNextStep()
    }
  }
}
</script>

<style></style>
