<template>
  <!-- TOP HEADER OF EVERY AUTOMATION PAGE -->
  <AutomationHeader title="Automation Store" />
  <section class="flex flex-1 border-t border-gray-200 dark:border-gray-700">
    <!-- CATEGORIES SECTION -->
    <!-- TODO: WILL BE USED IN FUTURE WHEN API IS READY -->
    <!-- <div class="flex w-[280px] 2xl:max-w-xs">
      
      <Categories/>
    </div> -->

    <div
      class="relative flex-1 border-l border-gray-200 bg-white text-gray-900 dark:border-gray-700 dark:bg-gray-900"
    >
      <!-- MAIN PART OF THE AUTOMATION PAGE  -->
      <div class="px-4 py-5 sm:p-6">
        <PlatformSelection />
        <hr class="mt-8 border-gray-200 dark:border-gray-700" />
        <RecommendedWorkflows
          v-if="showRecommendedWorkflow()"
          :workflows="recommendedWorkflows"
        />
      </div>
    </div>
  </section>
</template>

<script>
import clock from '@/assets/images/automationStore/clock.svg'
import PlatformSelection from '@/components/automationMatrix/PlatformSelection.vue'
import RecommendedWorkflows from '@/components/automationMatrix/RecommendedWorkflows.vue'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import Categories from '@/components/automationStore/Categories.vue'
import AutomationCards from '../../components/automationStore/AutomationCards.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    AutomationCards,
    Categories,
    AutomationHeader,
    PlatformSelection,
    RecommendedWorkflows
  },
  name: 'AutomationStorePage1',
  data() {
    return {
      clock,
      recommendedWorkflows: [],
      tempLogos: [],
      tempName: '',
      tempPlatform: null
    }
  },
  computed: {
    ...mapGetters('automationMatrix', ['getPlatformOperationData'])
  },
  methods: {
    showRecommendedWorkflow() {
      const {
        firstSelectedPlatformOperationData,
        secondSelectedPlatformOperationData
      } = this.getPlatformOperationData
      const logos = []
      let name = ''
      let platform
      let tempPlatformId = {}
      let tempPlatformOperationId = {}
      if (
        firstSelectedPlatformOperationData !== null &&
        secondSelectedPlatformOperationData !== null
      ) {
        const firstSelectedPlatformDataTemp = this.getSelectedPlatformData(
          firstSelectedPlatformOperationData
        )
        const secondSelectedPlatformDataTemp = this.getSelectedPlatformData(
          secondSelectedPlatformOperationData
        )
        logos.push(firstSelectedPlatformDataTemp.logoUrl)
        logos.push(secondSelectedPlatformDataTemp.logoUrl)
        name =
          name +
          firstSelectedPlatformDataTemp.label +
          ' + ' +
          secondSelectedPlatformDataTemp.label
        platform = firstSelectedPlatformDataTemp.platformName.toLowerCase()
        tempPlatformOperationId['first'] =
          firstSelectedPlatformDataTemp.platformOperationId
        tempPlatformId['first'] = firstSelectedPlatformDataTemp.platformId
        tempPlatformOperationId['second'] =
          secondSelectedPlatformDataTemp.platformOperationId
        tempPlatformId['second'] = secondSelectedPlatformDataTemp.platformId
        this.recommendedWorkflows.unshift({
          logos,
          name,
          platform,
          platformId: tempPlatformId,
          platformOperationId: tempPlatformOperationId
        })
      }
      return !!(
        firstSelectedPlatformOperationData &&
        secondSelectedPlatformOperationData
      )
    },

    getSelectedPlatformData(selectedPlatformOperationData) {
      const { label, logoUrl, platformName, platformOperationId, platformId } =
        new Proxy(selectedPlatformOperationData, {})
      return { label, logoUrl, platformName, platformOperationId, platformId }
    }
  }
}
</script>

<style></style>
