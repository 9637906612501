<!--
  Modal
  =============
  - Modal is a component that can be used to display a modal window.
  =============
  Props:
  - show: Boolean, whether the modal is visible or not.
  - title: string
  - text: string
  - submitButtonName: string
  - cancelButtonName: string
  - onClose: function
  - onSubmit: function
  - onCancel: function
  - onOutsideClick: function
 -->

<template>
  <div
    v-if="show"
    class="absolute top-0 left-0 h-full w-full bg-transparent/50 p-24 dark:bg-transparent/50"
    @click.self="onOutsideClick"
  >
    <div
      class="relative mx-auto w-full max-w-xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          class="rounded-md border border-gray-200 bg-white p-2 text-gray-600 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
          @click="onClose"
        >
          <span class="sr-only"> Close </span>
          <SvgIcon name="close" />
        </button>
      </div>

      <div class="px-4 py-5 sm:p-6 lg:p-8 xl:p-12">
        <div class="text-center">
          <p class="text-4xl font-bold text-gray-900 dark:text-gray-50">
            {{ title }}
          </p>
          <p
            class="mt-4 text-base font-medium text-gray-600 dark:text-gray-400"
          >
            {{ text }}
          </p>

          <div class="mt-8 flex items-center justify-center space-x-5">
            <Button
              :color="'secondary'"
              :text="cancelButtonName"
              @click="onCancel"
            />
            <Button
              :color="'primary'"
              :text="submitButtonName"
              @click="onSubmit"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from './Button.vue'
import SvgIcon from './SvgIcon.vue'
export default {
  name: 'Modal',
  components: { SvgIcon, Button },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: ''
    },
    submitButtonName: {
      type: String,
      default: 'Submit'
    },
    cancelButtonName: {
      type: String,
      default: 'Cancel'
    },
    onClose: {
      type: Function,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    },
    onCancel: {
      type: Function,
      required: true
    },
    onOutsideClick: {
      type: Function,
      required: true
    }
  }
}
</script>
