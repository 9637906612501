<template>
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto h-fit w-full max-w-3xl overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
            Your subscription will be canceled at the end of your current
            billing period. We will retain your data for 14 days.
          </p>

          <div class="flex items-center justify-end space-x-4">
            <Button
              @click="close"
              icon="close-1"
              class="!p-1.5"
              size="small"
              color="tertiary"
            />
          </div>
        </div>
        <div class="mt-3 pb-5 sm:pb-6">
          <h2>Are you sure you want to cancel your subscription?</h2>
          <div class="mt-3 flex items-center justify-between">
            <Button text="Go Back" @click="close" />
            <Button
              text="Cancel Subscription"
              color="danger"
              @click="startCancelSubscription"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import { cardDetails } from '@/apis/settings/settings-billing'
export default {
  name: 'confirmationModal',
  components: {
    SvgIcon,
    Button
  },

  props: {
    url: { type: String, required: true }
  },

  methods: {
    close() {
      this.$emit('close')
    },

    startCancelSubscription() {
      this.$emit('startCancelPlan', this.url)
    }
  }
}
</script>

<style></style>
