export const ADD_DATA = (state, payload) => {
  state.automationStoreData = { ...state.automationStoreData, ...payload }
}

export const REMOVE_DATA = state => {
  state.platformData = {}
  ;(state.automationStoreData = {}),
    (state.connectedAccountId = null),
    (state.connectedAccountIdGoogleSheets = null),
    (state.secondNodeId = null),
    (state.automationOutputMode = {
      outputMode: 'append',
      isDedupeResult: true
    }),
    (state.iterationDelay = null),
    (state.validationStatus = false)
  state.automationScheduleData = null
  state.automationDesktopData = null
}

export const ADD_AUTOMATION_INPUT = (state, payload) => {
  state.automationInputs = { ...state.automationInputs, ...payload }
}

export const REMOVE_AUTOMATION_INPUT = state => {
  state.automationInputs = {}
}

export const TOGGLE_STORE_INPUT_DATA = (state, payload) => {
  state.storeInputData = payload
}

export const TOGGLE_WORKFLOW_ID_CREATED = (state, payload) => {
  state.workflowIdCreated = payload
}

export const ADD_EXECUTION_ID = (state, payload) => {
  state.executionId = payload
}

export const ADD_STATUS = (state, payload) => {
  state.AutomationStatus = payload
}

export const ADD_CONNECTED_ACCOUNT_ID = (state, payload) => {
  state.connectedAccountId = payload
}

export const ADD_CONNECTED_ACCOUNT_ID_GOOGLE_SHEETS = (state, payload) => {
  state.connectedAccountIdGoogleSheets = payload
}

export const ADD_SECOND_NODE_ID = (state, payload) => {
  state.secondNodeId = payload
}

export const SET_VALIDATION_STATUS = (state, payload) => {
  state.validationStatus = payload
}

export const SET_AUTOMATION_NAME_TEXT = (state, payload) => {
  state.automationNameText = payload
}

export const SET_DELAY_AUTOMATION_NAMING = (state, payload) => {
  state.delayAutomationNaming = payload
}

export const CHANGE_AUTOMATION_OUTPUT_MODE = (state, payload) => {
  state.automationOutputMode = payload
}

export const CHANGE_ITERATION_DELAY = (state, payload) => {
  state.iterationDelay = payload
}

export const ADD_AUTOMATION_SCHEDULE_DATA = (state, payload) => {
  state.automationScheduleData = payload
}

export const ADD_AUTOMATION_DESKTOP_DATA = (state, payload) => {
  state.automationDesktopData = payload
}

export const ADD_PLATFORM_DATA = (state, payload) => {
  Object.assign(state.platformData, payload.payload)
}

export const ADD_CSV_HEADERS = (state, payload) => {
  state.csvHeaders = payload
}
