<template>
  <AutomationHeader title="Security" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <MfaConfirmSection
      v-if="showMfaConfirmationModal"
      :secret-key="secretKey"
      @close="closeMfaConfirmationModal"
    />
    <removeMfaModal
      v-if="showRemoveMfaModal"
      @close="showRemoveMfaModal = false"
      @confirmRemoveMfa="confirmRemoveMfa"
    />
    <SettingsMenu active-option="security" />

    <div class="relative flex-1 border-l border-gray-200 dark:border-gray-700">
      <div class="px-4 py-5 sm:p-5">
        <nav class="flex justify-center space-x-4" aria-label="Tabs">
          <a
            href="#"
            title=""
            class="rounded-lg px-3 py-2 text-sm font-medium transition-all duration-200"
            :class="[
              {
                'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-50':
                  tab === 'email'
              },
              {
                'text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
                  tab === '2fa'
              }
            ]"
            aria-current="page"
            @click.prevent="tab = 'email'"
          >
            Email and Password
          </a>

          <a
            href="#"
            title=""
            class="rounded-lg px-3 py-2 text-sm font-medium transition-all duration-200"
            :class="[
              {
                'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-50':
                  tab === '2fa'
              },
              {
                'text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50':
                  tab === 'email'
              }
            ]"
            @click.prevent="tab = '2fa'"
          >
            2 Factor Authentication
          </a>
        </nav>
        <EmailAndPasswordSection
          @apiResponse="apiResponse"
          v-show="tab === 'email'"
        />
        <TwoFactorAuthenticationSection
          ref="twofaComponent"
          v-show="tab === '2fa'"
          @showRemoveMfaModal="showRemoveMfaModal = true"
          @openMfaConfirmationModal="openMfaConfirmationModal"
        />
      </div>
    </div>
  </main>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import EmailAndPasswordSection from '@/components/settings/emailAndPasswordSection.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import TwoFactorAuthenticationSection from '@/components/settings/twoFactorAuthenticationSection.vue'
import removeMfaModal from '@/components/settings/removeMfaModal.vue'
import MfaConfirmSection from '@/components/settings/mfaConfirmSection.vue'
export default {
  name: 'emailAndPassword',
  components: {
    AutomationHeader,
    SettingsMenu,
    EmailAndPasswordSection,
    TwoFactorAuthenticationSection,
    removeMfaModal,
    MfaConfirmSection
  },
  data() {
    return {
      tab: 'email',
      showRemoveMfaModal: false,
      showMfaConfirmationModal: false,
      secretKey: ''
    }
  },
  methods: {
    apiResponse(data) {
      this.$emit(data.type, data.message)
    },
    openMfaConfirmationModal(data) {
      this.secretKey = data
      this.showMfaConfirmationModal = true
    },
    confirmRemoveMfa() {
      this.showRemoveMfaModal = false
      this.$refs.twofaComponent.confirmRemoveMfa()
    },
    closeMfaConfirmationModal() {
      this.showMfaConfirmationModal = false
      this.$refs.twofaComponent.onModalClose()
    }
  }
}
</script>

<style scoped></style>
