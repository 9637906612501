import axios from '@/common/axios'

const fetchAllExecutions = async (
  start = 0,
  limit = 20,
  searchText,
  sortField,
  sortOrder,
  createdFrom,
  status,
  signal
) => {
  try {
    const response = await axios.get(`/executions`, {
      params: {
        q: searchText,
        start,
        limit,
        sortField,
        sortOrder,
        createdFrom,
        status
      },
      signal
    })
    return response.data
  } catch (error) {
    return error?.response?.data
  }
}

export { fetchAllExecutions }
