<template>
  <!-- START WRAPPER -->
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto h-full w-full max-w-3xl rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 h-full sm:px-6 flex flex-col justify-between">
        <div>
          <div class="flex items-center justify-between">
            <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
              Add New Prompt
            </p>

            <div>
              <Button
                @click="close"
                icon="close-1"
                class="!p-1.5"
                size="small"
                color="tertiary"
              />
            </div>
          </div>
          <hr class="my-2" />
          <div class="mt-3 flex flex-col gap-5 overflow-y-scroll h-[65vh]">
            <div class="grid grid-cols-2 gap-[15px]">
              <p class="font-bold text-gray-500">Your Profile:</p>
              <p class="font-bold text-gray-500">Your Prospect:</p>
            </div>

            <div class="grid grid-cols-2 gap-[15px]">
              <div>
                <div
                  v-if="
                    variableData.variables &&
                    Object.keys(variableData.variables).length > 0
                  "
                >
                  <div class="flex flex-col gap-4">
                    <div
                      v-for="(value, key) in variableData.variables"
                      class="flex flex-col gap-3"
                    >
                      <p class="font-bold text-gray-500 capitalize">
                        {{ key }}
                      </p>
                      <div
                        class="border-[1px] border-gray-300 h-[41.83px] rounded-lg p-[10px] w-full"
                      >
                        <div
                          class="flex h-5 p-[10px] w-fit rounded-lg items-center justify-center bg-green-200"
                        >
                          <p class="text-sm font-semibold text-green-600">
                            {{ key }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-4">
                <div
                  v-for="(value, key) in placeholders"
                  class="flex flex-col gap-3"
                >
                  <p class="font-bold text-gray-500 capitalize">{{ key }}</p>

                  <Input :text="inputText(key)" v-model="placeholders[key]" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row-reverse">
          <Button
            @click="$emit('previewPromptClicked', placeholders)"
            text="Preview Prompt"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import Input from '@/components/Input.vue'
export default {
  components: {
    Button,
    Spinner,
    Input
  },
  props: {
    promptText: { type: String, required: true },
    variableData: { type: Object, required: true }
  },
  mounted() {
    this.extractPlaceholders(this.promptText)
  },
  data() {
    return {
      loading: true,
      placeholders: null
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    extractPlaceholders(string) {
      const regex = /{{(.*?)}}/g
      const obj = {}
      let match

      while ((match = regex.exec(string)) !== null) {
        const placeholder = match[1].trim()

        switch (placeholder) {
          case 'firstName':
          case 'First Name':
            obj[placeholder] = 'John Doe'
            break

          case 'companyName':
          case 'Company Name':
            obj[placeholder] = 'TexAu'
            break

          case 'Job Title':
            obj[placeholder] = 'Engineer'
            break

          case 'Headline':
            obj[placeholder] = 'Revolutionizing the Digital World'
            break

          case 'Industry':
            obj[placeholder] = 'IT'
            break

          default:
            obj[placeholder] = null
            break
        }
      }

      this.placeholders = { ...obj }
    },
    inputText(key) {
      return `Enter ${key}`
    }
  }
}
</script>

<style></style>
