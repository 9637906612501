<template>
  <!-- START WRAPPER -->
  <div
    class="absolute left-0 top-0 z-50 grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative mx-auto max-h-full w-full max-w-xl overflow-x-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-between">
          <div class="flex gap-4">
            <div
              class="flex items-center -space-x-2 overflow-hidden justify-center"
            >
              <img
                class="relative inline-block h-8 w-8 rounded-full ring-2 ring-white dark:ring-gray-900"
                :src="img"
                alt=""
                v-for="img in modalData.iconUrl"
              />
            </div>

            <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
              {{ modalData.name }}
            </p>
          </div>

          <div class="">
            <Button
              @click="close"
              icon="close-1"
              class="!p-1.5"
              size="small"
              color="tertiary"
            />
          </div>
        </div>
      </div>

      <div class="px-4 pb-5 sm:px-6 sm:pb-6 flex flex-col gap-y-4">
        <div>
          <p class="text-center">Are You Sure To Reset The Workflow?</p>
        </div>
        <div class="bg-red-100 text-red-600 rounded-lg p-2">
          <div class="flex space-x-2 items-center justify-center">
            <SvgIcon class="h-5 w-5" name="warning" />
            <p class="font-bold">Warning</p>
          </div>
          <div>
            <p class="text-sm font-[500] text-center">
              This will delete all past failed executions and results.
            </p>
          </div>
        </div>
        <div class="flex items-center justify-center space-x-4">
          <Button
            class="w-[80.63px]"
            text="Yes"
            @click="submitReset"
            color="dangerFilled"
            :showLoader="resetLoader"
          />
          <Button text="Cancel" @click="close()" color="tertiary" />
        </div>
      </div>
    </div>
  </div>
  <!-- END WRAPPER -->
</template>

<script>
import Button from '@/components/Button.vue'
import Spinner from '@/components/Spinner.vue'
import Input from '@/components/Input.vue'
import { resetWorkflow } from '@/apis/workflows'
export default {
  components: {
    Button,
    Spinner,
    Input
  },
  props: {
    modalData: { type: Object, required: true }
  },
  data() {
    return {
      resetLoader: false
    }
  },
  emits: ['resetSuccess', 'resetFail'],
  computed: {},
  async created() {},
  methods: {
    close() {
      this.$emit('close')
    },
    async submitReset() {
      try {
        this.resetLoader = true
        const response = await resetWorkflow(this.modalData.workflowId)
        if (response['success']) {
          this.$emit('resetSuccess', 'Workflow Reset Successfully')
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('resetFail', error)
      } finally {
        this.close()
      }
    }
  }
}
</script>
