<template>
  <div
    class="relative flex-1 border-l border-gray-200 antialiased dark:border-gray-700"
  >
    <div v-if="initialLoading" class="px-4 py-5 sm:p-5">
      <div class="overflow-hidden">
        <table class="min-w-full">
          <thead class="bg-gray-50 dark:bg-gray-700">
            <tr>
              <th
                scope="col"
                class="w-[30vw] rounded-l-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                Tags
              </th>

              <th
                scope="col"
                class="w-[30vw] px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                Event
              </th>

              <th scope="col" class="rounded-r-lg px-3 py-3.5 text-left">
                <span class="sr-only"> Actions </span>
              </th>
            </tr>
          </thead>

          <tbody
            class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
          >
            <tr v-for="ele in 4" :key="ele">
              <td
                v-for="ele in 3"
                :key="ele"
                class="whitespace-nowrap px-3 py-4"
              >
                <Skeleton
                  height="41px"
                  width="313px"
                  borderRadius="8px"
                ></Skeleton>
              </td>
            </tr>
            <!-- ADD TAG TABLE -->
            <tr v-show="showAddInputTable">
              <td class="whitespace-nowrap px-3 py-4">
                <input
                  type="text"
                  v-model="tagName"
                  placeholder="Tag Name"
                  class="block h-[32.400px] w-full rounded-lg border border-gray-300 placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                />
              </td>

              <td
                class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                <input
                  type="text"
                  v-model="tagDescription"
                  placeholder="Tag Description"
                  class="block h-[32.400px] w-full rounded-lg border border-gray-300 placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                />
              </td>

              <td class="whitespace-nowrap px-3 py-4">
                <div
                  class="flex w-full items-center justify-end space-x-3 px-10"
                >
                  <Button
                    size="small"
                    text="Save"
                    color="tertiary"
                    :show-loader="showLoader"
                    @click="onSaveTagClick()"
                    class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
                  />
                  <Button
                    size="small"
                    text="Cancel"
                    color="tertiary"
                    @click="onCancelAddTagClick()"
                    class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="px-4 py-5 sm:p-5">
      <div class="flex flex-col">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              v-show="noData"
              class="mt-10 flex w-full flex-col items-center justify-center space-y-4"
            >
              <SvgIcon :name="emptyData" />
              <p class="text-3xl font-bold text-gray-900 dark:text-gray-50">
                No Tag Available
              </p>
            </div>
            <div class="overflow-hidden">
              <table class="min-w-full" v-if="!noData && !initialLoading">
                <thead class="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th
                      scope="col"
                      class="w-[30vw] rounded-l-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                    >
                      Tags
                    </th>

                    <th
                      scope="col"
                      class="w-[30vw] px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                    >
                      Event
                    </th>

                    <th scope="col" class="rounded-r-lg px-3 py-3.5 text-left">
                      <span class="sr-only"> Actions </span>
                    </th>
                  </tr>
                </thead>

                <tbody
                  class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
                  :key="componentKey"
                >
                  <tr v-for="(data, index) in tagsData" :key="data.tagId">
                    <td
                      v-show="!data.isEditing"
                      class="whitespace-nowrap px-3 py-4"
                    >
                      <Badge :text="data.name" color="added" />
                      <!-- block h-[32.400px] w-fit rounded-lg border border-gray-300 placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm -->
                    </td>
                    <td
                      v-show="data.isEditing"
                      class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                    >
                      <input
                        type="text"
                        v-model="data.name"
                        class="block h-[32.400px] w-full rounded-lg border border-gray-300 placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                      />
                    </td>

                    <td
                      v-show="!data.isEditing"
                      class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                    >
                      {{ data.description }}
                    </td>

                    <td
                      v-show="data.isEditing"
                      class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                    >
                      <input
                        type="text"
                        v-model="data.description"
                        class="block h-[32.400px] w-full rounded-lg border border-gray-300 placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                      />
                    </td>

                    <td class="whitespace-nowrap px-3 py-4">
                      <div
                        class="flex w-full items-center justify-end space-x-3 px-10"
                        v-show="!data.isEditing"
                      >
                        <Button
                          icon="edit"
                          color="iconOnly"
                          @click="data.isEditing = true"
                          class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
                        />
                        <Button
                          icon="delete"
                          color="iconOnly"
                          @click="deleteTagClick(data.tagId)"
                          class="-m-2 rounded-lg p-2 text-red-600 transition-all duration-200 hover:bg-red-50 focus:outline-none dark:text-red-400 dark:hover:bg-red-500 dark:hover:text-red-50"
                        />
                      </div>
                      <div
                        class="flex w-full items-center justify-end space-x-3 px-10"
                        v-show="data.isEditing"
                      >
                        <Button
                          size="small"
                          text="Save"
                          @click="onEditSaveClick(data.tagId, index)"
                          color="tertiary"
                          :show-loader="showLoader"
                          class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
                        />

                        <Button
                          size="small"
                          text="Cancel"
                          @click="
                            () => {
                              data.isEditing = false
                              tagName = null
                              tagDescription = null
                            }
                          "
                          color="tertiary"
                          class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
                        />
                      </div>
                    </td>
                  </tr>
                  <!-- ADD TAG TABLE -->
                  <tr v-show="showAddInputTable">
                    <td class="whitespace-nowrap px-3 py-4">
                      <input
                        type="text"
                        v-model="tagName"
                        placeholder="Tag Name"
                        class="block h-[32.400px] w-full rounded-lg border border-gray-300 placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                      />
                    </td>

                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                    >
                      <input
                        type="text"
                        v-model="tagDescription"
                        placeholder="Tag Description"
                        class="block h-[32.400px] w-full rounded-lg border border-gray-300 placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                      />
                    </td>

                    <td class="whitespace-nowrap px-3 py-4">
                      <div
                        class="flex w-full items-center justify-end space-x-3 px-10"
                      >
                        <Button
                          size="small"
                          text="Save"
                          color="tertiary"
                          :show-loader="showLoader"
                          @click="onSaveTagClick()"
                          class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
                        />
                        <Button
                          size="small"
                          text="Cancel"
                          color="tertiary"
                          @click="onCancelAddTagClick()"
                          class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
import Badge from '@/components/Badge.vue'
import Button from '@/components/Button.vue'
import { mapActions, mapGetters } from 'vuex'

//API CALL
import {
  deleteTag,
  getTags,
  addTag,
  updateTag
} from '@/apis/settings/settings-tags'
import Input from '../Input.vue'

export default {
  name: 'tagsSection',
  components: {
    SvgIcon,
    Button,
    Badge,
    Input
  },
  data() {
    return {
      initialLoading: false,
      tagsData: null,
      showAddInputTable: false,
      componentKey: 0,
      showLoader: false,
      tagName: null,
      tagDescription: null,
      workspaceId: null,
      emptyData: null,
      noData: false
    }
  },
  created() {
    this.getAllTags()
    this.getMode()
  },
  computed: {
    ...mapGetters('settings', ['getNewTagAddStatus'])
  },
  watch: {
    getNewTagAddStatus: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.noData = false

          this.showAddInputTable = true
          this.componentKey += 1
        }
      }
    }
  },
  methods: {
    ...mapActions('settings', ['saveMetadata']),
    ...mapActions('settings', ['addNewTagClicked']),
    getMode() {
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? (this.emptyData = 'empty-data-dark')
        : (this.emptyData = 'empty-data-light')
    },
    async getAllTags() {
      this.initialLoading = true

      this.workspaceId = window.$cookies.get('workspaceId')
      const response = await getTags(this.workspaceId)
      this.initialLoading = false
      if (response['success']) {
        const data = response.data
        this.tagsData = data.map(v => ({ ...v, isEditing: false }))

        if (Array.isArray(this.tagsData) && this.tagsData.length === 0) {
          this.noData = true
        }
      } else {
        this.$emit('error', 'Error in getting all tags.')
      }
    },

    async onSaveTagClick() {
      if (
        !this.tagsValidation({
          name: this.tagName,
          description: this.tagDescription
        })
      )
        return
      this.showLoader = true
      const response = await addTag({
        name: this.tagName,
        description: this.tagDescription,
        workspaceId: this.workspaceId
      })
      if (response['success']) {
        this.tagsData.push({
          name: this.tagName,
          description: this.tagDescription,
          workspaceId: this.workspaceId,
          tagId: response.data.tagId,
          isEditing: false
        })

        this.tagName = null
        this.tagDescription = null
        this.componentKey += 1
      } else {
        this.$emit('error', response.message)
      }
      this.showLoader = false
      this.showAddInputTable = false
      this.addNewTagClicked({ payload: false })
    },

    async deleteTagClick(id) {
      const response = await deleteTag({
        workspaceId: this.workspaceId,
        tagId: id
      })
      if (response['success']) {
        this.tagsData = this.tagsData.filter(tag => tag.tagId !== id)
        this.tagName = null
        this.tagDescription = null
        this.componentKey += 1
      } else {
        this.$emit('error', 'Error in deleting tag.')
      }
    },

    async onEditSaveClick(id, index) {
      if (!this.tagsValidation(this.tagsData[index])) return
      this.showLoader = true

      const response = await updateTag(this.tagsData[index])
      if (response['success']) {
        this.showLoader = true
        const newTagsData = this.tagsData.map(obj =>
          obj.tagId === id
            ? {
                name: this.tagsData[index].name,
                description: this.tagsData[index].description,
                workspaceId: this.tagsData[index].workspaceId,
                tagId: id,
                isEditing: false
              }
            : obj
        )
        this.tagsData = newTagsData

        this.tagName = null
        this.tagDescription = null
        this.showLoader = false
        this.componentKey += 1
      } else {
        this.showLoader = false
        this.$emit('error', response.message)
      }
    },

    onCancelAddTagClick() {
      this.showAddInputTable = false
      this.addNewTagClicked({ payload: false })
      if (this.tagsData.length === 0) {
        this.noData = true
      }
    },
    tagsValidation(obj) {
      if (obj.name === null || obj.name === '') {
        this.$emit('error', 'Please Provide A Tag Name')
        return false
      }
      if (obj.description === null || obj.description === '') {
        this.$emit('error', 'Please Provide A Tag Event')
        return false
      }
      return true
    }
  }
}
</script>

<style scoped></style>
