<template>
  <div class="relative py-1">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t-[3px] border-dashed border-blue-600 dark:border-blue-500"></div>
    </div>
    <div class="relative flex justify-center">
      <span class="rounded-full bg-blue-600 p-2 text-white dark:bg-blue-500">
        <SvgIcon :name="icon" />
      </span>
    </div>
  </div>
</template>

<script>
import SvgIcon from '../SvgIcon.vue'
export default {
  name: 'ConnectionPath',
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  components: { SvgIcon }
}
</script>
