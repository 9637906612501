<template>
  <AutomationHeader
      :highlight="highlight"
      :nav="true"
      icon="instagram"
      title="Collect Instagram Followers"
  />

  <section
      class="flex flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <OptionalSidebar :data="optionalSidebarData" item="start"/>

    <DirectInputRun/>
  </section>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import OptionalSidebar from '@/components/automationStore/OptionalSidebar.vue'
import DirectInputRun from '@/components/automationStore/directInput/DirectInputRun.vue'

export default {
  components: {
    AutomationHeader,
    OptionalSidebar,
    DirectInputRun
  },
  name: 'AutomationStorePage6',
  data() {
    return {
      highlight: 3,
      optionalSidebarData: [
        'Click run to launch the automation.',
        {
          text: 'Select the schedule recurrence (frequency)',
          subtext: ['Green - Running', 'Orange - Paused', 'Red - Stopped']
        },
        'Click on any automation to reveal its logs.'
      ]
    }
  }
}
</script>

<style></style>
