<template>
  <div
    class="h-full overflow-x-hidden bg-white font-sans text-gray-900 antialiased dark:bg-gray-900 dark:text-gray-50"
  >
    <!-- START WRAPPER -->
    <div class="flex min-h-screen items-stretch justify-between">
      <!-- START LEFT COLUMN -->
      <OnboardingLeftColumn />
      <!-- END LEFT COLUMN -->

      <!-- START RIGHT COLUMN -->
      <div class="flex-1 bg-white dark:bg-gray-900">
        <div class="grid h-full items-start justify-center">
          <div class="h-full px-6 py-16 sm:px-12 sm:py-20 lg:px-20">
            <div class="flex h-full max-w-md flex-col">
              <a href="#" title="TexAu" class="">
                <img
                  class="mx-auto h-12 w-auto xl:h-14"
                  src="@/assets/images/logo-alt.png"
                  alt="TexAu logo"
                />
              </a>

              <div class="mt-12 grid h-full place-items-center">
                <div>
                  <img
                    class="mx-auto w-full max-w-sm"
                    src="@/assets/images/onboarding/illustrations/install-extension.svg"
                    alt=""
                  />

                  <div class="mt-12 text-center">
                    <Heading text="Let's start adding your accounts" />
                    <p class="mt-4 text-base text-gray-600 dark:text-gray-400">
                      To start using TexAu, install our Chrome Extension. We
                      will open a new tab and import all your accounts. If you
                      are not logged in to any social media accounts, we highly
                      recommend you to log in before doing this step.
                    </p>

                    <div class="mt-8">
                      <Button
                        :show-loader="isLoading"
                        @click="onInstallClick"
                        text="Install TexAu Extension"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END RIGHT COLUMN -->
    </div>
    <!-- END WRAPPER -->
  </div>
</template>

<script>
import OnboardingLeftColumn from '@/components/onboarding/OnboardingLeftColumn.vue'
import Heading from '@/components/onboarding/Heading.vue'
import Button from '@/components/Button.vue'
import { updateUserDetail } from '@/apis/user'
import UserProfile from '@/common/userWrapper'

export default {
  // step-3
  name: 'installExtension',
  components: {
    OnboardingLeftColumn,
    Heading,
    Button
  },

  data() {
    return {
      isLoading: false
    }
  },

  methods: {
    async updateNextStep() {
      const responseUser = await updateUserDetail({
        currentOnboardingStep: 'Trigger Extension'
      })
      UserProfile.updateOnboardingStateInCookies('Trigger Extension')
      this.isLoading = false
      if (responseUser['success']) {
        await this.$router.push('/onboarding/start-import')
      } else {
        this.$emit('error', 'Error Occurred!')
      }
    },

    async onInstallClick() {
      this.isLoading = true
      // TODO: redirect to chrome extension store
      //TODO: open chrome extension store link in new tab
      var newTab = window.open(
        'https://chromewebstore.google.com/detail/texau-v2/hbhdepogdnphlloancjiifodpchlkeam',
        '_blank'
      )
      newTab.focus()
      await this.updateNextStep()
    }
  }
}
</script>

<style></style>
