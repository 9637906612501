const formatDate = d => {
  const newDate = new Date(Date.parse(d))

  const curr_date = newDate.getDate()

  const curr_month = newDate.toLocaleString('en-US', { month: 'short' })

  const curr_yr = newDate.getFullYear()
  return `${curr_date} ${curr_month} ${curr_yr}`
}
const formatTime = d => {
  const newDate = new Date(Date.parse(d))
  let startTime = new Date(newDate.getTime())
  return startTime.toLocaleTimeString()
}

const formatExecutionTime = ms => {
  const seconds = Math.floor(ms / 1000)
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - hours * 3600) / 60)
  const remainingSeconds = seconds - hours * 3600 - minutes * 60
  return `${hours}hr ${minutes}min ${remainingSeconds}sec`
}

export { formatDate, formatTime, formatExecutionTime }
