import { mergeAttributes, Node } from '@tiptap/core'

export const Tag = Node.create({
  name: 'tag',
  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ node }) {
        return `${node.attrs.name}`
      }
    }
  },
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,
  addAttributes() {
    return {
      name: {
        default: null,
        parseHTML: element => element.getAttribute('data-name'),
        renderHTML: attributes => {
          if (!attributes.nodeId) {
            return {}
          }

          return {
            'data-name': attributes.name
          }
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`
      }
    ]
  },
  renderText({ node }) {
    return `[[${node.attrs.name}]]`
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        {
          'data-type': this.name,
          class:
            'inline rounded bg-indigo-100 px-1 text-sm font-semibold text-indigo-600 dark:bg-indigo-900 dark:text-indigo-50'
        },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      this.options.renderLabel({
        options: this.options,
        node
      })
    ]
  }
})
