import confirmPhoneOtp from '@/views/onboarding/confirmPhoneOtp.vue'
import ShowConfirmMessage from '@/views/onboarding/ShowConfirmMessage.vue'
import Login from '@/views/onboarding/login.vue'
import Onboarding from '@/views/onboarding/onboarding.vue'
import ForgotPassword from '@/views/onboarding/ForgotPassword.vue'
import magicLinkIntegration from '@/components/settings/integration/magicLinkIntegration.vue'

export const PublicRoutes = [
  {
    path: '/register',
    name: 'Register',
    component: Onboarding
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: ForgotPassword
  },
  {
    path: '/onboarding/show-confirm-message',
    name: 'Show Confirm Message',
    component: ShowConfirmMessage
  },
  {
    path: '/onboarding/confirm-phone-otp',
    name: 'Confirm phone OTP',
    component: confirmPhoneOtp
  },

  {
    path: '/new-account',
    name: 'Magic Link Integration',
    component: magicLinkIntegration
  },

  { path: '/:pathMatch(.*)*', name: 'Login', component: Login }
]
