<template>
  <Button text="error" @click="myUndefinedFunction()" />
  <div class="dark:bg-gray-900">
    <Select
      label="test"
      :leftIcon="'search'"
      :options="[
        { label: 'key1', value: 'value1' },
        { label: 'key2', value: 'value2' },
        { label: 'key3', value: 'value3' },
        { label: 'key4', value: 'value4' },
        { label: 'key5', value: 'value5' },
        { label: 'key6', value: 'value6' },
        { label: 'key7', value: 'value7' },
        { label: 'key8', value: 'value8' },
        { label: 'key9', value: 'value9' },
        { label: 'key10', value: 'value10' },
        { label: 'key11', value: 'value11' }
      ]"
      :include-search="true"
      v-model="selectValue"
      captionText="error"
    />
    <h1 class="text-gray-500">Components Testing</h1>
    <Email />

    <br />
    <Input isWorkflowInput label="workflow input" v-model="workflowInput" />
    <br />
    <Button text="Show Toast" @click="onToastClick" />

    <br />
    <ToastContainer class="w-1/5" ref="toast" />
    <br />
    <Button
      rightIcon="right-arrow"
      size="small"
      text="button"
      type="dangerFilled"
    />
    <br />
    <Collapse
      :heading="'this is heading'"
      :subheading="'this is subheading'"
      :body="'this is body sa dasd sajkd hajsgd hajsgd hagsdk gask dgaksgd aksgd asd asgdj aksjd asashdg ashdg ashkjdg ahsjg dkjashg djkas kdgasg dgahs dgkashd gsa dghasg kdaksd kgashd gsaljkf asf sad asd asd a'"
      :defaultExpanded="true"
    />
    <br />
    <Note title="Note" text="some text" contrast="success" />
    <br />
    <Card />
    <br />
    <Input v-bind:error="true" v-bind:rIcon="true" />
    <br />

    <br />
    <Tabs :tabs="tabs" />
    <br />
    <Checkboxes />
    <br />
    <Radio />
    <br />
    <StatusDot color="error" label="hey I'm online" />
    <br />
    <Spinner />
    <br />
    <TextArea default-text="hey its text area" type="normal" />
    <br />
    <Description text="hey its description" tooltip />
    <br />
    <Badge color="primary" text="Large badge" hasRemoveButton />
    <br />
    <Breadcrumbs
      :items="[
        {
          text: 'Home',
          href: '/',
          active: true
        },
        {
          text: 'About',
          href: '/about',
          active: true
        },
        {
          text: 'Components',
          href: '/components',
          active: false
        },
        {
          text: 'Contact',
          href: '/contact',
          active: false
        }
      ]"
    />
    <br />
    <Avatar
      :srcAndStatus="[
        {
          src: 'https://source.unsplash.com/user/c_v_r',
          status: true,
          initials: ''
        },
        {
          src: '',
          status: true,
          initials: ''
        },
        {
          src: 'https://source.unsplash.com/',
          status: false,
          initials: ''
        },
        {
          src: '',
          status: true,
          initials: 'AB'
        }
      ]"
      appearance="circle"
      size="large"
    />

    <Pagination
      :count="5"
      :page="currentpage"
      @pagechanged="onPageChange"
      :type="'select'"
      onlyIcon
    />
    <Modal
      :title="'Heading?'"
      :text="'Some text'"
      :show="show"
      :onCancel="onCancel"
      :onClose="onClose"
      :onOutsideClick="onOutsideClick"
      :onSubmit="onSubmit"
    />
    <Button @click="show = !show" :text="'Show Modal'" />

    <br />
    <Fieldset
      buttonText="Click me !"
      heading="heading"
      content="content"
      note="note"
      disabled="footer"
    />
    <br />
    <Entity
      entityType="form"
      placeholder="its my placeholder"
      caption="its my caption"
    />
    <br />
  </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
import Badge from '@/components/Badge.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Button from '@/components/Button.vue'
import Card from '@/components/Card.vue'
import Checkboxes from '@/components/Checkboxes.vue'
import Collapse from '@/components/Collapse.vue'
import Description from '@/components/Description.vue'
import Entity from '@/components/Entity.vue'
import Fieldset from '@/components/Fieldset.vue'
import Input from '@/components/Input.vue'
import Modal from '@/components/Modal.vue'
import Note from '@/components/Note.vue'
import Pagination from '@/components/Pagination.vue'
import Radio from '@/components/Radio.vue'
import Select from '@/components/Select.vue'
import Spinner from '@/components/Spinner.vue'
import StatusDot from '@/components/StatusDot.vue'
import Tabs from '@/components/Tabs.vue'
import TextArea from '@/components/TextArea.vue'
import ToastContainer from '@/components/ToastContainer.vue'
import TextInput from '@/components/workflowBuilder/customInput/WorkflowInput.vue'
import Email from '@/components/Email.vue'

export default {
  name: 'Components',
  components: {
    StatusDot,
    Button,
    Note,
    Card,
    Input,
    Select,
    Checkboxes,
    Radio,
    Spinner,
    Breadcrumbs,
    Description,
    TextArea,
    Tabs,
    Badge,
    Pagination,
    Modal,
    Fieldset,
    Avatar,
    Collapse,
    Entity,
    ToastContainer,
    TextInput,
    Email
  },

  data: function () {
    return {
      role: ['ankur', 'mehak'],
      tabs: [
        {
          text: 'Placeholder'
        },
        {
          text: 'Placeholder',
          counter: 1
        },
        {
          text: 'Placeholder',
          counter: 12
        },
        {
          text: 'Placeholder',
          counter: 10
        }
      ],
      mode: 'dark',
      currentpage: 4,
      show: false,
      workflowInput:
        '<span data-type="variable" data-output-name="test2" data-node-id="test2"></span>',
      selectValue: ''
    }
  },
  methods: {
    onPageChange: function (page) {
      this.currentpage = page
    },
    onClose: function () {
      this.show = false
    },
    onSubmit: function () {
      this.show = false
    },
    onCancel: function () {
      this.show = false
    },
    onOutsideClick: function () {
      this.show = false
    },
    onToastClick() {
      this.$refs.toast.addToast({
        timeout: 7000,
        text: 'error',
        color: 'error',
        caption: 'positive message',
        action: true,
        close: true
      })
    }
  }
}
</script>

<style></style>
