<!-- Checkboxes Component -->
<!-- For checked pass 'checked', for disabled pass 'disabled', for alert pass 'alert', as attribute -->

<template>
  <div>
    <div>
      <div
        :class="[
          {
            'flex items-start space-x-3': isLabelAndCaption,
            'items-centre flex space-x-3': isLabel,
            ' flex-col-reverse !space-x-0 gap-[0.375rem]': description
          }
        ]"
        @click="updateChecked"
      >
        <p v-if="description" class="mt-[23px] text-sm text-gray-500">
          {{ description }}<slot></slot>
        </p>
        <input
          type="checkbox"
          class="ml-1 h-5 w-5 cursor-pointer rounded-md transition-all duration-200"
          :class="[
            {
              'border-gray-300 text-blue-600 focus:ring-blue-600 enabled:hover:border-gray-400 disabled:text-gray-400 dark:border-gray-600 dark:bg-gray-900 dark:text-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900 enabled:dark:hover:border-gray-500':
                standard || grey,
              'border-red-300 text-red-600 focus:ring-red-600 dark:border-red-400 dark:bg-gray-900 dark:text-red-500 dark:focus:ring-red-500 dark:focus:ring-offset-gray-900':
                alert,
              '!ml-0': description
            }
          ]"
          :checked="modelValue"
          :disabled="disabled"
        />

        <label
          v-if="isLabel"
          class="flex text-sm font-medium text-gray-900 dark:text-gray-50 cursor-pointer"
          :class="labelClass"
        >
          {{ label }}
          {{ isRequired === false ? '(Optional)' : null }}
          <SvgIcon
            class="mb-3 ml-1 h-2 w-2 text-red-600"
            name="star"
            v-if="isRequired"
          />
        </label>

        <div v-if="isLabelAndCaption">
          <label
            class="cursor-pointer"
            :class="[
              {
                'block text-sm font-semibold text-gray-900 dark:text-gray-50':
                  standard,
                'block text-sm font-medium text-gray-900 text-opacity-50 dark:text-gray-50 dark:text-opacity-50':
                  disabled
              }
            ]"
          >
            {{ label }}
          </label>
          <span
            class="mt-0.5 text-xs"
            :class="[
              {
                'text-gray-600 dark:text-gray-400': !alert,
                'text-red-600 dark:text-red-400': alert,
                'text-gray-600 text-opacity-50 dark:text-gray-400 dark:text-opacity-50':
                  disabled
              }
            ]"
          >
            {{ captionText }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popper from 'vue3-popper'
import SvgIcon from './SvgIcon.vue'
export default {
  name: 'Checkboxes',
  props: {
    modelValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    alert: { type: Boolean, default: false },
    label: { type: String, default: '' },
    captionText: { type: String, default: '' },
    isRequired: { type: Boolean, default: undefined },
    description: String
  },
  emits: ['update:modelValue', 'blur'],
  computed: {
    standard() {
      return !this.disabled
    },
    grey() {
      return this.disabled
    },
    isLabel() {
      if (this.label !== '' && this.captionText === '') {
        return true
      }
    },
    isLabelAndCaption() {
      if (this.label !== '' && this.captionText !== '') {
        return true
      }
    }
  },
  methods: {
    updateChecked() {
      if (this.disabled) return
      this.$emit('update:modelValue', !this.modelValue)
      this.$emit('blur', !this.modelValue)
    }
  },
  components: { SvgIcon, Popper }
}
</script>

<style></style>
