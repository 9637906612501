<template>
  <div
      class="overflow-hidden bg-white border border-gray-200 rounded-lg dark:bg-gray-900 dark:border-gray-600">
    <div class="p-4">
      <div
          class="inline-flex items-center justify-center w-10 h-10 text-blue-600 rounded-md bg-blue-50 dark:text-blue-400 dark:bg-blue-900/50">
        <SvgIcon :name="iconName" :class="svgClass"/>
      </div>
      <p class="mt-4 text-2xl font-bold text-gray-900 dark:text-gray-50">
        {{ count}}
      </p>
      <p class="mt-1 text-xs font-semibold text-gray-600 dark:text-gray-400">
        {{ operationName }}
      </p>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";

export default {
  name: "infoBox",
  props: {
    iconName: {type: String, default:''},
    count: {type: String, default:'0'},
    operationName: {
      type: String, default:''
    },
    svgClass: {
      type: String, default:''
    }
  },
  components: {
    SvgIcon,
  }
}
</script>

<style scoped>

</style>
