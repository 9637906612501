<template>
  <div
      class="flex transform flex-col rounded-lg border border-transparent transition-all duration-200 hover:-translate-y-1 hover:shadow-lg"
      :class="platformClass"
  >
    <div class="flex-1 px-4 py-5 sm:p-6">
      <div class="flex -space-x-2 overflow-hidden">
        <img
            class="inline-block h-10 w-10 rounded-full"
            alt=""
            v-for="logo in logos"
            :key="logo"
            :src="logo"
        />
      </div>

      <p class="mt-5 text-base font-semibold text-gray-900 dark:text-gray-50">
        {{ name }}
      </p>
    </div>

    <div class="mt-auto px-4 pb-5 sm:px-6">
      <Button text="Try it!" color="secondary" @click="onClick"/>
    </div>
  </div>
</template>

<script>
import Button from '../Button.vue'
import {platformClasses} from '../../validators/platform'

export default {
  name: 'WorkflowCard',
  components: {
    Button
  },
  props: {
    name: {
      type: String,
      required: true
    },
    logos: {
      type: Array,
      required: true
    },
    platform: {
      type: String,
      required: true
    },
    PlatformId: {
      type: Object,
      required: true,
    },
    platformOperationId: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClick() {
      this.$emit('click', this.workflow)
    }
  },
  computed: {
    platformClass() {
      return platformClasses[this.platform]
    }
  }
}
</script>
