<template>
  <AutomationHeader title="Security" />
  <section class="flex flex-1 border-t border-gray-200 dark:border-gray-700">
    <SettingsMenu active-option="security" />
    <TwoFactorAuthenticationSection />
  </section>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import TwoFactorAuthenticationSection from '@/components/settings/twoFactorAuthenticationSection.vue'

export default {
  name: 'twoFactorAuthentication',
  components: {
    AutomationHeader,
    SettingsMenu,
    TwoFactorAuthenticationSection
  }
}
</script>

<style scoped></style>
