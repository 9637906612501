<template>
  <AutomationHeader title="Affiliate" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <SettingsMenu active-option="Partners Program" />
    <div
      class="relative h-full flex-1 border-l border-gray-200 antialiased dark:border-gray-700"
      v-if="true"
    >
      <div class="flex h-full items-center justify-center">
        <h2 class="font-semibold text-blue-500 text-2xl">Coming Soon...</h2>
      </div>
    </div>

    <div v-else>
      <div
        class="relative flex-1 border-l border-gray-200 antialiased dark:border-gray-700"
        v-if="loading"
      >
        <div class="px-4 py-5 sm:p-5">
          <div class="mx-auto max-w-2xl">
            <Skeleton height="40px" width="672px" borderRadius="8px"></Skeleton>
          </div>
          <div class="mt-4 h-full w-full overflow-auto" ref="tableBody">
            <table class="min-w-full">
              <thead class="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                    :class="[
                      {
                        ' rounded-l-lg': index === 0
                      },
                      {
                        ' rounded-r-lg': index === 7
                      }
                    ]"
                    v-for="(col, index) of columns"
                  >
                    <span> {{ col }} </span>
                  </th>
                </tr>
              </thead>
              <tbody
                class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
              >
                <tr v-for="ele in 4" :key="ele">
                  <td
                    v-for="ele in 8"
                    :key="ele"
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
                  >
                    <Skeleton
                      height="40px"
                      width="100%"
                      borderRadius="8px"
                    ></Skeleton>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        v-else
        class="relative flex-1 border-l border-gray-200 antialiased dark:border-gray-700"
      >
        <div class="px-4 py-5 sm:p-5">
          <div v-if="!linkCreated">
            <div class="mx-auto max-w-2xl">
              <p>
                Get paid for your influence. Earn 20% lifetime recurring
                commission for every month for every referral, with no
                expiration. As long as the customer pays, you get paid. Join our
                Partners Program, click below to activate.
              </p>
              <div class="flex items-center justify-center">
                <Button
                  @click="getLink()"
                  class="mt-8"
                  :showLoader="linkLoader"
                  text="Click to Get Your Referal Link "
                />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="mx-auto max-w-2xl">
              <Input
                v-model="participantData.shareUrl"
                :readonly="true"
                right-icon="copy"
                right-icon-mouse="cursor-pointer"
                @onRightIconClick="copy()"
              />
              <div
                class="mt-4 overflow-hidden rounded-lg bg-green-100 dark:bg-green-900/50"
              >
                <div class="px-4 py-3" v-show="copied">
                  <div class="flex items-center">
                    <SvgIcon name="check" />
                    <span
                      class="ml-3 text-sm font-medium text-green-700 dark:text-green-300"
                    >
                      Unique Link has been copied to the clipboard!
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 h-full w-full overflow-auto" ref="tableBody">
              <table class="min-w-full">
                <thead class="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                      :class="[
                        {
                          ' rounded-l-lg': index === 0
                        },
                        {
                          ' rounded-r-lg': index === 7
                        }
                      ]"
                      v-for="(col, index) of columns"
                    >
                      <span> {{ col }} </span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
                >
                  <tr>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
                    >
                      {{ participantData.rank }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
                    >
                      {{ participantData.email }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
                    >
                      {{ participantData.firstName }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
                    >
                      {{ participantData.lastName }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
                    >
                      {{ participantData.monthlyReferralCount }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
                    >
                      {{ participantData.inviteCount }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
                    >
                      {{ participantData.impressionCount }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
                    >
                      {{ participantData.uniqueImpressionCount }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import SettingsMenu from '@/components/settings/settingsMenu.vue'
import { addParticipant, getParticipantByEmail } from '@/apis/affiliate'
import { mapState } from 'vuex'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Input from '@/components/Input.vue'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'AffiliateMain',
  components: {
    AutomationHeader,
    SettingsMenu,
    Button,
    SvgIcon,
    Input,
    Spinner
  },
  data() {
    return {
      grsfReady: false,
      linkLoader: false,
      linkCreated: false,
      participantData: null,
      columns: [
        'Rank',
        'Email',
        'First Name',
        'Last Name',
        'Referrals',
        'Invites',
        'Impressions',
        'Unique Impressions'
      ],
      copied: false,
      loading: false
    }
  },
  mounted() {
    // Check to see if GrowSurf is available
    if (!window.growsurf) {
      // Listen and wait for the Growsurf Universal Code to initialize
      window.addEventListener('grsfReady', () => {
        this.handleGrowSurfReady()
      })
    } else {
      this.grsfReady = true
    }

    this.getParticipant()
  },
  computed: {
    ...mapState('user', ['userData'])
  },
  methods: {
    onAddTagClick() {
      this.addNewTagClicked({ payload: true })
    },
    handleGrowSurfReady() {
      window.removeEventListener('grsfReady', handleGrowSurfReady)

      this.grsfReady = true
    },
    openGrowsurfSidebar() {
      growsurf.open()
    },

    async getParticipant() {
      try {
        this.loading = true
        const response = await getParticipantByEmail(this.userData.email)
        if (response.status === 200) {
          this.participantData = response.data
          this.linkCreated = true
        }
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
    },
    async getLink() {
      try {
        this.linkLoader = true
        const data = {
          email: this.userData.email,
          firstName: this.userData.firstname,
          lastName: this.userData.lastname
        }
        const response = await addParticipant(data)
        if (response.status === 200) {
          this.participantData = response.data
          this.linkCreated = true
        } else {
          throw `Something Went Wrong Please Try Again Later`
        }
      } catch (error) {
        this.$emit('error', error)
      } finally {
        this.linkLoader = false
      }
    },
    async copy() {
      try {
        await navigator.clipboard.writeText(this.participantData.shareUrl)
        this.copied = true
        setTimeout(this.removeCopiedContainer, 2000)
      } catch (error) {
        this.$emit('error', error)
      }
    },
    removeCopiedContainer() {
      this.copied = false
    }
  }
}
</script>

<style scoped></style>
