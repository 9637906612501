import Axios from 'axios'
import { constants } from './constants'
import UserProfile from './userWrapper'

let isRefreshing = false
let refreshSubscribers = []

const axios = Axios.create({
  baseURL: `${constants.BACKEND_API_BASE_URL}/api/${constants.BACKEND_API_VERSION}`
})

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (!UserProfile.getUser()) {
      return config
    }
    config.headers.Authorization = `Bearer ${
      UserProfile.getUser()['AccessToken']
    }`

    const orgUserId = window.$cookies.get('orgUserId')
    const workspaceId = window.$cookies.get('workspaceId')
    const context = {}
    if (orgUserId) {
      context.orgUserId = orgUserId
    }
    if (workspaceId) {
      context.workspaceId = workspaceId
    }

    if (config.headers['X-TexAu-Context']) {
      return config
    }
    config.headers['X-TexAu-Context'] = JSON.stringify(context)

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const { config, response } = error
    const originalRequest = config

    let user_ = UserProfile.getUser()
    if (response && response.status === 401 && user_) {
      if (!isRefreshing) {
        isRefreshing = true
        axios
          .post(`auth/refresh-token`, {
            refreshToken: user_['RefreshToken']
          })
          .then(({ data }) => {
            user_['AccessToken'] = data.data['AccessToken']
            UserProfile.setUser(user_)
            isRefreshing = false
            onRefreshed(data['AccessToken'])
          })
          .catch(error => {
            UserProfile.clearCookie()
            window.location.reload()
          })
      }

      return new Promise((resolve, reject) => {
        subscribeTokenRefresh(token => {
          // replace the expired token and retry
          originalRequest.headers['Authorization'] = 'Bearer ' + token
          resolve(axios(originalRequest))
        })
      })
    } else {
      return Promise.reject(error)
    }
  }
)

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb)
}

function onRefreshed(token) {
  refreshSubscribers.map(cb => cb(token))
  refreshSubscribers = []
}

export default axios
