import axios from '@/common/axios'

const getAllActivities = async (start = 0, limit = 5) => {
  try {
    const response = await axios.get(`/activities`, {
      params: {
        start,
        limit
      }
    })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const removeActivities = async data => {
  try {
    const response = await axios.put(`/activities`, data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export { getAllActivities, removeActivities }
