<template>
  <div class="mt-4">
    <div class="rounded-lg bg-blue-50 dark:bg-blue-900/50">
      <div class="p-4">
        <div class="flex items-start">
          <SvgIcon
            name="important"
            class="h-5 w-5 shrink-0 text-blue-500 dark:text-blue-400"
          />
          <p
            class="ml-3 flex-1 text-sm font-medium text-gray-900 dark:text-gray-50"
          >
            Add your proxies in this format: IP:PORT:LOGIN:PASS or
            DOMAIN:PORT:LOGIN:PASS one proxy per line.
          </p>
        </div>
      </div>
    </div>

    <div
      class="mt-5 flex justify-center rounded-lg border-2 border-dashed border-blue-300 bg-blue-50 px-6 py-16 dark:border-blue-700 dark:bg-blue-900/50"
    >
      <div class="space-y-1 text-center">
        <div>
          <label
            for="file-upload"
            class="relative inline-flex cursor-pointer items-center justify-center rounded-lg border border-transparent bg-blue-600 px-4 py-2.5 text-base font-semibold text-white transition-all duration-200 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900 sm:text-sm"
          >
            <SvgIcon class="h-5 w-5" name="upload" />
            <span> Upload a file </span>
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              class="sr-only"
            />
          </label>
          <p class="mt-3 text-base font-semibold text-blue-600 sm:text-sm">
            or drag and drop
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
export default {
  name: 'UploadFromFile',
  components: { SvgIcon }
}
</script>

<style></style>
