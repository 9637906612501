<template>
  <div class="antialiased">
    <p class="text-xl font-bold text-gray-900 dark:text-gray-50">
      Select Working Hours
    </p>

    <div class="mt-6 space-y-3">
      <div class="grid items-end grid-cols-5 gap-x-6">
        <div class="col-span-2">
            <Select text="08:00 AM" options="" label="Between"/>
        </div>

        <div class="col-span-2">
            <Select text="08:00 AM" options="" label="to"/>
        </div>

        <div class="py-1.5">
          <button type="button"
                  class="inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out bg-gray-400 border-2 border-transparent rounded-full cursor-pointer dark:bg-blue-500 elative w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
                  role="switch" aria-checked="false" :aria-checked="firstOn.toString()"
                  @click="firstOn = !firstOn"
                  :class="{ 'bg-blue-600 dark:bg-blue-500': firstOn, 'bg-gray-400 dark:bg-gray-500': !(firstOn) }">
                          <span aria-hidden="true"
                                class="inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full shadow pointer-events-none ring-0"
                                :class="{ 'translate-x-5': firstOn, 'translate-x-0': !(firstOn) }"></span>
          </button>
        </div>
      </div>

      <div class="grid items-end grid-cols-5 gap-x-6">
        <div class="col-span-2">
            <Select text="08:00 AM" options="" label="Between"/>
        </div>

        <div class="col-span-2">
            <Select text="08:00 AM" options="" label="to"/>
        </div>

        <div class="py-1.5">
          <button type="button"
                  class="inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out bg-gray-400 border-2 border-transparent rounded-full cursor-pointer dark:bg-blue-500 elative w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
                  role="switch" aria-checked="false" :aria-checked="on.toString()"
                  @click="secondOn = !secondOn"
                  :class="{ 'bg-blue-600 dark:bg-blue-500': secondOn, 'bg-gray-400 dark:bg-gray-500': !(secondOn) }">
                          <span aria-hidden="true"
                                class="inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full shadow pointer-events-none ring-0"
                                :class="{ 'translate-x-5': secondOn, 'translate-x-0': !(secondOn) }"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from '@/components/Select.vue';

export default {
  name: "workingHours",
  components: {
    Select,
  },
  data() {
    return {
      firstOn: true,
      secondOn: true,
    }
  }
}
</script>

<style scoped>

</style>
