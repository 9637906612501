import axios from '@/common/axios'

const getAllIntegrations = async (start = 0, limit = 50) => {
  try {
    const response = await axios.get(`platforms?start=${start}&limit=${limit}`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const updateNotificationPreferences = async (workspaceId, data) => {
  try {
    const response = await axios.put(
      `workspaces/${workspaceId}/notifications`,
      data
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getNotificationPreferences = async workspaceId => {
  try {
    const response = await axios.get(`workspaces/${workspaceId}/notifications`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getAuthVariables = async id => {
  try {
    const response = await axios.get(`platforms/${id}/auth-variables`)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const postAccountIntegration = async data => {
  try {
    const response = await axios.post(`/accounts`, data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const updateAccountIntegration = async (data, accountId) => {
  try {
    const response = await axios.put(`/accounts/${accountId}`, data)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export {
  getAllIntegrations,
  updateNotificationPreferences,
  getNotificationPreferences,
  getAuthVariables,
  postAccountIntegration,
  updateAccountIntegration
}
