<template>
  <div>
    <div v-if="label">
      <label :class="labelClass">{{ label }}</label>
    </div>
    <div class="">
      <label
        class="relative mt-1 inline-flex cursor-pointer items-center justify-center rounded-lg border border-transparent bg-blue-600 px-4 py-2.5 text-base font-semibold text-white transition-all duration-200 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:hover:bg-blue-700 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900 sm:text-sm"
      >
        <SvgIcon class="h-5 w-5" :name="false ? 'spinnerIcon' : 'upload'" />
        <span> Browse files </span>

        <input
          id="file-upload"
          name="file-upload"
          type="file"
          class="sr-only"
          ref="fileUploadInput"
          v-on:change="handleFileUpload()"
        />
      </label>
      <p v-if="description" class="text-sm mt-2 text-gray-500">
        {{ description }}<slot></slot>
      </p>

      <!-- File Preview -->
      <div
        v-if="previewUrl"
        class="flex h-fit mt-1 w-full items-center overflow-hidden rounded-lg border border-gray-300 px-4 py-2"
      >
        <figure class="flex flex-1">
          <div class="flex h-[40px] w-[40px] items-center justify-center">
            <img
              :src="previewUrl"
              alt=""
              srcset=""
              class="overflow-clip"
              style="overflow-clip-margin: content-box"
            />
          </div>
          <figcaption
            v-if="uploadingStatus"
            class="flex-1 overflow-hidden px-2"
          >
            <div class="flex h-full items-center justify-center text-blue-600">
              <SvgIcon name="spinnerIcon" />
            </div>
          </figcaption>
          <figcaption v-else class="flex-1 overflow-hidden px-2">
            <div class="flex">
              <h3 class="truncate text-sm">
                {{ fileName }}
              </h3>
              <span
                class="text-sm text-gray-500 before:px-1 before:content-['•']"
              >
                {{ getFileSizeInMb(fileSize) }} MB
              </span>
            </div>

            <p class="text-xs text-gray-500">Attached</p>
          </figcaption>
        </figure>
        <button @click="deletePreview()">
          <SvgIcon name="close" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { deleteInputFile, uploadInputFile } from '@/apis/upload'
import { ref, watch, computed } from 'vue'
import SvgIcon from './SvgIcon.vue'

const dropZoneRef = ref(null)
const componentValue = ref({ file: null })
const file = ref(null)
const loading = ref(null)
const previewUrl = ref(null)
const uploadingStatus = ref(true)
const fileId = ref(null)
const fileName = ref(null)
const fileSize = ref(null)
const fileUploadInput = ref(null)

const props = defineProps({
  modelValue: {},
  label: String,
  labelClass: String,
  type: String,
  placeholder: { type: String, default: 'write something...' },
  readonly: Boolean,
  disabled: Boolean,
  previousNodes: Array,
  isRequired: { type: Boolean, default: undefined },
  modalClass: String,
  isLabelEditable: Boolean,
  attachmentSpecifications: Object,
  personalisedTags: Array,
  description: String
})

const fileTypeToPreviewURL = {
  pdf: 'https://upload.wikimedia.org/wikipedia/commons/6/6c/PDF_icon.svg',
  csv: 'https://upload.wikimedia.org/wikipedia/commons/c/c6/.csv_icon.svg'
}

watch(
  () => props.modelValue,
  () => {
    // if file is present
    if (componentValue.value.file) {
      setPreviewURL(componentValue.value.file)
      fileId.value = componentValue.value.file.fileId
      fileName.value = componentValue.value.file.originalName
      fileSize.value = componentValue.value.file.fileSize
      uploadingStatus.value = false
    }
  },
  { immediate: true }
)

function setPreviewURL(file) {
  const type = file.originalName?.split('.').pop()
  if (type in fileTypeToPreviewURL) {
    previewUrl.value = fileTypeToPreviewURL[type]
  } else {
    //create image file preview
    previewUrl.value = file.fileUrl
  }
}

function handleFileUpload() {
  // called when files are dropped on zone

  let unsupportedFile = true
  file.value = fileUploadInput.value.files[0]

  //check if file extenstion is matches the attachment specification
  for (let type of props.attachmentSpecifications.supportedFormats) {
    if (file.value.type.split('/').pop() === type) {
      unsupportedFile = false
      break
    }
  }

  // if file is not supported
  if (unsupportedFile) {
    componentValue.value.file = 'unsupportedFile'
    emit('update:modelValue', componentValue.value)
    return
  }

  //file size check
  if (file.value.size > props.attachmentSpecifications.maxFileSize) {
    componentValue.value.file = 'size limit exceed'
    emit('update:modelValue', componentValue.value)
    return
  }

  if (file.value.type.split('/')[0] === 'image') {
    //create image file preview
    const reader = new FileReader()
    reader.onload = e => {
      previewUrl.value = e.target.result
    }
    reader.readAsDataURL(file.value)
  } else if (file.value.type.split('/').pop() in fileTypeToPreviewURL) {
    previewUrl.value = fileTypeToPreviewURL[file.value.type.split('/').pop()]
  }

  //upload the file to s3
  uploadFile()
}

async function uploadFile() {
  try {
    const formData = new FormData()
    formData.append('filename', file.value)
    uploadingStatus.value = true
    const response = await uploadInputFile(formData)
    if (response['success']) {
      fileId.value = response.data.fileId

      const fileUrl = response.data.fileUrl

      const txFileDetails = {
        fileId: fileId.value,
        originalName: file.value.name,
        fileSize: file.value.size,
        fileName: fileUrl.split('/').pop(),
        fileUrl,
        key: fileUrl.split('.com/')[1],
        bucket: fileUrl.split('/')[2].split('.')[0],
        region: fileUrl.split('/')[2].split('.')[2]
      }
      componentValue.value.file = txFileDetails
      emit('update:modelValue', componentValue.value)
      emit('blur')
    } else {
      throw error
    }
  } catch (error) {
    componentValue.value.file = 'api error'
    emit('update:modelValue', componentValue.value)
  } finally {
    uploadingStatus.value = false
  }
}

function getFileSizeInMb(fileSize) {
  return (fileSize / (1024 * 1024)).toFixed(2)
}

watch(file, () => {
  if (file.value) {
    fileSize.value = getFileSizeInMb(file.value.size)
    fileName.value = file.value.name
  }
})

async function deletePreview() {
  uploadingStatus.value = true
  await deleteInputFile(fileId.value)
  file.value = null
  previewUrl.value = null
  componentValue.value.file = null
  uploadingStatus.value = false
  emit('update:modelValue', componentValue.value)
  emit('blur')
}
const emit = defineEmits([
  'update:modelValue',
  'label-update',
  'blur',
  'toggleOpenAiAccounts'
])
</script>
