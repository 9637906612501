<template>
  <main class="flex h-full min-w-0 flex-1 flex-col overflow-y-auto">
    <AutomationHeader :nav="false" title="Notifications" />
    <section class="border-t-2">
      <div class="mt-4 flex items-center justify-center">
        <div v-if="loading" class="h-full">
          <Spinner size="large" />
        </div>
        <p v-if="noNotifications" class="px-4 py-3 text-center italic">
          No new notifications
        </p>
        <div v-if="!loading && !noNotifications" class="rounded-lg border-2">
          
          <div
            @click="
              redirectToResults(
                notification.workflowExecutionId,
                notification.workflowId,
                notification.activityId,
                notification.notificationType
              )
            "
            v-for="notification of notifications"
            :key="notification.activityId"
            class="flex w-[70vh] items-center justify-between space-x-8 whitespace-nowrap px-4 py-3 text-base font-medium text-gray-700 transition-all duration-200 hover:bg-gray-100 dark:hover:bg-gray-800 sm:text-sm"
            :class="[
              {
                'cursor-pointer': notification.notificationType === 'completed'
              }
            ]"
            role="menuitem"
            tabindex="-1"
          >
            <div class="min-w-0">
              <p
                class="truncate text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                {{ notification.message }}
              </p>
              <p
                class="mt-0.5 text-xs font-normal text-gray-600 dark:text-gray-400"
              >
                {{
                  `${formatDate(notification.createdAt)} ${formatTime(
                    notification.createdAt
                  )}`
                }}
              </p>
            </div>

            <span
              class="inline-flex items-center rounded-lg px-3 py-1.5 text-sm font-semibold capitalize"
              :class="[
                {
                  'bg-green-100 text-green-600 dark:bg-green-900 dark:text-green-50':
                    completed(notification.notificationType),
                  'bg-red-100 text-red-600 dark:bg-red-900 dark:text-red-50':
                    failed(notification.notificationType),
                  'bg-yellow-100 text-yellow-500 dark:bg-yellow-700 dark:text-yellow-50':
                    update(notification.notificationType),
                  'bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-50':
                    paused(notification.notificationType)
                }
              ]"
            >
              {{ notification.notificationType }}
            </span>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { notificationTypes } from '@/common/constants'
import { getAllActivities, removeActivities } from '@/apis/activities'
import { mapActions, mapState } from 'vuex'
import Spinner from '@/components/Spinner.vue'
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
export default {
  components: { Spinner, AutomationHeader },
  data() {
    return {
      notifications: [],
      loading: true,
      noNotifications: false
    }
  },
  computed: {
    ...mapState('user', ['notificationNumber'])
  },
  async mounted() {
    const data = await getAllActivities(0, 50)
    if (data.data) {
      this.notifications = data.data
    }

    // if no notifications, show no notification message
    this.noNotifications = this.notifications.length === 0

    this.loading = false
  },
  methods: {
    ...mapActions('user', ['saveNotificationNumber']),
    completed(type) {
      return type === notificationTypes.COMPLETED
    },
    failed(type) {
      return type === notificationTypes.FAILED
    },
    paused(type) {
      return type === notificationTypes.PAUSED
    },
    update(type) {
      return type === notificationTypes.UPDATE
    },
    formatDate(d) {
      const newDate = new Date(Date.parse(d))

      const curr_date = newDate.getDate()

      const curr_month = newDate.toLocaleString('en-US', { month: 'short' })

      const curr_yr = newDate.getFullYear()
      return `${curr_date} ${curr_month} ${curr_yr}`
    },
    formatTime(d) {
      const newDate = new Date(Date.parse(d))
      let startTime = new Date(newDate.getTime())
      return startTime.toLocaleTimeString()
    },
    async redirectToResults(
      executionId,
      workflowId,
      activityId,
      notificationType
    ) {
      if (notificationType !== 'completed') {
        return
      }
      let data = {
        ids: [
          {
            id: activityId
          }
        ],
        isRead: true,
        markedForDelete: true
      }
      await removeActivities(data)
      this.saveNotificationNumber({ payload: this.notificationNumber - 1 })
      this.$emit('closeNotificaiton')
      this.$router.push(
        `/data-store/results?executionId=${executionId}&workflowId=${workflowId}`
      )
    }
  }
}
</script>
