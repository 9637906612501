<template>
  <div class="mt-8 flex flex-col">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div class="overflow-hidden">
          <Spinner class="mx-auto mt-10" size="large" v-if="loading" />

          <table v-if="!loading" class="min-w-full">
            <thead class="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th
                  scope="col"
                  class="rounded-l-lg py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  Paid On
                </th>

                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  Start Date
                </th>

                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  End Date
                </th>

                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  Price
                </th>

                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  Status
                </th>

                <th scope="col" class="rounded-r-lg px-3 py-3.5 text-left">
                  <span class="sr-only"> Actions </span>
                </th>
              </tr>
            </thead>

            <tbody
              class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
            >
              <tr v-for="(item, index) in invoiceData">
                <td
                  class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  {{ item.paidOn }}
                </td>

                <td
                  class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  {{ item.startDate }}
                </td>

                <td
                  class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  {{ item.endDate }}
                </td>

                <td
                  class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  {{ item.price.amount }}
                </td>

                <td class="whitespace-nowrap px-3 py-4">
                  <Badge text="paid" color="added" />
                </td>

                <td class="whitespace-nowrap px-3 py-4">
                  <Button
                    icon="external-link"
                    color="iconOnly"
                    @click="openReciptTab(item.receiptUrl)"
                    class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="flex items-center justify-center">
    <Paginator
      @page="onPaginate"
      ref="page"
      v-model:first="first"
      :rows="20"
      :totalRecords="total"
      template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown "
    ></Paginator>
  </div>
</template>

<script>
import BillingHeader from '@/components/settings/billingHeader.vue'
import Badge from '@/components/Badge.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import { invoices } from '@/apis/settings/settings-billing'
import Spinner from '@/components/Spinner.vue'
import Paginator from 'primevue/paginator'

export default {
  name: 'invoicesSection',
  components: {
    BillingHeader,
    Badge,
    SvgIcon,
    Button,
    Spinner,
    Paginator
  },
  data() {
    return {
      loading: true,
      invoiceData: null,
      total: 0,
      first: 0,
      count: 1
    }
  },
  beforeMount() {
    this.getData()
    if (this.$route.query.page) {
      this.first = (this.$route.query.page - 1) * 20
    }
  },
  methods: {
    async getData() {
      this.loading = true
      let start = 0
      if (this.$route.query.page) {
        start = (this.$route.query.page - 1) * 20
      }
      const response = await invoices(start, 20)
      this.invoiceData = response.data
      this.count = Math.ceil(response.total / 20)
      this.total = response.total
      this.loading = false
    },
    async onPaginate(e) {
      await this.updateQuery({
        page: Number(e.page + 1)
      })
      this.getData()
    },
    async updateQuery(updates) {
      await this.$router.push({
        query: {
          ...this.$route.query,
          ...updates
        }
      })
    },
    openReciptTab(url) {
      window.open(url)
    }
  }
}
</script>

<style scoped></style>
