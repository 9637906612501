<template>
  <div>
    <!-- Display the title with a label -->
    <Label>{{ title }}</Label>

    <!-- Input and Button for OTP request -->
    <div class="flex gap-2">
      <Input
        class="flex-1"
        :type="inputType"
        :text="inputValue"
        :disabled="true"
      />
      <Button
        class="w-[110px]"
        :text="buttonText"
        :disabled="isDisabled"
        @click="$emit('getOtp')"
      />
    </div>

    <!-- Help text for OTP issues -->
    <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
      ** Not getting OTP on your {{ title.toLowerCase() }}? Please contact
      <span class="inline">
        <a
          href="#"
          title="Contact Support"
          @click.prevent="$emit('openIntercom', title.toLowerCase())"
          class="text-sm font-semibold text-blue-600 hover:underline dark:text-blue-400"
        >
          support
        </a>
        .
      </span>
    </p>

    <!-- OTP input and submit button -->
    <div class="mt-4">
      <Label>Enter OTP</Label>
      <div class="flex gap-2">
        <Input class="flex-1" type="tel" v-model="val" placeholder="OTP" />
        <Button
          :showLoader="isLoading"
          class="w-[110px]"
          text="Submit"
          @click="$emit('submitCode', val)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'

export default {
  name: 'VerificationSection',
  components: {
    Input,
    Button
  },
  props: {
    title: {
      type: String,
      required: true
    },
    inputType: {
      type: String,
      required: true
    },
    inputValue: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    codeValue: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      val: this.codeValue
    }
  },
  emits: ['getOtp', 'submitCode', 'openIntercom']
}
</script>
