<!-- Note Component -->
<!-- Pass title, text, action, and color/contrast/filled to use the 3 variants of note -->
<!-- color: primary(default), success, error, warning -->
<!-- contrast: primary, success, error, warning -->
<!-- filled: primary, success, error, warning -->
<!-- to access action note pass v-bind:action="true/false", btnText as prop  -->

<template>
  <div>
    <div
      v-if="!action"
      class="overflow-hidden rounded-xl border bg-white"
      :class="[
        {
          'border-gray-300': primary,
          'bg-gray-100': contrastPrimary,
          'bg-gray-900': filledPrimary
        },
        {
          'border-green-600': success,
          'bg-green-100': contrastSuccess,
          'bg-green-600': filledSuccess
        },
        {
          'border-red-600': error,
          'bg-red-100': contrastError,
          'bg-red-600': filledError
        },
        {
          'border-yellow-500': warning,
          'bg-yellow-100': contrastWarning,
          'bg-yellow-500': filledWarning
        }
      ]"
    >
      <div class="px-4 py-3">
        <p
          class="text-base font-normal"
          :class="[
            { 'text-gray-900': primary },
            { 'text-green-700': success || contrastSuccess },
            { 'text-red-700': error || contrastError },
            { 'text-yellow-600': warning || contrastWarning },
            { 'text-white': filledPrimary },
            { 'text-white': filledSuccess },
            { 'text-white': filledError },
            { 'text-white': filledWarning }
          ]"
        >
          <span class="font-bold"> {{ title }}{{ title ? ':' : '' }} </span>
          {{ text }}
        </p>
      </div>
    </div>
    <div
      v-if="action"
      class="overflow-hidden rounded-xl border border-gray-300 bg-white"
    >
      <div class="py-2 pl-4 pr-2">
        <div class="flex items-center justify-between space-x-5">
          <p class="flex-1 text-base font-normal text-gray-900">
            <span class="font-bold"> {{ title }}: </span>
            {{ text }}
          </p>

          <div class="shrink-0">
            <button
              type="button"
              class="inline-flex items-center justify-center rounded-lg border border-transparent bg-blue-600 px-3 py-2 text-xs font-semibold text-white transition-all duration-200 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
            >
              {{ btnText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Note',
  props: {
    title: String,
    text: String,
    color: { type: String, default: 'primary' },
    contrast: String,
    filled: String,
    action: { type: Boolean, default: false },
    btnText: { String, default: 'pass button name' }
  },
  computed: {
    primary() {
      return this.color === 'primary'
    },
    success() {
      return this.color === 'success'
    },
    error() {
      return this.color === 'error'
    },
    warning() {
      return this.color === 'warning'
    },
    contrastPrimary() {
      return this.contrast === 'primary'
    },
    contrastSuccess() {
      return this.contrast === 'success'
    },
    contrastError() {
      return this.contrast === 'error'
    },
    contrastWarning() {
      return this.contrast === 'warning'
    },
    filledPrimary() {
      return this.filled === 'primary'
    },
    filledSuccess() {
      return this.filled === 'success'
    },
    filledError() {
      return this.filled === 'error'
    },
    filledWarning() {
      return this.filled === 'warning'
    }
  }
}
</script>

<style></style>
